import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { first } from "rxjs";
import { AddAssignTaskComponent } from "src/app/modal/add-assign-task/add-assign-task.component";
import { AdminService } from "src/app/services/admin.service";
import { AuthService } from "src/app/services/authService";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { DataServiceService } from "src/app/services/data-service.service";
import { WorkflowService } from "src/app/services/workflow.service";
import { DashboardParam } from "src/app/model/dashboardParam";
import { DataShareModel } from "src/app/model/dataShareModel";
import { CreateAgreementComponent } from "../onboarding-form/create-agreement/create-agreement.component";
import { InitiateOnboardingComponent } from "../onboarding-form/initiate-onboarding/initiate-onboarding.component";
import { TechOnboardingComponent } from "../onboarding-form/tech-onboarding/tech-onboarding.component";

@Component({
  selector: "app-assignment",
  templateUrl: "./assignment.component.html",
  styleUrls: ["./assignment.component.scss"],
})
export class AssignmentComponent implements OnInit, AfterViewInit {
  faClock = faClock;
  minDate: any;
  loading: any = false;
  error: any;
  message: any;
  user: any;
  assignedTasks: any[] = [];
  tasks: any = [];
  dashboardParams: DashboardParam = { "processType": "ekypProcess", "orgType": "BG" };
  
  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private dataService: DataServiceService,
    private cashlessService: CashflowService,
    private workflowService:WorkflowService
  ) {
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;
    this.authService.user.subscribe((x) => (this.user = x));
    this.dataService.newTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "hospitalOnBoardingProcess") {
        this.tasks = data.tasks;
      }
    });
    this.dataService.assignedTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "hospitalOnBoardingProcess") {
        this.assignedTasks = data.tasks;
      }
    });
  }
  ngAfterViewInit(): void {
    this.getAssignedTask();
  }
  ngOnInit(): void {
    this.getAssignedTask();
  }
  
  getNewTasks() {
    var variables: any[][] = [[], []];
    this.workflowService
      .getNewTask("hospitalOnBoardingProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.tasks= data; 
          const dataShare = new DataShareModel("hospitalOnBoardingProcess",data);
          this.dataService.updateNewTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  getAssignedTask() {
    this.workflowService
      .getAssignedTask("hospitalOnBoardingProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log("Accept");
          this.assignedTasks = data;
          const dataShare = new DataShareModel("hospitalOnBoardingProcess",data);
          this.dataService.updateAssignedTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          // console.log(error);
          this.loading = false;
        },
      });
  }

  assignTask(data: any) {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data: { data: data, roleName: ["ADMIN","ONBOARDING"] },
      width: "430px",
      height: "200",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getAssignedTask();
          this.getNewTasks();
        }
      },
    });
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  viewTask(task: any) {
    console.log("here", task.name);

    if (task.name == "Create Agreement") {
      const dialogRef = this._dialog.open(CreateAgreementComponent, {
        disableClose:true,
        data: { task: task },
        width: "90%",
        height: "90%",
      });
      dialogRef.afterClosed().subscribe({
        next: (val: any) => {
          this.getAssignedTask();
          this.getNewTasks();
          this.getDashboardParam();
        },
      });
    } else if (task.name == "Initiate Onboarding") {
      const dialogRef = this._dialog.open(InitiateOnboardingComponent, {
        disableClose:true,
        data: { task: task },
        width: "90%",
        height: "90%",
      });
      dialogRef.afterClosed().subscribe({
        next: (val: any) => {
          this.getAssignedTask();
          this.getNewTasks();
          this.getDashboardParam();
        },
      });
    } else if (task.name == "Tech Onboarding") {
      const dialogRef = this._dialog.open(TechOnboardingComponent, {
        disableClose:true,
        data: { task: task },
        width: "90%",
        height: "90%",
      });
      dialogRef.afterClosed().subscribe({
        next: (val: any) => {
          this.getAssignedTask();
          this.getNewTasks();
          this.getDashboardParam();
        },
      });
    }
  }

  escalation(dueDate:number){
    const currentDate = new Date().getTime();
    if(dueDate > currentDate){
      return false;
    }else{
      return true;
    }

  }

  getDashboardParam() {
    return JSON.stringify(this.dashboardParams);
  }

}
