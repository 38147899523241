import { Component, ViewChild } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { first } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";
import { CashflowService } from "src/app/services/cashflow.service";
import { AddCategoryMasterComponent } from "./add-category-master/add-category-master.component";
import { UpdateCategoryMasterComponent } from "./update-category-master/update-category-master.component";

@Component({
  selector: 'app-manage-category-master',
  // standalone: true,
  // imports: [],
  templateUrl: './manage-category-master.component.html',
  styleUrl: './manage-category-master.component.scss'
})
export class ManageCategoryMasterComponent {
   data = {
    "category_master": [
          {
                "type" : "DROPDOWN",
                "category" : "WORKFLOW",
                "category_name" : "CO-PAYMENT",
                "category_description" : "CO-PAYMENT",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "reconPartialSettlement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:32:54.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "reconPartialSettlement",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "DROPDOWN",
                "category" : "WORKFLOW",
                "category_name" : "EXCESS-DEDUCTIONS",
                "category_description" : "EXCESS-DEDUCTIONS",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "reconPartialSettlement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:32:36.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "reconPartialSettlement",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "DROPDOWN",
                "category" : "WORKFLOW",
                "category_name" : "HOSPITAL-DISCOUNT",
                "category_description" : "HOSPITAL-DISCOUNT",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "reconPartialSettlement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:30:12.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "reconPartialSettlement",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "DROPDOWN",
                "category" : "WORKFLOW",
                "category_name" : "NON-MEDICAL-ITEMS",
                "category_description" : "NON-MEDICAL-ITEMS",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "reconPartialSettlement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:31:11.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "reconPartialSettlement",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "DROPDOWN",
                "category" : "WORKFLOW",
                "category_name" : "OTHER",
                "category_description" : "OTHER",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "reconPartialSettlement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:33:11.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "reconPartialSettlement",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "DROPDOWN",
                "category" : "WORKFLOW",
                "category_name" : "RECOVERY NOT DONE",
                "category_description" : "RECOVERY NOT DONE",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "reconPartialSettlement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:32:04.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "reconPartialSettlement",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "DROPDOWN",
                "category" : "WORKFLOW",
                "category_name" : "TARIFF-DEDUCTIONS",
                "category_description" : "TARIFF-DEDUCTIONS",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "reconPartialSettlement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:32:04.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "reconPartialSettlement",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "ONBOARDING",
                "category_name" : "LEGAL_AGREEMENT",
                "category_description" : "LEGAL_AGREEMENT",
                "entity_type" : "ONBOARDING",
                "entity_sub_type" : "createAgreement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "createAgreement",
                "date_enabled" : 1,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "ONBOARDING",
                "category_name" : "SOC_DOCUMENT",
                "category_description" : "SOC_DOCUMENT",
                "entity_type" : "ONBOARDING",
                "entity_sub_type" : "initiateOnboarding",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "initiateOnboarding",
                "date_enabled" : 1,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "ONBOARDING",
                "category_name" : "TECHNICAL_DOCUMENT",
                "category_description" : "TECHNICAL_DOCUMENT",
                "entity_type" : "ONBOARDING",
                "entity_sub_type" : "techOnboarding",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "techOnboarding",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "BILL-SUMMARY",
                "category_description" : "BILL-SUMMARY",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "finalDischarge",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:19:23.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "finalDischarge,enhancement,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "CONSENT-FORM",
                "category_description" : "CONCENT-FORM",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:29:21.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth,finalDischarge,finalApproval,onboardPatient",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "CONSULTATION-PAPER",
                "category_description" : "CONSULTATION-PAPER",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:24:06.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth,onboardPatient",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "DISCHARGE-SUMMARY",
                "category_description" : "DISCHARGE-SUMMARY",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "finalDischarge",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:27:47.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "finalDischarge,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "E-CARD",
                "category_description" : "E-CARD",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:18:25.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth,onboardPatient",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "ENHANCEMENT-APPROVAL-DOC",
                "category_description" : "Enhancement Approval Document",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "process",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "process,onboardPatient",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "ENHANCEMENT-DOCUMENT",
                "category_description" : "ENHANCEMENT-DOCUMENT",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "enhancement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:22:15.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "enhancement,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "ENHANCEMENT-REJECTION-DOC",
                "category_description" : "Enhancement Rejection Document",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "process",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "process,onboardPatient",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "FINAL APPROVAL LETTER",
                "category_description" : "FINAL APPROVAL LETTER",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "finalApproval",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:17:34.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "FINAL SETTLEMENT LETTER",
                "category_description" : "FINAL SETTLEMENT LETTER",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "recon",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "recon",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "FINAL-BILL-SUMMARY",
                "category_description" : "FINAL-BILL-SUMMARY",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "finalDischarge",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:19:47.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "finalDischarge,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "FINAL-CASE-SUMMARY",
                "category_description" : "FINAL-CASE-SUMMARY",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "finalDischarge",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:24:49.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "finalDischarge,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "FINAL-REJECTION-DOC",
                "category_description" : "Final Rejection Document",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "process",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "process,onboardPatient",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "INDOOR-CASE-PAPERS",
                "category_description" : "INDOOR-CASE-PAPERS",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:21:16.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth,enhancement,finalDischarge,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "INTERIM-BILL-SUMMARY",
                "category_description" : "INTERIM-BILL-SUMMARY",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "enhancement",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:20:03.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "enhancement,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "INVESTIGATION-REPORT",
                "category_description" : "INVESTIGATION-REPORT",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:20:24.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth,enhancement,finalDischarge,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "KYC-DOCUMENT",
                "category_description" : "KYC-DOCUMENT",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:18:51.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth",
                "date_enabled" : 1,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "MEDICINE-BILLS",
                "category_description" : "MEDICINE-BILLS",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:28:35.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "finalDischarge,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "OTHER-DOCUMENTS",
                "category_description" : "OTHER-DOCUMENTS",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:24:26.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "others,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "POLICY",
                "category_description" : "POLICY",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "createCase",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:18:13.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "createCase,preauth",
                "date_enabled" : 1,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "PRE AUTH FORM",
                "category_description" : "PRE AUTH FORM",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "preauth",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T07:17:34.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "preauth,finalApproval",
                "date_enabled" : 0,
                "display_expression" : null
          },
          {
                "type" : "FILE",
                "category" : "WORKFLOW",
                "category_name" : "PRE-AUTH-APPROVAL-DOC",
                "category_description" : "Pre Auth Approval Document",
                "entity_type" : "BG-CASHLESS",
                "entity_sub_type" : "process",
                "created_by" : "d1668e13-fc12-46c1-96db-18ba7e769103",
                "date_created" : "2024-07-27T08:12:45.000Z",
                "updated_by" : null,
                "date_updated" : null,
                "inactive" : 0,
                "tags" : "process,onboardPatient",
                "date_enabled" : 0,
                "display_expression" : null
          }
    ]}
 



  catergorymasterlist: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];
  message: any;

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  user: any;
  pageEvent: PageEvent = new PageEvent();

  dataSource = new MatTableDataSource<any>(this.catergorymasterlist);
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  displayedColumns: string[] = [
    "category",
    "categoryName",
    "categoryDescription",
    "entityType",
    "type",
    "Status",
    "action",
  ];
  loading: boolean = false;
  error: any;

  constructor(
    private adminService: AdminService,
    private _dialog: MatDialog,
    private authService: AuthService,
    private catergorymaster:CashflowService,
    private customerService: CustomerService
  ) {
    this.authService.user.subscribe((x) => (this.user = x));
  }

  ngOnInit(): void {
    this.getCatergoryMaster();
    this.data;
    this.getProducts();
    this.dataSource.paginator = this.paginator;
    //this.coupons = this.data;
  }

  getCatergoryMaster() {
    this.adminService
      .getAllCategories()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.catergorymasterlist = data;
          this.dataSource.data = this.catergorymasterlist;
          this.dataSource.paginator = this.paginator;
          console.log("fdsg",this.catergorymasterlist);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //sthis.loading = false;
        },
      });
  }

  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.catergorymasterlist =
        query === ""
          ? this.catergorymasterlist
          : this.catergorymasterlist.filter((item: any) => {
              return Object.values(item).some((val) =>
                String(val).toLowerCase().includes(query)
              );
            });
    } else {
      this.getCatergoryMaster();
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getCatergoryMaster();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddCategoryMasterComponent, 
      {disableClose:true,
        width: '100vw',  // Sets the width to 100% of the viewport width
        maxWidth: '100vw',  // Ensures the max width doesn't override the width
        panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
        height: '100%',
      });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getCatergoryMaster();
        }
      },
    });
  }

  openEditForm(data: any) {
    const dialogRef = this._dialog.open(UpdateCategoryMasterComponent, {
      disableClose:true,
      data,
      //width: '750px',
      // height: '200'
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getCatergoryMaster();
        }
      },
    });
  }

  products: any;
  getProducts() {
    this.customerService
      .getProducts()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.products = data;
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No products available for!";
          }
        },
      });
  }
  getProductName(productId: string): string {
    const product = this.products.find((p: any) => p.id === productId);
    return product ? product.name : "Unknown Product";
  }
}
