<!-- <div mat-dialog-content>
    <h1> Add Role </h1>
</div> -->
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1> Add Role</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
<form [formGroup]="empForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Role name</mat-label>
                <input matInput type="text" placeholder="role" formControlName="roleName">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Role Description</mat-label>
                <input matInput placeholder="Role Description" formControlName="roleDescription">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Tag</mat-label>
                <input matInput placeholder="abc" formControlName="tag">
            </mat-form-field>
            <!-- <mat-form-field appearance="outline">
                <mat-label>Privileges</mat-label>
                <input matInput placeholder="abc" formControlName="privileges">
            </mat-form-field> -->
            <div class="p-2">
                <mat-form-field appearance="outline">
                  <mat-label>Privileges</mat-label>
                  <mat-select formControlName="privileges" [(ngModel)]="selectedType" name="privelegedata" multiple>
                    <mat-option *ngFor="let privelegedata of priveleges" [value]="privelegedata.id">
                        {{privelegedata.privilege}} 
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <mat-form-field appearance="outline" *ngIf='roles'>
                  <mat-label>Privileges</mat-label>
                  <mat-select formControlName="privileges" [(ngModel)]="selectedType" name="privelegedata" multiple>
                    <mat-option *ngFor="let priv of roles[0].privileges" [value]="priv.privilegeId">
                      {{priv.privilegeName}}    
                                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>
        </div>                      
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <!-- <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button> -->
        <button mat-raised-button color="primary" type="submit" [disabled]="!empForm.valid">Save</button>

    </div>
</form>