<div mat-dialog-title>
    <h1> Update KYP</h1>
    <!-- <h3>Redo Comment: </h3>
    <h4>Provide detailed line of treatment along with the medicine details and route of drug administration </h4> -->

</div>
<form [formGroup]="kypForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>First Inception date</mat-label>
                    <input matInput type="text" placeholder="MM/DD/YYYY" formControlName="start_date">
                </mat-form-field>   
                <div class="text-danger er-text" *ngIf="kypForm.controls.start_date.errors">{{errorMessage}}</div>
                <!-- <mat-error *ngIf="kypForm.value.start_date.invalid">{{kypForm.value.start_date.invalid}}</mat-error> -->
              
            </div>
            <div class="col">
                <!-- <mat-form-field appearance="outline">
                    <mat-label>First Effective date</mat-label>
                    <input matInput type="text" placeholder="MM/DD/YYYY" formControlName="start_date">
                </mat-form-field>
                <div class="text-danger er-text" *ngIf="kypForm.controls.start_date.errors">{{errorMessage}}</div> -->
                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label>policy_number</mat-label>
                        <input matInput type="text" placeholder="id" formControlName="policy_number">
                    </mat-form-field>
                    <div class="text-danger er-text" *ngIf="kypForm.controls.policy_number.errors">{{errorMessage}}</div>
                </div>
            </div>
        </div>
        <div class="row" >
            <mat-form-field appearance="outline" class="mat-50">
                <mat-label>End date</mat-label>
                <input matInput type="text" placeholder="MM/DD/YYYY" formControlName="end_date">
                <!-- <input matInput [matDatepicker]="picker2" formControlName="end_date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 ></mat-datepicker> -->
                <!-- @if (end_date.invalid) {
                    <mat-error>{{errorMessage}}</mat-error>
                } -->
            </mat-form-field>
            <div class="text-danger er-text" *ngIf="kypForm.controls.end_date.errors">{{errorMessage}}</div>
            
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Company Name</mat-label>
                <input matInput type="text" placeholder="insurance_company" formControlName="insurance_company">
                <!-- @if (insurance_company.invalid) {
                    <mat-error>{{errorMessage}}</mat-error>
                } -->
            </mat-form-field>
            <div class="text-danger er-text" *ngIf="kypForm.controls.insurance_company.errors">{{errorMessage}}</div>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Insurance Name</mat-label>
                <input matInput type="text" placeholder="insurance_name" formControlName="insurance_name">
            </mat-form-field>
            <div class="text-danger er-text" *ngIf="kypForm.controls.insurance_name.errors">{{errorMessage}}</div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Ported</mat-label>
                    <input matInput type="text" placeholder="ported" formControlName="ported">
                </mat-form-field>
                <div class="text-danger er-text" *ngIf="kypForm.controls.ported.errors">{{errorMessage}}</div>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Policy Type</mat-label>
                    <input matInput type="text" placeholder="policy_type" formControlName="policy_type">
                </mat-form-field>
                <div class="text-danger er-text" *ngIf="kypForm.controls.policy_type.errors">{{errorMessage}}</div>
            </div>

        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Policy Name</mat-label>
                <input matInput type="text" placeholder="Policy Name" formControlName="policy_name">
            </mat-form-field>
            <!-- <div class="text-danger er-text" *ngIf="kypForm.controls.policy_name.errors">{{errorMessage}}</div> -->
        </div>
        <!-- <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>policy_number</mat-label>
                    <input matInput type="text" placeholder="id" formControlName="policy_number">
                </mat-form-field>
                <div class="text-danger er-text" *ngIf="kypForm.controls.policy_number.errors">{{errorMessage}}</div>
            </div>
        </div> -->



        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>policy_holder</mat-label>
                <input matInput placeholder="policy_holder" formControlName="policy_holder">
            </mat-form-field>
        </div> -->
        <!-- <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>policy_holder</mat-label>
                    <input matInput type="text" placeholder="policy_holder" formControlName="policy_holder">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>holder_marker</mat-label>
                    <input matInput type="text" placeholder="holder_marker" formControlName="holder_marker">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Start date</mat-label>
                    <input matInput type="text" placeholder="Start date" formControlName="start_date">

                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>End date</mat-label>
                    <input matInput type="text" placeholder="End date" formControlName="end_date">
                    <mat-datepicker-toggle matSuffix [for]="picker1" ></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>

        </div> -->

        <!-- <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Insurance_company</mat-label>
                    <input matInput type="text" placeholder="insurance_company" formControlName="insurance_company">
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>sum_insured</mat-label>
                    <input matInput type="text" placeholder="sum_insured" formControlName="sum_insured">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>renewal_information</mat-label>
                    <input matInput type="text" placeholder="renewal_information" formControlName="renewal_information">
                </mat-form-field>
            </div>
        </div> -->
        <hr>


        <h2>Coverage Information</h2>
      <form [formGroup]="membersForm">
            <div formArrayName="members">
                <table style="border-spacing: 10px; ">
                    <thead>
                        <tr style="background-color: brown; height: 50px;">
                            <th>Insured Name</th>
                            <th>Sum Insured</th>
                            <th>Super Bonus</th>
                            <th>Bonus</th>
                            <th>Restore</th>
                            <th>Effective Coverage</th>
                        </tr>

                        <tr *ngFor="let member of getMembers.controls; let i = index" [formGroupName]="i">
                            <td>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="insured_name">
                                </mat-form-field>
                                
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="sum_insured">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                    
                                    <input matInput formControlName="super_bonus">
                                </mat-form-field>

                            </td>
                            <td>
                                <mat-form-field appearance="outline">
 
                                    <input matInput formControlName="bonus">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                   
                                    <input matInput formControlName="restore">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                    
                                    <input matInput formControlName="effective_coverage">
                                </mat-form-field>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </form>

      

        <!-- <form [formGroup]="membersForm">
            <div formArrayName="members">
                <div *ngFor="let member of getMembers.controls; let i = index" [formGroupName]="i">
                    <h2>Member - {{i+1}}</h2>
                    <div class="row">
                        <div class="col">
                            <mat-form-field appearance="outline">
                                <mat-label>Name of the Insured Person(s)</mat-label>
                                <input matInput formControlName="insured_name">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="outline">
                                <mat-label>Base Sum Insured (in Rs.)</mat-label>
                                <input matInput formControlName="sum_insured">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="outline">
                                <mat-label>Super Bonus</mat-label>
                                <input matInput formControlName="super_bonus">
                            </mat-form-field>

                        </div>
                        <div class="col">
                            <mat-form-field appearance="outline">
                                <mat-label>Bonus</mat-label>
                                <input matInput formControlName="bonus">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="outline">
                                <mat-label>Restore</mat-label>
                                <input matInput formControlName="restore">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="outline">
                                <mat-label>Effective Coverage</mat-label>
                                <input matInput formControlName="effective_coverage">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </form> -->



        <hr>
        <h2>Insured Waiting Period</h2>
        <!-- <form [formGroup]="insured_waiting_period">
            <div formArrayName="insured">
                <div *ngFor="let insured of getInsured.controls; let i=index" [formGroupName]="i">
                    <h2>Insured - {{i+1}}</h2>
                    <mat-form-field appearance="outline">
                        <mat-label>Age (in Years) </mat-label>
                        <input matInput formControlName="Age (in Years)">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Gender </mat-label>
                        <input matInput formControlName="Gender">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Personal Waiting Period</mat-label>
                        <input matInput formControlName="Personal Waiting Period">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>DOB</mat-label>
                        <input matInput formControlName="dob">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Pre Existing Disease</mat-label>
                        <input matInput formControlName="pre-existing_disease">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Relationship</mat-label>
                        <input matInput formControlName="relationship">
                    </mat-form-field>
                </div>
            </div>
        </form> -->

        <form [formGroup]="insured_waiting_period">
            <div formArrayName="insured">
                <table style="border-spacing: 10px;">
                    <thead>
                        <tr style=" height:50px ; background-color: brown;">
                            <th>Name</th>
                            <th>Pre Hospitalisation </th>
                            <th>Post Hospitalisation</th>
                            <th> Waiting Period</th>
                        </tr>
                        <tr *ngFor="let insured of getInsured.controls; let i=index" [formGroupName]="i">
                            <td>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="insured_name">
                                </mat-form-field>
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Select an option</mat-label>
                                    <mat-select formControlName="pre_hospitalisation">
                                      <mat-option value="No Waiting Period">No Waiting Period</mat-option>
                                      <mat-option value="30 days">30 days</mat-option>
                                      <mat-option value="60 days">60 days</mat-option>
                                    </mat-select>
                                    <!-- <input matInput formControlName="pre_hospitalisation"> -->

                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>Select an option</mat-label>
                                    <mat-select formControlName="post_hospitalisation">
                                      <mat-option value="No Waiting Period">No Waiting Period</mat-option>
                                      <mat-option value="30 days">30 days</mat-option>
                                      <mat-option value="60 days">60 days</mat-option>
                                    </mat-select>
                                    <!-- <input matInput formControlName="post_hospitalisation"> -->
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-select formControlName="waiting_period">
                                        <mat-option value="No Waiting Period">No Waiting Period</mat-option>
                                        <mat-option value="30 days">30 days</mat-option>
                                        <mat-option value="60 days">60 days</mat-option>
                                      </mat-select>
                                    <!-- <input matInput formControlName="waiting_period"> -->
                                </mat-form-field>
                            </td>

                        </tr>
                    </thead>
                </table>
            </div>
        </form>
        <hr>
        <h2>Sublimit Information</h2>
      
          <!-- comment for testing  -->
        <form [formGroup]="sublimit_info">
            <div formArrayName="sublimit_info">
                <table>
                    <thead>
                        <tr style="background-color: brown; height: 50px;" *ngFor="let info of getSublimitInfo.controls, let i=index" [formGroupName]="i">
                            <th>Insured_Name</th>
                            <th >
                                <div formArrayName="sublimit">
                                    <td *ngFor=" let data of  getSubmlimitIndex(info).controls let j = index" [formGroupName]="j">
                                      <mat-form-field >
                                        <input matInput placeholder="Sublimit Name" formControlName="name" >
                                      </mat-form-field>
                                    </td>
                                  </div>
                           
                         </tr>
                         <tr  *ngFor="let info of getSublimitInfo.controls, let i=index" [formGroupName]="i">
                            <td>
                                <mat-form-field>
                                    <input matInput formControlName="insured_name">
                                </mat-form-field>
                                
                            </td>
                            
                            <div formArrayName="sublimit">
                                <td *ngFor=" let data of  getSubmlimitIndex(info).controls let j = index" [formGroupName]="j">
                                 
                                  <mat-form-field>
                                    <input matInput placeholder="Comment" formControlName="comment">
                                  </mat-form-field>
                                </td>
                              </div>
                               
                          
                         </tr>
                    </thead>
                </table>
            </div>
</form>

    
<!-- <form [formGroup]="benefitForm" (ngSubmit)="onFormSubmit()">
            <div mat-dialog-content class="content">
                <div formArrayName="dynamicData">
                    <div class="row">
                        <div *ngFor="let item of controlNames;let i=index">
                            <div class="col">
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>{{ item.name}} : </mat-label>
                                    <input #myInput matInput type="text" [value]="item.comment"
                                        (keyup)="updateValue(i,myInput.value)">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>
                <button mat-raised-button color="primary" (click)="onFormSubmit()">Submit Benefits</button>
            </div>
        </form> -->

<!-- not needed
        <form [formGroup]="benefitForm">
            <div formArrayName="dynamicData">
                <table style="border-spacing: 10px;">
                    <tbody>
                        <tr style="background-color: brown; height: 50px;">
                            <th *ngFor="let item of controlNames; let i = index">
                                {{ item.name }}
                            </th>
                        </tr>

                        <tr>
                            <td *ngFor="let item of controlNames; let i = index">
                                <mat-form-field>
                                    <input #myInput matInput type="text" [value]="item.comment"
                                        (keyup)="updateValue(i,myInput.value)">
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form> -->



<hr>
<div style="display: flex; justify-content: space-between;">
    <h2>Comment Section</h2>
    <button type="button" style="background-color: brown;" (click)="addNewComment()">Add Comment</button>
 
  </div>
 <div *ngIf="comment_section" >
    <form [formGroup]="comment_section">
    <div formArrayName="comment">
        <div class="row">
            <table>
                <thead>
                    <tr style="background-color: brown; height: 50px;">
                        <th>Comment Category</th>
                        <th>Add Comment </th>
                    </tr>
                    <tr *ngFor="let comment of  getComment.controls; let i = index" [formGroupName]="i">
                        <td>
                            <mat-form-field appearance="outline">
                                <input matInput type="text" placeholder="name" formControlName="name">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline">
                                <input matInput type="text" placeholder="comment" formControlName="comment">
                            </mat-form-field>
                        </td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</form>
</div>

</div>

<div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!kypForm.valid">Save</button>
</div>


</form>