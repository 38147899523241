import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-view-add-kyp',
  templateUrl: './view-add-kyp.component.html',
  styleUrls: ['./view-add-kyp.component.scss']
})
export class ViewAddKypComponent implements OnInit{

  pdfUrl:any;
  imgData:any;
  kyp_data:any;
  kypForm: FormGroup;
  commentsForm: FormGroup;
  memberForm: FormGroup;
  sublimitinformationform: FormGroup;
  InsuredwaitingperiodForm: FormGroup;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private datePipe:DatePipe,
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<ViewAddKypComponent>,
  ){
    this.kypForm = this._fb.group({
      policynumber: ['', Validators.required],
      policyname: ['', [Validators.required,Validators.maxLength(300)]],
      policytype: ['', Validators.required],
      insurancename: ['', [Validators.required, Validators.maxLength(300)]],
      membername: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
      insurancecompany: ['', Validators.required],
      renewalinformation: ['', Validators.required],
      ported: ['', Validators.required],
    });

    this.commentsForm = this._fb.group({
      submitcomment: ['', Validators.required],
      additionalcomment: ['', Validators.required],
      expertcomments: ['', Validators.required],
      coveragecomments: ['', Validators.required],
      waitingperiodcomments: ['', Validators.required],
    });

    this.memberForm = this._fb.group({
      insuredname: ['', Validators.required],
      suminsured: ['', Validators.required],

      superbonus: ['', Validators.required],
      bonus: ['', Validators.required],
      restore: ['', Validators.required],
      effectivecoverage: ['', Validators.required],
      dob: ['', Validators.required],

    });
    this.InsuredwaitingperiodForm = this._fb.group({
      insuredname: ['', Validators.required],
      prehospitalisation: ['', Validators.required],
      posthospitalisation: ['', Validators.required],
      waitingperiod: ['', Validators.required],
    });

    this.sublimitinformationform = this._fb.group({
      insuredname: ['', Validators.required],
      roomrent: ['', Validators.required],
      icuICCU: ['', Validators.required]

    })
  }

  ngOnInit(): void {
    this.getPolicyFile(this.data.caseId);
  }

  async getPolicyFile(policyId:string){
    await this.customerService
      .getPolicyFile(policyId)
      .subscribe({
        next: (data: Uint8Array) => {

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = URL.createObjectURL(file);
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
        },
      });
  }

  

  onFormSubmit(){
    console.log(this.data);
    if(this.data.policyId == 'Processing Failed'){
      console.log("Processing Failed");
     this. kyp_data =
      {
  
        "id": this.data.caseId || 0,
  
        user_id: this.data.userId,
        "policyPath":this.data.policyPath ,
        "processed":true,
  
        "member_name": this.kypForm.value.membername,
  
        "ekypData": {
  
          "start_date": this.datePipe.transform(this.kypForm.value.startdate, 'MMM dd yyyy'),
  
          "end_date": this.datePipe.transform(this.kypForm.value.enddate, 'MMM dd yyyy'),
  
          "insurance_company": this.kypForm.value.insurancecompany,
  
          "insurance_name": this.kypForm.value.insurancename,
  
          "ported": this.kypForm.value.ported,
  
          "policy_type": this.kypForm.value.policytype,
  
          "policy_name": this.kypForm.value.policyname,
  
          "policy_number": this.kypForm.value.policynumber,
  
          "coverage_information": [
  
            {
  
              "insured_name": this.memberForm.value.insuredname,
  
              "sum_insured": this.memberForm.value.sumInsured,
  
              "super_bonus": this.memberForm.value.superbonus,
  
              "bonus": this.memberForm.value.bonus,
  
              "restore": this.memberForm.value.restore,
  
              "effective_coverage": this.memberForm.value.effectivecoverage,
  
              "dob": this.memberForm.value.dob?this.datePipe.transform(this.memberForm.value.dob, 'MMM dd yyyy'):'',
  
            }
  
          ],
  
          "waiting_period_information": [
  
            {
  
              "insured_name": this.InsuredwaitingperiodForm.value.insuredname,
  
              "pre_hospitalisation": this.InsuredwaitingperiodForm.value.prehospitalisation,
  
              "post_hospitalisation": this.InsuredwaitingperiodForm.value.posthospitalisation,
  
              "waiting_period": this.InsuredwaitingperiodForm.value.waitingperiod
  
            }
  
          ],
  
          "sublimit_information": [
  
            {
  
              "insured_name": this.sublimitinformationform.value.insuredname,
  
              "sublimit": [
  
                {
  
                  "name": "Room Rent",
  
                  "comment": this.sublimitinformationform.value.roomrent
  
                },
  
                {
  
                  "name": "ICU/ICCU",
  
                  "comment": this.sublimitinformationform.value.icuICCU
                }
  
              ]
  
            }
  
          ],
  
          "renewal_information": this.kypForm.value.renewalinformation,
  
          "comments": [
  
            {
  
              "name": "Submit Comment",
  
              "comment": this.commentsForm.value.submitcomment
  
            },
  
            {
  
              "name": "Additional Comment",
  
              "comment": this.commentsForm.value.additionalcomment
  
            },
  
            {
  
              "name": "Expert Comments",
  
              "comment": this.commentsForm.value.expertcomments
  
            },
  
            {
  
              "name": "Coverage Comments",
  
              "comment": this.commentsForm.value.coveragecomments
  
            },
  
            {
  
              "name": "Waiting Period Comments",
  
              "comment": this.commentsForm.value.waitingperiodcomments
  
            }
  
          ],
         
  
        }
  
      }

    console.log(this.kyp_data);
    }

    this.customerService.addkyp(this.kyp_data).subscribe({
      next: (val: any) => {
        console.log("POlicy returned :"+JSON.stringify(val));
        this.toastrService.success("kyp has been added successfully");
        this._dialogRef.close(val);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));


      },
    });

  }

}
