
<div class="px-4 py-5 my-5 text-center ">

    <img width="10%" class="d-block mx-auto mb-4" src="../../assets/img/enterprise.png" alt="">

    <h1 class="display-5 fw-bold">Bima<span style="color:#C00000;">Garage</span> Enterprise portal</h1>

    <div class="col-lg-6 mx-auto">

        <p class="lead mb-4 fw-semibold">
            We are standing by to make sure that all your Health and Insurance needs are covered.
        </p>
        <p class="fw-light">
            1001, 10th floor, Dosti Pinnacle, Road No.22, Wagle Industrial Estate, Thane – 400604, India.
        </p>
            
        <p class="fw-light">
            support&#64;bimagarage.com
            Call us: +91 844 844 4158
        </p>
            
                    

    </div>

</div>


<!-- 

We are standing by to make sure that all your Health and Insurance needs are covered.
1001, 10th floor, Dosti Pinnacle, Road No.22, Wagle Industrial Estate, Thane – 400604, India.

support@bimagarage.com
Call us: +91 844 844 4158 -->