<div class="horizontal-scroller ">
  <div class="scroll-content">
    <div class="btn-group" role="group" aria-label="Basic example">
      <div *ngFor="let item of data">
        <ng-container *ngIf="item.bgProductModel; else otherFields">
          <div style="border: 1px solid #ccc; padding: 5px; border-radius: 5px;margin:1px;">
            {{ item.bgProductModel.name }} -
            Qty :{{ item.qty }}, Rate: ({{ item.negotiatedRate }})
          </div>

        </ng-container>

        <ng-template #otherFields>
          <div class="row">
            <div class="col">
              <button style="color:white;background:#8e0101;margin:1px;" class="btn" (click)="onFileSelected(item); ">
                <ng-container *ngIf="item.socId; else cashlessMedia">
                  {{item.insuranceName}}
                </ng-container>
                <ng-template #cashlessMedia>
                  {{item.filename}} ({{ item.docType }}) 
                </ng-template>
              </button>
            </div>
            <div class="col">
              <button mat-icon-button style="color:white;" (click)="deleteMedia(item.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>

        </ng-template>


      </div>

    </div>
  </div>
</div>