import { Component } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-accountactivation',
  templateUrl: './accountactivation.component.html',
  styleUrls: ['./accountactivation.component.scss']
})
export class AccountactivationComponent {

  constructor(private router:Router)
  {
    let token =localStorage.getItem("token");

    //console.log("gettingtoken",JSON.stringify(token));

  }
naviagatetoregister()
{
  this.router.navigate(["hospitalregistration"]);
}

onSubmit(){
  window.location.assign("https://bimagarage.com/")

}
}
