<div class="container-fluid py-5 px-5 ">

  <div class=" p-3">
    <div mat-dialog-title>
      <div class="row">
        <div class="col-8 d-flex justify-content-start">
          <h1>Task Details: {{selectedTask.task_name}}</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>

          <mat-icon color="accent">cancel</mat-icon>
        </div>
      </div>
    </div>

    <div class="card-body">


      <div class="row" *ngFor="let taskVariable of data.task_data">
        <div class="col">
          {{ taskVariable.name }}
        </div>
        <div class="col">
          {{ taskVariable.value }}
        </div>

      </div>


    </div>
  </div>
  <div class="p-3">
    <mat-dialog-actions align="end">
      <button color="accent" mat-button mat-dialog-close>Cancel</button>

    </mat-dialog-actions>
  </div>
</div>