<section>

    <div class="container" style="overflow: none;">
        <mat-tab-group>
            <mat-tab label="Dashboard">
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">dashboard</mat-icon>
                    Dashboard
                  </ng-template>
                <app-workflow-dashboard [dashboardParam]="getDashboardParam()"></app-workflow-dashboard>
            </mat-tab>
            <mat-tab label="Inbox">
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">inbox</mat-icon>
                    Inbox 
                  </ng-template>
                  <app-policy-consulting-inbox></app-policy-consulting-inbox>
               

            </mat-tab>

           
        </mat-tab-group>
    </div>
</section>