import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { BehaviorSubject, Observable, concat, every, first, of, take } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatDialogConfig
} from "@angular/material/dialog";
import { ViewFileComponent } from "./view-file/view-file.component";
import { AddKypComponent } from "./add-kyp/add-kyp.component";
import { AddAssignTaskComponent } from "../../../modal/add-assign-task/add-assign-task.component";
import { EditKypComponent } from "./edit-kyp/edit-kyp.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToastrService } from "ngx-toastr";
import { DashboardParam } from "src/app/model/dashboardParam";
import { ViewAddKypComponent } from "../../../modal/view-add-kyp/view-add-kyp.component";
import { ViewUpdateKypComponent } from "src/app/modal/view-update-kyp/view-update-kyp.component";
import { ViewReimbursementComponent } from "src/app/modal/view-reimbursement/view-reimbursement.component";
import { DataServiceService } from '../../../services/data-service.service';
import { WorkflowService } from "src/app/services/workflow.service";
import { DataShareModel } from "src/app/model/dataShareModel";

@Component({
  selector: "app-inbox",
  templateUrl: "./inbox.component.html",
  styleUrls: ["./inbox.component.scss"],
})


export class InboxComponent implements OnInit, AfterViewInit {

  @Input() model!: {};

  public dynamicFormGroup!: FormGroup;
  public fields: any = [];
  minDate: string;
  healthFiles: any;
  databody: any;
  results: any;
  sharedView: boolean = false;
  rolename: any;
  tasks: any[] = [];
  showDetails: boolean = true;
  policyMembers: any;
  displayedColumns: string[] = ['pendingTask', 'productName', 'orderDate', 'orderId', 'customerName', 'customerMobileNumber', 'assignedTo', 'action'];
  dataSource = new MatTableDataSource<any>(this.tasks);
  secondFormGroup = this._formBuilder.group({
    memberName: ["", Validators.required],
  });
  dashboardParams: DashboardParam = { "processType": "reimbursementProcessHobnob", "orgType": "BG" };

  allTasks:any;
  isLoading$ = new BehaviorSubject<boolean>(false);
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  constructor(
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    private dataService: DataServiceService,
    private workflowService:WorkflowService

  ) {
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;

    this.dataService.allTasksData$.subscribe((data: any) => {
      if (data != null && data.getProcessName() == "reimbursementProcessHobnob") {
        this.allTasks = data.tasks;
        this.dataSource.data =this.allTasks;
      }
    });
    // this.dataService.newTasksData$.subscribe((data: any) => {
    //   if (data.getProcessName() == "reimbursementProcessHobnob") {
    //     this.tasks = data.tasks;
    //   }
    //   console.log(this.dataSource.data);
    // });
    // this.dataService.assignedTasksData$.subscribe((data: any) => {
    //   if (data.getProcessName() == "reimbursementProcessHobnob") {
    //     this.assignedTasks = data.tasks;
    //   }
    //   console.log(this.dataSource.data);
    // });
  }
  ngAfterViewInit(): void {
    
    this.dataSource.paginator = this.paginator;
  }
  fileInfos?: Observable<any>;
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;

  faInbox = faInbox;
  today: Date = new Date();
  loading: any = false;
  error: any;
  message: any;

  user: any;

  getpolicyFiles: any = [];
  ngOnInit() {
    this.user = this.authService.userValue;
    this.getAllTasks();
    // this.getTasks();
    // this.getAssignedTasks();

  }

  formDataFieldOptions: any;
  getAllTasks() {
    this.workflowService
      .getAllTask("reimbursementProcessHobnob")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allTasks = data;
         const dataShare = new DataShareModel("reimbursementProcessHobnob",data);
          this.dataService.updateAllTasks(dataShare);
          
         // this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          //this.loading = false;
        },
      });
  }

  viewTask(element:any){
    const dialogRef = this._dialog.open(ViewReimbursementComponent,{
      disableClose:true,
      data:element,
      height:'80%',
      width:'80%'
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getAllTasks();
      }
    })
  }

  // getTasks() {
  //   var variables: any[][] = [[], []];
  //   this.workflowService
  //     .getNewTask("reimbursementProcessHobnob")
  //     .pipe(first())
  //     .subscribe({
  //       next: (data: any) => {
  //         this.tasks = data;
  //         this.dataSource.data = data;
  //         //console.log("tasks " + JSON.stringify(this.tasks));
  //         const dataShare = new DataShareModel("reimbursementProcessHobnob",data);

  //         this.dataService.updateNewTasks(dataShare);
  //         this.loading = false;
  //       },
  //       error: (error) => {
  //         this.error = error.error;
  //         this.message = undefined;
  //         console.log(error);
  //         this.loading = false;
  //       },
  //     });
  // }

  assignedTasks: any[] = [];
  // getAssignedTasks() {
  //   var variables: any[][] = [[], []];
  //   this.workflowService
  //   .getAssignedTask("reimbursementProcessHobnob")
  //     .pipe(first())
  //     .subscribe({
  //       next: (data: any) => {
  //         this.assignedTasks = data;
  //         const dataShare = new DataShareModel("reimbursementProcessHobnob",data);
  //         this.dataService.updateAssignedTasks(dataShare);
  //         console.log("assignedTasks " + JSON.stringify(this.assignedTasks));
  //         this.loading = false;
  //       },
  //       error: (error) => {
  //         this.error = error.error;
  //         this.message = undefined;
  //         console.log(error);
  //         this.loading = false;
  //       },
  //     });
  // }

  selectedTask: any;
  ekypdata: any;
  data: any;
  async selectTask(task: any) {
    console.log(task);
    this.selectedTask = task;
    if (this.selectedTask.name == "Process eKYP") {
      this.customerService.getPolicieByPolicyId(this.selectedTask.caseVariables.policyId).pipe(first())
        .subscribe({
          next: (data: any) => {
            this.getpolicyFiles = data;
            this.fields = [];
            this.buildForm(this.selectedTask);
            console.log(this.getpolicyFiles);
          },
          error: (error) => {
            this.error = error.error;
            this.message = undefined;
            console.log(error);
            this.loading = false;
          },
        });
    }
    else {
      this.getBPMNDiagram(task.id);
      this.fields = [];
      this.buildForm(this.selectedTask);
      if (this.selectedTask.policy) {
        this.ekypdata = JSON.parse(this.selectedTask.caseVariables.policy.ekypData);
        console.log(this.ekypdata);
        // const data = JSON.parse( this.selectedTask.ekyp_data);
        this.policyMembers = this.selectedTask.caseVariables.policy.policyMembers;
        console.log("policyMembers", this.policyMembers);
      }
      this.getHealthCloudFilesByUser(this.selectedTask.caseVariables.customer.id);
      this.getPolicyForUser(this.selectedTask.caseVariables.customer.id);
    }

  }

  
  selectedFile: any;
  selectHealthCloudFile(event: any): void {
    this.selectedFile = event.target.files;
  }


  uploadHealthCloudFilesForReimbursement() {
    this.progress = 0;
    if (this.selectedFile) {
      const file: File | null = this.selectedFile.item(0);

      if (file) {
        this.currentFile = file;

        this.customerService
          .uploadHealthFilesForUser(
            this.currentFile,
            this.selectedTask.caseVariables.customer.id,//this is incorrect 
            this.selectedCategory
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);

              } else if (event instanceof HttpResponse) {
                this.message = event.body.message;
                // this.getTasks();
                this.dataSource.paginator = this.paginator;
              }
            },
            error: (err: any) => {
              console.log(err);
              this.progress = 0;
              if (err.error && err.error.error) {
                this.error = err.error.error;
              } else {
                err = "Could not upload the file!";
              }

              this.currentFile = undefined;
            },
          });
      }

      this.selectedFiles = undefined;
    }
    //console.log("here", this.selectedCategory);
  }

  selectFileCategory(event: any): void {
    //console.log(event.target.value);
    this.selectedCategory = event.target.value;
  }
  pdfUrl: any;
  getPolicyFile(PolicyFileid: any): Observable<any[]> {
    return this.customerService.getPolicieByPolicyId(PolicyFileid).pipe(first());
  }

  getBPMNDiagram(taskId: any) {
    this.workflowService
      .getBPMNDiagram(taskId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {

          this.diagram = 'data:image/png;base64,' + data; // use this in <img src="..."> binding

          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  selectedCategory: any;
  selectCategory(event: any, name: any): void {
    console.log(name)
    if (name === "File Categories") {
      console.log(event.target.value);
      this.selectedCategory = event.target.value;
    }

  }

  next() {
    //console.log("Task " + JSON.stringify(this.tasks));
  }

  private buildForm(task: any) {
    const formGroupFields = this.getFormControlsFields(task);
    this.dynamicFormGroup = new FormGroup(formGroupFields);
  }

  private getFormControlsFields(task: any) {
    let formGroupFields: any = {};

    for (let form of task.formDataFieldOptions) {
      if (form.property.type.name == "date") {
        formGroupFields[form.property.id] = new FormControl(
          new Date().toISOString().substring(0, 10)
        );
      } else {
        formGroupFields[form.property.id] = new FormControl("");
      }

      this.fields.push({ ...form, fieldName: form.property.id });
      //console.log(form.id);
    }

    return formGroupFields;
  }

  executeTask() {
    let d = this.dynamicFormGroup.value;
    this.dynamicFormGroup.addControl("user", new FormControl(this.user));
    //console.log(JSON.stringify(this.dynamicFormGroup.value));

    if (this.selectedTask.assignee == null) {
      this.toastrService.error("Please accept or assign the task to execute");
    }
    this.workflowService
      .executeTask(this.selectedTask.id, this.dynamicFormGroup.value)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log("tasks " + JSON.stringify(data));
          this.loading = false;
          
          this.selectedTask = undefined;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  acceptTask(task: any) {
    //console.log(JSON.stringify(this.dynamicFormGroup.value));
    this.selectedTask = task;
    this.workflowService
      .assignTask(task.id, this.user.id, new Date().toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log("tasks " + JSON.stringify(data));
          this.loading = false;
          // this.getTasks();
          // this.getAssignedTasks();
          this.getAllTasks();
          this.selectedTask = undefined;
        },
        error: (error: { error: any; }) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  diagram: any;
  assignTask(data: any) {
    //console.log(JSON.stringify(this.dynamicFormGroup.value));
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
     // data: { data: data, roleName: 'HNB' },
     data:{data:data, roleName:['ADMIN','HNB']},

      width: '430px',
      height: '200'
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getAllTasks();
          // this.getTasks();
          // this.getAssignedTasks();
        }
      },
    });
    
  }

  getBenefits(benefits: any) {
    var ff = JSON.parse(benefits);
    return ff;
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  uploadHealthCloudFiles(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.adminService
          .uploadHealthFilesForUser(
            this.currentFile,
            this.selectedTask.caseVariables.customer.id,
            this.selectedCategory
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (event instanceof HttpResponse) {
                this.message = event.body.message;
                this.fileInfos = this.adminService.getProfilePic();
              }
            },
            error: (err: any) => {
              console.log(err);
              this.progress = 0;

              if (err.error && err.error.error) {
                this.error = err.error.error;
              } else {
                this.error = "Could not upload the file!";
              }

              this.currentFile = undefined;
            },
          });
      }

      // this.selectedFiles = undefined;
    }
  }

  async getHealthCloudFilesByUser(userId: any) {
    console.log(userId);
    // await this.loader.showLoading("Please wait. Looking for your files.");
    this.customerService.getHealthFilesByUserIdForAgent(userId).pipe(take(4))
      .subscribe({
        next: async (data: any) => {
          this.healthFiles = data;
          this.results = data;
          this.error = undefined;
          console.log("data", this.results.healthCloudModelFiles.length);
          // await this.loader.hideLoader();
        },
        error: async error => {
          this.error = error.error;
          console.log(error);
          // await this.loader.hideLoader();
        }
      });
  }

  policyFiles: any[] = [];
  async getPolicyForUser(userId: any) {
    // await this.loader.showLoading("Please wait. Looking for your files.");
    this.customerService.getPolicy(userId).pipe(take(4))
      .subscribe({
        next: async (data: any) => {
          this.policyFiles = data;
          console.log("data.here", this.policyFiles)
          this.error = undefined;
          console.log("data", this.policyFiles);
          // await this.loader.hideLoader();
        },
        error: async error => {
          this.error = error.error;
          console.log(error);
          // await this.loader.hideLoader();
        }
      });
  }

  openViewFile(metaData: any, fileType: any) {
    console.log(metaData);
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose:true,
      data: { fileMetaData: metaData, fileType: fileType },
      width: '90%',
      height: '70%',

    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) { }
      },
    });
  }

  categoryId: any;
  fileId: any;
  selectedPolicy(event: any) {
    this.fileId = event.id
    console.log(event)
  }

  selectedSummary(event: any) {
    this.categoryId = event.id
  }

  claimCheck: boolean = false;

  submitForClaimCheck() {
    this.claimCheck = true;
    console.log(this.claimCheck);
    this.isLoading$.next(true);
    this.customerService.claimCheck(this.categoryId, this.fileId)
      .subscribe((data: any) => {

        // this.progress = Math.round((100 * data.loaded) / data.total);
        console.log("data returned :" + JSON.stringify(data));
        console.log(this.claimCheck);
        this.databody = data.body;
        this.loading = false;
        // const tableBody = document.getElementById('tableData');
        const tableBody = document.getElementById('tableData');

        //  const tableRow = document.createElement('tr');
        for (const key in this.databody) {

          const tableRow = document.createElement('tr');

          // Create a table cell (TD) for the key
          const keyCell = document.createElement('td');
          keyCell.textContent = key;
          tableRow.appendChild(keyCell);

          // Create a table cell (TD) for the value
          const valueCell = document.createElement('td');
          valueCell.textContent = this.databody[key];
          tableRow.appendChild(valueCell);
          if (this.databody[key] === 'SUCCESS') {
            valueCell.style.color = 'green';
            valueCell.style.fontWeight = 'bold';

          }
          if (this.databody[key] === 'FAILURE') {
            valueCell.style.color = 'red';
            valueCell.style.fontWeight = 'bold';

          }
          tableBody?.appendChild(tableRow);



        }
        console.log(this.claimCheck);
        this.isLoading$.next(false);
        if (data.type != 0) {
          this.claimCheck = false;
        }

        console.log(this.claimCheck);

      })

  }

  addKYP = false;
  openAddForm(data: any, policyFile?: any) {
    console.log("data: " + JSON.stringify(data));
    if (this.memberName == null) {
      this.toastrService.error("Please select a policy to add/update KYP")
    } else {
      const dialogRef = this._dialog.open(ViewAddKypComponent, {
        disableClose:true,
        data: {
          policyId: data.policyNumber,
          userId: data.userId,
          policyPath: data.policyPath,
          caseId: data.id,
        },
        width: '80%',
        height: '80%'
      });
      dialogRef.afterClosed().subscribe({
        next: (val) => {

          if (val) {


          }
        },
      });
    }


  }

  parseId(id: string) {
    var policyName = id.split("-");
    var getName = policyName[policyName.length - 1].split("_");
    return getName[getName.length - 1];
  }
  memberName: any;
  onMemberChange(event: any) {
    this.memberName = event.value;

    console.log(this.memberName);
  }

  async getPolicyFileDisplay(id: any) {
    console.log("data");
    this.loading = true;
    await this.customerService
      .getPolicyFile(id)
      .subscribe({
        next: (data: Uint8Array) => {
          this.loading = false;

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = URL.createObjectURL(file);
          console.log(this.pdfUrl);
          window.open(this.pdfUrl, this.pdfUrl);

        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
        },
      });
  }



  openEditForm(policy: any, order_id: any, taskId: any) {
    console.log("open edit form");
    
    const dialogRef = this._dialog.open(ViewUpdateKypComponent, {
      disableClose:true,
      data: {
        policy: policy,
        policyId: policy.policyNumber,
        order_id: order_id,

        taskId: taskId

      },
      height: '80%',
      width: '80%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.selectedTask.caseVariables.policy = val;
          this.selectTask(this.selectedTask);
        }
      },
    });
  }

  openUnProcessedFile() {
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose:true,
      data: { fileMetaData: 'hello', fileType: 'unprocessed', id: '1' },
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) { }
      },
    });
  }

  getDashboardParam() {
    return JSON.stringify(this.dashboardParams);
  }

}


