  <div class="container">
    <div mat-dialog-title>
        <div class="row">
          <div class="col-8 d-flex justify-content-start">
            <h1>Process eKYP </h1>
          </div>
          <div class="col-4 d-flex justify-content-end " mat-dialog-close>
            <h4><i class="px-3" style="color:lightgray">* marked fields are mandatory </i></h4>
            <mat-icon color="accent">cancel</mat-icon>
          </div>
        </div>
      </div>
     


    <mat-tab-group>
   

      <mat-tab>
          <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">list</mat-icon>
              Task Details
          </ng-template>

          <div class="card-body" *ngIf="selectedTask != undefined">
            <ul>
              <li *ngIf="selectedTask?.description != null">
                <b>SOP:</b>
                <div >{{selectedTask?.description}} </div>
              </li>
              <li *ngIf="selectedTask?.dueDate != null">
                <b>TAT:</b>
                <span style="color: red">
                  {{ selectedTask.dueDate | date : "long" }}</span>
              </li>
              <li style="color: #c00000" *ngIf="selectedTask.caseVariables?.policyId == null">
                <b>Note:</b> The policy hasn't been uploaded!
              </li>
              <li style="color: #c00000" *ngIf="
                        selectedTask.caseVariables?.customerUserId == ''
                      ">
                <b>Note:</b> The member name has not been provided!
              </li>
            </ul>
            <hr />
            <div>
              <div *ngIf="!selectedTask.caseVariables?.customerUserId" style="margin-left: 10px;">
                <b>No customer details available</b>
              </div>
              <ul *ngIf="selectedTask.caseVariables?.customerUserId">
                <li>
                
                  <b>CUSTOMER:</b>
                  {{ selectedTask.caseVariables?.customerUserId?.firstName ?selectedTask.caseVariables.customerUserId.firstName :'' }}
                  {{ selectedTask.caseVariables?.customerUserId?.lastName ? selectedTask.caseVariables.customerUserId.lastName: ''}}
                </li>
                <li>
                  <b>Email:</b>
                  {{ selectedTask.caseVariables?.customerUserId?.emailId? selectedTask.caseVariables.customerUserId.emailId: '' }}
                </li>
                <li>
                  <b>Mobile:</b>
                  {{
                  selectedTask.caseVariables?.customerUserId?.mobilePhone ?selectedTask.caseVariables.customerUserId.mobilePhone :''
                  }}
                </li>
              </ul>

              

              <hr />
              <ng-container *ngIf="getpolicyFiles != null">
                <ul >
                  <li>
                    <b>POLICY:</b>
                    {{ getpolicyFiles?.policyNumber }}
                  </li>
                  <li>
                    <b>Policy Link:</b>
                    {{ getpolicyFiles?.policyPath }}
                  </li>
                  <!-- <li>
                    <b>eKYP:</b>
                    
                    <div class="row">
                      <div class="col">
                        
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                       
                      </div>
                    </div>
  
                    
                    
                  </li> -->
                 
                </ul>
                <hr>
              </ng-container>
             
            </div>
          </div>
      </mat-tab>

      <mat-tab>
          <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">cloud_upload</mat-icon>
              Uploaded Polices
          </ng-template>
         
          <div class="card p-4 m-2 overflow-auto w-10 custom-scrollbar">
            <div class="row"
              (click)="openViewFile(selectedTask.caseVariables?.customerUserId?.id ,getpolicyFiles, 'POLICY','viewFile')">
              <div class="your-item-style">
                <div class="col-1">
                  <mat-icon>assignment</mat-icon>

                </div>
                <div class="col-11">
                  <strong>Policy</strong>
                  <p>

                    PDF
                  </p>
                </div>
              </div>


            </div>
          </div>
  

      </mat-tab>
      <mat-tab>
          <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">attach_file</mat-icon>
              eKYP
          </ng-template>
          <div class="card p-2 m-2"
            *ngIf="getpolicyFiles != null && getpolicyFiles.policyNumber == 'Processing Failed' && selectedTask != undefined">
            <div class="card-body">
              <h3 class="card-title">KYP</h3>

             
              <div class="addbtn">
                <button type="button" *ngIf="getpolicyFiles.ekypData == null" class="btn btn-outline-primary"
                  (click)="openViewFile(selectedTask.caseVariables?.customerUserId?.id , getpolicyFiles, 'POLICY','addEKYP')">
                  Add
                 
                </button>

                <button *ngIf="getpolicyFiles.ekypData != null" type="button" class="btn btn-outline-primary"
                  (click)="
                          openEditForm(getpolicyFiles.ekypData)">
                  Update
                </button>
              </div>

            </div>
          </div>

      </mat-tab>

  </mat-tab-group>


    <!-- policy member list  -->
     <mat-accordion *ngIf="policyMemberList.length>0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-expansion-title>Policy Members</mat-expansion-title>
          </mat-expansion-panel-header>
          <mat-table [dataSource]="dataSource">

            <ng-conatiner matColumnDef="memberName">
              <mat-header-cell *matHeaderCellDef>Member Name</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.memeberName}}</mat-cell>
            </ng-conatiner>

            <ng-conatiner matColumnDef="dob">
              <mat-header-cell *matHeaderCellDef>Date of Birth</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.memberDOB | date}}</mat-cell>
            </ng-conatiner>
            <ng-conatiner matColumnDef="isHolder">
              <mat-header-cell *matHeaderCellDef>Holder</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.holder}}</mat-cell>
            </ng-conatiner>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>

          </mat-table>
        </mat-expansion-panel>
      </mat-accordion>
    <div class="row">
      <div class="col">
        <div *ngIf="selectedTask != undefined && selectedTask.name =='Process eKYP'">


          <div class="card p-2 m-2">
            <form [formGroup]="dynamicFormGroup" *ngIf="dynamicFormGroup != undefined">
              <div *ngFor="let field of fields">
                <div class="row m-2 p-2" *ngIf="field.property.type.name == 'boolean'">
                  <div class="col">
                    <b>{{ field.property.name }}</b>
                  </div>
                  <div class="col">
                    <select [formControlName]="field.property.id" class="form-select"
                      aria-label="Default select example" required="true" [value]="false">
                      <option value="true">Accept</option>
                      <option value="false">Reject</option>
                    </select>
                  </div>
                </div>
                <div class="row m-2 p-2" *ngIf="
                          field.property.type.name == 'string' &&
                          field.property.id != 'fileUpload'
                        ">
                  <div class="col">
                    <b>{{ field.property.name }}</b>
                  </div>
                  <div class="col">
                    <textarea class="form-control" required="true" [formControlName]="field.property.id"></textarea>
                  </div>
                </div>

                <div class="row m-2 p-2" *ngIf="
                          field.property.type.name == 'string' &&
                          field.property.id == 'fileUpload'
                        ">
                  <div class="col">
                    <b>{{ field.property.name }}</b>
                  </div>
                  <div class="col">
                    <label class="btn btn-default p-0">
                      <input type="file" [formControlName]="field.property.id" (change)="selectFile($event)" />
                    </label>
                    <ul class="list-group list-group-flush">
                      <li *ngFor="let file of fileInfos | async" class="list-group-item">
                        <a href="{{ file.url }}">{{ file.name }}</a>
                      </li>
                    </ul>
                    <div>
                      <button class="btn btn-success btn-sm" [disabled]="
                                selectedFiles == undefined ||
                                selectedCategory == undefined
                              " (click)="uploadHealthCloudFiles()">
                        Upload
                      </button>
                    </div>
                    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </div>
                <div class="row m-2 p-2" *ngIf="field.property.type.name == 'date'">
                  <div class="col">
                    <b>{{ field.property.name }}</b>
                  </div>
                  <div class="col">
                    <input type="date" class="form-control" required="true" [formControlName]="field.property.id"
                      [min]="minDate" />
                  </div>
                </div>

                <div class="row m-2 p-2" *ngIf="field.property.type.name == 'enum'">
                  <div class="col">
                    <b>{{ field.property.name }}</b>
                  </div>
                  <div class="col">
                    <select [formControlName]="field.property.id" class="form-select"
                      aria-label="Default select example" required="true" (change)="
                              selectCategory($event, field.property.name)
                            ">
                      <option value="" selected disabled>
                        Update status
                      </option>
                      <option *ngFor="let option of field.values" [value]="option.id">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button type="button" class="btn btn-danger" [disabled]="
                          dynamicFormGroup != undefined &&
                          dynamicFormGroup.invalid
                        " (click)="executeTask()">
                  Execute
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  
  