<div class="container pt-3 text-center ">

    <img width="10%" class="d-block mx-auto mb-3" src="../../assets/img/enterprise.png" alt="">

    <h1 class="display-5 fw-bold">Bima<span style="color:#C00000;">Garage</span> HobNob</h1>

    <div class="col-lg-6 mx-auto">

        <p class="lead mb-4"><span><h2>eKYP</h2></span>You can upload your policies, analyze them and be prepared!</p>
        <p><button mat-raised-button color="danger" style="background-color:#C00000;color:white;">Know your policy</button></p>
        <p class="lead mb-4"><span><h2>ICA Plan</h2></span>
            Immediate Claim Assistance (ICA) is an one-time registration add-on for customers who wish to avail our claim processing service on their past claims (closed or ongoing).
            
            Customers can avail this add-on to get claim support on any number of their old/ongoing claims, be it health/accidental claims, death claims or overseas travel claims. Both ICA plans include all the standard benefits and are charged only for the first year. Customer will be moved to a standard plan completion of one year.
            
            The standard plan of HobNob does not provide claim assistance of any claims from before the date of subscription/payment of the customer.</p>
            <button mat-raised-button color="danger" style="background-color:#C00000;color:white;">Claim Now!</button>
    </div>

</div>