import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AgChartOptions } from 'ag-charts-community';
import { DashboardService } from 'src/app/services/dashboard.service';
import { first, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { faMagnifyingGlassChart, faClock, faFlagCheckered, faFilterCircleXmark, faGaugeSimple } from '@fortawesome/free-solid-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrentStatusComponent } from 'src/app/cashless/timeline/current-status/current-status.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CashflowService } from 'src/app/services/cashflow.service';
import { PreauthComponent } from 'src/app/pdf/cashless/preauth/preauth.component';
import { AuthService } from 'src/app/services/authService';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-workflow-dashboard',
  templateUrl: './workflow-dashboard.component.html',
  styleUrls: ['./workflow-dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class WorkflowDashboardComponent implements OnInit, AfterViewInit, OnDestroy ,OnChanges{
  dt:any = new Date();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatPaginator) completedPaginator!: MatPaginator;
  dateForm: FormGroup;
  loadTATs:boolean = false;
  loadCompletedTATs:boolean = false;
  message: any;
  selectedTask:any;
  completedSelectedTask:any;
  error: any;
  rowNum =0;
  size =100;
  loading: any = false;
  dashboardData:any=[];
  length:any=0;
  user:any;
  temporaryDataSource:any=[];
  @Input() public dashboardParam:any;
  @Input() isActive!: boolean;
  public options: AgChartOptions;
  public optionsCompleted: AgChartOptions;
  private unsubscribe = new Subject<void>();
  faClock = faClock;
  faGaugeSimple=faGaugeSimple;
  chartDataSubject = new Subject<any>();
  chartDataCompletedSubject = new Subject<any>();
  dataSet:any=[];
  selectedHospital:any;
  hospitalList:any =[];
  dataSource = new MatTableDataSource() ;
  
  dataSourceCompleted = new MatTableDataSource();
  
  columnsToDisplay = ['taskName', 'count'];
  displayedColumns = ['caseId','assignee','startDate','endDate','dueDate','dueInDays','status'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any | null;

  constructor(
    private _fb: FormBuilder,
     public dashboard: DashboardService,
    public _dialog: MatDialog,
    private cashlessService:CashflowService,
    private authService:AuthService,
    private toastrService:ToastrService,
    private cdr : ChangeDetectorRef) {
      //this.dataSource = this.dashboardData;
    console.log("#####  "+this.dashboardParam);
    this.dt.setMonth(this.dt.getMonth() - 3);
    this.authService.user.subscribe((x: any) => {
      this.user = x;

    });

    this.dateForm = this._fb.group({
      
      fromDate: [this.dt, Validators.required],
     });
    this.options = {
      data: [{asset:"data",amount:3}],
      series: [
        {
          type: 'pie',
          angleKey: 'amount',
          calloutLabelKey: 'asset',
          sectorLabelKey: 'amount',
          sectorLabel: {
            color: 'white',
            fontWeight: 'bold',
          },
        
        listeners: {
          nodeClick: (event:any) => {
            var datum = event.datum;
            this.loadTATs = true;
            this.getTATDetails(datum.asset, this.dateForm.get('fromDate')?.value.toISOString(),"inProgress",this.selectedHospital.hospitalOrgId);
            this.selectedTask = datum.asset;
            // window.alert(
            //   "Selected Asset: " +
            //     datum.asset + // Correct key for the pie chart
            //     "\nAmount: " +
            //     datum.amount // Correct key for the pie chart
            // );
          },
        }
      },
      ],
     
    };

    this.optionsCompleted = {
      data: [{asset:"data",amount:3}],
      series: [
        {
          type: 'pie',
          angleKey: 'amount',
          calloutLabelKey: 'asset',
          sectorLabelKey: 'amount',
          sectorLabel: {
            color: 'white',
            fontWeight: 'bold',
          },
          listeners: {
            nodeClick: (event:any) => {
              var datum = event.datum;
              this.loadCompletedTATs = true;
              this.completedSelectedTask = datum.asset;
              this.length = datum.amount
              this.getTATDetails(datum.asset, this.dateForm.get('fromDate')?.value.toISOString(),"",this.selectedHospital.hospitalOrgId);
             
            },
          }
        },
        
      ],
    };

    this.chartDataSubject.subscribe((dt:any) =>{
      this.dataSet = dt;
      this.options.data = dt
//console.log("DATA SET " + JSON.stringify(this.options));
      this.options = {
        data: this.dataSet,
        series: [
          {
            type: 'pie',
            angleKey: 'amount',
            calloutLabelKey: 'asset',
            sectorLabelKey: 'amount',
            
            sectorLabel: {
              color: 'white',
              fontWeight: 'bold',
            },
          },
        ],
        width: 800,
        height: 600, 
      };
    });
    this.chartDataCompletedSubject.subscribe((dt:any) =>{
      
      this.optionsCompleted = {
        data: dt,
        series: [
          {
            type: 'pie',
            angleKey: 'amount',
            calloutLabelKey: 'asset',
            sectorLabelKey: 'amount',
            sectorLabel: {
              color: 'white',
              fontWeight: 'bold',
            },
          },
        ],
       
        width: 800,
        height: 600, 
      };
    });

    
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.isActive){
      this.dt= new Date();
      this.dt.setMonth(this.dt.getMonth() - 3);
      console.log(this.isActive)
      console.log(this.dt)
      console.log("input data:"+this.dashboardParam);
      this.getOnboardedHospitals();
      // this.getDashboard(this.dt.toISOString(), );
      // this.dataSource.paginator = this.paginator;
      // this.dataSourceCompleted.paginator = this.completedPaginator;
    }
   
  }
  ngOnDestroy(): void {
    console.log("destroyed")
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getDateDifference(startDate: string, endDate: string): number {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime()); // Difference in milliseconds
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
  }

  
  onChangeEvent(event: { value: any; }){
    //console.log(event);
    this.dt = new Date(event.value).toISOString();
//console.log(new Date(event.value));
    if(this.selectedHospital === "ALL"){
      this.getDashboard(this.dt , "");
    }else if(this.selectedHospital != "ALL"){
      this.getDashboard(this.dt , this.selectedHospital.hospitalOrgId);
    }
    
  }

  getTATDetails(taskName:any, date:any,status:any, hospitalOrgId:any){
    hospitalOrgId = hospitalOrgId? hospitalOrgId: "";
    console.log(taskName);
    console.log(date);
      this.dashboard.getDashboardDetails(this.dashboardParam.processType,date,taskName,status, this.rowNum, this.size, hospitalOrgId)
      .subscribe({
        next: (data: any) => {
          console.log("TAT Details: ", data);
          if(status == "inProgress"){
            this.dataSource.data = data.result;
            this.dataSource.paginator = this.paginator;
          }else{
            this.dataSourceCompleted = data.result; 
            this.dataSourceCompleted.paginator = this.completedPaginator; 
            // console.log(this.dataSourceCompleted.data);
            // this.cdr.detectChanges(); 
           // this.dataSourceCompleted.paginator = this.completedPaginator;
          }
        
        },
        error: (error: any) => {
          console.log("Error fetching TAT details: ", error);
          this.loadTATs = false;
        }
      })
  }
  
  ngAfterViewInit(): void {
    console.log("##### AFTER VIEW INIT "+this.dashboardParam);
    this.dashboardParam = JSON.parse(this.dashboardParam);
    this.dataSource.paginator = this.paginator;
    this.dataSourceCompleted.paginator = this.paginator;
  }
  ngOnInit(): void {
  //   console.log(this.isActive)
  //   console.log(this.dt)
  //   console.log("input data:"+this.dashboardParam);
  //   this.dt = this.getDashboard(this.dt.toISOString());
  //   this.getDashboard(this.dt);
  //   if(this.isActive){
  //     this.dt = this.getDashboard(this.dt.toISOString());
  //     this.getDashboard(this.dt);
  //     console.log("input data:"+this.dashboardParam);
  //     // this.getDashboard(this.dt.toISOString());
  //   // timer(0,3000000).pipe(
  //   //   //tap((x)=> console.log(x)),
  //   //   takeUntil(this.unsubscribe),
  //   //   switchMap(async () => this.getDashboard(this.dt.toISOString()))
  //   // ).subscribe();

  //   //this.getCashlessDashboard();
    
  // }
}


 
  avgDurationCompletedProcesses:number = 0;
  getDashboard(dt:any,hospitalOrgId:any){
    
    this.dashboard
      .getDashboardHeader(this.dashboardParam.processType, dt,hospitalOrgId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
        console.log(data.tasksInProgress.length);
          this.dashboardData = data;
          this.temporaryDataSource = data;
         // this.dataSource = data.tasksInProgress;
          this.dataSourceCompleted = data.tasksCompleted
          var info:any[]=[];
          var infoCompleted:any[]=[];
          
          for (var x = 0; x < data.tasksInProgress.length; x++) {
            var d = data.tasksInProgress[x];
            var name = d.taskName;
            var count = d.count;
            info.push({ asset: name, amount: count });
          }

          for (var x = 0; x < data.tasksCompleted.length; x++) {
            var d = data.tasksCompleted[x];
            var name = d.taskName;
            var count = d.count;
            infoCompleted.push({ asset: name, amount: count });
          }
          // var avgCount = 0 ;
          // for (var x = 0; x < data.completedInstances.length; x++) {
          //   var d = data.completedInstances[x];
          //   avgCount += d.duration;
          // }
          // if(avgCount != 0){
          //   this.avgDurationCompletedProcesses = (avgCount/(data.completedInstances.length*60*60*100));
          // }
          

          this.loading = false;
          this.chartDataSubject.next(info);
          this.chartDataCompletedSubject.next(infoCompleted);

        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  getStatus(startDate:any,dueDate:any){
    if(dueDate  < new Date().getTime()){
      return "Overdue";
    }else{
      return "Good to go";
    }
  }

  viewActivityTab(processInstanceId:any){
    let dialogRef = this._dialog.open(CurrentStatusComponent,{
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%',
      data: {
        processInstanceId : processInstanceId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  
  }
  viewCaseDetails(selectedCase: any) {
    console.log(selectedCase);
    let dialogRef = this._dialog.open(CurrentStatusComponent, {
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'full-width-dialog',
      height: '100%',
      data: {
        processInstanceId:selectedCase.processInstId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    console.log(selectedCase);

  }
  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    return dd;
  }

  getOnboardedHospitals() {
    if (this.isPrivileged(["CAN_VIEW_HOSPITAL"])) {
      this.cashlessService.getOnboardedHospitalByUser()
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            console.log("Selected hospital is :" + JSON.stringify(data));
            this.hospitalList = data;
          },
          error: (error:any) => {
            console.log(error);
          }
        })
    }
    else {
      this.cashlessService.getOnboardedHospitals()
        .pipe(first())
        .subscribe({
          next: (hospitals: any) => {
            this.hospitalList = hospitals;
            console.log(this.hospitalList);

          },
          error: (error:any) => {
            console.log("error " + JSON.stringify(error));
          }
        })
    }
  }

  getDashboardData(event:any){
    console.log(this.selectedHospital)
      this.dt= new Date();
      this.dt.setMonth(this.dt.getMonth() - 3);
    if(this.selectedHospital === "ALL"){
      this.getDashboard(this.dt.toISOString(),"");
    }else if(this.selectedHospital){
      this.getDashboard(this.dt.toISOString(),this.selectedHospital.hospitalOrgId);
    }
  }
}
