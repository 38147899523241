import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, first, Observable, take } from 'rxjs';
import { AddAssignTaskComponent } from 'src/app/modal/add-assign-task/add-assign-task.component';
import { ViewAddKypComponent } from 'src/app/modal/view-add-kyp/view-add-kyp.component';
import { DashboardParam } from 'src/app/model/dashboardParam';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { CashflowService } from 'src/app/services/cashflow.service';
import { CustomerService } from 'src/app/services/customer.service';
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { ViewFileComponent } from 'src/app/portal/bg/inbox/view-file/view-file.component';
import { EditKypComponent } from 'src/app/portal/bg/inbox/edit-kyp/edit-kyp.component';
import {faClock} from "@fortawesome/free-solid-svg-icons";
import { ViewProcessEkypComponent } from 'src/app/modal/view-process-ekyp/view-process-ekyp.component';
import { DataServiceService } from 'src/app/services/data-service.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { DataShareModel } from 'src/app/model/dataShareModel';


@Component({
  selector: 'app-assignment-ekyp',
  templateUrl: './assignment-ekyp.component.html',
  styleUrls: ['./assignment-ekyp.component.scss']
})
export class AssignmentEkypComponent implements OnInit{
  @Input() model!: {};
  faClock = faClock;

  //ekypProcess
  dashboardParams: DashboardParam = { "processType": "ekypProcess", "orgType": "BG" };

  public dynamicFormGroup!: FormGroup;
  public fields: any = [];
  minDate: string;
  healthFiles: any;
  databody: any;
  results: any;
  sharedView: boolean = false;
  rolename: any;
  tasks: any[] = [];
  showDetails: boolean = true;
  policyMembers: any;
  displayedColumns: string[] = ['pendingTask', 'orderDate', 'orderId', 'customerName', 'customerMobileNumber', 'assignedTo', 'action'];
  //displayedColumns: string[] = ['pendingTask','productName', 'orderDate', 'orderId', 'customerName','customerMobileNumber','action'];
  dataSource = new MatTableDataSource<any>(this.tasks);
  secondFormGroup = this._formBuilder.group({
    memberName: ["", Validators.required],
  });

  isLoading$ = new BehaviorSubject<boolean>(false);
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  assignedTasks:any=[];

  loading: any = false;
  error:any;
  message:any;
  user:any;
  selectedTask: any;
  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private cashlessService: CashflowService,
    private toastrService: ToastrService,
    private _formBuilder: FormBuilder,
    private dataService:DataServiceService,
    private workflowService:WorkflowService

  ) {
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;
    this.authService.user.subscribe(x => this.user = x);
    this.dataService.newTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "ekypProcess") {
        this.tasks = data.tasks;
      }

      console.log(this.dataSource.data);

    });
    this.dataService.assignedTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "ekypProcess") {
        this.assignedTasks = data.tasks;
      }

      //this.dataSource.data = this.newTasks[0];
      console.log(this.dataSource.data);

    });
    
    //
  }
  ngOnInit(): void {
    this.getTasks();
    this.getAssignedTasks();
  }
  getAssignedTasks() {
    console.log("in get all assigned ");
    this.workflowService
    .getAssignedTask("ekypProcess")
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        const dataShare = new DataShareModel("ekypProcess",data);
       
        this.dataService.updateAssignedTasks(dataShare);
        this.assignedTasks = data;
        console.log("assignedTasks " + JSON.stringify(this.assignedTasks));
        this.loading = false;


      },
      error: (error) => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      },
    });
    
  }

  
  assignTask(data:any)
  {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data:{data:data, roleName:['ADMIN','CASHLESS','HNB']},
      width:'430px', 
      height:'200'
    });
    dialogRef.afterClosed().subscribe({
      next: (val:any) => {
        if (val) {
          this.getAssignedTasks();
          this.getTasks();
        }
      },
    });
  }

  isPrivileged(privilege:string[]){
    const privileges:any[] = this.user.privileges;
    var dd =  privileges.some((x: string) => privilege.includes(x));
//console.log("is Privileged "+ this.user.privileges);
    return dd;
  }
  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    console.log(this.user.roles);
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ dd);
    return dd;
  }

 

  viewTask(element:any){
    this.selectedTask = element;
    console.log("here",this.selectedTask);
    if(this.selectedTask.assignee == undefined){
     // this.toastrService.info("Please accept or assign the task to view");
  
    }else{
      const dialogRef = this._dialog.open(ViewProcessEkypComponent, {
        disableClose:true,
        data: this.selectedTask,
        width: '100vw',  // Sets the width to 100% of the viewport width
        maxWidth: '100vw',  // Ensures the max width doesn't override the width
        panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
        height: '100%'
      });
      dialogRef.afterClosed().subscribe({
        next: (val:any) => {
          
          this.getTasks();
          this.getAssignedTasks();
        },
      });
    }
  
  }


  getTasks() {
    this.workflowService
      .getNewTask("ekypProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          const dataShare = new DataShareModel("ekypProcess",data);
          this.dataService.updateNewTasks(dataShare);
        //  this.dataSource.data = this.tasks;
          //console.log("tasks " + JSON.stringify(this.tasks));
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  getDashboardParam() {
    return JSON.stringify(this.dashboardParams);
  }

  
  escalation(dueDate:number){
    const currentDate = (new Date()).getTime();
    if(dueDate >= currentDate){
      return false;
    }else{
      return true;
    }

  }

}