import { Component, Inject, Input, ViewChild } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { first } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { UpdateMailParserConfsComponent } from "./update-mail-parser-confs/update-mail-parser-confs.component";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { AddMailParserConfsComponent } from "./add-mail-parser-confs/add-mail-parser-confs.component";


@Component({
  selector: 'app-view-mail-parser-confs',
  templateUrl: './view-mail-parser-confs.component.html',
  styleUrl: './view-mail-parser-confs.component.scss'
})

export class ViewMailParserConfsComponent {
  masterEmailConfig: any =[];
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];
  message: any;

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  dataSource = new MatTableDataSource<any>(this.masterEmailConfig);
  displayedColumns: string[] = [
    "hospitalOrgId",
    "receivingEmailHost",
    "receivingEmailId",
    "receivingEmailStoreType",
    "update",

  ];
  loading: boolean = false;
  @Input() hospitalOrgId: any ;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private adminService: AdminService,
    private hospitalRegistration: HospitalRegistrationService,
    private _dialog: MatDialog,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<ViewMailParserConfsComponent>,

 //  @Inject(MAT_DIALOG_DATA) public data: any,
  
  ) { }

  ngOnInit(): void {
    console.log(this.hospitalOrgId)
  //  console.log("Data",JSON.stringify(this.data));
    this.getEmailConfig(this.hospitalOrgId);
  }


  getEmailConfig(id: any) {
    this.hospitalRegistration.getReceiverEmailConfig(id).subscribe({
      next: (data: any) => {
        console.log(JSON.stringify(data));
        this.masterEmailConfig = data;
        this.dataSource.data = this.masterEmailConfig;

      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error));
      },
    });
  }

  update(data: any) {
    const dialogRef = this._dialog.open(UpdateMailParserConfsComponent, {
      data: { config:data , type:'receiver'},
      width: "90%",
      height: "70%",
      disableClose:true,

    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getEmailConfig(this.hospitalOrgId);
        }
      },
    });
  }
  

  add(data: any) {
    const dialogRef = this._dialog.open(AddMailParserConfsComponent, {
      data: { 
        insurance: data,
        type:'receiver'
    },
      width: "90%",
      height: "70%",
      disableClose:true,

    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getEmailConfig(this.hospitalOrgId);
        }
      },
    });
  }


}
