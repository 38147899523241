import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";
import { HealthCloudFileComponent } from "./health-cloud-file/health-cloud-file.component";
import { FormControl, FormGroup } from "@angular/forms";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-health-cloud",
  templateUrl: "./health-cloud.component.html",
  styleUrls: ["./health-cloud.component.scss"],
})
export class HealthCloudComponent {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  insuranceData: any = [];
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  displayedColumns: string[] = ["category", "dateCreated", "mediaType","View"];
  healthFiles: any;
  results: any;
  error: undefined;
  user: any;
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message: any;
  fileInfos: any;
  selectedCategory: any;
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent: PageEvent = new PageEvent();
  

  constructor(
    public customerService: CustomerService,
    private authService: AuthService,
    private _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.user = this.authService.userValue;
    const userId = this.user.id;
    this.getHealthCloudFilesByUser(userId);
    // this.selectTask(task);
  }

  async getHealthCloudFilesByUser(userId: any) {
    // await this.loader.showLoading("Please wait. Looking for your files.");
    this.customerService
      .getHealthFiles()
      // .pipe(take(4))
      .subscribe({
        next: async (data: any) => {
          console.log("data", data);
          this.healthFiles = data.healthCloudModelFiles;
          //this.results = data.healthCloudModelFiles;
          this.dataSource.data = data.healthCloudModelFiles;

          this.error = undefined;
          // await this.loader.hideLoader();
        },
        error: async (error) => {
          this.error = error.error;
          console.log(error);
          // await this.loader.hideLoader();
        },
      });
  }

  
  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.dataSource.data  = query === ""?  this.dataSource.data :  this.dataSource.data.filter((item:any) =>
      item.category.toLowerCase().includes(query.toLowerCase()));
    } else {
        this.getHealthCloudFilesByUser(this.user.id);
    }
     
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.getHealthCloudFilesByUser(this.user.id);
  }

  openViewFile(data: any) {
    const dialogRef = this._dialog.open(HealthCloudFileComponent, {
      data,
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
    // console.log(data);
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  selectCategory(event: any): void {
    //console.log(event.target.value);
    this.selectedCategory = event.target.value;
  }

  uploadHealthCloudFiles(): void {
    this.progress = 0;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.customerService
          .uploadHealthFilesForUser(
            this.currentFile,
            this.user.id,
            this.selectedCategory
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);

              } else if (event instanceof HttpResponse) {
                this.message = event.body.message;
                window.location.reload();
              }
            },
            error: (err: any) => {
              console.log(err);
              this.progress = 0;
              if (err.error && err.error.error) {
                this.error = err.error.error;
              } else {
                err = "Could not upload the file!";
              }

              this.currentFile = undefined;
            },
          });
      }

      this.selectedFiles = undefined;
    }
    //console.log("here", this.selectedCategory);
  }

  
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    //this.getHealthCloudFilesByUser(this.userId);  }
}
}
