import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router, NavigationExtras } from "@angular/router";
import { first } from "rxjs/operators";
import { AddAssignTaskComponent } from "src/app/modal/add-assign-task/add-assign-task.component";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { FormComponent } from "../case/form/form.component"
import { AuthService } from "src/app/services/authService";
import { ToastrService } from "ngx-toastr";
import { DataServiceService } from "src/app/services/data-service.service";
import { WorkflowService } from "src/app/services/workflow.service";
import { CreatecaseComponent } from "../createcase/createcase.component";
import { DataShareModel } from "src/app/model/dataShareModel";


@Component({
  selector: 'app-cashless-inbox',
  templateUrl: './cashless-inbox.component.html',
  styleUrls: ['./cashless-inbox.component.scss']
})
export class CashlessInboxComponent {

  dateVal = new Date();
  createCase!: FormGroup;
  selectedoption = null;
  DoYouhaveFamilyPhysician = null;
  newTasks: any[] = [];
  loading: any = false;
  error: any;
  message: any;
  form: FormGroup = this.formBuilder.group({});
  displayedColumns: string[] = [
    "currenttask",
    "orderDate",
    "orderId",
    "customer",
    "hospital",
    "assignTo",
    "action",
  ];
  dataSource = new MatTableDataSource<any>(this.newTasks);
  public dynamicFormGroup!: FormGroup;
  public fields: any = [];

  policyMembers: any;
  user: any;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  constructor(
    private createCaseService:CreatecaseComponent,
    private dataService: DataServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private cashlessService: CashflowService,
    private customerService: CustomerService,
    private authService: AuthService,
    private _dialog: MatDialog,
    private workflowService:WorkflowService,
    private toastrService: ToastrService
  ) {
    this.dataService.newTasksData$.subscribe((data: any) => {
      if(data.getProcessName() == "cashless"){
        this.newTasks = data;
      }
      
      this.dataSource.data = data;
      
    });
  }

  ngOnInit(): void {
    this.authService.user.subscribe(x => {
      this.user = x;

    });

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  assignTask(data: any) {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data: { data: data, roleName:['ADMIN','CASHLESS'] },
      width: '60%',
      
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if(val == "ASSIGNED"){
          console.log("ASSIGNED");
          this.getNewTasks();
          this.getAssignedTasks();
        }
      },
    });

  }


  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    console.log(this.user.roles);
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ dd);
    return dd;
  }

  applyFilter(event: any) {

    // const filterValue = (event.target as HTMLInputElement).value;
    const filterValue = event.target.value.toLowerCase();
    if (filterValue !== "") {
      //  this.dataSource.filter = filterValue.trim().toLowerCase();
      this.dataSource.data = this.dataSource.data.filter((item: any) => {
        return Object.values(item).some((val) =>
          String(val).toLowerCase().includes(filterValue)
        );
      });
    } 
  }

  selectedTask: any;

  acceptTask(task: any) {
    this.selectedTask = task
    this.workflowService.assignTask(this.selectedTask.id, this.user.id, new Date().toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.getNewTasks();
          this.getAssignedTasks();
          this.createCaseService.updateSelectedTab(2);
        },
        error: error => {
          console.error('Error', error);
        }

      });
  }

 

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  getNewTasks() {
    this.workflowService
      .getNewTask("cashlessProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.newTasks = data;
          console.log("newTasks " + JSON.stringify(this.newTasks));
          const dataShare = new DataShareModel("cashlessProcess",data);
          this.dataService.updateNewTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  getAssignedTasks() {
    this.workflowService
      .getAssignedTask("cashlessProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.newTasks = data;
          console.log("assignedTasks " + JSON.stringify(this.newTasks));
          const dataShare = new DataShareModel("cashlessProcess",data);
          this.dataService.updateAssignedTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }
}
