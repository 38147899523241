import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-service-level-aggrement',
  templateUrl: './service-level-aggrement.component.html',
  styleUrls: ['./service-level-aggrement.component.scss']
})
export class ServiceLevelAggrementComponent {
  pdf =   '../../../../../assets/pdfs/Updated Customer-BG SLA (1).pdf';
  tc:any;

  constructor(
    public dialogRef: MatDialogRef<ServiceLevelAggrementComponent>){

  }

  confirm(value:any){

    if(value == 'AGREE'){
      this.tc = true;
      this.dialogRef.close(this.tc);
    }else{
      this.tc = false;
      this.dialogRef.close(this.tc);
    }

  }

}
