import { Component } from '@angular/core';

@Component({
  selector: 'app-reg-selection',
  templateUrl: './reg-selection.component.html',
  styleUrls: ['./reg-selection.component.scss']
})
export class RegSelectionComponent {

}
