<div class="overlay" *ngIf="loadingForm">
    <div class="spinner"></div>
  </div>
<div class=" p-3">
    <div class="row p-3">
        <div class="col text-end">
            <!-- <button class="btn" style="color:#8e0101;" (click)="downloadPDF()">Download PreAuth <mat-icon
                    aria-hidden="false" aria-label="Download PreAuth" fontIcon="download"></mat-icon></button> -->
            <button mat-dialog-close style="color:white;background:#8e0101;"
                mat-mini-fab><mat-icon>cancel</mat-icon></button>
        </div>
    </div>
    <div id= "preauthContent" >
        <div class="row pt-3">
            <div class="col-3 hr-line">
                &nbsp;
            </div>
            <div class="col-6 text-center">
                <h4 style="font-weight: bold;text-decoration: underline; text-decoration-thickness: 2px">REQUEST FOR
                    CASHLESS HOSPITALIZATION FOR HEALTH INSURANCE</h4>
            </div>
            <div class="col-3 hr-line">

            </div>

        </div>
        <div class="row p-2">
            <div class="col-3 hr-line">
                &nbsp;
            </div>
            <div class="col-6 text-center">
                <h4 style="font-weight: bold;">POLICY PART-C(Revised)</h4>
            </div>
            <div class="col-3 hr-line">

            </div>

        </div>
        <div class="row p-2 ">
            <div class="col-3 hr-line">
                &nbsp;
            </div>
            <div class="col-4 text-center">

            </div>
            <div class="col-5 hr-line">
                <h4>(TO BE FILLED IN BLOCK LETTERS)</h4>
            </div>

        </div>
        <div class="row ">
            <div class="col-3 hr-line">
                &nbsp;
            </div>
            <div class="col-6 text-center">
                <h4 style="font-weight: bold;text-decoration: underline; text-decoration-thickness: 2px">DETAILS OF THE
                    THIRD PARTY ADMINISTRATOR/INSURER/HOSPITAL</h4>
            </div>
            <div class="col-3 hr-line">

            </div>

        </div>
        <div class="container px-5 " *ngIf="preAuthData">
            <div class="row align-items-center">
                <div class="col-auto">
                    a.
                </div>
                <div class="col-4">
                    Name of the TPA/Insurance Company:
                </div>
                <div class="col-7 ">

                    <div class="data-container">
                        <span *ngIf="tpa"> {{tpa.tpaName | uppercase}} / 
                            <span *ngIf="insurance">{{insurance.nameReg | uppercase}}</span> 
                        </span>
                      
                       
                        <!-- {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}} -->
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto">
                    b.
                </div>
                <div class="col-4">
                    Toll free phone number:
                </div>
                <div class="col-7">

                    <div class="data-container">
                        <!-- {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}} -->
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto">
                    c.
                </div>
                <div class="col-4">
                    Toll Free Fax:
                </div>
                <div class="col-7">

                    <div class="data-container">
                        <!-- {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}} -->
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto">
                    d.
                </div>
                <div class="col-4">
                    Name of Hospital:
                </div>
                <div class="col-6">

                    <div class="data-container">
                        {{removeQuotes(preAuthData.hospital.name)}}
                        <!-- {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}} -->
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row  align-items-center " style="padding-left:70px;">
                <div class="col-1">
                    i.
                </div>
                <div class="col-4">
                    Address
                </div>
                <div class="col-6">

                    <div class="data-container">
                        {{removeQuotes(preAuthData.hospital.address)}}
                        <!-- {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}} -->
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row  align-items-center" style="padding-left:70px;">
                <div class="col-1">
                    ii.
                </div>
                <div class="col-4">
                    Rohini ID
                </div>
                <div class="col-6">

                    <div class="data-container">
                        {{preAuthData.hospital.hospitalUniqueId}}
                        <!-- {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}} -->
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row  align-items-center " style="padding-left:70px;">
                <div class="col-1">
                    iii.
                </div>
                <div class="col-4">
                    E-mail id
                </div>
                <div class="col-6">

                    <div class="data-container">
                        {{removeQuotes(preAuthData.hospital.emailId)}}
                        <!-- {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}} -->
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="row ">
            <div class="col-3 hr-line">
                &nbsp;
            </div>
            <div class="col-6 text-center">
                <h4 style="font-weight: bold;">TO BE FILLED BY INSURED/PATIENT</h4>
            </div>
            <div class="col-3 hr-line">

            </div>

        </div>
        <div class="container px-5 " *ngIf="preAuthData">
            <div class="row align-items-center">
                <div class="col-auto index-column">
                    A.
                </div>
                <div class="col-4">
                    Name of the patient:
                </div>
                <div class="col-7">

                    <div class="data-container">
                        {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}}
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center pt-2">
                <div class="col-auto index-column">
                    B.
                </div>
                <div class="col-4">
                    Gender:
                </div>
                <div class="col-7">
                    <div>
                        MALE <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.gender) ==
                            'MALE'? '&#10003;' :
                            '&nbsp;'}}</span> &nbsp;&nbsp; FEMALE <span class="text-center bx"
                            style="color:blue">{{convertToParagraph(preAuthData.gender)
                            == 'FEMALE'? '&#10003;' : '&nbsp;'}}</span> &nbsp;&nbsp;THIRD GENDER <span
                            class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.gender)
                            == 'OTHER'? '&#10003;' : '&nbsp;'}}</span>
                        &nbsp;&nbsp;

                    </div>
                </div>
            </div>
            <div class="row align-items-center pt-2">
                <div class="col-auto index-column">
                    C.
                </div>
                <div class="col-4">
                    Age:
                </div>
                <div class="col-6">
                    <span class="text-center " *ngFor="let n of ageFormat(age)">{{n}}</span> Years /
                    &nbsp;
                    <span class="text-center " *ngFor="let n of ageFormat(months)">{{n}}</span> Months
                </div>
            </div>
            <div class="row align-items-center py-2">
                <div class="col-auto index-column">
                    D.
                </div>
                <div class="col-4">
                    Date of birth:
                </div>
                <div class="col-7">
                    <div class="data-container">
                        {{removeQuotes(preAuthData?.dob) | date:'dd/MM/yyyy' }}
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center ">
                <div class="col-auto index-column">
                    E.
                </div>
                <div class="col-4">
                    Contact Number:
                </div>
                <div class="col-7">
                    <div class="data-container">
                        {{removeQuotes(preAuthData['lookup_contactNumber_modal'])}}
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto index-column">
                    F.
                </div>
                <div class="col-4">
                    Contact Number Of attending relative:
                </div>
                <div class="col-7">
                    <div class="data-container">
                        {{removeQuotes(preAuthData?.attenderContactNumber)}}
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto index-column">
                    G.
                </div>
                <div class="col-4">
                    Insured card ID number:
                </div>

                <div class="col-7">
                    <div class="data-container">
                        {{removeQuotes(preAuthData?.idCardNumber)}}
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto index-column">
                    H.
                </div>
                <div class="col-4 ">
                    Policy Number/Name of corporate:
                </div>

                <div class="col-7">
                    <div class="data-container">
                        {{removeQuotes(preAuthData?.policyNumber)}}
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto index-column">
                    I.
                </div>
                <div class="col-4">
                    Employee ID:
                </div>

                <div class="col-7">
                    <div class="data-container">
                        {{removeQuotes(preAuthData?.employeeId)}}
                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center pt-2">
                <div class="col-auto index-column">
                    J.
                </div>
                <div class="col-6 ">
                    Currently do you have any other mediclaim/health insurance:
                </div>
                <div class="col-2">
                    <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.mediclaimExists) ==
                        'MEDICLAIMEXISTSYES'?' &#10003;': '&nbsp;'}}</span> Yes
                </div>
                <div class="col-2">
                    <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.mediclaimExists) ==
                        'MEDICLAIMEXISTSNO'?'
                        &#10003;': '&nbsp;'}}</span> No
                    &nbsp;&nbsp;
                </div>

            </div>
            <div class="row  align-items-center " style="padding-left:70px;">
                <div class="col-1">
                    i.
                </div>
                <div class="col-4">
                    Company Names:
                </div>
                <div class="col-5">
                    <div class="data-container">

                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row  align-items-center " style="padding-left:70px;">
                <div class="col-1">
                    ii.
                </div>
                <div class="col-4">
                    Give Details:
                </div>
                <div class="col-5">
                    <div class="data-container">

                    </div>
                    <hr class="dashed-line">
                </div>
            </div>
            <div class="row align-items-center py-2">
                <div class="col-auto index-column">
                    K.
                </div>
                <div class="col-6 ">
                    Do you have a family physician:
                </div>
                <div class="col-2">
                    <span class="text-center bx"
                        style="color:blue">{{convertToParagraph(preAuthData.familyPhysicianExists)
                        ==
                        'FAMILYPHYSICIANEXISTSYES'?' &#10003;': '&nbsp;'}}</span> Yes
                </div>
                <div class="col-2">
                    <span class="text-center bx"
                        style="color:blue">{{convertToParagraph(preAuthData.familyPhysicianExists)
                        ==
                        'FAMILYPHYSICIANEXISTSNO'?'
                        &#10003;': '&nbsp;'}}</span> No
                    &nbsp;&nbsp;
                </div>
                <div class="row align-items-center pt-4">
                    <div class="col-auto index-column">
                        L.
                    </div>
                    <div class="col-4 ">
                        Name of the family physician:
                    </div>
                    <div class="col-7">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.familyPhysicianName)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        M.
                    </div>
                    <div class="col-4 ">
                        Contact Number If any:
                    </div>
                    <div class="col-7">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.familyPhysicianContactNumber)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        N.
                    </div>
                    <div class="col-4 ">
                        Current Address of Insured patient:
                    </div>
                    <div class="col-7">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.address)}} , {{removeQuotes(preAuthData?.city)}} ,
                            {{removeQuotes(preAuthData?.country)}} , {{removeQuotes(preAuthData?.zipcode)}}

                        </div>
                        <hr class="dashed-line">

                    </div>
                </div>
                <div class="row align-items-center ">
                    <div class="col-auto index-column">
                        O.
                    </div>
                    <div class="col-4 ">
                        Occupation of Insured patient:
                    </div>
                    <div class="col-7 ">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.occupation)}}

                        </div>
                        <hr class="dashed-line">

                    </div>
                </div>
            </div>
            <br>
            <div class="row p-1">
                <div class="col-8 hr-line">
                    &nbsp;
                </div>

                <div class="col-4 ">
                    <h6>(PLEASE FILL UP COMPLETE DECLARATION OF THIS FORM)</h6>
                </div>
            </div>
            <!-- <div [ngStyle]="{ height: (insurance.nameReg .length < 40 &&  removeQuotes(preAuthData.hospital.address).length < 40) ? '10vh' : {height: (insurance.nameReg .length > 80 &&  removeQuotes(preAuthData.hospital.address).length > 80) :'8vh' ? '8vh'} }"></div> -->
            <div  *ngIf="insurance" [ngStyle]="{
                height: (insurance.nameReg.length < 40 && removeQuotes(preAuthData.hospital.address).length < 40) ? '10vh' :
                         (insurance.nameReg.length > 80 && removeQuotes(preAuthData.hospital.address).length > 60) ? '6vh' : '8vh'
            }">
            </div>
            
            <div class="row p-3">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>

                <div class="col-6 text-center">
                    <h4 style="font-weight: bold;text-decoration: underline; text-decoration-thickness: 2px;">TO BE
                        FILLED BY TREATING DOCTOR/HOSPITAL</h4>
                </div>
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
            </div>
            <!-- <div class="page-break"></div> -->
            <div class="container px-5 ">
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        A.
                    </div>
                    <div class="col-4">
                        Name of the treating doctor:
                    </div>

                    <div class="col-7">

                        <div class="data-container">
                            {{removeQuotes(preAuthData?.treatingDoctorName)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        B.
                    </div>
                    <div class="col-4">
                        Contact Number:
                    </div>
                    <div class="col-7">

                        <div class="data-container">
                            {{removeQuotes(preAuthData?.treatingDoctorContact)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        C.
                    </div>
                    <div class="col-5">
                        Nature of illness/Disease with presenting complaints:
                    </div>
                    <div class="col-6">

                        <div class="data-container">
                            {{removeQuotes(preAuthData?.natureOfComplaint)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        D.
                    </div>
                    <div class="col-4">
                        Relevant Critical Findings:
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.clinicalFindings)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <!-- <div class="page-break"></div> -->
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        E.
                    </div>
                    <div class="col-4">
                        Duration of the present ailment
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.durationOfAilments)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                    Days
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        Date of first consulation:
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.firstConsultationDate) | date:'dd/MM/yyyy' }}
                        </div>
                        <hr class="dashed-line">
                    </div>

                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        ii.
                    </div>
                    <div class="col-4">
                        Past history of present ailment , if any
                    </div>

                    <div class="col-6">
                        <div class="data-container">
                            {{(removeQuotes((preAuthData?.historyOfPresentAilment))).endsWith('NO') ? 'NO' : removeQuotes((preAuthData?.historyOfPresentAilment))}}
                        </div>
                        <hr class="dashed-line">
                    </div>

                </div>
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        F.
                    </div>
                    <div class="col-4">
                        Provisional diagnosis:
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.provisionalDiagnosis)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        ICD 10 codes
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.icdCode)}}
                        </div>
                        <hr class="dashed-line">
                    </div>

                </div>
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        G.
                    </div>
                    <div class="col-4">
                        Proposed line of treatment:
                    </div>
                    <div class="col-6">

                    </div>
                </div>
                <div class="row  align-items-center pt-2 " style="padding-left:70px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        Medical Management
                    </div>
                    <div class="col-6">
                        (<span
                            style="color:blue">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes('MEDICALMANAGEMENT')
                            ? '&#10003;':'&nbsp;'}} </span>)
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        ii.
                    </div>
                    <div class="col-4">
                        Surgical Management
                    </div>
                    <div class="col-6">
                        (<span
                            style="color:blue">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                            ('SURGICALMANAGEMENT')? '&#10003;':'&nbsp;'}}</span>)
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        iii.
                    </div>
                    <div class="col-4">
                        Intensive care
                    </div>
                    <div class="col-6">
                        (<span style="color:blue">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                        ('INTENSIVECARE')? '&#10003;':'&nbsp;'}}</span>)
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        iv.
                    </div>
                    <div class="col-4">
                        Invesgtiation
                    </div>
                    <div class="col-6">
                        (<span style="color:blue">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                        ('INVESTIGATION')? '&#10003;':'&nbsp;'}}</span>)
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        v.
                    </div>
                    <div class="col-4">
                        Non-allophatic treatment
                    </div>
                    <div class="col-6">
                        (<span style="color:blue">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                        ('NONALLOPATHICTREATMENT')? '&#10003;':'&nbsp;'}}</span>)
                    </div>
                </div>
                <div class="row align-items-center  pt-2">
                    <div class="col-auto index-column">
                        H.
                    </div>
                    <div class="col-6">
                        If Investigation and/or Medical management provide details
                    </div>
                    <div class="col-4">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.icdCode)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        Route of Drug Administation
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{getRoutes(removeQuotes(preAuthData['select-multi-drugAdministrationRoute']))}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        I.
                    </div>
                    <div class="col-4">
                        If Surgical, Name Of Surgery
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.surgeryName)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:70px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        ICD 10 PCS Code
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.icd10PCSCode)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        J.
                    </div>
                    <div class="col-4">
                        If other treatment, provide details
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.otherTreatment)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        K.
                    </div>
                    <div class="col-4">
                        How did injury occur
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.injuryReason)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <!-- <div class="page-break"></div> -->
                <div class="row align-items-center  pt-2">
                    <div class="col-auto index-column">
                        L.
                    </div>
                    <div class="col-4">
                        In case of accident
                    </div>
                    <div class="col-6">

                    </div>
                </div>
                
                <div class="row  align-items-center " style="padding-left:70px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        Is it RTA
                    </div>
                    <div class="col-6">
                        Yes<span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.rta) ==
                            'RTAYES' ?'&#10003;':'&nbsp;'}}</span>
                        No <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.rta) == 'RTANO'
                            ?'&#10003;':'&nbsp;'}}</span>
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        ii.
                    </div>
                    <div class="col-4">
                        Date of Injury(DD/MM/YYYY)
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.dateOfInjury) | date:'dd/MM/yyyy' }}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center pt-2 " style="padding-left:70px;">
                    <div class="col-1">
                        iii.
                    </div>
                    <div class="col-4">
                        Report to police
                    </div>
                    <div class="col-6">
                        &nbsp;&nbsp; Yes
                        <span class="text-center bx" style="color:blue">{{ convertToParagraph(preAuthData.reportToPolice) ==
                            'REPORTTOPOLICEYES' ?'&#10003;':'&nbsp;'}}</span>
                        No <span class="text-center bx" style="color:blue">{{ convertToParagraph(preAuthData.reportToPolice) ==
                            'REPORTTOPOLICENO'
                            ?'&#10003;':'&nbsp;'}}</span>
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        iv.
                    </div>
                    <div class="col-4">
                        FIR NO
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.firNo)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        v.
                    </div>
                    <div class="col-7">
                        Injury/disease caused due to substance abuse/Alcohol Consumption
                    </div>
                    <div class="col-4">
                        &nbsp;&nbsp;
                        Yes<span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.substanceAbuse)
                            =='SUBSTANCEABUSEYES'
                            ?'&#10003;':'&nbsp;'}}</span> &nbsp;&nbsp;
                        No <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.substanceAbuse)
                            =='SUBSTANCEABUSENO'
                            ?'&#10003;':'&nbsp;'}}</span>
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div class="row  align-items-center pt-2" style="padding-left:70px;">
                    <div class="col-1">
                        vi.
                    </div>
                    <div class="col-6">
                        Test conducted to establish this(if yes. Attach report )
                    </div>
                    <div class="col-4">
                        &nbsp;&nbsp; <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.substanceAbuseTested)
                            ?'&#10003;':'&nbsp;'}}</span> Yes
                        <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.substanceAbuseTested)
                            ?'&#10003;':'&nbsp;'}}</span>
                        No
                    </div>
                </div>
                <div class="row align-items-center pt-2">
                    <div class="col-auto index-column">
                        M.
                    </div>
                    <div class="col-4">
                        In case of Maternity
                    </div>
                    <div class="col-6">
                        &nbsp;
                        &nbsp;G &nbsp;<span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.maternityType) ==
                            'G'
                            ?'&#10003;':'&nbsp;'}}</span>
                        &nbsp;P &nbsp;<span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.maternityType) ==
                            'P'
                            ?'&#10003;':'&nbsp;'}}</span>
                        &nbsp;L &nbsp;<span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.maternityType) ==
                            'L'
                            ?'&#10003;':'&nbsp;'}}</span>
                        &nbsp;A &nbsp;<span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.maternityType) ==
                            'A'
                            ?'&#10003;':'&nbsp;'}}</span>
                        &nbsp;
                    </div>
                </div>
                <div class="row  align-items-center py-2 " style="padding-left:70px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        Expected date of delivery(DD/MM/YYYY)
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.expectedDateOfDelivery) | date:'dd/MM/yyyy' }}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
            </div>
            <div class="page-break"></div>
            <div class="row p-3">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
                <div class="col-6 text-center">
                    <h4 style="font-weight: bold;text-decoration:underline; text-decoration-thickness: 2px;">DETAILS OF
                        PATIENT ADMITTED</h4>
                </div>
                <div class="col-3 hr-line">

                </div>

            </div>
            <div class="page-break"></div>
            <div class="container px-5">
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        A.
                    </div>
                    <div class="col-4">
                        Date of admission (DD/MM/YYYY)
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.dateOfAdmission) | date:'dd/MM/yyyy' }}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        B.
                    </div>
                    <div class="col-4">
                        Time of admission (HH:MM)
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.timeOfAdmission)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center py-2">
                    <div class="col-auto index-column">
                        C.
                    </div>
                    <div class="col-6">
                        Is this an emergency/planned hospitalization event:
                    </div>
                    <div class="col-4">
                       
                        <span class="text-center bx" style="color:blue">{{convertToParagraph(preAuthData.visitType) ==
                            'EMERGENCY'
                            ?'&#10003;' :
                            '&nbsp;'}} </span>&nbsp;&nbsp; Emergency
                        &nbsp;&nbsp;<span class="text-center bx">{{convertToParagraph(preAuthData.visitType) ==
                            'PLANNED'
                            ?'&#10003;' :
                            '&nbsp;'}}</span> Planned
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        D.
                    </div>
                    <div class="col-4">
                        Mandatory past history of any chronic illness
                    </div>
                    <div class="col-6">
                        <div class="align-items-center">
                            If yes ( Since Month/Year)
                        </div>

                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        i.
                    </div>
                    <div class="col-4">
                        Diabetes
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyDiabetesSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>

                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        ii.
                    </div>
                    <div class="col-4">
                        Heart Disease
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyHeartDiseaseSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        iii.
                    </div>
                    <div class="col-4">
                        Hypertension
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyHypertensionSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        iv.
                    </div>
                    <div class="col-4">
                        Hyperlipidemias
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyHypelipidemiaSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        v.
                    </div>
                    <div class="col-4">
                        Osteoarthritis
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyOsteoarthritisSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        vi.
                    </div>
                    <div class="col-4">
                        Asthma/COPD/Bronchitis
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyAsthamaSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        vii.
                    </div>
                    <div class="col-4">
                        Cancer
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyCancerSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        viii.
                    </div>
                    <div class="col-4">
                        Alcohol/Drug Abuse
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historySubstanceAbuseSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        ix.
                    </div>
                    <div class="col-4">
                        Any HIV/or STD Related ailment
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyStdSince)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row  align-items-center " style="padding-left:50px;">
                    <div class="col-1">
                        x.
                    </div>
                    <div class="col-4">
                        Any other ailment Give details
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.historyMisc)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        E.
                    </div>
                    <div class="col-4">
                        Expected number of days/stay in hospital
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.daysInHospital)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        F.
                    </div>
                    <div class="col-4">
                        Days in ICU
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.daysInICU)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        G.
                    </div>
                    <div class="col-4">
                        Room Type
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.roomType)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        H.
                    </div>
                    <div class="col-6">
                        Per day room rent+ Nursing and Service charges + patients diet
                    </div>
                    <div class="col-4">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.roomRent)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        I.
                    </div>
                    <div class="col-5">
                        Expected cost of investigation + diagnostic
                    </div>
                    <div class="col-4">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.invCost)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        J.
                    </div>
                    <div class="col-4">
                        ICU Charges
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.icuRent)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        K.
                    </div>
                    <div class="col-4">
                        OT Charges
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.otCharges)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        L.
                    </div>
                    <div class="col-6">
                        Professional fees surgeon + anesthetic fees + consulation charges
                    </div>
                    <div class="col-4">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.fees)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        M.
                    </div>
                    <div class="col-6">
                        Medicines + consumables + cost of Implants (if applicable please specify)
                    </div>
                    <div class="col-4">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.medCost)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        N.
                    </div>
                    <div class="col-4">
                        Other Hospital expenses if any
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.hospitalExpenses)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        O.
                    </div>
                    <div class="col-4">
                        All-Inclusive package charges if any applicable
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.packageCharges)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        P.
                    </div>
                    <div class="col-4">
                        Sum total expected cost of hospitalization
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.sumExpectedCost)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
            </div>
            <div class="page-break"></div>
            <div class="row p-3">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
                <div class="col-6 text-center">
                    <h4 style="font-weight: bold;text-decoration:underline; text-decoration-thickness: 2px;">DECLARATION
                    </h4>
                </div>
                <div class="col-3 hr-line">

                </div>

            </div>
            <div class="row">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
                <div class="col-6 text-center">
                    <h5>(Please read very carefully)</h5>
                </div>
                <div class="col-3 hr-line">

                </div>

            </div>
            <div class="row p-3">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
                <div class="col-6 text-center">
                    <h5>We confirm having read understood and agreed to the declarations of this form </h5>
                </div>
                <div class="col-3 hr-line">

                </div>

            </div>
            <div class="container p-5">
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        a.
                    </div>
                    <div class="col-4">
                        Name Of the Treating Doctor
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.treatingDoctorName)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        b.
                    </div>
                    <div class="col-4">
                        Qualification
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.qualification)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        c.
                    </div>
                    <div class="col-4">
                        Registration Number With State code
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            <!-- {{removeQuotes(preAuthData?.treatingDoctorName)}} -->
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
            </div>
            <div class="row p-3">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
                <div class="col-6 text-center">
                    <h4 style="font-weight: bold;text-decoration:underline; text-decoration-thickness: 2px;">DECLARATION
                        BY THE PATIENT/REPRESENTATIVE</h4>
                </div>
                <div class="col-3 hr-line">

                </div>

            </div>

            <div class="container p-5">
                <div class="col">
                    <p>a. I agree to allow the hospital to submit all original documents pertaining to hospitalization
                        to
                        the
                        Insurer/TPA after the discharge. I agree to sign on the Final
                        Bill & the Discharge Summary,
                        before
                        my discharge.</p>
                    <p>b. Payment to hospital is governed by the terms and conditions of the policy. In case the Insurer
                        /
                        TPA
                        is not liable to settle the hospital bill, I undertake to settle the bill as per the terms and
                        conditions
                        of the policy.</p>
                    <p>c. All non-medical expenses and expenses not relevant to current hospitalization and the amounts
                        over
                        &
                        above the limit authorized by the Insurer/TPA not governed by the terms and conditions of
                        the policy will be paid by me.</p>
                    <p>d. I hereby declare to abide by the terms and conditions of the policy and if at any time the
                        facts
                        disclosed by me are found to be false or incorrect I forfeit my claim and agree to indemnify the
                        insurer / TPA</p>
                    <p>e. I agree and understand that TPA is in no way warranting the service of the hospital & that the
                        Insurer / TPA is in no way guaranteeing that the services provided by the hospital will be of a
                        particular
                        quality or standard.</p>
                    <p>f. I hereby warrant the truth of the forgoing particulars in every respect and I agree that if I
                        have
                        made or shall make any false or untrue statement, suppression or concealment with respect to the
                        claim, my right to claim reimbursement of the said expenses shall be absolutely forfeited.</p>
                    <p>g. I agree to indemnify the hospital against all expenses incurred on my behalf, which are not
                        reimbursed by the Insurer/ TPA.</p>
                    <p>h. “I/We authorize Insurance Company/TPA to contact me/us through mobile/email for any update on
                        this
                        claim”</p>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        (a)
                    </div>
                    <div class="col-4">
                        Patient's/Insured's name:
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData?.firstname)}} {{removeQuotes(preAuthData?.lastname)}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        (b)
                    </div>
                    <div class="col-4">
                        Contact Number
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData['lookup_contactNumber_modal'])}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        (c)
                    </div>
                    <div class="col-4">
                        Email-id (optional)
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            {{removeQuotes(preAuthData['emailid-email'])}}
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto index-column">
                        (d)
                    </div>
                    <div class="col-4">
                        Patient's/Insured's Signature
                    </div>
                    <div class="col-6">
                        <div class="data-container">
                            <!-- {{removeQuotes(preAuthData['emailId-email'])}}  -->
                        </div>
                        <hr class="dashed-line">
                    </div>
                </div>
                <div class="row p-3 align-items-center">
                    <div class="col-1 ">
                        Date:
                    </div>
                    <div class="col-5">
                        <span style="color:blue">{{today | date:'dd/MM/yyyy'}} </span>
                    </div>
                    <div class="col-auto">
                        Time:
                    </div>
                    <div class="col-5">
                        <span style="color:blue">{{today | date:'HH:mm'}} </span>
                       
                    </div>
                </div>
            </div>
            <div class="row p-3">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
                <div class="col-6 text-center">
                    <h4 style="font-weight: bold;text-decoration:underline; text-decoration-thickness: 2px;">HOSPITAL
                        DECLARATION</h4>
                </div>
                <div class="col-3 hr-line">

                </div>

            </div>
            <div class="container p-5">
                <div class="col">
                    <p>a. We have no objection to any authorized TPA / Insurance Company official verifying documents
                        pertaining to hospitalization.</p>
                    <p>b. All valid original documents duly countersigned by the insured / patient as per the checklist
                        below
                        will be sent to TPA/ Insurance Company within 7 days of the patient’s discharge.</p>
                    <p>c. We agree that TPA / Insurance Company will not be Liable to make the payment in the event of
                        any
                        discrepancy between the facts in this form and discharge summary or other documents.</p>
                    <p>d. The patient declaration has been signed by the patient or by his representative in our
                        presence.
                    </p>
                    <p>e. We agree to provide clarifications for the queries raised regarding this hospitalization and
                        we
                        take
                        the sole responsibility for any delay in offering clarifications.</p>
                    <p>f. We will abide by the terms and conditions agreed in the MOU.</p>
                    <p>g. We confirm that no additional amount would be collected from the insured in excess of Agreed
                        Package
                        Rates except costs towards non-admissible amounts (including additional charges due to
                        opting higher room rent than eligibility choosing separate line of treatment which is not
                        envisaged/
                        considered in package).</p>
                    <p>h. We confirm that no recoveries would be made from the deposit amount collected from the lnsured
                        except
                        for costs towards non-admissible amounts (including additional charges due to opting
                        higher room rent than eligibility/ choosing separate line of treatment which is not
                        envisaged/considered in package).</p>
                    <p>i. In the event of unauthorized recovery of any additional amount from the Insured in excess of
                        Agreed
                        Package Rates, the authorized TPA / Insurance Company reserves the right to recover the
                        same from us (the Network Provider) and,/or take necessary action, as provided under the MOU or
                        applicable laws.</p>
                </div>
                <div class="conatiner p-5">
                    <div class="flex">
                        <div class="row" *ngIf="getHospitalSealUrl()">
                            <img  id= "hospitalSeal" 
                            class="small-image"
                            [src]="getHospitalSealUrl()"
                            alt=""
                           
                        />
                        </div>
                        <div class="small-image"></div>
                    </div>
                    <div class="flex">
                        <div >
                            Hospital Seal
                        </div>
                        <div >
                            Doctor's Signauture
                   </div>
                    </div>
                    <!-- <div class="row p-2">
                        <div class="col-6 text-center "> 
                            <div class="row" *ngIf="getHospitalSealUrl()">
                                <img  id= "hospitalSeal" 
                                class="small-image"
                                [src]="getHospitalSealUrl()"
                                alt=""
                               
                            />
                            </div>
                            <div class="row px-2" >
                                Hospital Seal
                            </div>
                          
                        </div>
                        <div class="col-2">

                        </div>
                       
                        <div class="col-4">
                            <div class="row" >
                               <div class="small-image"></div>
                            </div>
                            <div class="signature" >
                                     Doctor's Signauture
                            </div>
                        </div>
                    </div> -->

                </div>
                <div class="container p-2">
                    <div class="row p-2">
                        <div class="col-1  ">
                            Date:
                        </div>
                        <div class="col-5">
                            <span style="color:blue">{{today | date:'dd/MM/yyyy'}} </span>
                        </div>
                        <div class="col-1">
                            Time:
                        </div>
                        <div class="col-5">
                            <span style="color:blue">{{today | date:'HH:mm'}} </span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>