<!-- <div mat-dialog-title>
    <h1> Add User</h1>
</div> -->
<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Add User</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="empForm" (ngSubmit)="onFormSubmit()">
  <div class="container">
    <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id" readonly formControlName="id"  >
            </mat-form-field>
        </div> -->
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input
          matInput
          type="text"
          placeholder="abc"
          formControlName="firstName"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="abc" formControlName="lastName" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>User Name</mat-label>
        <input matInput placeholder="abc" formControlName="userName" />
      </mat-form-field>

      <!-- <mat-form-field appearance="outline">
                <mat-label>UserType</mat-label>
                <input matInput type="text" placeholder="userType" formControlName="userType">
            </mat-form-field> -->
      <!-- <mat-select formControlName="userType" [(ngModel)]="selectedType" name="partner">
                <mat-option *ngFor="let role of roles" [value]="role.id">
                  {{role.type}} {{role.subType}}
                </mat-option>
              </mat-select> -->

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          placeholder="Ex. test@gmail.com"
          formControlName="emailId"
        />
      </mat-form-field>

      <!-- <div class="row">
            <mat-select formControlName="userType" [(ngModel)]="selectedType" name="partner">
                <mat-option *ngFor="let role of roles" [value]="role.id">
                  {{role.roleName}} 
                </mat-option>
              </mat-select>
        </div> -->
      <mat-form-field appearance="outline">
        <mat-label>User Type</mat-label>
        <mat-select
          formControlName="userType"
          [(ngModel)]="selectedType"
          name="role"
        >
          <mat-option *ngFor="let role of roles" [value]="role.roleName">
            {{ role.roleName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input
          matInput
          type="text"
          placeholder="address"
          formControlName="address"
        />
      </mat-form-field>

      <!-- <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input matInput type="text" placeholder="country" formControlName="country">
            </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <mat-select
          formControlName="country"
          (selectionChange)="setCountry($event)"
          name="country"
        >
          <mat-option
            *ngFor="let country of countries"
            [value]="country.isoCode"
          >
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>State</mat-label>
        <!-- <input matInput type="text" placeholder="state" formControlName="state"> -->
        <mat-select
          formControlName="state"
          name="state"
          (selectionChange)="setState($event)"
        >
          <mat-option *ngFor="let state of states" [value]="state.isoCode">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <!-- <input matInput type="text" placeholder="city" formControlName="city"> -->
        <mat-select formControlName="city" name="city">
          <mat-option *ngFor="let city of cities" [value]="city">
            {{ city.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Zip</mat-label>
        <input matInput type="text" placeholder="zip" formControlName="zip" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Mobile</mat-label>
        <input
          matInput
          type="text"
          placeholder="mobilePhone"
          formControlName="mobilePhone"
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">
      Cancel
    </button>
    <!-- <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button> -->
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!empForm.valid"
    >
      Save
    </button>
  </div>
</form>
