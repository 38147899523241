<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1>LookUp Details</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
            <h4>
                <i class="px-3" style="color: lightgray">* marked fields are mandatory
                </i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div>
<div class="container p-3 d-flex justify-content-end">
   
        <div class="addbtn p-3">
            <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
                Add
            </button>
        </div>

</div>
<div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center">
        <div class="p-4">
            <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="fieldName">
                    <mat-header-cell *matHeaderCellDef>Field Name</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldName}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="fieldType">
                    <mat-header-cell *matHeaderCellDef>Field Type</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldType}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="fieldPattern">
                    <mat-header-cell *matHeaderCellDef>Field Pattern</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldPattern}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="dataLookupStrategy">
                    <mat-header-cell *matHeaderCellDef>LookUp Strategy</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wrap-text">{{element.dataLookupStrategy}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="fieldValue">
                    <mat-header-cell *matHeaderCellDef>Field Value</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldValue}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="fieldMatchCondition">
                    <mat-header-cell *matHeaderCellDef>Field Matching Condition</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldMatchCondition}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row ;columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
</div>
<div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">
        Cancel
    </button>
</div>