export class DataShareModel{
    private processName:string;
    private tasks:any;

    public constructor(processName:string,tasks:any){
        this.processName = processName;
        this.tasks = tasks;
    }

    public getProcessName():string{
        return this.processName;
    }
    public getTasks():string{
        return this.tasks;
    }

    public setProcessName(processName:string){
        this.processName = processName;
    }
    public setTasks(tasks:any){
        this.tasks = tasks;
    }

}