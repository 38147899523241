import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/authService';


export const customerGuard = () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const user = authService.userValue;
    if (user) {
        const dd: any = user.roles?.filter((x: string) => x == 'MOBILE_USER' || x == 'ADMIN' || x == 'SUPERADMIN'  || x == 'HOBNOB_AGENT' || x == 'HNB_CLAIMS_ADMIN' || x == 'HNB_POLICY_CONSULTANT');
        if (dd?.length > 0) {
            return true;
        }

    }
    // not logged in so redirect to login page with the return url
    return router.parseUrl('/home');


};

