import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-update-user-org',
 // standalone: false,
  //imports: [],
  templateUrl: './update-user-org.component.html',
  styleUrl: './update-user-org.component.scss'
})
export class UpdateUserOrgComponent {

  userhospitalform: FormGroup;
  orgId:any;
  user:any;
  status: any;

  
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<UpdateUserOrgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    this.userhospitalform = this._fb.group({
      hospitalOrgId:'',
      name:['',Validators.required],

    });

    console.log(data);
  }


  
  ngOnInit(): void {

 
  //this.status = this.data.inactive ? "Inactive" : "Activate";
  //this.couponForm.controls['inactive'].setValue(this.status);
    this.userhospitalform.patchValue(this.data.hospitaldata);
    // var data = this.user.orgId;
    //console.log(this.data)
   }

  onFormSubmit() {
    if (this.userhospitalform.valid) {
      if (this.data) {
        this.adminService
          .updateUserhospital(this.data.userId,this.data.hospitaldata.hospitalOrgId)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("User hospital has been updated successfully");


              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));


            },
          });
      } 
      }
    }
  
}