<!-- <div mat-dialog-title>
    <h1>Update BG Product</h1>
</div> -->
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex text-start justify-content-start">
        <h1>Update BG Product</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>

<form [formGroup]="productForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="bgProductId" readonly >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput type="text" placeholder="name" formControlName="name" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput type="email" placeholder="description" formControlName="description" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Price</mat-label>
                <input matInput type="email" placeholder="Price" formControlName="price" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>uom</mat-label>
                <input matInput type="email" placeholder="uom" formControlName="uom" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>qty</mat-label>
                <input matInput type="email" placeholder="qty" formControlName="qty" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Short Name</mat-label>
                <input matInput type="email" placeholder="Short Name" formControlName="shortName" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Tc Required</mat-label>
                <input matInput type="email" placeholder="Tc Required" formControlName="tcRequired" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>GST</mat-label>
                <input matInput type="email" placeholder="gst" formControlName="gst" >
            </mat-form-field>
            <mat-form-field>
                <mat-label>Workflow</mat-label>
                <mat-select formControlName="processWorkflow" required>
                  <mat-option>--</mat-option>
                  @for (deployedProcess of deployedProcesses; track deployedProcess) {
                    <mat-option [value]="deployedProcess.key">{{deployedProcess.name}} {{deployedProcess.description}}</mat-option>
                  }
                </mat-select>
                @if (productForm.hasError('required')) {
                  <mat-error>Please choose a Workflow</mat-error>
                }
                <mat-hint>{{productForm.value?.description}}</mat-hint>
              </mat-form-field>
                     
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>{{status}}</mat-label>
                <mat-select formControlName="inactive" name="inactiveData"  >
                    <mat-option value=true>
                        Inactive
                    </mat-option>
                    <mat-option value=false>
                        Activate
                    </mat-option>
                  </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
    </div>
</form>