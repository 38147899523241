<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add Techinal Insurance</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="card">
      <div class="card-body">
        <div class="row pt-2">
            <label class="btn btn-default p-0">
              <input
                class="form-control"
                type="file"
                (change)="selectFile($event)"
              />
            </label>
        </div>
        <p style="font-size:14px;color: #8e0101;">Image size should be 200x200px.</p>
        <div mat-dialog-actions class="action">
            <button 
        mat-flat-button
        style="color: white; background: #8e0101; margin-top:10px"
        [disabled]="selectedFiles == undefined "
        (click)="uploadFiles()"
      >
        Submit
      </button>
        </div>
        
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
  