import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private http: HttpClient) { }

  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  requestOptions = { headers: this.headers };

  getAllTask(processName:any) {
    return this.http
      .get<any>(`${environment.API_URL}/bpmn/process/`+processName+`/tasks/all`)
      .pipe(catchError(this.errorHandle));
  }

  getQuickTask(processName:any) {
    return this.http
      .get<any>(`${environment.API_URL}/bpmn/process/`+processName+`/quicktask/all`)
      .pipe(catchError(this.errorHandle));
  }

  getNewTask(processName:any) {
    return this.http
      .get<any>(`${environment.API_URL}/bpmn/process/`+processName+`/tasks/new`)
      .pipe(catchError(this.errorHandle));
  }

  getAssignedTask(processName:any) {
    return this.http
      .get<any>(`${environment.API_URL}/bpmn/process/`+processName+`/tasks/assigned`)
      .pipe(catchError(this.errorHandle));
  }

  /*getBPMNDiagram(taskId:any) {
    let HTTPOptions:Object = {

      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      responseType: 'image/png'
   }
    return this.http.get<any>(`${environment.API_URL}/bpmn/process/diagram/`+taskId,HTTPOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }*/

  getBPMNDiagram(taskId:any) {
    let HTTPOptions:Object = {

      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      responseType: 'image/png'
   }
    return this.http.get<any>(`${environment.API_URL}/bpmn/process/${taskId}/diagram/`,HTTPOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getTaskDetails(taskId:string) {
    return this.http.get<any>(`${environment.API_URL}/bpmn/report/taskDetails/`+taskId)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  executeTask(taskId:string, variables:any[][]) {
    return this.http.post<any>(`${environment.API_URL}/bpmn/process/tasks/`+taskId,variables)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  assignTask(taskId:string,userId:any,dueDate:any) {
    return this.http.post<any>(`${environment.API_URL}/bpmn/assign/task?taskId=`+taskId+"&userId="+userId+"&dueDate="+dueDate,{})
      .pipe(
        catchError(this.errorHandle)
      );
  }
  reinitiateReimbursementProcess(processId:string) {
    return this.http.post<any>(`${environment.API_URL}/hobnob/reInitiateReimbursementProcess/${processId}`,{})
      .pipe(
        catchError(this.errorHandle)
      );
  }

  errorHandle(error: any) {
    let errorMessage = '';
    if (error.error.error instanceof ErrorEvent) {
      errorMessage = error.error.error;
    } else {
      errorMessage = error.error;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
