<div class="container-fluid pt-3 py-2">

  <div class="row  px-3 py-4 justify-content-md-center">
    <input type="search" (input)="handleInput($event)" class="form-control" placeholder="Filter" aria-label="Search">
  </div>
  <div class=" p-4">
    <table mat-table [dataSource]="claimOrders" matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="Id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{element.id?element.id:"NA"}}</td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.memberName?element.memberName:"NA"}}</td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">{{element.amount?element.amount:"NA"}}</td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="TransactionDate">
        <th mat-header-cell *matHeaderCellDef>Transaction Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.transactionDate | date}}
        </td>
      </ng-container>
      <ng-container matColumnDef="TransactionStatus">
        <th mat-header-cell *matHeaderCellDef>Transaction Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.transactionStatus?element.transactionStatus:"NA"}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Gst">
        <th mat-header-cell *matHeaderCellDef>Gst</th>
        <td mat-cell *matCellDef="let element">{{element.gst?element.gst:"NA"}}</td>
      </ng-container>

      <ng-container matColumnDef="DiscountPercent">
        <th mat-header-cell *matHeaderCellDef>Discount Percent</th>
        <td mat-cell *matCellDef="let element">{{element.discountPercent?element.discountPercent:"NA"}}</td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="openDetails(row)">
            <mat-icon>description</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- <a
        class="btn btn-clear"
        title="Download JSON"
        [href]="downloadJsonHref"
        download="download.json"
        >hi</a
      > -->

      <ng-container matColumnDef="Download">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Download</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="generateDownloadJsonUri(row)">
            <mat-icon>get_app</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="demo-options p-4">
    <div class="demo-toggles">
      <mat-slide-toggle [(ngModel)]="hidePageSize">Hide page size</mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showPageSizeOptions">Show multiple page size options</mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showFirstLastButtons">Show first/last buttons</mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="disabled">Disabled</mat-slide-toggle>

      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>