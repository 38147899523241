import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';


@Component({
  selector: 'app-view-ekyp',
  templateUrl: './view-ekyp.component.html',
  styleUrl: './view-ekyp.component.scss'
})
export class ViewEkypComponent implements OnInit {
  error:any;
  message:any;
  performance:any;
  loading:any = false;
  ekypProcess !: FormGroup;
  currentDate:any = new Date();
  
  constructor(
    private dashboardService:DashboardService,
    private fb : FormBuilder,
  ){
    this.ekypProcess = this.fb.group({
      fromdate: [this.currentDate , Validators.required]
    });
  }
  ngOnInit() {
    this.analyze('ekypProcess',this.currentDate);
  }

  onChangeEvent(event: any) {
    this.currentDate = new Date(event.value);
    this.analyze('ekypProcess',this.currentDate);
  }

  
  analyze(processName: any, date:any) {
    this.dashboardService
      .getUserPerformance(processName, date.toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.performance = data;
         
          console.log("Performance " + JSON.stringify(data));
          
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }
}
