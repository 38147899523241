<section class="container my-4">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Manage Subscription</mat-card-title>
      <mat-card-subtitle
        >Update product and funds for your subscription</mat-card-subtitle
      >
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
        <!-- Product Dropdown -->
        <div class="row mt-4">
          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Product</mat-label>
              <mat-select formControlName="product" required>
                <mat-option
                  *ngFor="let product of productsData"
                  [value]="product.bgProductId"
                >
                  {{ product.bgProductModel.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['product'].hasError('required')">
                Product selection is required.
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Funds Input -->
          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Funds</mat-label>
              <input
                matInput
                type="number"
                formControlName="funds"
                placeholder="Enter funds"
                required
              />
              <mat-error *ngIf="form.controls['funds'].hasError('required')">
                Funds are required.
              </mat-error>
              <mat-error *ngIf="form.controls['funds'].hasError('min')">
                Funds must be a positive number.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Form Actions -->
        <div class="d-flex justify-content-end">
          <button
            mat-stroked-button
            type="button"
            color="warn"
            class="me-2"
            (click)="form.reset()"
          >
            Cancel
          </button>
          <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</section>

<section class="container">
  <div *ngIf="productsData.length === 0">
    <div class="alert alert-info mt-2 pt-1" role="alert">
      No Products found.
    </div>
  </div>
  <div *ngIf="productsData.length != 0">
    <div class="p-4">
      <table mat-table [dataSource]="dataSourceProduct" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Product Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.bgProductModel.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            {{ element.bgProductModel.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startDate | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="qty">
          <th mat-header-cell *matHeaderCellDef>Qty</th>
          <td mat-cell *matCellDef="let element">
            {{ element.qty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Price</th>
          <td mat-cell *matCellDef="let element">
            {{ element.negotiatedRate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="funds">
          <th mat-header-cell *matHeaderCellDef>Funds</th>
          <td mat-cell *matCellDef="let element">
            {{ element.funds }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsProduct"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsProduct"></tr>
      </table>
      <mat-paginator
        [length]="dataSourceProduct.data.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </div>
  </div>
</section>
