<div style="width: 150vh; height: 50%">
  <ngx-extended-pdf-viewer
    *ngIf="pdf != undefined"
    [src]="pdf"
   
    [textLayer]="true"
    [showHandToolButton]="true"
  >
    <!-- enable text selection -->
  </ngx-extended-pdf-viewer>
  <div class="p-3 row">
    <div class="col-6 text-end">
      <button type="button" class="btn btn-primary" (click)="confirm('AGREE')">
        Agree
      </button>
    </div>
    <div class="col-6">
      <button type="button" class="btn btn-primary" (click)="confirm('CANCEL')">
        Cancel
      </button>
    </div>
  </div>
</div>
