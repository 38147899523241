<div class="container py-1">
    <div mat-dialog-title>
      <div class="row m-2  justify-content-md-center">
        <div class="col p-3 ">
          <div class="card">
            <div class="card-header text-center bg-light">
              Upload file
            </div>
            <div class="card-body">
              <div class="row pt-2">
               
                  <select class="form-select"  required="true"  [(ngModel)]="selectedCategory" >
                    <option value="POLICY">POLICY</option>
                    
                  </select>
               
              </div>
              <div class="row pt-2">
               
                  <label class="btn btn-default p-0">
                    <input class="form-control" type="file" accept="application/pdf,image/png,image/jpeg,image/jpg"
                      (change)="selectFile($event)" />
                  </label>
               
              </div>
              <div class="row pt-2">
                <div class="col text-end">
                 
                    <button class="btn btn-success btn-sm"  [disabled]="selectedFiles == undefined || selectedCategory == undefined || clicked "
                      (click)="uploadFile();clicked = true;">
                      Upload
                    </button>
                
                </div>
              </div>
    
    
              <div *ngIf="currentFile" class="progress my-3">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }">
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
    
      
      <div class="p-3">
        <mat-dialog-actions align="end">
          <button color="accent" mat-button mat-dialog-close>Cancel</button>
    
        </mat-dialog-actions>
      </div>
    </div>