import { Component } from '@angular/core';

@Component({
  selector: 'app-update-medical-report',
  templateUrl: './update-medical-report.component.html',
  styleUrls: ['./update-medical-report.component.scss']
})
export class UpdateMedicalReportComponent {

}
