import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrl: './member-list.component.scss'
})
export class MemberListComponent implements OnInit{
  displayedColumns =[ 'select','memberName', 'dob', 'isHolder'];
  dataSource = new MatTableDataSource()
  constructor(
    @Inject(MAT_DIALOG_DATA) private memberList: any,
    public dialogRef : MatDialogRef<MemberListComponent>

  ){
    this.dataSource.data = this.memberList;
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  onSelect(row:any){
    this.dialogRef.close(row);
  }

}
