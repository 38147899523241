import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-view-update-kyp',
  templateUrl: './view-update-kyp.component.html',
  styleUrls: ['./view-update-kyp.component.scss']
})
export class ViewUpdateKypComponent implements OnInit {
  pdfUrl:any;
  imgData:any;
  policyMember:any[] =[];
  errorMessage:string = '';
  kypForm: FormGroup;
  associateArray!:FormArray;
  secondaryArray!:FormArray;
  membersForm!:FormGroup;
  order:any;
  ekypData:any;
  insured_waiting_period!:FormGroup;
  sublimit_info!:FormGroup;
  comment_section!:FormGroup;
  insured_waiting:any;
  sublimit_information:any;
  comment_sec:any;
  benefitForm!: FormGroup;
  policyMembers:any;

  constructor(
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe
  ){
    
    this.kypForm = new FormGroup({
      id: new FormControl(''),
      policy_number: new FormControl('', [Validators.required,]),
      policy_name: new FormControl('', [Validators.required,Validators.pattern(/^(([A-Za-z]+)(\s[A-Za-z]+)?)$/gm)]),
      policy_type: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      insurance_company: new FormControl('', [Validators.required, Validators.pattern(/^(([A-Za-z]+)(\s[A-Za-z]+)?)$/gm)]),
      insurance_name:new FormControl('',Validators.required),
      start_date: new FormControl('', [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[12]\d|3[01])[\/](19|20)\d{2}$/)]),
      end_date:new FormControl('', [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[12]\d|3[01])[\/](19|20)\d{2}$/)]),
      renewal_insurance:new FormControl('', Validators.required),
      ported: new FormControl('',Validators.required),
      sum_insured: new FormControl('', Validators.required),

    });
    console.log(this.kypForm);
  }

  ngOnInit(): void {
    console.log("order_id:"+JSON.stringify(this.data.policy));
    const policy = this.data.policy;
    this.ekypData = JSON.parse(policy.ekypData);
    console.log(this.ekypData.comments);
    this.getPolicyFile(this.data.policy.id);

    this.kypForm.patchValue({
      id:this.data.id,
      policy_number:this.ekypData.policy_number,
       policy_name: this.ekypData.policy_name,
      policy_type:this.ekypData.policy_type ,
       insurance_company: this.ekypData.insurance_company,
       insurance_name: this.ekypData.insurance_name,
       start_date: this.datePipe.transform(this.ekypData.start_date,'MM/dd/YYYY'),
       end_date:this.datePipe.transform(this.ekypData.end_date,'MM/dd/YYYY'),
       renewal_insurance:this.ekypData.start_date,
       ported: this.ekypData.ported,
       sum_insured: this.data.sumInsured,
    })

    // this.endDate = this.kypForm.value.end_date

    this.membersForm = new FormGroup({
      members: new FormArray([])
    });

    this.insured_waiting= this.ekypData['waiting_period_information'];
    console.log(this.insured_waiting);
    this.comment_sec = this.ekypData.comments;
    console.log(this.comment_sec);
    this.sublimit_information =this.ekypData.sublimit_information;
     console.log("sublimit_info:"+JSON.stringify(this.sublimit_information));
     this.policyMembers = this.policyMembers as FormArray;
    this.insured_waiting= this.ekypData.waiting_period_information;
   
    this.comment_sec = this.ekypData.comments;
 
    this.sublimit_information =this.ekypData.sublimit_information;
     console.log("sublimit_info:"+JSON.stringify(this.sublimit_information));

      this.membersForm = new FormGroup({
        members: new FormArray([])
      });
      if (this.ekypData && this.ekypData.coverage_information) {
        this.ekypData.coverage_information.forEach((member:any)=>{
          // console.log("memeber:"+JSON.stringify(member));
          this.policyMember.push(member);
        })
      }else{
        console.log("error");
      }
  
      this.policyMember.forEach((member:any) => {
        
          this.associateArray = this.membersForm.get('members') as FormArray;
          this.associateArray.push(this.addMember(member));
      });

     

      this.insured_waiting_period = new FormGroup({
        insured:new FormArray([])
      });

      this.insured_waiting.forEach((insured:any)=>{
        this.associateArray = this.insured_waiting_period.get('insured') as FormArray;
        this.associateArray.push(this.addInsured(insured));
      });

      this.comment_section = new FormGroup({
        comment: new FormArray([])
      });

      this.comment_sec.forEach((comment:any)=>{
        this.associateArray = this.comment_section.get('comment') as FormArray;
        this.associateArray.push(this.addComment(comment));
      })

      this.sublimit_info = new FormGroup({
        sublimit_info : new FormArray([])
      })
      this.sublimit_information.forEach((value:any)=>{
       
          this.associateArray = this.sublimit_info.get('sublimit_info') as FormArray;
          this.associateArray.push(this.addSublimit(value));
     
    })

       console.log(this.sublimit_info.value);
  }

  async getPolicyFile(policyId:string){
    await this.customerService
      .getPolicyFile(policyId)
      .subscribe({
        next: (data: Uint8Array) => {

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = URL.createObjectURL(file);
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
        },
      });
  }

  addNewComment(){
    this.secondaryArray = this.comment_section.get('comment') as FormArray;
    this.secondaryArray.push(
     new FormGroup({
      'name':new FormControl(''),
      'comment':new FormControl('')
    })
  );
  }

  
  addMember(member:any){
    console.log("each member:"+JSON.stringify(member));
      return  new FormGroup({
        'sum_insured': new FormControl(member['sum_insured']),
        'super_bonus': new FormControl(member['super_bonus']),
        'insured_name': new FormControl(member['insured_name']),
        'bonus': new FormControl(member['bonus']),
        'restore':new FormControl(member['restore']),
        'effective_coverage': new FormControl(member['effective_coverage']),
        'dob': new FormControl(member['dob'])
      })
  }

  addInsured(insured:any){
    return new FormGroup({
      'insured_name': new FormControl(insured['insured_name']),
      'pre_hospitalisation': new FormControl(insured['pre_hospitalisation']),
      'post_hospitalisation':new FormControl(insured['post_hospitalisation']),
      'waiting_period': new FormControl(insured['waiting_period'])
    });
  }
  
  addComment(comment:any){
    return new FormGroup({
      'name':new FormControl(comment['name']),
      'comment':new FormControl(comment['comment'])
    })
  }

  addSublimit(sublimit:any){
    const newSublimit = new FormGroup({
      'insured_name': new FormControl(sublimit['insured_name']),
      sublimit:new FormArray([])
    })
    sublimit.sublimit.forEach((value:any)=>{
        this.associateArray = newSublimit.get('sublimit') as FormArray;
        this.associateArray.push(this.addComment(value));
  });
    return newSublimit;
  }

  get getMembers(){
    return this.membersForm.get('members') as FormArray;
  }

  get getComment(){
    return this.comment_section.get('comment') as FormArray;
  }

  get getInsured(){
    return this.insured_waiting_period.get('insured') as FormArray;
  }

   get getSublimitInfo(){
    return this.sublimit_info.get('sublimit_info') as FormArray;
   }

   getSubmlimitIndex(group:any){
     const arr = group as FormArray;
     return arr.get('sublimit') as FormArray;

   }
  onFormSubmit(){

  }


}
