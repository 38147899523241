import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { first } from 'rxjs';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-view-files',
  templateUrl: './view-files.component.html',
  styleUrl: './view-files.component.scss'
})
export class ViewFilesComponent implements OnInit{
  pdfUrl: Uint8Array | null = null;

  id:any = "";
  constructor(private fileService:FileService,
    private sanitizer: DomSanitizer,
    //@Inject(MAT_DIALOG_DATA) public data: any,
    ){

  }
  ngOnInit() {
    this.getMedia("ad51870b-a0a5-4261-aa29-5112f3c25760");
    //console.log(JSON.stringify(this.data))
  }

  getMedia(mediaId:any){
    this.fileService.getPdfAsByteStream(mediaId).pipe(first())
    .subscribe({
      next: (data: any) => {
        this.pdfUrl = data;
        // console.log("prod", data);
      },
      error: (err: any) => {
        console.log(err);

      },
    });

  }

}
