<table mat-table [dataSource]="dataSource">
  <!-- Position Column -->
  <!-- {{selectedTask}} -->
  <ng-container matColumnDef="currenttask">
    <th mat-header-cell *matHeaderCellDef>Tasks</th>
    <td mat-cell
      *matCellDef="let element"
      (click)="selectTask(element)"
      [class]="element == selectedTask ? 'selected' : ''"
    >
      {{ element.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="hospitalName">
    <th mat-header-cell *matHeaderCellDef>Hospital Name</th>
    <td mat-cell *matCellDef="let element">
      {{
        element.caseVariables.org.name ? element.caseVariables.org.name : "NA"
      }}
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="orderDate">
    <th mat-header-cell *matHeaderCellDef>Order Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.createdDate | date : "M/dd/yyyy hh:mm" }}
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="onboardingId">
    <th mat-header-cell *matHeaderCellDef>Onboarding Id</th>
    <td mat-cell *matCellDef="let element">
      {{ element.processInstanceId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="assignedTo">
    <th mat-header-cell *matHeaderCellDef>Assigned to</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.assignee != undefined"
        >{{ element.localVariables.assignedUser.firstName }}
        {{ element.localVariables.assignedUser.lastName }}</span
      >
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <div class="btn-group p-2" role="group" aria-label="Basic example">
        <button
          type="button"
          class="btn"
          style="color: white; background: #8e0101"
          (click)="acceptTask(element)"
          [disabled]="element.assignee != undefined"
        >
          Accept
        </button>
        <button
          type="button"
          class="btn"
          style="color: white; background: #8e0101"
          (click)="assignTask(element)"
          [disabled]="!isPrivileged(['ADMIN', 'CAN_ASSIGN_TASK'])"
        >
          Assign
        </button>
        <button
          type="button"
          class="btn"
          style="color: white; background: #8e0101"
          (click)="viewTask(element)"
        >
          View
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  aria-label="Select page of Tasks!"
>
</mat-paginator>
