<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Assign Manager</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="manageReporteeForm" (ngSubmit)="onFormSubmit()">
  <mat-dialog-content>
    <div class="container">
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Manage Reportee</mat-label>
          <mat-select
            formControlName="manager"
            [(ngModel)]="selectedUser"
            name="manager"
          >
            <mat-option *ngFor="let user of users" [value]="user.id">
              {{ user.firstName }} {{ user.lastName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">
      Cancel
    </button>
    <!-- <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button> -->
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!manageReporteeForm.valid"
    >
      Save
    </button>
  </div>
</form>
