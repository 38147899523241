import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";
import { AddTpaComponent } from "../add-tpa/add-tpa.component";
import { first } from "rxjs";

@Component({
  selector: "app-upload-tpa-logo",
  templateUrl: "./upload-tpa-logo.component.html",
  styleUrls: ["./upload-tpa-logo.component.scss"],
})
export class UploadTpaLogoComponent {
  tpaAddForm: any = [];
  selectedFiles?: any;
  message: any;
  tpaDataId: any;
  category: any;
  entityType: any;
  tpatables: any;
  formData: FormData = new FormData();
  constructor(
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<AddTpaComponent>,
    private tpaService: ManagetpaService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tpaAddForm = this._fb.group({
      logo: [""],
    });
  }

  ngOnInit() {}
  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
    this.category = "LOGO";
    this.entityType = "TPA_MASTER";
    console.log("this.selectedFiles", this.selectedFiles.name);
  }
  onFormSubmit() {
    console.log("onFormSubmit");
    this.tpaDataId = this.data.id;
    this.formData.append("file", this.selectedFiles);
    this.formData.append("entityId", this.tpaDataId);
    this.formData.append("category", this.category);
    this.formData.append("entityType", this.entityType);
    this.tpaAddMedia(this.formData);
  }
  tpaAddMedia(data: any) {
    this.tpaService.addExternalMedia(data).subscribe({
      next: (data: any) => {
        var logo = data 
        // this.formData.append("logo", this.data.id);
        var addData = {
          id: this.tpaDataId,
          logo: logo.id,
          tpaName: this.data.tpaName,
          emailId: this.data.emailId,
          contactNos: this.data.contactNos,
          inactive: this.data.inactive,
           address: this.data.address,
          city: this.data.city,
          state: this.data.state,
          pincode: this.data.pincode,
        };
        this.updateTpa(addData);
        this.toastrService.success("Tpa Logo is updated successfully");
        this._dialogRef.close(true);
       
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
        this.toastrService.error(JSON.stringify(error));
        this._dialogRef.close(true);
      },
    });
  }
  updateTpa(data: any) {
    this.tpaService.updateTpa(this.tpaDataId, data).subscribe({
      next: (data: any) => {
        // this.tpaAddMedia(this.tpaData, this.category, this.entityType);
        // this.toastrService.success("Tpa is updated successfully");
        this._dialogRef.close(true);
       
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
        this.toastrService.error(JSON.stringify(error));
        this._dialogRef.close(true);
      },
    });
  }

 
}
