import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first, firstValueFrom } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Country } from "country-state-city";
import { HttpErrorResponse } from "@angular/common/http";

interface list {
  id: number;
  name: string;
}

@Component({
  selector: "app-add-zone",
  //standalone: true,
  //imports: [],
  templateUrl: "./add-zone.component.html",
  styleUrl: "./add-zone.component.scss",
})
export class AddZoneComponent {
  zoneForm: FormGroup;
  pageIndex = 0;
  pageSize = 100;
  visible = true;
  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emailTags: string[] = [];
  addressList: string[] = [];
  cityList: string[] = [];
  stateList: string[] = [];
  pincodeList: string[] = [];
  branchList: string[] = [];
  branchForm:FormGroup;
  payorList:any[] = [];
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private tpaService: ManagetpaService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AddZoneComponent>
  ) {
    this.zoneForm = this._fb.group({
      // id: '',
      category: ["", Validators.required],
      insuranceCompanyId: ["", [Validators.required]],
      zone: ["", [Validators.required]],
      // insuranceEmailId: ["", [Validators.required]],
      // address: ["", Validators.required],
      // city: ["", [Validators.required]],
      // branch: ["", Validators.required],
      // state: ["", Validators.required],
      // pincode: ["", [Validators.required]],
      // country: [""]
    });
    this.branchForm = this._fb.group({
      branch: ["", Validators.required],
      address: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      pincode: ["", [Validators.required]],
      country:[""],
      insuranceEmailId: ["", [Validators.required]],

    });
  }

  async ngOnInit() {
     this.getPayorList();
    // const insuranceList = await this.getInsuranceCompanyMasterData();
    // const tpaList = await this.getTpaList();
    // this.insuranceAndTpaList = [
    //   ...insuranceList.map((insurance: any) => ({
    //     id: insurance.id,
    //     name: insurance.nameReg
    //   })),
    //   ...tpaList.map((tpa: any) => ({
    //     id: tpa.id,
    //     name: tpa.nameReg
    //   }))
    // ];

      }

  ngAfterViewInit(): void { }

  deployedAllProcesses: any;

  onFormSubmit() {
    var data = {

      insuranceTpaId: this.zoneForm.value.insuranceCompanyId,
      branch: this.branchForm.value.branch,
      category: this.zoneForm.value.category,
      email: this.branchForm.value.insuranceEmailId,
      address: this.branchForm.value.address,
      city: this.branchForm.value.city,
      state: this.branchForm.value.state,
      pincode: this.branchForm.value.pincode,
      country: "India",
      zoneRegion: this.zoneForm.value.zone,
      inactive:false,
      dateCreated: "",
      createdBy: "",
      dateUpdated: "",
      updatedBy: "",
    };
    console.log(JSON.stringify(data));

    this.adminService.addZone(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("Branch has been added successfully");
        this.branchForm.reset();

        //this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        if(err instanceof HttpErrorResponse){
          if(err.error.error.includes('org.hibernate.exception.ConstraintViolationException')){
              this.toastrService.error("The branch has already been added ")
          }else{
            this.toastrService.error(JSON.stringify(err.error.error));
          }
        }
        //this.toastrService.error("Error");
        
      },
    });
  }
  async getPayorList() {
    this.adminService.getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize)
    .subscribe({
      next: (val: any) => {
        this.payorList = val.result;
      },
      error: (err: any) => {
        console.error(err);
      },
     });
    }
    // try {
    //   const data = await firstValueFrom(
    //     this.adminService.getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize)
    //   );
    //   this.payorList = data.result;
    //   return data.result;
    // } catch (error) {
    //   console.error(error);
    //   return [];
    // }
 // }

  async getTpaList(): Promise<any[]> {
    try {
      const data = await firstValueFrom(this.tpaService.getTpa());
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }

  }

  // addEmailId(event: MatChipInputEvent) {
  //   const input = event.input;
  //   const value = (event.value || '').trim();
  //   console.log(value)
  //   if (value) {
  //     this.emailTags.push(value);
  //     console.log(this.emailTags)
  //   }
  //   if (input) {
  //     input.value = '';
  //   }
  // }

  // addAddress(event: MatChipInputEvent) {
  //   const input = event.input;
  //   const value = (event.value || '').trim();
  //   console.log(value)
  //   if (value) {
  //     this.addressList.push(value);
  //     console.log(this.addressList)
  //   }
  //   if (input) {
  //     input.value = '';
  //   }
  // }

  // addBranch(event: MatChipInputEvent) {
  //   const input = event.input;
  //   const value = (event.value || '').trim();
  //   console.log(value)
  //   if (value) {
  //     this.branchList.push(value);
  //     console.log(this.branchList)
  //   }
  //   if (input) {
  //     input.value = '';
  //   }
  // }

  // addCity(event: MatChipInputEvent) {
  //   const input = event.input;
  //   const value = (event.value || '').trim();
  //   console.log(value)
  //   if (value) {
  //     this.cityList.push(value);
  //     console.log(this.cityList)
  //   }
  //   if (input) {
  //     input.value = '';
  //   }
  // }
  // addState(event: MatChipInputEvent) {
  //   const input = event.input;
  //   const value = (event.value || '').trim();
  //   console.log(value)
  //   if (value) {
  //     this.stateList.push(value);
  //     console.log(this.stateList)
  //   }
  //   if (input) {
  //     input.value = '';
  //   }
  // }

  // addPincode(event: MatChipInputEvent) {
  //   const input = event.input;
  //   const value = (event.value || '').trim();
  //   console.log(value)
  //   if (value) {
  //     this.pincodeList.push(value);
  //     console.log(this.pincodeList)
  //   }
  //   if (input) {
  //     input.value = '';
  //   }
  // }


  // removeEmailId(emailId: string) {
  //   const index = this.emailTags.indexOf(emailId);
  //   if (index >= 0) {
  //     this.emailTags.splice(index, 1);
  //   }
  // }

  // removeAddress(address: string) {
  //   const index = this.addressList.indexOf(address);
  //   if (index >= 0) {
  //     this.addressList.splice(index, 1);
  //   }
  // }

  // removeBranch(branch: string) {
  //   const index = this.branchList.indexOf(branch);
  //   if (index >= 0) {
  //     this.branchList.splice(index, 1);
  //   }
  // }


  // removeCity(city: string) {
  //   const index = this.cityList.indexOf(city);
  //   if (index >= 0) {
  //     this.cityList.splice(index, 1);
  //   }
  // }
  // removeState(state: string) {
  //   const index = this.stateList.indexOf(state);
  //   if (index >= 0) {
  //     this.stateList.splice(index, 1);
  //   }
  // }

  // removePincode(pincode: string) {
  //   const index = this.pincodeList.indexOf(pincode);
  //   if (index >= 0) {
  //     this.pincodeList.splice(index, 1);
  //   }
  // }

}
