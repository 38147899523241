import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { User } from '../model/user';
import { AdminService } from './admin.service';

const USER_KEY = "user";
@Injectable({ providedIn: 'root' })
export class AuthService {
    private userSubject: BehaviorSubject<User | null>;
    public imgData: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public user: Observable<User | null>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private adminService:AdminService
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem(USER_KEY)!));
        this.user = this.userSubject.asObservable();
        
        
    }

    public get userValue() {
        return this.userSubject.value;
    }

    login(cred: any) {
        return this.http.post<User>(`${environment.API_URL}/auth/login`, cred)
            .pipe(map(user => {
                this.userSubject.next(user);
                this.getProfile();
                localStorage.setItem(USER_KEY, JSON.stringify(user));
                
                return user;
            }));
    }

    getProfile() {
        this.adminService.getProfilePic().subscribe({
          next:(data:any) =>{
            const reader = new FileReader();
            reader.onload = (e:any) => this.imgData.next(e.target.result);    
            reader.readAsDataURL(new Blob([data]));
          },
          error:(err:any) =>{
            console.log(err);
          }
        })
      }

    getPin(passRequest: any) {
        return this.http.post<User>(`${environment.API_URL}/auth/pin`, passRequest)
            .pipe(map(data => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
//console.log("User "+JSON.stringify(data))
                return data;
            }));
    }

    forgotPassword(passRequest: any) {
        return this.http.post<User>(`${environment.API_URL}/auth/forgotPassword`, passRequest)
            .pipe(map(data => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
//console.log("User "+JSON.stringify(data))
                return data;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem(USER_KEY);
        this.userSubject.next(null);
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    updateProfile(data: any) {
        return this.http.patch<any>(`${environment.API_URL}/auth/user`, data)
          .pipe(map(data => {
            return data;
          }));
      }
    

    accountactivation(customerId: any): Observable<any> {
        //   return this.http.post<User>(`${environment.API_URL}/auth/accountActivation`, + "?customer_id" + customerId)
        return this.http.get<any>(environment.API_URL + "/auth/accountActivation?userId=" + customerId)
            .pipe(
                catchError(this.errorHandle)
            );
    }

    getUserById(userId: any): Observable<any> {
        return this.http.get<any>(environment.API_URL + `/auth/user/${userId}`)
          .pipe(
            catchError(this.errorHandle)
          );
      }

    errorHandle(error: any) {
        let errorMessage = '';
        if (error.error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.error;
        } else {
            // Get server-side error
            //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
    }

    parseJwt(token: any) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
      };

}