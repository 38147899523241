import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-edit-ekyp-details',
  templateUrl: './edit-ekyp-details.component.html',
  styleUrl: './edit-ekyp-details.component.scss'
})
export class EditEkypDetailsComponent implements OnInit {
  imgData:any;
  pdfUrl:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef : MatDialogRef<EditEkypDetailsComponent>,
    private customerService: CustomerService,
  ){

  }
  ngOnInit(): void {
    this.getPolicyFile(this.data.id);
  }

  async getPolicyFile(policyId:string){
    await this.customerService
      .getPolicyFile(policyId)
      .subscribe({
        next: (data: Uint8Array) => {

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = URL.createObjectURL(file);
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
        },
      });
  }

}
