import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";

@Component({
  selector: "app-add-tpa",
  templateUrl: "./add-tpa.component.html",
  styleUrls: ["./add-tpa.component.scss"],
})
export class AddTpaComponent {
  tpaAddForm: any = [];
  selectedFiles?: any;
  message: any;
  tpaDataId: any;
  category: any;
  entityType: any;
  tpatables: any;
  formData: FormData = new FormData();

  constructor(
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<AddTpaComponent>,
    private tpaService: ManagetpaService
  ) {
    this.tpaAddForm = this._fb.group({
      nameReg: ["", Validators.required],
      nameShort:["", Validators.required],
      website:["", Validators.required],
      claimSupportNumber: ["", Validators.required],
      claimSupportAddr: ["", Validators.required],
      claimSupportEmail: ["", [Validators.required]],
      address: ["", [Validators.required,Validators.maxLength(500)]],
      city: ["", [Validators.required,Validators.maxLength(20)]],
      state: ["", [Validators.required]],
      pincode: ["", [Validators.required, Validators.maxLength(7)]],
      // contactNos: [
      //   "",
      //   [
      //     Validators.required,
      //     Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$"),
      //   ],
      // ],
      // logo: [""],
      inactive: ["", Validators.required],
    });
  }

  ngOnInit() {}

  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
    this.category = "LOGO";
    this.entityType = "TPA_MASTER";
    console.log("this.selectedFiles", this.selectedFiles.name);
  }

  date() {
    var str = new Date().setSeconds(0, 0);
    var dt = new Date(str).toISOString();
    console.log("date :", dt);
    return dt;
  }

  onFormSubmit() {
    console.log("onFormSubmit");
    var addData = {
      //"id": this.couponForm.value.id,
      nameReg: this.tpaAddForm.value.nameReg,
      nameShort: this.tpaAddForm.value.nameShort,
      website: this.tpaAddForm.value.website,
      claimSupportNumber: this.tpaAddForm.value.claimSupportNumber,
      claimSupportAddr: this.tpaAddForm.value.claimSupportAddr,
      claimSupportEmail: this.tpaAddForm.value.claimSupportEmail,
      contactNumber: this.tpaAddForm.value.contactNos,
      inactive: this.tpaAddForm.value.inactive,
      address: this.tpaAddForm.value.address,
      city: this.tpaAddForm.value.city,
      state: this.tpaAddForm.value.state,
      pincode: this.tpaAddForm.value.pincode,
      payorType:"TPA",
      inhouse:true,
      // logo: this.selectedFiles.name,
    };
    this.tpaAdd(addData);
  }

  tpaAdd(data: any) {
    this.tpaService.addTpa(data).subscribe({
      next: (data: any) => {
        this.toastrService.success("Tpa is added successfully");
        this._dialogRef.close(true);
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
        this.toastrService.error(JSON.stringify(error));
        this._dialogRef.close(true);
      },
    });
  }

  tpaAddMedia(data: any) {
    this.tpaService.addExternalMedia(data).subscribe({
      next: (data: any) => {
        // this.toastrService.success("Tpa is added successfully");
        this._dialogRef.close(true);
        console.log("data " + data);
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
        this.toastrService.error(JSON.stringify(error));
        this._dialogRef.close(true);
      },
    });
  }


}
