<div class="container p-3">
    <div mat-dialog-title>
      <div class="row">
        <div class="col-8 d-flex justify-content-start">
          <h1>Update EKYP</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
          <mat-icon color="accent">cancel</mat-icon>
        </div>
      </div>
    </div>
    <mat-dialog-content class="mat-typography">
      <div class="row">
        <div class='col-md-6' style="padding: 0">
          <div class="container text-center">
            <div style="width: 100%; max-height: 50%">
              <ngx-extended-pdf-viewer *ngIf="pdfUrl != undefined" [src]="pdfUrl" 
                [textLayer]="true" [showHandToolButton]="true">
              </ngx-extended-pdf-viewer>
              <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
            </div>
          </div>
        </div>
        <div class='col-md-6' style="padding: 0">
          <div class="container text-center">
            <div class="container p-3">
              <div style="width: 100%; max-height: 50%">
                 <form [formGroup]="kypForm" (ngSubmit)="onFormSubmit()">
                    <div mat-dialog-content class="content">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="outline">
                                    <mat-label>First Inception date</mat-label>
                                    <input matInput type="text" placeholder="MM/DD/YYYY" formControlName="start_date">
                                </mat-form-field>   
                                <div class="text-danger er-text" *ngIf="kypForm.controls.start_date.errors">{{errorMessage}}</div>
                                
                              
                            </div>
                            <div class="col">
                              
                                <div class="col">
                                    <mat-form-field appearance="outline">
                                        <mat-label>policy_number</mat-label>
                                        <input matInput type="text" placeholder="id" formControlName="policy_number">
                                    </mat-form-field>
                                    <div class="text-danger er-text" *ngIf="kypForm.controls.policy_number.errors">{{errorMessage}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" >
                            <mat-form-field appearance="outline" class="mat-50">
                                <mat-label>End date</mat-label>
                                <input matInput type="text" placeholder="MM/DD/YYYY" formControlName="end_date">
                                
                            </mat-form-field>
                            <div class="text-danger er-text" *ngIf="kypForm.controls.end_date.errors">{{errorMessage}}</div>
                            
                        </div>
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>Company Name</mat-label>
                                <input matInput type="text" placeholder="insurance_company" formControlName="insurance_company">
                              
                            </mat-form-field>
                            <div class="text-danger er-text" *ngIf="kypForm.controls.insurance_company.errors">{{errorMessage}}</div>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>Insurance Name</mat-label>
                                <input matInput type="text" placeholder="insurance_name" formControlName="insurance_name">
                            </mat-form-field>
                            <div class="text-danger er-text" *ngIf="kypForm.controls.insurance_name.errors">{{errorMessage}}</div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="outline">
                                    <mat-label>Ported</mat-label>
                                    <input matInput type="text" placeholder="ported" formControlName="ported">
                                </mat-form-field>
                                <div class="text-danger er-text" *ngIf="kypForm.controls.ported.errors">{{errorMessage}}</div>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="outline">
                                    <mat-label>Policy Type</mat-label>
                                    <input matInput type="text" placeholder="policy_type" formControlName="policy_type">
                                </mat-form-field>
                                <div class="text-danger er-text" *ngIf="kypForm.controls.policy_type.errors">{{errorMessage}}</div>
                            </div>
                
                        </div>
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>Policy Name</mat-label>
                                <input matInput type="text" placeholder="Policy Name" formControlName="policy_name">
                            </mat-form-field>
                            <div class="text-danger er-text" *ngIf="kypForm.controls.policy_name.errors">{{errorMessage}}</div>
                        </div>
                        <hr>
                
                
                        <h2>Coverage Information</h2>
                      <form [formGroup]="membersForm">
                            <div formArrayName="members">
                                <table style="border-spacing: 10px; ">
                                    <thead>
                                        <tr style="background-color: brown; height: 50px;">
                                            <th>Insured Name</th>
                                            <th>Sum Insured</th>
                                            <th>Super Bonus</th>
                                            <th>Bonus</th>
                                            <th>Restore</th>
                                            <th>Effective Coverage</th>
                                        </tr>
                
                                        <tr *ngFor="let member of getMembers.controls; let i = index" [formGroupName]="i">
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    <input matInput formControlName="insured_name">
                                                </mat-form-field>
                                                
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    <input matInput formControlName="sum_insured">
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    
                                                    <input matInput formControlName="super_bonus">
                                                </mat-form-field>
                
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline">
                 
                                                    <input matInput formControlName="bonus">
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline">
                                                   
                                                    <input matInput formControlName="restore">
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    
                                                    <input matInput formControlName="effective_coverage">
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </form>
                
                      
                
                      
                        <hr>
                        <h2>Insured Waiting Period</h2>
                        
                
                        <form [formGroup]="insured_waiting_period">
                            <div formArrayName="insured">
                                <table style="border-spacing: 10px;">
                                    <thead>
                                        <tr style=" height:50px ; background-color: brown;">
                                            <th>Name</th>
                                            <th>Pre Hospitalisation </th>
                                            <th>Post Hospitalisation</th>
                                            <th> Waiting Period</th>
                                        </tr>
                                        <tr *ngFor="let insured of getInsured.controls; let i=index" [formGroupName]="i">
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    <input matInput formControlName="insured_name">
                                                </mat-form-field>
                                            </td>
                
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Select an option</mat-label>
                                                    <mat-select formControlName="pre_hospitalisation">
                                                      <mat-option value="No Waiting Period">No Waiting Period</mat-option>
                                                      <mat-option value="30 days">30 days</mat-option>
                                                      <mat-option value="60 days">60 days</mat-option>
                                                    </mat-select>
                                                   
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Select an option</mat-label>
                                                    <mat-select formControlName="post_hospitalisation">
                                                      <mat-option value="No Waiting Period">No Waiting Period</mat-option>
                                                      <mat-option value="30 days">30 days</mat-option>
                                                      <mat-option value="60 days">60 days</mat-option>
                                                    </mat-select>
                                                   
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field appearance="outline">
                                                    <mat-select formControlName="waiting_period">
                                                        <mat-option value="No Waiting Period">No Waiting Period</mat-option>
                                                        <mat-option value="30 days">30 days</mat-option>
                                                        <mat-option value="60 days">60 days</mat-option>
                                                      </mat-select>
                                                  
                                                </mat-form-field>
                                            </td>
                
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </form>
                        <hr>
                        <h2>Sublimit Information</h2>
                      
                        <form [formGroup]="sublimit_info">
                            <div formArrayName="sublimit_info">
                                <table>
                                    <thead>
                                        <tr style="background-color: brown; height: 50px;" *ngFor="let info of getSublimitInfo.controls, let i=index" [formGroupName]="i">
                                            <th>Insured_Name</th>
                                            <th >
                                                <div formArrayName="sublimit">
                                                    <td *ngFor=" let data of  getSubmlimitIndex(info).controls let j = index" [formGroupName]="j">
                                                      <mat-form-field >
                                                        <input matInput placeholder="Sublimit Name" formControlName="name" >
                                                      </mat-form-field>
                                                    </td>
                                                  </div>
                                           
                                         </tr>
                                         <tr  *ngFor="let info of getSublimitInfo.controls, let i=index" [formGroupName]="i">
                                            <td>
                                                <mat-form-field>
                                                    <input matInput formControlName="insured_name">
                                                </mat-form-field>
                                                
                                            </td>
                                            
                                            <div formArrayName="sublimit">
                                                <td *ngFor=" let data of  getSubmlimitIndex(info).controls let j = index" [formGroupName]="j">
                                                 
                                                  <mat-form-field>
                                                    <input matInput placeholder="Comment" formControlName="comment">
                                                  </mat-form-field>
                                                </td>
                                              </div>
                                               
                                          
                                         </tr>
                                    </thead>
                                </table>
                            </div>
                </form>
                
                <hr>
                <div style="display: flex; justify-content: space-between;">
                    <h2>Comment Section</h2>
                    <button type="button" style="background-color: brown;" (click)="addNewComment()">Add Comment</button>
                 
                  </div>
                 <div *ngIf="comment_section" >
                    <form [formGroup]="comment_section">
                    <div formArrayName="comment">
                        <div class="row">
                            <table>
                                <thead>
                                    <tr style="background-color: brown; height: 50px;">
                                        <th>Comment Category</th>
                                        <th>Add Comment </th>
                                    </tr>
                                    <tr *ngFor="let comment of  getComment.controls; let i = index" [formGroupName]="i">
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" placeholder="name" formControlName="name">
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <input matInput type="text" placeholder="comment" formControlName="comment">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </form>
                </div>
                
                </div>
                
                <div mat-dialog-actions class="action">
                    <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!kypForm.valid">Save</button>
                </div>
                
                
                </form> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>