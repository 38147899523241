import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-add-user-role',
  templateUrl: './add-user-role.component.html',
  styleUrls: ['./add-user-role.component.scss']
})
export class AddUserRoleComponent {
  userroleform: FormGroup;
  pageSize = 10;
  pageIndex = 0;
  userroles: any;
  message: any;
  selectedType = [];
  user: any;
  userrolesdata:any;

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService, private authService: AuthService,
    private _dialogRef: MatDialogRef<AddUserRoleComponent>,
    private toastrService:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    {
      this.authService.user.subscribe(x => this.user = x);
//console.log("udet", this.user.id);
//console.log("id",data)
      this.userroleform = this._fb.group({
        roles: ['', [Validators.required, Validators.maxLength(50)]],
        //userId: ['', [Validators.required, Validators.maxLength(50)]],
      });
    }
  }

  ngOnInit(): void {

    var user = localStorage.getItem("user");
    var dt = JSON.stringify(user)
    //console.log("user", user);
    //console.log("user", user);



    //this.userroleform.patchValue(this.data);
    // this.userroleform.value.userId.patchValue(this.data.id);
    //this.userroleform.value.userId = this.data.id;
    // this.userroleform.value.userId.patchValue(this.data.id);
    // var data = this.user.orgId;
    // console.log(data)
    this.getroles();
  }
  getroles() {
    this.adminService.getRoles(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
      .subscribe({
        next: (rolesdata: any) => {
          //this.loading = false;
          //console.log("userroles", rolesdata);
          this.userroles = rolesdata;
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //sthis.loading = false;
        }
      });
  }

  onFormSubmit() {
    var data = {
      //"roles": this.selectedType,
      "roleIds": this.selectedType,
      // "userId": this.data.id,
      "userId": this.data,
    }
    this.adminService.addUserRoles(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("User role has been updated successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        // this.toastrService.success("Error");
        this.toastrService.error(JSON.stringify(err.error.error));

        console.error(err);
      },
    });
  }

}
