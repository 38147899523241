import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AddAssignTaskComponent } from 'src/app/modal/add-assign-task/add-assign-task.component';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { CashflowService } from 'src/app/services/cashflow.service';
import { CustomerService } from 'src/app/services/customer.service';
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FormComponent } from '../case/form/form.component';
import { DataServiceService } from 'src/app/services/data-service.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { DataShareModel } from 'src/app/model/dataShareModel';
@Component({
  selector: 'app-assignment-cashless',
  templateUrl: './assignment-cashless.component.html',
  styleUrls: ['./assignment-cashless.component.scss']
})
export class AssignmentCashlessComponent implements OnInit, AfterViewInit {
  faClock = faClock;
  minDate: any;
  loading: any = false;
  error: any;
  message: any;
  user: any;
  assignedTasks: any = [];
  constructor(
    private dataService: DataServiceService,
    private authService: AuthService,
    private _dialog: MatDialog,
    private cashlessService: CashflowService,
    private workflowService: WorkflowService

  ) {
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;
    this.authService.user.subscribe(x => this.user = x);
    this.dataService.assignedTasksData$.subscribe((data:any) => {
      if(data != null && data.getProcessName() == "cashlessProcess"){
        this.assignedTasks = data;
      }
      
    });
  }
  ngAfterViewInit(): void {
    this.getAssignedTasks();
  }
  ngOnInit(): void {

  }



  assignTask(data: any) {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data: { data: data, roleName: ['ADMIN', 'CASHLESS'] },
      width: '430px',
      height: '200'
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
        }
      },
    });
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }
  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    console.log(this.user.roles);
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ dd);
    return dd;
  }

  viewTask(task: any) {
    const dialogRef = this._dialog.open(FormComponent, {
      disableClose:true,
      data: { task: task },
      width: '90%',
      height: '90%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        this.getNewTasks();
        this.getAssignedTasks();
      },
    });
  }

  getNewTasks() {
    this.workflowService
      .getNewTask("cashlessProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.newTasks = data;
          //console.log("newTasks " + JSON.stringify(this.newTasks));
          const dataShare = new DataShareModel("cashlessProcess",data);
          this.dataService.updateNewTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  getAssignedTasks() {
    this.workflowService
      .getAssignedTask("cashlessProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.assignedTasks = data;
          console.log("assignedTasks " + JSON.stringify(this.assignedTasks));

          const dataShare = new DataShareModel("cashlessProcess",data);
          this.dataService.updateAssignedTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  escalation(dueDate: number) {
    const currentDate = (new Date()).getTime();
    if (dueDate >= currentDate) {
      return false;
    } else {
      return true;
    }

  }

}



