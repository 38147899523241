import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeAndSpace'
})
export class CapitalizeAndSpacePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    // Add space before each capital letter
    const spacedText = value.replace(/([A-Z])/g, ' $1').trim();

    // Capitalize the first letter of each word
    return spacedText.replace(/\b\w/g, char => char.toUpperCase());
  }

}
