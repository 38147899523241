
<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Update User</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="empForm" (ngSubmit)="onFormSubmit()">
    <div class="container">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Id</mat-label>
        <input
          matInput
          type="text"
          placeholder="id"
          readonly
          formControlName="id"
          readonly
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input
          matInput
          type="text"
          placeholder="Ex. Technical"
          formControlName="firstName"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Ex. Babaji" formControlName="lastName" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>UserType</mat-label>
        <input
          matInput
          type="text"
          placeholder="userType"
          formControlName="userType"
          readonly
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          placeholder="Ex. test@gmail.com"
          formControlName="emailId"
          readonly
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input
          matInput
          type="text"
          placeholder="address"
          formControlName="address"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput type="text" placeholder="city" formControlName="city" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Zip</mat-label>
        <input matInput type="text" placeholder="zip" formControlName="zip" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Mobile</mat-label>
        <input
          matInput
          type="text"
          placeholder="mobilePhone"
          formControlName="mobilePhone"
          readonly
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ status }}</mat-label>
        <mat-select formControlName="inactive" name="inactiveData">
          <mat-option value="true"> Inactive </mat-option>
          <mat-option value="false"> Activate </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    </div>
  <div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">
      Cancel
    </button>
    <button mat-raised-button color="primary" type="submit">
      {{ data ? "Update" : "Save" }}
    </button>
  </div>
</form>
