import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { AddAssignTaskComponent } from 'src/app/modal/add-assign-task/add-assign-task.component';
import { DataShareModel } from 'src/app/model/dataShareModel';
import { AuthService } from 'src/app/services/authService';
import { DataServiceService } from 'src/app/services/data-service.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { ViewTaskComponent } from '../view-task/view-task.component';

@Component({
  selector: 'app-policy-consulting-inbox',
  templateUrl: './policy-consulting-inbox.component.html',
  styleUrl: './policy-consulting-inbox.component.scss'
})
export class PolicyConsultingInboxComponent implements OnInit {

  tasks: any[] = [];
  displayColumns: any = ['taskName', 'dueDate', 'orderId', 'customerName', 'mobileNumber', 'assignedTo', 'action'];
  dataSource = new MatTableDataSource<any>(this.tasks);
  user: any;
  error: any;
  allTasks:any;
  message: any;
  selectedTask: any;
  constructor(
    private _dialog: MatDialog,
    private workflowService: WorkflowService,
    private dataService: DataServiceService,
    private authService: AuthService
  ) {
    this.authService.user.subscribe(x => {
      this.user = x;
    });

    this.dataService.allTasksData$.subscribe((data: any) => {
      if (data != null && data.getProcessName() == "policyConsulting") {
        this.allTasks = data.tasks;
        this.dataSource.data =this.allTasks;
      }
    });
  }

  ngOnInit(): void {
    this.getAllTasks();

  }

  assignedTasks: any;
  assignTask(data: any) {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data: { data: data, roleName: ['ADMIN', 'HNB'] },
      width: '430px',
      height: '200'
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        this.getAllTasks();
      },
    });

  }

  
  getAllTasks() {
    this.workflowService
      .getAllTask("policyConsulting")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allTasks = data;
         const dataShare = new DataShareModel("policyConsulting",data);
          this.dataService.updateAllTasks(dataShare);
          
         // this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          //this.loading = false;
        },
      });
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  acceptTask(element: any) {
    this.selectedTask = element;
    this.workflowService.assignTask(this.selectedTask.id, this.user.id, new Date().toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.viewTask(element)
          this.getAllTasks();
        },
        error: (error: any) => {
          console.error('Error', error);
        }

      });
  }

  viewTask(element:any){
    const dialogRef = this._dialog.open(ViewTaskComponent, {
      disableClose:true,
      data: element,
      width: '80%',
      height: '80%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        this.getAllTasks();
      },
    });
  }

}
