import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-manage-insurance-logo',
  templateUrl: './manage-insurance-logo.component.html',
  styleUrl: './manage-insurance-logo.component.scss'
})
export class ManageInsuranceLogoComponent {
  icAddForm: any = [];
  selectedFiles?: any;
  message: any;
  icDataId: any;
  category: any;
  entityType: any;
  ictables: any;
  formData: FormData = new FormData();
  constructor(
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private fileService: FileService,
    private _dialogRef: MatDialogRef<ManageInsuranceLogoComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.icAddForm = this._fb.group({
      logo: [""],
    });
    console.log(JSON.stringify(data));
  }

  ngOnInit() {}
  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
    this.category = "LOGO";
    this.entityType = "IC_MASTER";
    console.log("this.selectedFiles", this.selectedFiles.name);
  }
  onFormSubmit() {
    console.log("onFormSubmit");
    this.icDataId = this.data.id;
    this.formData.append("file", this.selectedFiles);
    this.formData.append("entityId", this.icDataId);
    this.formData.append("category", "PROFILE");
    this.formData.append("sourceType", "LOGO");
    this.formData.append("entityType", this.entityType);
    this.icAddMedia(this.formData);
  }
  icAddMedia(data: any) {
    this.fileService.addExternalMedia(data).subscribe({
      next: (data: any) => {
        this.toastrService.success("IC Logo was updated successfully");        
        console.log("data " + data);
        this._dialogRef.close(data.id);
      },
      error: (error:any) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
        this.toastrService.error(JSON.stringify(error));
        this._dialogRef.close(true);
      },
    });
  }
}