import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';

@Component({
  selector: 'app-add-portal-details',
  templateUrl: './add-portal-details.component.html',
  styleUrl: './add-portal-details.component.scss'
})
export class AddPortalDetailsComponent implements OnInit {
  portalForm!:FormGroup;
  formData : any;

  constructor(
    private formBuilder : FormBuilder,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    
  }
  ngOnInit(): void {
    this.portalForm = this.formBuilder.group({
      url: ["",Validators.required],
      userName: ["",Validators.required],
      password: ["",Validators.required]
    });
    console.log(this.data)
  }

  onFormSubmit(){

    this.formData = {
      hospitalOrgId : this.data.hospitalOrgId,
      insuranceTpaid: this.data.insuranceId,
      portalUrl: this.portalForm.value.url,
      userId: this.portalForm.value.userName,
      password: this.portalForm.value.password,
      payorType: this.data.payorType
    }

   
    this.hospitalRegistrationService.addPortalInfo(this.formData)
    .pipe()
    .subscribe({
      next: (data: any) => {
        console.log(data);
        this.toastrService.success('Portal Details Added Successfully')
      },
      error: (error) => {
        console.log(error);
        this.toastrService.error('Portal Details count not be added ')
      }
    })

  }

}
