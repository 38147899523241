import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.scss']
})
export class UpdateProductComponent {

  productForm: FormGroup;
  orgId:any;
  user:any
  status:any;
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<UpdateProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    this.productForm = this._fb.group({
      id:'',
      name:['',Validators.required],
      description:['',Validators.required],
      price:['',[Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      shortName: ['',Validators.required],
      tcRequired:['',Validators.required],
      gst:['',[Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      processWorkflow:['',Validators.required],
     // orgId:'',
     inactive:['',Validators.required],
    });
  }


  // "id": "string",
  // "productId": "string",
  // "discountCode": "string",
  // "discountPercent": 0,
  // "discountNote": "string",
  // "startDate": "2023-11-01T05:05:36.709Z",
  // "endDate": "2023-11-01T05:05:36.709Z",
  // "maxCount": 0,
  // "partnerTypeId": 0,
  // "orgId": "string",
  // "dateCreated": "2023-11-01T05:05:36.709Z",
  // "createdBy": "string",
  // "inactive": true,
  // "currentCount": 0
  ngOnInit(): void {

  //  var user = localStorage.getItem("user")
  //   this.user=  user;
  //   this.user = this.orgId;
  //   console.log("orgid",this.user);
  this.status = this.data.inactive?"Inactive":"Activate";
  this.productForm.controls['inactive'].setValue(this.status);
  this.productForm.patchValue(this.data);
    //this.productForm.inactive.patchValue(status);
    //this.productForm.value.inactive.patchValue(status)


    // var data = this.user.orgId;
    //console.log(this.data)
   }

  onFormSubmit() {
    if (this.productForm.valid) {
      if (this.data) {
        this.adminService
          .updateProduct( this.productForm.value)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("Product has been updated successfully");


              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              //this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));


            },
          });
      } 
      }
    }
  
}