<div class="container">
  <div class="row p-3 py-4 bg-light">
    <div class="col-9 p-3 d-flex justify-content-start">
      <h1>Technical Configration for {{ data.name }}</h1>
    </div>

    <div class="col p-2 d-flex justify-content-end">
      <h4>
        <i class="p-2" style="color: rgb(183, 0, 0)"
          >* <span style="color: lightgray">marked fields are mandatory</span>
        </i>
      </h4>

      <div class="p-2 example-button-container" mat-dialog-close>
        <button style="color: white; background: #8e0101" mat-mini-fab>
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="row">
        <div class="">
          <label for="select-insurance-companies"
            >Select Insurance Company
          </label>
          <select
            (change)="onChangeInsuranceCompanyId($event.target)"
            class="form-select my-2"
            formControlName="selectInsuranceCompanies"
            id="select-insurance-companies"
          >
            <option
              *ngFor="let insuranceCompany of insuranceCompanies"
              [value]="insuranceCompany | json"
            >
              {{ insuranceCompany.insuranceName }}
            </option>
          </select>
        </div>
        <div class="">
          <label for="claimSubmissionMethod"
            >Claim Submission Information
          </label>
          <input
            readonly
            type="text"
            class="form-control"
            id="claimSubmissionMethod"
            formControlName="claimSubmissionMethod"
          />
        </div>
        <div class="">
          <label for="configdata">Config Data </label>
          <textarea
            formControlName="configdata"
            class="form-control"
            id="configdata"
            rows="3"
          ></textarea>
        </div>
        <div mat-dialog-actions class="action">
          <button mat-raised-button type="button" [mat-dialog-close]="false">
            Cancel
          </button>
          <button
            mat-flat-button
            style="color: white; background: #8e0101"
            type="button"
            (click)="uploadFiles()"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
