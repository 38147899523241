import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CashflowService } from 'src/app/services/cashflow.service';

@Component({
  selector: 'app-manage-workflow',
  templateUrl: './manage-workflow.component.html',
  styleUrl: './manage-workflow.component.scss'
})
export class ManageWorkflowComponent implements OnInit{

  constructor(
    private cashlessService:CashflowService,
    private adminService: AdminService,

  ){

  }
  ngOnInit(){
    this.getDeployedProcesses();
  }
  deployedprocessByProcessDef:any=[];
  getDeployedProcesses(){
    this.cashlessService.getDeployedProcesses().subscribe({
      next: (data: any) => {
        //this.loading = false;
        this.deployedprocessByProcessDef = data;
        console.log("Deployed Processes", this.deployedprocessByProcessDef);
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        //this.message = error;

        //this.loading = false;
      },
    });
  }
  deployedprocessByProcessDefTasks:any=[];
  selectedKey:any;
  getDeployedprocessByProcessDefTasks(processKey : any) {
    this.selectedKey = processKey;
    this.adminService
      .getDeployedprocessByProcessDefKey(processKey )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.deployedprocessByProcessDefTasks = data;
          console.log("Tasks", this.deployedprocessByProcessDefTasks);
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
        },
      });
  }

}
