<!-- <div mat-dialog-title>
    <h1>Update Insurance Company</h1>
</div> -->

<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Update Insurance Company</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="insuranceForm" (ngSubmit)="onFormSubmit()">
  <div mat-dialog-content class="content">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Id</mat-label>
        <input
          matInput
          type="text"
          placeholder="id"
          formControlName="id"
          readonly
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          placeholder="Name"
          formControlName="nameReg"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Name Short</mat-label>
        <input
          matInput
          type="email"
          placeholder="Name Short"
          formControlName="nameShort"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Claim Support Addr</mat-label>
        <input
          matInput
          type="text"
          placeholder="Claim Support Addr"
          formControlName="claimSupportAddr"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Contact Number</mat-label>
        <input
          matInput
          type="text"
          placeholder="Contact Number"
          formControlName="contactNumber"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Claim Support Number</mat-label>
        <input
          matInput
          type="text"
          placeholder="claimSupportNumber"
          formControlName="claimSupportNumber"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Claim Support Email</mat-label>
        <input
          matInput
          type="email"
          placeholder="Claim Support Email"
          formControlName="claimSupportEmail"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Claim</mat-label>
        <input
          matInput
          type="text"
          placeholder="Claim"
          formControlName="claim"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>sector</mat-label>
        <input
          matInput
          type="text"
          placeholder="sector"
          formControlName="sector"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>insuranceCoverage</mat-label>
        <input
          matInput
          type="text"
          placeholder="insuranceCoverage"
          formControlName="insuranceCoverage"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>website</mat-label>
        <input
          matInput
          type="website"
          placeholder="website"
          formControlName="website"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ status }}</mat-label>
        <mat-select formControlName="inactive" name="inactiveData">
          <mat-option value="true"> Inactive </mat-option>
          <mat-option value="false"> Activate </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ automated }}</mat-label>
        <mat-select formControlName="automated" name="automated">
          <mat-option value="true"> Automated </mat-option>
          <mat-option value="false"> Non-Automated </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ inhouse }}</mat-label>
        <mat-select formControlName="inhouse" name="inhouse">
          <mat-option value="true"> Inhouse </mat-option>
          <mat-option value="false"> Non-Inhouse </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appearance="outline">
                <mat-label>mailParserConfs</mat-label>
                <mat-select formControlName="mailParserConfs" [(ngModel)]="selectedType" name="company" multiple>
                  <mat-option *ngFor="let company of emailConfig" [value]="company.id">
                      {{company.category}} {{company.seqNo}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

      <!-- <h1>mail Parser Confs</h1>


                <mat-form-field appearance="outline">
                    <mat-label>Claim Support Email</mat-label>
                    <input matInput type="email" placeholder="Claim Support Email" formControlName="claimSupportEmail" >
                </mat-form-field>
    
                 <mat-form-field appearance="outline">
                    <mat-label>Claim</mat-label>
                    <input matInput type="email" placeholder="Claim" formControlName="claim" >
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>sector</mat-label>
                    <input matInput type="email" placeholder="sector" formControlName="sector" >
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>insuranceCoverage</mat-label>
                    <input matInput type="insuranceCoverage" placeholder="insuranceCoverage" formControlName="insuranceCoverage" >
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>website</mat-label>
                    <input matInput type="website" placeholder="website" formControlName="website" >
                </mat-form-field> -->

      <!-- <mat-form-field appearance="outline">
                <mat-label>mailParserConfs</mat-label>
                <mat-select formControlName="mailParserConfs" [(ngModel)]="selectedType" name="company" multiple>
                  <mat-option *ngFor="let company of emailConfig" [value]="company.id">
                      {{company.category}}
                  </mat-option>
                </mat-select>
            </mat-form-field>  -->
    </div>
    <!-- <button mat-raised-button color="primary">Add Mail Parser</button> -->
  </div>
  <div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">
      Cancel
    </button>
    <button mat-raised-button color="primary" type="submit">
      {{ data ? "Update" : "Save" }}
    </button>
  </div>
</form>
