import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { AddEmailConfComponent } from "./add-email-conf/add-email-conf.component";
import { UpdateEmailConfComponent } from "./update-email-conf/update-email-conf.component";
// import { EditOrgComponent } from "./edit-org/edit-org.component";


@Component({
  selector: 'app-email-conf',
  //standalone: true,
  //imports: [],
  templateUrl: './email-conf.component.html',
  styleUrl: './email-conf.component.scss'
})
export class EmailConfComponent {
  emailConfig: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private HospitalRegistrationService: HospitalRegistrationService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    console.log(JSON.stringify(this.data))
  }
  ngOnInit(): void {
    this.getEmailCong(this.data.element.id);//TODO
  }
  dataSource = new MatTableDataSource<any>(this.emailConfig);
  displayedColumns: string[] = [
    "Id",
    "category",
    "emailId",  
    "matchRegex",
    "matchSet",
  
    "subjectFilter",
    "Action",
  ];
  loading: boolean = false;
  message: any;

  form!: FormGroup;
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getEmailCong(this.data.element.id);//TODO
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  getEmailCong(hospitalOrgId:any) {
    this.HospitalRegistrationService
      .getEmailConfig(hospitalOrgId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.loading = false;
          //console.log(data);
          this.emailConfig = data;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          this.loading = false;
        },
      });
  }

  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.emailConfig =
        query === ""
          ? this.emailConfig
          : this.emailConfig.filter((item: any) => {
              return Object.values(item).some((val) =>
                String(val).toLowerCase().includes(query)
              );
            });
    } else {
      this.getEmailCong(this.data.element.id);//TODO
    }
  }

  openEditForm(data: any) {
    const dialogRef = this._dialog.open(UpdateEmailConfComponent, {
      disableClose:true,
      data: { data: data,senderId:this.data.element.id},

    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getEmailCong(this.data.element.id);//TODO
        }
      },
    });
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddEmailConfComponent, {
      disableClose:true,
      data: {id:this.data.element.id,
        name:this.data.element.nameReg
      }


    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getEmailCong(this.data.element.id);//TODO
        }
      },
    });
  }
}
