<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Edit Technical Configuration for {{ data.name }}</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray">* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="card">
    <div class="card-body">
      <table mat-table [dataSource]="insuranceDataSource">
        <ng-container matColumnDef="payor">
          <th mat-header-cell *matHeaderCellDef>Payor</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Insurance Name</mat-label> -->
              <input matInput readonly type="text" class="form-control" [(ngModel)]="element.insuranceName" />
            </mat-form-field>
           
          </td>
        </ng-container>

        <ng-conatiner matColumnDef="branch">
          <th mat-header-cell *matHeaderCellDef>Branch</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Branch</mat-label> -->
              <input matInput readonly type="text" class="form-control" [(ngModel)]="element.branch" />
            </mat-form-field>
            
          </td>

        </ng-conatiner>
        <ng-container matColumnDef="dateRange">
          <th mat-header-cell *matHeaderCellDef>Valid From - Valid To</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Enter a date range</mat-label> -->
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start Date" [(ngModel)]="element.startDate">
                <input matEndDate placeholder="End Date" [(ngModel)]="element.endDate">
              </mat-date-range-input>
              <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
            <!-- <div class="date-range-container form-select">
              <mat-date-range-input [rangePicker]="dateRange" [disabled]="element.isDisabled">
                <input matStartDate placeholder="Start Date" [(ngModel)]="element.startDate">
                <input matEndDate placeholder="End Date" [(ngModel)]="element.endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="dateRange"></mat-datepicker-toggle>
              <mat-date-range-picker #dateRange></mat-date-range-picker>
            </div> -->
          </td>
        </ng-container>


        <ng-container matColumnDef="blacklisted">
          <th mat-header-cell *matHeaderCellDef>Blacklisted</th>
          <td mat-cell *matCellDef="let element">
           
            <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="element.blacklisted" [disabled]="element.isDisabled"
              (change)="onChangeInsuranceCompanyblacklisted($event.target)" >
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
              
            </mat-select>
                
          </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="submissionType">
          <th mat-header-cell *matHeaderCellDef>Submission Type</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance=outline>

            <mat-select [(ngModel)]="element.claimSubmissionMethod" [disabled]="element.isDisabled"
              (change)="onChangeInsuranceCompanyclaims($event.target)" >
              <mat-option value="EMAIL">EMAIL</mat-option>
              <mat-option value="PORTAL">PORTAL</mat-option>
              <mat-option value="INTEGRATION">INTEGRATION</mat-option>
            </mat-select>
            
          </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="uploadSOC">
          <th mat-header-cell *matHeaderCellDef>Upload SOC</th>
          <td mat-cell *matCellDef="let element">
            <div class="content">
              <button type="button" mat-flat-button [ngStyle]="{'background' : '#8e0101', 'color':'white'}"
                (click)="attachSOC(element)">Attach SOC</button>
              <mat-icon *ngIf="element.isDisabled">check_circle</mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row ; columns: displayColumns"></tr>
      </table>

      <!-- <form class="overflow-card" [formGroup]="form">
        <div class="container row">
          <div class="">
            <label for="insuranceName">Selected Insurance Company </label>
            <input
              readonly
              type="text"
              class="form-control"
              id="insuranceName"
              formControlName="insuranceName"
            />
          </div>
          <div class="">
            <label for="branch">Branch </label>
            <input
              type="text"
              class="form-control"
              id="branch"
              formControlName="branch"
            />
          </div>
          <div class="">
            <label for="inHouse">Inhouse </label>
            <select formControlName="inHouse" placeholder="In House"  class="form-select my-2">
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div class="">
            <label for="tpa">TPA </label>
            <input
              type="text"
              class="form-control"
              id="tpa"
              formControlName="tpa"
            />
          </div>
          <div class="">
            <label for="activationStatus">Activation Status </label>
            <select
            
              class="form-control"
              id="activationStatus"
              formControlName="activationStatus"
            >
            <option value="false">Active</option>
            <option value="true ">Inactive</option>
            </select>
          </div>
          <div class="">
            <label for="blacklisted">Select Blacklisted Type </label>
            <select
              (change)="onChangeInsuranceCompanyblacklisted($event.target)"
              class="form-select my-2"
              formControlName="blacklisted"
              id="blacklisted"
            >
              <option value="true">Yes</option>
              <option selected value="false">No</option>
            </select>
          </div>
          <div class="">
            <label for="claimSubmissionMethod"
              >Select Claim Submission Method Type
            </label>
            <select
              (change)="onChangeInsuranceCompanyclaims($event.target)"
              class="form-select my-2"
              formControlName="claimSubmissionMethod"
              id="claimSubmissionMethod"
            >
              <option value="EMAIL">EMAIL</option>
              <option value="PORTAL">PORTAL</option>
              <option value="INTEGRATION">INTEGRATION</option>
            </select>
          </div>
          <div class="row pt-2">
            <label class="normal-font">Valid From </label>
            <input class="form-control" formControlName="validFrom" type="date"  [(ngModel)]="validFrom"/>
          </div>

          <div class="row pt-2">
            <label class="normal-font">Valid To</label>
            <input class="form-control"formControlName="validTo" type="date" [(ngModel)]="validTo"/>
          </div>
          <div>
            <label for="upload">Upload SOC</label>
            <div class="col">
              <label class="btn btn-default p-0">
                <input
                  class="form-control my-2"
                  accept="application/pdf"
                  type="file"
                  formControlName="upload"
                  (change)="selectFile($event)"
                  id="upload"
                />
              </label>
              <ul class="list-group list-group-flush">
                <li
                  *ngFor="let file of fileInfos | async"
                  class="list-group-item"
                >
                  <a href="{{ file.url }}">{{ file.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        
          <div mat-dialog-actions class="action">
            <button mat-raised-button type="button" [mat-dialog-close]="false">
              Cancel
            </button>
            <button
              mat-flat-button
              style="color: white; background: #8e0101"
              type="button"
              [disabled]="selectedFiles == undefined || clicked"
              (click)="uploadFiles(); clicked = true"
            >
              Update
            </button>
          </div>
        </div>
      </form> -->
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>