import { Component } from '@angular/core';

@Component({
  selector: 'app-view-medical-report',
  templateUrl: './view-medical-report.component.html',
  styleUrls: ['./view-medical-report.component.scss']
})
export class ViewMedicalReportComponent {

}
