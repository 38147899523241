import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { CustomerService } from "src/app/services/customer.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { CashflowService } from "src/app/services/cashflow.service";

@Component({
  selector: 'app-update-task-calculation',
  //standalone: true,
  //imports: [],
  templateUrl: './update-task-calculation.component.html',
  styleUrl: './update-task-calculation.component.scss'
})
export class UpdateTaskCalculationComponent implements  OnInit, AfterViewInit {

  taskCalculationForm: FormGroup;
  //orgId:any;
  userorg: any;
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  orgs: any;
  partnerTypes: any;
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;
  todayDate: Date = new Date();
  deployedprocessByProcessDefKey: any;
  selectedDeployeedProcessEntity: any;
  selectedDeployedSubEntityProcessByProcessDefKey: any;
  selectedattributes: any = [];
  selectedSubEntity:any =[];
  selected:any;
  status:any;

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private cashlessservice: CashflowService,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UpdateTaskCalculationComponent>
  ) {
    //this.setProcess(this.data.id.taskName)
    this.getDeployedprocessByProcessDefKey(this.data.id.processName);

    this.taskCalculationForm = this._fb.group({
      // id: '',
      name: ["", Validators.required],
      calculationExpression: ["", [Validators.required]],
      processVersion: ["", [Validators.required]],
      processName: ["", [Validators.required]],
      taskName: "",
      inactive: "",
      attributeName: "",
    });
  }

  ngOnInit(): void {
    this.getDeployedProcesses();
    this.taskCalculationForm.patchValue(this.data);
    this.taskCalculationForm.controls['processVersion'].setValue(this.data.id.processVersion);
    this.status = this.data.inactive ? "Inactive" : "Activate";
    this.taskCalculationForm.controls['inactive'].setValue(this.status);
    //this.taskCalculationForm.controls['attributeName'].setValue(this.data.id.attributeName);
    //this.taskCalculationForm.controls['processName'].setValue(this.data.id.processName);
    //this.taskCalculationForm.controls['taskName'].setValue(this.data.id.taskName);
this.selectedDeployeedProcessEntity = this.data.id.processName;
this.selectedDeployedSubEntityProcessByProcessDefKey = this.data.id.taskName;
//this.selectedDeployedSubEntityProcessByProcessDefKey = this.data.id.taskName;
//this.selectedattributes = this.data.id.attributeName
// this.setProcess(this.data.id.taskName)
    //this.taskCalculationForm.controls['type'].setValue(this.data.id.type);
    //this.selectedDeployeedProcessEntity.push(this.data.id.key)
    console.log("gddf",this.data);
   // this.selected = this.data.id.processName;
  //  this.getDeployedprocessByProcessDefKey(this.data.id.processName);




  }

  ngAfterViewInit(): void {
    // this.selectedDeployedSubEntityProcessByProcessDefKey = this.data.id.taskName;
    //this.selectedattributes = this.data.id.attributeName;


  }

  deployedAllProcesses: any;

  getDeployedProcesses() {
    this.cashlessservice
      .getDeployedProcesses()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.deployedAllProcesses = data;
          // this.selectedDeployedSubEntityProcessByProcessDefKey.push(priv.id);

        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No products available for!";
          }
        },
      });
  }

  key: any;
  setProcess(event: any) {
    // console.log("Country selected:"+JSON.stringify(event.value));
    this.key = event.value;

    this.getDeployedprocessByProcessDefKey(this.key);
  }
  setSubProcess(event: any) {
    //this.taskName = event.value;
    console.log(event);
  }

  selectedtask(data:any)
  {
console.log("vv",data);
this.selectedSubEntity = data;

  }
  getDeployedprocessByProcessDefKey(key: any) {
    this.adminService
      .getDeployedprocessByProcessDefKey(key)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.deployedprocessByProcessDefKey = data;
          console.log("fsff", this.deployedprocessByProcessDefKey);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //this.loading = false;
        },
      });
    // console.log(this.states);
    // this.adminService.getStates(countryId).subscribe({
    //   next:(data:any)=>{
    //     this.states =  data.states;
    //   }
    // })
  }

  onFormSubmit() {
    var data = {
      name: this.taskCalculationForm.value.name,
      calculationExpression:
        this.taskCalculationForm.value.calculationExpression,
      processVersion: this.taskCalculationForm.value.processVersion,
    //attributeName:   this.data.id.attributeName.join(","),
      attributeName: this.taskCalculationForm.value.attributeName.join(","),
       processName: this.taskCalculationForm.value.processName,
       taskName: this.taskCalculationForm.value.taskName,
      // "inactive": this.taskCalculationForm.value.inactive
      // processName: this.data.id.processName,
       //taskName: this.data.id.taskName,
      inactive: true,
    };

    this.adminService.updateTaskCalculation(data).subscribe({
      next: (val: any) => {
        this.toastrService.success(
          "Task calculation has been updated successfully"
        );
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));
      },
    });
  }
}
