
<div class="container p-3">
    
    <div class="container d-flex justify-content-md-center">
        <form [formGroup]="ekypProcess">
          <mat-form-field>
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="fromDate" (dateChange)="onChangeEvent($event)">
            <mat-hint>MM/DD/YYYY</mat-hint>
      
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </form>
      </div>
      

    <div class="container py-3 px-2">
        <div class="card px-3 py-4 justify-content-md-center">
        
            <div class="row">
                <div class="col-4 text-center ">
                    <h4>User</h4>
                </div>
                <div class="col text-center">
                    <h4>Task</h4>
                </div>
                <div class="col text-center">
                    <h4>Execution Count</h4>
                </div>
                <div class="col text-center">
                    <h4>Avg. Duration (Mins)</h4>
                </div>
                <div class="col text-center">
                    <h4>TAT</h4>
                </div>
            </div>
            <hr>
            <div class="row h5" *ngFor="let element of performance.userPerformance">
                <div class="col-4 text-center">
                    {{element.username}}
                </div>
                <div class="col text-center">
                    <div class="row" *ngFor="let task of element.tasks">
                        <div class="col text-center">
                            {{task.taskName}} 
                        </div>
                        <div class="col text-center">
                            {{task.count }}
                        </div>
                        <div class="col text-center">
                            {{task.averageDuration/(1000*60*60) | number}}
                        </div>
                        <div class="col text-center">
                            {{task.tat}}
                        </div>
                    </div>
                    
                </div>
            </div>
          </div>
      </div>
</div>