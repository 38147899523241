<section class="container-fluid">
  <div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add Tpa</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="tpaAddForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
      <div class="row">
        <button type="button" mat-raised-button (click)="fileInput.click()">
          Choose Logo
        </button>
        <input
          hidden
          formControlName="logo"
          (input)="selectFile($event)"
          #fileInput
          type="file"
        />
        <span class="file-name">{{ selectedFiles?.name }}</span>
      </div>
    </div>

    <div mat-dialog-actions class="action">
      <button mat-raised-button type="button" [mat-dialog-close]="false">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!tpaAddForm.valid"
        type="submit"
      >
        Save
      </button>
    </div>
  </form>
</section>
