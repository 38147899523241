<!-- <div mat-dialog-title>
  <h1>Add Coupon</h1>
</div> -->
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add Category Master</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="categoryForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
      <div class="row">
              <mat-form-field appearance="outline">
                  <mat-label>category</mat-label>
                  <input matInput type="text" placeholder=""  formControlName="category"  >
              </mat-form-field>
          </div>
      <div class="row">
        <mat-form-field appearance="outline">
                  <mat-label>category Name</mat-label>
                  <input matInput type="text" placeholder="" formControlName="categoryName">
              </mat-form-field>

              <!-- <mat-form-field appearance="outline">
                <mat-label>type</mat-label>
                <input matInput type="text" placeholder="" formControlName="type">
            </mat-form-field> -->


            <mat-form-field appearance="outline">
                <mat-label>type</mat-label>
                <mat-select formControlName="type" name="inactiveData">
                  <mat-option value="DROPDOWN"> DROPDOWN </mat-option>
                  <mat-option value="FILE"> FILE </mat-option>
                </mat-select>
              </mat-form-field>  
            <mat-form-field appearance="outline">
                <mat-label>categoryDescription</mat-label>
                <input matInput type="text" placeholder="" formControlName="categoryDescription">
            </mat-form-field>
        
           
        <mat-form-field appearance="outline">
          <mat-label>Entity-type</mat-label>
          <mat-select
            formControlName="deployeedallprocess"
            (selectionChange)="setProcess($event)"
            [(ngModel)]="selectedDeployeedProcessEntity"
            name="Entity"
          >
            <mat-option *ngFor="let process of deployedAllProcesses" [value]="process.key">
              {{ process.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Sub-entity-type</mat-label>
            <!-- <input matInput type="text" placeholder="state" formControlName="state"> -->
            <mat-select
              formControlName="deployedprocessByProcessDefKey"
              [(ngModel)]="selectedDeployedSubEntityProcessByProcessDefKey"

              name="Sub-Entity" >
              <mat-option *ngFor="let process of deployedprocessByProcessDefKey" [value]="process.taskId">
                {{ process.taskName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>tags</mat-label>
            <!-- <input matInput type="text" placeholder="state" formControlName="state"> -->
            <mat-select
              formControlName="tags"
              [(ngModel)]="selectedtags"

              name="Sub-Entity" multiple>
              <mat-option *ngFor="let process of deployedprocessByProcessDefKey" [value]="process.taskId">
                {{ process.taskName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

                        
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select formControlName="inactive" name="inactiveData">
          <mat-option value="true"> Inactive </mat-option>
          <mat-option value="false"> Activate </mat-option>
        </mat-select>
      </mat-form-field> 
        
      
<!--       
        <mat-form-field appearance="outline">
          <mat-label>Discount percent</mat-label>
          <input
            matInput
            type="text"
            placeholder="Discount percent"
            formControlName="discountPercent"
          />
        </mat-form-field> -->
  
        <!-- <mat-form-field appearance="outline">
          <mat-label>Discount note</mat-label>
          <input
            matInput
            type="email"
            placeholder="Discount note"
            formControlName="discountNote"
          />
        </mat-form-field> -->
    
  
        <!-- <mat-form-field appearance="outline">
                  <mat-label>Start date</mat-label>
                  <input matInput type="text" placeholder="Start date" formControlName="startDate">
              </mat-form-field> -->
       
  
  
        <!-- <mat-form-field appearance="outline">
                  <mat-label>End date</mat-label>
                  <input matInput type="text" placeholder="End date" formControlName="endDate">
              </mat-form-field> -->
       
      
      <!-- <div class="row">
              <mat-form-field appearance="outline">
                  <mat-label>Org id</mat-label>
                  <input matInput type="text" placeholder="zip" formControlName="orgId">
              </mat-form-field>
          </div> -->
      <!-- <mat-form-field appearance="outline">
        <mat-label>Org Name</mat-label>
        <mat-select
          formControlName="orgId"
          [(ngModel)]="selectedOrgsTypeId"
          name="id"
        >
          <mat-option *ngFor="let org of orgs" [value]="org.id">
            {{ org.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    
      
<!--     
      <mat-form-field appearance="outline">
        <mat-label>Partner Type Name</mat-label>
        <mat-select
          formControlName="orgId"
          [(ngModel)]="selectedPartnerTypeId"
          name="id"
          formControlName="partnerTypeId"
        >
          <mat-option *ngFor="let partner of partnerTypes" [value]="partner.id">
            {{ partner.type }} {{ partner.subType }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      </div>
    </div>
    <!-- [disabled]="!categoryForm.valid" -->

    <div mat-dialog-actions class="action">
      <button mat-raised-button type="button" [mat-dialog-close]="false">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
      >
        Save
      </button>
    </div>
  </form>
  