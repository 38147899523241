import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { LocatorReq } from 'src/app/model/locatorReq';

@Component({
  selector: 'app-hospital-locator',
  templateUrl: './hospital-locator.component.html',
  styleUrls: ['./hospital-locator.component.scss']
})
export class HospitalLocatorComponent {
  latitude: any;
  longitude: any
  locatorReq: any
  loading = false;
  hospitals: any;
  results: any;
  error: any;
  loc_radius:any
  constructor(
    private customerService: CustomerService,
  ) {

  }
  ngOnInit(): void {
    this.getCurrentLocation();
  }


  hospitalLocator() {
    this.loading = true;
    this.locatorReq = {
      lat: this.latitude, lng: this.longitude,
      radius: this.loc_radius?this.loc_radius:3
    }
    this.customerService.hospitalLocator(this.locatorReq).pipe(take(4))
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.hospitals = data;
          this.results = data;
          //console.log("fsf", this.results);
          this.error = undefined;
          this.loading = false;
        },
        error: (err: { error: any; }) => {
          this.error = err.error;
          console.log(this.error);
        }
      });
  }

  handleInput(event: any) {
    const query = event.target.value.toLowerCase();
    // this.results = this.hospitals.filter((d: any) => d.empanelledInsuranceCompanies.find((f: any) => f.name.toLowerCase().indexOf(query) > -1) != null);
    this.results = this.hospitals.filter((d: any) => d.empanelledInsuranceCompanies.find((f: any) => f.name.toLowerCase().indexOf(query) > -1) != null);

  }

  radius: number = 3;
  radiusChanged(event: any) {
    this.radius = event.target.value;
   // console.log("Radius " + this.radius);
    // this.locatorReq.radius = this.radius / 1.6;
    this.loc_radius = this.radius / 1.6;
    if (this.radius != undefined && this.radius > 1) {
      this.getCurrentLocation();
    }

  }
  getCurrentLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        //console.log("ty", resp);
        this.latitude = resp.coords.latitude;
        this.longitude = resp.coords.longitude;
        this.hospitalLocator();

      },
        err => {
          reject(err);
        });
    });

  }

  openMap(lat: any, lng: any) {
    var location = "" + lat + ", " + lng;
   // window.location.href = 'https://maps.google.com/maps?q=19.3987711,72.8170496'
    window.location.href = 'https://maps.google.com/maps?q=' + lat + ',' + lng

  }


}
