import { Component, ElementRef, HostListener, Inject } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { debounceTime, distinctUntilChanged, first, Observable, of, switchMap, tap } from "rxjs";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { ToastrService } from 'ngx-toastr';
import { FileUploadComponent } from "src/app/file-upload/file-upload.component";
import { ViewUploadFileComponent } from "src/app/file-upload/view-upload-file/view-upload-file.component";
import { InsuranceComponent } from "src/app/onboarding/insurance/insurance.component";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";
import { DatePipe } from "@angular/common";
import { AddKypComponent } from "src/app/portal/bg/inbox/add-kyp/add-kyp.component";
import { EditKypComponent } from "src/app/portal/bg/inbox/edit-kyp/edit-kyp.component";
import { AuthService } from "src/app/services/authService";
import { WorkflowService } from "src/app/services/workflow.service";
import { ClaimsComponent } from "src/app/portal/bg/claims/claims.component";
import { FileSelectDirective } from "ng2-file-upload";
import { FormComponent } from "src/app/cashless/case/form/form.component";
import { BgProductComponent } from "../bg-product/bg-product.component";
import { FileService } from "src/app/services/file.service";

@Component({
  selector: "app-onboarding-form",
  templateUrl: "./onboarding-form.component.html",
  styleUrls: ["./onboarding-form.component.scss"],
})
export class OnboardingFormComponent
  {

    id: any;
    route: any;
    addkyp: boolean = false;
    taskDetailsNew!: FormGroup;
    tpaList: any = [];
    insuranceCompanyList: any = [];
    displayedColumns: string[] = ["docType", "fileName", "createdDate", "view"];
    selectedFiles?: any;
    selectedOption: any;
    uploadedFiles: any = [];
    dataSource = new MatTableDataSource<any>(this.uploadedFiles);
    length = 100;
    pageSize = 10;
    pageIndex = 0;
    pageSizeOptions = [5, 10, 25];
    formData: FormData = new FormData();
    customerId: any;
    createCase!: FormGroup;
    selectedoption = null;
    DoYouhaveFamilyPhysician = null;
    assignedTasks: any[] = [];
    loading: any = false;
    error: any;
    rowNum = 0;
    inputValue: number = 0;
    showError = false;
    showDateError = false;
    size = 100;
    selectedTaskDetails: any;
    message: any;
    form: FormGroup = this.formBuilder.group({});
    previousDetails: FormGroup = this.formBuilder.group({});
    tasks: any[] = [];
    diagram: any;
    submitted: boolean = false;
    currentState: any;
    insuranceMedia: any = '';
    uploadedData: any = [];
    maxDate = new Date();
    formattedDate: any;
    comparedWith: any;
    user: any;
    taskForms: FormGroup[] = [];
    taskFormData: any[] = [];
    salesAgent$: Observable<any[]> = of([]);
   
    constructor(
      private elementRef: ElementRef,
      public dialogRef: MatDialogRef<FormComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private router: Router,
      private formBuilder: FormBuilder,
      private cashlessService: CashflowService,
      private fileService: FileService,
      private HospitalRegistrationService: HospitalRegistrationService,
      private _dialog: MatDialog,
      private toastrService: ToastrService,
      private tpaService: ManagetpaService,
      private datePipe: DatePipe,
      private authService: AuthService,
      private workflowService: WorkflowService
    ) {
      //this.currentState = this.router.getCurrentNavigation()?.extras.state;
      //console.log("currentState", this.currentState);
      // console.log("part2", this.data.task.task);
      // console.log("here", this.id);
    }
  
    ngOnInit() {
      this.authService.user.subscribe(x => this.user = x);
      // console.log("here", this.customerId);
      console.log("part2", this.data.task);
      this.getTaskDetailsByTask();
      this.maxDate = new Date();
      // console.log(this.maxDate);
      this.initializeForm();
      // this.getCategory();
      this.inputValue = 0;
      this.getTpa();
      //this.getSelectedTaskDetails();
      this.getTaskCalculations();
      //this.subscribeToControlValueChanges();
      this.getViewFileList();
      this.getHospitalInsurance();
      console.log(this.form.get('salesAgent_search')?.value);
      this.salesAgent$ = this.form.get('salesAgent_search')?.valueChanges
      ? this.form.get('salesAgent_search')!.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap((value: any) => {
            // Handle the value as needed
          }),
          switchMap((value: any) =>
            typeof value === 'string' && value.length > 0
              ? this.cashlessService.getAgentsBySearch(value)
              : of([])
          )
        )
      : of([]); 
    
    }
  
    checkDate(event: any, id:any) {
      console.log(event);
      const selectedDate = this.datePipe.transform(event, 'yyyy-MM-dd');
      console.log(selectedDate);
      this.formattedDate = this.datePipe.transform(new Date(this.maxDate), 'yyyy-MM-dd');
         console.log(this.formattedDate);
      // this.showDateError = event> this.formattedDate;
      if (selectedDate && (selectedDate > this.formattedDate) && (id === 'dob')) {
        // console.log("date more than today ");
        this.showDateError = true;
  
      } else {
        this.showDateError = false;
      }
    }
    onChangeInsuranceCompanyId(arg0: any) {
      if (arg0) {
        this.insuranceMedia = JSON.parse(arg0.value);
        console.log(this.insuranceMedia);
      }
  
      // this.formData = new FormData();
      // this.formData.append("files",'');
      // this.formData.append("docType", 'soc');
    }
    onInputChange(value: number) {
      this.inputValue = value;
      this.showError = value.toString().length !== 10; // Check for exact 10 digits
    }
  
    submitForm() {
      Object.keys(this.form.controls).forEach((controlName) => {
       console.log(controlName+" "+this.form.get(controlName)?.value);
      });
      
      if (!this.form.valid) {
        Object.keys(this.form.controls).forEach((controlName) => {
          this.form.get(controlName)?.markAsTouched();
        });
      } else {
        //console.log("Submit Form");
        console.log(this.form.value); // Log form values for debugging
       // this.form.get('select-multi-drugAdministrationRoute')?.value
        this.submitted = true;
  
        this.cashlessService
          .postFormData(this.data.task.id, this.form.value)
          .pipe(first())
          .subscribe({
            next: () => {
              this.loading = false;
              // console.log("done");
              this.toastrService.success(this.data.task.name, "has been updated successfully");
              this.submitted = false;
              this.dialogRef.close();
        
            },
            error: (error) => {
              console.log("error " + JSON.stringify(error));
              this.submitted = false;
              this.toastrService.error("Please fill in the required fields ");
              this.message = error.error.error;
  
            },
          });
      }
    }
    initializeForm() {
      //console.log("part2", this.data.task.formDataFieldOptions);
      this.getInsuranceByHospitalId(this.data.task.caseVariables.hospitalId);
      this.getBPMNDiagram(this.data.task.processInstanceId);
      if (this.data.task) {
        const task = this.data.task; // assuming you only deal with the first task for now
        const formControls: Record<string, FormControl> = {};
  
        task.formDataFieldOptions.forEach((field: { property: { required: any; id: string | number; value: any }; }) => {
          const validators = field.property.required ? [Validators.required] : [];
  
          // Add phone number validation if field ID contains 'contact'
          if (field.property.id.toString().toLowerCase().includes('contact')) {
            validators.push(
              Validators.pattern(/^\d{10}$/), // Allow only 10 digits
              Validators.minLength(10),
              Validators.maxLength(10)
            );
          }
  
          formControls[field.property.id] = new FormControl(
            { value: field.property.value, disabled: false } || '',
            validators
          );
        });
  
        this.form = this.formBuilder.group(formControls);
        //console.log("here");
      }
      this.getSelectedTaskDetails()
  
  
    }
  
    parseId(id: string) {
      return id.split("-");
    }
    parseIdForDropdown(id: string) {
      return id.split("_");
    }
  
    parseAnotherId(id: string) {
      //console.log(id.split(" "));
      return id.split(" ");
  
    }
    dropdownValues: any;
    getVariable(propertyId: any) {
      //this.dropdownValues = data.task.caseVariablesid+'Options
    }

    onOptionSelected(event:any){
      console.log(event.option.value)
    }
  
    parseType(fieldId: any, typeName: any) {
      //console.log("Field name :"+fieldId.toLowerCase().includes("contact")+" type :"+typeName);
      if (fieldId.toLowerCase().includes("contact") && typeName == "long") {
  
        return "tel";
      } else {
        return "number";
      }
    }

    displayAgentName(agent: any): string {
      return agent && agent.name ? agent.name : "";
    }
  
    parseExpression2(fieldValue: any, fieldId: any) {
//console.log("fieldValue "+fieldValue+" fieldId "+fieldId);
      if (fieldValue && fieldValue.includes('==')) {
        const key = fieldValue.split("==");
        const varName = key[0].trim();
        const varCheckValue = key[1].trim().replaceAll("\"", "");
  
        //get existing value of varName
        const varValue = this.form.get(varName)?.value;
        //this.form.get(fieldId)?.setValue("");
//console.log(" Field Name ="+varName+", Field Value ="+varValue+", expression Value ="+varCheckValue);
        if (varCheckValue == varValue) {
          this.form.get(fieldId)?.enable();
          return false;
        } else {
          this.form.get(fieldId)?.disable();
          return true;
        }
      } else {
        this.form.get(fieldId)?.enable();
        return false;
      }
    }
  
    writableCheck(fieldValue:any,fieldId:any,writable:any){
      if(!(this.parseExpression2(fieldValue,fieldId) || writable)){
        this.form.get(fieldId)?.disable();
        return true;
      }else{
        this.form.get(fieldId)?.enable();
        return false;
      }
    }

    salesAgentSearch(value:string){

    }
  
    parseExpression2ForEnum(fieldValue: any, fieldId: any) {
      //console.log("fieldValue "+fieldValue+" fieldId "+fieldId);
      if (fieldValue && fieldValue.includes('==')) {
        const key = fieldValue.split("==");
        const varName = key[0].trim();
        const varCheckValue = key[1].trim().replaceAll("\"", "");
        //get existing value of varName
        const varValue = this.form.get(varName)?.value;
        console.log("var Value " + varValue + " varCheckValue " + varCheckValue + " varName " + varName);
        if (varCheckValue == varValue) {
          this.form.get(fieldId)?.enable();
          return false;
        } else {
          this.form.get(fieldId)?.disable();
          return true;
        }
      } else {
        this.form.get(fieldId)?.enable();
        return false;
      }
    }
  
  
    viewClaimStatus() {
      const dialog = this._dialog.open(ClaimsComponent, {
        disableClose:true,
        height: '60%',
        width: '60%'
      });
  
      dialog.afterClosed().subscribe(() => {
  
      });
    }
  
    getBPMNDiagram(taskId: any) {
      this.workflowService
        .getBPMNDiagram(taskId)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
  
            this.diagram = 'data:image/png;base64,' + data;
  
            this.loading = false;
          },
          error: (error) => {
            this.error = error.error;
            this.message = undefined;
            console.log(error);
            this.loading = false;
          },
        });
    }
  
    fileUploadComponent() {
     
      if(this.form.get('payorType')?.value == null){
       
        this.toastrService.info("Please select the payor type to upload SOC")
      }else{
        const dialogRef = this._dialog.open(InsuranceComponent, {
          disableClose:true,
          data: { 
            category:"WORKFLOW",
            sourceType:this.data.task.taskDefKey,
            entityId:this.data.task.caseVariables.org.id,
            entityType:"HOSPITAL",
            caseId:this.data.task.processInstanceId,
            payorType:this.form.get('payorType')?.value
            /*hospitalId: this.data.task.caseVariables.org.id, 
            entity_id: this.data.task.caseVariables.assignedUser.id, 
            caseId: this.data.task.processInstanceId, 
            taskName: this.data.task.taskDefKey */},
            width: '100vw',  // Sets the width to 100% of the viewport width
            maxWidth: '100vw',  // Ensures the max width doesn't override the width
            panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
            height: '100%'
        });
        dialogRef.afterClosed().subscribe({
          next: (val: any) => {
            console.log("after closed:" + val);
            if (val === 'UPLOADED') {
              this.form.patchValue({'upload-file': val,});
              this.getViewFileList();
            }else if(val === ""){
              
            }
            else{
              this.toastrService.error("Upload failed. Please try again!");
            }
          },
        });
      }
      
    }

    upload(){
      
      const dialogRef = this._dialog.open(FileUploadComponent, {
        disableClose:true,
        data: { 
          category:"WORKFLOW",
          sourceType:this.data.task.taskDefKey,
          entityId:this.data.task.caseVariables.org.id,
          entityType:"HOSPITAL",
          caseId:this.data.task.processInstanceId,
          payorType:this.form.get('payorType')?.value
          /*hospitalId: this.data.task.caseVariables.org.id, 
          entity_id: this.data.task.caseVariables.assignedUser.id, 
          caseId: this.data.task.processInstanceId, 
          taskName: this.data.task.taskDefKey */},
        width: '60%'
      });
      dialogRef.afterClosed().subscribe({
        next: (val: any) => {
          console.log("after closed:" + val);
          if (val === 'UPLOADED') {
            this.getViewFileList();
            this.form.patchValue({'upload-file': val,});
          }else{
            this.toastrService.error("Upload failed. Please try again!");
          }
        },
      });
    
    }

    onMediaListUpdated(media:any){
      this.uploadedMediaData = media;
    }
  

    viewProductForm():void
    {
      console.log(this.data.task.caseVariables.org.id)
        const dialogRef = this._dialog.open(BgProductComponent, {
          disableClose:true,
          data: this.data.task.caseVariables.org.id,
          // Set width to 600px
          //height:'350px',  // Set height to 530px
        });
     
        dialogRef.afterClosed().subscribe({
          next: (val) => {
            if (val) {
              // console.log("tyu", val);
              // this.getUserRoleslById();
              //this._dialogRef.close(true);
              // console.log("abc", val);
              console.log(val);
              
              if (this.form.controls["select-hospital-products-modal"]) {
                this.form.controls["select-hospital-products-modal"].setValue(val);
                this.getHospitalInsurance();
              }
            }
          },
        });
      }
      getHospitalInsurance() {
        this.HospitalRegistrationService
          .getBgProducttWithHospitalId(this.data.task.caseVariables.org.id)
          .subscribe({
            next: (data) => {
               console.log("....", data);
              this.uploadedData = data;
              this.dataSource.data = this.uploadedData;
              // if (this.form.controls["select-hospital-products-modal"] &&  this.uploadedData.length > 0) {
              //   this.form.controls["select-hospital-products-modal"].setValue(true);
              // }
              // console.log("Get :" + this.dataSource.data);
            },
            error: (error) => {
              // console.log("error " + JSON.stringify(error));
              this.message = error;
            },
          });
      }
  
  
    openEkypForm() {
      // const ekypData = JSON.parse(this.data.task.caseVariables.patientPolicies);
      const dialogRef = this._dialog.open(EditKypComponent, {
        disableClose:true,
        data: {
          policy: this.data.task.caseVariables.patientPolicies,
          // policyId:policy.policyNumber,
          // order_id: order_id,
          // taskId:taskId
  
        },
        height: '80%',
        width: '80%'
      });
      dialogRef.afterClosed().subscribe({
        next: (val) => {
          if (val) {
            // this.selectedTask.caseVariables.policy = val;
            // this.selectTask(this.selectedTask);
          }
        },
      });
  
    }
  
  
    compareWithFn(o1: any, o2: any) {
      //console.log("compare with");
  
      return o1 === o2;
    };
  
    getEnumOptions(fieldId: string) {
      const field = this.data.task.formDataFieldOptions.find(
        (option: any) => option.property.id === fieldId
      );
  
      //console.log(JSON.stringify(field.values));
      return field ? field.values : [];
    }
  
    getSelectedTaskDetails() {
      this.cashlessService
        .getSelectedTask(this.data.task.id)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            //window.alert('Registration Successfull');
            //console.log("data:" + JSON.stringify(data));
            this.loading = false;
            this.selectedTaskDetails = data;
            console.log("selectedtaskdetails:" + this.selectedTaskDetails);
            if (this.selectedTaskDetails) {
              this.updateForm(this.selectedTaskDetails);
              this.taskDetailsHistory(this.selectedTaskDetails);
  
              this.getViewFileList();
            }
            //this.registrationsuccess = true;
          },
          error: (error) => {
            console.log("error " + JSON.stringify(error));
            this.message = error.error.error;
  
            this.loading = false;
  
          },
        });
    }
  
    updateForm(json: any) {
      const formControls = this.form.controls;
  
      // 2.1. Update Existing Controls Based on Field Name Match
      Object.keys(json).forEach(updateKey => {
        const control = formControls[updateKey];
        if (control) {
          control.setValue(json[updateKey]);
          // console.log(control.value);
        } else {
          // Consider handling missing updateJson fields (optional)
          // console.warn(`Field '${updateKey}' not found in the form. Ignoring update.`);
        }
      });
  
      // console.log(formControls);
    }
  /*
    getUploadedHospitalMedia() {
      this.fileService.getExternalMediaList(this.data.task.caseVariables.hospitalId, "HOSPITAL", "").subscribe({
        next: (data: any) => {
  
          this.uploadedData = data;
          this.dataSource.data = this.uploadedData;
          
  
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
    }
  */
    toSentenceCase(str: string) {
      return str.replace(/\b\w+\b/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }
  
    viewInsuranceCompanyForm(data: any): void {
      const dialogRef = this._dialog.open(InsuranceComponent, {
        disableClose:true,
        data,
        // Set width to 600px
        //height:'350px',  // Set height to 530px
      });
  
      dialogRef.afterClosed().subscribe({
        next: (val) => {
          if (val) {
            // this.getUserRoleslById();
            //this._dialogRef.close(true);
          }
        },
      });
    }
  
    taskHistory: any;
    filteredData: any;
    taskDetailsHistory(json: any) {
      this.filteredData = Object.fromEntries(
        Object.entries(json)
          .filter(([key, value]) => !key.includes("TAT") && value !== null)
      );
  
      const files = Object.fromEntries(
        Object.entries(json).filter(([key]) => key.includes("upload"))
  
      );
      this.uploadedFiles = Object.entries(files).map(([key, value]) => {
        const fileNameParts = key.split('-'); // Split the file name by '-'
        const fieldName = fileNameParts.slice(1).join(' '); // Extract field name after '-'
        return {
          fieldName: fieldName.charAt(0).toUpperCase() + fieldName.slice(1), // Capitalize first letter
          filePath: value
        };
      });
  
  
      this.previousDetails = this.formBuilder.group(this.filteredData);
  
      console.log(this.previousDetails.controls);
      console.log("Uploaded Files:" + JSON.stringify(this.uploadedFiles));
    }
    // type:any;
    // getCategory() {
  
    //   if(this.data.task.name == 'Final Dispatch') {
    //     this.type ='PARTIAL-PAYMENT';
    //   } else{
    //     this.type = 'BG-CASHLESS'
    //   }
    //   this.cashlessService.getCategoryByTypeAndEntityType('FILE',this.type)
    //     .pipe(first())
    //     .subscribe({
    //       next: (data: any) => {
    //         console.log(data);
    //       }
    //     })
  
  
    // }
    policy: any;
    openAddForm(data: any, policyFile?: any) {
      console.log(data);
      const dialogData = {
        data: data,
        policyFile: 'CASHLESS'
      }
      const config = new MatDialogConfig();
      config.disableClose = true;
      config.width = '1000px';
      config.height = '500px';
      config.data = dialogData;
      const dialogRef = this._dialog.open(AddKypComponent, config);
      dialogRef.afterClosed().subscribe({
        next: (val) => {
  
          if (val) {
            // console.log("new added",this.selectedTask);
            this.addkyp = true;
            console.log("new added1", val);
            this.policy = val;
            // this.selectedTask.caseVariables.policy = val;
            // this.selectTask(this.selectedTask);
  
            //this.getUsers();
            //this.getroles();
          }
        },
      });
    }
  
    openEditForm(policy: any, orderId: any, id: any) {
      // selectedTask.caseVariables.policy,
      //                   selectedTask.caseVariables.order.id,
      //                   selectedTask.id
      const dialogRef = this._dialog.open(EditKypComponent, {
        disableClose:true,
        data: {
          data: this.policy,
          order_id: orderId,
          member_name: '',
          taskId: id
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (val) => {
          if (val) {
            // this.selectedTask.caseVariables.policy = val;
            // this.selectTask(this.selectedTask);
          }
        },
      });
    }
  
    getInsuranceByHospitalId(hospitalId: any) {
      this.cashlessService.getInsuranceByHospitalId(hospitalId, this.rowNum, this.size)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            // console.log(data);
            this.insuranceCompanyList = data;
  
          }
        })
    }
  
    openViewFile(file: any) {
      console.log(file);
      const dialogRef = this._dialog.open(ViewFileComponent, {
        disableClose:true,
        data: { fileMetaData: file },
  
      });
      dialogRef.afterClosed().subscribe({
        next: (val: any) => {
          if (val) { }
        },
      });
    }
  
    viewUploadedFileComponent() {
       
      const dialogRef = this._dialog.open(ViewUploadFileComponent, {
        disableClose:true,
        data: {hospitalId:  this.data.task.caseVariables.org.id,
          processInstanceId :  this.data.task.processInstanceId}
      });
      dialogRef.afterClosed().subscribe({
        next: (val: any) => {
          console.log("after closed:" + val);
  
        },
      });
    }
  
    getTpa() {
      this.tpaService
        .getTpa()
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            this.tpaList = data;
            // console.log("prod", data);
          },
          error: (err: any) => {
            console.log(err);
          },
        });
    }
  
    isDateDisabled(date: Date) {
      return date > this.maxDate;
    }
  
    isRoles(role: string[]) {
      const roles: any[] = this.user.roles;
      //console.log(this.user.roles);
      var dd = roles.some((x: string) => role.includes(x));
      //console.log("roles "+ dd);
      return dd;
    }
  
    taskCalculations: any = [];
    getTaskCalculations() {
      this.cashlessService.getTaskCalculations('cashlessProcess', this.data.task.taskDefKey , 'false')
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            this.taskCalculations = data;
            console.log(this.taskCalculations);
            //this.subscribeToControlValueChanges();
          }
        });
    }
  
    dataChange() {
      console.log("Trying to change data ");
      for (var x = 0; x < this.taskCalculations.length; x++) {
        const attribute = this.taskCalculations[x].id.attributeName;
        console.log(attribute);
        var calculation = this.taskCalculations[x].calculationExpression;
        console.log(calculation);
        Object.keys(this.form.controls).forEach((key) =>{
          const v = this.form.get(key)?.value;
          calculation = calculation.replaceAll(key, v);
        });
        var calculatedValue = eval(calculation);
        console.log("calculatedValue "+calculatedValue);
        this.form.get(attribute)?.setValue(calculatedValue);
      }
    }
  
  
    taskDetails: any;
    formGroups: FormGroup[] = [];
    getTaskDetailsByTask() {
      this.cashlessService.getCaseDetails('cashlessProcess',this.data.task.processInstanceId)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            this.taskDetails = data;
            console.log(this.taskDetails);
            this.taskDetails.forEach((task: any) => {
              console.log(task);
              const taskData = Object.fromEntries(
                Object.entries(task.task_data)
                  .filter(([key, value]) => !key.includes("TAT") && value !== null)
              );
              this.taskDetailsNew = this.formBuilder.group(taskData);
              this.formGroups.push(this.taskDetailsNew);
              // console.log(this.formGroups);
            });
          }
        });
  
    }

    pdfUrl: any;
    showPdf: any = false;
    getExternalMediaById(mediaId: any) {
      console.log(JSON.stringify(mediaId));
      this.loading = true;
      this.fileService
        .getExternalMedia(mediaId.id)
        .subscribe({
          next: (data: any) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              this.pdfUrl = e.target.result;
              this.showPdf = true;
              this.loading = false;
            }
            reader.readAsDataURL(data);
          },
          error: async (err: any) => {
            this.showPdf = false;
            this.loading = false;
            console.log("error");
            console.log(err);
          },
        });
    }
    uploadedMediaData:any=[];
    getViewFileList() {
      console.log(JSON.stringify(this.data.task.caseVariables.org.id));
      this.fileService.getExternalMediaList(this.data.task.caseVariables.org.id, 'HOSPITAL', '').subscribe({
        next: (data: any) => {
          console.log(JSON.stringify(data));
          this.uploadedMediaData = data;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
    }
    toggleView() {
      if (this.showPdf) {
        this.showPdf = false;
      }
      else {
        this.showPdf = false;
      }
    }
    showDiagram:any = false;
    toggleDiagramView(){
      if(this.showDiagram){
        this.showDiagram = false;
      }else{
        this.showDiagram = true
      }
    }
  
  }

