<!-- <div mat-dialog-title>
    <h1>Update Coupon</h1>
</div> -->
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Update Email parser config</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
<form [formGroup]="emailconfigForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id" readonly >
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>category</mat-label>
                <input matInput type="text" placeholder="category" formControlName="category">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>emailId</mat-label>
                <input matInput placeholder="emailId" formControlName="emailId">
            </mat-form-field>
    
        
            <mat-form-field appearance="outline">
                <mat-label>seqNo</mat-label>
                <input matInput type="text" placeholder="seqNo" formControlName="seqNo" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>subjectFilter</mat-label>
                <input matInput type="email" placeholder="subjectFilter" formControlName="subjectFilter" >
            </mat-form-field>
        
        
        
            <mat-form-field appearance="outline">
                <mat-label>matchRegex</mat-label>
                <input matInput type="text" placeholder="matchRegex" formControlName="matchRegex">
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>matcherField</mat-label>
                <input matInput type="text" placeholder="matcherField" formControlName="matcherField">
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>matchSet</mat-label>
                <input matInput type="text" placeholder="matchSet" formControlName="matchSet">
            </mat-form-field>
        
        
            <!-- <mat-form-field appearance="outline">
                <mat-label>Insurance Company</mat-label>
                <mat-select formControlName="privileges" [(ngModel)]="selectedType" name="company">
                  <mat-option *ngFor="let company of insuranceCompanyMasterData" [value]="company.id">
                      {{company.nameShort}} 
                  </mat-option>
                </mat-select>
              </mat-form-field>
        
            -->
            
      <mat-form-field appearance="outline">
        <mat-label>{{ status }}</mat-label>
        <mat-select formControlName="inactive" name="inactiveData">
          <mat-option value="true"> Inactive </mat-option>
          <mat-option value="false"> Activate </mat-option>
        </mat-select>
      </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
    </div>
</form>