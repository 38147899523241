import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrl: './external-login.component.scss'
})
export class ExternalLoginComponent implements OnInit {
  form!: FormGroup;

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
  ) { }
  ngOnInit() {
    this.form = this.formBuilder.group({
      url: [""],
      username: [""],
      password: [""],
    })
  }

  login() {
    /*this.adminService.loginToExternalPortal(this.form.value.url, this.form.value.username, this.form.value.password)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log("Successful");
        }
      }),
      (error: any) => {
        console.log(error);

      };*/
      this.redirectToExternalLogin("vhi.kaushalya.medi2183@vitrayatech.com","VO##_=Z#H7v8");
  }
  redirectToExternalLogin(username: string, password: string) {
    const url = `https://shpv.starhealth.in/?email=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
    window.location.href = url;
  }
}
