import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatTabGroup } from "@angular/material/tabs";
import { Router, NavigationExtras } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { first, switchMap, takeUntil } from "rxjs/operators";
import { DashboardParam } from "src/app/model/dashboardParam";
import { AdminService } from "src/app/services/admin.service";
import { AuthService } from "src/app/services/authService";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { faSquareH } from "@fortawesome/free-solid-svg-icons";
import { DataRefreshServiceService } from "src/app/services/data-refresh-service.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { BehaviorSubject, Subject, timer } from "rxjs";
import { DataServiceService } from "src/app/services/data-service.service";
import { WorkflowService } from "src/app/services/workflow.service";
import { DataShareModel } from "src/app/model/dataShareModel";

@Component({
  selector: "app-createcase",
  templateUrl: "./createcase.component.html",
  styleUrls: ["./createcase.component.scss"],
})
export class CreatecaseComponent implements OnInit {

  private unsubscribe = new Subject<void>();
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;


  faSquareH = faSquareH;
  selectedIndex = 0;
  dateVal = new Date();
  createCase!: FormGroup;
  selectedoption = null;
  DoYouhaveFamilyPhysician = null;
  //assignedTasks: any[] = [];
  loading: any = false;
  error: any;
  hospitalId: any;
  disabled: boolean = false;
  message: any;
  form: FormGroup = this.formBuilder.group({});
  dashboardParams: DashboardParam = { "processType": "cashlessProcess", "orgType": "BG" };
  displayedColumns: string[] = [
    "currenttask",
    "orderDate",
    "orderId",
    "customer",
    "assignedTo",
    "action",
  ];
  allTasks: any[] = [];
  dataSource = new MatTableDataSource<any>(this.allTasks);
  public dynamicFormGroup!: FormGroup;
  public fields: any = [];
  orgId: any;
  policyMembers: any;
  length = 100;
  pageSize = 100;
  pageIndex = 0;
  user: any;
  org: any;
  clicked: any;
  hospitalClicked: any = false;
  hospitalList: any;
  selectedHospital: any;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  public selectedTabSubject = new BehaviorSubject<any>(2);
  selectedTabState$ = this.selectedTabSubject.asObservable();
  public selectedTab:any = 1;
  constructor(
    private dataService: DataServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private cashlessService: CashflowService,
    private adminService: AdminService,
    private customerService: CustomerService,
    private hospitalService: HospitalRegistrationService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private workflowService: WorkflowService
    // private dataRefreshService: DataRefreshServiceService,
  ) {

    this.authService.user.subscribe((x: any) => {
      this.user = x;
      //console.log(this.user);
      if (this.user && this.isPrivileged(["HOSPITAL_ADMIN"])) {
        this.dashboardParams.orgType = "HOSPITAL";
        this.org = this.user.orgId;
      }
    });

    this.selectedTabSubject.subscribe((data:any) =>{
      this.selectedTab = data;
    })
  }
  public updateSelectedTab(newData: any) {
    this.selectedTabSubject.next(newData);
    this.disabled = false;
  }

  ngOnInit(): void {
    this.hospitalClicked = false;

    this.isHospital();

    timer(0, 3000000).pipe(
      //tap((x)=> console.log(x)),
      takeUntil(this.unsubscribe),
      switchMap(async () => {
        this.getAllTasks();
        //console.log("Fetching tasks");
      })
    ).subscribe();


  }
  // refreshChildData() {
  //   this.dataRefreshService.refreshData();
  // }

  ngAfterViewInit(): void {
    this.org = this.user.orgId;
    this.isHospital();
    this.getAllTasks();


  }

  orgType: any;
  isHospital() {
    if (this.isPrivileged(["CAN_VIEW_HOSPITAL"])) {
      this.cashlessService.getOnboardedHospitalByUser()
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            this.orgType = "HOSPITAL";
            console.log("Selected hospital is :" + JSON.stringify(data));
            // if(data.length == 1){
            //   this.hospitalId = data.hospitalOrgId;
            //   this.selectedHospital = data;
            //   this.orgId = data.hospitalOrgId;
            // }else{
                 
            this.hospitalList= data;
            // }
        
          },
          error: (error) => {
            console.log(error);
          }
        })
    }
    else {
      this.selectedHospital = undefined;
      this.hospitalId = undefined;
      this.getHospitalList();
    }
  }


  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;

    var dd = roles.some((x: string) => role.includes(x));
    return dd;
  }
  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  async startProcess() {
    if (!this.selectedHospital) {
      this.toastrService.warning("Please select a hospital to create a case ")
    } else {
      this.disabled = true;
      this.changeDetectorRef.detectChanges();
      console.log("Is it clicked:" + JSON.stringify(this.selectedHospital));

      this.cashlessService.postStartProcess(this.selectedHospital.hospitalOrgId).pipe(first())
        .subscribe({
          next: (data: any) => {
            this.allTasks = data;
            this.getAllTasks();
            this.showSuccessToast();
            this.loading = false;

          },
          error: (error) => {
            this.error = error.error;
            this.message = undefined;
            console.log(error);
            this.loading = false;
          },
          complete:() =>{
            this.selectedHospital = '';
          }
        });
    }
  }

  showSuccessToast() {
    this.toastrService.success("New case has been created");
  }
  // HOSPITAL or BG
  getAllTasks() {
    this.workflowService
      .getAllTask("cashlessProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.newTasks = data;
          //console.log("newTasks " + JSON.stringify(this.newTasks));
          const dataShare = new DataShareModel("cashlessProcess",data);
          this.dataService.updateAllTasks(dataShare);

          this.loading = false;
          this.disabled = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }


  getHospitalList() {
    this.cashlessService.getActiveHospitals()
      .pipe(first())
      .subscribe({
        next: (data: any) => {

          this.hospitalList = data;
          //console.log(this.hospitalList);
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
        }
      })
  }

  getDashboardParam() {
    //console.log("Data for Dashboard "+JSON.stringify(this.dashboardParams));
    return JSON.stringify(this.dashboardParams);
  }

  ngOnDestroy(): void {
    console.log("destroyed")
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
