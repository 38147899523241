<div class="p-3">
    <div mat-dialog-title>
        <div class="row">
            <div class="col-8 d-flex justify-content-start">
                <h1>VIew Portal Details</h1>
            </div>
            <div class="col-4 d-flex justify-content-end" mat-dialog-close>
                <h4>
                    <i class="px-3" style="color: lightgray">* marked fields are mandatory
                    </i>
                </h4>
                <mat-icon color="accent">cancel</mat-icon>
            </div>
        </div>
    </div>
    <hr />
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="portalForm" >
            
                    <div class="form-group">
                        <label >Portal URL</label>
                        <input type="text" formControlName="portalUrl" id="portalUrl" class="form-control"  />
                        

                        <label >UserName</label>
                        <input type="text" formControlName="userId" id="userId" class="form-control"  />
                        

                        <label >Password</label>
                        <input type="text" formControlName="password" id="password" class="form-control"  />
                        
                    </div>
                  
        </form>
    </mat-dialog-content>
</div>