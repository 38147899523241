import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/authService';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { AdminService } from '../services/admin.service';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  //returnUrl: string;
  faCoffee = faCoffee;
  message: any;
  hide = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public adminService: AdminService
    //private alertService: AlertService
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //     this.router.navigate(['/']);
    // }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // password: ['', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$")]]
      password: ['',Validators.required]


    });

    // get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    var data = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
    }
    this.loading = true;
    this.authService.login(data)
    
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.loading = false;
//console.log("Login "+JSON.stringify(data))
          this.router.navigate([data.landingPage]);
        },
        error: error => {
          this.message = error.error.error
          console.log(JSON.stringify(error));
          this.loading = false;
        }
      });
  }
  
}
