import { Component, Inject, signal } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { first, Observable } from "rxjs";
import { FileUploadComponent } from "src/app/file-upload/file-upload.component";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { FileService } from "src/app/services/file.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { WorkflowService } from "src/app/services/workflow.service";

@Component({
  selector: "app-create-agreement",
  templateUrl: "./create-agreement.component.html",
  styleUrls: ["./create-agreement.component.scss"],
})
export class CreateAgreementComponent {
  diagram: any;
  error: any;
  message: undefined;
  form: FormGroup = this.formBuilder.group({});
  selectedTaskDetails: any;
  selectedFiles?: any;
  formData: FormData = new FormData();
  docType: any;
  fileInfos?: Observable<any>;
  hospitalType: any;
  fieldValue: any;
  fieldId: any;

  readonly panelOpenState = signal(false);

  constructor(
    public dialogRef: MatDialogRef<CreateAgreementComponent>,
    private formBuilder: FormBuilder,
    private cashlessService: CashflowService,
    private fileService: FileService,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService: ToastrService,
    private _dialog: MatDialog,
    private workflowService:WorkflowService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("task : " + JSON.stringify(data));
  }
  ngOnInit() {
    this.getBPMNDiagram(this.data.task.processInstanceId);
    this.initializeForm();
  }

  parseId(id: string) {
    return id.split("-");
  }

  getFieldType(field: any) {
    // console.log(field.property.mimeType);
    if (field.property.mimeType === "text/plain") {
      return "text";
    }
    return "text";
    // return "number";
  }

  getBPMNDiagram(taskId: any) {
    this.workflowService
      .getBPMNDiagram(taskId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.diagram = "data:image/png;base64," + data; // use this in <img src="..."> binding
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          // console.log(error);
        },
      });
  }

  initializeForm() {
    // console.log("part2", this.data.task.formDataFieldOptions);
    if (this.data.task) {
      const task = this.data.task; // assuming you only deal with the first task for now
      const formControls: Record<string, FormControl> = {};
      task.formDataFieldOptions
        // .slice(0, 4)
        .forEach(
          (field: {
            property: { required: any; id: string | number; value: any };
          }) => {
            const validators = field.property.required
              ? [Validators.required]
              : [];

            formControls[field.property.id] = new FormControl(
              field.property.value || "",
              validators
            );
          }
        );

      this.form = this.formBuilder.group(formControls);
      // console.log("here");
    }
    this.getSelectedTaskDetails();
  }

  getSelectedTaskDetails() {
    this.cashlessService
      .getSelectedHospitalOnBoardingProcessTask(this.data.task.id)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.selectedTaskDetails = data;
          // console.log(this.selectedTaskDetails);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error.error.error;
        },
      });
  }

  selectFile(event: any, formId: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
    this.docType = "LegalAgreement";
    this.formData.append("docType", this.docType);
    this.formData.append("file", this.selectedFiles);
    this.formData.append("entityId", formId);
    this.formData.append("entityType", "HOSPITAL");
    this.formData.append("category", "WORKFLOW");
    this.formData.append("sourceType", "WORKFLOW");//TODO
    this.hospitalmediasubmit();
  }

  getEnumOptions(fieldId: string) {
    const field = this.data.task.formDataFieldOptions.find(
      (option: any) => option.property.id === fieldId
    );
    return field ? field.values : [];
  }

  onChangeHospitalType(arg0: any) {
    if (arg0) {
      this.hospitalType = arg0.value;
      // console.log("hospitalType", this.hospitalType);
    }
  }

  hospitalmediasubmit() {
    if (this.docType == "LegalAgreement") {
      this.fileService
        .addExternalMedia(this.formData)
        .subscribe({
          next: (data: any) => {
            //console.log(data)
            // console.log("Get file :" + JSON.stringify(data));
            this.toastrService.success(
              "Legal Agreement File added successfully"
            );
          },
          error: (error) => {
            // console.log("error " + JSON.stringify(error));
            this.message = error;
          },
        });
    } else {
      // console.log("No data");
    }
  }

  submitForm() {
    // console.log("Submit Form", this.form.value);
    this.cashlessService
      .postFormData(this.data.task.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          //window.alert('Registration Successfull');
          this.toastrService.success(
            this.data.task.name + " has been updated successfully"
          );
          // console.log("done");
          this.dialogRef.close(); 
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error.error.error;
        },
      });
  }

  fileUploadComponent() {
    const dialogRef = this._dialog.open(FileUploadComponent, {
      disableClose:true,
      data: {
        hospitalId: this.data?.task?.caseVariables?.org?.id,
        entity_id: this.data?.task?.caseVariables?.assignedUser?.id,
        caseId: this.data?.task?.processInstanceId,
        taskName: this.data?.task?.taskDefKey,
      },
      width: "90%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        // console.log("after closed:" + val);
        if (val === "File(s) uploaded") {
          this.form.patchValue({
            "upload-file": val,
          });
        }
      },
    });
  }

  parseExpressionForEnum(fieldValue: any, fieldId: any) {
    // console.log(fieldValue);
    if (fieldValue && fieldValue.includes("!=")) {
      // const keyValue = fieldValue.split(".");
      // console.log(keyValue);
      const key = fieldValue.split("!=");
      // console.log(key);

      const value = key[0].trim();
      const disable = this.form.get(value)?.value;
      // this.form.get(fieldId)?.setValue("");
      // console.log(disable);
      // console.log(key[1].trim());
      if (disable == key[1].trim().replaceAll('"','')) {
        this.form.get(fieldId)?.disable();
        // this.form.get(value)?.setValue("");
        
        return true;
      } else {
        this.form.get(fieldId)?.enable();
        //this.form.get(value)?.setValue("");
        return false;
      }
    } else {
      this.form.get(fieldId)?.enable();

      return false;
    }
  }
}
