import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/authService';
import { first } from 'rxjs';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPassowrdComponent implements OnInit{
  faCoffee=faCoffee;
  emailForm!: FormGroup;
  passwordForm!: FormGroup;
  loading = false;
  submitted = false;
  message: any;
  error:any;
  isLinear = true;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,){

  }
  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      pin: ['',Validators.required]
    }); 

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            
    }); 
   }

   get f() { return this.emailForm.controls; }
   get p() { return this.passwordForm.controls; }

   onSubmit() {
    this.message = undefined;
    this.submitted = true;
    var data = {
      email: this.emailForm.value.email,
      password: this.passwordForm.value.password,
      pin: this.passwordForm.value.pin

    }
    this.loading = true;
    this.authService.getPin(data)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.loading = false;
          this.message = data.message;
          this.error = undefined;
          this.submitted = false;
        },
        error: error => {
          this.error = error.error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        }
      });
  }
  changePassword(){
    this.message = undefined;
    this.loading = true;
    
    var data = {
      email: this.emailForm.value.email,
      password: this.passwordForm.value.password,
      pin: this.passwordForm.value.pin

    }

    this.authService.forgotPassword(data)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        this.loading = false;
        this.message = data.message;
        this.error = undefined;
        this.submitted = true;
      },
      error: error => {
        this.error = error.error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
        this.submitted = true;
      }
    });
  }
}
