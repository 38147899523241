<div class="container">
  <div class="p-3 text-center">
    <div class="addbtn">
      <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
        Add 
      </button>
  
    </div>

    <div class="spinner-border text-secondary" *ngIf="loading" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>

    <div class="row px-3 py-4 justify-content-md-center">
      <input
        type="search"
        (input)="handleUInput($event)"
        class="form-control"
        placeholder="Filter"
        aria-label="Search"
      />
    </div>

    <mat-table class="" [dataSource]="icCompanies">
      <ng-container matColumnDef="nameReg">
        <mat-header-cell *matHeaderCellDef>Name Reg</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.nameReg ? element.nameReg : "NA" }}
        </mat-cell>
      </ng-container>mat-header-cell

      <ng-container matColumnDef="nameShort">
        <mat-header-cell *matHeaderCellDef>Name Short</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.nameShort ? element.nameShort : "NA" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="payorType">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.payorType ? element.payorType : "NA" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="claimSupportAddr">
        <mat-header-cell *matHeaderCellDef>Claim Support Addr</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.claimSupportAddr ? element.claimSupportAddr : "NA" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="claimSupportEmail">
        <mat-header-cell *matHeaderCellDef>Claim Support Email</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.claimSupportEmail ? element.claimSupportEmail : "NA" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="claimSupportNumber">
        <mat-header-cell *matHeaderCellDef>Claim Support Number</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.claimSupportNumber ? element.claimSupportNumber : "NA" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="logo">
        <mat-header-cell *matHeaderCellDef>Image</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div *ngIf="element.logo != null; else other_content">
            <img
              class="img-thumbnail"
              [src]="apiUrl + '/externalMedia/' + element.logo"
            />
          </div>
          <ng-template #other_content>
            <p>No logo for this IC</p>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [style.color]="element.inactive == true ? 'gray' : null"
        >
          {{ element.inactive ? "In Active" : "Active" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Update</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button style="color:#8e0101;" (click)="openEditForm(element)" matTooltip="Edit Form"
          matTooltipPosition="below">
          <mat-icon>edit</mat-icon>
        </button>
        </mat-cell>
      </ng-container>
      x<ng-container matColumnDef="updateLogo">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Add Logo</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button style="color:#8e0101;" (click)="openLogoForm(element)" matTooltip="Logo"
          matTooltipPosition="below">
          <mat-icon>image</mat-icon>
        </button>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="inactivate">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Inactivate</mat-header-cell>
        <mat-cell *matCellDef="let element">
        
          <button mat-icon-button style="color:#8e0101;" (click)="deleteIC(element)" matTooltip="Inactive"
          matTooltipPosition="below">
          <mat-icon>check_circle_outline</mat-icon>
        </button>
        </mat-cell>
      </ng-container> -->

      
      <ng-container matColumnDef="inactivate">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let element"
        >
          <button   [disabled]="element.inactive == true"
          mat-icon-button
          [style.color]="element.inactive == true ? 'gray' : '#8e0101'"
          (click)="deleteIC(element)"
          matTooltip="Edit Form"
          matTooltipPosition="below"
        >
        <mat-icon>delete</mat-icon>
      </button>
        </mat-cell>
      </ng-container>

      

      <!-- <ng-container matColumnDef="EmailParserConfs">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Mail parser</mat-header-cell>
        <mat-cell *matCellDef="let element">
        
          <button mat-icon-button style="color:#8e0101;" (click)="ViewMailParser(element)" matTooltip="Inactive"
          matTooltipPosition="below">
          <mat-icon>mail_outline</mat-icon>
        </button>
        </mat-cell>
      </ng-container> -->

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <div class="demo-options p-4">
      <div class="demo-toggles">
        <mat-paginator
          #paginator
          class="demo-paginator"
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageIndex"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
