import { Component, ViewChild } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { first } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { EditCouponComponent } from "./edit-coupon/edit-coupon.component";
import { AddCouponComponent } from "./add-coupon/add-coupon.component";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";

@Component({
  selector: "app-manage-coupon",
  templateUrl: "./manage-coupon.component.html",
  styleUrls: ["./manage-coupon.component.scss"],
})
export class ManageCouponComponent {
  // allCoupons = [
  //   {
  //     id: 1,
  //     product_id: 1,
  //     discount_code: 'TRYNEW',
  //     discount_percent: 50,
  //     discount_note: "Use code TRYNEW & get 20% off",
  //     start_date: "2023-09-30T23:59:59",
  //     end_date: "2023-10-30T23:59:59",
  //     max_count: "100",
  //     partner_type_id: 6,
  //     org_id: "646646747"
  //   },
  //   {
  //     id: 2,
  //     product_id: 2,
  //     discount_code: 'FREESHIP',
  //     discount_percent: 100,
  //     discount_note: "Flat ₹100 off",
  //     start_date: "2023-09-30T23:59:59",
  //     end_date: "2023-10-30T23:59:59",
  //     max_count: "400",
  //     partner_type_id: 6,
  //     org_id: "646646747"
  //   },

  //   {
  //     id: 3,
  //     product_id: 3,
  //     discount_code: 'SALE50',
  //     discount_percent: 20,
  //     discount_note: "Big Sale - Flat 50% off on everything",
  //     start_date: "2023-09-30T23:59:59",
  //     end_date: "2023-10-30T23:59:59",
  //     max_count: "200",
  //     partner_type_id: 6,
  //     org_id: "646646747"
  //   },
  //   {
  //     id: 4,
  //     product_id: 4,
  //     discount_code: 'SALE50',
  //     discount_percent: 0,
  //     discount_note: "Big Sale - Flat 50% off on everything",
  //     start_date: "2023-09-30T23:59:59",
  //     end_date: "2023-10-30T23:59:59",
  //     max_count: "200",
  //     partner_type_id: 6,
  //     org_id: "646646747"
  //   },
  //   {
  //     id: 5,
  //     product_id: 5,
  //     discount_code: 'SALE50',
  //     discount_percent: 0,
  //     discount_note: "Big Sale - Flat 50% off on everything",
  //     start_date: "2023-09-30T23:59:59",
  //     end_date: "2023-10-30T23:59:59",
  //     max_count: "200",
  //     partner_type_id: 6,
  //     org_id: "646646747"
  //   }

  // ]

  allCoupons = [
    {
      id: 5,
      productId: 5,
      discountCode: "SALE50",
      discountPercent: 0,
      discountNote: "Big Sale - Flat 50% off on everything",
      startDate: "2023-09-30T23:59:59",
      endDate: "2023-10-30T23:59:59",
      maxCount: "200",
      partnerTypeId: 6,
      orgId: "646646747",
    },
  ];
  // "id": "string",
  // "productId": "string",
  // "discountCode": "string",
  // "discountPercent": 0,
  // "discountNote": "string",
  // "startDate": "2023-11-01T05:05:36.709Z",
  // "endDate": "2023-11-01T05:05:36.709Z",
  // "maxCount": 0,
  // "partnerTypeId": 0,
  // "orgId": "string",
  // "dateCreated": "2023-11-01T05:05:36.709Z",
  // "createdBy": "string",
  // "inactive": true,
  // "currentCount": 0
  discountcoupon: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];
  message: any;
  coupons: any;

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  user: any;
  pageEvent: PageEvent = new PageEvent();

  dataSource = new MatTableDataSource<any>(this.discountcoupon);
  @ViewChild(MatPaginator) paginator! : MatPaginator;
  displayedColumns: string[] = [
    "Id",
    "ProdId",
    "DiscountCode",
    "DiscountPercent",
    "DiscountNote",
    "StartDate",
    "EndDate",
    "MaxCount",
    "PartnerTypeId",
    "OrgId",
    "Status",
    "action",
  ];
  loading: boolean = false;
  error: any;

  constructor(
    private adminService: AdminService,
    private _dialog: MatDialog,
    private authService: AuthService,
    private customerService: CustomerService
  ) {
    this.authService.user.subscribe((x) => (this.user = x));
  }

  ngOnInit(): void {
    this.getDiscountCoupon();
    this.getProducts();
    this.coupons = this.allCoupons;
  }

  getDiscountCoupon() {
    this.adminService
      .getDiscountCoupon()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.discountcoupon = data;
          this.dataSource.data = this.discountcoupon;
          this.dataSource.paginator = this.paginator
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //sthis.loading = false;
        },
      });
  }

  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.dataSource.data =
        query === ""
          ? this.discountcoupon
          : this.discountcoupon.filter((item: any) => {
              return Object.values(item).some((val) =>
                String(val).toLowerCase().includes(query)
              );
            });
    } else {
      this.getDiscountCoupon();
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getDiscountCoupon();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddCouponComponent,{disableClose:true,});
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getDiscountCoupon();
        }
      },
    });
  }

  openEditForm(data: any) {
    const dialogRef = this._dialog.open(EditCouponComponent, {
      disableClose:true,
      data,
      //width: '750px',
      // height: '200'
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getDiscountCoupon();
        }
      },
    });
  }

  products: any;
  getProducts() {
    this.customerService
      .getProducts()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.products = data;
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No products available for!";
          }
        },
      });
  }
  getProductName(productId: string): string {
    const product = this.products.find((p: any) => p.id === productId);
    return product ? product.name : "Unknown Product";
  }
}
