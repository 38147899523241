<!-- <div class="px-4 py-3 my-3 text-center">

    <mat-tab-group (selectedTabChange)="test($event)">
        <mat-tab label="View Products"> 
            <div>
                <h2>View Products </h2>
            </div>
            <div class="spinner-border text-secondary" *ngIf="loading" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            <div class="">
                <table mat-table [dataSource]="products">
              
                 
              
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Desc </th>
                    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="short_description">
                    <th mat-header-cell *matHeaderCellDef> Short Desc </th>
                    <td mat-cell *matCellDef="let element"> {{element.short_description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="regular_price">
                    <th mat-header-cell *matHeaderCellDef> Regular Price </th>
                    <td mat-cell *matCellDef="let element"> {{element.short_description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="sale_price">
                    <th mat-header-cell *matHeaderCellDef> Sale Price </th>
                    <td mat-cell *matCellDef="let element"> {{element.short_description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="stock_quantity">
                    <th mat-header-cell *matHeaderCellDef> Stock</th>
                    <td mat-cell *matCellDef="let element"> {{element.stock_quantity}} </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef > Action </th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button color="primary" (click)="openEditForm(element)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </ng-container>
    
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              
                <div class="demo-options p-4">


                    <div class="demo-toggles">
                        <mat-slide-toggle [(ngModel)]="hidePageSize">Hide page size</mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="showPageSizeOptions">Show multiple page size options</mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="showFirstLastButtons">Show first/last buttons</mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="disabled">Disabled</mat-slide-toggle>
            
                        <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
                            [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                            [pageIndex]="pageIndex" aria-label="Select page">
                        </mat-paginator>
                    </div>
                </div>
              </div>
            
       
        
        </mat-tab>
        <mat-tab label="Edit Products">       
            <h2>Edit Products </h2> 
          <form [formGroup]="couponForm" (ngSubmit)="onFormSubmit()">
              <div mat-dialog-content class="content">
                  <div class="row">
                          <mat-form-field  appearance="outline">
                            <mat-label>Product Name</mat-label>
                            <mat-select formControlName="productName" [(ngModel)]="selectedProductTypeId" name="name">
                              <mat-option *ngFor="let product of products" [value]="product.id">
                                  {{product.name}} 
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Discount code</mat-label>
                          <input matInput placeholder="Discount code" formControlName="discountCode">
                      </mat-form-field>
                  </div>
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>Discount percent</mat-label>
                          <input matInput type="text" placeholder="Discount percent" formControlName="discountPercent" >
                      </mat-form-field>
          
                      <mat-form-field appearance="outline">
                          <mat-label>Discount note</mat-label>
                          <input matInput type="email" placeholder="Discount note" formControlName="discountNote" >
                      </mat-form-field>
                  </div>
                  
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>Start date</mat-label>
                          <input matInput [matDatepicker]="picker" placeholder="Start date"  formControlName="startDate">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                  </div>
          
          
                  
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>End date</mat-label>
                          <input matInput [matDatepicker]="picker1" placeholder="End date"  formControlName="endDate">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                  </div>
                      <mat-form-field  appearance="outline">
                        <mat-label>Org Name</mat-label>
                        <mat-select formControlName="orgId" [(ngModel)]="selectedOrgsTypeId" name="id">
                          <mat-option *ngFor="let org of orgs" [value]="org.id">
                              {{org.name}} 
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>Max count</mat-label>
                          <input matInput type="text" placeholder="Max count" formControlName="maxCount" >
                      </mat-form-field>
                  </div>
                      <mat-form-field  appearance="outline">
                        <mat-label>Partner Type Name</mat-label>
                        <mat-select formControlName="orgId" [(ngModel)]="selectedPartnerTypeId" name="id" formControlName="partnerTypeId">
                          <mat-option *ngFor="let partner of partnerTypes" [value]="partner.id">
                              {{partner.type}}  {{partner.subType}} 
          
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
              </div>
              <div mat-dialog-actions class="action">
                  <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
                  <button mat-raised-button color="primary"[disabled]="!couponForm.valid" type="submit">Save</button>
              </div>
          </form>
        
        </mat-tab>
        <mat-tab label="Add Products"> 
            
            <h2>Add Products </h2> 

            <form [formGroup]="couponForm" (ngSubmit)="onFormSubmit()">
              <div mat-dialog-content class="content">
                  <div class="row">
                          <mat-form-field  appearance="outline">
                            <mat-label>Product Name</mat-label>
                            <mat-select formControlName="productName" [(ngModel)]="selectedProductTypeId" name="name">
                              <mat-option *ngFor="let product of products" [value]="product.id">
                                  {{product.name}} 
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Discount code</mat-label>
                          <input matInput placeholder="Discount code" formControlName="discountCode">
                      </mat-form-field>
                  </div>
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>Discount percent</mat-label>
                          <input matInput type="text" placeholder="Discount percent" formControlName="discountPercent" >
                      </mat-form-field>
          
                      <mat-form-field appearance="outline">
                          <mat-label>Discount note</mat-label>
                          <input matInput type="email" placeholder="Discount note" formControlName="discountNote" >
                      </mat-form-field>
                  </div>
                  
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>Start date</mat-label>
                          <input matInput [matDatepicker]="picker" placeholder="Start date"  formControlName="startDate">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                  </div>
          
          
                  
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>End date</mat-label>
                          <input matInput [matDatepicker]="picker1" placeholder="End date"  formControlName="endDate">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                  </div>
                      <mat-form-field  appearance="outline">
                        <mat-label>Org Name</mat-label>
                        <mat-select formControlName="orgId" [(ngModel)]="selectedOrgsTypeId" name="id">
                          <mat-option *ngFor="let org of orgs" [value]="org.id">
                              {{org.name}} 
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  <div class="row">
                      <mat-form-field appearance="outline">
                          <mat-label>Max count</mat-label>
                          <input matInput type="text" placeholder="Max count" formControlName="maxCount" >
                      </mat-form-field>
                  </div>
                      <mat-form-field  appearance="outline">
                        <mat-label>Partner Type Name</mat-label>
                        <mat-select formControlName="orgId" [(ngModel)]="selectedPartnerTypeId" name="id" formControlName="partnerTypeId">
                          <mat-option *ngFor="let partner of partnerTypes" [value]="partner.id">
                              {{partner.type}}  {{partner.subType}} 
          
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
              </div>
              <div mat-dialog-actions class="action">
                  <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
                  <button mat-raised-button color="primary"[disabled]="!couponForm.valid" type="submit">Save</button>
              </div>
          </form>
        
        </mat-tab>
      </mat-tab-group>

    
</div> -->









  <div class="addbtn">
    <button type="button" class="btn btn-outline-primary" (click)="openAddForm()">Add</button>
</div>
<div class="px-4 py-3 my-3 text-center">
  <div class="row justify-content-md-center">
    <div class="col ">
        <div class="p-1 container text-center fw-bold">
            <p>
                View Product!
            </p>
        </div>
    </div>
</div>
<div class="row  px-3 py-4 justify-content-md-center">
  <input type="search" (input)="handleInput($event)" class="form-control" placeholder="Filter"
  aria-label="Search">
</div>


          <div class="spinner-border text-secondary" *ngIf="loading" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          <div class="">
              <table mat-table [dataSource]="products">
            
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef> Image </th>
                  <td mat-cell *matCellDef="let element" >
                    <img style="width: 20vh; height: 20vh" [src]="element.images[0].src" />
                    

                     </td>
                     
                </ng-container>
            
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
            
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> Type </th>
                  <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>
            
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef> Desc </th>
                  <td mat-cell *matCellDef="let element">   <span [innerHTML]="element.description"></span> </td>
                </ng-container>

                <ng-container matColumnDef="short_description">
                  <th mat-header-cell *matHeaderCellDef> Short Desc </th>
                  <td mat-cell *matCellDef="let element"> <span [innerHTML]="element.short_description"></span>  </td>
                </ng-container>

                <ng-container matColumnDef="regular_price">
                  <th mat-header-cell *matHeaderCellDef> Regular Price </th>
                  <td mat-cell *matCellDef="let element"> {{element.regular_price}} </td>
                </ng-container>

                <ng-container matColumnDef="sale_price">
                  <th mat-header-cell *matHeaderCellDef> Sale Price </th>
                  <td mat-cell *matCellDef="let element"> {{element.sale_price}} </td>
                </ng-container>

                <ng-container matColumnDef="stock_quantity">
                  <th mat-header-cell *matHeaderCellDef> Stock</th>
                  <td mat-cell *matCellDef="let element"> {{element.stock_quantity}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef > Action </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" (click)="openEditForm(element)">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
  
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <div class="demo-options p-4">


                  <div class="demo-toggles">
                      <mat-slide-toggle [(ngModel)]="hidePageSize">Hide page size</mat-slide-toggle>
                      <mat-slide-toggle [(ngModel)]="showPageSizeOptions">Show multiple page size options</mat-slide-toggle>
                      <mat-slide-toggle [(ngModel)]="showFirstLastButtons">Show first/last buttons</mat-slide-toggle>
                      <mat-slide-toggle [(ngModel)]="disabled">Disabled</mat-slide-toggle>
          
                      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
                          [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                          [pageIndex]="pageIndex" aria-label="Select page">
                      </mat-paginator>
                  </div>
              </div>
            </div>          
</div>
