import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataShareModel } from '../model/dataShareModel';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  private allTasksSubject = new BehaviorSubject<any>(null);
  allTasksData$ = this.allTasksSubject.asObservable();

  private newTasksSubject = new BehaviorSubject<any>(null);
  newTasksData$ = this.newTasksSubject.asObservable();

  private assignedTasksSubject = new BehaviorSubject<any>(null);
  assignedTasksData$ = this.assignedTasksSubject.asObservable();

  constructor() { }
  updateNewTasks(newData: DataShareModel) {
    this.newTasksSubject.next(newData);
  }
  updateAssignedTasks(newData: DataShareModel) {
    this.assignedTasksSubject.next(newData);
  }
  updateAllTasks(newData: DataShareModel) {
    this.allTasksSubject.next(newData);
  }
}
