export class Order {
    amount?: string;
    productId?: string;
    transactionStatus?: string;
    userId?: string;
    policyNumber?:string;
    orderSource?:string;
    memberName?:string;
    agreementAccepted?:boolean;
    couponId?:any;
    discountPercent?:any;
}