<div class="container pt-3 text-center ">

    <img width="10%" class="d-block mx-auto mb-3" src="../../assets/img/enterprise.png" alt="">

    <h1 class="display-5 fw-bold">Bima<span style="color:#C00000;">Garage</span> ABHA</h1>

    <div class="col-lg-6 mx-auto">

        <p class="lead mb-4"><span><h2>eKYP</h2></span>Create your ABHA ID (Health ID)</p>
        <p><button mat-raised-button color="danger" style="background-color:#C00000;color:white;">Create your health Id.</button></p>
        <p class="lead mb-4"><span><h2>Health information</h2></span>
            Coming soon! You will be able to push all your helth information and make it available on NHX.</p>
            <button mat-raised-button color="danger" style="background-color:#C00000;color:white;">Create your ABHA ID now!</button>
    </div>

</div>
