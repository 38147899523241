import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Case } from 'src/app/model/case';
import { CustomerService } from 'src/app/services/customer.service';
import {parse} from 'angular-html-parser';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ClaimOrdersDetailsComponent } from '../../customer/hobnob/claim-orders/claim-orders-details/claim-orders-details.component';
import { ClaimDetailsComponent } from './modal/claim-details/claim-details.component';
import { SearchService } from 'src/app/services/search.service';
import { FileService } from 'src/app/services/file.service';
import { ViewFileExternalMediaComponent } from '../inbox/view-file-external-media/view-file-external-media.component';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit{
  
  @ViewChild("userHtml", { static: false }) userHtml: any;

  caseId = new FormControl('', [Validators.required]);
  cases: Case[] = [];
  pdfUrl:any;
  displayedColumns: string[] = ['caseId','icState', 'status', 'hospitalEmail', 'attachments', 'receivedDate','information'];
  dataSource!: MatTableDataSource<Case>;
  constructor(
    private customerService: CustomerService, 
    private cd: ChangeDetectorRef,
    private _dialog: MatDialog,
    private searchService:SearchService,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }
  ngOnInit(){
    console.log(JSON.stringify(this.data));
    let name = this.data.variables.firstname.replace(/\s+/g, ''); // Removes all spaces
    this.caseId.setValue(name);
    this.getCaseStatus();
  }

  error: any;
  message: any;
  loading: any;
  rowNum:any = 0;
  size:any = 100;
  getCaseStatus() {
    this.loading = true;
    this.customerService.getCaseStatus(this.caseId.value, this.rowNum, this.size).pipe(first()).subscribe({
      next: (data: Case[]) => {
        this.cases = data;
        this.dataSource = new MatTableDataSource(this.cases);
        this.loading = false;
      },
      error: error => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      }
    })

  }

  getClaimEmails() {
    this.loading = true;
    this.searchService.searchClaimEmails(this.caseId.value).pipe(first()).subscribe({
      next: (data: Case[]) => {
        this.cases = data;
        this.dataSource = new MatTableDataSource(this.cases);
        this.loading = false;
      },
      error: error => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      }
    })

  }

  getErrorMessage() {
    if (this.caseId.hasError('required')) {
      return 'You must enter a value';
    }

    return this.caseId.hasError('caseId') ? 'Not a valid caseId' : '';
  }

  parseJson(inputData:string){
    var dd = JSON.parse(inputData);
//console.log(dd.content.replaceAll('\\','').replaceAll('\r','').replaceAll('<html>','').replaceAll('<body>','').replaceAll('</html>','').replaceAll('</body>',''))
    const hh = dd.content.replaceAll('\\','').replaceAll('\r','').replaceAll('<html>','').replaceAll('<body>','').replaceAll('</html>','').replaceAll('</body>','');
    return "<p>"+hh+"</p>";
  }

  parseAttachements(inputData:string){
    var dd = JSON.parse(inputData);
//console.log(dd.content.replaceAll('\\','').replaceAll('\r','').replaceAll('<html>','').replaceAll('<body>','').replaceAll('</html>','').replaceAll('</body>',''))
const hh = dd.replaceAll('\\','').replaceAll('\[','').replaceAll('\]','').replaceAll('<body>','').replaceAll('</html>','').replaceAll('</body>','');

    return hh;
  }

  getHTMLData(inputData:any){
    const {rootNodes, errors} = parse(inputData);
    //console.log(rootNodes.values);
  }

  openViewHtml(element: any,attachments:any) {
    const idString = attachments;
    const id_change = idString.match(/"([^"]+)"/)?.[1];
    const dialogRef = this._dialog.open(ClaimDetailsComponent, {
      disableClose:true,
      data: { htmlData: element, attachments:id_change },

    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) { }
      },
    });
  }

  openViewFile(metaData: any) {
    const idString = metaData;
    const id_change = idString.match(/"([^"]+)"/)?.[1];
    console.log(id_change);  // Outputs: cdca5af1-2eab-47a7-bc25-ce1c51ec51ad
    
    const dialogRef = this._dialog.open(ViewFileExternalMediaComponent, {
      disableClose:true,
      data: { fileMetaData: {
       id: id_change
      } },
      width: "90%",
      // height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }


}
