import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first, firstValueFrom } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { MatChipInputEvent } from "@angular/material/chips";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";

interface list{
  id:number;
  name:string;
}

@Component({
  selector: "app-update-zone",
  //standalone: true,
  //imports: [],
  templateUrl: "./update-zone.component.html",
  styleUrl: "./update-zone.component.scss",
})
export class UpdateZoneComponent {
  zoneForm: FormGroup;
  visible = true;
  pageIndex = 0;
  pageSize = 100;
  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emailTags: string[] = [];


  insuranceAndTpaList :list[] =[];
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    private tpaService: ManagetpaService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UpdateZoneComponent>
  ) {
    this.zoneForm = this._fb.group({
      category: ["", Validators.required],
      insuranceCompanyId: ["", [Validators.required]],
      zone: ["", [Validators.required]],
      insuranceEmailId: ["", [Validators.required]],
    });
  }

 async ngOnInit() {
    this.zoneForm.patchValue(this.data.id);
    this.zoneForm.controls["insuranceEmailId"].setValue(
      this.data.insuranceEmailId
    );
    this.emailTags = this.data.insuranceEmailId.split(";").map((tag:any)=>tag.trim());
    const insuranceList = await this.getInsuranceCompanyMasterData();
    const tpaList = await this.getTpaList();
    this.insuranceAndTpaList = [
      ...insuranceList.map((insurance: any) => ({
        id: insurance.id,
        name: insurance.nameReg
      })),
      ...tpaList.map((tpa: any) => ({
        id: tpa.id,
        name: tpa.tpaName
      }))
    ];

    console.log(this.insuranceAndTpaList);
  }

  ngAfterViewInit(): void {}
  onFormSubmit() {
    var data = {
      insuranceEmailId: this.emailTags.join(';'),
      category: this.zoneForm.value.category,
      insuranceCompanyId: this.zoneForm.value.insuranceCompanyId,
      zone: this.zoneForm.value.zone,
      dateCreated: "",
      createdBy: "",
      dateUpdated: "",
      updatedBy: "",
    };
    console.log(JSON.stringify(data));
    this.adminService.updateZone(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("Zone has been updated successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));
      },
    });
  }

  async getInsuranceCompanyMasterData(): Promise<any[]> {
    try {
      const data = await firstValueFrom(
        this.adminService.getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize)
      );
      return data;
    } catch (error) {
      console.error(error);
      return [];  
    }
  }
 
  async getTpaList(): Promise<any[]> {
    try {
      const data = await firstValueFrom(this.tpaService.getTpa());
      return data;
    } catch (error) {
      console.error(error);
      return [];  
    }

  }

  addEmailId(event:MatChipInputEvent){
    const input = event.input;
    const value = (event.value || '').trim();
    console.log(value)
    if(value){
      this.emailTags.push(value);
      console.log(this.emailTags)
    }
    if(input){
      input.value = '';
    }
  }

  removeEmailId(emailId: string){
    const index = this.emailTags.indexOf(emailId);
    if(index >= 0){
      this.emailTags.splice(index, 1);
    }
  }

}
