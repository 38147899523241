<section class="container-fluid">
  <!-- <div mat-dialog-title>
    <h1>Add Tpa</h1>
  </div> -->
  <div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add Tpa</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="tpaAddForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Name Reg</mat-label>
          <input matInput placeholder="Name Reg" formControlName="nameReg" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Name Short</mat-label>
          <input matInput placeholder="Name Short" formControlName="nameShort" />
        </mat-form-field>
      </div>
       <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Website</mat-label>
          <input matInput placeholder="Website" formControlName="website" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Claim Support Number</mat-label>
          <input matInput placeholder="Claim Support Number" formControlName="claimSupportNumber" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Claim Support Address</mat-label>
          <input matInput placeholder="Claim support Address" formControlName="claimSupportAddr" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Claim Support Email</mat-label>
          <input
            matInput
            type="email"
            placeholder="email"
            formControlName="claimSupportEmail"
          />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Xyz, Street" formControlName="address" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput placeholder="Mumbai" formControlName="city" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <input matInput placeholder="Maharashtra" formControlName="state" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>pincode</mat-label>
          <input matInput type="number" placeholder="Name" formControlName="pincode" />
        </mat-form-field>
      </div>

      

      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Number</mat-label>
          <input
            matInput
            type="number"
            placeholder="1234567"
            formControlName="contactNos"
            (wheel)="($event).preventDefault()"
          />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>In Active</mat-label>
          <mat-select formControlName="inactive" name="inactive">
            <mat-option value="true"> Inactive </mat-option>
            <mat-option value="false"> Activate </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div class="row">
        <button type="button" mat-raised-button (click)="fileInput.click()">
          Choose Logo
        </button>
        <input hidden formControlName="logo" (input)="selectFile($event)" #fileInput type="file" />
        <span class="file-name">{{ selectedFiles?.name }}</span>
      </div> -->
    </div>

    <div mat-dialog-actions class="action">
      <button mat-raised-button type="button" [mat-dialog-close]="false">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!tpaAddForm.valid"
        type="submit"
      >
        Save
      </button>
    </div>
  </form>
</section>
