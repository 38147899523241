import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { EKypModalComponent } from '../modal/e-kyp-modal/e-kyp-modal.component';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PolicyComponent {
  options: any = { allowedMimeType: ["application/pdf", "image/png", "image/jpeg", "image/jpg"] };
  public fileUploader: FileUploader = new FileUploader(this.options);
  public hasBaseDropZoneOver: boolean = false;
  @ViewChild('outerSort', { static: true })
  sort!: MatSort;
  @ViewChildren('innerSort')
  innerSort!: QueryList<MatSort>;
  @ViewChildren('innerTables')
  innerTables!: QueryList<MatTable<Member>>;


  displayedColumns: string[] = ['policyNumber','insuranceProduct', 'policyType', 'sumInsured', 'startDate', 'endDate'];
  dataSource!: MatTableDataSource<Policy>;

  type = 'policy';
  categories: [{ name: 'Policy'; type: 'POLICY'; }] | undefined
  selectedCategory = "POLICY";

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  imgData: any;
  loader: boolean = false;
  success:any;

  fileInfos?: Observable<any>;
  constructor(public dialog: MatDialog, private customerService: CustomerService, private cd: ChangeDetectorRef, private toastrService:ToastrService,) {
    this.getPolicies();
  }

  policies: any;
  error: any;
  message: any;
  loading: any = false;

  ngOnInit() {

    //this.dataSource = new MatTableDataSource(this.policies);
    //this.dataSource.sort = this.sort;
  }

  fileOverBase(event: boolean): void {
    this.hasBaseDropZoneOver = event;
  }
  getFiles(): FileLikeObject[] {
    return this.fileUploader.queue.map((fileItem) => {
      return fileItem.file;

    });
  }

  policyData: Policy[] = [];
  getPolicies() {
    this.customerService.getPolicies().pipe(first())
      .subscribe({
        next: (data: Policy[]) => {

          this.loading = false;
          //console.log(data);
          this.policies = data;
          data.forEach(policy => {
            //console.log(policy.policyMembers)
            if (policy.policyMembers && Array.isArray(policy.policyMembers)) {
              this.policyData = [...this.policyData, { ...policy, policyMembers: new MatTableDataSource(policy.policyMembers) }];
            } else {
              this.policyData = [...this.policyData, policy];
            }
          });
          this.dataSource = new MatTableDataSource(this.policyData);
          this.dataSource.sort = this.sort;
          this.dataSource.sort = this.sort;
          this.error = undefined;
        },
        error: error => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        }
      });
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }


  upload() {
    if (this.selectedFiles) {
    this.progress = 0;
    this.loader = true;
    let files = this.getFiles();
    let requests: any[] = [];

    files.forEach((file: any) => {
      let formData = new FormData();
      formData.append('files', file.rawFile, file.name);
      requests.push(this.customerService.uploadFile(formData, this.type, this.selectedCategory).subscribe({
        next: (event: any) => {
          //console.log("event",event);
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
            this.loader = false;

          } else if (event instanceof HttpResponse) {
            this.message = event.body.message;
            this.toastrService.success(this.message);

            this.fileInfos = this.customerService.getPolicies();
            this.loader = false;
            //window.location.reload();
            this.getPolicies();
          }
          
        },
        error: error => {
          if (error && error.error) {
            this.error = error.error;
            this.toastrService.error(this.error);

          } else {
            this.error = 'Could not upload the file!';
            this.toastrService.error(this.error);
          }
        }
      }));
    });
    this.selectedFiles = undefined
  }
  }

  analyzedData: any;

  analyze(policyId: any, memberName: any) {
    this.selectedPolicy = this.policies.filter((x: { id: any; }) => x.id == policyId);
    this.customerService.analyzePolicy(policyId, memberName).subscribe({
      next: (data: any) => {
        this.analyzedData = data;
        this.openDialog();
      }, error: (err: any) => {
        console.log(err);
        this.progress = 0;

        if (err.error && err.error.error) {
          this.error = err.error.error;
        } else {
          this.error = 'Could not Analyze the policy!';
        }

      }
    })
  }
  selectedPolicy: any;
  policyId: any;
  expandedElement!: Policy | null;

  innerDisplayedColumns = ['memberName', 'isHolder', 'ped', 'dob', 'relationship'];
  toggleRow(element: Policy) {
    this.policyId = element.id;
    element.policyMembers && (element.policyMembers as MatTableDataSource<Member>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Member>).sort = this.innerSort.toArray()[index]);
  }

  applyFilter(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target) {
      //console.log(target.value);
    } else {
      //console.log(target);
    }
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Member>).filter = target.value.trim().toLowerCase());
  }
  openDialog() {
    this.dialog.open(EKypModalComponent, {
      disableClose:true,
      data: {
        analyzedData: this.analyzedData,
      },
      width: '80%'
    });
  }


}

export interface Policy {
  "id": string,
  "userId": string,
  "ekypData": string,
  "policyPath": string,
  "policyNumber": number,
  "insuranceCompany": string,
  "insuranceProduct": string,
  "startDate": Date,
  "endDate": Date,
  "policyType": string,
  "portedPolicy": boolean,
  "sumInsured": number,
  "benefits": any,
  policyMembers?: Member[] | MatTableDataSource<Member>;
}

export interface Member {
  memeberName: string;
  isHolder: boolean;
  ped: string;
  dob: Date,
  relationship: string

}
