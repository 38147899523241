import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { CurrentStatusComponent } from 'src/app/cashless/timeline/current-status/current-status.component';
import { PreauthComponent } from 'src/app/pdf/cashless/preauth/preauth.component';
import { AuthService } from 'src/app/services/authService';
import { StandardPreauthFormComponent } from 'src/app/pdf/cashless/standard-preauth-form/standard-preauth-form.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataServiceService } from 'src/app/services/data-service.service';
import { AdminService } from 'src/app/services/admin.service';
import { CashlessFilter } from 'src/app/model/cashlessFilter';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}
@Component({
  selector: 'app-manage-cases',
  templateUrl: './manage-cases.component.html',
  styleUrls: ['./manage-cases.component.scss']
})
export class ManageCasesComponent implements OnInit, AfterViewInit, OnChanges {
  hospitalList: any = [];
  selectedHospital: any;
  insuranceMediaId: any;
  insurance: any;
  error: any;
  allTasks: any;
  taskId: any = "ALL";
  selectedTask: any;
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  @Input() isActive!: boolean;
  caseList: any = [];
  user: any;
  dataSource = new MatTableDataSource<any>(this.caseList);
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  displayedColumns = ['caseID', 'patientName', 'hospitalName', 'startDate', 'endDate', 'currentProcessState', 'action'];
  constructor(
    private cashlessService: CashflowService,
    private router: Router,
    private authService: AuthService,
    private _dialog: MatDialog,
    private dataService: DataServiceService,
    private adminService: AdminService
  ) {
    this.authService.user.subscribe((x: any) => {
      this.user = x;

    });



  }

  ngOnInit(): void {
   // this.getOnboardedHospitals();
    // if (this.isActive) {
    //   this.getOnboardedHospitals();
    //   this.getCaseList('', '', '');
    //   this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
    //     const flattenObject = (obj: any): string => {
    //       if (typeof obj !== 'object' || obj === null) {
    //         return obj ? obj.toString().toLowerCase().trim() : '';
    //       }
  
    //       if (Array.isArray(obj)) {
    //         return obj.map(flattenObject).join(', ');
    //       }
  
    //       return Object.values(obj)
    //         .map(flattenObject)
    //         .join(' ');
    //     };
  
    //     const dataStr = Object.keys(data)
    //       .map((key) => flattenObject(data[key]))
    //       .join(' ');
  
    //     const normalizedFilter = filter.toLowerCase().trim();
    //     return dataStr.includes(normalizedFilter);
    //   };
    // }
   
  }
  ngOnChanges(): void {
    if(this.isActive){
      this.getOnboardedHospitals();
    }
   
    // if (this.isActive) {
    //   this.getOnboardedHospitals();
    //   this.getCaseList('', '', '');
    // }
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  getOnboardedHospitals() {
    if (this.isPrivileged(["CAN_VIEW_HOSPITAL"])) {
      this.cashlessService.getOnboardedHospitalByUser()
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            console.log("Selected hospital is :" + JSON.stringify(data));
            this.hospitalList = data;
          },
          error: (error) => {
            console.log(error);
          }
        })
    }
    else {
      this.cashlessService.getOnboardedHospitals()
        .pipe(first())
        .subscribe({
          next: (hospitals: any) => {
            this.hospitalList = hospitals;
            console.log(this.hospitalList);

          },
          error: (error) => {
            console.log("error " + JSON.stringify(error));
          }
        })
    }
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    return dd;
  }

  getCaseList(hospitalOrgId: any, startDate: any, endDate: any) {
    this.cashlessService.getCaseList('cashlessProcess', hospitalOrgId, startDate, endDate)
      .pipe(first())
      .subscribe({
        next: (cases: any) => {
          console.log("cases " + JSON.stringify(cases));
          this.caseList = cases;
          this.dataSource.data = this.caseList;
          this.getDeployedprocessByProcessDefTasks("cashlessProcess");
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
        }
      })
  }

  searchCases() {
    console.log(this.selectedHospital);
    console.log(this.dateRange?.value.start);
    console.log(this.dateRange?.value.end);
    if (this.dateRange.value.start == null) {
      this.getCaseList(this.selectedHospital.hospitalOrgId, '', '');
    } else {
      this.getCaseList(this.selectedHospital.hospitalOrgId, this.dateRange?.value.start.toISOString(), this.dateRange?.value.end.toISOString());
    }

  }

  clear() {
    this.selectedHospital = '';
    this.getCaseList('', '', '');
    this.dateRange.reset();
    this.dataSource.data = this.caseList;
  }

  onDateRangeChange(selected: MatDateRangeInput<Date>) {
    if (selected.value) {
      console.log('Start date:', selected.value.start);
      console.log('End date:', selected.value.end);
    }
  }

  viewCaseDetails(selectedCase: any) {
    console.log(selectedCase);
    let dialogRef = this._dialog.open(CurrentStatusComponent, {
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'full-width-dialog',
      height: '100%',
      data: selectedCase
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    console.log(selectedCase);

  }

  viewPreAuth(selectedCase: any) {

    let dialogRef = this._dialog.open(StandardPreauthFormComponent, {
      disableClose: true,
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'full-width-dialog',
      height: '100%',
      data: { caseId: selectedCase.process_inst_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  filteredData: any = [];
  filterPriority(taskId: any) {
    if (taskId == "ALL") {
      this.taskId = 'ALL';
      this.selectedHospital = '';
      this.dateRange.reset();
      this.dataSource.data = this.caseList;
      this.getCaseList('', '', '');
    } else {
      this.taskId = taskId;
      this.filteredData = this.caseList.filter((data: any) => data.process_data.currentProcessStatus === taskId);
      this.dataSource.data = this.filteredData;
    }

  }

  selectedKey: any;
  filterData: any[] = [];
  deployedprocessByProcessDefTasks: any = [];
  getDeployedprocessByProcessDefTasks(processKey: any) {
    this.selectedKey = processKey;
    this.filterData = [];
    this.adminService
      .getDeployedprocessByProcessDefKey(processKey)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.deployedprocessByProcessDefTasks = data;
          console.log(this.deployedprocessByProcessDefTasks);

          for (let x = 0; x < data.length; x++) {
            const cf = new CashlessFilter();
            cf.taskId = data[x].taskId;
            cf.taskName = data[x].taskName;
            cf.count = this.caseList.filter((task: any) => task.process_data.currentProcessStatus === cf.taskId).length;
            this.filterData.push(cf);
          }

          this.filterData.sort((a: any, b: any) => b.count - a.count);
          console.log(JSON.stringify(this.filterData));
        },
        error: (err: any) => {
          console.log(err);
        },

      });

  }

  downloadPreAuthForm(element: any) {
    console.log(element);
    this.cashlessService.downloadPreAuthForm(element.process_inst_id)
      .pipe(first())
      .subscribe({
        next: (response: Blob) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${element.process_data.patientName}_${element.process_inst_id}_PreAuthForm.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    else {
      this.dataSource.filter = "";
    }
  }

  fileName = 'ExcelSheet.xlsx';
  exportexcel() {
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }

}
