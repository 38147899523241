import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit{
  error:any;
  message:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any)
     {
      
     }
  ngOnInit(): void {
    
      console.log("Inside modal "+JSON.stringify(this.data));
   
  }
}
