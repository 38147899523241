<div class="p-3">
    <div mat-dialog-title>
        <div class="row">
            <div class="col-8 d-flex justify-content-start">
                <h1>Add Portal Details</h1>
            </div>
            <div class="col-4 d-flex justify-content-end" mat-dialog-close>
                <h4>
                    <i class="px-3" style="color: lightgray">* marked fields are mandatory
                    </i>
                </h4>
                <mat-icon color="accent">cancel</mat-icon>
            </div>
        </div>
    </div>
    <hr />
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="portalForm" (ngSubmit)="onFormSubmit()">
            
                    <div class="form-group">
                        <label >Portal URL</label>
                        <input type="text" formControlName="url" id="url" class="form-control" required />
                        <div *ngIf="portalForm.get('url')?.invalid && (portalForm.get('url')?.dirty || portalForm.get('url')?.touched)">
                            <div *ngIf="portalForm.get('url')?.errors?.required" style="color: red;">Portal URL is required.</div>
                        </div>

                        <label >UserName</label>
                        <input type="text" formControlName="userName" id="userName" class="form-control" required />
                        <div *ngIf="portalForm.get('userName')?.invalid && (portalForm.get('userName')?.dirty || portalForm.get('userName')?.touched)">
                            <div *ngIf="portalForm.get('userName')?.errors?.required" style="color: red;">Username is required.</div>
                        </div>

                        <label >Password</label>
                        <input type="text" formControlName="password" id="password" class="form-control" required />
                        <div *ngIf="portalForm.get('password')?.invalid && (portalForm.get('password')?.dirty || portalForm.get('password')?.touched)">
                            <div *ngIf="portalForm.get('password')?.errors?.required" style="color: red;">Password is required.</div>
                        </div>
                    </div>
                    <div mat-dialog-actions class="action" >
                        <button mat-raised-button color="primary" type="submit">
                          Submit
                        </button>
                      </div>
        </form>
    </mat-dialog-content>
</div>