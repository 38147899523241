<div class="row p-3">
    <div class="col-8 d-flex justify-content-start">
        <h1>Add EKYP</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <mat-icon color="accent" >cancel</mat-icon>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <mat-dialog-content class="mat-typography">
            <div class="row pt-2">
                <div style="padding: 0">
                    <ngx-extended-pdf-viewer *ngIf="pdfUrl != undefined" [src]="pdfUrl" [textLayer]="true"
                        [showHandToolButton]="true">
                    </ngx-extended-pdf-viewer>
                    <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
                </div>
            </div>
        </mat-dialog-content>

    </div>

    <div class="col-6">

        <div>
            <form [formGroup]="policyForm">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="fromPicker" placeholder="Start Date"
                                formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #fromPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="toPicker" placeholder="End Date" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                            <mat-datepicker #toPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Policy Number</mat-label>
                            <input matInput placeholder="Policy Number" formControlName="policyNumber">
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Policy Type</mat-label>
                            <input matInput placeholder="Policy Type" formControlName="policyType">
                        </mat-form-field>
                    </div>

                </div>


                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Insurance Company</mat-label>
                            <input matInput placeholder="Insurance Company" formControlName="insuranceCompany">
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Insurance Name</mat-label>
                            <input matInput placeholder="Insurance Name" formControlName="insuranceName">
                        </mat-form-field>
                    </div>

                </div>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Ported</mat-label>
                            <mat-select formControlName="ported">
                                <mat-option value="yes">Yes</mat-option>
                                <mat-option value="no">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                      <h3> Policy Members</h3>
                      <button mat-button color="primary" class="add-member-button" type="button" (click)="addMember()">
                        Add Member
                      </button>
                    </div>
                    <div class="card-content">
                      <form [formGroup]="policyMembers">
                        <div formArrayName="members">
                          <div *ngFor="let member of members.controls; let i = index" [formGroupName]="i">
                            <div class="row">
                              <div class="col-4">
                                <mat-form-field class="full-width">
                                  <mat-label>Member Name</mat-label>
                                  <input matInput formControlName="memberName" />
                                </mat-form-field>
                              </div>
                  
                              <div class="col-4">
                                <mat-form-field class="full-width">
                                  <mat-label>Member Phone Number</mat-label>
                                  <input matInput formControlName="memberPhoneNumber" />
                                </mat-form-field>
                              </div>
                  
                              <div class="col-4">
                                <mat-form-field class="full-width">
                                  <mat-label>Holder</mat-label>
                                  <mat-select formControlName="isHolder">
                                    <mat-option value="true">True</mat-option>
                                    <mat-option value="false">False</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  
            </form>

            <div class="card">
                <div class="card-header">
                    <h3>Coverage Information</h3>
                    <button mat-button color="primary"  class="add-member-button" type="button" (click)="addCoverageInformation()">Add Member</button>
                </div>

                <div class="card-content">
                    <form [formGroup]="coverageInformation">
               
                        <div formArrayName="coverageInformationMemberList">
                            <div *ngFor="let member of coverageInformationMemberList.controls; let i=index" [formGroupName]="i">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Insured Name</mat-label>
                                            <input matInput formControlName="insuredName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Sum Insured</mat-label>
                                            <input matInput formControlName="sumInsured">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Super Bonus</mat-label>
                                            <input matInput formControlName="superBonus">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Restore</mat-label>
                                            <input matInput formControlName="restore">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Effective Coverage</mat-label>
                                            <input matInput formControlName="effectiveCoverage">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Date of Birth</mat-label>
                                            <input matInput [matDatepicker]="dob" formControlName="dob">
                                            <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                                            <mat-datepicker #dob></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </form>
                </div>
            </div>

           
            <div class="card">
                <div class="card-header">
                    <h3>Waiting Period Information</h3>
                    <button mat-button color="primary" type="button" class="add-member-button"  (click)="addWaitingPeriodMemberList()">Add
                        Member</button>
                </div>
                <div class="card-content">
                    <form [formGroup]="waitingPeriod">
                      <div formArrayName="waitingPeriodMemberList" class="waiting-period-container">
                        <div *ngFor="let member of waitingPeriodMemberList.controls; let i = index" [formGroupName]="i" class="waiting-period-entry">
                          <div class="row">
                            <div class="col-6">
                              <mat-form-field class="full-width">
                                <mat-label>Member Name</mat-label>
                                <input matInput formControlName="memberName" />
                              </mat-form-field>
                            </div>
                            <div class="col-6">
                              <mat-form-field class="full-width">
                                <mat-label>Pre Hospitalisation</mat-label>
                                <input matInput formControlName="preHospitalisation" />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <mat-form-field class="full-width">
                                <mat-label>Post Hospitalisation</mat-label>
                                <input matInput formControlName="postHospitalisation" />
                              </mat-form-field>
                            </div>
                            <div class="col-6">
                              <mat-form-field class="full-width">
                                <mat-label>Waiting Period</mat-label>
                                <input matInput formControlName="waitingPeriod" />
                              </mat-form-field>
                            </div>
                          </div>
                          <!-- Add horizontal spacing -->
                          <hr class="entry-divider" />
                        </div>
                      </div>
                    </form>
                  </div>
                  
            </div>
            
            <div class="card">
                <div class="card-header">
                    <h3>Sublimit Information</h3>
                    <button mat-button color="primary" type="button" class="add-member-button" (click)="addSublimitInformation()">Add Member</button>

                </div>
                <div class="card-content">
                    <form [formGroup]="sublimitInformation">
                
                        <div formArrayName="sublimitMemberInformation">
                            <div *ngFor="let member of sublimitMemberInformation.controls; let i=index" [formGroupName]="i">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Insured Name</mat-label>
                                            <input matInput formControlName="insuredName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>Room Rent</mat-label>
                                            <input matInput formControlName="roomRent">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="full-width">
                                            <mat-label>ICU Rent</mat-label>
                                            <input matInput formControlName="icuRent">
                                        </mat-form-field>
                                    </div>
                                </div>
        
                            </div>
                        </div>
        
                    </form>
                </div>
            </div>
           

            <div class="button-container">
                <button
                  mat-button
                  type="submit"
                  class="submit-button"
                  (click)="onFormSubmit()"
                >
                  Submit
                </button>
              </div>
              
        </div>
    </div>
</div>