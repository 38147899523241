import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { CustomerService } from "src/app/services/customer.service";

@Component({
  selector: "app-health-cloud-file",
  templateUrl: "./health-cloud-file.component.html",
  styleUrls: ["./health-cloud-file.component.scss"],
})
export class HealthCloudFileComponent {
  id: any;
  mediaType: any;
  modalTitle: any;
  imgData: any;
  pdfUrl: any;
  fileURL: any;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit() {
    this.getFile();
  }

  async getFile() {
   // console.log("data",this.data.id);
    // this.loader.showLoading("Please wait... Getting the file.");
    this.loading = true;
    await this.customerService.getHealthCloudFile(this.data.id).subscribe({
      next: (data: Uint8Array) => {
       // console.log("Got the file from S3");
        this.loading = false;
        if (this.data.mediaType != "application/pdf") {
          let file = new Blob([data], { type: this.data.mediaType });
          this.imgData = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(file)
          );
        } else {
          let file = new Blob([data], { type: this.data.mediaType });
          this.pdfUrl = URL.createObjectURL(file);
          //console.log('pdfUrl',this.pdfUrl);
        }
        // this.loader.hideLoader();
      },
      error: async (err: any) => {
        console.log("error");
        console.log(err);
        // this.loader.hideLoader();
      },
    });
  }
}
