import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { AuthService } from 'src/app/services/authService';
import { CustomerService } from 'src/app/services/customer.service';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss']
})
export class ViewTaskComponent implements OnInit {
  diagram:any;
  loading: boolean = true;
  error: any;
  user:any;
  message:any;
  public fields:any = [];
  public dynamicFormGroup!: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<ViewTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private workflowService:WorkflowService
  ){}
  ngOnInit(): void {
    console.log('data', this.data);
    this.user = this.authService.userValue;
    this.getBPMNDiagram(this.data.processInstanceId);
    this.fields=[];
    this.buildForm(this.data);
  }

  getBPMNDiagram(taskId:any){
    this.workflowService
    .getBPMNDiagram(taskId)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        
        this.diagram = 'data:image/png;base64,' + data; // use this in <img src="..."> binding

        this.loading = false;
      },
      error: (error) => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      },
    });
  }

  private buildForm(task: any) {
    const formGroupFields = this.getFormControlsFields(task);
    this.dynamicFormGroup = new FormGroup(formGroupFields);
  }

  getFormControlsFields(task: any){
    let formGroupFields: any = {};

    for (let form of task.formDataFieldOptions) {
      if (form.property.type.name == "date") {
        formGroupFields[form.property.id] = new FormControl(
          new Date().toISOString().substring(0, 10)
        );
      } else {
        formGroupFields[form.property.id] = new FormControl("");
      }

      this.fields.push({ ...form, fieldName: form.property.id });
      //console.log(form.id);
    }

    return formGroupFields;
  }

  executeTask(){

    let d = this.dynamicFormGroup.value;
    this.dynamicFormGroup.addControl("user", new FormControl(this.user));
    //console.log(JSON.stringify(this.dynamicFormGroup.value));

    this.workflowService
      .executeTask(this.data.id, this.dynamicFormGroup.value)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log("tasks " + JSON.stringify(data));
          this.loading = false;
          this.toastrService.success("Task has been completed");
          this.dialogRef.close();
         
          //this.selectedTask = undefined;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

}
