import { HttpErrorResponse, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { map, Observable } from "rxjs";
import { FileService } from "src/app/services/file.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-add-manage-hospital-technical-insurance",
  templateUrl: "./add-manage-hospital-technical-insurance.component.html",
  styleUrls: ["./add-manage-hospital-technical-insurance.component.scss"],
})
export class AddManageHospitalTechnicalInsuranceComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({});
  formData: FormData = new FormData();
  clicked: boolean = false;
  fileInfos?: Observable<any>;
  message: any;
  selectedFiles?: any;
  blacklisted: any;
  claims: any;
  insuranceCompanies: any;
  insuranceCompanyId: any = null;
  inactive: any;
  progress:any;
  validFrom:any;
  validTo:any;
  rowNum = 0;
  size=100;
  tpaMasterList:any;
  branchList:string[] =[];
  constructor(
    private HospitalRegistrationService: HospitalRegistrationService,
    private formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<AddManageHospitalTechnicalInsuranceComponent>,
    private toastrService: ToastrService,
    private fileService:FileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      selectInsuranceCompanies: ["", Validators.required],
      inHouse: ["", [Validators.required]],
      branch:["",Validators.required],
      tpa:[{ value: '', disabled: true }],
      blacklisted: ["", Validators.required],
      claimSubmissionMethod: ["", Validators.required],
      upload: [null, Validators.required],
      inactive: ["", Validators.required],
      validFrom: ["", Validators.required],
      validTo: ["", Validators.required],
      configdata: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    // console.log("Dailog:" + this.data.hospitalOrgId);
    console.log(this.data);
    this.getInsuranceCompanies();
    this.form.get('inHouse')?.valueChanges.subscribe(value => {
      if(value === 'no'){
        this.form.get('tpa')?.enable();
      }else{
        this.form.get('tpa')?.disable();
      }
    });
    //this.getTpaMaster();
  }

  getInsuranceCompanies() {
    this.HospitalRegistrationService.getInsuranceCompany().subscribe({
      next: (data: any) => {
        console.log(data);
        
       const insuranceList = data;
        this.insuranceCompanies = insuranceList;
        this.tpaMasterList = insuranceList.filter((insuranceCompany:any) =>insuranceCompany.payorType == 'TPA');
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        this.message = error;
      },
    });
  }

  getTpaMaster(){
    this.HospitalRegistrationService.getTpaMasterList(this.rowNum, this.size).subscribe({
      next :(data:any)=>{
        this.tpaMasterList = data;;
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        this.message = error;
      },
    })
  }

  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
  }

  getBranchList() {
    const selectedInsuranceId = this.insuranceCompanyId.id; // Get ID from object
  console.log("Selected Insurance ID:", selectedInsuranceId);
   console.log("Selected Insurance ID:", selectedInsuranceId);
    this.HospitalRegistrationService.getBranchList('',selectedInsuranceId).subscribe({
      next: (data: any) => {
        console.log("Branch List:", data);
        this.branchList = data;
      },
      error: (error) => {
        console.log("Error:", error);
        this.message = error;
      },
    });
   
  }



  uploadFiles(): void {
    this.formData.append("startDate", new Date(this.validFrom).toISOString());
    this.formData.append("endDate",new Date(this.validTo).toISOString());
    this.formData.append("file", this.selectedFiles);
    this.formData.append("category", "ONBOARDING");
    this.formData.append("sourceType", this.data.sourceType);/// put the the task defination name
    this.formData.append("entityType", "HOSPITAL");
    this.formData.append("entityId", this.data.entityId); /// put the hospital ID
    this.formData.append("docType", "CashlessSOC");
    this.formData.append("inHoused",  this.form.value.inHouse);
    console.log(this.form.value.inHouse);
    if(this.form.value.inHouse =='no'){
      console.log(this.form.value.inHouse);
      this.formData.append("tpa", this.form.value.tpa);
    }
    // this.formData.append("file", this.selectedFiles);
    // this.formData.append("hospitalId", this.data.hospitalOrgId);
    // this.formData.append("insuranceCompanyId", this.insuranceCompanyId);
    // this.formData.append("docType", "CashlessSOC");
    // this.formData.append("blacklisted", this.blacklisted);
    // this.formData.append("claimSubmissionMethod", this.claims);
    // this.formData.append("inactive", "false");
    // this.formData.append("entityId", this.data.hospitalOrgId); 
     
    this.formData.append("validTo",new Date(this.validTo).toISOString());
    if (this.claims === "EMAIL") {
      this.formData.append("emailConf", '{}');
    } else if (this.claims === "PORTAL") {
      this.formData.append("portalConf", '{}');
    } else if (this.claims === "INTEGRATION") {
      this.formData.append("integrationConf", '{}');
    } else {
      this.formData.append("emailConf", '{}');
    }
    
    this.hospitalInsuranceMediaSubmit();
  }

  hospitalInsuranceMediaSubmit() {
    this.fileService.addExternalMedia(this.formData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          console.log(event);
          this.selectedFiles = undefined
          this.saveInsuranceTPA(event.body.id);
          this.form.reset();
          // window.location.reload();
        } else if (event instanceof HttpErrorResponse) {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          this.toastrService.error(
            
            " SOC file couldn't be uploaded. Try again!"
          );
        }else{

        }
      })
    )
      .subscribe({
        next: (data: any) => {
        },
        error: (error) => {
          this.toastrService.success(
            
            "SOC  file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });
  }
  
 

  saveInsuranceTPA(socId: any) {
    console.log(this.form.value);
    var submitData = {
      "hospitalOrgId": this.data.data.hospitalOrgId,
      "insuranceId":this.insuranceCompanyId.id,
      "blacklisted": this.form.value.blacklisted,
      "claimSubmissionMethod": this.form.value.claimSubmissionMethod,
      "inactive": "false",
      "payorType": this.insuranceCompanyId.payorType,
      "socId": socId,
      "branch":this.form.value.branch,
    };
    console.log(submitData);
    this.HospitalRegistrationService.addHospitalInsurance(submitData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          this.toastrService.success( "SOC  File has been uploaded successfully");
          this.form.reset();
          this.clicked = false;
          this.progress = 0;
          this.selectedFiles = undefined;
          //this.getHospitalInsurance();

        } else {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          // this.toastrService.error(
          //   this.selectedCategory,
          //   " file couldn't be uploaded. Try again!"
          // );
        }
      })
    )

      .subscribe({
        next: (data: any) => {
        },
        error: (error) => {
          this.toastrService.success(
            
            "SOC file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });

  }

 
}
