<div class="px-4 py-3 my-3 text-center">
    <div class="row justify-content-md-center">
        <div class="col ">
            <div class="p-1 container text-center fw-bold">
                <p>
                    Your orders
                </p>
            </div>
        </div>
    </div>
    <hr>
    <div class="row  px-3 py-4 justify-content-md-center">
        <input type="search" (input)="handleInput($event)" class="form-control" placeholder="Filter"
        aria-label="Search">
      </div>
    
    
    <table mat-table [dataSource]="dataSource" class=" demo-table" multiTemplateDataRows>
        <!-- Position Column -->
        <ng-container matColumnDef="Order number" >
            <th mat-header-cell *matHeaderCellDef> Order Number </th>
            <td mat-cell *matCellDef="let element" (click)="toggleDetails(element.orderId)"> {{element.orderId}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Order date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.orderDate | date:'full'}} </td>
        </ng-container>
        
        <ng-container>
            <th mat-header-cell *matHeaderCellDef> Order Number </th>
            <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div [@detailExpand]="element === expandedElement?'expanded':'collapsed'">
                    <h2>Details</h2>
                    <table mat-table [dataSource]="selectedOrder" class=" inner-table" multiTemplateDataRows>
                        <ng-container matColumnDef="Product Name" >
                            <th mat-header-cell *matHeaderCellDef> Product Name </th>
                            <td mat-cell *matCellDef="let element" > {{element.productName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Quantity" >
                            <th mat-header-cell *matHeaderCellDef> Quantity </th>
                            <td mat-cell *matCellDef="let element" > {{element.qty}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Amount" >
                            <th mat-header-cell *matHeaderCellDef> Amount </th>
                            <td mat-cell *matCellDef="let element" > {{element.amount}} </td>
                        </ng-container>
                
                        <!-- Name Column -->
                        <ng-container matColumnDef="Order Date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.orderDate | date:'mediumDate'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.orderStatus}} </td>
                        </ng-container>
                        
                        <!-- <ng-container>
                            <th mat-header-cell *matHeaderCellDef> Order Number </th>
                            <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
                        </ng-container> -->
                        <tr mat-header-row *matHeaderRowDef="selectedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: selectedColumns;" >  </tr>
                    </table>
                </div>
            </td>
        </ng-container>
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleDetails(row)">  </tr>
        <tr mat-row *matRowDef="let row; columns:['expandedDetail']"></tr>
    
    </table>
</div>