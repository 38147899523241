import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first, firstValueFrom } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent} from '@angular/material/chips';

interface list{
  id:number;
  name:string;
}

@Component({
  selector: "app-add-zone",
  //standalone: true,
  //imports: [],
  templateUrl: "./add-zone.component.html",
  styleUrl: "./add-zone.component.scss",
})
export class AddZoneComponent {
  zoneForm: FormGroup;
  pageIndex = 0;
  pageSize = 100;
  visible = true;
  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emailTags: string[] = [];


  insuranceAndTpaList :list[] =[];
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private tpaService: ManagetpaService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AddZoneComponent>
  ) {
    this.zoneForm = this._fb.group({
      // id: '',
      category: ["", Validators.required],
      insuranceCompanyId: ["", [Validators.required]],
      zone: ["", [Validators.required]],
      insuranceEmailId: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    const insuranceList = await this.getInsuranceCompanyMasterData();
    const tpaList = await this.getTpaList();
    this.insuranceAndTpaList = [
      ...insuranceList.map((insurance: any) => ({
        id: insurance.id,
        name: insurance.nameReg
      })),
      ...tpaList.map((tpa: any) => ({
        id: tpa.id,
        name: tpa.tpaName
      }))
    ];

    console.log(this.insuranceAndTpaList);

  }

  ngAfterViewInit(): void {}

  deployedAllProcesses: any;

  onFormSubmit() {
    var data = {
      insuranceEmailId: this.emailTags.join(';'),
      category: this.zoneForm.value.category,
      insuranceCompanyId: this.zoneForm.value.insuranceCompanyId,
      zone: this.zoneForm.value.zone,
      dateCreated: "",
      createdBy: "",
      dateUpdated: "",
      updatedBy: "",
    };
    console.log(JSON.stringify(data));

    this.adminService.addZone(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("Zone has been added successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));
      },
    });
  }
  async getInsuranceCompanyMasterData(): Promise<any[]> {
    try {
      const data = await firstValueFrom(
        this.adminService.getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize)
      );
      return data;
    } catch (error) {
      console.error(error);
      return [];  
    }
  }
 
  async getTpaList(): Promise<any[]> {
    try {
      const data = await firstValueFrom(this.tpaService.getTpa());
      return data;
    } catch (error) {
      console.error(error);
      return [];  
    }

  }

  addEmailId(event:MatChipInputEvent){
    const input = event.input;
    const value = (event.value || '').trim();
    console.log(value)
    if(value){
      this.emailTags.push(value);
      console.log(this.emailTags)
    }
    if(input){
      input.value = '';
    }
  }

  removeEmailId(emailId: string){
    const index = this.emailTags.indexOf(emailId);
    if(index >= 0){
      this.emailTags.splice(index, 1);
    }
  }

}
