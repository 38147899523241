import { NgModule , CUSTOM_ELEMENTS_SCHEMA  } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { FileUploadModule } from "ng2-file-upload";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HospitalregistrationComponent } from "./hospitalregistration/hospitalregistration.component";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { AccountactivationComponent } from "./accountactivation/accountactivation.component";
import { LoginComponent } from "./login/login.component";
import { CampaignComponent } from "./campaign/campaign.component";
import { HttpInterceptor } from "./intercetor/httpInterceptor";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AccountactivationfromemailComponent } from "./accountactivationfromemail/accountactivationfromemail.component";
import { HomeComponent } from "./home/home.component";
import { ForgetPassowrdComponent } from "./forget-password/forget-password.component";
import { OrgRegistrationComponent } from "./registration/org-registration/org-registration.component";
import { NgStepperModule } from "angular-ng-stepper";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from "@angular/material/stepper";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSelectModule } from "@angular/material/select";
import { MatPaginatorModule } from "@angular/material/paginator";
import { ManageUserComponent } from "./admin/manage-user/manage-user.component";
import { ManageRoleComponent } from "./admin/manage-role/manage-role.component";
import { ManageCompanyComponent } from "./admin/manage-company/manage-company.component";
import { ManageOrgComponent } from "./admin/manage-org/manage-org.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { PartnerComponent } from "./portal/partner/partner.component";
import { ProductComponent } from "./storefront/product/product.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { EditUserComponent } from "./admin/manage-user/edit-user/edit-user.component";
import { MatDialogModule } from "@angular/material/dialog";
import { EditOrgComponent } from "./admin/manage-org/edit-org/edit-org.component";
import { ProfileComponent } from "./profile/profile.component";
import { AddUserComponent } from "./admin/manage-user/add-user/add-user.component";
import { AddRoleComponent } from "./admin/manage-role/add-role/add-role.component";
import { AddUserRoleComponent } from "./admin/manage-user/add-user-role/add-user-role.component";
import { ManageUserRoleComponent } from "./admin/manage-user/manage-user-role/manage-user-role.component";
import { ClaimsComponent } from "./portal/bg/claims/claims.component";
import { PrivilegesComponent } from "./admin/manage-user/privileges/privileges.component";
import { PolicyComponent } from "./portal/customer/hobnob/policy/policy.component";
import { HobnobHomeComponent } from "./portal/customer/hobnob/hobnob-home/hobnob-home.component";
import { MatSortModule } from "@angular/material/sort";
import { ClaimsNowComponent } from "./portal/customer/hobnob/claims-now/claims-now.component";
import { MatCardModule } from "@angular/material/card";
import { MatChipListbox, MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule , MAT_DATE_FORMATS , DateAdapter } from "@angular/material/core";
import { InboxComponent } from "./portal/bg/inbox/inbox.component";
import { ManageCouponComponent } from "./admin/manage-coupon/manage-coupon.component";
import { AddCouponComponent } from "./admin/manage-coupon/add-coupon/add-coupon.component";
import { EditCouponComponent } from "./admin/manage-coupon/edit-coupon/edit-coupon.component";
import { RegSelectionComponent } from "./reg-selection/reg-selection.component";
import { UserRegistrationComponent } from "./registration/user-registration/user-registration.component";
import { ClaimOrdersComponent } from "./portal/customer/hobnob/claim-orders/claim-orders.component";
import { ClaimOrdersDetailsComponent } from "./portal/customer/hobnob/claim-orders/claim-orders-details/claim-orders-details.component";
import { HospitalLocatorComponent } from "./portal/customer/hobnob/hospital-locator/hospital-locator.component";
import { ViewFileComponent } from "./portal/bg/inbox/view-file/view-file.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { HealthCloudComponent } from "./portal/customer/hobnob/health-cloud/health-cloud.component";
import { HealthCloudFileComponent } from "./portal/customer/hobnob/health-cloud/health-cloud-file/health-cloud-file.component";
import { ClaimOrdersAgentComponent } from "./portal/customer/hobnob/claim-orders-agent/claim-orders-agent.component";
import { ClaimOrdersDetailAgentComponent } from "./portal/customer/hobnob/claim-orders-detail-agent/claim-orders-detail-agent.component";
import { EditStoreProductComponent } from "./storefront/product/edit-store-product/edit-store-product.component";
import { AddStoreProductComponent } from "./storefront/product/add-store-product/add-store-product.component";
import { ManageProductComponent } from "./admin/manage-product/manage-product.component";
import { AddProductComponent } from "./admin/manage-product/add-product/add-product.component";
import { UpdateProductComponent } from "./admin/manage-product/update-product/update-product.component";
import { RoleWithPrivilagesComponent } from "./admin/manage-role/role-with-privilages/role-with-privilages.component";
import { UpdateRoleComponent } from "./admin/manage-role/update-role/update-role.component";
import { EditProfileComponent } from "./profile/edit-profile/edit-profile.component";
import { AddKypComponent } from "./portal/bg/inbox/add-kyp/add-kyp.component";
import { AddAssignTaskComponent } from "./modal/add-assign-task/add-assign-task.component";
import { EditKypComponent } from "./portal/bg/inbox/edit-kyp/edit-kyp.component";
import { ServiceLevelAggrementComponent } from "./portal/customer/hobnob/service-level-aggrement/service-level-aggrement.component";
import { VendorOrderComponent } from "./storefront/vendor-order/vendor-order.component";
import { VendorSupportComponent } from "./storefront/vendor-support/vendor-support.component";
import { SupportComponent } from "./admin/support/support.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ManageReporteeComponent } from "./admin/manage-user/manage-reportee/manage-reportee.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { CreatecaseComponent } from "./cashless/createcase/createcase.component";
import { WorkflowDashboardComponent } from "./dashboard/workflow-dashboard/workflow-dashboard.component";
import { TimelineComponent } from "./cashless/timeline/timeline.component";
import { CasedetailsComponent } from "./cashless/casedetails/casedetails.component";
import { FormComponent } from "./cashless/case/form/form.component";
import { CashlessInboxComponent } from "./cashless/cashless-inbox/cashless-inbox.component";
import { ProcessEkypComponent } from "./process-ekyp/process-ekyp.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { OnboardingFormComponent } from "./onboarding/onboarding-form/onboarding-form.component";
import { InsuranceComponent } from "./onboarding/insurance/insurance.component";
import { OnboardingInsuranceViewModalComponent } from "./onboarding/onboarding-insurance-view-modal/onboarding-insurance-view-modal.component";
import { ManageCasesComponent } from "./admin/manage-cases/manage-cases.component";
import { ManageHospitalsComponent } from "./admin/manage-hospitals/manage-hospitals.component";
import { ManageHospitalDetailComponent } from "./admin/manage-hospitals/manage-hospital-detail/manage-hospital-detail.component";
import { CaseDetailsComponent } from "./admin/manage-cases/case-details/case-details.component";
import { TaskDetailsComponent } from "./admin/manage-cases/task-details/task-details.component";
import { PolicyConsultingComponent } from "./policy-consulting/policy-consulting.component";
import { BgProductComponent } from "./onboarding/bg-product/bg-product.component";
import { ManageHospitalProductsComponent } from "./admin/manage-hospital-products/manage-hospital-products.component";
import { ManageTPAComponent } from "./admin/manage-tpa/manage-tpa.component";
import { AddTpaComponent } from "./admin/manage-tpa/add-tpa/add-tpa.component";
import { AddHospitalProductsComponent } from "./admin/manage-hospital-products/add-hospital-products/add-hospital-products.component";
import { UpdateHospitalProductsComponent } from "./admin/manage-hospital-products/update-hospital-products/update-hospital-products.component";
import { AssignmentComponent } from "./onboarding/assignment/assignment.component";
import { TechComponent } from "./onboarding/insurance/tech/tech.component";

import { UpdateTpaComponent } from "./admin/manage-tpa/update-tpa/update-tpa.component";
import { UpdateInsuranceCompanyComponent } from "./admin/manage-insurance-company/update-insurance-company/update-insurance-company.component";
import { AddInsuranceCompanyComponent } from "./admin/manage-insurance-company/add-insurance-company/add-insurance-company.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { ViewUploadFileComponent } from "./file-upload/view-upload-file/view-upload-file.component";
import { AgCharts } from "ag-charts-angular";
import { CreateAgreementComponent } from "./onboarding/onboarding-form/create-agreement/create-agreement.component";
import { InitiateOnboardingComponent } from "./onboarding/onboarding-form/initiate-onboarding/initiate-onboarding.component";
import { TechOnboardingComponent } from "./onboarding/onboarding-form/tech-onboarding/tech-onboarding.component";

import { UploadTpaLogoComponent } from "./admin/manage-tpa/upload-tpa-logo/upload-tpa-logo.component";

import { ViewTaskComponent } from "./policy-consulting/view-task/view-task.component";
import { AssignmentCashlessComponent } from "./cashless/assignment-cashless/assignment-cashless.component";
import { AssignmentEkypComponent } from "./process-ekyp/assignment-ekyp/assignment-ekyp.component";
import { EKypModalComponent } from "./portal/customer/hobnob/modal/e-kyp-modal/e-kyp-modal.component";
import { ManagementDashboardComponent } from "./dashboard/management-dashboard/management-dashboard.component";
import { CamelCaseToSpacesPipe } from "./pipes/camel-case-to-spaces-pipe.pipe";
import { UserModalComponent } from "./dashboard/modal/user-modal/user-modal.component";
import { CreateAbhaComponent } from "./portal/hobnob/abha/create-abha/create-abha.component";
import { UpdateMedicalReportComponent } from "./portal/hobnob/abha/update-medical-report/update-medical-report.component";
import { ViewMedicalReportComponent } from "./portal/hobnob/abha/view-medical-report/view-medical-report.component";
import { AddMedicalReportComponent } from "./portal/hobnob/abha/add-medical-report/add-medical-report.component";
import { ViewAddKypComponent } from "./modal/view-add-kyp/view-add-kyp.component";
import { ViewUpdateKypComponent } from "./modal/view-update-kyp/view-update-kyp.component";
import { AssignmentPolicyConsultingComponent } from "./policy-consulting/assignment-policy-consulting/assignment-policy-consulting.component";
import { AssignmentReimbursementComponent } from "./portal/bg/assignment-reimbursement/assignment-reimbursement.component";
import { ManageEkypComponent } from "./admin/manage-ekyp/manage-ekyp.component";
import { AggregatorComponent } from "./registration/aggregator/aggregator.component";
import { VendorComponent } from "./registration/vendor/vendor.component";
import { UpdateManageHospitalTechnicalInsuranceComponent } from "./admin/manage-hospitals/update/update-manage-hospital-technical-insurance/update-manage-hospital-technical-insurance.component";
import { ViewManageHospitalTechnicalInsuranceComponent } from "./admin/manage-hospitals/view/view-manage-hospital-technical-insurance/view-manage-hospital-technical-insurance.component";
import { AddManageHospitalTechnicalInsuranceComponent } from "./admin/manage-hospitals/add/add-manage-hospital-technical-insurance/add-manage-hospital-technical-insurance.component";
import { AddManageHospitalAggrementComponent } from "./admin/manage-hospitals/add/add-manage-hospital-aggrement/add-manage-hospital-aggrement.component";
import { AddKypUserComponent } from "./admin/manage-ekyp/add-kyp-user/add-kyp-user.component";
import { ViewProcessEkypComponent } from "./modal/view-process-ekyp/view-process-ekyp.component";
import { ViewReimbursementComponent } from "./modal/view-reimbursement/view-reimbursement.component";
import { UploadPolicyFileComponent } from "./admin/manage-ekyp/add-kyp-user/upload-policy-file/upload-policy-file.component";
import { OnboardingInboxComponent } from "./onboarding/onboarding-inbox/onboarding-inbox.component";
import { ProcessEkypInboxComponent } from "./process-ekyp/process-ekyp-inbox/process-ekyp-inbox.component";
import { PolicyConsultingInboxComponent } from "./policy-consulting/policy-consulting-inbox/policy-consulting-inbox.component";
import { ManageUserOrgComponent } from "./admin/manage-user/manage-user-org/manage-user-org.component";
import { ClaimDetailsComponent } from "./portal/bg/claims/modal/claim-details/claim-details.component";
import { AddUserOrgComponent } from "./admin/manage-user/manage-user-org/add-user-org/add-user-org.component";
import { UpdateUserOrgComponent } from "./admin/manage-user/manage-user-org/update-user-org/update-user-org.component";
import { NewInboxComponent } from "./cashless/new-inbox/new-inbox.component";
import { CurrentStatusComponent } from "./cashless/timeline/current-status/current-status.component";
import { ManageCategoryMasterComponent } from "./admin/manage-category-master/manage-category-master.component";
import { AddCategoryMasterComponent } from "./admin/manage-category-master/add-category-master/add-category-master.component";
import { UpdateCategoryMasterComponent } from "./admin/manage-category-master/update-category-master/update-category-master.component";
import { ManageTaskCalculationComponent } from "./admin/manage-task-calculation/manage-task-calculation.component";
import { AddTaskCalculationComponent } from "./admin/manage-task-calculation/add-task-calculation/add-task-calculation.component";
import { UpdateTaskCalculationComponent } from "./admin/manage-task-calculation/update-task-calculation/update-task-calculation.component";
import { PreAuthDetailsComponent } from "./cashless/pre-auth-details/pre-auth-details.component";
import { NgOtpInputModule } from "ng-otp-input";
import { PreauthComponent } from "./pdf/cashless/preauth/preauth.component";
import { ManageInsuranceLogoComponent } from "./admin/manage-insurance-company/manage-insurance-logo/manage-insurance-logo.component";
import { ViewFileExternalMediaComponent } from "./portal/bg/inbox/view-file-external-media/view-file-external-media.component";
import { EmailConfComponent } from "./admin/email-conf/email-conf.component";
import { AddEmailConfComponent } from "./admin/email-conf/add-email-conf/add-email-conf.component";
import { UpdateEmailConfComponent } from "./admin/email-conf/update-email-conf/update-email-conf.component";
import { ViewFilesComponent } from "./modal/view-files/view-files.component";
import { UpdateMailParserConfsComponent } from "./admin/manage-insurance-company/view-mail-parser-confs/update-mail-parser-confs/update-mail-parser-confs.component";
import { ViewMailParserConfsComponent } from "./admin/manage-insurance-company/view-mail-parser-confs/view-mail-parser-confs.component";
import { AddMailParserConfsComponent } from "./admin/manage-insurance-company/view-mail-parser-confs/add-mail-parser-confs/add-mail-parser-confs.component";
import { AttachmentScrollerComponent } from './attachment-scroller/attachment-scroller.component';
import { ManagerInsuranceCompanyComponent } from "./admin/manage-insurance-company/manager-insurance-company.component";
import { ManageWorkflowComponent } from "./admin/manage-workflow/manage-workflow.component";
import { AddPortalDetailsComponent } from './admin/manage-hospitals/add-portal-details/add-portal-details.component';
import { ViewPortalDetailsComponent } from './admin/manage-hospitals/view-portal-details/view-portal-details.component';
import { SendEmailConfigComponent } from './admin/manage-hospitals/send-email-config/send-email-config.component';
import { CapitalizeAndSpacePipe } from "./capitalize-and-space.pipe";
import { ExternalLoginComponent } from "./admin/external-login/external-login.component";
import { CustomDateAdapter } from "./model/custom-native-adapter";
import { MY_DATE_FORMATS } from "./model/custom-date-formats";
import { StandardPreauthFormComponent } from "./pdf/cashless/standard-preauth-form/standard-preauth-form.component";
import { ManageZoneComponent } from "./admin/manage-zone/manage-zone.component";
import { AddZoneComponent } from "./admin/manage-zone/add-zone/add-zone.component";
import { UpdateZoneComponent } from "./admin/manage-zone/update-zone/update-zone.component";
import { ManageEmailLookupComponent } from './admin/manage-email-lookup/manage-email-lookup.component';
import { AddEmailLookupComponent } from './admin/manage-email-lookup/add-email-lookup/add-email-lookup.component';
import { UpdateEmailLookupComponent } from './admin/manage-email-lookup/update-email-lookup/update-email-lookup.component';
import { DetailsEmailLookupComponent } from './admin/manage-email-lookup/details-email-lookup/details-email-lookup.component';
import { AddEmailLookupDetailComponent } from './admin/manage-email-lookup/add-email-lookup-detail/add-email-lookup-detail.component';
import { AddHospitalSealComponent } from "./admin/manage-hospitals/add-hospital-seal/add-hospital-seal.component";
import { ViewEkypComponent } from './admin/manage-ekyp/view-ekyp/view-ekyp.component';
import { MemberListComponent } from './cashless/case/member-list/member-list.component';
import { HospitalSubscriptionComponent } from "./subscription/hospital-subscription/hospital-subscription.component";
import { ManageHobnobOrdersComponent } from './admin/manage-hobnob-orders/manage-hobnob-orders.component';
import { AddEkypDetailsComponent } from "./process-ekyp/add-ekyp-details/add-ekyp-details.component";
import { EditEkypDetailsComponent } from "./process-ekyp/edit-ekyp-details/edit-ekyp-details.component";
import { ViewPolicyMembersComponent } from "./admin/manage-ekyp/view-policy-members/view-policy-members.component";
import { ConfirmationMessageComponent } from "./attachment-scroller/confirmation-message/confirmation-message.component";


@NgModule({ declarations: [
        AppComponent,
        StandardPreauthFormComponent,
        CapitalizeAndSpacePipe,
        HospitalregistrationComponent,
        AccountactivationComponent,
        LoginComponent,
        CampaignComponent,
        AccountactivationfromemailComponent,
        HomeComponent,
        AddKypUserComponent,
        ForgetPassowrdComponent,
        OrgRegistrationComponent,
        ManageUserComponent,
        ManageRoleComponent,
        ManageCompanyComponent,
        ManageOrgComponent,
        PartnerComponent,
        VendorComponent,
        ProductComponent,
        EditUserComponent,
        EditOrgComponent,
        ProfileComponent,
        AddUserComponent,
        AddRoleComponent,
        AddUserRoleComponent,
        ManageUserRoleComponent,
        ClaimsComponent,
        PrivilegesComponent,
        PolicyComponent,
        HobnobHomeComponent,
        ClaimsNowComponent,
        InboxComponent,
        ManageCouponComponent,
        AddCouponComponent,
        EditCouponComponent,
        RegSelectionComponent,
        UserRegistrationComponent,
        ClaimOrdersComponent,
        ClaimOrdersDetailsComponent,
        HospitalLocatorComponent,
        ViewFileComponent,
        HealthCloudComponent,
        HealthCloudFileComponent,
        ClaimOrdersAgentComponent,
        ClaimOrdersDetailAgentComponent,
        EditStoreProductComponent,
        AddStoreProductComponent,
        ManageProductComponent,
        AddProductComponent,
        UpdateProductComponent,
        RoleWithPrivilagesComponent,
        UpdateRoleComponent,
        EditProfileComponent,
        AddKypComponent,
        AddAssignTaskComponent,
        EditKypComponent,
        ServiceLevelAggrementComponent,
        VendorOrderComponent,
        VendorSupportComponent,
        SupportComponent,
        ManageReporteeComponent,
        CreatecaseComponent,
        WorkflowDashboardComponent,
        TimelineComponent,
        CasedetailsComponent,
        FormComponent,
        CashlessInboxComponent,
        ProcessEkypComponent,
        OnboardingComponent,
        OnboardingFormComponent,
        InsuranceComponent,
        OnboardingInsuranceViewModalComponent,
        ManageCasesComponent,
        ManageHospitalsComponent,
        ManageHospitalDetailComponent,
        CaseDetailsComponent,
        TaskDetailsComponent,
        PolicyConsultingComponent,
        BgProductComponent,
        ManageHospitalProductsComponent,
        ManageTPAComponent,
        AddTpaComponent,
        AddHospitalProductsComponent,
        UpdateHospitalProductsComponent,
        AssignmentComponent,
        TechComponent,
        UpdateTpaComponent,
        ManagerInsuranceCompanyComponent,
        UpdateInsuranceCompanyComponent,
        AddInsuranceCompanyComponent,
        FileUploadComponent,
        ViewUploadFileComponent,
        CreateAgreementComponent,
        InitiateOnboardingComponent,
        TechOnboardingComponent,
        UploadTpaLogoComponent,
        ViewTaskComponent,
        AssignmentCashlessComponent,
        AssignmentEkypComponent,
        EKypModalComponent,
        ManagementDashboardComponent,
        CamelCaseToSpacesPipe,
        UserModalComponent,
        CreateAbhaComponent,
        UpdateMedicalReportComponent,
        ViewMedicalReportComponent,
        AddMedicalReportComponent,
        ViewAddKypComponent,
        ViewUpdateKypComponent,
        AssignmentPolicyConsultingComponent,
        AssignmentReimbursementComponent,
        ManageEkypComponent,
        AggregatorComponent,
        UpdateManageHospitalTechnicalInsuranceComponent,
        ViewManageHospitalTechnicalInsuranceComponent,
        AddManageHospitalTechnicalInsuranceComponent,
        AddManageHospitalAggrementComponent,
        ViewProcessEkypComponent,
        ViewReimbursementComponent,
        UploadPolicyFileComponent,
        OnboardingInboxComponent,
        ProcessEkypInboxComponent,
        PolicyConsultingInboxComponent,
        ManageUserOrgComponent,
        ClaimDetailsComponent,
        AddUserOrgComponent,
        UpdateUserOrgComponent,
        NewInboxComponent,
        CurrentStatusComponent,
        ManageCategoryMasterComponent,
        AddCategoryMasterComponent,
        UpdateCategoryMasterComponent,
        ManageTaskCalculationComponent,
        AddTaskCalculationComponent,
        UpdateTaskCalculationComponent,
        PreAuthDetailsComponent,
        PreauthComponent,
        ManageInsuranceLogoComponent,
        ViewFileExternalMediaComponent,
        EmailConfComponent,
        AddEmailConfComponent,
        UpdateEmailConfComponent,
        ViewFilesComponent,
        ViewMailParserConfsComponent,
        UpdateMailParserConfsComponent,
        AddMailParserConfsComponent,
        AttachmentScrollerComponent,   
        ManageWorkflowComponent,
        AttachmentScrollerComponent,
        AddPortalDetailsComponent,
        ViewPortalDetailsComponent,
        SendEmailConfigComponent,   
        ExternalLoginComponent,
        ManageZoneComponent,
        AddZoneComponent,
        UpdateZoneComponent,
        ManageEmailLookupComponent,
        AddEmailLookupComponent,
        UpdateEmailLookupComponent,
        DetailsEmailLookupComponent,
        AddEmailLookupDetailComponent,
        AddHospitalSealComponent,
        ViewEkypComponent,
        MemberListComponent,
        ManageHobnobOrdersComponent,
        AddEkypDetailsComponent,
        EditEkypDetailsComponent,
        ViewPolicyMembersComponent,
        HospitalSubscriptionComponent,
        ManageHobnobOrdersComponent,
        ConfirmationMessageComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgStepperModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatTableModule,
        MatTabsModule,
        MatExpansionModule,
        MatIconModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSortModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FileUploadModule,
        ToastrModule.forRoot(),
        NgxExtendedPdfViewerModule,
        MatTooltipModule,
        MatTableModule,
        NgOtpInputModule,
        AgCharts],
        schemas: [CUSTOM_ELEMENTS_SCHEMA],
         providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        
    ] })
export class AppModule { }
