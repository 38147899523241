<section>
  <div class="container mb-4">
    <div class="row">
      <div class="col-8">
        <!-- title -->
        <div class="alert alert-dark row" role="alert">
          <div class="col-6">This is a dark alert—check it out!</div>
          <div class="col-6" style="text-align: end">Case/{{ caseId }}</div>
        </div>
        <!-- main div -->
        <div id="tabs" #tabs>
          <ul class="custom-nav nav nav-pills nav-fill">
            <li class="nav-item">
              <a
                class="nav-link active"
                (click)="general('general')"
                [ngClass]="{ active: activeTab === 'general' }"
                (click)="general('general')"
                data-toggle="tab"
                >General</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="details('details')"
                [ngClass]="{ active: activeTab === 'details' }"
                data-toggle="tab"
                (click)="details('details')"
                >Member details</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="documents('documents')"
                [ngClass]="{ active: activeTab === 'documents' }"
                data-toggle="tab"
                (click)="documents('documents')"
                >Related documents</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="comments('comments')"
                [ngClass]="{ active: activeTab === 'comments' }"
                data-toggle="tab"
                (click)="comments('comments')"
                >Comments</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="mail('mail')"
                [ngClass]="{ active: activeTab === 'mail' }"
                data-toggle="tab"
                (click)="mail('mail')"
                >Related Mail</a
              >
            </li>
          </ul>

          <div class="tab-content">
            <div class="alert alert-secondary mt-2 row" role="alert">
              <dl class="row">
                <div class="col-4">
                  <dt>Case id</dt>
                  <dd>{{ this.currentState?.data.id}}</dd>
                </div>
                <div class="col-4">
                  <dt>Date of admission</dt>
                  <dd>7 Jun 2024, 09:58 PM</dd>
                </div>
                <div class="col-4">
                  <dt>Date of discharge</dt>
                  <dd>11 Jun 2024, 11:00 PM</dd>
                </div>
                <div class="col-4">
                  <dt>Claimed amount</dt>
                  <dd>₹ 1,41,208.00</dd>
                </div>
                <div class="col-4">
                  <dt>Approved amount</dt>
                  <dd>₹ 90,000.00</dd>
                </div>
                <div class="col-4">
                  <dt>Courier tracking/ Acknowledgement number</dt>
                  <dd>-</dd>
                </div>
                <div class="col-4">
                  <dt>Courier Provider Service</dt>
                  <dd>-</dd>
                </div>
                <div class="col-4">
                  <dt>Courier Status</dt>
                  <dd>-</dd>
                </div>
              </dl>
            </div>
            <div
              class="tab-pane"
              id="general"
              [ngClass]="{ active: activeTab === 'general' }"
            >
              general screen
            </div>
            <div
              class="tab-pane"
              id="details"
              [ngClass]="{ active: activeTab === 'details' }"
            >
              <div class="alert alert-light">
                <dl class="row">
                  <div class="col-4">
                    <dt>Name Of Patient</dt>
                    <dd>Jhon</dd>
                  </div>
                  <div class="col-4">
                    <dt>Gender</dt>
                    <dd>Male</dd>
                  </div>
                  <div class="col-4">
                    <dt>Age</dt>
                    <dd>21</dd>
                  </div>
                  <div class="col-4">
                    <dt>Date Of Birth</dt>
                    <dd>1-may-1997</dd>
                  </div>
                  <div class="col-4">
                    <dt>Contact Number</dt>
                    <dd>1234567890</dd>
                  </div>
                  <div class="col-4">
                    <dt>Contact Number of Attending Relative</dt>
                    <dd>-</dd>
                  </div>
                  <div class="col-4">
                    <dt>Insured ID Card Number</dt>
                    <dd>-</dd>
                  </div>
                </dl>
              </div>
            </div>
            <div
              class="tab-pane"
              id="documents"
              [ngClass]="{ active: activeTab === 'documents' }"
            >
              documents screen
            </div>
            <div
              class="tab-pane"
              id="comments"
              [ngClass]="{ active: activeTab === 'comments' }"
            >
              comments screen
            </div>
            <div
              class="tab-pane"
              id="mail"
              [ngClass]="{ active: activeTab === 'mail' }"
            >
              mail screen
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <app-timeline></app-timeline>
      </div>
    </div>
  </div>
</section>
