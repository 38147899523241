<div class="px-4 py-3 my-3 text-center">
    <div class="px-4 py-1 my-1 text-center">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <a href="https://www.bimagarage.com"><img src="../../assets/img/logo.png"></a>
            <h3 class="display-7 fw-bold">Welcome to Bimagarage!</h3>
            <h3 class="display-7 fw-bold">Thanks for visting our registration page. </h3>

            <h1 class="py-3 display-5 fw-bold">{{name}} </h1>

            <div class="col-lg-6 mx-auto">
                <p class="lead mb-4">Please check the information provided below in the form, once you are satisfied
                    with the deatils, click on the registration button to initiate the process. <span style="color:rgb(67, 95, 146)"><i>You change the contact person's name.</i></span></p>
                <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <button type="button" (click)="onSubmit()" style="background-color:C00000;"
                        class="btn btn-danger btn-lg px-4 gap-3">Register</button>
                </div>
            <div class="mb-3 py-3 my-3">
                <!-- <label class="form-label"> Hospital Name</label> -->
                <input type="text" formControlName="name" readonly [(ngModel)]='name' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Hospital Name is required</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">First Name</label> -->
                <input type="text" formControlName="firstName" [(ngModel)]='firstName' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">Last Name</label> -->
                <input type="text" formControlName="lastName" [(ngModel)]='lastName' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">Address Line 1</label> -->
                <input type="text" formControlName="addLine1" readonly [(ngModel)]='addLine1' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.addLine1.errors }" />
                <div *ngIf="submitted && f.addLine1.errors" class="invalid-feedback">
                    <div *ngIf="f.addLine1.errors.required">Address is required</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">City</label> -->
                <input type="text" formControlName="city" readonly [(ngModel)]='city' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                    <div *ngIf="f.city.errors.required">City is required</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">State</label> -->
                <input type="text" formControlName="state" readonly [(ngModel)]='state' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.state.errors }" />
                <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                    <div *ngIf="f.state.errors.required">State is required</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">Country</label> -->
                <input type="text" formControlName="country" readonly [(ngModel)]='country' [(ngModel)]="country"
                    class="form-control" readonly [ngClass]="{ 'is-invalid': submitted && f.country.errors }" />
                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                    <div *ngIf="f.country.errors.required">Country is required</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">Zip Code</label> -->
                <input type="text" formControlName="zipcode" readonly [(ngModel)]='zipcode' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }" />
                <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                    <div *ngIf="f.zipcode.errors.required">Zip Code is required</div>
                    <div *ngIf="f.zipcode.errors.minLength">Postal Code must be at least 6 characters</div>

                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">Email</label> -->
                <input type="text" formControlName="email" readonly [(ngModel)]='email' class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
            </div>
            <div class="mb-3">
                <!-- <label class="form-label">Mobile Number</label> -->
                <input type="text" formControlName="mobileNumber" readonly [(ngModel)]='mobileNumber'
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }" />
                <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.mobileNumber.errors.required">Mobile number is required</div>
                    <div *ngIf="f.mobileNumber.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                </div>
            </div>
            <!-- <div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Register
                </button>
                <button class="btn btn-link" (click)="clear()">Cancel</button>
            </div> -->
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">

                <button (click)="onSubmit()" type="button" style="background-color:C00000;"
                    class="btn btn-danger btn-lg px-4 gap-3">Register</button>

            </div>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center danger" *ngIf="message != undefined">
                {{message}}
            </div>
        </div>
        </form>
    </div>

</div>


