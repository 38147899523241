<div class="container py-1">
    <div mat-dialog-title>
        <div class="row">
          <div class="col-8 d-flex justify-content-start">
            <h1>View File</h1>
          </div>
          <div class="col-4 d-flex justify-content-end " mat-dialog-close>
            <!-- <h4><i class="px-3" style="color:lightgray">* marked fields are mandatory </i></h4> -->
            <mat-icon color="accent">cancel</mat-icon>
          </div>
        </div>
      </div>
    <div class="row m-2 bg-light-subtle border border-light-subtle rounded-3" *ngIf="uploadedData.length>0 && uploadedData!= undefined">
      
    <mat-table #table [dataSource]="dataSource">

        <ng-conatiner matColumnDef="docType">
            <mat-header-cell *matHeaderCellDef>File Type</mat-header-cell>
            <mat-cell *matCellDef="let element" > {{element.docType }}  </mat-cell>
        </ng-conatiner>

        <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef>FileName</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.filename}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdDate">
            <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.dateCreated |date}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="view">
        <mat-header-cell *matHeaderCellDef>View</mat-header-cell>
        <mat-cell *matCellDef="let element"> <a (click)="viewFile(element)"> View </a> </mat-cell>
    </ng-container>

        
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
   
       
   
    </div>
    <div *ngIf="dataSource != undefined && dataSource.data.length <= 0" class="alert alert-primary" role="alert">
        You don't have any files to view!
      </div>
    <div class="p-3">
        <mat-dialog-actions align="end">
          <button color="accent" mat-button mat-dialog-close>Cancel</button>
    
        </mat-dialog-actions>
      </div>
</div>
    