
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Update ORG</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
<form [formGroup]="empForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id" formControlName="id" readonly >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput type="text" placeholder="abc" formControlName="firstName">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="abc" formControlName="lastName">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput type="text" placeholder="abc" formControlName="name">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>type</mat-label>
                <input matInput placeholder="abc" formControlName="type" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>subType</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="subType" readonly >
            </mat-form-field>
            <!-- <mat-form-field appearance="outline">
                <mat-label>UserType</mat-label>
                <input matInput type="text" placeholder="userType" formControlName="userType">
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Ex. test@gmail.com" formControlName="email" readonly>
            </mat-form-field>
        
            <mat-form-field appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput type="text" placeholder="address" formControlName="addLine1">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Address 1</mat-label>
                <input matInput type="text" placeholder="address2" formControlName="addLine2">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput type="text" placeholder="city" formControlName="city">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Website</mat-label>
                <input matInput type="text" placeholder="website" formControlName="website">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <input matInput type="text" placeholder="state" formControlName="state">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input matInput type="text" placeholder="country" formControlName="country">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Zip</mat-label>
                <input matInput type="text" placeholder="zip" formControlName="zipcode">
            </mat-form-field>
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Mobile</mat-label>
                <input matInput type="text" placeholder="mobilePhone" formControlName="mobilePhone">
            </mat-form-field>
        </div> -->
            <mat-form-field appearance="outline">
                <mat-label>Contact Number</mat-label>
                <input matInput type="text" placeholder="contactNos" formControlName="contactNos">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Default Role</mat-label>
                <input matInput type="text" placeholder="defaultRole" formControlName="defaultRole">
            </mat-form-field>
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label> Specialty</mat-label>
                <input matInput type="text" placeholder="specialty" formControlName="specialty">
            </mat-form-field>
        </div> -->
        

        <!-- <mat-form-field>
            <mat-label>Registration Status</mat-label>
            <mat-select [(value)]="selected" formControlName="registrationStatus">
              <mat-option>None</mat-option>
              <mat-option value="PENDING">Pending</mat-option>
              <mat-option value="REGISTERED">Registered</mat-option>
            </mat-select>
          </mat-form-field> -->

          <mat-form-field appearance="outline">
            <mat-label>{{ status }}</mat-label>
            <mat-select formControlName="registrationStatus" name="inactiveData">
              <mat-option value="PENDING"> PENDING </mat-option>
              <mat-option value="REGISTERED"> REGISTERED </mat-option>
            </mat-select>
          </mat-form-field>
          
          <!-- <p>You selected: {{selected}}</p> -->
            <!-- <mat-form-field appearance="outline">
                <mat-label>Registration Status</mat-label>
                <input matInput type="text" placeholder="registrationStatus" formControlName="registrationStatus">
            </mat-form-field> -->
        </div>
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
    </div>
</form>