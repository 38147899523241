<div class="pt-3 text-center ">
    <h1 class="display-5 fw-bold">Bima<span style="color:#C00000;">Garage</span> HobNob - eKYP</h1>

    <div class="row m-2  justify-content-md-center">
        <div class="col-5 p-3 ">
            <div class="card">
                <div class="card-header text-center bg-light">
                    Upload your policy.
                </div>
                <div class="card-body">
                    <div class="row pt-2">
                        <div class="col">
                            <div class="row justify-content-md-center">
                                <div class="col-8 text-center">
                                    <label class="btn btn-default p-0">
                                        <!-- <input type="file" (change)="selectFile($event)" /> -->
                                        <input class="form-control" type="file" accept="application/pdf" ng2FileSelect [uploader]="fileUploader"
                                            (change)="selectFile($event)" single />
    
                                    </label>
                                </div>
    
                                <div class="col-4">
                                    <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">
                                        Upload
                                    </button>
                                </div>
                            </div>
    
                            <div *ngIf="loader" class="progress my-3">
                                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                    attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                                    [ngStyle]="{ width: progress + '%' }">
                                    {{ progress }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row py-4">

        <div class="col-lg-8 mx-auto">

            <div class="row py-4">
                <div class="col">
                    <p class="lead mb-4">Your policies</p>
                    <table mat-table #outerSort="matSort" [dataSource]="dataSource" multiTemplateDataRows
                        class="" matSort>
                        <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </th>
                                    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container> -->

                        <!-- Position Column -->
                        <ng-container matColumnDef="policyNumber">
                            <th mat-header-cell *matHeaderCellDef> Policy</th>
                            <td mat-cell *matCellDef="let element"
                                [style.color]="element.policyNumber == 'Processing Failed' ? 'gray' : null">
                                {{element.policyNumber?element.policyNumber:'NA'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="insuranceProduct">
                            <th mat-header-cell *matHeaderCellDef> Insurance Product </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.insuranceProduct?element.insuranceProduct:'NA'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="policyType">
                            <th mat-header-cell *matHeaderCellDef> Policy Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.policyType?element.policyType:'NA'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="sumInsured">
                            <th mat-header-cell *matHeaderCellDef> Sum Insured </th>
                            <td mat-cell *matCellDef="let element"> {{element.sumInsured?element.sumInsured:'NA'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="startDate">
                            <th mat-header-cell *matHeaderCellDef> Start Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.startDate? (element.startDate |date)
                                :'NA'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="endDate">
                            <th mat-header-cell *matHeaderCellDef> End Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.endDate? (element.endDate | date):'NA'}}
                            </td>
                        </ng-container>




                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="example-element-detail" *ngIf="element.policyMembers?.data.length"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                    <div class="inner-table " *ngIf="expandedElement">
                                        
                                        <table #innerTables mat-table #innerSort="matSort"
                                            [dataSource]="element.policyMembers" matSort>
                                            <ng-container matColumnDef="{{innerColumn}}"
                                                *ngFor="let innerColumn of innerDisplayedColumns">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{innerColumn}}
                                                </th>
                                                <td mat-cell *matCellDef="let element"
                                                    (click)="analyze(policyId,element.memberName)">
                                                    {{element[innerColumn]}} </td>
                                                <td mat-cell *matCellDef="let element">
                                                    <div class="example-button-container">
                                                        <button mat-mini-fab color="primary"
                                                            (click)="analyze(element.policyId,element.member.memberName)"
                                                            aria-label="Example icon button with a menu icon">
                                                            <mat-icon>bar_chart</mat-icon>

                                                        </button>
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                            [class.example-element-row]="element.member?.data.length"
                            [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>




                </div>
                <!-- <div class="col">
                    <p class="lead mb-3">eKYP Details</p>
                    <div class=" " *ngIf="analyzedData != undefined">

                        <div class="row justify-content-md-center">
                            <div class="col">Age</div>
                            <div class="col">{{analyzedData.ageInYears}}</div>
                        </div>

                        <div class="row justify-content-md-center">
                            <div class="col">With PED</div>
                            <div class="col">{{analyzedData.withPED}}</div>
                        </div>
                        <div class="row justify-content-md-center">
                            <div class="col">Pre hospitalization days</div>
                            <div class="col">{{analyzedData.preHospitalizationDays}}</div>
                        </div>
                        <div class="row justify-content-md-center">
                            <div class="col">Post hospitalization days</div>
                            <div class="col">{{analyzedData.postHospitalizationDays}}</div>
                        </div>

                        <div class="row justify-content-md-center">
                            <div class="col">Start Date</div>
                            <div class="col">{{analyzedData.start_date | date}}</div>
                        </div>
                        <div class="row justify-content-md-center">
                            <div class="col">Policy Year</div>
                            <div class="col">{{analyzedData.currentPolicyYear}}</div>
                        </div>

                        <div class="row justify-content-md-center">
                            <div class="col">Your Co-Pay amount</div>
                            <div class="col">{{analyzedData.coPayPercent}}</div>
                        </div>
                        <div class="row justify-content-md-center">
                            <div class="col">Your Available Bonus</div>
                            <div class="col">{{analyzedData.bonusAvailableAmount}} %</div>
                        </div>

                        <div class="row justify-content-md-center">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <h3>Benefits</h3>
                                    </div>
                                </div>
                                <div class="row" *ngFor="let benefit of analyzedData.benefits">
                                    <div class="col">{{benefit.name }}</div>
                                    <div class="col">{{benefit.comment }}</div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
                        <div *ngIf="message" class="alert alert-success" role="alert">{{ message }}</div>
                    </div>
                </div> -->
            </div>

        </div>

    </div>

</div>