<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1>Add User</h1>
        </div>
        <div class="col-4 d-flex justify-content-end " mat-dialog-close>
            <h4><i class="px-3" style="color:lightgray">* marked fields are mandatory </i></h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div>
<div class="row p-2">
    <div class=" p-5  justify-content-start">
        <form  [formGroup]="form" (ngSubmit)="submitForm()">
            <div class=" row">
                <div class="col-6">
                    <label> Contact Number</label>
                    <input type="text" class="form-control my-2" formControlName="contactNumber" />
                </div>
                <div class="col-6">
                    <label> First Name </label>
                    <input type="text" class="form-control my-2" formControlName="firstname" />
                </div>
            </div>
            <div class=" row">
                <div class="col-6">
                    <label> Last Name</label>
                    <input type="text" class="form-control my-2" formControlName="lastname" />
                </div>
                <div class="col-6">
                    <label> Email Id</label>
                    <input type="text" class="form-control my-2" formControlName="emailId" />
                </div>
               
            </div>
          
            <div class=" row">
                <div class="col-6">
                    <label> Address</label>
                    <input type="text" class="form-control my-2" formControlName="address" />
                </div>
                <div class="col-6">
                    <label> City </label>
                    <input type="text" class="form-control my-2" formControlName="city" />
                </div>
            </div>
            <div class=" row">
                <div class="col-6">
                    <label> State</label>
                    <input type="text" class="form-control my-2" formControlName="state" />
                </div>
                <div class="col-6">
                    <label> Country </label>
                    <input type="text" class="form-control my-2" formControlName="country" />
                </div>
            </div>
            <div class=" row">
                <div class="col-6">
                    <label> Pincode</label>
                    <input type="text" class="form-control my-2" formControlName="zipcode" />
                </div>
                <div class="col-6">
                    <label> UserType</label>
                    <input type="text" class="form-control my-2" formControlName="userType" />
                </div>
               
                
            </div>
            <!-- <div class="row">
                <div class="col-6">
                    <label> Upload Policy</label>
                    <input
                class="form-control my-2"
                type="file"
                accept="application/pdf,image/png,image/jpeg,image/jpg"
                (change)="selectFile($event)"
              />
                </div>
            </div> -->

   </form>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="submitForm()">Add</button>
        </div>
    
</div>