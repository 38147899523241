import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HospitalRegistrationService } from '../services/hospitalregistration.service';
@Component({
  selector: 'app-hospitalregistration',
  templateUrl: './hospitalregistration.component.html',
  styleUrls: ['./hospitalregistration.component.scss']
})
export class HospitalregistrationComponent {
  form!: FormGroup;
  loading = false;
  submitted = false;
  //country ="India";
  customerdata: any;
  customerId: any;
  name: any;
  firstName: any;
  lastName: any;
  addLine1: any;
  city: any;
  state: any;
  country: any;
  zipcode: any;
  mobileNumber: any;
  email: any;
  registrationsuccess: boolean = false;
  registrationfail: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private HospitalRegistrationService: HospitalRegistrationService,
  ) {
  }
  ngOnInit() {

    
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      addLine1: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.minLength(6)]],
      mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
    this.route.params.subscribe((params: Params) => {
      const prodId = params['id'];
      this.customerId = prodId;
    })
    this.HospitalRegistrationService.getcustomerdetailById(this.customerId).subscribe((data) => {
      this.customerdata = data.result;
      this.name = data.name;
      this.firstName = data.name;
      this.lastName = data.name;
      this.addLine1 = data.address;
      this.city = data.cityVillageTown;
      this.state = data.state;
      this.country = "India";
      this.zipcode = data.pinCode;
      this.mobileNumber = data.contactDetail;
      this.email = data.emailId;
      //console.log('data is received');
      // var str = data.emailId;
      // var nameMatch = str.match(/^([^@]*)@/);
      // var name = nameMatch ? nameMatch[1] : null;
      // this.firstName = name;
      // this.lastName = name;
      // console.log("fsdf", name)
       var empmail = data.emailId;
      //var empmail = "soumadeep.sen@gmail.com";
      var fullName = empmail.split('@')[0].split('.');
      var firstName = fullName[0];
      var lastName = fullName[fullName.length - 1]
       this.firstName = firstName;
       this.lastName = lastName;
      // this.lastName = name;
      //console.log("fsdfn", firstName);
      //console.log("fsdfl", lastName);


      //   this.form.patchValue({                 
      //      name = data.name;
      //     firstName = data.name;
      //     lastName = data.name;
      //     addLine1 = data.name;
      //     city = data.name;
      //     state = data.name;
      //     country = data.name;
      //     zipcode = data.name;
      //     mobileNumber = data.name;
      //     email = data.name;     
      // })
    })
  }

  get f() { return this.form.controls; }

  message:any = undefined;
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var reqdata = {
      "id": this.customerId,
      "name": this.form.value.name,
      "firstName": this.form.value.firstName,
      "lastName": this.form.value.lastName,
      "addLine1": this.form.value.addLine1,
      "addLine2": "",
      "city": this.form.value.city,
      "state": this.form.value.state,
      "country": this.form.value.country,
      "zipcode": this.form.value.zipcode,
      "website": "",
      "email": this.form.value.email,
      "mobileNumber": this.form.value.mobileNumber,
      "contactNos": this.form.value.mobileNumber,
      "defaultRole": "PARTNER_ADMIN",
      "type": "HOSPITAL",
      "subType": "MULTI_SPECIALTY"
    }
    //console.log("json", reqdata)
    this.loading = true;
   
    this.HospitalRegistrationService.register(reqdata)
      .pipe(first())
      .subscribe({
        next: () => {
          //window.alert('Registration Successfull');
          this.loading = false;
          this.router.navigate(["acountactivationComponent"]);
          //this.registrationsuccess = true;
        },
        error: error => {
          console.log("error "+JSON.stringify(error))
          this.message = error.error.error;
          //window.alert(JSON.stringify(error.error.message));
          //this.registrationsuccess = true;
          this.loading = false;
          //this.router.navigate(["acountactivationComponent"])
        }
      });
  }

  


  clear() {
    this.form.value.controls = "";
    this.form.reset();
    this.form.clearValidators();
  }

}
