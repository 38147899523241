import { Component } from '@angular/core';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/authService';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent {
  priveleges: any
  empForm: FormGroup;
  selectedType = [];
  roles: any;
  message: any;
  filteredArr: any;
  user:any;

  constructor(private adminService: AdminService, private _fb: FormBuilder, private toastrService: ToastrService, private authService:AuthService,
    private _dialogRef: MatDialogRef<AddRoleComponent>) {
    this.empForm = this._fb.group({
      roleName: ['', [Validators.required, Validators.maxLength(50)]],
      roleDescription: ['', [Validators.required, Validators.maxLength(80)]],
      privileges: ['', Validators.required],
      tag: ['', Validators.required],
    });
    this.authService.user.subscribe(x => this.user = x);

  }

  ngOnInit(): void {
    this.getpriveleges();
    //this.getprivelegesdata();
  }

  getpriveleges() {
    this.adminService.getUserRoleslById(this.user.id)
      .pipe(first())
      .subscribe({
        next: (userRoles: any) => {
          this.roles = userRoles;
          console.log("Manage user role:", this.roles);

          this.adminService.getPrivileges()
            .pipe(first())
            .subscribe({
              next: (privileges: any) => {
                console.log("Manage user privileges:", privileges);
                this.priveleges = privileges;

                this.filteredArr = [];
                this.roles?.forEach((role: any) => {
                  // const matchingPrivileges = this.priveleges?.filter((privilege: any) =>
                  // //role.roleName=== privilege.group
                  //   role.rolePrivileges.some((rolePrivilege: any) =>
                  //     rolePrivilege.id.privilegeId === privilege.id
                  //   )
                  // );
                  // this.filteredArr = this.filteredArr.concat(matchingPrivileges);

                  const matchingPrivileges = this.priveleges.filter((privilege: any) =>
                   // role.roleName === privilege.group &&
                   // role.privileges.some((rolePrivilege: any) => rolePrivilege.privilegeId === privilege.id)
                    role.rolePrivileges.some((rolePrivilege: any) => rolePrivilege.id.privilegeId === privilege.id)

                  );
                  
                  this.filteredArr = Array.from(matchingPrivileges);
                });

                console.log("Filtered privileges:", this.filteredArr);
              },
              error: (error) => {
                console.log(error);
              },
            });
        },
        error: (error) => {
          console.log("Error: " + JSON.stringify(error));
          // this.message = error;
        },
      });
  }

  // getpriveleges() {
  //   this.adminService.getUserRoleslById(this.user.id)
  //     .pipe(first())
  //     .subscribe({
  //       next: (userRoles: any) => {
  //         this.roles = userRoles;
  //         console.log("Manage user role:", this.roles);    
  //       },
  //       error: (error) => {
  //         console.log("Error: " + JSON.stringify(error));
  //         // this.message = error;
  //       },
  //     });
  // }
  getprivelegesdata() {
    this.adminService.getPrivileges()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(" get priveleges", data)
          this.priveleges = data;
        },
        error: error => {
          //this.error = error.error;
          //this.message = undefined;
          console.log(error);
          //this.loading = false;
        }
      });
  }


  onFormSubmit() {
   // debugger;
    if (this.empForm.valid) {

      var data = {
        "roleName": this.empForm.value.roleName,
        "roleDescription": this.empForm.value.roleDescription,
        "privileges": this.selectedType,
        "tags":this.empForm.value.tag
      }
      console.log("ffsf", data);
      this.adminService.addRoles(data).subscribe({
        next: (val: any) => {
          this.toastrService.success("Role has been updated successfully");
          this._dialogRef.close(true);
        },
        error: (err: any) => {
          // this.toastrService.error("Error");
          this.toastrService.error(JSON.stringify(err.error.error));
          console.error(err);
        },
      });

    }
  }
}
