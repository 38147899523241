<h1 mat-dialog-title>Select an Option</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            class="select-circle"
            (click)="onSelect(element)"
          >
            <mat-icon>radio_button_unchecked</mat-icon>
          </button>
        </td>
      </ng-container>

    <ng-container matColumnDef="memberName">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{ element.memberName }} </td>
    </ng-container>

    <ng-container matColumnDef="dob">
        <th mat-header-cell *matHeaderCellDef> Date of birth </th>
        <td mat-cell *matCellDef="let element"> {{ element.memberDOB | date }} </td>
      </ng-container>

      <ng-container matColumnDef="isHolder">
        <th mat-header-cell *matHeaderCellDef> Holder </th>
        <td mat-cell *matCellDef="let element"> {{ element.holder }} </td>
      </ng-container>


    <!-- <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          color="primary"
          class="select-circle"
          (click)="onSelect(element)"
        >
          <mat-icon>radio_button_unchecked</mat-icon>
        </button>
      </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
