import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) { }
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  requestOptions = { headers: this.headers };

  searchIC(searchTerm:any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/search/insurance/`+searchTerm,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }
  searchHospital(searchTerm:any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/search/hospital/`+searchTerm,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  searchUser(searchTerm:any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/search/admin/user/`+searchTerm,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  searchClaimEmails(searchTerm:any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/search/claimEmails/`+searchTerm,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  errorHandle(error: any) {
    let errorMessage = "";
    if (error.error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.error;
    } else {
      // Get server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
