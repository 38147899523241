import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../services/authService';
import { first } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { AddAssignTaskComponent } from '../modal/add-assign-task/add-assign-task.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { ViewTaskComponent } from './view-task/view-task.component';
import { DashboardParam } from '../model/dashboardParam';
import { DataServiceService } from '../services/data-service.service';
import { CashflowService } from '../services/cashflow.service';
import { WorkflowService } from '../services/workflow.service';
import { DataShareModel } from '../model/dataShareModel';

@Component({
  selector: 'app-policy-consulting',
  templateUrl: './policy-consulting.component.html',
  styleUrls: ['./policy-consulting.component.scss']
})
export class PolicyConsultingComponent implements OnInit , AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  tasks: any = [];
  //displayedColumns: string[] = ['taskId', 'taskName', 'taskDescription', 'createdBy', 'createdDate', 'dueDate','status'];
  displayColumns: any = ['taskName', 'orderDate', 'orderId', 'customerName', 'mobileNumber', 'assignedTo', 'action'];
  dataSource = new MatTableDataSource<any>(this.tasks);
  dashboardParams:DashboardParam = {"processType":"policyConsulting","orgType":"BG"};
  selectedTask: any;
  user: any;
  public fields:any = [];
  public dynamicFormGroup!: FormGroup;
  isTaskSelected:boolean = false;
  loading: boolean = false;
  message:any;
  error:any;
  diagram:any;
  allTasks:any;

  constructor(
    private dataService: DataServiceService,
    private service: CustomerService,
    private authService: AuthService,
    private customerService: CustomerService,
    private _dialog: MatDialog,
    private toastrService:ToastrService,
    private cashlessService: CashflowService,
    private workflowService:WorkflowService
  ) { 
    
  }


  ngAfterViewInit(): void {
    this.getAllTasks();
    
  }
  ngOnInit(): void {
    this.user = this.authService.userValue;
    
  }

  getAllTasks() {
    this.workflowService
      .getAllTask("policyConsulting")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allTasks = data;
          console.log(this.allTasks);
         const dataShare = new DataShareModel("policyConsulting",data);
          this.dataService.updateAllTasks(dataShare);
          
         // this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          //this.loading = false;
        },
      });
  }


 

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  getDashboardParam(){
    return JSON.stringify(this.dashboardParams);
  }


}
