<div class="container p-3">
  <div class="container py-3 px-2">
    <div class="row justify-content-md-center">
      <div class="col-lg-4 ms-3">
        <mat-form-field *ngIf="hospitalList != undefined" appearance="fill" class="w-100">
          <mat-select placeholder="Select a hospital" [(ngModel)]="selectedHospital">
            <mat-option *ngFor="let hospital of hospitalList" [value]="hospital">
              {{ hospital.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4">
        <mat-form-field>
          <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-lg-2 mt-2">
        <div class="d-flex flex-row">
          <button mat-flat-button style="color:white;background:#8e0101" (click)="searchCases()">
            Search
          </button>
          <button mat-flat-button style="color:white;background:#8e0101" (click)="clear()">
            Clear
          </button>
        </div>

      </div>
    </div>
    <div class="border rounded-1 p-2">
      <div class="row p-2">
        <div class="col text-start">
          <span style="color:grey">Task Filters</span>
        </div>
        <div class="col text-end">
         
          <button type="button" style="color:white;background:#8e0101;" class="btn  btn-sm"
            (click)="filterPriority('ALL')">
            Reset Filter
          </button> 
        </div>
      </div>
      <hr>
      <div class="row p-2">
        <div class="col-2 p-1" *ngFor="let filterButton of filterData">
          <button (click)="filterPriority(filterButton.taskId)" type="button"
            [class]="filterButton.count > 0?'btn btn-warning btn-sm position-relative':'btn btn-secondary btn-sm position-relative'">{{filterButton.taskName}}
            <span
              [class]="filterButton.count > 0?'position-absolute top-0 start-100 translate-middle badge text-bg-white rounded-pill bg-danger':'position-absolute top-0 start-100 translate-middle badge text-bg-white rounded-pill bg-secondary'">
              {{filterButton.count}}
              <span class="visually-hidden">Pending</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center">
      <div class="p-4">
        <mat-table class="" #table [dataSource]="dataSource">
          <ng-container matColumnDef="caseID">
            <mat-header-cell *matHeaderCellDef> Case ID </mat-header-cell>
            <mat-cell *matCellDef="let element" (click)="viewCaseDetails(element)"
              [class]="element == selectedTask ? 'selected' : ''">
              {{ element.process_inst_id }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="patientName">
            <mat-header-cell *matHeaderCellDef> Patient Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.process_data.firstname }}
              {{ element.process_data.lastname }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="hospitalName">
            <mat-header-cell *matHeaderCellDef> Hospital Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.process_data.hospitalName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="startDate">
            <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element._id.date | date }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef> End Date </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.tx_date | date }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="currentProcessState">
            <mat-header-cell *matHeaderCellDef> Current Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.process_data.currentProcessStatus | capitalizeAndSpace}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button style="color:white;background:#8e0101" class="btn" mat-flat-button
                  (click)="viewCaseDetails(element)">View</button>
                <button style="color:white;background:#8e0101" class="btn" mat-flat-button
                  [disabled]="(element?.process_data['preauth-ExecutionStatus']) != 'EXECUTED'"
                  (click)="viewPreAuth(element)">Pre Auth</button>
              </div>
              <!-- [disabled]="(element?.process_data['preauth-ExecutionStatus']) != 'EXECUTED'" -->
            </mat-cell>


          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

</div>