import { Component, ViewChild } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { first } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { AddRoleComponent } from "./add-role/add-role.component";
import { ToastrService } from "ngx-toastr";
import { PrivilegesComponent } from "../manage-user/privileges/privileges.component";
import { RoleWithPrivilagesComponent } from "./role-with-privilages/role-with-privilages.component";
import { UpdateRoleComponent } from "./update-role/update-role.component";
import Swal from "sweetalert2";
import { MatPaginatedTabHeader } from "@angular/material/tabs";
@Component({
  selector: "app-manage-role",
  templateUrl: "./manage-role.component.html",
  styleUrls: ["./manage-role.component.scss"],
})
export class ManageRoleComponent {
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];
  message: any;

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  dataSource = new MatTableDataSource<any>(this.roles);
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  displayedColumns: string[] = [
    "Id",
    "OrgId",
    "RoleDescription",
    "RoleName",
    "tags",
    "update",
    "action",
    "checkPrivileges",
  ];
  loading: boolean = false;
  constructor(
    private adminService: AdminService,
    private _dialog: MatDialog,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getroles();
    this.dataSource.paginator = this.paginator;
  }

  getroles() {
    this.adminService
      .getRoles(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          //console.log("roles",data);
          this.roles = data;
          this.dataSource.data = this.roles;
          this.dataSource.paginator = this.paginator;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //sthis.loading = false;
        },
      });
  }
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getroles();
  }

  handleInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query !== "") {
      this.dataSource.data = this.roles.filter((item: any) => {
        return Object.values(item).some((val) =>
          String(val).toLowerCase().includes(query)
        );
      });
    } else {
      this.getroles();
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }
  async deleteRoles(data: any) {
    Swal.fire({
      title: "Are you sure want to delete this role?",
      //text: 'You will not be able to recover this imaginary file!',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminService
          .deleteRoles(data.roleName)
          .pipe(first())
          .subscribe({
            next: (data: any) => {
              this.toastrService.success("Role has been deleted successfully");

              //this.loading = false;
              this.getroles();
              //console.log("roles",data);
              // this.roles = data;
            },
            error: (error) => {
              console.log("error " + JSON.stringify(error));
              //this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(error.error.error));
              this.message = error;

              //sthis.loading = false;
            },
          });
        console.log("Clicked Yes, File deleted!");
      } else if (result.isDismissed) {
        console.log("Clicked No, File is safe!");
      }
    });
  }

  openCheckPrivileges(data: any) {
    console.log(data);
    const dialogRef = this._dialog.open(RoleWithPrivilagesComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          //this.getUsers();
        }
      },
    });
  }

  updateRole(data: any) {
    const dialogRef = this._dialog.open(UpdateRoleComponent, {
      disableClose:true,
      data,
      // width: "90%",
      // height: "70%",
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getroles();
        }
      },
    });
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddRoleComponent, {
      disableClose:true,
      width: "90%",
      height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          //this.getUsers();
          this.getroles();
        }
      },
    });
  }
}
