<!-- <div mat-dialog-title>
    <h1> Add Insurance Company</h1>
</div> -->

<div mat-dialog-title>
    <div class="heading d-flex align-items-center">
        <div class="row w-100">
          <!-- Left Section -->
          <div class="col-8 d-flex align-items-center">
            <h1 class="m-0">Add Payor</h1>
          </div>
          <!-- Right Section -->
          <div class="col-4 d-flex align-items-center justify-content-end" mat-dialog-close>
            <h4 class="m-0">
              <i class="px-3" style="color: lightgray">* marked fields are mandatory</i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
          </div>
        </div>
      </div>
      <br>
<form [formGroup]="insuranceForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id"  >
            </mat-form-field>
        </div> -->
        <div class="row">
            <!-- <mat-form-field appearance="outline">
                <mat-label>Product id</mat-label>
                <input matInput type="text" placeholder="Ex. Technical" formControlName="productId">
            </mat-form-field> -->
             
            <mat-form-field appearance="outline">
                <mat-label>Registered Name</mat-label>
                <input matInput placeholder="Registered Name" formControlName="nameReg">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Payor Name</mat-label>
                <input matInput type="text" placeholder="Payor Name" formControlName="nameShort" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Payor Type</mat-label>
                <mat-select formControlName="payorType" placeholder="Insurance or TPA">
                    <mat-option value="Insurance">Insurance</mat-option>
                    <mat-option value="TPA">TPA</mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>Website</mat-label>
                <input matInput type="text" placeholder="Website" formControlName="website" >
            </mat-form-field>

            <!-- <mat-form-field appearance="outline">
                <mat-label>sector</mat-label>
                <input matInput type="email" placeholder="sector" formControlName="sector" >
            </mat-form-field>
        
            <mat-form-field appearance="outline">
                <mat-label>insuranceType</mat-label>
                <input matInput type="text" placeholder="insuranceType" formControlName="insuranceType" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>insuranceCoverage</mat-label>
                <input matInput type="text" placeholder="insuranceCoverage" formControlName="insuranceCoverage" >
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>contactNumber</mat-label>
                <input matInput type="text" placeholder="contactNumber" formControlName="contactNumber" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>claimSupportNumber</mat-label>
                <input matInput type="text" placeholder="claimSupportNumber" formControlName="claimSupportNumber" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>claimSupportEmail</mat-label>
                <input matInput type="text" placeholder="claimSupportEmail" formControlName="claimSupportEmail" >
            </mat-form-field>
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>In Active</mat-label>
                <input matInput type="text" placeholder="Status" formControlName="inactive" >
            </mat-form-field>
        </div> -->

           
        

            <mat-form-field appearance="outline">
                <mat-label>claimSupportAddr</mat-label>
                <input matInput type="text" placeholder="claimSupportAddr" formControlName="claimSupportAddr" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Payor Type</mat-label>
                <mat-select formControlName="payorType" placeholder="Insurance or TPA">
                    <mat-option value="Insurance">Insurance</mat-option>
                    <mat-option value="TPA">TPA</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput type="text" placeholder="Address" formControlName="address" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <input matInput type="text" placeholder="Address" formControlName="state" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput type="text" placeholder="Address" formControlName="city" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Pincode</mat-label>
                <input matInput type="text" placeholder="Address" formControlName="pincode" >
            </mat-form-field>
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Partner type id</mat-label>
                <input matInput type="text" placeholder="mobilePhone" formControlName="partnerTypeId" >
            </mat-form-field>
        </div> -->
        </div> 
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary"[disabled]="!insuranceForm.valid" type="submit">Save</button>
    </div>
</form>