import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdminService } from "src/app/services/admin.service";
import { ToastrService } from "ngx-toastr";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { first } from "rxjs";


@Component({
  selector: "app-update-mail-parser-confs",
  //standalone: true,
  //imports: [],
  templateUrl: "./update-mail-parser-confs.component.html",
  styleUrl: "./update-mail-parser-confs.component.scss",
})
export class UpdateMailParserConfsComponent {
  insuranceForm: FormGroup;
  orgId: any;
  user: any;
  status: any;
  automated: any;
  inhouse: any;
  emailConfig: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  selectedType: any;

  displayedColumns: string[] = [
    "id",
    "nameReg",
    "nameShort",
    "claimSupportAddr",
    "claimSupportEmail",
    "claimSupportNumber",
    "action",
    "delete",
  ];

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private HospitalRegistrationService: HospitalRegistrationService,
    private _dialogRef: MatDialogRef<UpdateMailParserConfsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) // @Inject(MAT_DIALOG_DATA) public orgId: any,

  //private _coreService: CoreService
  {
    this.insuranceForm = this._fb.group({
      hospitalOrgId: [""],
      payorType: [""],
      receivingEmailHost: [""],
      receivingEmailId: [""],
      receivingEmailStoreType: [""]
    });
  }
  ngOnInit(): void {
    console.log("dsf", this.data.config);
    console.log("dsf", this.data.insurancedata);

    //this.getEmailCong(this.data.config.hospitalOrgId);//TODO
    if(this.data.type == "receiver"){
      this.insuranceForm.patchValue({
        hospitalOrgId: this.data.config.hospitalOrgId,
        payorType: this.data.config.payorType,
        receivingEmailHost: this.data.config.receivingEmailHost,
        receivingEmailId: this.data.config.receivingEmailId,
        receivingEmailStoreType: this.data.config.receivingEmailStoreType,
       

      });
    }else{
      this.insuranceForm.patchValue({
        hospitalOrgId: this.data.config.hospitalOrgId,
        payorType: this.data.config.payorType,
        receivingEmailHost: this.data.config.senderEmailHost,
        receivingEmailId: this.data.config.senderEmailId,
        receivingEmailStoreType: this.data.config.senderEmailStoreType
      });
    }
   
  }

  getEmailCong(hospitalOrgId:any) {
    this.HospitalRegistrationService
      .getEmailConfig(hospitalOrgId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          //console.log(data);
          this.emailConfig = data;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          // this.message = error;

          //this.loading = false;
        },
      });
  }

  // onFormSubmit() {
  //   if (this.insuranceForm.valid) {
  //     console.log("dfg", this.insuranceForm.value);
  //     var data = {
  //       id: this.data.insurancedata.id,
  //       nameReg: this.data.insurancedata.nameReg,
  //       nameShort: this.data.insurancedata.nameShort,
  //       sector: this.data.insurancedata.sector,
  //       insuranceType: this.data.insurancedata.insuranceType,
  //       insuranceCoverage: this.data.insurancedata.insuranceCoverage,
  //       contactNumber: this.data.insurancedata.contactNumber,
  //       website: this.data.insurancedata.website,
  //       claim: this.data.insurancedata.claim,
  //       claimSupportNumber: this.data.insurancedata.claimSupportNumber,
  //       claimSupportEmail: this.data.insurancedata.claimSupportEmail,
  //       claimSupportAddr: this.data.insurancedata.claimSupportAddr,
  //      referenceId: 0,
  //       logo: this.data.insurancedata.logo,
  //       mailParserConfs: [
  //         {
  //           id: this.data.data.id,
  //           category: this.insuranceForm.value.category,
  //           emailId: this.insuranceForm.value.emailId,
  //           matchRegex: this.insuranceForm.value.matchRegex,
  //           matchSet: this.insuranceForm.value.matchSet,
  //           matcherField: this.insuranceForm.value.matcherField,
  //           seqNo: this.insuranceForm.value.seqNo,
  //           subjectFilter: this.insuranceForm.value.subjectFilter,
  //           inactive: this.insuranceForm.value.inactive,
  //           createdBy: "",
  //           dateCreated: "",
  //           updatedBy: "",
  //           dateUpdated: "",
  //         },        
  //         //this.emailConfig = this.data.insurancedata.mailParserConfs
  //       ],
  //       insuranceProducts: [{}],
  //       automated: this.data.insurancedata.automated,
  //       inhouse:  this.data.insurancedata.inhouse,
  //       inactive: this.data.insurancedata.inactive,
  //       dateCreated: "",
  //       createdBy: "",
  //       dateUpdated: "",
  //       updatedBy: "",
  //     };
  //     if (this.data) {
  //       this.adminService
  //         .updateInsuranceCompany(data, this.data.insurancedata.id)
  //         .subscribe({
  //           next: (val: any) => {
  //             this.toastrService.success(
  //               "Insurance company has been updated successfully"
  //             );

  //             this._dialogRef.close(true);
  //           },
  //           error: (err: any) => {
  //             console.error(err);
  //             //this.toastrService.error("Error");
  //             this.toastrService.error(JSON.stringify(err.error));
  //           },
  //         });
  //     }
  //   }
  // }



onFormSubmit() {
  if (this.insuranceForm.valid && this.data.type =="receiver") {
 var data ={
  
    "id": this.data.config.id,
    "hospitalOrgId": this.insuranceForm.value.hospitalOrgId,
    "receivingEmailId": this.insuranceForm.value.receivingEmailId,
    "receivingEmailPassword": this.data.config.receivingEmailPassword,
    "receivingEmailHost": this.insuranceForm.value.receivingEmailHost,
    "receivingEmailPort": this.data.config.receivingEmailPort,
    "receivingEmailStoreType": this.insuranceForm.value.receivingEmailStoreType,
    "inactive": false,
    "insuranceTpaId": this.data.config.insuranceTpaId,
    "payorType": this.insuranceForm.value.payorType
  
 }
    if (this.data) {
      this.HospitalRegistrationService
        // .updateMasterEmailConfig(this.insuranceForm.value)
        .updateReceiverEmailConfig(data)

        .subscribe({
          next: (val: any) => {
            this.toastrService.success(
              "Email config  has been updated successfully"
            );

            this._dialogRef.close(true);
          },
          error: (err: any) => {
            console.error(err);
            //this.toastrService.error("Error");
            this.toastrService.error(JSON.stringify(err.error));
          },
        });
    }
  }else{
    var senderData ={
  
      "id": this.data.config.id,
      "hospitalOrgId": this.insuranceForm.value.hospitalOrgId,
      "senderEmailId": this.insuranceForm.value.receivingEmailId,
      "senderEmailPassword": this.data.config.senderEmailPassword,
      "senderEmailHost": this.insuranceForm.value.receivingEmailHost,
      "senderEmailPort": this.data.config.senderEmailPort,
      "senderEmailStoreType": this.insuranceForm.value.receivingEmailStoreType,
      "inactive": false,
      "insuranceTpaId": this.data.config.insuranceTpaId,
      "payorType": this.insuranceForm.value.payorType
    
   }
      if (this.data) {
        this.HospitalRegistrationService
          // .updateMasterEmailConfig(this.insuranceForm.value)
          .updateSenderEmailConfig(senderData)
  
          .subscribe({
            next: (val: any) => {
              this.toastrService.success(
                "Email config  has been updated successfully"
              );
  
              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              //this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));
            },
          });
      }
  }
}
}