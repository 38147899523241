import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-add-insurance-company',
  templateUrl: './add-insurance-company.component.html',
  styleUrls: ['./add-insurance-company.component.scss']
})
export class AddInsuranceCompanyComponent {

  insuranceForm: FormGroup;
  //orgId:any;
  userorg: any
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  orgs: any;
  partnerTypes: any;
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;


  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<AddInsuranceCompanyComponent>,

  ) {
    this.insuranceForm = this._fb.group({
     // id: '',
     nameReg: ['', Validators.required],
     nameShort: ['', [Validators.required]],
     sector: ['', [Validators.required]],
     insuranceType: ['', [Validators.required]],
     insuranceCoverage: ['', Validators.required],
     contactNumber: ['', Validators.required],
     claimSupportNumber: ['', [Validators.required]],
      // orgId:'',
      claimSupportEmail: ['', Validators.required],
      claimSupportAddr: ['', [Validators.required]],
      // orgId:'',
    });
  }

  ngOnInit(): void {
  }

  products: any;


  onFormSubmit() {
    // {
    //   "id": "string",
    //   "productId": "string",
    //   "discountCode": "string",
    //   "discountPercent": 0,
    //   "discountNote": "string",
    //   "startDate": "2023-11-01T10:04:54.794Z",
    //   "endDate": "2023-11-01T10:04:54.795Z",
    //   "maxCount": 0,
    //   "partnerTypeId": 0,
    //   "orgId": "string",
    //   "dateCreated": "2023-11-01T10:04:54.795Z",
    //   "createdBy": "string",
    //   "inactive": true,
    //   "currentCount": 0
    // }
    // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
    //console.log(this.datePipe.transform(this.couponForm.value.startDate,"yyyy-MM-dd")); //output : 2018-02-13
    // var data =
    // {
    //   "id": "string",
    //   "name": "string",
    //   "description": "string",
    //   "price": 0,
    //   "shortName": "string",
    //   "inactive": true,
    //   "tcRequired": true,
    //   "gst": 0,
    //   "processWorkflow": "string"
    // }
  var data =  {
      "id": "",
      "nameReg": this.insuranceForm.value.nameReg,
      "nameShort": this.insuranceForm.value.nameShort,
      "sector": this.insuranceForm.value.sector,
      "insuranceType": this.insuranceForm.value.insuranceType,
      "insuranceCoverage": this.insuranceForm.value.insuranceCoverage,
      "contactNumber": this.insuranceForm.value.contactNumber,
      "website": "",
      "claim": "",
      "claimSupportNumber": this.insuranceForm.value.claimSupportNumber,
      "claimSupportEmail": this.insuranceForm.value.claimSupportEmail,
      "claimSupportAddr": this.insuranceForm.value.claimSupportAddr,
      "referenceId": 0,
      "insuranceProducts": [
        {}
      ],
      "automated": true,
      "inhouse": true,
      "inactive": true,
      "dateCreated": "",
      "createdBy": "",
      "dateUpdated": "",
      "updatedBy": ""
    }
    this.adminService.addInsuranceCompany(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("Insurance company has been added successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        this.toastrService.error(JSON.stringify(err.error));

      },
    });


  }
}
