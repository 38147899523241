import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { ViewFileComponent } from 'src/app/portal/bg/inbox/view-file/view-file.component';
import { CashflowService } from 'src/app/services/cashflow.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-current-status',
  templateUrl: './current-status.component.html',
  styleUrl: './current-status.component.scss'
})
export class CurrentStatusComponent {
  caseDetails:any[]=[];
  generalDetailsForm !:FormGroup;
  dataSource = new MatTableDataSource<any>(this.caseDetails);
  submitted: boolean = false;
  toastrService: any;
  message: any;
  uploadedData:any[]=[];
  insuranceMedia:any;
  insuranceCompanyList:any;
  insurance:any;
  length = 100;
  pageIndex = 0;
  pageSize = 10;
  processId:any;
  uploadedFiles:any[] =[];
  dataSourceforMedia = new MatTableDataSource<any>(this.uploadedFiles);
  //displayedColumns: string[] = ["docType", "fileName", "createdDate", "view"];
  displayedColumns: string[] = ["docType"];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cashlessService: CashflowService,
    private fileService:FileService,
    private formBuilder: FormBuilder,   
   private dialog:MatDialog,
  ) { }

  
  ngOnInit(): void {
    this.processId = this.data.processInstanceId ? this.data.processInstanceId : this.data.process_inst_id;
    console.log("Test ID:", this.processId);
    this.getCaseDetails(); 
    this.getViewFileList();

    this.generalDetailsForm = this.formBuilder.group({
      admissionDate : new FormControl(''),
      admissionType : new FormControl(''),
      dischargeDate : new FormControl(''),
      typeOfTreatment : new FormControl(''),
      claimNumber : new FormControl(''),
      treatingDoctor : new FormControl(''),
      roomType: new FormControl(''),
      natureOfIllness : new FormControl(''),
      fileNumber : new FormControl(''),
      depositAmount : new FormControl(''),
      courierReceipt : new FormControl(''),
      courierTrackingId : new FormControl(''),
    })


  }

 
  
  getCaseDetails(){
    console.log("data "+JSON.stringify(this.data));
   
    this.cashlessService.getCaseDetails(this.processId)
    .pipe(first())
    .subscribe({
      next:(data:any)=>{
        console.log("data:"+JSON.stringify(data));
        this.caseDetails = data.reverse();
        this.dataSource.data = this.caseDetails;
       // this.combineData(data);
        
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        this.submitted = false;
        this.toastrService.error( "Please fill in the required fields ");
        this.message = error.error.error;
        //window.alert(JSON.stringify(error.error.message));
        //this.registrationsuccess = true;
       // this.loading = false;
        //this.router.navigate(["acountactivationComponent"])
      }
    });
     

  }
   combinedData :any= {};
  combineData(details:any){
    details.forEach((item:any) => {
      item.task_data.forEach((field:any) => {
        if (!this.combinedData[field.id]) {
          this.combinedData[field.id] = {
            name: field.name,
            type: field.type,
            values: []
          };
        }
    
        if (field.value) {
          this.combinedData[field.id].values.push(field.value);
        }
      });
    });
   
    console.log(this.combinedData);
    console.log( this.combinedData.sumInsured.values[0]);
  }

  getValueByName( task_data:any,targetName:any) {
    for (const key in task_data) {
      if (task_data[key].id === targetName) {
        //console.log(task_data[key].value)
        return task_data[key].value; // Assuming you want the first value
      }
    }
    return null; // Return null if the targetName is not found
  }

  removeDoubleQuotes(text: string): string {
    return text.replace(/"/g, '');
  }


  formatString(str: string): string {
    const data =  str.replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return data.replace(/"/g, '');
  }

  getViewFileList() {
    this.fileService.getExternalMediaList(this.processId, 'CASE', '').subscribe({
      next: (data: any) => {

        this.uploadedData = data;
        this.dataSourceforMedia.data = this.uploadedData;
          

      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        this.message = error;
        
      },
    });
  }

  // getInsuranceByHospitalId(hospitalId: any) {
  //   this.cashlessService.getInsuranceByHospitalId(hospitalId, this.pageIndex, this.pageSize)
  //     .pipe(first())
  //     .subscribe({
  //       next: (data: any) => {
  //          console.log(data);
  //         this.insuranceCompanyList = data;
  //         const insuranceId = this.data.task.caseVariables['select-primary-insurance-company-name-modal'];
  //         this.insurance = this.insuranceCompanyList.filter((insurance:any)=>insurance.insuranceId === insuranceId);
  //         console.log(this.insurance);
  //         this.insuranceMedia = this.insurance[0].media;
  //         console.log(this.insuranceMedia);
  //         this.uploadedData.push( this.insuranceMedia);
          

  //       }
  //     })
  // }
  viewFile(element:any){
    const dialogRef = this.dialog.open(ViewFileComponent,{
      disableClose:true,
      data:{fileMetaData:element, type: element.docType},
      height:'90%',
      width:'90%'

    });

    dialogRef.afterClosed().subscribe({
      next: (val)=> {
        if (val){}
      },
    })
  }
  
}
