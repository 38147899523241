<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1>Add Email LookUp</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
            <h4>
                <i class="px-3" style="color: lightgray">* marked fields are mandatory
                </i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div>

<div class="container py-3 px-2">
   <form [formGroup]="detailForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Field Name</mat-label>
                <input matInput type="text" placeholder="FieldName" formControlName="fieldName">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Field Type</mat-label>
                <!-- <input matInput type="text" placeholder="taskDefKey" formControlName="taskDefKey"> -->
                <mat-select placeholder="Field Type" formControlName="fieldType">
                    <mat-option value="java.lang.String">String</mat-option> 
                    <mat-option value="java.lang.Double">Double</mat-option> 
                    <mat-option value="java.lang.Integer">Integer</mat-option> 
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Field Pattern</mat-label>
                <input matInput type="text" placeholder="FieldPattern" formControlName="fieldPattern">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Info Type</mat-label>
                <input matInput type="text" placeholder="InfoType" formControlName="infoType">
            </mat-form-field>
            
            <mat-form-field appearance="outline">
                <mat-label>Data LookUp Strategy</mat-label>
                <input matInput type="text" placeholder="DataLookUpStrategy" formControlName="dataLookupStrategy">
                
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Field Value</mat-label>
                <input matInput type="text" placeholder="FieldValue" formControlName="fieldValue">
                
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Field Match Condition</mat-label>
               
             <!-- <input matInput type="text" placeholder="FieldMatchCondition" formControlName="fieldMatchCondition"> -->
            <mat-select placeholder="FieldMatchCondition" formControlName="fieldMatchCondition">
                <mat-option value="BOOLEAN">BOOLEAN</mat-option>
                <mat-option value="EXTRACTED">EXTRACTED</mat-option>
            </mat-select>    
            </mat-form-field>
        </div>
    </div>
   
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">
            Cancel
        </button>
        <button mat-raised-button color="primary" type="submit" [disabled]="this.detailForm.invalid">
            Save
        </button>
    </div>
   </form>
</div>
