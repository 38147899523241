<div class="pt-1 text-center ">

    <img width="10%" class="d-block mx-auto mb-4" src="../../assets/img/enterprise.png" alt="">

    <h1 class="display-5 fw-bold">Bima<span style="color:#C00000;">Garage</span> Enterprise portal</h1>

    <div class="col-lg-6 mx-auto">

        <p class="lead mb-4">Bima Garage is a Claim Processing Company. We act as a bridge between Hospitals and Insurance companies, through our technology and processing expertise. We are a one stop shop for all insurance claim processing needs, like improved TAT, medical queries, timely approvals, improved cash flow and many more rich features, enabled through our Cloud based portal.</p>

    </div>

</div>

