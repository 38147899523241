<div class="container p-3 card">

    <div class="">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="taskName">
                <th mat-header-cell *matHeaderCellDef> Tasks</th>
                <td mat-cell *matCellDef="let element">
                    {{element.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef> Due Date</th>
                <td mat-cell *matCellDef="let element">{{element.dueDate | date : "short" }}</td>
            </ng-container>

            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef> Order Id</th>
                <td mat-cell *matCellDef="let element">{{element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef> Customer Name</th>
                <td mat-cell *matCellDef="let element">{{element.caseVariables?.customer?.firstName + "
                    "+ element.caseVariables?.customer?.lastName }}</td>
            </ng-container>


            <ng-container matColumnDef="mobileNumber">
                <th mat-header-cell *matHeaderCellDef> Mobile Number</th>
                <td mat-cell *matCellDef="let element">{{element.caseVariables?.customer?.mobilePhone }}
                </td>
            </ng-container>


            <ng-container matColumnDef="assignedTo">
                <th mat-header-cell *matHeaderCellDef> Assigned To</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.assignee!= undefined">
                        {{element.caseVariables?.assignedUser?.firstName + ' ' +
                        element.caseVariables?.assignedUser?.lastName}}
                        <!-- {{user.firstName}} -->
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action</th>
                <td mat-cell *matCellDef="let element">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn" style="color:white;background:#8e0101;" (click)="acceptTask(element)"
                        [disabled]="element.assignee != undefined" >Accept</button>

                        <button type="button" class="btn" style="color:white;background:#8e0101;" (click)="assignTask(element)"
                            [disabled]=" !isPrivileged(['ADMIN','CAN_ASSIGN_TASK']) ">
                            Assign
                        </button>

                        <button type="button" class="btn" style="color:white;background:#8e0101;" (click)="viewTask(element)"
                        >
                        View
                    </button>

                        
                    </div>


                </td>

            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

        </table>
        <!-- <div class=""> -->
        <mat-paginator *ngIf="allTasks != undefined && allTasks.length <= 0" length={{allTasks.length}} [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            aria-label="Select page of Tasks!">
        </mat-paginator>
        <!-- </div> -->

        <hr>
        <div *ngIf="allTasks != undefined && allTasks.length <= 0" class="alert alert-primary" role="alert">
            You don't have any pending tasks at this moment. Check back later!
        </div>
   
            


    </div>
</div>