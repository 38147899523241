import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { ViewUpdateKypComponent } from 'src/app/modal/view-update-kyp/view-update-kyp.component';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { CustomerService } from 'src/app/services/customer.service';
import { AddKypUserComponent } from './add-kyp-user/add-kyp-user.component';
import { ToastrService } from 'ngx-toastr';
import { ViewPolicyMembersComponent } from './view-policy-members/view-policy-members.component';
import { ViewEkypComponent } from './view-ekyp/view-ekyp.component';

@Component({
  selector: 'app-manage-ekyp',
  templateUrl: './manage-ekyp.component.html',
  styleUrls: ['./manage-ekyp.component.scss']
})
export class ManageEkypComponent implements OnInit {

  user: any;
  dataToDisplay: any[] = []; // Current page data
  policyNumber: any;
  memberName: any;
  policyList: any[] = [];
  dataSet: any = [];
  totalRecords = 0;
  pageSize = 20;
  currentPage = 0;
  itemsFetched = 0;
  batchSize = 100;
  dataSource = new MatTableDataSource<any>(this.dataSet);
  displayedColumns = ['policyNumber', 'startDate', 'endDate', 'sumInsured', 'policyType', 'policyMember', 'ekyp'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private _dialog: MatDialog,
    private toastrService: ToastrService
  ) { }
  ngOnInit(): void {
    this.authService.user.subscribe(x => this.user = x);
    this.dataSource.paginator = this.paginator;

  }

  getDetailsByPolicyNumber(rowNum: any, size: any) {
    if (this.policyNumber == null && this.memberName == null) {
      this.toastrService.error("Please enter either policy number or member name to get details");
    } else if (this.policyNumber == null) {
      this.customerService.getProcessEkypDetailsByPolicyId(rowNum, size, '', this.memberName)
        .pipe(first())
        .subscribe((data: any) => {
          console.log(data);
          this.dataSet = data.result;

          this.dataSource.data = [...this.dataSource.data, ...this.dataSet];
          console.log(this.dataSource.data);
          this.totalRecords = this.dataSource.data.length;
        //  this.dataToDisplay = this.dataSource.data.slice(0, this.pageSize);
          this.itemsFetched += this.dataSource.data.length;

        })
    } else if (this.memberName == null) {
      this.customerService.getProcessEkypDetailsByPolicyId(rowNum, size, this.policyNumber, '')
        .pipe(first())
        .subscribe((data: any) => {
          console.log(data);
          this.dataSet = data?.result;
          this.dataSource.data = [...this.dataSource.data, ...this.dataSet];
         // this.dataToDisplay = this.dataSource.data.slice(0, this.pageSize);
          this.totalRecords = this.dataSource.data.length;
          this.itemsFetched += data.data.length;

        })
    }else if(this.policyNumber != null && this.memberName != null){
      this.customerService.getProcessEkypDetailsByPolicyId(rowNum, size, this.policyNumber, this.memberName)
      .pipe(first())
      .subscribe((data: any) => {
        console.log(data);
        this.dataSet = data?.result;
        this.dataSource.data = [...this.dataSource.data, ...this.dataSet];
       // this.dataToDisplay = this.dataSource.data.slice(0, this.pageSize);
        this.totalRecords = this.dataSource.data.length;
        this.itemsFetched += data.data.length;

      })
    }

  }

  onPaginateChange(event: any) {

    const pageIndex = event.pageIndex;
    const pageSize = event.pageSize;

    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;


    if (endIndex >= this.dataSource.data.length) {
      this.getDetailsByPolicyNumber(this.itemsFetched, this.batchSize);
      this.dataToDisplay = this.dataSource.data.slice(startIndex, Math.min(endIndex, this.dataSource.data.length));
    }else {
      // No need to fetch new data, simply slice the data for the current page
      this.dataToDisplay = this.dataSource.data.slice(startIndex, Math.min(endIndex, this.dataSource.data.length));
    }
  
    this.currentPage = pageIndex;
  }

  viewMembers(policyMembers: any) {
    const dialog = this._dialog.open(ViewPolicyMembersComponent, {
      disableClose: true,
      data: policyMembers,
      height: '80%',
      width: '80%'
    });
    dialog.afterClosed().subscribe((data: any) => {

    });

  }


  viewEkypData(ekypData: any) {
    const dialogRef = this._dialog.open(ViewEkypComponent, {
      data: ekypData,
      disableClose: true,
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'full-width-dialog',
      height: '100%'
    });

    dialogRef.afterClosed().subscribe((data: any) => {

    }
    );
  }


  clear() {
    this.policyNumber = null;
  }



}
