import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/authService';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  user:any;
  constructor(private router:Router, private authService: AuthService,){
    this.authService.user.subscribe(x => this.user = x);
  }
  ngOnInit(): void {
    if(this.isRoles(['MOBILE_USER'])){
      this.router.navigate(['/claim-now']);
    }
    
  }

  isRoles(role:string[]){
    const roles:any[] = this.user.roles;
    var dd =  roles.some((x: string) => role.includes(x));
    return dd;
  }
}
