import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, map, retry, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class HospitalRegistrationService {
  public userData = new Subject();
  constructor(private router: Router, private http: HttpClient) {}

  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  requestOptions = { headers: this.headers };
  register(req: any) {
    return this.http.post<any>(
      `${environment.API_URL}/auth/org`,
      req,
      this.requestOptions
    );
  }

  requestOptions_token:any;
  addHospital(req: any) {
    let token =localStorage.getItem("token");
    const headers_token = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    this.requestOptions_token = { headers: headers_token };
    return this.http.post<any>(
      `${environment.API_URL}/auth/org`,
      req,
      this.requestOptions_token
    );
  }

  startCampaing(
    city: string,
    minBedSize: number,
    maxBedSize: number
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.API_URL}/campaign/email/hospitals/` +
          city +
          "?minBedSize=" +
          minBedSize +
          "&maxBedSize=" +
          maxBedSize,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getCities() {
    return this.http
      .get<any>(
        `${environment.API_URL}/campaign/cities?rowNum=0&size=20`,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getcustomerdetailById(customerId: any): Observable<any> {
    // return this.http.get<any>(environment.API_URL_FOR_DETAILS + "?customer_id" + customerId )
    return this.http
      .get<any>(environment.API_URL + "/campaign/hospital/" + customerId)

      .pipe(catchError(this.errorHandle));
  }

  

  hospital(keyword: any): Observable<any> {
    return this.http
      .get<any>(environment.API_URL + "/campaign/hospitals?filter=" + keyword)
      .pipe(catchError(this.errorHandle));
    // .pipe(map((data) => data.map((item: { name: any }) => item.name)));
  }

  
/*
  addhospitalMedia(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/hospitalmedia/add`, data)
      .pipe(catchError(this.errorHandle));
  }
*/
  addhospitalInsuranceMedia(data: any, hospitalId: any) {
    return this.http
      .post<any>(
        `${environment.API_URL}/onboarding/hospital/${hospitalId}/insurance`,
        data,{
          reportProgress: true,
          observe: "events"
        }
      )
      .pipe(catchError(this.errorHandle));
  }

  gethospitalInsurance(hospitalId: any, rowNum: number, size: number) {
    return this.http
      .get<any>(
        `${environment.API_URL}/onboarding/hospital/${hospitalId}/insurance?rowNum=`+rowNum +`&size=`+size)
      .pipe(catchError(this.errorHandle));
  }

  getHospitalByOrgId(hospitalId:any){
    return this.http
    .get<any>(
      `${environment.API_URL}/onboarding/org/${hospitalId}/hospital`)
    .pipe(catchError(this.errorHandle));
  }
  

  getInsuranceCompany() {
    return this.http
      .get<any>(`${environment.API_URL}/bgproduct/insurance/companies`, {})
      .pipe(catchError(this.errorHandle));
  }


  getTpaMasterList(rowNum: number, size: number) {
    return this.http
      .get<any>(`${environment.API_URL}/tpamaster/list?rowNum=` + rowNum + `&size=` + size)
      .pipe(catchError(this.errorHandle));
  }

  getBgProdcut(rowNum: number, size: number) {
    return this.http
      .get<any>(
        `${environment.API_URL}/bgproduct/list?rowNum=` + rowNum + `&size=` + size
      )
      .pipe(catchError(this.errorHandle));
  }

  associateProductwithHospital(data: any, hospitalId: any) {
    return this.http
      .post<any>(
        `${environment.API_URL}/onboarding/hospital/${hospitalId}/bgProducts`,
        data
      )
      .pipe(catchError(this.errorHandle));
  }

  getBgProducttWithHospitalId(hospitalId: any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/onboarding/hospital/${hospitalId}/bgProducts`
      )
      .pipe(catchError(this.errorHandle));
  }

  patchHospitalInsuranceTech(data: any, hospitalId: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/onboarding/hospital/insurance`,
        data
      )
      .pipe(catchError(this.errorHandle));
  }

  getReceiverEmailConfig(id: any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/claims/receiver/email/config?hospitalOrgId=` +
          id,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  addReceiverEmailConfig(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/claims/receiver/email/config`, data)
      .pipe(map((data) => {}));
  }
  
  updateReceiverEmailConfig(data: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/claims/receiver/email/config`,data)
      .pipe(catchError(this.errorHandle));
  }
  getSenderEmailConfig(id: any, insuranceTPAId:any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/claims/sender/email/config?hospitalOrgId=` +id + `&insuranceTPAId=`+insuranceTPAId ,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  addSenderEmailConfig(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/claims/sender/email/config`, data)
      .pipe(map((data) => {}));
  }
  
  updateSenderEmailConfig(data: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/claims/sender/email/config`,data)
      .pipe(catchError(this.errorHandle));
  }


  getEmailConfig(hospitalOrgId:any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/claims/email/config/` +
        hospitalOrgId,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }


  addEmailConfig(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/claims/email/config`, data)
      .pipe(map((data) => {}));
  }
  
  
  updateEmailConfig(data: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/claims/email/config`,data)
      .pipe(catchError(this.errorHandle));
  }


  // all services should be above this

  errorHandle(error: any) {
    let errorMessage = "";
    if (error.error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.error;
    } else {
      // Get server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  addHospitalInsurance(data: any) {
    return this.http
      .post<any>(
        `${environment.API_URL}/onboarding/hospital/insurance`,
        data,{
        reportProgress: true,
        observe: "events",
        }
        
      )
      .pipe(catchError(this.errorHandle));
  }

  getInsuranceCompanyById(Id: string) {
    return this.http
      .get<any>(`${environment.API_URL}/insurance/` + Id)
      .pipe(catchError(this.errorHandle));
  }
  
  getTpaById(Id: string) {
    return this.http
      .get<any>(`${environment.API_URL}/tpamaster/` + Id)
      .pipe(catchError(this.errorHandle));
  }

  getPortalInfo(hospitalOrgId:any, insuranceTPAId:any){
    return this.http
    .get<any>(
      `${environment.API_URL}/onboarding/hospital/insurance/portal?hospitalOrgId=`+hospitalOrgId+`&insuranceTPAId=`+insuranceTPAId
    )
    .pipe(catchError(this.errorHandle));
  }

  addPortalInfo(data: any) {
    return this.http
      .post<any>(
        `${environment.API_URL}/onboarding/hospital/insurance/portal`,
        data,
      )
      .pipe(catchError(this.errorHandle));
  }

  patchPortalInfo(data: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/onboarding/hospital/insurance/portal`,
        data,
      )
      .pipe(catchError(this.errorHandle));
  }
 
  patchHospitalFunds(productId: any,funds: any) {
    return this.http
    .patch<any>(
      `${environment.API_URL}/onboarding/hospital/funds?bgProductId=${productId}&funds=${funds}`,
      {})
      .pipe(catchError(this.errorHandle));
  }

  getRecipientsList(rowNum:any, size:any,recipientGroup:any){
    return this.http
    .get<any>(`${environment.API_URL}/campaign/recipients?rowNum=${rowNum}&size=${size}&recipientGroup=${recipientGroup}`,{})
    .pipe(catchError(this.errorHandle));
  }

  addRecipient(formData:any){
    return this.http
      .post<any>(
        `${environment.API_URL}/campaign/recipients`,
        formData,
        {
          // reportProgress: true,
          // observe: "events"
        }
      )
      .pipe(catchError(this.errorHandle));
  }
  

  getTemplate(){
    return this.http.get<any>(`${environment.API_URL}/campaign/recipients/template`,{   responseType: 'blob' as 'json',})
    .pipe(catchError(this.errorHandle));
  }

  updateHospitalDetails(hospitalUniqueId:any,form:any){
    return this.http.patch<any>(`${environment.API_URL}/onboarding/hospital/${hospitalUniqueId}`,form)
    .pipe(catchError(this.errorHandle));
  }

  getDetails(hospitalOrgId:any){
    return this.http.get<any>(`${environment.API_URL}/auth/org/${hospitalOrgId}/detail`)
    .pipe(catchError(this.errorHandle));
  }

  addDetails(hospitalOrgId:any,form:any)
  {
    return this.http.post<any>(`${environment.API_URL}/auth/org/${hospitalOrgId}/detail`,form)
    .pipe(catchError(this.errorHandle));
  }

  updateDetail(orgDetailId:any, form:any)
  {
    return this.http.patch<any>(`${environment.API_URL}/auth/org/detail/${orgDetailId}`,form)
    .pipe(catchError(this.errorHandle));
  }

  testSenderConfig(form:any){
    return this.http.post<any>(`${environment.API_URL}/claims/sender/email/config/test`,form)
    .pipe(catchError(this.errorHandle));
  }

  testReceiverConfig(form:any){
    return this.http.post<any>(`${environment.API_URL}/claims/receiver/email/config/test`,form)
    .pipe(catchError(this.errorHandle));
  }

  getDailyReport(hospitalOrgId: any, startDate:any, endDate:any){
    return this.http.get<any>(`${environment.API_URL}/bpmn/hospital/${hospitalOrgId}/payorReport?startDate=${startDate}&endDate=${endDate}`)
    .pipe(catchError(this.errorHandle));
  }

  getBranchList(hospitalId:any,insuranceId:any){
    return this.http.get<any>(`${environment.API_URL}/onboarding/${hospitalId}/payor/${insuranceId}/branch`)
    .pipe(catchError(this.errorHandle));
  }


}
