import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';

@Component({
  selector: 'app-view-portal-details',
  templateUrl: './view-portal-details.component.html',
  styleUrl: './view-portal-details.component.scss'
})
export class ViewPortalDetailsComponent {

  portalForm!:FormGroup;
  formData : any;

  constructor(
    private formBuilder : FormBuilder,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    
  }
  ngOnInit(): void {
    this.portalForm = new FormGroup({
     hospitalOrgId: new FormControl(),
      insuranceTpaid: new FormControl(),
      portalUrl: new FormControl(),
      userId: new FormControl(),
      password: new FormControl(),
      payorType: new FormControl()
    });
    console.log(this.data)
    this.getPortalDetails();
  }

  getPortalDetails(){



   
    this.hospitalRegistrationService.getPortalInfo(this.data.hospitalOrgId, this.data.insuranceId)
    .pipe()
    .subscribe({
      next: (data: any) => {
        this.portalForm.patchValue(data);
      },
      error: (error) => {
        console.log(error);
        this.toastrService.error('Please add the portal details ');
        
      }
    })

  }

}
