import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  analyzedData: {
    "ageInYears":number;
    "withPED":boolean;
    "preHospitalizationDays":number;
    "postHospitalizationDays":number;
    "start_date":string;
    "currentPolicyYear":number;
    "coPayPercent":number;
    "benefits":[
      {
        "name":string,
        "comment":string
      }
    ];
    "comments":[
      {
        "name":string,
        "comment":string
      }
    ];
    "coverage_informations":[
      {
        "bonus":string,
        "dob":string,
        "effective_coverage":string,
        "insured_name":string,
        "restore":string,
        "super_bonus":string
      }
    ];
    "sublimit_informations":[
      {
        "insured_name":string,
        "sublimit":[
        {
          "name":string,
          "comment":string
        }
        ]
      }
    ]
    "waiting_period_informations":[
      {
        "insured_name":string,
        "pre_hospitalisation":string,
        "post_hospitalisation":string,
        "waiting_period":string
      }
    ]
  
  };
    
}
@Component({
  selector: 'app-e-kyp-modal',
  templateUrl: './e-kyp-modal.component.html',
  styleUrls: ['./e-kyp-modal.component.scss']
})
export class EKypModalComponent {
  error:any;
  message:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData)
     {
      console.log(this.data);
     }
}
