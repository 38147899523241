<mat-dialog-content class="mat-typography">
    <div class="container py-1">
      <div mat-dialog-title>
        <div class="row m-2 justify-content-md-center">
          <div class="col p-3">
            <div class="card">
              <div class="card-header text-center bg-light">Insurance and TPA Details</div>
              <div class="card-body" class="flex">
                <mat-form-field appearance="outline">
                    <mat-label>Insurance Name</mat-label>
                    <input matInput [value]="data.insuranceName" p>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>TPA Name</mat-label>
                    <input matInput [value]="data.tpaName" p>
                </mat-form-field>
                <button class="btn" type="button" style="color:white;background:#8e0101; " (click)="confirm()">Confirm
              </button>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button (click)="close()" >Close</button>
  </mat-dialog-actions>
  