import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CashflowService } from 'src/app/services/cashflow.service';

@Component({
  selector: 'app-add-user-org',
 //standalone: false,
   //imports: [CommonModule],
  templateUrl: './add-user-org.component.html',
  styleUrl: './add-user-org.component.scss'
})
export class AddUserOrgComponent {
  hospitalList:any
  userhospitalform: FormGroup;
  selectedHospital:any;


  constructor(private adminService:AdminService,
    private cashlessService:CashflowService,
    private _dialogRef: MatDialogRef<AddUserOrgComponent>,
    private toastrService:ToastrService,
    private _fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) public data: any,

  ){
    this.userhospitalform = this._fb.group({
      hospitals: ['', [Validators.required]],
      //userId: ['', [Validators.required, Validators.maxLength(50)]],
    });
    console.log(data);
this.getOnboardedHospitals();
  }
   
  getOnboardedHospitals(){
    this.cashlessService.getOnboardedHospitals()
    .pipe(first())
    .subscribe({
     next:(hospitals:any)=>{
       this.hospitalList = hospitals;
       console.log(this.hospitalList);

     },
     error:(error)=>{
       console.log("error " + JSON.stringify(error));
     }
    })
 }

 //this.selectedHospital.hospitalOrgId

 onFormSubmit() {
  var data = {
    //"roles": this.selectedType,
    "selectedHospital": this.selectedHospital,
    // "userId": this.data.id,
    "userId": this.data,
  }
  this.adminService.addUserhospital(this.data,this.selectedHospital.hospitalOrgId).subscribe({
    next: (val: any) => {
      this.toastrService.success("User hospital has been added successfully");
      this._dialogRef.close(true);
    },
    error: (err: any) => {
      // this.toastrService.success("Error");
      this.toastrService.error(JSON.stringify(err.error.error));

      console.error(err);
    },
  });
}
}
