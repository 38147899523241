import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { first, Observable } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { FileService } from "src/app/services/file.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-add-manage-hospital-aggrement",
  templateUrl: "./add-manage-hospital-aggrement.component.html",
  styleUrls: ["./add-manage-hospital-aggrement.component.scss"],
})
export class AddManageHospitalAggrementComponent implements OnInit {
  formData: FormData = new FormData();
  loading: any;
  message: any;
  selectedFiles: any;
  currentFile: any;
  categoryList: any;
  selectedCategory?: any;
  progress = 0;
  clicked: boolean = false;
  validFrom:any;
  validTo:any;
  
  constructor(
    public dialogRef: MatDialogRef<AddManageHospitalAggrementComponent>,
    private fileService: FileService,
    private cashlessService: CashflowService,
    private adminService: AdminService,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    this.getCategory();
  }

  getCategory() {
    const taskName = "createAgreement";
    this.cashlessService
      .getCategoryByTypeAndEntityType(taskName)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.categoryList = data;
          this.selectedCategory = this.categoryList[0].id.categoryName;
        },
      });
  }

  compareWithFn(o1: any, o2: any) {
    console.log("compare with");

    return o1 === o2;
  }

  selectCategory(event: any): void {
    //console.log(event.target.value);
    this.selectedCategory = event.target.value;
  }

  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = event.target.files.item(0);
    console.log(this.selectedFiles);
    //this.selectedFiles = file;
    this.currentFile = file;
  }

  uploadFile() {
    console.log("upload file start");
    this.progress = 0;
    this.formData = new FormData();
    this.formData.append("file", this.selectedFiles);
    this.formData.append("docType", this.selectedCategory);
    this.formData.append("entityId", this.data.hospitalOrgId);
    this.formData.append("entityType", "HOSPITAL");
    this.formData.append("category", "MASTER");
    this.formData.append("sourceType", "ADMIN");
    this.formData.append("startDate",new Date(this.validFrom).toISOString());
    this.formData.append("endData",new Date(this.validTo).toISOString());
    this.fileService.addExternalMedia(this.formData).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          this.message = event.body.message;
          window.location.reload();
        }
        this.toastrService.success(
          this.selectedCategory,
          " file has been uploaded successfully"
        );
        this.close("File(s) uploaded");
      },
      error: (error: any) => {
        console.log("error " + JSON.stringify(error));
        this.message = error;

        this.loading = false;
      },
    });
  }

  close(file: any) {
    this.dialogRef.close(file);
  }
}
