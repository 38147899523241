<div class="container p-3">
    <div class="container py-3 px-2">
      <div class="addbtn p-3">
        <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
          Add 
        </button>
      </div>
    
      <div class="row justify-content-md-center">
       
        <!-- <div class="col-lg-9 ms-3"> -->

          <mat-form-field appearance="outline">
            <mat-label>Select Zone Name</mat-label>
            <mat-select 
            (selectionChange)="setZone($event)"
            [(ngModel)]="selectedZone"
            >
              <mat-option value="north"> North </mat-option>
              <mat-option value="south"> South </mat-option>
              <mat-option value="west"> West </mat-option>
              <mat-option value="east"> East </mat-option>
            </mat-select>
          </mat-form-field>
  
        
              <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="container py-3 px-2">
      <div class="card px-3 py-4 justify-content-md-center">
        <div class="p-4">
          <mat-table class="" #table [dataSource]="dataSource">
            <ng-container matColumnDef="category">
              <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
              <mat-cell
                *matCellDef="let element"
              >

                {{ element.id.category}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="insuranceCompanyId">
              <mat-header-cell *matHeaderCellDef> Insurance Company Id </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id.insuranceCompanyId}}
               </mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="zone">
              <mat-header-cell *matHeaderCellDef> Zone </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id.zone}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="insuranceEmailId">
              <th mat-header-cell *matHeaderCellDef> Insurance Email Id </th>
              <td mat-cell *matCellDef="let element">
                <div *ngFor="let email of element.insuranceEmailId.split(',')">
                  {{ email }}
                </div>
              
              </td>
            </ng-container>

            <!-- <div class="col-lg-4">
                <ng-container matColumnDef="insuranceEmailId">
                    <mat-header-cell *matHeaderCellDef> InsuranceEmailId </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.insuranceEmailId}}
                    </mat-cell>
                  </ng-container>
              </div> -->

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button style="color:white;background:#8e0101" mat-flat-button (click)="openEditForm(element)">Edit</button>
                    </mat-cell>
                    
                    
                  </ng-container>
           
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>
      </div>
    </div> 
    
  