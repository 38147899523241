import { Component, Inject } from '@angular/core';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddUserRoleComponent } from '../add-user-role/add-user-role.component';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-manage-user-role',
  templateUrl: './manage-user-role.component.html',
  styleUrls: ['./manage-user-role.component.scss']
})
export class ManageUserRoleComponent {
  displayedColumns: string[] = ['id', 'roleName', 'roleDescription', 'action'];
  //  displayedColumns: string[] = ['Id', 'FirstName', 'LastName', 'User Type', 'EMail', 'Address', 'City', 'Zip', 'Mobile', 'action'];
  user: any;
  userRolesData: any;
  message: any;
  pageSize = 10;
  pageIndex = 0;
  constructor(private adminService: AdminService, private authService: AuthService,
    private _dialog: MatDialog,private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<ManageUserRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
    this.authService.user.subscribe(x => this.user = x);
    const dataSource = new MatTableDataSource<any>(this.userRolesData);
//console.log("selected user id",this.data);
  }

  ngOnInit(): void {

    this.getUserRoleslById();
  }

  getUserRoleslById() {
    // this.adminService.getUserRoleslById(this.user.id).pipe(first())
    this.adminService.getUserRoleslById(this.data).pipe(first())

      .subscribe({
        next: (data: any) => {
          //this.loading = false;
//console.log("getUserRoleslById", data);
          this.userRolesData = data;
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //sthis.loading = false;
        }
      });

  }

  openAddRolesForm() {
    const dialogRef = this._dialog.open(AddUserRoleComponent, {
      disableClose:true,
      "data":this.data,
      width:'430px',   // Set width to 600px
  //height:'350px',  // Set height to 530px
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getUserRoleslById();
          //this._dialogRef.close(true);

        }
      },
    });
  }

  // openAddRolesForm() {
  //   // console.log("dta",data)

  //   const dialogRef = this._dialog.open(AddUserRoleComponent);
  //   dialogRef.afterClosed().subscribe({
  //     next: (val) => {
  //       if (val) {
  //         this.getUserRoleslById();
  //       }
  //     },
  //   });
  // }
  deletUsereRoles(role: any) {
    //debugger;

    Swal.fire({
      title: 'Are you sure want to delete this role?',
      //text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {

      if (result.isConfirmed) {

       // console.log('Clicked Yes, File deleted!');
        var userroledata = {
          "roleIds": [
            role.id
          ],
          // "userId": this.user.id
          "userId": this.data
    
        }
       // console.log("payload",userroledata);
        this.adminService.deleteUserRoles(this.data,role.id).pipe(first())
          .subscribe({
            next: (data: any) => {
              //this.loading = false;
              //console.log("after delete",data);
              this.toastrService.success("User role has been deleted successfully");
  
              this.getUserRoleslById();
              // this.roles = data;
            },
            error: error => {
              console.log("error " + JSON.stringify(error))
              this.message = error;
    
              //sthis.loading = false;
            }
          });

      } else if (result.isDismissed) {

        //console.log('Clicked No, File is safe!');

      }
    })

      //console.log("Implement delete functionality here");
     
  
    }
  
    
  
   

}
