import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ViewFileComponent } from 'src/app/portal/bg/inbox/view-file/view-file.component';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
import { SendEmailConfigComponent } from '../send-email-config/send-email-config.component';
import { UpdateManageHospitalTechnicalInsuranceComponent } from '../update/update-manage-hospital-technical-insurance/update-manage-hospital-technical-insurance.component';
import { AddPortalDetailsComponent } from '../add-portal-details/add-portal-details.component';
import { ViewPortalDetailsComponent } from '../view-portal-details/view-portal-details.component';
import { AddManageHospitalTechnicalInsuranceComponent } from '../add/add-manage-hospital-technical-insurance/add-manage-hospital-technical-insurance.component';
import { InsuranceComponent } from 'src/app/onboarding/insurance/insurance.component';

@Component({
  selector: 'app-insurance-tpa-list',
  templateUrl: './insurance-tpa-list.component.html',
  styleUrl: './insurance-tpa-list.component.scss'
})
export class InsuranceTpaListComponent implements OnInit ,AfterViewInit, OnDestroy{

  insuranceData:any[] = [];
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  message:any;
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  displayedColumns: string[] = [
    "insuranceName",
    "claimSubmissionMethod",
    "socId",
    "dateCreated",
    "senderConfig",
    "update",
    "addPortalDetails"
    
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public hospitalDetails: any,
    public hospitalRegistrationService: HospitalRegistrationService,
    private _dialog:MatDialog,
  ){
    console.log("hospital Details:" +JSON.stringify(this.hospitalDetails));
    this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
  }

  ngOnInit(): void {
    this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator  = this.paginator;
  }
  ngOnDestroy(): void {
   
  }
  
  getHospitalInsurance(hospitalOrgId:any) {
    this.hospitalRegistrationService
      .gethospitalInsurance(
        hospitalOrgId,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          console.log( data);
          this.insuranceData = data;
          this.dataSource.data = this.insuranceData;
          this.dataSource.paginator  = this.paginator;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  applyFilter(filter:any){
    const value = filter.target.value;
    filter = value.trim();
    this.dataSource.filter = filter;
  }

  openViewFile(metaData: any) {
    console.log(metaData);
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose: true,
      data: { fileMetaData: metaData },
      width: "90%",
      // height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }

  
  viewSendingMailParser(insurance:any){
    const dialogRef = this._dialog.open(
      SendEmailConfigComponent,
      {
        disableClose: true,
        data: insurance,
        width:'80%'

        // height: "70%",
      },
      
    );
   
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
        if (val) {
        }
      },
    });
  }


  editTechincalInsurance(metaData: any) {
    // console.log(metaData);
    const dialogRef = this._dialog.open(
      UpdateManageHospitalTechnicalInsuranceComponent,
      {
        disableClose: true,
        data: metaData,

        // height: "70%",
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
        if (val) {
        }
      },
    });
  }

  addPortalDetails(insurance:any){
    const dialogRef = this._dialog.open(
      AddPortalDetailsComponent,
      {
        disableClose: true,
        data: insurance,
        width:'80%'

        // height: "70%",
      },
      
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
        if (val) {
        }
      },
    }); 
  }

  viewPortalDetails(insurance:any){
    const dialogRef = this._dialog.open(
      ViewPortalDetailsComponent,
      {
        disableClose: true,
        data: insurance,
        width:'80%'

        // height: "70%",
      },
      
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
        if (val) {
        }
      },
    }); 
  }

  // addTechincalInsurance(data: any): void {
  //   console.log(data);
  //   const dialogRef = this._dialog.open(
  //     AddManageHospitalTechnicalInsuranceComponent,
  //     {
  //       disableClose: true,
  //       data: data,
  //     }
  //   );
  //   dialogRef.afterClosed().subscribe({
  //     next: (val) => {
  //       this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
  //       if (val) {
  //       }
  //     },
  //   });
  // }


  addTechincalInsurance(data: any): void {
    console.log(data);
    const dialogRef = this._dialog.open(
      InsuranceComponent,
      {
        disableClose: true,
        data: {
          "sourceType":data.data.sourceType,
          "entityId":data.data.hospitalOrgId,
        },
        height:"90%"
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance(this.hospitalDetails.data.hospitalOrgId);
        if (val) {
        }
      },
    });
  }
}
