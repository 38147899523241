
    <h2 mat-dialog-title> Assign Task To </h2>

    <mat-dialog-content class="mat-typography">
        <form [formGroup]="assignForm" (ngSubmit)="onFormSubmit()">
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>User</mat-label>
                    <mat-select formControlName="task" [(ngModel)]="selectedUserId" name="user" single>
                        <mat-option *ngFor="let user of users" [value]="user.id">
                            {{user.firstName}} {{user.lastName}} ({{user.userType}})

                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="!assignForm.valid" (click)="onFormSubmit()" [mat-dialog-close]="true">Save</button>
        
    </mat-dialog-actions>

