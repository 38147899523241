<!-- <div mat-dialog-title>
    <h1> Add product</h1>
</div> -->

<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add product</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
<form [formGroup]="productForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id"  >
            </mat-form-field>
        </div> -->
        <div class="row">
            <!-- <mat-form-field appearance="outline">
                <mat-label>Product id</mat-label>
                <input matInput type="text" placeholder="Ex. Technical" formControlName="productId">
            </mat-form-field> -->
             
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" formControlName="name">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput type="text" placeholder="Description" formControlName="description" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Price</mat-label>
                <input matInput type="email" placeholder="Price" formControlName="price" >
            </mat-form-field>
        
            <mat-form-field appearance="outline">
                <mat-label>Short Name</mat-label>
                <input matInput type="text" placeholder="Short Name" formControlName="shortName" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Tc Required</mat-label>
                <input matInput type="text" placeholder="Tc Required" formControlName="tcRequired" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>GST</mat-label>
                <input matInput type="text" placeholder="gst" formControlName="gst" >
            </mat-form-field>
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>In Active</mat-label>
                <input matInput type="text" placeholder="Status" formControlName="inactive" >
            </mat-form-field>
        </div> -->

            <mat-form-field appearance="outline">
                <mat-label>In Active</mat-label>
                <mat-select formControlName="inactive" name="inactiveData">
                    <mat-option value=true>
                        Inactive
                    </mat-option>
                    <mat-option value=false>
                        Activate
                    </mat-option>
                  </mat-select>
            </mat-form-field>
        

            <mat-form-field appearance="outline">
                <mat-label>Process Workflow</mat-label>
                <input matInput type="text" placeholder="Process Workflow" formControlName="processWorkflow" >
            </mat-form-field>
        </div>
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Partner type id</mat-label>
                <input matInput type="text" placeholder="mobilePhone" formControlName="partnerTypeId" >
            </mat-form-field>
        </div> -->
         
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary"[disabled]="!productForm.valid" type="submit">Save</button>
    </div>
</form>