<div class="container p-3">
    <div class="input-fields">
        <div class="col">
            <mat-form-field  style="width: 300px;">
                <mat-label>Month</mat-label>
                <mat-select [(ngModel)]="month">
                    <mat-option *ngFor="let month of months" [value]="month.number">
                        {{ month.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col">

            <mat-form-field  style="width: 300px;">
                <mat-label>Year</mat-label>
                <mat-select [(ngModel)]="year">
                    <mat-option *ngFor="let year of years" [value]="year">
                        {{ year }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col">         
            <mat-form-field  style="width: 300px;">
                <mat-label>Hospital</mat-label>
                <mat-select [(ngModel)]="hospitalOrgId">
                    <mat-option *ngFor="let hospital of hospitalList" [value]="hospital.hospitalOrgId">
                        {{ hospital.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="button-group">
            <button mat-raised-button class="generateButton"  (click)="generateReport()"
            [disabled]="month === null || year === null || hospitalOrgId === null"
            >Generate Report</button>

            <button mat-raised-button class="generateButton"  (click)="exportexcel()"
            [disabled]="!reportGenerated"
            >Export to Excel</button>
        </div>
    </div>

</div>
<div class="overlay" *ngIf="loading">
    <div class="spinner"></div>
  </div>
  <!-- <button (click)="exportexcel()">Export to Excel</button> -->
<div class="row" *ngIf="monthlyDataMap.length>0" id="table-data">
    <div class="card">
       
            <table>
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>Category</th>
                    <th>Count</th>
                    <th>Business</th>
                    <th>Settled With TDS</th>
                    <th>Partial Payment By IC (Recoverable)</th>
                  </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="monthlyDataMap && monthlyDataMap.length > 0">
                        <ng-container *ngFor="let element of monthlyDataMap">
                          <tr *ngFor="let row of element.rows; let i = index">
                            
                            <td *ngIf="i === 0" [attr.rowspan]="element.rows.length">
                              {{ element.monthYear }}
                            </td>
                            <td>
                                <strong *ngIf="row.category == 'Total'; else normalCategory">{{ row.category }}</strong>
                                <ng-template #normalCategory>{{ row.category }}</ng-template>
                              </td>
                            <td>{{ row.count  }}</td>
                            <td>{{ row.business  }}</td>
                            <td>{{ row.settledWithTDS  }}</td>
                            <td>{{ row.recoverable }}</td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <tr>
                        <td><strong>Total</strong></td>
                        <td></td>
                        <td>{{ totalCount }}</td>
                        <td>{{ totalBusiness }}</td>
                        <td>{{ totalSettledWithTDS }}</td>
                        <td>{{ totalRecoverable }}</td>
                      </tr>
                </tbody>
            </table>

    </div>
    
    <div class="card">
        <table>
            <thead>
                <tr>
                    <th>Month</th>
                    <th>Count</th>
                    <th>Business</th>
                    <th>Settled with TDS</th>
                    <th>Partial Payment by IC( Recoverable)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Fully Claim Settled</td>
                    <td>{{dataSource2.fullyClaimSettled.count}}</td>
                    <td>{{dataSource2.fullyClaimSettled.business}}</td>
                    <td>{{dataSource2.fullyClaimSettled.settledWithTDS}}</td>
                    <td>{{dataSource2.fullyClaimSettled.recoverable}}</td>
                </tr>
                <tr>
                    <td>Outstanding</td>
                    <td>{{dataSource2.outstanding.count}}</td>
                    <td>{{dataSource2.outstanding.business}}</td>
                    <td>{{dataSource2.outstanding.settledWithTDS}}</td>
                    <td>{{dataSource2.outstanding.recoverable}}</td>
                </tr>
                <tr>
                    <td>Partial Claim Settled</td>
                    <td>{{dataSource2.partiallyClaimSettled.count}}</td>
                    <td>{{dataSource2.partiallyClaimSettled.business}}</td>
                    <td>{{dataSource2.partiallyClaimSettled.settledWithTDS}}</td>
                    <td>{{dataSource2.partiallyClaimSettled.recoverable}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card">
       
            <table class="p-3" *ngIf="weeklyReport">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>0-15 Days</th>
                        <th>15-30 Days</th>
                        <th>30-45 Days</th>
                        <th>45-60 Days</th>
                        <th>60 Days & Above</th>
                        <th>Grand Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Claim Approved- UTR Pending </td>
                        <td>{{weeklyReport.utrPending0to15Days}}</td>
                        <td>{{weeklyReport.utrPending15to30Days}}</td>
                        <td>{{weeklyReport.utrPending30to45Days}}</td>
                        <td>{{weeklyReport.utrPending45to60Days}}</td>
                        <td>{{weeklyReport.utrPending60plusDays}}</td>
                        <td>{{(weeklyReport.utrPending0to15Days) + (weeklyReport.utrPending15to30Days)+
                            (weeklyReport.utrPending30to45Days)+ (weeklyReport.utrPending45to60Days)+ (weeklyReport.utrPending60plusDays)}}
                        </td>
                    </tr>
        
                    <tr>
                        <td>Under Process </td>
                        <td>{{weeklyReport.utrPending0to15Days}}</td>
                        <td>{{weeklyReport.utrPending15to30Days}}</td>
                        <td>{{weeklyReport.utrPending30to45Days}}</td>
                        <td>{{weeklyReport.utrPending45to60Days}}</td>
                        <td>{{weeklyReport.utrPending60plusDays}}</td>
                        <td>{{(weeklyReport.utrPending0to15Days) + (weeklyReport.utrPending15to30Days)+
                            (weeklyReport.utrPending30to45Days)+ (weeklyReport.utrPending45to60Days)+ (weeklyReport.utrPending60plusDays)}}
                        </td>
                    </tr>
                </tbody>
            </table>
        
    </div>

    <br>
   
    <div class="card">
        <table class="p-3" *ngIf="weeklyReport">
            <thead>
                <tr>
                    <th>Month</th>
                    <th>0-15 Days</th>
                    <th>15-30 Days</th>
                    <th>30-45 Days</th>
                    <th>45-60 Days</th>
                    <th>60 Days & Above</th>
                    <th>Grand Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Claim Approved- UTR Pending </td>
                    <td>{{weeklyReport.utrPending0to15DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending15to30DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending30to45DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending45to60DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending60plusDaysAmt}}</td>
                    <td>
                        {{ (weeklyReport.utrPending0to15DaysAmt)+ (weeklyReport.utrPending15to30DaysAmt)+
                        (weeklyReport.utrPending30to45DaysAmt) + (weeklyReport.utrPending45to60DaysAmt) + (weeklyReport.utrPending60plusDaysAmt)}}
                    </td>
                </tr>
    
                <tr>
                    <td>Under Process </td>
                    <td>{{weeklyReport.utrPending0to15DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending15to30DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending30to45DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending45to60DaysAmt}}</td>
                    <td>{{weeklyReport.utrPending60plusDaysAmt}}</td>
                    <td>
                        {{ (weeklyReport.utrPending0to15DaysAmt)+ (weeklyReport.utrPending15to30DaysAmt)+
                        (weeklyReport.utrPending30to45DaysAmt) + (weeklyReport.utrPending45to60DaysAmt) + (weeklyReport.utrPending60plusDaysAmt)}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  
    <div class="card">
        <table class="p-3" *ngIf="weeklyReport">
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Count</th>
                    <th>Amount</th>
                </tr>
                
            </thead>
            <tbody>
                <tr>
                    <td>Short Payment - Recovery Done By BG</td>
                    <td>{{weeklyReport.recoveryDoneByBGCount}}</td>
                    <td>{{weeklyReport.recoveryDoneByBGAmt}}</td>
                    
                </tr>
    
                <tr>
                    <td>Short Payment - Under Review</td>
                    <td>{{weeklyReport.shortPaymentUnderReviewCount}}</td>
                    <td>{{weeklyReport.shortPaymentUnderReviewAmount}}</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
   
    <div class="card">
        <table>
            <thead>
                <tr>
                    <th>Month</th>
                    <th>FAL Deductions</th>
                    <th>Reconsiderations</th>
                    <th>Grand Total</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let element of weeklyReport.monthlyDataMap">
                    <tr>
                        <td>{{element.monthYear}}</td>
                        <td>{{element.falDeductions}}</td>
                        <td>{{element.reconsiderations}}</td>
                        <td>{{(element.falDeductions)+(element.reconsiderations)}}</td>
                    </tr>
                </ng-container>
                
            </tbody>
        </table>
        <!-- <mat-table [dataSource]="dataSource6" class="p-3">
            <ng-container matColumnDef="month">
                <mat-header-cell *matHeaderCellDef>Month</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.monthYear}}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="falDeductions">
                <mat-header-cell *matHeaderCellDef>FAL Deductions</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.falDeductions}} </mat-cell>
            </ng-container>
    
            <ng-conatiner matColumnDef="reconsideration">
                <mat-header-cell *matHeaderCellDef="">Reconsideration</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.reconsiderations}}</mat-cell>
            </ng-conatiner>
    
            <ng-conatiner matColumnDef="grandTotal">
                <mat-header-cell *matHeaderCellDef>Grand Total</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.falDeductions + element.reconsiderations}} </mat-cell>
            </ng-conatiner>
    
            <mat-header-row *matHeaderRowDef="columnsForTable6"></mat-header-row>
            <mat-row *matRowDef="let row;columns : columnsForTable6;"></mat-row>
        </mat-table> -->
    </div>
   
</div>