<div class="container text-center" style="width: 100%">
  <p>View File</p>
  <div style="width: 100vh; height: 50%">
    <ngx-extended-pdf-viewer
      *ngIf="pdfUrl != undefined"
      [src]="pdfUrl"
     
      [textLayer]="true"
      [showHandToolButton]="true"
    >
      <!-- enable text selection -->
    </ngx-extended-pdf-viewer>
    <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
  </div>
</div>
<div class="d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Please wait...</span>
  </div>
</div>
