import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { first } from "rxjs";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-hospital-subscription",
  templateUrl: "./hospital-subscription.component.html",
  styleUrl: "./hospital-subscription.component.scss",
})
export class HospitalSubscriptionComponent {
  message: any;
  user: any;

  productsData: any[] = [];
  dataSourceProduct = new MatTableDataSource<any>(this.productsData);
  displayedColumnsProduct: string[] = ["name", "description","startDate","endDate", "qty", "price","funds"];

  form: FormGroup = this.formBuilder.group({});
  selectedProductId: any;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private hospitalRegistrationService: HospitalRegistrationService,
    private customerService: CustomerService
  ) {
    this.user = this.authService.userValue;
  }
  ngOnInit() {
    this.getUser();
    this.form = this.formBuilder.group({
      product: ["", Validators.required],
      funds: ["", [Validators.required, Validators.min(0)]],
    });
  }
  getUser() {
    this.authService
      .getUserById(this.user.id)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.user = data;
          console.log("users data", data);
          this.getBgProducttWithHospital(this.user);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //sthis.loading = false;
        },
      });
  }
  getBgProducttWithHospital(hospitalData: any) {
    this.hospitalRegistrationService
      .getBgProducttWithHospitalId(hospitalData.orgId)
      .subscribe({
        next: (data) => {
          this.productsData = data;
          this.dataSourceProduct.data = this.productsData;
          console.log("products data", data);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }
  onFormSubmit(): void {
    if (this.form.valid) {
      const { product, funds } = this.form.value;
      console.log("Form submitted successfully:", product);
      var data = {
        bgProductId: product,
        funds: funds,
      };
      const orderId = this.generateOrderId(); // Ensure this matches the backend expectations
      const amount = funds * 100;
      
      this.customerService.getPhonepePaymentUrl(orderId, amount)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            console.log('Payment URL:', data.result);
            const redirectUrl = data.result;
            window.location.href = redirectUrl;
            // this.payurl = data.result;
            // this.payurlbtn = true;
          },
          error: (error) => {
            console.error('Error:', error);
          }
        });
      // Add your submission logic here
      this.hospitalRegistrationService
        .patchHospitalFunds(product, funds)
        .subscribe({
          next: (data: any) => {
            console.log("Form submitted successfully:", data);
            this.form.reset();
            this.message = "Form submitted successfully!";
          },
          error: (error: { error: any }) => {
            console.log(error);
          },
        });
    } else {
      console.log("Form is invalid");
    }
  }
   generateOrderId(): string {
    const prefix = "Order_";
    const randomPart = Math.random().toString(36).substring(2, 9).toUpperCase(); // Random alphanumeric
    return prefix + randomPart;
  }
}
