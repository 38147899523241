<div class="p-3">
  <div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <div class="input-group mb-3">
          <span *ngIf="currentData.data.hospitalOrgId ">
            <img id="hospitalSeal" class="small-image"
              [src]="apiUrl + '/externalMedia/' + currentData.data.hospitalOrgId + '/seal'" />
          </span>
          <h1>{{ currentData.data.name }}</h1>
        </div>

      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>

        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <hr />
  <mat-dialog-content class="mat-typography">
    <mat-tab-group>
      <mat-tab label="Hospital Details">
        <div class="d-flex justify-content-end">
          <!-- <div class="row ">
                  <img  id= "hospitalSeal" 
                  class="small-image"
                  [src]="apiUrl + '/externalMedia/' + currentData.data.hospitalOrgId + '/seal'"
              />
              </div> -->
          <button type="button" mat-flat-button style="color: white; background: #8e0101 ;margin-right: 10px;"
            (click)="addHospitalSeal()">Add Hospital Seal
          </button>

          <button type="button" mat-flat-button style="color: white; background: #8e0101"
            (click)="updateHospitalDetails()">Update
          </button>

        </div>

        <form [formGroup]="addressForm" (ngSubmit)="onFormSubmit()" [ngClass]="{'disabled-form': addressForm.disabled}">
          <!-- <div mat-dialog-content class="content"> -->
          <hr />
          <div class="text-center">
            <!-- <p>Contact Details</p> -->
          </div>
          <div class="row">
            <mat-form-field appearance="outline">
              <mat-label>Rohini Id</mat-label>
              <input matInput type="text" placeholder="Xyz" formControlName="hospitalUniqueId" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput type="text" placeholder="Xyz" formControlName="name" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Mobile</mat-label>
              <input matInput type="text" placeholder="1234567890" formControlName="contactDetail" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" placeholder="abc@gmail.com" formControlName="emailId" />
            </mat-form-field>
          </div>
          <!-- ........ <hr /> -->
          <div class="text-center">
            <!-- <p>Address Details</p> -->
          </div>
          <div class="row">
            <mat-form-field appearance="outline">
              <mat-label>Address</mat-label>
              <input matInput type="text" placeholder="Abc, Street" formControlName="address" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="outline">
              <mat-label>District</mat-label>
              <input matInput type="text" placeholder="Mumbai" formControlName="district" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <input matInput type="text" placeholder="Maharashtra" formControlName="state" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Latitude</mat-label>
              <input matInput type="text" placeholder="latitude" formControlName="latitude" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Longitude</mat-label>
              <input matInput type="text" placeholder="longitude" formControlName="longitude" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Zone</mat-label>
              <input matInput type="text" placeholder="zone" formControlName="zone" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Pre Auth Filled</mat-label>
              <input matInput type="text" placeholder="Pre Auth Filled" formControlName="preAuthFilled" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Medical Scrutiny Filled</mat-label>
              <input matInput type="text" placeholder="Medical Scrutiny" formControlName="medicalScrutinyApproval" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Claim Through Email</mat-label>
              <input matInput type="text" placeholder="Claim Through Email" formControlName="claimThroughEmail" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>File Dispatch Verification</mat-label>
              <input matInput type="text" placeholder="File Dispatch Verification"
                formControlName="fileDispatchVerification" />
            </mat-form-field>
          </div>
          <!-- </div> -->
          <div mat-dialog-actions class="action" hidden>
            <button mat-raised-button type="button" [mat-dialog-close]="false">
              Cancel
            </button>
            <button mat-raised-button color="primary" type="submit">
              Update
            </button>
          </div>
        </form>

      </mat-tab>
      <mat-tab label="Legal Agreement">
        <div class="d-flex justify-content-end">
          <button type="button" mat-flat-button style="color: white; background: #8e0101"
            (click)="addLegalAgreement(currentData.data)">
            Add
          </button>
        </div>
        <div *ngIf="legalData.length != 0">
          <div class="p-4">
            <table mat-table [dataSource]="dataSourceLegal" matSort>
              <!-- Position Column -->
              <ng-container matColumnDef="docType">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.docType }}
                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="path">
                <th mat-header-cell *matHeaderCellDef> Agreement</th>
                <td mat-cell *matCellDef="let element">
                  <a (click)="openViewFile(element)"> View </a>
                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef>Created Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dateCreated | date : "short" }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsLegal"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsLegal"></tr>
            </table>
          </div>
          <div class="demo-options p-4">
            <div class="demo-toggles">
              <mat-paginator [length]="dataSourceLegal.data.length" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </div>
          </div>
          <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
            {{ message }}
          </div>
        </div>
        <div *ngIf="legalData.length === 0">
          <div class="alert alert-info mt-2 pt-1" role="alert">
            No Legal Agreement found.
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Inurance Companies/TPA" class="tab">

        <app-insurance-tpa-list [hospitalDetails]="currentData.data"></app-insurance-tpa-list>
        <!-- <div class="d-flex justify-content-end">
          <button type="button" mat-flat-button style="color: white; background: #8e0101"
            (click)="addTechincalInsurance(currentData.data)">
            Add
          </button>
        </div>
        <div class="row pt-3 pb-3 justify-content-md-center">
          <div class="col">
            <input matInput (keyup)="applyFilter($event)" placeholder="Filter" class="form-control">

          </div>

        </div> -->
        <!-- <div *ngIf="insuranceData.length != 0">



          <mat-table class="p-4" [dataSource]="dataSource" matSort>
          
            <ng-container matColumnDef="insuranceName">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.insuranceName }}
              </td>
            </ng-container>
        
            <ng-container matColumnDef="claimSubmissionMethod">
              <th mat-header-cell *matHeaderCellDef>Tech Method</th>
              <td mat-cell *matCellDef="let element">
                {{ element.claimSubmissionMethod }}
              </td>
            </ng-container>
           
            <ng-container matColumnDef="socId">
              <th mat-header-cell *matHeaderCellDef>Soc</th>
              <td mat-cell *matCellDef="let element">
                <a (click)="openViewFile(element)"> View </a>
              </td>
            </ng-container>
          
            <ng-container matColumnDef="dateCreated">
              <th mat-header-cell *matHeaderCellDef>Created Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateCreated | date : "short" }}
              </td>
            </ng-container>
         
            <ng-container matColumnDef="senderConfig">
              <th mat-header-cell *matHeaderCellDef>Sender's Config</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button style="color:#8e0101;" (click)="viewSendingMailParser(element)">
                  <mat-icon>mail_outline</mat-icon>
                </button>
              </td>
            </ng-container>

           
            <ng-container matColumnDef="update">
              <th mat-header-cell *matHeaderCellDef>Update</th>
              <td mat-cell *matCellDef="let element">
                <button type="button" mat-flat-button style="color: white; background: #8e0101"
                  (click)="editTechincalInsurance(element)">
                  Update
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="addPortalDetails">
              <th mat-header-cell *matHeaderCellDef>Portal Details</th>
              <td mat-cell *matCellDef="let element">
                <button type="button" mat-flat-button style="color: white; background: #8e0101; margin:2px"
                  (click)="addPortalDetails(element)">
                  Add
                </button>
                <button type="button" mat-flat-button style="color: white; background: #8e0101"
                  (click)="viewPortalDetails(element)">
                  View
                </button>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </mat-table>
          <mat-paginator #paginator [length]="dataSource.data.length || 0" [pageSizeOptions]="[10, 25, 100]"
            showFirstLastButtons>
          </mat-paginator> -->

          <!-- <div class="demo-options p-4">
            <div class="demo-toggles"> -->

              <!-- <mat-paginator
                          #paginator
                          class="demo-paginator"
                          [length]="insuranceData.length"
                          [pageSize]="[5,10,20]"
                          showFirstLastButtons
                         aria-label="Select page"
                        >
                        </mat-paginator> -->
            <!-- </div>
          </div>
          <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
            {{ message }}
          </div>
        </div> -->
        <!-- <div *ngIf="insuranceData.length === 0">
          <div class="alert alert-info mt-2 pt-1" role="alert">
            No Insurance Companies found.
          </div>
        </div> -->
      </mat-tab>
      <mat-tab label="Receiving Email Config">
        <app-view-mail-parser-confs [hospitalOrgId]="currentData.data.hospitalOrgId"></app-view-mail-parser-confs>
      </mat-tab>

      <mat-tab label="Receiving Emails">
        <app-view-receiving-email [hospitalOrgId]="currentData.data.hospitalOrgId"></app-view-receiving-email>
      </mat-tab>

      <mat-tab label="Associated Products">
        <div class="d-flex justify-content-end">
          <button type="button" mat-flat-button style="color: white; background: #8e0101" (click)="viewProductForm()">
            Add
          </button>
        </div>
        <div class="row">
          <div *ngIf="productsData.length === 0">
            <div class="alert alert-info mt-2 pt-1" role="alert">
              No Products found.
            </div>
          </div>
          <div *ngIf="productsData.length != 0">
            <div class="p-4">
              <table mat-table [dataSource]="dataSourceProduct" matSort>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Product Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.bgProductModel.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.bgProductModel.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="qty">
                  <th mat-header-cell *matHeaderCellDef>Qty</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.qty }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef>Price</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.negotiatedRate }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="funds">
                  <th mat-header-cell *matHeaderCellDef>Funds</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.funds }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="fundsThreshold">
                  <th mat-header-cell *matHeaderCellDef>Funds Threshold</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fundsThreshold }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsProduct"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsProduct"></tr>
              </table>
            </div>
          </div>
          <div>
            <mat-paginator [length]="dataSourceLegal.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </div>

        </div>
      </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
</div>