<!-- <div mat-dialog-title>
  <h1>Add Coupon</h1>
</div> -->
<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Add Task Calculation</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray">* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="taskCalculationForm" (ngSubmit)="onFormSubmit()">
  <div mat-dialog-content class="content">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>name</mat-label>
        <input matInput type="text" placeholder="name" formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>calculationExpression</mat-label>
        <input matInput type="text" placeholder="calculationExpression" formControlName="calculationExpression">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>processVersion</mat-label>
        <input matInput type="text" placeholder="processVersion" formControlName="processVersion">
      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>Process Name</mat-label>
        <mat-select formControlName="processName" (selectionChange)="setProcess($event)"
          [(ngModel)]="selectedDeployeedProcessEntity" name="Entity">
          <mat-option *ngFor="let process of deployedAllProcesses" [value]="process.key">
            {{ process.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Task Name</mat-label>
        <!-- <input matInput type="text" placeholder="state" formControlName="state"> -->
        <mat-select formControlName="taskName" [(ngModel)]="selectedDeployedSubEntityProcessByProcessDefKey"
          name="Sub-Entity">
          <mat-option *ngFor="let process of deployedprocessByProcessDefKey" [value]="process.taskId"  (click)="selectedtask(process)">
            {{ process.taskName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Attributes</mat-label>
        <mat-select formControlName="attributeName" (ngModelChange)="appendAttributes($event)" name="Sub-Entity" multiple>
          <!-- <mat-option *ngFor="let process of selectedSubEntity" [value]="process.formAttributeId">
            {{ process.formAttributeName}}
          </mat-option> -->
          <mat-option *ngFor="let process of selectedSubEntity.formPropertyModel" [value]="process.formAttributeId">
            {{ process.formAttributeName}}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <div >
        
      </div>
      <mat-form-field appearance="outline">
        <mat-label>calculationExpression</mat-label>
        <input matInput type="text" placeholder="calculationExpression" formControlName="calculationExpression" [(ngModel)]="calculatedExpression">
      </mat-form-field>

      <!-- <div *ngFor="let input of inputs; let i = index" >
        <label for="input-{{i}}">Input {{i + 1}}:</label>
        <input type="text" id="input-{{i}}" [(ngModel)]="input.value" name="input-{{i}}">
      </div> -->
 
       <!-- <mat-form-field appearance="outline" *ngIf="calculatedExpression">
        <div *ngFor="let input of inputs; let i = index" >
          <label for="input-{{i}}">Calculation Input {{i + 1}}:</label>
          <input  matInput type="text" id="input-{{i}}" [(ngModel)]="input.value" name="input-{{i}}">
        </div>
      </mat-form-field>  -->
      <!-- <mat-form-field appearance="outline">

      <div *ngFor="let input of inputs; let i = index">
        <label for="input-{{i}}">Input {{i + 1}}:</label>
        <input type="number" matInput id="input-{{i}}" [(ngModel)]="inputs[i].value" name="input-{{i}}" (ngModelChange)="calculateResult()">
      </div>
      </mat-form-field> -->


<form [formGroup]="form" *ngIf="calculatedExpression">
  <div formArrayName="inputs">
    
    <div *ngFor="let input of inputs.controls; let i = index" [formGroupName]="i">
      <!-- <div *ngFor="let key of Object.keys(inputs.controls)">
        {{ key }}: {{ input.controls[key].value }}
      </div> -->
      <label for="input-{{i}}">Calculation Input{{i+1}}</label>
      <input type="number" id="input-{{i}}" formControlName="value" (input)="onInputChange()">
    </div>
    <button mat-raised-button color="success" type="button" (click)="calculateResult()">
      Validate
    </button>
  </div>
  
  <!-- <span>   {{totalSum}}
  </span> -->
  <div class="text-center">
    <h1>Result {{totalSum}}</h1>

</div>
</form>

      <!-- <mat-form-field appearance="outline">
              <mat-label>Attributes</mat-label>
              <mat-select
                formControlName="attributeName"
                [(ngModel)]="selectedtags"
  
                name="Sub-Entity" multiple>
                <mat-option *ngFor="let process of deployedprocessByProcessDefKey" [value]="process.formPropertyModel.formAttributeId">
                  {{ process.formPropertyModel[0].formAttributeName|json }}
                </mat-option>
              </mat-select>
            </mat-form-field>
           -->


      <!-- <mat-form-field appearance="outline">
                <mat-label>attributeName</mat-label>
                <input matInput type="text" placeholder="attributeName" formControlName="attributeName">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>processName</mat-label>
                <input matInput type="text" placeholder="processName"  formControlName="processName"  >
            </mat-form-field>
        </div>
      <mat-form-field appearance="outline">
                <mat-label>taskName</mat-label>
                <input matInput type="text" placeholder="taskName" formControlName="taskName">
            </mat-form-field> -->


      <mat-form-field appearance="outline">
        <!-- <mat-label>{{ status }}</mat-label> -->
        <mat-select formControlName="inactive" name="inactiveData">
          <mat-option value="true"> Inactive </mat-option>
          <mat-option value="false"> Activate </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <!-- [disabled]="!categoryForm.valid" -->

    <div mat-dialog-actions class="action">
      <button mat-raised-button type="button" [mat-dialog-close]="false">
        Cancel
      </button>
      <button mat-raised-button color="primary" type="submit">
        Save
      </button>
    </div>