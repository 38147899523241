import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { TechComponent } from "src/app/onboarding/insurance/tech/tech.component";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-update-manage-hospital-technical-insurance",
  templateUrl: "./update-manage-hospital-technical-insurance.component.html",
  styleUrls: ["./update-manage-hospital-technical-insurance.component.scss"],
})
export class UpdateManageHospitalTechnicalInsuranceComponent implements OnInit {
  // error message
  message: any;
  // upload file
  selectedFiles?: any;
  fileInfos?: Observable<any>;
  // form
  claims: any;
  insuranceCompanyId: any;
  form: FormGroup = this.formBuilder.group({});
  formData: FormData = new FormData();
  blacklisted: any;
  claimSubmissionMethod: any;
  insuranceName: any;
  // submission
  clicked: boolean = false;
  validFrom: any;
  validTo: any;

  constructor(
    private HospitalRegistrationService: HospitalRegistrationService,
    private formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<UpdateManageHospitalTechnicalInsuranceComponent>,
    private toastrService: ToastrService,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      insuranceName: [""],
      blacklisted: ["", Validators.required],
      claimSubmissionMethod: ["", Validators.required],
      configdata: ["", Validators.required],
      validFrom: ["", Validators.required],
      validTo: ["", Validators.required],
      upload: [null, Validators.required],
    });
  }
  ngOnInit(): void {
    console.log(this.data);
    this.form.controls["insuranceName"].setValue(this.data.insuranceName);
    this.form.controls["blacklisted"].setValue(this.data.blacklisted);
    this.form.controls["configdata"].setValue(this.data.configdata);
    this.form.controls["validFrom"].setValue(this.data.validFrom);
    this.form.controls["validTo"].setValue(this.data.validTo);
    this.form.controls["claimSubmissionMethod"].setValue(
      this.data.claimSubmissionMethod
    );
  }
  uploadFiles() {
    if (
      this.claims === null ||
      this.claims === undefined ||
      this.claims === ""
    ) {
      this.claims = this.data.claimSubmissionMethod;
    }
    this.formData.append("hospitalId", this.data.hospitalOrgId);
    this.formData.append("claimSubmissionMethod", this.claims);
    this.formData.append("insuranceCompanyId", this.data.insuranceId);
    this.formData.append("insuranceName", this.data.insuranceName);
    this.formData.append("validFrom", new Date(this.validFrom).toISOString());
    this.formData.append("validTo", new Date(this.validTo).toISOString());
    if (this.data.claimSubmissionMethod === "EMAIL") {
      this.formData.append("emailConf", this.form.value.configdata);
    } else if (this.data.claimSubmissionMethod === "PORTAL") {
      this.formData.append("portalConf", this.form.value.configdata);
    } else if (this.data.claimSubmissionMethod === "INTEGRATION") {
      this.formData.append("integrationConf", this.form.value.configdata);
    } else {
      this.formData.append("emailConf", this.form.value.configdata);
    }
    this.formData.append("file", this.selectedFiles);
    this.formData.append("docType", "CashlessSOC");
    this.formData.append("inactive", "false");
    // console.log("formData " + JSON.stringify(this.formData.getAll));

    this.hospitalInsuranceMediaSubmit();
  }

  hospitalInsuranceMediaSubmit() {
    this.HospitalRegistrationService
      .addhospitalInsuranceMedia(this.formData, this.data.id)
      .subscribe({
        next: (data: any) => {
          this.toastrService.success("File has been uploaded successfully");
          this._dialogRef.close(true);
          this.hospitalInsurance();
        },
        error: (error) => {
          console.log("error " + error);
          this.message = error;
          this.toastrService.error(JSON.stringify(error));
          this._dialogRef.close(true);
        },
      });
  }
  hospitalInsurance() {
    this.HospitalRegistrationService
      .patchHospitalInsuranceTech(this.formData, this.data.hospitalOrgId)
      .subscribe({
        next: (data: any) => {
          this.toastrService.success(
            "Data uploaded successfully for technical insurance"
          );
          this._dialogRef.close(true);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
          this.toastrService.error(JSON.stringify(error));
          this._dialogRef.close(true);
        },
      });
  }

  onChangeInsuranceCompanyblacklisted(arg0: any) {
    if (arg0) {
      this.blacklisted = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }
  onChangeInsuranceCompanyclaims(arg0: any) {
    if (arg0) {
      this.claims = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    } else {
      this.claims = this.data.claimSubmissionMethod;
    }
  }
  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
  }
}
