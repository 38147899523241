import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { CurrentStatusComponent } from 'src/app/cashless/timeline/current-status/current-status.component';
import { PreauthComponent } from 'src/app/pdf/cashless/preauth/preauth.component';
import { AuthService } from 'src/app/services/authService';
import { StandardPreauthFormComponent } from 'src/app/pdf/cashless/standard-preauth-form/standard-preauth-form.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataServiceService } from 'src/app/services/data-service.service';
import { AdminService } from 'src/app/services/admin.service';
import { CashlessFilter } from 'src/app/model/cashlessFilter';
export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}
@Component({
  selector: 'app-manage-cases',
  templateUrl: './manage-cases.component.html',
  styleUrls: ['./manage-cases.component.scss']
})
export class ManageCasesComponent implements OnInit{
  hospitalList:any =[];
  selectedHospital:any;
  insuranceMediaId:any;
  insurance:any;
  error:any;
  allTasks:any;
  selectedTask: any;
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  caseList:any =[];
  user:any;
  dataSource = new MatTableDataSource<any>(this.caseList);
  displayedColumns = ['caseID','patientName' ,'hospitalName','startDate','endDate','currentProcessState','action'];
  constructor(
    private cashlessService: CashflowService,
    private router:Router,
    private authService :AuthService,
    private _dialog: MatDialog,
    private dataService : DataServiceService,
    private adminService: AdminService
  ){
    this.authService.user.subscribe((x: any) => {
      this.user = x;
      
    });
   

    
  }
  ngOnInit(): void {
    this.getOnboardedHospitals();
    this.getCaseList('','','');
   // this.getDeployedprocessByProcessDefTasks("cashlessProcess");
  }

  getOnboardedHospitals(){
    if (this.isPrivileged(["CAN_VIEW_HOSPITAL"])) {
      this.cashlessService.getOnboardedHospitalByUser()
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            //  this.orgType = "HOSPITAL";
            console.log("Selected hospital is :" + JSON.stringify(data));
            // if(data.length == 1){
            //   this.hospitalId = data.hospitalOrgId;
            //   this.selectedHospital = data;
            //   this.orgId = data.hospitalOrgId;
            // }else{
                 
            this.hospitalList= data;
            // }
        
          },
          error: (error) => {
            console.log(error);
          }
        })
    }
    else {
     this.cashlessService.getOnboardedHospitals()
     .pipe(first())
     .subscribe({
      next:(hospitals:any)=>{
        this.hospitalList = hospitals;
        console.log(this.hospitalList);

      },
      error:(error)=>{
        console.log("error " + JSON.stringify(error));
      }
     })
  }
}

isPrivileged(privilege: string[]) {
  const privileges: any[] = this.user.privileges;
  var dd = privileges.some((x: string) => privilege.includes(x));
  //console.log("is Privileged "+ this.user.privileges);
  return dd;
}

  getCaseList(hospitalOrgId:any,startDate:any,endDate:any){
    this.cashlessService.getCaseList(hospitalOrgId,startDate,endDate)
    .pipe(first())
    .subscribe({
      next:(cases:any)=>{
        console.log("cases " + JSON.stringify(cases));
        this.caseList = cases;
        this.dataSource.data = this.caseList;
        this.getDeployedprocessByProcessDefTasks("cashlessProcess");
      },
      error:(error:any)=>{
        console.log("error " + JSON.stringify(error));
      }
     })
  }

  searchCases(){
    console.log(this.selectedHospital);
    console.log(this.dateRange?.value.start);
    console.log(this.dateRange?.value.end);
    if(this.dateRange.value.start == null ){
      this.getCaseList(this.selectedHospital.hospitalOrgId,'','');
    }else{
     this.getCaseList(this.selectedHospital.hospitalOrgId,this.dateRange?.value.start.toISOString(),this.dateRange?.value.end.toISOString());
    }
    
  }

  clear(){
    this.selectedHospital ='';
    this.getCaseList('','','');
  }

  onDateRangeChange(selected: MatDateRangeInput<Date>) {
    if (selected.value) {
      console.log('Start date:', selected.value.start);
      console.log('End date:', selected.value.end);
    }
  }

  viewCaseDetails(selectedCase:any){
    console.log(selectedCase);
    let dialogRef = this._dialog.open(CurrentStatusComponent,{
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%',
      data: selectedCase
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    console.log(selectedCase);

    // let dialogRef = this._dialog.open(CaseDetailsComponent,{
    //   width:'90%',
    //   height:'90%',
    //   data:selectedCase
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
    // console.log(selectedCase);
  
  }

  viewPreAuth(selectedCase:any){
    // console.log(selectedCase);
    // this.setInsuranceMediaId(selectedCase);
    // if(selectedCase["select-tpa-modal"] !="" && selectedCase.inhouseTPA == "inHouseTPANo"){
    //   console.log(this.insurance);
    //     let dialogRef = this._dialog.open(PreauthComponent,{
    //       disableClose:true,
    //       width: '100vw',  // Sets the width to 100% of the viewport width
    //       maxWidth: '100vw',  // Ensures the max width doesn't override the width
    //       panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
    //       height: '100%',
    //       data:{caseId: selectedCase.process_inst_id}
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //       console.log(`Dialog result: ${result}`);
    //     });
    //     console.log(selectedCase);
    // }else{
      let dialogRef = this._dialog.open(StandardPreauthFormComponent,{
            disableClose:true,
            width: '100vw',  // Sets the width to 100% of the viewport width
            maxWidth: '100vw',  // Ensures the max width doesn't override the width
            panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
            height: '100%',
            data:{caseId: selectedCase.process_inst_id}
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
          });
    }
    //console.log(selectedCase.process_data.preauth-ExecutionStatus == "EXECUTED")
    // if( this.insurance){
    //   console.log(this.insurance);
    //   let dialogRef = this._dialog.open(PreauthComponent,{
    //     disableClose:true,
    //     width: '100vw',  // Sets the width to 100% of the viewport width
    //     maxWidth: '100vw',  // Ensures the max width doesn't override the width
    //     panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
    //     height: '100%',
    //     data:{caseId: selectedCase.process_inst_id}
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(`Dialog result: ${result}`);
    //   });
    //   console.log(selectedCase);
    // }
    // else{
    //   let dialogRef = this._dialog.open(StandardPreauthFormComponent,{
    //     disableClose:true,
    //     width: '100vw',  // Sets the width to 100% of the viewport width
    //     maxWidth: '100vw',  // Ensures the max width doesn't override the width
    //     panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
    //     height: '100%',
    //     data:{caseId: selectedCase.process_inst_id}
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(`Dialog result: ${result}`);
    //   });
    //   // console.log(selectedCase);
    // }
   
  
  // }

  // setInsuranceMediaId(data: any) {
  //   //var x = JSON.parse(data);
  //   for (var key in data) {
  //     // // console.log(key);
  //     //var k = key.replace(/^"|"$/g, "");
  //     if (key == "select-tpa-modal" && data["select-tpa-modal"] !="" && data.inhouseTPA == "inHouseTPANo") {
  //       this.insuranceMediaId = data[key].replace(/^"|"$/g, "");
  //       this.getTpa();
  //       // console.log("tpaMediaId: " + JSON.stringify(this.insuranceMediaId));
  //     }
  //    else if (key == "select-primary-insurance-company-name-modal") {
  //       this.insuranceMediaId = data[key].replace(/^"|"$/g, "");
  //       let dialogRef = this._dialog.open(StandardPreauthFormComponent,{
  //         disableClose:true,
  //         width: '100vw',  // Sets the width to 100% of the viewport width
  //         maxWidth: '100vw',  // Ensures the max width doesn't override the width
  //         panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
  //         height: '100%',
  //         data:{caseId: data.process_inst_id}
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
  //         console.log(`Dialog result: ${result}`);
  //       });
  //     }
  //   }
  // }
  // getTpa() {
  //   this.cashlessService
  //     .getTpa(this.insuranceMediaId)
  //     .pipe(first())
  //     .subscribe({
  //       next: (data: any) => {
  //         this.insurance = data;
  //          console.log("prod", data);
  //       },
  //       error: (err: any) => {
  //         // console.log(err);

  //         if (err.error && err.error.error) {
  //           this.error = err.error.error;
  //         } else {
  //           this.error = "No Tpa available!";
  //         }
  //       },
  //     });
  
  // }
  


  filteredData:any = [];
  filterPriority(taskId:any){
    if(taskId == "ALL"){
      this.dataSource.data = this.caseList;
    }else{
      this.filteredData = this.caseList.filter((data:any) => data.process_data.currentProcessStatus === taskId);
      this.dataSource.data = this.filteredData;
    }
    
  }
  
  selectedKey:any;
  filterData:any[]=[];
deployedprocessByProcessDefTasks:any=[];
  getDeployedprocessByProcessDefTasks(processKey:any){
    this.selectedKey = processKey;
    this.filterData =[];
    this.adminService
    .getDeployedprocessByProcessDefKey(processKey)
    .pipe(first())
    .subscribe({
      next:(data:any)=>{
        this.deployedprocessByProcessDefTasks = data;
        console.log(this.deployedprocessByProcessDefTasks);

        for(let x=0;x<data.length;x++){
          const cf = new CashlessFilter();
          cf.taskId = data[x].taskId;
          cf.taskName = data[x].taskName;
          cf.count = this.caseList.filter((task:any)=>task.process_data.currentProcessStatus === cf.taskId).length;
          this.filterData.push(cf);
         }

         this.filterData.sort((a:any,b:any)=>b.count - a.count);
         console.log(JSON.stringify(this.filterData));
      },
      error:(err:any)=>{
         console.log(err);
      },

    });

  }


}
