<div class="row p-3 py-4 bg-light">
  <div class="col-9 p-3 d-flex justify-content-start">
    <h1>{{ data.task.name }} for {{ data.task.caseVariables.org.name }}</h1>
  </div>

  <div class="col p-2 d-flex justify-content-end">
    <h4>
      <i class="p-2" style="color: rgb(183, 0, 0)"
        >* <span style="color: lightgray">marked fields are mandatory</span>
      </i>
    </h4>

    <div class="p-2 example-button-container" mat-dialog-close>
      <button style="color: white; background: #8e0101" mat-mini-fab>
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="container mb-2" *ngIf="diagram != undefined">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState.set(true)"
        (closed)="panelOpenState.set(false)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Onboarding flow daigram </mat-panel-title>
        </mat-expansion-panel-header>
        <img class="img-fluid" [src]="diagram" />
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="container">
      <div class="col-12" *ngFor="let field of data.task.formDataFieldOptions">
        <ng-container [ngSwitch]="field.property.type.name">
          <div *ngSwitchCase="'string'">
            <ng-container *ngIf="parseId(field.property.id)[0] != 'upload'">
              <label [for]="field.property.id"
                >{{ field.property.name }}
                <span class="required" *ngIf="field.property.required"></span>
              </label>

              <input
                [ngClass]="{
                  invalid:
                    form.get(field.property.id)?.invalid &&
                    form.get(field.property.id)?.touched
                }"
                class="form-control my-2"
                [type]="getFieldType(field)"
                [formControlName]="field.property.id"
                [id]="field.property.id"
              />
            </ng-container>
            <div *ngIf="parseId(field.property.id)[0] == 'upload'">
              <div class="mt-2">
                <label [for]="field.property.id"
                  >{{ field.property.name }}
                  <span class="required" *ngIf="field.property.required"></span>
                </label>
              </div>

              <div class="mb-2">
                <button
                  [ngClass]="
                    !parseExpressionForEnum(
                      field.property.value,
                      field.property.id
                    )
                      ? ''
                      : 'bg-secondary'
                  "
                  [disabled]="
                    parseExpressionForEnum(
                      field.property.value,
                      field.property.id
                    )
                  "
                  mat-flat-button
                  type="button"
                  style="color: white; background: #8e0101"
                  (click)="fileUploadComponent()"
                >
                  Upload File
                </button>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'enum'">
            <div>
              <label [for]="field.property.id"
                >{{ field.property.name }}
                <span class="required" *ngIf="field.property.required"></span>
              </label>
              <select
                [ngClass]="{
                  invalid:
                    form.get(field.property.id)?.invalid &&
                    form.get(field.property.id)?.touched
                }"
                class="form-select my-2"
                [formControlName]="field.property.id"
                [id]="field.property.id"
              >
                <option disabled selected>Select an option</option>
                <option
                  (change)="
                    parseExpressionForEnum(
                      field.property.value,
                      field.property.id
                    )
                  "
                  *ngFor="let option of getEnumOptions(field.property.id)"
                  [value]="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
          <div *ngSwitchCase="'date'">
            <label [for]="field.property.id"
              >{{ field.property.name }}
              <span class="required" *ngIf="field.property.required"></span>
            </label>
            <input
              [ngClass]="{
                invalid:
                  form.get(field.property.id)?.invalid &&
                  form.get(field.property.id)?.touched
              }"
              class="form-control my-2"
              type="date"
              [formControlName]="field.property.id"
              [id]="field.property.id"
            />
          </div>
        </ng-container>
      </div>
    </div>

    <button
      [disabled]="!form.valid"
      type="submit"
      mat-flat-button
      style="color: white; background: #8e0101"
    >
      Submit
    </button>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
