<div class="row p-3 py-4 bg-light">
  <div class="row bg-light">
    <div class="col-9 pt-3 d-flex justify-content-start ">
      <h1>
        <span style="color:#8e0101;">TASK </span>
        <span class="text-secondary">{{ data.task.name }}</span>
        <span style="color:#8e0101;">&nbsp;&nbsp;CASE ID </span>
        <span class="text-secondary">{{ data.task.id }}</span>
      </h1>
      <br>

    </div>

    <div class="col pt-3 d-flex justify-content-end ">
      <button style="color:white;background:#8e0101;" mat-mini-fab
        (click)="toggleDiagramView()"><mat-icon>business</mat-icon></button>&nbsp;
      <button style="color:white;background:#8e0101;" mat-mini-fab
        (click)="toggleView()"><mat-icon>vertical_split</mat-icon></button>&nbsp;
      <button style="color:white;background:#8e0101;" mat-dialog-close mat-mini-fab><mat-icon>cancel</mat-icon></button>


    </div>
  </div>
  <div class="flex flex-nowrap  bg-white ">
    <div class="text-secondary">
      <strong>INFO </strong>
    </div>
    <div>
          <span *ngIf="data.task.caseVariables.org.name" style="margin:10px" class="item"> <strong>Hospital Name:
            </strong>{{data.task.caseVariables.org.name}}</span>
          <span *ngIf="data.task.caseVariables.org.addLine1" style="margin:10px" class="item"> <strong>Address :
            </strong>{{data.task.caseVariables.org.addLine1}}</span>
          <span *ngIf="data.task.caseVariables.org.contactNos" style="margin:10px" class="item"><strong>Contact Number:
            </strong>{{data.task.caseVariables.org.contactNos}}</span>
            <span *ngIf="data.task.caseVariables.org.email" style="margin:10px" class="item"><strong>Email :
            </strong>{{data.task.caseVariables.org.email}}</span>
    </div>

  </div>

  <div class="row p-3 ">
    <div class="col-1 p-2 text-secondary">
      <strong>ATTACHMENT(s) </strong>
    </div>
    <div class="col-10">
      <app-attachment-scroller [data]="uploadedMediaData"
        (fileSelected)="getExternalMediaById($event)"  (updateMediaList)="onMediaListUpdated($event)"></app-attachment-scroller>
    </div>
    <div class="col-1 pt-1 d-flex justify-content-end" style="color:#009ed3;">
      <div *ngIf="loading" class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="row p-3 ">
    <div class="col-1 p-2 text-secondary">
      <strong>BG PRODUCTS(s) </strong>
    </div>
    <div class="col-10">
      <app-attachment-scroller [data]="uploadedData"
        (fileSelected)="getExternalMediaById($event)"></app-attachment-scroller>
    </div>
    <div class="col-1 pt-1 d-flex justify-content-end" style="color:#009ed3;">
      <div *ngIf="loading" class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showDiagram">
    <div class="col">
      <div class="card-body" *ngIf="diagram != undefined">
        <img [src]="diagram" class="img-fluid" />
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col d-flex justify-content-end ">
      <i class=" " style="color:rgb(183, 0, 0)"> *
        <span style="color:lightgray">mandatory fields</span>
      </i>
    </div>
  </div>
  <div class="row p-1">
    <div class="col-6" *ngIf="showPdf">
      <ngx-extended-pdf-viewer *ngIf="pdfUrl != undefined" [src]="pdfUrl" [textLayer]="true" [height]="'800px'"
        [showHandToolButton]="true">
      </ngx-extended-pdf-viewer>
    </div>
    <div [class]="showPdf? 'col-6':'md-auto'">



      <div class="card">
        <div class="card-header">
          <span style="font-size: large; font-weight: 600">Please fill in the required fields </span>
        </div>
        <div class="card-body">
          <form class="" [formGroup]="form" (ngSubmit)="submitForm()">
            <div class=" row">
              <div class="col-4" *ngFor="let field of this.data.task.formDataFieldOptions">

                <ng-container [ngSwitch]="field.property.type.name">
                  <div *ngSwitchCase="'string'">
                    <div *ngIf="parseId(field.property.id)[0] == 'upload'">
                      <label [for]="field.property.id">{{ field.property.name }} &nbsp;
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <div class="btn-group" role="group" aria-label="Basic example"
                        style="color:white;background:#8e0101;">
                        <button class="btn" type="button" style="color:white;background:#8e0101;"
                          (click)=" upload()">Upload File</button>
                      </div>
                    </div>
                    <div *ngIf="parseIdForDropdown(field.property.id)[0] == 'dropdown'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select [formControlName]="field.property.id">
                          <mat-option *ngFor="let option of data.task.caseVariables[field.property.id + 'Options']"
                            [value]="option.id">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div
                      *ngIf="parseId(field.property.id)[1] == 'insurance' && parseId(field.property.id)[0] == 'select' && parseId(field.property.id)[2] == 'tpa' ">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <div class="btn-group" role="group" aria-label="Basic example"
                        style="color:white;background:#8e0101;">
                        <button class="btn" type="button" style="color:white;background:#8e0101;"
                          (click)="fileUploadComponent()"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)">Manage Insurance
                          </button>
                      </div>
                    </div>

                    <div
                      *ngIf="parseId(field.property.id)[1] == 'noncashless' && parseId(field.property.id)[0] == 'select' && parseId(field.property.id)[2] == 'soc'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <div class="btn-group" role="group" aria-label="Basic example"
                        style="color:white;background:#8e0101;">
                        <button class="btn" type="button" style="color:white;background:#8e0101;"
                          (click)="fileUploadComponent()"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)">Upload
                          File</button>
                      </div>
                    </div>

                    <div
                      *ngIf="parseId(field.property.id)[1] == 'insurance' && parseId(field.property.id)[0] == 'select' && parseId(field.property.id)[2] == 'company'  && parseId(field.property.id)[3] == 'tech'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <!-- tech modal to be added later  -->
                    </div>

                    <div
                      *ngIf="parseId(field.property.id)[1] == 'hospital' && parseId(field.property.id)[0] == 'select' && parseId(field.property.id)[2] == 'products'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <div class="btn-group" role="group" aria-label="Basic example"
                        style="color:white;background:#8e0101;">
                        <button class="btn" type="button" style="color:white;background:#8e0101;"
                          (click)="viewProductForm()"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)">Upload
                          File</button>
                      </div>
                    </div>

                    <div *ngIf="parseId(field.property.id)[1] == 'multi' && parseId(field.property.id)[0] == 'select'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field appearance="fill" class="example-full-width"
                        (change)="onChangeInsuranceCompanyId($event.target)">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select multiple [id]="field.property.id" [formControlName]="field.property.id"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let company of insuranceCompanyList" [value]="company | json">
                            {{ company.insuranceName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div
                      *ngIf="parseId(field.property.id)[2] == 'modal'  && parseId(field.property.id)[0] == 'select' ">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select [formControlName]="field.property.id" [id]="field.property.id"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let tpa of tpaList" [value]="tpa.id"> {{ tpa.tpaName }} </mat-option>
                        </mat-select>
                        <!-- <select class="form-select my-2" [formControlName]="field.property.id" [id]="field.property.id" [disabled]="parseExpression2(field.property.expression, field.property.id)"
                        [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                        <option *ngFor="let tpa of tpaList" [value]="tpa.id">
                          {{ tpa.tpaName }}
                        </option>
                      </select> -->
                      </mat-form-field>

                    </div>

                    <div *ngIf="parseId(field.property.id)[0] != 'select' && parseId(field.property.id)[0] != 'upload' 
                      && parseIdForDropdown(field.property.id)[0] != 'dropdown' && parseId(field.property.id)[2] != 'modal' 
                      && parseId(field.property.id)[4] != 'modal'">
                      <label [for]="field.property.id">{{
                        field.property.name
                        }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <input type="text" matInput [formControlName]="field.property.id" [id]="field.property.id"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [readonly]="!field.property.writable">
                      </mat-form-field>

                    </div>

                  </div>


                  <div *ngSwitchCase="'long'">
                    <label [for]="field.property.id">{{ field.property.name }}
                      <span class="required" *ngIf="field.property.required"></span>
                    </label>
                    <mat-form-field class="example-full-width"
                      *ngIf="parseType(field.property.id,field.property.type.name) == 'tel'">
                      <mat-label>{{ field.property.name }}</mat-label>
                      <input type="tel" matInput placeholder="555-555-1234" [formControlName]="field.property.id"
                        (change)="dataChange()"
                        [disabled]="parseExpression2(field.property.expression, field.property.id)">
                      <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                    </mat-form-field>

                    <mat-form-field class="example-full-width"
                      *ngIf="parseType(field.property.id,field.property.type.name) == 'number'">
                      <mat-label>{{ field.property.name }}</mat-label>
                      <input type="number" matInput placeholder="0" [formControlName]="field.property.id"
                        (change)="dataChange()"
                        [disabled]="parseExpression2(field.property.expression, field.property.id)"
                        [readonly]="!field.property.writable">
                      <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                    </mat-form-field>

                  </div>
                  <div *ngSwitchCase="'double'">
                    <label [for]="field.property.id">{{ field.property.name }}
                      <span class="required" *ngIf="field.property.required"></span>
                    </label>
                    <mat-form-field class="example-full-width"
                      *ngIf="parseType(field.property.id,field.property.type.name) == 'number'">
                      <mat-label>{{ field.property.name }}</mat-label>
                      <input type="number" matInput placeholder="0" [formControlName]="field.property.id"
                        (change)="dataChange()" (wheel)="($event).preventDefault()"
                        [disabled]="parseExpression2(field.property.expression, field.property.id)"
                        [readonly]="!field.property.writable" step="any">
                      <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'enum'">

                    <div *ngIf="parseId(field.property.id)[1] == 'multi' && parseId(field.property.id)[0] == 'select'">
                      <label [for]="field.property.id">{{field.property.name}}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select multiple [formControlName]="field.property.id" [id]="field.property.id"
                          [disabled]="parseExpression2( field.property.value, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let option of getEnumOptions(field.property.id)" [value]="option.id">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                    <div *ngIf="parseId(field.property.id)[1] != 'multi' && parseId(field.property.id)[0] != 'select' ">
                      <label [for]="field.property.id">{{field.property.name}}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select [formControlName]="field.property.id" [id]="field.property.id"
                          [disabled]="parseExpression2( field.property.value, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let option of getEnumOptions(field.property.id)" [value]="option.id">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>



                  </div>

                  <div *ngSwitchCase="'date'">
                    <label [for]="field.property.id">{{
                      field.property.name
                      }}</label>
                    <span class="required" *ngIf="field.property.required"></span>
                    <mat-form-field class="example-full-width">
                      <mat-label>{{ field.property.name }}</mat-label>
                      <input matInput [matDatepicker]="picker" (ngModelChange)="checkDate($event, field.property.id)"
                        [formControlName]="field.property.id" [id]="field.property.id"
                        [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}"
                        [disabled]="parseExpression2(field.property.expression, field.property.id)" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div *ngIf="showDateError && field.property.id =='dob'" style="color: red;">
                      Date in the future can not be selected
                    </div>


                  </div>


                </ng-container>
              </div>
            </div>
            <div *ngIf="this.data.task.name == 'eKYP Query'" class="p-3">
              <button type="button" [disabled]="addkyp == true" class="btn btn-outline-primary" (click)="
                      openAddForm(selectedTaskDetails)
                    ">
                Add
              </button>
            </div>

            <div class="btn-group" role="group" aria-label="Basic example" style="margin:5px">
              <button *ngIf="(data.task.name == 'Pending with Insurer'  || data.task.name == 'Pre Auth Approval') "
                class="btn" type="button" style="color:white;background:#8e0101" (click)="viewClaimStatus()">Check
                Claim Status
              </button>
              <!--<button class="btn" type="button" style="color:white;background:#8e0101;margin-left:1px"
                (click)="viewUploadedFileComponent()">View File</button>-->
              <button
                *ngIf="(data.task.name == 'Pre Auth' || data.task.name == 'Medical Scrutiny') && data.task.caseVariable?.patientPolicies != undefined"
                class="btn" type="button" style="color:white;background:#8e0101" (click)="openEkypForm()">View
                EKYP</button>

              <button class="btn" type="submit" style="color:white;background:#8e0101;margin-left:1px"
                [disabled]="(!form.valid && submitted == true )|| showDateError">Submit</button>


            </div>

          </form>
        </div>
      </div>
    </div>


    <div class="p-3">
      <mat-dialog-actions align="end">
        <button color="accent" mat-button mat-dialog-close>Close</button>
      </mat-dialog-actions>
    </div>