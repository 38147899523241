import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-bg-product",
  templateUrl: "./bg-product.component.html",
  styleUrls: ["./bg-product.component.scss"],
})
export class BgProductComponent {
  insuranceData: any = [];
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  displayedColumns: string[] = ["name", "description","qty","price","gst"];
  clicked: boolean = false;
  form: FormGroup = this.formBuilder.group({});
  bGProduct: any;
  message: any;
  uploadedData: any[] = [];
  length = 100;
  pageSize = 0;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  pageEvent: PageEvent = new PageEvent();
  rowNum = 0;
  size = 500;
  productList:any;
  selectedProduct: number[] = [];
  disabled = false;
 hospitalId: any;

  constructor(
    private HospitalRegistrationService: HospitalRegistrationService,
    private formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<BgProductComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      productId: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      qty: ["", Validators.required],
      negotiatedRate: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    console.log(this.data);
    this.getInsuranceCompanies();
    this.getHospitalInsurance();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  getInsuranceCompanies() {
    this.HospitalRegistrationService
      .getBgProdcut(this.rowNum, this.size)
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.bGProduct = data;
          this.selectedProduct.push(data.bgProductId);

          console.log("Get :" + JSON.stringify(data));
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  getHospitalInsurance() {
    this.HospitalRegistrationService
      .getBgProducttWithHospitalId(this.data.id)
      .subscribe({
        next: (data) => {
          console.log("....", data);
          this.uploadedData = data;
          this.dataSource.data = this.uploadedData;
          console.log("Get :" + this.dataSource.data);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  openViewFile(metaData: any) {
    console.log(metaData);
    // const dialogRef = this._dialog.open(ViewFileComponent,{
    //   data:{fileMetaData:metaData,fileType:fileType},

    // });
    // dialogRef.afterClosed().subscribe({
    //   next: (val)=> {
    //     if (val){}
    //   },
    // });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getHospitalInsurance();
  }

  click(bgProductId: any) {
    //console.log(selected);
    this.selectedProduct.push(bgProductId);
    console.log(bgProductId);
  }
  onFormSubmit() {
    var productdata = [{
      bgProductId: this.form.get("productId")?.value,
      hospitalId: this.data,
      startDate: new Date(this.form.get("startDate")?.value).toISOString(),
      endDate: new Date(this.form.get("endDate")?.value).toISOString(),
      qty: this.form.get("qty")?.value,
      negotiatedRate: this.form.get("negotiatedRate")?.value

    }];

    console.log("id", productdata);
    this.HospitalRegistrationService
      .associateProductwithHospital(productdata, this.data)
      .subscribe({
        next: (val: any) => {
          this.productList = val;
          this.toastrService.success("Product has been associated!");
         // this._dialogRef.close(true);
        },
        error: (err: any) => {
          // this.toastrService.success("Error");
          this.toastrService.error(JSON.stringify(err.error.error));

          console.error(err);
        },
      });
  }

  close() {
    if (this.productList == undefined) {
      this._dialogRef.close("");
    } else {
      this._dialogRef.close(this.productList);
    }
  }
}
