<div class="container-fluid pb-3 text-center">
    <div class="row justify-content-md-center">

        <div class="col ">
            <div class="p-5 container text-center">
                <a href="https://www.bimagarage.com"><img src="../../assets/img/logo.png"></a>


                <h1 class="display-8 ">Welcome to Bima<span style="color:#C00000;">Garage</span>!</h1>
                <p>
                    Please provide your email id. Once you submit, we will email you a pin. Please provide the pin
                    in the next section.
                </p>
                <p> Or <a href="#" class=" px-1 link-dark" routerLink="/login" routerLinkActive="active"
                        ariaCurrentWhenActive="page">Login</a>If you changed your mind. In any case you can call us
                    anytime on our toll free number +91 844 844 4158 </p>


            </div>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col col-lg-4 justify-content-md-center">
            <div class="container text-center shadow-lg p-3 bg-white rounded">
                <div class="p-3 text-sm">
                    <h2>Forgot Password</h2>

                </div>
                <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
                    <mat-step [stepControl]="emailForm">
                        <form [formGroup]="emailForm">
                            <ng-template matStepLabel>Fill out your email</ng-template>
                            <mat-form-field>
                                <mat-label>Email</mat-label>
                                <input matInput type="email" placeholder="Your email id " formControlName="email" required>
                            </mat-form-field>
                            <mat-error *ngIf="f.email.invalid">Please provide a proper email address.</mat-error>
                            <div>
                                <button mat-button (click)="onSubmit()" [disabled]="f.email.invalid" matStepperNext>Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="passwordForm">

                        <form [formGroup]="passwordForm">
                            <div class="spinner-border text-primary" *ngIf="loading" role="status">
                                <span class="visually-hidden">Please wait...</span>
                              </div>
                            <div class="alert alert-success mt-2 pt-1" role="alert" *ngIf="message != undefined">
                                {{message}}
                            </div>
                            <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="error != undefined">
                                {{error}}
                            </div>
                            <ng-template matStepLabel>Provide your PIN</ng-template>
                            <mat-form-field>
                                <mat-label>Pin</mat-label>
                                <input matInput type="number" length="4" formControlName="pin" placeholder="4 digit pin" required>

                            </mat-form-field>
                            <mat-error *ngIf="p.pin.invalid">Please provide a proper 4 digit pin</mat-error>
                            <mat-form-field>
                                <mat-label>New Password</mat-label>
                                <input type="password" matInput formControlName="password" placeholder="New password" required>
                            </mat-form-field>
                            
                            <div>
                                <button mat-button matStepperPrevious>Back</button>
                                <button mat-button *ngIf="!loading" matStepperNext>Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Done</ng-template>
                        <div class="spinner-border text-primary" *ngIf="loading" role="status">
                            <span class="visually-hidden">Please wait...</span>
                          </div>
                        <div class="alert alert-success mt-2 pt-1" role="alert" *ngIf="message != undefined">
                            {{message}}
                        </div>
                        <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="error != undefined">
                            {{error}}
                        </div>
                        <div>
                            <!--<button mat-button matStepperPrevious>Back</button>
                            <button mat-button (click)="stepper.reset()">Reset</button>-->
                            <button (click)="changePassword()" [disabled]="submitted" class="btn btn-danger btn-lg gap-2">
                                Change Password
                            </button>
                            <p>Please <a href="#" class=" px-1 link-dark" routerLink="/login" routerLinkActive="active"
                        ariaCurrentWhenActive="page">Login</a></p>
                        </div>
                    </mat-step>
                </mat-stepper>

            </div>
        </div>
    </div>

</div>