import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { first } from "rxjs";
import { ShopService } from "src/app/services/shop.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/services/admin.service";
import { CustomerService } from "src/app/services/customer.service";
import { MatDialog } from "@angular/material/dialog";

import Swal from "sweetalert2";
import { UpdateInsuranceCompanyComponent } from "./update-insurance-company/update-insurance-company.component";
import { AddInsuranceCompanyComponent } from "./add-insurance-company/add-insurance-company.component";
import { ManageInsuranceLogoComponent } from "./manage-insurance-logo/manage-insurance-logo.component";
import { environment } from "src/environments/environment.prod";
import { SearchService } from "src/app/services/search.service";
import { EmailConfComponent } from "../email-conf/email-conf.component";

@Component({
  selector: "app-manager-insurance-company",
  templateUrl: "./manager-insurance-company.component.html",
  styleUrls: ["./manager-insurance-company.component.scss"],
})
export class ManagerInsuranceCompanyComponent {
  apiUrl: string = environment.API_URL;
  [x: string]: any;
  displayedColumns: string[] = [
    "nameReg",
    "nameShort",
    "payorType",
    "claimSupportAddr",
    "claimSupportEmail",
    "claimSupportNumber",
    "logo",
    "status",
    "action",
    "updateLogo",
    "inactivate",
  ];
  couponForm: FormGroup;
  change: any;
  categories: any;
  tags: any;

  ngAfterViewInit() { }
  constructor(
    private shopService: ShopService,
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private customerService: CustomerService,
    private searchService:SearchService
  ) {
    this.couponForm = this._fb.group({
      // id: '',
      id: ["", Validators.required],
      nameReg: ["", [Validators.required, Validators.maxLength(10)]],
      nameShort: ["", Validators.required],
      claimSupportAddr: ["", [Validators.required, Validators.maxLength(300)]],
      claimSupportEmail: ["", Validators.required],
      claimSupportNumber: ["", Validators.required],
    });
  }
  ngOnInit(): void {
    this.getInsuranceCompanyMaster();
  }

  length = 100;
  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 50, 100];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  loading = false;
  message = undefined;
  error: any;
  submitted = false;
  icCompanies: any = [];
  rowNum = 0;
  size = 50;

  pageEvent: PageEvent = new PageEvent();
  dataSource = new MatTableDataSource<any>(this.icCompanies);
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getInsuranceCompanyMaster();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  getInsuranceCompanyMaster() {
    this.adminService
      .getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.icCompanies = data.result;
          this.length=data.total;
          this.pageSize = data.size;
          this.pageIndex = data.rowNum;
          //console.log("prod", data);
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No Insurance Companies available!";
          }
        },
      });
  }

  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "" && query.length >3) {
      this.search(query);
    } else {
      this.getInsuranceCompanyMaster();
    }
  }

  openEditForm(data: any) {
    //console.log(data);
    const dialogRef = this._dialog.open(UpdateInsuranceCompanyComponent, {
      disableClose:true,
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
          this.getInsuranceCompanyMaster();
        }
      },
    });
  }

  selectedIC: any;
  openLogoForm(data: any) {
    //console.log(data);
    this.selectedIC = data;
    const dialogRef = this._dialog.open(ManageInsuranceLogoComponent, {
      disableClose:true,
      minWidth: "60%",
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
          this.selectedIC.logo = val;
          console.log("Media ID " + val+" "+JSON.stringify(this.selectedIC));
          
          this.updateInsuranceData(this.selectedIC,val);

        }
      },
    });
  }

  test(a: { index: any }) {
    //console.log(a.index)
    this.change = a.index;
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddInsuranceCompanyComponent,{disableClose:true,});

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getInsuranceCompanyMaster();
        }
      },
    });
  }

  deleteIC(data: any) {
    console.log("fg", data);

    Swal.fire({
      title: "Are you sure want to inactivate this Insurance company?",
      //text: 'You will not be able to recover this imaginary file!',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, inactivate it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminService
          .deleteInsurance(data.id)
          .pipe(first())
          .subscribe({
            next: (data: any) => {
              this.toastrService.success(
                "Insurance company has been inactivated successfully"
              );

              //this.loading = false;
              this.getInsuranceCompanyMaster();
              //console.log("roles",data);
              // this.roles = data;
            },
            error: (error) => {
              console.log("error " + JSON.stringify(error));
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(error.error.error));

              this.message = error;

              //sthis.loading = false;
            },
          });

        // console.log('Clicked Yes, File deleted!');
      } else if (result.isDismissed) {
        //console.log('Clicked No, File is safe!');
      }
    });
  }

  updateInsuranceData(icData:any,id: any) {
    this.adminService
      .updateInsuranceCompany(icData, icData.id)
      .subscribe({
        next: (val: any) => {
          this.toastrService.success("Insurance company has been updated successfully");
          this.getInsuranceCompanyMaster();
          this.selectedIC = undefined;
        },
        error: (err: any) => {
          console.error(err);
          //this.toastrService.error("Error");
          this.toastrService.error(JSON.stringify(err.error));
        },
      });
  }


   
  


    
  ViewMailParser(element:any) {
    const dialogRef = this._dialog.open(EmailConfComponent, {
      data: { element},
      height:'80%',
      width:'80%',
      disableClose:true,

    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getInsuranceCompanyMaster();
        }
      },
    });
  }


  search(searchTerm:any){
    this
      .searchService.searchIC(searchTerm)
      .subscribe({
        next: (data: any) => {
          
          this.icCompanies = data;
        },
        error: (err: any) => {
          console.error(err);
          //this.toastrService.error("Error");
          this.toastrService.error(JSON.stringify(err.error));
        },
      });
  }
}
