import { Component, Inject, OnInit } from '@angular/core';
import { CashflowService } from '../services/cashflow.service';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: 'app-bg-ai',
  templateUrl: './bg-ai.component.html',
  styleUrl: './bg-ai.component.scss'
})
export class BgAiComponent implements OnInit {
  closed:boolean = false;
  disabled:boolean = false;
  searchTerm = new FormControl('');
  diagnosisInProgress:boolean = false;
  providedDiagnosis:any;
  constructor(
    private cashlessService:CashflowService,
    public MatDialogRef: MatDialogRef<BgAiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){

  }
  ngOnInit(): void {
    this.searchTerm.patchValue("What is the provisional diagnosis for "+this.data);
  }

  getDiagnosis(){
    this.diagnosisInProgress = true;
    console.log(this.searchTerm.value);
    this.cashlessService.getMedicalScrutinyDiagnosis(this.searchTerm.value)
    .subscribe(
      {
        next: (data: any) => {
          console.log(data);
          this.disabled = true;
          this.providedDiagnosis = data.provisionalDiagnosis;
          this.diagnosisInProgress = false;
        },
        error: (error: any) => {
          console.log(error);
        }
      }
    )
    ;
  }

  close(){
    this.MatDialogRef.close();
  }
}
