import { Component } from '@angular/core';

@Component({
  selector: 'app-add-medical-report',
  templateUrl: './add-medical-report.component.html',
  styleUrls: ['./add-medical-report.component.scss']
})
export class AddMedicalReportComponent {

}
