<div class="row p-3 py-4 bg-light">
  <div class="row bg-light">
    <div class="col-9 p-3 d-flex justify-content-start">
      <h1>Add details</h1>
    </div>

    <div class="col p-2 d-flex gap-2 justify-content-end">
      <h4>
        <i class="p-2" style="color: rgb(183, 0, 0)">* <span style="color: lightgray">marked fields are mandatory</span>
        </i>
        <mat-icon color="accent" (click)="close()">cancel</mat-icon>
      </h4>


    </div>
  </div>
  <div class="row p-3 ">
    <div class="col-1 p-2 text-secondary">
      <strong>UPLOADED SOC(s) </strong>
    </div>
    <div class="col-10">
      <app-attachment-scroller [data]="uploadedData" (fileSelected)="openViewFile($event)"
        (updateMediaList)="onMediaListUpdated($event)"></app-attachment-scroller>
    </div>
    <div class="col-1 pt-1 d-flex justify-content-end" style="color:#009ed3;">
      <div *ngIf="loading" class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

</div>
<div style="text-align: right; margin-right:10px;padding-top:10px;">
  <button mat-flat-button (click)="addRow()" class="btn">Add Row</button>
</div>

<mat-dialog-content class="mat-typography">

  <table mat-table [dataSource]="dataSourceInsurance">
    <ng-container matColumnDef="payor">
      <th mat-header-cell *matHeaderCellDef>Payor</th>
      <td mat-cell *matCellDef=" let element">
        <select class="form-select my-2" [(ngModel)]="element.payor" (change)="getBranchList(element)"
          [disabled]="element.isDisabled">
          <option [ngValue]="null" disabled selected>Select an insurance company</option>
          <option *ngFor="let insuranceCompany of insuranceCompanies" [ngValue]="insuranceCompany">
            {{ insuranceCompany.nameReg }}
          </option>
        </select>
      </td>
    </ng-container>

    <ng-container matColumnDef="branch">
      <th mat-header-cell *matHeaderCellDef>Branch</th>
      <td mat-cell *matCellDef=" let element">
        <select class="form-select my-2" [(ngModel)]="element.branch" [disabled]="element.isDisabled">
          <option *ngFor="let branch of element.branchList" [value]="branch">{{branch}}</option>
        </select>
      </td>
    </ng-container>

    <ng-container matColumnDef="dateRange">
      <th mat-header-cell *matHeaderCellDef>Valid From - Valid To</th>
      <td mat-cell *matCellDef="let element">
        <div class="date-range-container form-select">
          <mat-date-range-input [rangePicker]="dateRange" [disabled]="element.isDisabled">
            <input matStartDate placeholder="Start Date" [(ngModel)]="element.startDate">
            <input matEndDate placeholder="End Date" [(ngModel)]="element.endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="dateRange"></mat-datepicker-toggle>
          <mat-date-range-picker #dateRange></mat-date-range-picker>
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="blacklisted">
      <th mat-header-cell *matHeaderCellDef>Blacklisted</th>
      <td mat-cell *matCellDef="let element">
        <select [(ngModel)]="element.blacklisted" [disabled]="element.isDisabled"
          (change)="onChangeInsuranceCompanyblacklisted($event.target)" class="form-select my-2">
          <option value="true">Yes</option>
          <option selected value="false">No</option>
        </select>
      </td>
    </ng-container>

    <ng-container matColumnDef="submissionType">
      <th mat-header-cell *matHeaderCellDef>Submission Type</th>
      <td mat-cell *matCellDef="let element">
        <select [(ngModel)]="element.submissionType" [disabled]="element.isDisabled"
          (change)="onChangeInsuranceCompanyclaims($event.target)" class="form-select my-2">
          <option value="EMAIL">EMAIL</option>
          <option value="PORTAL">PORTAL</option>
          <option value="INTEGRATION">INTEGRATION</option>
        </select>
      </td>
    </ng-container>

    <ng-container matColumnDef="uploadSOC">
      <th mat-header-cell *matHeaderCellDef>Upload SOC</th>
      <td mat-cell *matCellDef="let element">
        <div class="content">
          <button type="button" mat-flat-button [disabled]="element.isDisabled || isRowEmpty(element)"
            [ngStyle]="{'background':(element.isDisabled || isRowEmpty(element)) ? '#f67777' : '#8e0101', 'color':'white'}"
            (click)="attachSOC(element)">Attach SOC</button>
          <mat-icon *ngIf="element.isDisabled">check_circle</mat-icon>
        </div>

        <!-- <input
                  class="form-control my-2"
                  accept="application/pdf"
                  type="file"
                  (change)="selectFile($event)"
                /> -->
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

  </table>


</mat-dialog-content>
<div style="text-align:right; margin-right: 10px;">
  <button mat-flat-button style="color: white; background: #8e0101" (click)="saveInsuranceTPA();">
    Submit
  </button>

</div>
  <!-- <div class="table-conatiner" *ngIf="existinginsuranceTPAList.data.length >0">
    <table class="table" mat-table [dataSource]="existinginsuranceTPAList" >
      <ng-container matColumnDef="payor">
        <th mat-header-cell *matHeaderCellDef>Payor</th>
        <td mat-cell *matCellDef="let element">{{element.insuranceName}}</td>

      </ng-container>

      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef>Branch</th>
        <td mat-cell *matCellDef="let element">{{element.branch}}</td> 

      </ng-container>

      <ng-container matColumnDef="dateRange">
        <th mat-header-cell *matHeaderCellDef>Valid From -Valid To</th>
        <td mat-cell *matCellDef="let element">{{element.dateCreated | date}} - {{element.dateUpdated |date}}</td>
      </ng-container>

      <ng-container matColumnDef="blacklisted">
        <th mat-header-cell *matHeaderCellDef>Blacklisted</th>
        <td mat-cell *matCellDef="let element">{{element.blacklisted}}</td>

      </ng-container>

      <ng-container matColumnDef="submissionType">
        <th mat-header-cell *matHeaderCellDef>Submission Type</th>
        <td mat-cell *matCellDef="let element">{{element.claimSubmissionMethod}}</td>
      </ng-container>

      <ng-container matColumnDef="uploadSOC">
        <th mat-header-cell *matHeaderCellDef> SOC</th>
        <td mat-cell *matCellDef="let element" (click)="openViewFile(element)">View</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    </table>
  </div> -->
   
<!-- <mat-dialog-content class="mat-typography">
  <div class="card">
    <div class="card-body">
      <form class="overflow-card" [formGroup]="form">
        <div class="container row">
          <div class="">
            <label for="selectInsuranceCompanies"
              >Select Insurance Company
            </label>
            <select
            class="form-select my-2"
            formControlName="selectInsuranceCompanies"
            [(ngModel)]="insuranceCompanyId"
            (change)="getBranchList()"
          >
            <option [ngValue]="null" disabled selected>Select an insurance company</option>
            <option *ngFor="let insuranceCompany of insuranceCompanies" [ngValue]="insuranceCompany">
              {{ insuranceCompany.nameReg }}
            </option>
          </select>

          </div>
          <div class="">
            <label>Branch</label>
            <select class="form-select my-2"
              formControlName = "branch">
              <option *ngFor="let branch of branchList" [value]="branch" >{{branch}}</option>
            </select>
          </div>
          <div class="">
            <label for="blacklisted">Select Blacklisted Type </label>
            <select
              (change)="onChangeInsuranceCompanyblacklisted($event.target)"
              class="form-select my-2"
              formControlName="blacklisted"
              id="blacklisted"
            >
              <option value="true">Yes</option>
              <option selected value="false">No</option>
            </select>
          </div>
          <div class="">
            <label for="selectedCategory">Select Category </label>
            <select
              class="form-select"
              required="true"
              formControlName="selectedCategory"
              id="selectedCategory"
              [(ngModel)]="selectedCategory"
              [compareWith]="compareWithFn"
            >
              <option
                *ngFor="let category of categoryList"
                [value]="category.id.categoryName"
              >
                {{ category.id.categoryName }}
              </option>
            </select>
          </div>
          <div class="">
            <label class="normal-font">Valid From </label>
            <input
              class="form-control"
              type="date"
              formControlName="startDate"
              [(ngModel)]="startDate"
            />
          </div>
          <div class="">
            <label class="normal-font">Valid To</label>
            <input
              class="form-control"
              type="date"
              formControlName="endDate"
              [(ngModel)]="endDate"
            />
          </div>
          <div class="">
            <label for="blacklisted"
              >Select Claim Submission Method Type
            </label>
            <select
              (change)="onChangeInsuranceCompanyclaims($event.target)"
              class="form-select my-2"
              formControlName="claimSubmissionMethod"
              id="claimSubmissionMethod"
            >
              <option value="EMAIL">EMAIL</option>
              <option value="PORTAL">PORTAL</option>
              <option value="INTEGRATION">INTEGRATION</option>
            </select>
          </div>
          <div>
            <label for="upload">Upload SOC</label>
            <div class="col">
              <label class="btn btn-default p-0">
                <input
                  class="form-control my-2"
                  accept="application/pdf"
                  type="file"
                  (change)="selectFile($event)"
                  formControlName="upload"
                />
              </label>
           
              <div mat-dialog-actions class="action">
                <button
                  mat-raised-button
                  type="button"
                  [mat-dialog-close]="false"
                >
                  Cancel
                </button>
                <button
                  mat-flat-button
                  style="color: white; background: #8e0101"
                  [disabled]="selectedFiles == undefined || clicked"
                  (click)="uploadFiles(); clicked = true"
                >
                  Submit
                </button>
                
              </div>
              <div *ngIf="selectedFiles" class="progress my-3">
                <div
                  class="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  attr.aria-valuenow="{{ progress }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }"
                >
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content> -->
<mat-dialog-actions align="end">
  <button color="accent" mat-flat-button  (click)="close()" >Close</button>
</mat-dialog-actions>