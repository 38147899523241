import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs";
import { AuthService } from "src/app/services/authService";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent {
  profileForm: FormGroup;
  orgId: any;
  user: any;

  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<EditProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // @Inject(MAT_DIALOG_DATA) public orgId: any, //private _coreService: CoreService
  ) {
    this.user = this.authService.userValue;

    console.log(JSON.stringify(data))

    this.profileForm = this._fb.group({
      id: "",
      firstName: ["", [Validators.required, Validators.maxLength(50)]],
      lastName: ["", [Validators.required, Validators.maxLength(50)]],
      emailId: ["", [Validators.required]],
      landingPage: ["home", [Validators.required]],
      address: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      country: ["", Validators.required],
      // zip:['', [Validators.required, Validators.maxLength(6)]],
      mobilePhone: [
        "",
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.profileForm.patchValue(this.data);

    //console.log(this.data)
  }

  getUser() {
    this.authService.getUserById(this.user.id).pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.user = data;
          console.log("users data", data);
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          //this.message = error;

          //sthis.loading = false;
        }
      });
  }

  onFormSubmit() {
    var data = {
      id: this.data.id,
      emailId: "",
      firstName: this.profileForm.value.firstName,
      lastName: this.profileForm.value.lastName,
      mobilePhone: this.profileForm.value.mobilePhone,
      //"userName": "",
      //"passwd": "",
      landline: "",
      userType: "",
      //"color": "",
      address: this.profileForm.value.address,
      city: this.profileForm.value.city,
      state: this.profileForm.value.state,
      country: this.profileForm.value.country,
      zip: this.profileForm.value.zip,
      inactive:this.data.inactive,
      landingPage:this.profileForm.value.landingPage
      //"inactive": false,
      //"orgId": ""
    };
    if (this.profileForm.valid) {
      if (data) {
        this.authService.updateProfile(data).subscribe({
          next: (val: any) => {
            //console.log("val", val);
            localStorage.setItem("user", JSON.stringify(val));



            this.toastrService.success(
              "User Profile has been updated successfully"
            );

            this._dialogRef.close(true);

          },
          error: (err: any) => {
            console.error(err);
            // this.toastrService.error("Error");
            this.toastrService.error(JSON.stringify(err.error.error));
          },
        });
      }
    } else {
      this.toastrService.error("Please enter valid details");
    }
  }
}
