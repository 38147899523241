import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { MatDialog } from "@angular/material/dialog";
import { EditOrgComponent } from "./edit-org/edit-org.component";

@Component({
  selector: "app-manage-org",
  templateUrl: "./manage-org.component.html",
  styleUrls: ["./manage-org.component.scss"],
})
export class ManageOrgComponent implements OnInit {
  orgs: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private _dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.getOrgs();
  }
  dataSource = new MatTableDataSource<any>(this.orgs);
  displayedColumns: string[] = [
    "Id",
    "Name",
    "Type",
    "SubType",
    "EMail",
    // "Address",
    "City",
    "State",
    // "Country",
    // "Zipcode",
    "Contact",
    "Action",
  ];
  loading: boolean = false;
  message: any;

  form!: FormGroup;
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getOrgs();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  getOrgs() {
    this.adminService
      .getOrgs(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.loading = false;
          //console.log(data);
          this.orgs = data;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          this.loading = false;
        },
      });
  }

  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.orgs =
        query === ""
          ? this.orgs
          : this.orgs.filter((item: any) => {
              return Object.values(item).some((val) =>
                String(val).toLowerCase().includes(query)
              );
            });
    } else {
      this.getOrgs();
    }
  }

  openEditForm(data: any) {
    const dialogRef = this._dialog.open(EditOrgComponent, {
      disableClose:true,
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getOrgs();
        }
      },
    });
  }
}
