import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDateRangeInput } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { NavigationExtras, Router } from "@angular/router";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { CashflowService } from "src/app/services/cashflow.service";
import { AddZoneComponent } from "./add-zone/add-zone.component";
import { UpdateZoneComponent } from "./update-zone/update-zone.component";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: 'app-manage-zone',
  //standalone: true,
  //imports: [],
  templateUrl: './manage-zone.component.html',
  styleUrl: './manage-zone.component.scss'
})
export class ManageZoneComponent implements AfterViewInit{
  selectedZone:any ="west";
  zoneName:any;
  zoneData: any = [];
  dataSource = new MatTableDataSource<any>(this.zoneData);
  @ViewChild(MatPaginator) paginator!:MatPaginator;
  displayedColumns = [
    "category",
    "insuranceCompanyId",
    "zoneRegion",
    "city",
    "branch",
    "insuranceEmailId",
    "action",
  ];
  constructor(
    private cashlessService: CashflowService,
    private router: Router,
    private _dialog: MatDialog,
    private adminService: AdminService
  ) {}
  ngAfterViewInit(): void {
    this.dataSource.paginator =this.paginator;
  }
  ngOnInit(): void {
    this.getZoneByZoneName(this.selectedZone);
  }


  getZoneByZoneName(zoneName : any) {
    this.adminService
      .getZoneByZoneName(zoneName)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.zoneData = data;
          this.dataSource.data = this.zoneData;
          this.dataSource.paginator = this.paginator;
          console.log("fsff", this.zoneData);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
        },
      });
  }

  

  setZone(event: any) {
    this.zoneName  = event.value;
    console.log("GDfg",this.zoneName);
    this.getZoneByZoneName(this.zoneName );
  }
  
  
  



  clear() {
    this.selectedZone= "";
  }

 
  openAddForm() {
    const dialogRef = this._dialog.open(AddZoneComponent,{disableClose:true,
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%',
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          //this.getCatergoryMaster();
          //this.getZoneByZoneName();
          this.getZoneByZoneName(this.selectedZone);

        }
      },
    });
  }

  openEditForm(data: any) {
    const dialogRef = this._dialog.open(UpdateZoneComponent, {
      disableClose:true,
      data,
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%',
      //width: '750px',
      // height: '200'
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          //this.getCatergoryMaster();
         // this.getTaskCalculations();
         this.getZoneByZoneName(this.selectedZone);


        }
      },
    });
  }
}
