import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";

const USER_KEY = "user";
@Injectable({ providedIn: "root" })
export class AdminService {
  constructor(private router: Router, private http: HttpClient) {}
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  requestOptions = { headers: this.headers };

  getPartnerTypes() {
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/partnerTypes?rowNum=0&size=20`,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getUserByMobileNumber(mobileNumber:any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/user/mobileNumber/`+mobileNumber,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getUsersOrgId(orgId: string, rowNum: number, size: number) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/users/` +
          orgId +~
          `?rowNum=` +
          rowNum +
          `&size=` +
          size,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getCountries() {
    return this.http.get("https://csc.sidsworld.co.in/api/countries").pipe(
      map((data) => {
        return data;
      })
    );
  }

  getStates(countryId: any) {
    return this.http
      .get("https://csc.sidsworld.co.in/api/states/" + countryId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCities(stateId: any) {
    return this.http
      .get("https://csc.sidsworld.co.in/api/cities/" + stateId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getOrgUsers(rowNum: number, size: number) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/org/users?rowNum=` +
          rowNum +
          `&size=` +
          size,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getUsersByManager(rowNum: number, size: number) {
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/managedUsers?rowNum=` +
          rowNum +
          `&size=` +
          size,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }
/*
  addhospitalMedia(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/hospitalmedia/add`, data,{
        reportProgress: true,
        observe: "events"
      })
      .pipe(catchError(this.errorHandle));
  }
*/
  getRoles(rowNum: number, size: number) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/org/roles?rowNum=` +
          rowNum +
          `&size=` +
          size,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getApplicationRoles(rowNum: number, size: number) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/application/roles?rowNum=` +
          rowNum +
          `&size=` +
          size,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  getOrgs(rowNum: number, size: number) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .get<any>(
        `${environment.API_URL}/auth/orgs?rowNum=` + rowNum + `&size=` + size,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  updateUser(id: number, data: any) {
    // return this.http.patch(`http://localhost:3000/employees/${id}`, data);
    return this.http.patch<any>(`${environment.API_URL}/auth/user`, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateLandingPage(url:any) {
    return this.http.patch<any>(`${environment.API_URL}/auth/landingPage?`+"landingPage="+url,{}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateDiscountCoupon(data: any) {
    return this.http
      .patch<any>(`${environment.API_URL}/hobnob/discountCoupon`, data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addDiscountCoupon(cred: any) {
    return this.http
      .post<any>(`${environment.API_URL}/hobnob/discountCoupon`, cred)
      .pipe(map((data) => {}));
  }

  updateProduct(data: any) {
    return this.http
      .patch<any>(`${environment.API_URL}/hobnob/products`, data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addProduct(cred: any) {
    return this.http
      .post<any>(`${environment.API_URL}/hobnob/products`, cred)
      .pipe(map((data) => {}));
  }

  getDiscountCoupon() {
    return this.http
      .get<any>(`${environment.API_URL}/hobnob/discountCoupons`)
      .pipe(catchError(this.errorHandle));
  }

  updateOrg(id: number, data: any) {
    return this.http.patch<any>(`${environment.API_URL}/auth/org`, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  uploadLogo(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append("files", file);

    const req = new HttpRequest(
      "POST",
      `${environment.API_URL}/auth/logo`,
      formData,
      {
        reportProgress: true,
        responseType: "json",
      }
    );
    return this.http.request(req);
  }

  uploadProfilePic(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append("files", file);

    const req = new HttpRequest(
      "POST",
      `${environment.API_URL}/auth/profilePic`,
      formData,
      {
        reportProgress: true,
        responseType: "json",
      }
    );
    return this.http.request(req);
  }

  uploadHealthFilesForUser(
    file: File,
    userId: any,
    selectedCategory: any
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append("files", file);

    const req = new HttpRequest(
      "POST",
      `${environment.API_URL}/auth/uploadFile/` +
        "health_cloud/" +
        selectedCategory +
        "/" +
        userId,
      formData,
      {
        reportProgress: true,
        responseType: "json",
      }
    );
    return this.http.request(req);
  }

  getLogo() {
    return this.http.get(`${environment.API_URL}/auth/logo`);
  }

 

  getProfilePic() {
    return this.http.get(`${environment.API_URL}/auth/profilePic`, {
      responseType: "blob",
    });
  }

  /*public uploadImage(files: any){
    const formData = new FormData();

    formData.append('files ', files);

   // return this.http.post('/api/v1/image-upload', formData);
   return this.http.post<any>(`${environment.API_URL}/auth/profilePic`, formData)

  }*/

  userregister(cred: any) {
    return this.http
      .post<any>(`${environment.API_URL}/auth/register`, cred)
      .pipe(map((data) => {}));
  }

  mobileRegister(cred: any) {
    return this.http
      .post<any>(`${environment.API_URL}/auth/mobile/register`, cred)
      .pipe(map((data) => {}));
  }

  getPrivileges() {
    return this.http
      .get<any>(`${environment.API_URL}/auth/privileges`)
      .pipe(catchError(this.errorHandle));
  }

  addRoles(cred: any) {
    return this.http
      .post<any>(`${environment.API_URL}/auth/application/roles`, cred)
      .pipe(map((data) => {}));
  }
  updateRole(data: any) {
    return this.http
      .patch<any>(`${environment.API_URL}/auth/application/roles`, data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateEKYP(data: any, taskId: any) {
    return this.http
      .post<any>(`${environment.API_URL}/bpmn/process/policy/` + taskId, data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateEKYPData(data: any) {
    return this.http.post<any>(`${environment.API_URL}/ekyp/manual`, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  addUserRoles(cred: any) {
    return this.http
      .post<any>(`${environment.API_URL}/auth/user/roles`, cred)
      .pipe(map((data) => {}));
  }

  addUserManager(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/auth/manager`, data)
      .pipe(map((data) => {}));
  }

  getUserRoleslById(userId: any): Observable<any> {
    // return this.http.get<any>(environment.API_URL_FOR_DETAILS + "?customer_id" + customerId )
    //return this.http.get<any>(environment.API_URL + "/auth/user/roles/" + userId)
    // return this.http.get<any>(environment.API_URL + "/auth/user/userId/roles")
    //return this.http.get<any>(environment.API_URL + "/auth/user/ ${userId}/roles")
    return this.http
      .get<any>(environment.API_URL + `/auth/user/${userId}/roles`)

      .pipe(catchError(this.errorHandle));
  }

  deleteRoles(roleName: string) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .delete<any>(
        `${environment.API_URL}/auth/application/roles?roleName=` + roleName
      )
      .pipe(catchError(this.errorHandle));
  }

  deleteProduct(productId: string) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .delete<any>(`${environment.API_URL}/hobnob/products/` + productId)
      .pipe(catchError(this.errorHandle));
  }

  // deleteUserRoles(data: any) {
  //   //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
  //   return this.http.delete<any>(`${environment.API_URL}/auth/user/roles` ,data)
  //     .pipe(
  //       catchError(this.errorHandle)
  //     );
  // }

  deleteUserRoles(userId: any, roleId: any) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .delete<any>(`${environment.API_URL}/auth/user/${userId}/role/${roleId}`)
      .pipe(catchError(this.errorHandle));
  }

  errorHandle(error: any) {
    let errorMessage = "";
    if (error.error.error instanceof ErrorEvent) {
      errorMessage = error.error.error;
    } else {
      errorMessage = error.error;
    }

    return throwError(() => {
      return errorMessage;
    });
  }

  manageHospital(keyword?: any): Observable<any> {
    return this.http
      .get<any>(
        environment.API_URL + "/onboarding/onboardedHospitals?filter=" + keyword
      )
      .pipe(catchError(this.errorHandle));
  }

  
  getBgProdcut(rowNum: number, size: number) {
    return this.http
      .get<any>(
        `${environment.API_URL}/bgproduct/list?rowNum=` +
          rowNum +
          `&size=` +
          size
      )
      .pipe(catchError(this.errorHandle));
  }

  // addBgProduct(data:any) {
  //   return this.http
  //     .post<any>(
  //       `${environment.API_URL}/bgproduct/` + data)
  //     .pipe(catchError(this.errorHandle));
  // }

  addBgProduct(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/bgproduct/add`, data)
      .pipe(map((data) => {}));
  }
  
  
  updateBgProduct(data: any,bgProductId: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/bgproduct/${bgProductId}`,data)
      .pipe(catchError(this.errorHandle));
  }

  deleteBGProduct(bgProductId: string) {
    return this.http
      .delete<any>(`${environment.API_URL}/bgproduct/` + bgProductId)
      .pipe(catchError(this.errorHandle));
  }

    
  getTPAMaster(rowNum: number, size: number) {
    return this.http
      .get<any>(
        `${environment.API_URL}/tpamaster/?rowNum=` +
          rowNum +
          `&size=` +
          size
      )
      .pipe(catchError(this.errorHandle));
  }


  // addTPAMaster(data:any) {
  //   return this.http
  //     .post<any>(
  //       `${environment.API_URL}/tpamaster` + data)
  //     .pipe(catchError(this.errorHandle));
  // }

   
  updateTPAMaster(data: any,Id: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/tpamaster/${Id}`,data)
      .pipe(catchError(this.errorHandle));
  }

  deleteTPAMaster(Id: string) {
    return this.http
      .delete<any>(`${environment.API_URL}/tpamaster/` + Id)
      .pipe(catchError(this.errorHandle));
  }

  getInsuranceCompanyMaster(rowNum: number, size: number) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http
      .get<any>(
        `${environment.API_URL}/insurance/list?rowNum=` +
          rowNum +
          `&size=` +
          size,
        this.requestOptions
      )
      .pipe(catchError(this.errorHandle));
  }

  
  addInsuranceCompany(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/insurance/add`, data)
      .pipe(map((data) => {}));
  }
  
  
  updateInsuranceCompany(data: any,id: any) {
    return this.http
      .patch<any>(
        `${environment.API_URL}/insurance/`+id,data)
      .pipe(catchError(this.errorHandle));
  }

  deleteInsurance(Id: string) {
    return this.http
      .delete<any>(`${environment.API_URL}/insurance/` + Id)
      .pipe(catchError(this.errorHandle));
  }


  getUserhospitalByUserId(userId: any): Observable<any> {
    return this.http
      .get<any>(environment.API_URL + `/auth/userHospital/${userId}`)

      .pipe(catchError(this.errorHandle));
  }

    
  // updateUserhospital(userId: any,hospitalId:any) {
  //   return this.http
  //     .patch<any>(
  //       `${environment.API_URL}/auth/userHospital/${userId}?hospitalId=` +hospitalId)
  //     .pipe(catchError(this.errorHandle));
  // }

  updateUserhospital(userId: any |null,hospitalId:any |null): Observable<any> {
    return this.http.patch<any>(`${environment.API_URL}/auth/userHospital/${userId}?hospitalId=`+hospitalId,{}).pipe(
      catchError(this.errorHandle)
    );  }


    deleteUserhospital(userId: any |null,hospitalId:any |null): Observable<any> {
      return this.http.delete<any>(`${environment.API_URL}/auth/userHospital/${userId}?hospitalId=`+hospitalId,{}).pipe(
        catchError(this.errorHandle)
      );  }
  
  
  addUserhospital(userId: number, hospitalId: number) {
    return this.http
      .post<any>(
        `${environment.API_URL}/auth/userHospital?userId=` +userId +`&hospitalId=` +hospitalId,{})
      .pipe(catchError(this.errorHandle));
  }


  getDeployedprocessByProcessDefKey(key: any): Observable<any> {
    return this.http
      .get<any>(environment.API_URL + `/bpmn/processes/deployed/tasks/${key}`)

      .pipe(catchError(this.errorHandle));
  }

  addCategoryMaster(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/auth/categoryMaster`, data)
      .pipe(map((data) => {}));
  }

  updateCategoryMaster(data: any) {
    return this.http
      .patch<any>(`${environment.API_URL}/auth/categoryMaster`, data)
      .pipe(map((data) => {}));
  }
  
  getAllCategories(){
    return this.http
    .get<any>(
      `${environment.API_URL}/auth/categoryMaster`
      
    )
    .pipe(catchError(this.errorHandle));
  }

  getTaskCalculations(processName:any,taskName:any,inactive:any){
    return this.http.get<any>(`${environment.API_URL}/extended/process/calculatedAttributes/`+processName+"/"+taskName+"/"+inactive)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  addTaskCalculation(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/extended/process/calculatedAttributes`, data)
      .pipe(map((data) => {}));
  }

  updateTaskCalculation(data: any) {
    return this.http
      .patch<any>(`${environment.API_URL}/extended/process/calculatedAttributes`, data)
      .pipe(map((data) => {}));
  }

  getUserHospital(userId:any){
    return this.http
    .get<any>(
      `${environment.API_URL}/auth/userHospital/`+userId
      
    )
    .pipe(catchError(this.errorHandle));
  }

  loginToExternalPortal(url:any, username: string, password: string): Observable<any> {
   

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      email: username,
      password: password
    };

    return this.http.post(url, body, { headers });
  }


  getZoneByZoneName(zoneName : any): Observable<any> {
    return this.http
      .get<any>(environment.API_URL + `/onboarding/insurance/zone/${zoneName }`)

      .pipe(catchError(this.errorHandle));
  }

  updateZone(data: any) {
    return this.http
      .patch<any>(`${environment.API_URL}/onboarding/insurance/zone`, data)
      .pipe(map((data) => {}));
  }

  addZone(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/onboarding/insurance/zone`, data)
      .pipe(map((data) => {}));
  }

  getEmailLookupList(){
    return this.http
    .get<any>(environment.API_URL +`/claims/email/lookup`)
    .pipe(catchError(this.errorHandle));
  }

  postEmailLookup(data:any){
    return this.http
    .post<any>(environment.API_URL +`/claims/email/lookup`,data)
    .pipe(catchError(this.errorHandle));
  }

  addEmailLookUpDetails(data:any){
    return this.http
    .post<any>(environment.API_URL +``,data)
    .pipe(catchError(this.errorHandle));
  }

  patchEmailLookup(data:any){
    return this.http
    .patch<any>(environment.API_URL +`/claims/email/lookup`,data)
    .pipe(catchError(this.errorHandle));
  }

  
}
