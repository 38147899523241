import { HttpErrorResponse, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { first, map, Observable } from "rxjs";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { CashflowService } from "src/app/services/cashflow.service";
import { FileService } from "src/app/services/file.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { TechComponent } from "./tech/tech.component";
import { AttachSOCComponent } from "./attach-soc/attach-soc.component";
interface TableElement {
  payor: any;
  branch: any;
  startDate:any;
  endDate:any;
  blacklisted:any;
  submissionType:any;
  uploadSOC:any;
  isDisabled:boolean;
  
}
@Component({
  selector: "app-insurance",
  templateUrl: "./insurance.component.html",
  styleUrls: ["./insurance.component.scss"],
})

export class InsuranceComponent {
  insuranceData: any = [];
  insuranceTPAList: any=[];
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  existinginsuranceTPAList = new MatTableDataSource(this.insuranceTPAList);
  displayedColumns: string[] = ["insuranceName", "socId", "dateCreated"];
  displayColumns= ['payor','branch','dateRange' ,'blacklisted','submissionType','uploadSOC'];
  dataSourceInsurance: TableElement[] = [
  ];
  clicked: boolean = false;
  form: FormGroup = this.formBuilder.group({});
  fileInfos?: Observable<any>;
  formData: FormData = new FormData();
  insuranceCompanyId: any;
  insuranceCompanies: any;
  message: any ="";
  selectedFiles?: any;
  selectedTaskDetails: any;
  uploadedData: any[] = [];
  length = 100;
  pageSize = 10;
  submitted: boolean = false;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  uploadedMediaData: any = [];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent: PageEvent = new PageEvent();
  blacklisted: any;
  insuranceTPAJson:any ={
    list:[]
  };
  blacklist:any;
  submission:any;
  claims: any;
  selectedCategory: any;
  categoryList: any;
  startDate: any;
  endDate: any;
  payorType: any = "INSURANCE";
  progress: any;
  loading: boolean = false;
  branchList:any[]=[];
  branchSelected:any;
  showPdf:any;
  pdfUrl:any;
  constructor(
    private hospitalRegistrationService: HospitalRegistrationService,
    private cashlessService: CashflowService,
    private fielService: FileService,
    private formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<InsuranceComponent>,
    private _dialog:MatDialog,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileService: FileService,

  ) {
    this.form = this.formBuilder.group({
      selectInsuranceCompanies: ["", Validators.required],
      branch:["",Validators.required],
      blacklisted: ["", Validators.required],
      selectedCategory: ["", Validators.required],
      claimSubmissionMethod: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      upload:["", Validators.required]
    });
  }

  ngOnInit(): void {
    console.log(this.data);
    // console.log("this.data" + this.data.taskName);
    if (this.payorType == "INSURANCE") {
      this.getInsuranceCompanies();
    } else {
      //this.getTpaCompanies();
    }
    this.getUploadedHospitalMedia();
    this.getHospitalInsurance();
    this.getCategory(this.data.sourceType);
  }

  getCategory(taskName: any) {
    console.log(taskName);
    this.cashlessService
      .getCategoryByTypeAndEntityType(taskName)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.categoryList = data;
          this.selectedCategory = this.categoryList[0].id.categoryName;
        },
      });
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  close() {
    if(!this.clicked && this.dataSourceInsurance.length>0){
      this.toastrService.info("Please click on submit to add the insurance details.");
    }else {
      if (this.message == "") {
        this._dialogRef.close("");
      } else if(this.message == "UPLOADED"){
        this._dialogRef.close("UPLOADED");
      }else{
        this._dialogRef.close("FAILED");
      }
    }
   
  }

  isRowEmpty(element: any) {
    return !element.payor || !element.startDate || !element.endDate || !element.blacklisted || !element.submissionType;
  }
  
  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
    console.log(this.selectedFiles);
  }
  compareWithFn(o1: any, o2: any) {
    console.log("compare with");

    return o1 === o2;
  }

  selectCategory(event: any): void {
    //console.log(event.target.value);
    this.selectedCategory = event.target.value;
  }

  addRow(){
    this.insuranceCompanyId ='';
    this.branchSelected = '';
    this.startDate = '';
    this.endDate = '';
    this.blacklist = '';
    this.submission = '';

    const row : TableElement ={
      payor:'',
      branch:'',
      startDate:'',
      endDate:'',
      blacklisted:'',
      submissionType:'',
      uploadSOC:'',
      isDisabled:false,
    };
    this.dataSourceInsurance = [row,...this.dataSourceInsurance]
  }
  // UPLOADING FILE Separate the attributes for uploading a file startDate,endDate,file, category
  attachSOC(element:any){
    var reqData = {
      "startDate":new Date(element.startDate).toISOString(),
      "endDate": new Date(element.endDate).toISOString(),
      "category":"WOKFLOW",
      "sourceType":this.data.sourceType,
      "entityType":"HOSPITAL",
      "entityId":this.data.entityId,
      "docType":"SOC_DOCUMENT",
      "socId": '',
    }
    
    const attachSOC = this._dialog.open(AttachSOCComponent,{
      data: reqData,
      disableClose:true,
      width:'50%', 
      height:'60%'
    });
    attachSOC.afterClosed()
    .subscribe({
      next:(data:any)=>{
          if(data){
           reqData.socId = data;
           //this.insuranceTPAJson = {...this.insuranceTPAJson , ...reqData};
           //this.insuranceTPAJson.list.push(reqData);
              //this.saveInsuranceTPA(data);
              element.isDisabled = true;
              console.log(this.insuranceTPAJson);
              this.addInsuranceTPAJson(element , data)
              this.getUploadedHospitalMedia();
          }
      }
    })
  }
addInsuranceTPAJson(reqData:any , socId:any){
  var submitData = {
    "hospitalOrgId": this.data.entityId,
    "insuranceId": reqData.payor.id,
    "blacklisted": reqData.blacklisted,
    "claimSubmissionMethod": reqData.submissionType,
    "inactive": "false",
    "payorType":"INSURANCE",
    "socId": socId,
    "branch":reqData.branch,
     };
  this.insuranceTPAJson.list.push(submitData);
}

getExternalMediaById(mediaId: any) {
  console.log(JSON.stringify(mediaId));
  this.loading = true;
  this.fileService
    .getExternalMedia(mediaId.id)
    .subscribe({
      next: (data: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.pdfUrl = e.target.result;
          this.showPdf = true;
          this.loading = false;
        }
        reader.readAsDataURL(data);
      },
      error: async (err: any) => {
        this.showPdf = false;
        this.loading = false;
        console.log("error");
        console.log(err);
      },
    });
}

getExistingHospitalInsuranceList(){

}


onMediaListUpdated(media:any){
  this.uploadedData = media;
}
 
  uploadFiles() {
    this.formData.append("startDate", new Date(this.startDate).toISOString());
    this.formData.append("endDate", new Date(this.endDate).toISOString());
    this.formData.append("file", this.selectedFiles);
    this.formData.append("category", "WORKFLOW");
    this.formData.append("sourceType", this.data.sourceType);/// put the the task defination name
    this.formData.append("entityType", "HOSPITAL");
    this.formData.append("entityId", this.data.entityId); /// put the hospital ID
    this.formData.append("docType", this.selectedCategory);


    this.fileService.addExternalMedia(this.formData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          console.log(event);
          this.selectedFiles = undefined
          //this.saveInsuranceTPA(event.body.id);

          // window.location.reload();
        } else if (event instanceof HttpErrorResponse) {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          this.toastrService.error(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
        }else{

        }
      })
    )
      .subscribe({
        next: (data: any) => {
        },
        error: (error) => {
          this.toastrService.success(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });
  }

  saveInsuranceTPA() {
    // var submitData = {
    //   "hospitalOrgId": this.data.entityId,
    //   "insuranceId": this.insuranceCompanyId.id,
    //   "blacklisted": this.blacklist,
    //   "claimSubmissionMethod": this.submission,
    //   "inactive": "false",
    //   "payorType": this.data.payorType,
    //   "socId": socId,
    //   "branch":this.branchSelected,
    // };
   
    this.hospitalRegistrationService.addHospitalInsurance(this.insuranceTPAJson.list).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          this.toastrService.success(this.selectedCategory, " File has been uploaded successfully");
          this.form.reset();
          this.clicked = true;
          this.progress = 0;
          this.selectedFiles = undefined;
          this.getHospitalInsurance();
          this.message = "UPLOADED"
          this.close();

        } else {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          // this.toastrService.error(
          //   this.selectedCategory,
          //   " file couldn't be uploaded. Try again!"
          // );
        }
      })
    )

      .subscribe({
        next: (data: any) => {
        },
        error: (error) => {
          this.toastrService.success(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });

  }



  onChangeInsuranceCompanyId(arg0: any) {
    if (arg0) {
      this.insuranceCompanyId = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }
  onChangeInsuranceCompanyblacklisted(arg0: any) {
    if (arg0) {
      this.blacklisted = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }
  onChangeInsuranceCompanyclaims(arg0: any) {
    if (arg0) {
      this.claims = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }

  getInsuranceCompanies() {
    this.hospitalRegistrationService.getInsuranceCompany().subscribe({
      next: (data: any) => {
        // console.log(data);
        this.insuranceCompanies = data;
        // console.log("Get Users by Manager Array:" + JSON.stringify(data));
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
      },
    });
  }

  getUploadedHospitalMedia() {
    this.fileService.getExternalMediaList(this.data.entityId, 'HOSPITAL', '').subscribe({
      next: (data: any) => {
        console.log(JSON.stringify(data));
        this.uploadedData = data;
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        this.message = error;
      },
    });
    // this.fileService
    //   .getExternalMediaList(
    //     this.data.id,
    //     this.pageIndex * this.pageSize,
    //     this.pageSize
    //   )
    //   .subscribe({
    //     next: (data) => {
    //       // console.log("....", data);
    //       this.uploadedData = data;
    //       // console.log("Get Users by Manager Array:" + JSON.stringify(data));
    //     },
    //     error: (error) => {
    //       // console.log("error " + JSON.stringify(error));
    //       this.message = error;
    //     },
    //   });
  }

  getHospitalInsurance() {
    this.loading = true;
    this.hospitalRegistrationService
      .gethospitalInsurance(
        this.data.entityId,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          console.log("....", data);
          this.existinginsuranceTPAList.data = data;
          this.loading = false;
          // this.dataSource.data = this.uploadedData;
          // console.log("Get Users by Manager Array:" + this.dataSource.data);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
          this.loading = false;
        },
      });
  }

 
  openViewFile(metaData: any) {
    console.log(metaData);
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose: true,
      data: { fileMetaData: metaData },
      width: "90%",
      // height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getHospitalInsurance();
  }

  getBranchList(element:any) {
    const selectedInsuranceId = element.payor.id; // Get ID from object
    if(!element.isDisabled){
      
      console.log("Selected Insurance ID:", selectedInsuranceId);
      console.log("Selected Insurance ID:", selectedInsuranceId);
       this.hospitalRegistrationService.getBranchList('',selectedInsuranceId).subscribe({
         next: (data: any) => {
           console.log("Branch List:", data);
           element.branchList = data;
         },
         error: (error) => {
           console.log("Error:", error);
           this.message = error;
         },
       });
    } 
 
   
  }




}
