import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs";
import { CustomerService } from "src/app/services/customer.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/services/authService";
import { ToastrService } from "ngx-toastr";
import { WorkflowService } from "src/app/services/workflow.service";

@Component({
  selector: "app-add-assign-task",
  templateUrl: "./add-assign-task.component.html",
  styleUrls: ["./add-assign-task.component.scss"],
})
export class AddAssignTaskComponent {
  assignForm: FormGroup;
  users: any;
  selectedTask: any;
  selectedUserId: any;
  user: any;
  constructor(
    private customerService: CustomerService,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<AddAssignTaskComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService,
    private workflowService:WorkflowService
  ) {
    this.selectedTask = data.data;
    console.log(data.roleName);
    this.assignForm = this._fb.group({
      task: ["",Validators.required],
    });
    this.getroles();
  }

  ngOnInit() {
    this.user = this.authService.userValue;
  }
  getroles() {
    this.customerService
      .getUserByRoleName(this.data.roleName)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          console.log("users", data);
          //this.users = data;
          const uniqueObjects = data.reduce((acc: any, item: any) => {
            if (!acc.some((obj: { id: any }) => obj.id === item.id)) {
              acc.push(item);
            }
            return acc;
          }, []);
          console.log("df", uniqueObjects);
          this.users = uniqueObjects;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
        },
      });
  }

  onFormSubmit() {

    console.log(this.data.data.id);
    console.log(this.selectedUserId);
    this.workflowService
      .assignTask(
        this.data.data.id,
        this.selectedUserId,
        new Date().toISOString()
      )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log("tasks " + JSON.stringify(data));
          this.toastrService.success(
            "Task has been assign",
            data.firstName + data.lastName
          );
          this._dialogRef.close("ASSIGNED");
          this.selectedTask = undefined;
        },
        error: (error: { error: any }) => {
          console.log(error);

        },
      });
  }
}
