import { Component } from '@angular/core';

@Component({
  selector: 'app-update-email-lookup',
  templateUrl: './update-email-lookup.component.html',
  styleUrl: './update-email-lookup.component.scss'
})
export class UpdateEmailLookupComponent {

}
