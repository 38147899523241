<div class="container">
  <div class="bg-white p-3" id="preAuthForm">
    <div
      class="row"
      *ngIf="
        insurance != undefined && insurance.logo != null;
        else other_content
      "
    >
      <div class="col text-start">
        <img
          width="10%"
          class="img-thumbnail"
          [src]="apiUrl + '/externalMedia/' + insurance.logo"
        />
      </div>
    </div>

    <div class="text-center">
      <h2>REQUEST FOR CASHLESS HOSPITALISATION FOR HEALTH INSURANCE POLICY</h2>
      <h2>PART C (Revised)</h2>
    </div>
    <form>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-2 col-form-label"
          >Name of the hospital</label
        >
        <div class="col-sm-9 col-lg-10">
          <ng-otp-input #ngOtpInput [config]="{ length: 45, allowNumbersOnly:false }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-2 col-form-label">
          Hospital location</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input #ngOtpInput [config]="{ length: 12 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Hospital Id</label
        >
        <div class="col-sm-9 col-lg-5">
          <ng-otp-input #ngOtpInput [config]="{ length: 39 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-2 col-form-label"
          >Hospital email</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input #ngOtpInput [config]="{ length: 30 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Rohini Id</label
        >
        <div class="col-sm-9 col-lg-5">
          <ng-otp-input #ngOtpInput [config]="{ length: 20 }"></ng-otp-input>
        </div>
      </div>
      <div class="separator mt-4">
        <div class="line"></div>
        <h2>TO BE FILLED BY INSURED/PATIENT</h2>
        <div class="line"></div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-2 col-form-label"
          >Name of the patient</label
        >
        <div class="col-sm-9 col-lg-10">
          <ng-otp-input #ngOtpInput [config]="{ length: 40 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Gender</label
        >
        <div class="col-sm-9 col-lg-1">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              value="option1"
              [checked]="jsonData['Gender'] == 'male'"
            />
            <label class="form-check-label" for="inlineCheckbox1">Male</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox2"
              value="option2"
              [checked]="jsonData['Gender'] == 'female'"
            />
            <label class="form-check-label" for="inlineCheckbox2">Female</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox3"
              value="option2"
              [checked]="
                jsonData['Gender'] != 'female' && jsonData['Gender'] != 'male'
              "
            />
            <label class="form-check-label" for="inlineCheckbox3"
              >Another</label
            >
          </div>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Contact no</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input #ngOtpInput [config]="{ length: 10 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Alternate Contact no</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input #ngOtpInput [config]="{ length: 10 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Age</label
        >
        <div class="col-sm-9 col-lg-2">
          <ng-otp-input [config]="{ length: 4 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Date Of birth</label
        >
        <div class="col-sm-9 col-lg-3">
          <ng-otp-input #ngOtpInput [config]="{ length: 12 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Insurer ID card no</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input #ngOtpInput [config]="{ length: 12 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label"
          >Policy number/Name of corporate</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input [config]="{ length: 17 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label"
          >Employee Id</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input [config]="{ length: 15 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-4 col-form-label"
          >Currently do you have any other medical claim/health
          Insurance:</label
        >
        <div class="col-sm-9 col-lg-2">
          <div class="col-auto">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="autoSizingCheck2"
              />
              <label class="form-check-label" for="autoSizingCheck2">
                Remember me
              </label>
            </div>
          </div>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-2 col-form-label"
          >Insurance Company Name</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input [config]="{ length: 15 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label">
          Give Details</label
        >
        <div class="col-sm-9 col-lg-9">
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label">
          Do you have a family physician, if yes: Name</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input #ngOtpInput [config]="{ length: 15 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label">
          Contact no
        </label>
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input #ngOtpInput [config]="{ length: 10 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label">
          Occupation of insured patient</label
        >
        <div class="col-sm-9 col-lg-9">
          <ng-otp-input [config]="{ length: 25 }"></ng-otp-input>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label">
          Address of insured patient</label
        >
        <div class="col-sm-9 col-lg-9">
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            >{{ jsonData["Current Address of the Insured Patient"] }}</textarea
          >
        </div>
      </div>
      <div class="separator mt-4">
        <div class="line"></div>
        <h2>TO BE FILLED BY THE TREATING DOCTOR/HOSPITAL</h2>
        <div class="line"></div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label">
          Name of the treating doctor</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input [config]="{ length: 18 }"></ng-otp-input>
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-1 col-form-label">
          Contact no</label
        >
        <div class="col-sm-9 col-lg-4">
          <ng-otp-input [config]="{ length: 10 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="col-6">
          <label for="exampleFormControlTextarea1" class="form-label">
            Name of Illness/disease with presenting complaints</label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            >{{
              jsonData["Nature of Illness/Disease with Presenting Complaints"]
            }}</textarea
          >
        </div>
        <div class="col-6">
          <label for="exampleFormControlTextarea1" class="form-label">
            Relevant clinical findings</label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            >{{ jsonData["Relevant Clinical Findings"] }}</textarea
          >
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label">
          Duration of the present ailment</label
        >
        <div class="col-sm-9 col-lg-4">
          <input
            type="text"
            class="form-control"
            value="{{ jsonData['Duration Of Present Ailments'] }}"
          />
        </div>
        <label for="nameOfHospital" class="col-sm-3 col-lg-2 col-form-label">
          Date of first consultation</label
        >
        <div class="col-sm-9 col-lg-3">
          <ng-otp-input [config]="{ length: 10 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-3 col-lg-3 col-form-label">
          Past history of present ailment if any</label
        >
        <div class="col-sm-9 col-lg-9">
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            >{{ jsonData["Past History of Present Ailment If any"] }}</textarea
          >
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="col-lg-9 col-sm-9">
          <label for="nameOfHospital"> Provisional Diagnosis</label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            >{{ jsonData["Past History of Present Ailment If any"] }}</textarea
          >
        </div>
        <div class="col-lg-3 col-sm-3">
          <label for="nameOfHospital" class=""> ICD 10 code</label>
          <ng-otp-input [config]="{ length: 10 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-lg-3 col-sm-3 col-form-label">
          Proposed line of treatment
        </label>
        <div class="col-sm-9 col-lg-9">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              value="option1"
              [checked]="
                jsonData['Proposed Line of Treatment'] == 'medicalManagement'
              "
            />
            <label class="form-check-label" for="inlineCheckbox1">
              Medical management</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              value="option1"
              [checked]="
                jsonData['Proposed Line of Treatment'] == 'surgicalManagement'
              "
            />
            <label class="form-check-label" for="inlineCheckbox1">
              Surgical management</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              value="option1"
              [checked]="
                jsonData['Proposed Line of Treatment'] == 'intensiveCare'
              "
            />
            <label class="form-check-label" for="inlineCheckbox1">
              Intensive care</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              value="option1"
              [checked]="
                jsonData['Proposed Line of Treatment'] == 'investigation'
              "
            />
            <label class="form-check-label" for="inlineCheckbox1">
              Investigation</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              value="option1"
              [checked]="
                jsonData['Proposed Line of Treatment'] ==
                'nonallopathicTreatment'
              "
            />
            <label class="form-check-label" for="inlineCheckbox1">
              Non-Allopathic treatment</label
            >
          </div>
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="col-lg-7 col-sm-7">
          <label for="nameOfHospital">
            If investigation and/or medical management, provide details</label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          >
          </textarea>
        </div>
        <div class="col-lg-5 col-sm-5">
          <label for="nameOfHospital" class=""
            >Route of drug administration</label
          >
          <div class="row">
            <div class="col-sm-6 col-lg-6">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                  [checked]="jsonData['Route of Drug Administration'] == 'iv'"
                />
                <label class="form-check-label" for="inlineCheckbox1">
                  IV</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                  [checked]="jsonData['Route of Drug Administration'] == 'oral'"
                />
                <label class="form-check-label" for="inlineCheckbox1">
                  Oral</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                  [checked]="
                    jsonData['Route of Drug Administration'] == 'other'
                  "
                />
                <label class="form-check-label" for="inlineCheckbox1">
                  Other</label
                >
              </div>
            </div>
            <div class="col-sm-6 col-lg-6">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="col-lg-9 col-sm-9">
          <label for="nameOfHospital"> If Surgical, name of surgery</label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          >
          </textarea>
        </div>
        <div class="col-lg-3 col-sm-3">
          <label for="nameOfHospital" class=""> ICD 10 PCS code</label>
          <ng-otp-input [config]="{ length: 10 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="col-lg-6 col-sm-6">
          <label for="nameOfHospital"
            >If other treatments provide details</label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          >
 {{ jsonData["Other Treatment Details"] }}</textarea
          >
        </div>
        <div class="col-lg-6 col-sm-6">
          <label for="nameOfHospital">How did injury occur</label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          >
          </textarea>
        </div>
      </div>
      <div class="form-group row mb-2">
        <label for="nameOfHospital" class="col-sm-2 col-lg-2 col-form-label">
          In case of accident
        </label>
        <div>
          <label for="nameOfHospital" class="col-sm-2 col-lg-2 col-form-label">
            Is it RTA
          </label>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              [checked]="jsonData['Is it RTA'] == 'yes'"
            />
            <label class="form-check-label" for="inlineCheckbox1">Yes </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              [checked]="jsonData['Is it RTA'] == 'no'"
            />
            <label class="form-check-label" for="inlineCheckbox1">No</label>
          </div>
        </div>
        <label for="nameOfHospital" class="col-sm-2 col-lg-2 col-form-label">
          Date of injury:
        </label>
        <div class="col-sm-1 col-lg-1">
          <ng-otp-input [config]="{ length: 10 }"></ng-otp-input>
        </div>
      </div>
      <div class="form-group row mb-2"></div>
      <div class="form-group row mb-2"></div>
    </form>
  </div>
</div>
