import { HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { first, map } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-add-hospital-seal',
  templateUrl: './add-hospital-seal.component.html',
  styleUrl: './add-hospital-seal.component.scss'
})
export class AddHospitalSealComponent implements OnInit {
  selectedFiles:any;
  clicked:any;

  constructor(
    private fileService:FileService,
    private toastrService:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  ngOnInit(): void {
    console.log(this.data);
   
  }

  selectFile(event:any){
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
  }
  progress:any;
  uploadFiles(){
    const formData = new FormData();
    formData.append('file',this.selectedFiles)
    this.fileService.addHospitalSeal(this.data,formData)
    .pipe(first())
    .subscribe(
      (event: any) => {
        console.log(event);
        this.toastrService.success("Hospital Seal Uploaded Successfully!")
      })
  }

}
