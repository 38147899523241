<!-- <div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1>Manage users Org - Smile Officers!</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
            <h4>
                <i class="px-3" style="color: lightgray">* marked fields are mandatory
                </i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div> -->


<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Manage users Org - Smile Officers!({{this.data.emailId}})</h1>
    </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <!-- <button
          type="button"
          class="btn btn-outline-primary"
          (click)="openAddRolesForm()"
        >
          Add
        </button> -->
        <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
            Add User hospital
          </button>
      </div>
    </div>
  </div>
  <mat-dialog-content>
    <div class=" p-4">
      <mat-table [dataSource]="filteredArr" matSort>
        <ng-container matColumnDef="orgd">
          <mat-header-cell *matHeaderCellDef>ORG Id</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.hospitalOrgId }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="userId">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.name}}</mat-cell>
        </ng-container>
<!--   
        <ng-container matColumnDef="roleDescription">
          <mat-header-cell *matHeaderCellDef>Role Description</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.desc }}
          </mat-cell>
        </ng-container> -->
  
        <ng-container matColumnDef="update">
            <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button style="color:#8e0101;" (click)="openEditForm(row)" matTooltip="Edit Form"
                matTooltipPosition="below">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          
          </ng-container>
          <ng-container matColumnDef="delete">
            <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button style="color:#8e0101;" (click)="deleteHospital(row)" matTooltip="Edit Form"
                matTooltipPosition="below">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

         
       
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>