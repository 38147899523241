import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CustomerService } from 'src/app/services/customer.service';
import {MatDatepickerModule} from '@angular/material/datepicker';

interface DynamicFormElement {
  name: string;
  comment: string; // Adjust the value type based on your needs (e.g., number, boolean)
}
interface Benefit {
  name: string;
  description: string;
}

@Component({
  selector: 'app-kyp-coupon',
  templateUrl: './edit-kyp.component.html',
  styleUrls: ['./edit-kyp.component.scss']
})
export class EditKypComponent implements OnInit{
  @ViewChild('myInput') myInputRef!: ElementRef<HTMLInputElement>;
   kypForm: FormGroup;
   errorMessage:string = "Please enter a valid value";
   displayedColumns =['name','comment']
   myForm!: FormGroup;
   membersForm!:FormGroup;
   insured_waiting_period!:FormGroup;
   sublimit_info!:FormGroup;
   comment_section!:FormGroup;
   benefits: Benefit[] = [];
   myBenefit!: FormGroup;
  orgId: any;
  user: any
  startDate: any;
  endDate: any;
  insured_waiting:any;
  sublimit_information:any;
  comment_sec:any;
  benefitForm!: FormGroup;
  // policyMembers: any;
  selectedType: number[] = [];
  associateArray!:FormArray;
  secondaryArray!:FormArray;
  ekypData: any;
  benefitsdata: any[] = [];
  policyMember:any[] =[];
  policyMembers:any
  benefitControls:any;
  order:any;
  options = new FormArray([]);
  type= new FormGroup({
    options: this.options
  })
  benefit : DynamicFormElement[] = []
  controlNames:any;


  trialForm!:FormGroup;


  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private _dialogRef: MatDialogRef<EditKypComponent>,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService:CustomerService,
    //@Inject(MAT_DIALOG_DATA) public order_id: any,
 
  ){ 
    
    console.log("order_id:"+JSON.stringify(this.data.policy));
    const policy = JSON.parse(this.data.policy);
    console.log("order_id:"+policy);
    this.order = policy[0];
    console.log(this.order)
    this.ekypData = JSON.parse(this.order['ekypData']);
    console.log(this.ekypData);
    if (this.data.data && this.data.data.hasOwnProperty('ekypData') ) {
      // this.ekypData =JSON.parse(this.data.data.ekypData);
      //  console.log(this.ekypData);
      
    }
   

    this.kypForm = new FormGroup({
      id: new FormControl(''),
      policy_number: new FormControl('', [Validators.required,]),
      policy_name: new FormControl('', [Validators.required,Validators.pattern(/^(([A-Za-z]+)(\s[A-Za-z]+)?)$/gm)]),
      policy_type: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      insurance_company: new FormControl('', [Validators.required, Validators.pattern(/^(([A-Za-z]+)(\s[A-Za-z]+)?)$/gm)]),
      insurance_name:new FormControl('',Validators.required),
      // policy_holder: new FormControl('', Validators.required),
     // holder_marker: new FormControl('', Validators.required),
      start_date: new FormControl('', [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[12]\d|3[01])[\/](19|20)\d{2}$/)]),
      end_date:new FormControl('', [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[12]\d|3[01])[\/](19|20)\d{2}$/)]),
      renewal_insurance:new FormControl('', Validators.required),
      // policyMembers: new FormControl('', Validators.required),
      ported: new FormControl('',Validators.required),
      // processed: new FormControl('', Validators.required),
      sum_insured: new FormControl('', Validators.required),

    });
    console.log(this.kypForm);

  }

  ngOnInit(): void {
    this.myBenefit = this._fb.group({
      items: this._fb.array([
        this._fb.group({
          name: ['', Validators.required],
          description: ''
        })
      ])
    });


    this.myForm = this._fb.group({
      kypData: this._fb.group({
        // ... your KYP data form controls here
      }),
      benefits: this._fb.array([])
    });
   
  

    
    
    // console.log("bhy", this.benefitsdata);
    // console.log("orgid", this.data.data);
    // console.log("ekyp", this.data.data.ekypData);

    // this.kypForm.patchValue(this.ekypData);
    console.log(this.data.policy.ekypData);
    console.log(this.ekypData?.policy_type);
    this.kypForm.patchValue({
      id:this.order.id,
      policy_number:this.ekypData.policy_number,
       policy_name: this.ekypData.policy_name,
      policy_type:this.ekypData.policy_type ,
       insurance_company: this.ekypData.insurance_company,
       insurance_name: this.ekypData.insurance_name,
      // policy_holder: this.ekypData.userId ,
       //holder_marker: this.ekypData.userId ,
       start_date: this.datePipe.transform(this.ekypData.start_date,'MM/dd/YYYY'),
       end_date:this.datePipe.transform(this.ekypData.end_date,'MM/dd/YYYY'),
       renewal_insurance:this.ekypData.start_date,
       ported:this.ekypData.ported,
       sum_insured: this.order.sumInsured,
    })

    this.endDate = this.kypForm.value.end_date
    console.log(this.kypForm.value);
   

    this.benefitForm = new FormGroup({
      dynamicData: new FormArray([])
    })
   

    // this.ekypData.benefits.forEach((benefit: any) => {
    //   this.benefitsdata.push(benefit);
     
    // })
    // this.createForm(this.ekypData);
    if (this.ekypData && this.ekypData.coverage_information) {
    this.ekypData.coverage_information.forEach((member:any)=>{
      // console.log("memeber:"+JSON.stringify(member));
      this.policyMember.push(member);
    })
  }else{
    console.log("error");
  }
    // console.log("Policy member:"+JSON.stringify(this.policyMember));
     
    //
   // const jsonData = this.data.data.ekypData;
    // const membersArray = JSON.parse(this.data.data.policyMembers);
   // console.log("Policy available benefits array:"+JSON.stringify(this.data.data.coverage_information));
    this.policyMembers = this.policyMembers as FormArray;
    this.insured_waiting= this.ekypData.waiting_period_information;
    // console.log(this.insured_waiting);
    this.comment_sec = this.ekypData.comments;
    // console.log("comments :"+JSON.stringify(this.comment_sec));
    this.sublimit_information =this.ekypData.sublimit_information;
     console.log("sublimit_info:"+JSON.stringify(this.sublimit_information));





// Assuming the member data array is stored in a variable named 'membersData'
      this.membersForm = new FormGroup({
        members: new FormArray([])
      });
      // console.log("memeber"+JSON.stringify(this.policyMembers));
      this.policyMember.forEach((member:any) => {
        
          this.associateArray = this.membersForm.get('members') as FormArray;
          this.associateArray.push(this.addMember(member));
      });

      // console.log("Policy members by new format:"+ (this.membersForm.get('members') as FormArray));

      this.insured_waiting_period = new FormGroup({
        insured:new FormArray([])
      });

      this.insured_waiting.forEach((insured:any)=>{
        this.associateArray = this.insured_waiting_period.get('insured') as FormArray;
        this.associateArray.push(this.addInsured(insured));
      });

      this.comment_section = new FormGroup({
        comment: new FormArray([])
      });

      this.comment_sec.forEach((comment:any)=>{
        this.associateArray = this.comment_section.get('comment') as FormArray;
        this.associateArray.push(this.addComment(comment));
      })

      this.sublimit_info = new FormGroup({
        sublimit_info : new FormArray([])
      })
      this.sublimit_information.forEach((value:any)=>{
        // this.sublimit_info.get('insured_name')?.setValue(value.insured_name);
        //   value.sublimit.forEach((sublimit:any)=>{
          this.associateArray = this.sublimit_info.get('sublimit_info') as FormArray;
          this.associateArray.push(this.addSublimit(value));
      // })
    })

       console.log(this.sublimit_info.value);
    }

  createForm(ekypdata:any){
       this.benefitsdata.forEach((x:any)=>{
        
        const newElement: DynamicFormElement = {
        name:x.name,
        comment:x.comment
      };
  
      this.benefit.push(newElement);
      const newFormGroup = this.createFormGroup(newElement);
      (this.benefitForm.get('dynamicData') as FormArray).push(newFormGroup);
     

    });  
  
    console.log("New form created :",this.benefitForm);    
    this.controlNames = Object.values(this.benefitForm.controls.dynamicData.value)

  }

  // get endDate() {
  //   return this.kypForm?.get('end_date')?.value;
  // }

  addMember(member:any){
    console.log("each member:"+JSON.stringify(member));
      return  new FormGroup({
        'sum_insured': new FormControl(member['sum_insured']),
        'super_bonus': new FormControl(member['super_bonus']),
        'insured_name': new FormControl(member['insured_name']),
        'bonus': new FormControl(member['bonus']),
        'restore':new FormControl(member['restore']),
        'effective_coverage': new FormControl(member['effective_coverage']),
        'dob': new FormControl(member['dob'])
      })
  }

  addInsured(insured:any){
    return new FormGroup({
      // 'Age (in Years)':new FormControl(insured['Age (in Years)']),
      // 'Gender': new FormControl(insured['Gender']),
      // 'Personal Waiting Period':new FormControl(insured['Personal Waiting Period']),
      // 'dob':new FormControl(insured['dob']),
      // 'name':new FormControl(insured['name']),
      // 'pre-existing_disease':new FormControl(insured['pre-existing_disease']),
      // 'relationship':new FormControl(insured['relationship'])
      'insured_name': new FormControl(insured['insured_name']),
      'pre_hospitalisation': new FormControl(insured['pre_hospitalisation']),
      'post_hospitalisation':new FormControl(insured['post_hospitalisation']),
      'waiting_period': new FormControl(insured['waiting_period'])
    });
  }

  addComment(comment:any){
    return new FormGroup({
      'name':new FormControl(comment['name']),
      'comment':new FormControl(comment['comment'])
    })
  }

  addSublimit(sublimit:any){
    const newSublimit = new FormGroup({
      'insured_name': new FormControl(sublimit['insured_name']),
      sublimit:new FormArray([])
    })
    sublimit.sublimit.forEach((value:any)=>{
        this.associateArray = newSublimit.get('sublimit') as FormArray;
        this.associateArray.push(this.addComment(value));
  });
    return newSublimit;
  }

  addNewComment(){
    //this.associateArray.clear();
    this.secondaryArray = this.comment_section.get('comment') as FormArray;
    this.secondaryArray.push(
     new FormGroup({
      'name':new FormControl(''),
      'comment':new FormControl('')
    })
  );
  }
  // addBenefitForPolicy(){
  //   const control = <FormArray>this.kypForm.controls['benefit'];
  //   control.push(
  //     new FormGroup({
  //       benefit_name: new FormControl(''),
  //       comment:new FormControl('')

  //     })
  //   )

  // }
  


  save(){
    console.log("Form values:"+JSON.stringify(this.ekypData.value));
  }

  
  get getMembers(){
    return this.membersForm.get('members') as FormArray;
  }

  get getComment(){
    return this.comment_section.get('comment') as FormArray;
  }

  get getInsured(){
    return this.insured_waiting_period.get('insured') as FormArray;
  }

  // get getSublimit(){
  //  let sublimits = this.insured_waiting_period.get('insured');
  //   return sublimits[0]?.get('sublimit') as FormArray;
  // }

   get getSublimitInfo(){
    return this.sublimit_info.get('sublimit_info') as FormArray;
   }

   getSubmlimitIndex(group:any){
    // console.log((group.sublimit));
     const arr = group as FormArray;
    //  console.log(arr.get('sublimit'));
     return arr.get('sublimit') as FormArray;

   }

  // addName(){
  //   const control = <FormArray>this.benefitForm.controls['dynamicData'];
   
  //   this.benefitsdata.forEach(x => {
  //    // control.push(this.patchValues(x.name, x.comment))
  //     control.push(
  //       new FormGroup({
  //         name:new FormControl(x.name),
  //         comment:new FormControl(x.comment)
  //       })
  //     )
  //   })
  // }
 

  // addBenefit(benefit: any) 
  // { 
  //   console.log("dg",benefit);
  //   const benefitGroup = new FormGroup({ 
  //   name: new FormControl(benefit.name), 
  //   comment: new FormControl(benefit.comment) }
  //   );
  //    (this.benefitForm.get('benefits') as FormArray).push(benefitGroup); 
    
  //   }

  createFormGroup(element: DynamicFormElement) {
    return new FormGroup({
      name: new FormControl(element.name),
      comment: new FormControl(element.comment)
    });
  }



  getBenefits(benefits: any) {
    var ff = JSON.parse(benefits);
    //console.log("EKYPDATA " + JSON.stringify(ff.benefits));
    return ff;
  }
  onFormSubmit() {
    // const value = this.membersForm.get('members') as FormArray;
    // console.log(this.membersForm.value);
  //   const jsonData = JSON.parse(this.data.ekypData);
  // const membersArray = jsonData.members;
  // console.log("Policy available benefits array:"+JSON.stringify(jsonData));
  // this.policyMembers = membersArray as FormArray;
  // console.log("Policy members:"+JSON.stringify(this.policyMembers));
  // console.log("Normal JSON:" +JSON.stringify(this.data));
    const array:any[] = [];
   
      // for (const benefit of this.controlNames) {
      //   const formData :any = {};
      //     formData[benefit.key] = benefit.value;
      //     array.push(formData);
      // }

    // console.log(this.controlNames);
      // const jsonString = JSON.stringify(formData);
      

      const date = new Date(this.kypForm.value.start_date);

      // console.log("JSON string:"+jsonString);
    // const formData = this.benefitForm.value; // Access entire form data
    // console.log("Updated form :",formData);
    var kypjson = {
      "id":this.data.data.id,
      //  "order_id":this.data.order_id,
      "memeber_name":this.data.member_name,
      "user_id" : this.data.data.userId,
      "ekypData":{
        // "benefits":this.controlNames,
        "start_date":this.datePipe.transform(this.kypForm.value.start_date, 'MMM dd yyyy'),
        "end_date":this.datePipe.transform(this.kypForm.value.end_date, 'MMM dd yyyy'),
        "insurance_company":this.kypForm.value.insurance_company,
        "insurance_name":this.kypForm.value.insurance_name,
        "ported":this.kypForm.value.ported,
        "policy_type":this.kypForm.value.policy_type,
        "policy_name":this.kypForm.value.policy_name,
        "policy_number":this.kypForm.value.policy_number,
        "coverage_information":this.membersForm.get('members')?.value,        
        "waiting_period_information":this.insured_waiting_period.get('insured')?.value,
        "sublimit_information":this.sublimit_info.get('sublimit_info')?.value,
        "comments":this.comment_section.get('comment')?.value
        
      },
      
     
      
    }

    console.log(kypjson);
    this.customerService.addkyp(kypjson).subscribe({
      next:(data:any)=>{
        this.toastrService.success("Updated ekyp information");
        console.log("Updated Data:"+JSON.stringify(data));
        this._dialogRef.close(data);
      },
      error:(error:any)=>{
        this.toastrService.error(JSON.stringify(error.error.error));
      }
    });
    
  }
  updateValue(index: number,newValue: string) {
    this.controlNames[index].value = newValue;
  }

  onBenefitSubmission(){
    console.log(this.myBenefit.controls)
  }

  get items(): FormArray {
    return this.myBenefit.get('items') as FormArray;
  }

  addItem() {
    this.items.push(this._fb.group({
      name: ['', Validators.required],
      description: ''
    }));
  }

 


}