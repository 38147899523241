<div class="container py-1">
  <div class="row justify-content-md-center">
    <div class="col">
      <div class="p-1 container text-center">
        <a href="https://www.bimagarage.com"
          ><img src="../../assets/img/logo.png"
        /></a>
        <h1 class="display-8">
          Welcome to Bima<span style="color: #c00000">Garage</span>!
        </h1>
        <hr />
        <div class="row">
          <div class="col-6 pt-2 text-center">
            <div class="h-100 p-5 rounded-3" style="background: #f0f0f0">
              <h2>Register as a Hospitals</h2>
              <p>
                Register to utilize our various services. If you are running a
                hospital, we offer cashless claims assistance.
              </p>

              <a
                mat-raised-button
                class="text-white"
                style="background-color: brown"
                href="#"
                routerLink="/orgRegistration"
              >
                REGISTER NOW</a
              >
            </div>
          </div>
          <div class="col-6 pt-2 text-center">
            <div class="h-100 p-5 rounded-3" style="background: #f0f0f0">
              <h2>Register as an eCommerce Store vendors</h2>
              <p>
                Register to utilize our various services. If you are a provider
                of health care merchandise or associated services then be on our
                ecommerce store.
              </p>

              <a
                mat-raised-button
                class="text-white"
                style="background-color: brown"
                href="#"
                routerLink="/vendorRegistration"
              >
                REGISTER NOW</a
              >
            </div>
          </div>
          <div class="col-6 pt-2 text-center">
            <div class="h-100 p-5 rounded-3" style="background: #eeeeee">
              <h2>Register as (Doctors, paitients, claims service)</h2>
              <p>
                Register to utilize various claims related services. As a doctor
                one would have access to all the patient details. As a Patient
                one would be able to store all their health information which
                can be shared with doctors or hospitals.Use our service to
                locate hospitals nearby.
              </p>
              <a
                mat-raised-button
                class="text-white"
                style="background-color: brown"
                href="#"
                routerLink="/userRegistration"
              >
                REGISTER NOW
              </a>
            </div>
          </div>
          <div class="col-6 pt-2 text-center">
            <div class="h-100 p-5 rounded-3" style="background: #eeeeee">
              <h2>Aggregator</h2>
              <p>
                Register to utilize various claims related services. As an
                aggregator where one could provide Cashless, Reimbursement
                services to hospital and patients.
              </p>
              <a
                mat-raised-button
                class="text-white"
                style="background-color: brown"
                href="#"
                routerLink="/aggregator"
              >
                REGISTER NOW
              </a>
            </div>
          </div>
        </div>
        <div class="p-3">
          <p>
            Or
            <a
              href="#"
              class="px-1 link-secondary"
              routerLink="/login"
              routerLinkActive="active"
              ariaCurrentWhenActive="page"
              ><b>Login</b></a
            >
            if you are already registered with us.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
