<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Add Techinal Insurance</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="card">
    <div class="card-body">
      <form class="overflow-card" [formGroup]="form">
        <div class="container row">
          <div class="">
            <label for="selectInsuranceCompanies"
              >Select Insurance Company <strong class="text-red pl-2">*</strong>
            </label>
            <select
              [(ngModel)]="insuranceCompanyId"
              class="form-select my-2"
              formControlName="selectInsuranceCompanies"
              id="selectInsuranceCompanies"
            >
              <option
                *ngFor="let insuranceCompany of insuranceCompanies"
                [value]="insuranceCompany.id"
              >
                {{ insuranceCompany.nameReg }}
              </option>
            </select>
          </div>
          <div class="">
            <label for="inHouse"
              >In House <strong class="text-red pl-2">*</strong>
            </label>
            <select
             
              class="form-select my-2"
              formControlName="inHouse"
              id="inHouse"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="">
            <label for="tpa"
              >Select TPA <strong class="text-red pl-2">*</strong>
            </label>
            <select
              class="form-select my-2"
              formControlName="tpa"
              id="tpa"
             
            >
              <option *ngFor="let tpa of tpaMasterList" [value]="tpa | json">{{tpa.tpaName}}</option>
              
            </select>
          </div>
          <div class="">
            <label for="blacklisted"
              >Select Blacklisted Type
              <strong class="text-red pl-2">*</strong></label
            >
            <select
              [(ngModel)]="blacklisted"
              class="form-select my-2"
              formControlName="blacklisted"
              id="blacklisted"
            >
              <option value="true">Yes</option>
              <option selected value="false">No</option>
            </select>
          </div>
          <div class="">
            <label for=""
              >Select Claim Submission Method Type
              <strong class="text-red pl-2">*</strong>
            </label>
            <select
              [(ngModel)]="claims"
              class="form-select my-2"
              formControlName="claimSubmissionMethod"
              id="claimSubmissionMethod"
            >
              <option value="EMAIL">EMAIL</option>
              <option value="PORTAL">PORTAL</option>
              <option value="INTEGRATION">INTEGRATION</option>
            </select>
          </div>
          <div class="">
            <label for="configdata"
              >Config Data <strong class="text-red pl-2">*</strong></label
            >
            <textarea
              formControlName="configdata"
              class="form-control"
              id="configdata"
              rows="3"
            ></textarea>
          </div>
          <div class="">
            <label for="inactive"
              >Select Status <strong class="text-red pl-2">*</strong></label
            >
            <select
              [(ngModel)]="inactive"
              class="form-select my-2"
              formControlName="inactive"
              id="inactive"
            >
              <option value="true">Active</option>
              <option selected value="false">Inactive</option>
            </select>
          </div>
          <div class="">
            <label class="normal-font">Valid From </label>
            <input
              class="form-control"
              type="date"
              formControlName="validFrom"
              [(ngModel)]="validFrom"
            />
          </div>

          <div class="">
            <label class="normal-font">Valid To</label>
            <input
              class="form-control"
              type="date"
              formControlName="validTo"
              [(ngModel)]="validTo"
            />
          </div>
          <div>
            <label for="upload">Upload SOC</label>
            <div class="col">
              <label class="btn btn-default p-0">
                <input
                  class="form-control my-2"
                  accept="application/pdf"
                  type="file"
                  formControlName="upload"
                  (change)="selectFile($event)"
                  id="upload"
                />
              </label>
              <ul class="list-group list-group-flush">
                <li
                  *ngFor="let file of fileInfos | async"
                  class="list-group-item"
                >
                  <a href="{{ file.url }}">{{ file.name }}</a>
                </li>
              </ul>
              <div mat-dialog-actions class="action">
                <button
                  mat-raised-button
                  type="button"
                  [mat-dialog-close]="false"
                >
                  Cancel
                </button>
                <button
                  mat-flat-button
                  style="color: white; background: #8e0101"
                  [disabled]="selectedFiles == undefined || clicked"
                  (click)="uploadFiles(); clicked = true"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
