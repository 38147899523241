import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdminService } from "src/app/services/admin.service";
import { ToastrService } from "ngx-toastr";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { first } from "rxjs";

@Component({
  selector: "app-update-insurance-company",
  templateUrl: "./update-insurance-company.component.html",
  styleUrls: ["./update-insurance-company.component.scss"],
})
export class UpdateInsuranceCompanyComponent {
  insuranceForm: FormGroup;
  orgId: any;
  user: any;
  status: any;
  automated: any;
  inhouse: any;
  emailConfig: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  selectedType: any;

  displayedColumns: string[] = [
    "id",
    "nameReg",
    "nameShort",
    "claimSupportAddr",
    "claimSupportEmail",
    "claimSupportNumber",
    "action",
    "delete",
  ];

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private HospitalRegistrationService: HospitalRegistrationService,
    private _dialogRef: MatDialogRef<UpdateInsuranceCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) // @Inject(MAT_DIALOG_DATA) public orgId: any,

  //private _coreService: CoreService
  {
    this.insuranceForm = this._fb.group({
      id: "",
      nameReg: [""],
      nameShort: [""],
      claimSupportAddr: [""],
      claimSupportEmail: [""],
      //Uom:['',Validators.required],
      claimSupportNumber: [""],
      sector: [""],
      insuranceCoverage: [""],
      claim: [""],
      website: [""],
      contactNumber: [""],
      inactive: [""],
      automated: [""],
      inhouse: [""],
      logo: [""],
      // mailParserConfs: [""],
    });
  }
  ngOnInit(): void {
    console.log("dsf", this.data);
    this.getEmailCong(this.data.id);//TODO
    this.status = this.data.inactive ? "Inactive" : "Activate";
    this.insuranceForm.controls["inactive"].setValue(this.status);

    this.automated =
      this.data.automated == false ? "Non Automated" : "Automated";
    //this.automated = this.data.automated?"No":"YEs"
    this.insuranceForm.controls["automated"].setValue(this.automated);

    this.inhouse = this.data.inhouse == false ? "Non-Inhouse" : "Inhouse";
    //this.automated = this.data.automated?"No":"YEs"
    this.insuranceForm.controls["inhouse"].setValue(this.inhouse);

    this.insuranceForm.controls["logo"].setValue(this.data.logo);

    this.insuranceForm.patchValue(this.data);
  }

  getEmailCong(hospitalOrgId:any) {
    this.HospitalRegistrationService
      .getEmailConfig(hospitalOrgId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          //console.log(data);
          this.emailConfig = data;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          // this.message = error;

          //this.loading = false;
        },
      });
  }

  onFormSubmit() {
    if (this.insuranceForm.valid) {
      var data = {
        id: this.data.id,
        nameReg: this.insuranceForm.value.nameReg,
        nameShort: this.insuranceForm.value.nameShort,
        sector: this.insuranceForm.value.sector,
        insuranceType: this.insuranceForm.value.insuranceType,
        insuranceCoverage: this.insuranceForm.value.insuranceCoverage,
        contactNumber: this.insuranceForm.value.contactNumber,
        website: this.insuranceForm.value.website,
        claim: this.insuranceForm.value.claim,
        claimSupportNumber: this.insuranceForm.value.claimSupportNumber,
        claimSupportEmail: this.insuranceForm.value.claimSupportEmail,
        claimSupportAddr: this.insuranceForm.value.claimSupportAddr,
        referenceId: 0,
        logo: this.insuranceForm.value.logo,
        // mailParserConfs: [this.insuranceForm.value.mailParserConfs],
       // insuranceProducts: [{}],
        automated: this.insuranceForm.value.automated,
        inhouse: this.insuranceForm.value.inhouse,
        inactive: this.insuranceForm.value.inactive,
        dateCreated: "",
        createdBy: "",
        dateUpdated: "",
        updatedBy: "",
      };
      if (this.data) {
        console.log(this.data);
        this.adminService 
          .updateInsuranceCompany(data, this.data.id)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success(
                "Insurance company has been updated successfully"
              );

              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              //this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));
            },
          });
      }
    }
  }
}
