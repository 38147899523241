<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Associate Products
            for {{ data.name }}</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="card">
      <div class="card-body">
        <form class="overflow-card" [formGroup]="form" (ngSubmit)="onFormSubmit()">
            <div class="p-2">
                <mat-form-field appearance="outline">
                    <mat-label>Select Product</mat-label>
                    <mat-select formControlName="productId" name="role">
                        <mat-option *ngFor="let product of bGProduct"
                            [value]="product.bgProductId" #matOption>
                            {{product.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Negotiated Rate</mat-label>
                    <input matInput placeholder="Rate" type="number" formControlName="negotiatedRate"
                        value="Sushi">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Qty</mat-label>
                    <input matInput placeholder="Quantity" type="number" formControlName="qty"
                        value="Sushi">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date"
                            formControlName="startDate">
                        <input matEndDate placeholder="End date" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <div mat-dialog-actions class="action">
                <button mat-raised-button type="button"
                    [mat-dialog-close]="false">Cancel</button>
                <button mat-raised-button color="primary" type="submit"
                    [disabled]="!form.valid">Save</button>

            </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button (click)="close()">Close</button>
  </mat-dialog-actions>