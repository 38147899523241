<div class="container py-1">
  <div class="row justify-content-md-center">
    <div class="col">
      <div class="p-1 container text-center">
        <a href="https://www.bimagarage.com"
          ><img src="../../assets/img/logo.png"
        /></a>
        <h1 class="display-8">
          Welcome to Bima<span style="color: #c00000">Garage</span>!
        </h1>
        <p>Please fill in your user details to start using our application!</p>
        <p>
          Or
          <a
            href="#"
            class="px-1 link-dark"
            routerLink="/login"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            >Login</a
          >if you are already registered with us.
        </p>
      </div>
    </div>
    <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel
            >Register as an eCommerce store user</ng-template
          >
          <div class="p-2">
            <mat-form-field>
              <mat-label>User Type</mat-label>
              <mat-select
                formControlName="firstCtrl"
                [(ngModel)]="selectedType"
                name="partner"
              >
                <mat-option
                  *ngFor="let partnerType of partnerTypes"
                  [value]="partnerType.id"
                >
                  {{ partnerType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="p-2 text-end">
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="form" label="User details">
        <form [formGroup]="form">
          <div class="row p-2">
            <div class="col">
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input
                  matInput
                  formControlName="firstName"
                  placeholder="Contact person's first name"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Second Name</mat-label>
                <input
                  matInput
                  formControlName="lastName"
                  placeholder="Contact person's last name"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>User Name</mat-label>
                <input
                  matInput
                  formControlName="userName"
                  placeholder="Contact person's User name"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input
                  matInput
                  formControlName="passwd"
                  placeholder="Password"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Confirm Password</mat-label>
                <input
                  matInput
                  placeholder="Confirm Password"
                  required
                  formControlName="confirmPassword"
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input
                  matInput
                  formControlName="email"
                  placeholder="Contact person's email"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Mobile</mat-label>
                <input
                  matInput
                  formControlName="mobileNumber"
                  placeholder="Contact person's mobile number"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input
                  matInput
                  formControlName="addLine1"
                  placeholder="Address"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>city</mat-label>
                <input
                  matInput
                  formControlName="city"
                  placeholder="City"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>state</mat-label>
                <input
                  matInput
                  formControlName="state"
                  placeholder="State"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Country</mat-label>
                <input
                  matInput
                  formControlName="country"
                  placeholder="Country"
                  required
                />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field>
                <mat-label>Pin</mat-label>
                <input
                  matInput
                  formControlName="zipcode"
                  placeholder="Pin code"
                  required
                />
              </mat-form-field>
            </div>
          </div>

          <div class="p-2 text-end">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext [disabled]="!form.valid">
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p>You are now done. Please click on the register button.</p>
        <div class="p-2 text-end">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="stepper.reset()">Reset</button>
          <button type="button" class="btn btn-danger" (click)="register()">
            Register
          </button>
        </div>
      </mat-step>
    </mat-stepper>
    <div
      class="alert alert-danger mt-2 pt-1"
      role="alert"
      *ngIf="message != undefined"
    >
      {{ message }}
    </div>
  </div>
</div>
