<!-- <div mat-dialog-title>
    <h1>Update Coupon</h1>
</div> -->
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Update Category</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
<form [formGroup]="categoryForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>category</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="category">
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>categoryName</mat-label>
                <input matInput type="text" placeholder="Product id" formControlName="categoryName">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>entityType</mat-label>
                <input matInput placeholder="Discount code" formControlName="entityType">
            </mat-form-field>
    
<!--         
            <mat-form-field appearance="outline">
                <mat-label>type</mat-label>
                <input matInput type="text" placeholder="type" formControlName="type" >
            </mat-form-field> -->

            
            <mat-form-field appearance="outline">
              <mat-label>{{type}}</mat-label>
              <mat-select formControlName="type" name="Data">
                <mat-option value="DROPDOWN"> DROPDOWN </mat-option>
                <mat-option value="FILE"> FILE </mat-option>
              </mat-select>
            </mat-form-field>  

            <mat-form-field appearance="outline">
                <mat-label>categoryDescription</mat-label>
                <input matInput type="email" placeholder="categoryDescription" formControlName="categoryDescription" >
            </mat-form-field>
        
        
        
            <mat-form-field appearance="outline">
                <mat-label>entitySubType</mat-label>
                <input matInput type="text" placeholder="entitySubType" formControlName="entitySubType" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Tags</mat-label>
                <input matInput type="text" placeholder="tags" formControlName="tags" >
            </mat-form-field>
                
      <mat-form-field appearance="outline">
        <mat-label>{{ status }}</mat-label>
        <mat-select formControlName="inactive" name="inactiveData">
          <mat-option value="true"> Inactive </mat-option>
          <mat-option value="false"> Activate </mat-option>
        </mat-select>
      </mat-form-field> 
        </div>
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
    </div>
</form>