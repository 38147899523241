<div class="row p-3">
    <div class="col-8 d-flex justify-content-start">
        <h1>Edit EKYP</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <mat-icon color="accent" >cancel</mat-icon>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <mat-dialog-content class="mat-typography">
            <div class="row pt-2">
                <div style="padding: 0">
                    <ngx-extended-pdf-viewer *ngIf="pdfUrl != undefined" [src]="pdfUrl" [textLayer]="true"
                        [showHandToolButton]="true">
                    </ngx-extended-pdf-viewer>
                    <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
                </div>
            </div>
        </mat-dialog-content>

    </div>
    <div class="col-6">
        
    </div>
</div>