<!-- <div mat-dialog-title>
    <h1>Update Insurance Company</h1>
</div> -->

<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Update</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
<form [formGroup]="insuranceForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <!-- <mat-form-field appearance="outline">
                <mat-label>HospitalOrgId</mat-label>
                <input matInput type="text" placeholder="hospitalOrgId"  formControlName="hospitalOrgId" readonly >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>PayorType</mat-label>
                <input matInput type="text" placeholder="payorType" formControlName="payorType" >
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>EmailHost</mat-label>
                <input matInput type="email" placeholder="EmailHost" formControlName="receivingEmailHost" [readonly]="true" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>EmailId</mat-label>
                <input matInput type="email" placeholder="EmailId" formControlName="receivingEmailId" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>EmailStoreType</mat-label>
                <input matInput type="email" placeholder="EmailStoreType" formControlName="receivingEmailStoreType" [readonly]="true">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Inactive</mat-label>
              <mat-select formControlName="inactive" name="inactiveData">
                <mat-option [value]=true> Inactive </mat-option>
                <mat-option [value]=false> Activate </mat-option>
              </mat-select>

          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Email Password</mat-label>
            <input matInput type="password" placeholder="App Password" formControlName="receivingEmailPassword">
        </mat-form-field>

       

     
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
        <button mat-raised-button color="primary" type="button" (click)="test()" [disabled]="!insuranceForm.value.receivingEmailPassword">Test</button>

    </div>
