import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/services/admin.service';
import { DetailsEmailLookupComponent } from './details-email-lookup/details-email-lookup.component';
import { AddEmailLookupComponent } from './add-email-lookup/add-email-lookup.component';


@Component({
  selector: 'app-manage-email-lookup',
  templateUrl: './manage-email-lookup.component.html',
  styleUrl: './manage-email-lookup.component.scss'
})
export class ManageEmailLookupComponent implements OnInit{
  emailLookup :any [] =[];
  displayedColumns =['senderType','taskDefKey','lookupPhrase','emailLookupStrategy', 'details' , 'edit'];
  dataSource = new MatTableDataSource(this.emailLookup);
  constructor(
    private adminService:AdminService,
    private _dialog:MatDialog,
  ){}
  ngOnInit(): void {
    this.getEmailLookupList();
  }

  getEmailLookupList(){
    this.adminService.getEmailLookupList()
    .subscribe((data:any)=>{
      console.log(data);
      this.emailLookup = data;
      this.dataSource.data = data;
    })
  }

  openAddForm(){
    const detailsPage  = this._dialog.open(AddEmailLookupComponent,{
      disableClose:true,
        width: '100vw',  // Sets the width to 100% of the viewport width
        maxWidth: '100vw',  // Ensures the max width doesn't override the width
        panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
        height: '100%',
        data: {
          type: 'add'
        }
        
    });
      detailsPage.afterClosed().subscribe({
        next :(val)=>{
          console.log(val);
          this.getEmailLookupList();
        }
      })
  
  }

  showDetails(element:any){
    const detailsPage  = this._dialog.open(DetailsEmailLookupComponent,{
      disableClose:true,
        width: '100vw',  // Sets the width to 100% of the viewport width
        maxWidth: '100vw',  // Ensures the max width doesn't override the width
        panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
        height: '100%',
        data:element.mailLookupDetails
    });
      detailsPage.afterClosed().subscribe({
        next :(val)=>{
          console.log(val);
        }
      })
  }

  editDetails(element:any){
    const detailsPage  = this._dialog.open(AddEmailLookupComponent,{
      disableClose:true,
        width: '100vw',  // Sets the width to 100% of the viewport width
        maxWidth: '100vw',  // Ensures the max width doesn't override the width
        panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
        height: '100%',
        data: {
          type: 'edit',
          mail:element
        }
        
    });
      detailsPage.afterClosed().subscribe({
        next :(val)=>{
          console.log(val);
          this.getEmailLookupList();
        }
      })

  }

}
