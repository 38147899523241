<div class="row p-4 bg-light">
  <div class="row bg-light">
    <div class="col-9 pt-3 d-flex justify-content-start ">
      <h1>
        <span style="color:#8e0101;">TASK </span>
        <span class="text-secondary">{{ data.task.name }}</span>
        <span style="color:#8e0101;">&nbsp;&nbsp;CASE ID </span>
        <span class="text-secondary">{{ data.task.processInstanceId }}</span>
      </h1>
      <br>

    </div>

    <div class="col pt-3 d-flex justify-content-end ">
      <button style="color:white;background:#8e0101;" mat-mini-fab (click)="toggleView()"><mat-icon>vertical_split</mat-icon></button>&nbsp;
      <button style="color:white;background:#8e0101;" mat-dialog-close mat-mini-fab><mat-icon>cancel</mat-icon></button>


    </div>
  </div>
  <div class="row p-3 ">
    <div class="col-1 pt-4 text-secondary bg-white">
      <strong>INFO </strong>
    </div>
    <div class="col p-2 bg-white ">
      <div class="horizontal-scroller ">
        <div class="scroll-content">
          <span *ngIf="data.task.caseVariables.policyNumber" style="margin:10px" class="item"> <strong>Policy Number: </strong>{{data.task.caseVariables.policyNumber}}</span>
          <span  *ngIf="data.task.caseVariables.hospitalName" style="margin:10px" class="item"><strong>Hospital Name: </strong>{{data.task.caseVariables.hospitalName}}</span>
          <span  *ngIf="data.task.caseVariables['select-primary-insurance-company-name-modal']" style="margin:10px" class="item"><strong>Insurance Company :</strong> {{selectedInusranceCompany?.[0]?.nameReg}}</span>
          <span   *ngIf="data.task.caseVariables.tpa" style="margin:10px" class="item"><strong>TPA :</strong> {{selectedInusranceCompany?.[0]?.nameReg}}</span>
          <span  *ngIf="data.task.caseVariables.sumExpectedCost" style="margin:10px" class="item"><strong>Estimated Cost :</strong> {{data.task.caseVariables.sumExpectedCost}}</span>
          <span  *ngIf="data.task.caseVariables.claimNumber" style="margin:10px" class="item"><strong>Claim Number:</strong> {{data.task.caseVariables.claimNumber}}></span>
          <span  *ngIf="data.task.caseVariables.preAuthApprovalAmount" style="margin:10px" class="item"><strong>Approved Amount :</strong> {{data.task.caseVariables.preAuthApprovalAmount}}</span>
          <span  *ngIf="data.task.caseVariables.preAuthApprovalComment" style="margin:10px" class="item"><strong>Approval Comment :</strong> {{data.task.caseVariables.preAuthApprovalComment}}</span>
        </div>
      </div>
    </div>
   
  </div>
  
  <div class="row p-3 ">
    <div class="col-2 p-2 text-secondary">
      <strong>ATTACHMENT(s) </strong>
    </div>
    <div class="col-10 ">
      <app-attachment-scroller [data]="uploadedData" (fileSelected)="getExternalMediaById($event)" (updateMediaList)="onMediaListUpdated($event)"></app-attachment-scroller>
    </div>
    <!-- <div class="col-1 pt-1 d-flex justify-content-end" style="color:#009ed3;">
      <div *ngIf="loading " class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> -->
  </div>
  <div class="row">
    <div class="col">
      <mat-tab-group>
        <mat-tab label="Previous Details">  
          <mat-accordion *ngIf="this.data.task.name != 'New admission'">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Information</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let task of taskDetails; let i = index">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ task.task_name }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row" *ngFor="let field of task.task_data">
                    <div class="col">
                      <b>{{field.name}}</b>
                    </div>
                    <div class="col">
                      {{field.value}}
                    </div>
                  </div>
    
                </mat-expansion-panel>
              </mat-accordion>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>
        <mat-tab label="Case Diagram">
          <mat-accordion style="margin: 10px;"
            *ngIf="isPrivileged(['ADMIN','SUPERADMIN','CAN_VIEW_WF_DIAGRAM'])">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Workflow
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="card-body" *ngIf="diagram != undefined">
                <img [src]="diagram" class="img-fluid" />
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>

        
      </mat-tab-group>
    </div>
    
  </div>
  <div class="row pt-2">
    <div class="col d-flex justify-content-end ">
      <i class=" " style="color:rgb(183, 0, 0)"> *
        <span style="color:lightgray">mandatory fields</span>
      </i>
    </div>
  </div>
</div>
<div class="row p-1" *ngIf="this.data != undefined">
  <div class="col-6" *ngIf="showPdf">
    <ngx-extended-pdf-viewer *ngIf="pdfUrl != undefined" [src]="pdfUrl" [textLayer]="true" [height]="'800px'"
      [showHandToolButton]="true">
    </ngx-extended-pdf-viewer>
  </div>
  <div [class]="showPdf? 'col-6':'md-auto'">
    <div class="p-2">
      <br>
      <div class="overlay" *ngIf="formSubmissionProgress || showLoading">
        <div class="spinner"></div>
      </div>
      <div class="card">
        <div class="card-header">
          <span style="font-size: large; font-weight: 600">Please fill in the required fields </span>
        </div>
        <div class="card-body">
          <form class="" #myForm="ngForm" [formGroup]="form" (ngSubmit)="submitForm()"  >
           
            <div *ngIf="searchFields.length > 0">
              <div class="fieldset-alternative">
                <span class="legend">LookUp Details</span>
                <div class="lookup-fields-container">
                  <div *ngFor="let field of searchFields"  class="lookup-field" >
                    <label [for]="field.property.id">
                      {{ field.property.name }}
                      <span class="required" *ngIf="field.property.required">*</span>
                    </label>
                    <mat-form-field class="example-full-width">
                      <mat-label>{{ field.property.name }}</mat-label>
            
                      <div class="input-with-spinner">
                        <input
                          type="text"
                          matInput
                          [formControlName]="field.property.id"
                          (change)="lookupForPatient(field.property.id)"
                          [id]="field.property.id"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                        />
                        <!-- <div *ngIf="showLoading" class="spinner-grow spinner-inline" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div> -->
                      </div>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            
          
            <div class=" row">
             
              <div class="col-4" *ngFor="let field of otherFields">
                <ng-container [ngSwitch]="field.property.type.name">
                  <div *ngSwitchCase="'string'">
                    <!-- <div *ngIf="parseIdForDropdown(field.property.id)[1] == 'search'">
                    <div class="lookUp">
                      <div class="fieldset-alternative">
                        <span class="legend">LookUp Details</span>
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                       
                        <div class="input-with-spinner">
                          <input
                            type="text"
                            matInput
                            [formControlName]="field.property.id"
                            (change)="lookupForPatient(field.property.id)"
                            [id]="field.property.id"
                            [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          />
                          <div *ngIf="showLoading" class="spinner-grow spinner-inline" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </mat-form-field>
                      </div>
                    </div>
                    </div> -->

                    <div *ngIf="parseId(field.property.id)[0] == 'upload'" class="p-3">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                        
                      </label> &nbsp;
                      <div class="btn-group" role="group" aria-label="Basic example"
                        style="color:white;background:#8e0101;">
                        <button class="btn" type="button" style="color:white;background:#8e0101;"
                          (click)="fileUploadComponent()">Upload File</button>
                      </div>
                      <mat-icon *ngIf="fileExists" style="color: green; padding-top: 2px;">check_circle</mat-icon>
                    </div>
                 
                    <div *ngIf="parseIdForDropdown(field.property.id)[0] == 'dropdown'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select [formControlName]="field.property.id" #formControl [id]="field.property.id">
                          <mat-option *ngFor="let option of data.task.caseVariables[field.property.id + 'Options']"
                            [value]="option.id">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!-- <div *ngIf="parseId(field.property.id)[0] == 'patientName'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select [formControlName]="field.property.id"  [id]="field.property.id"  (selectionChange)="onPatientSelected($event.value)">
                          <mat-option *ngFor="let option of memberName"
                            [value]="option.id">
                            {{ option.memberName }}
                          </mat-option>
                        </mat-select>
                       
                      </mat-form-field>
                     
                    </div> -->


                    <div
                      *ngIf="parseId(field.property.id)[1] == 'primary' && parseId(field.property.id)[0] == 'select' && parseId(field.property.id)[2] == 'insurance' ">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select (selectionChange)="onChangeInsuranceCompanyId($event)" #formControl
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [formControlName]="field.property.id" [id]="field.property.id"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let company of insuranceCompanyMasterData"  [value]="company.id">
                            {{ company.nameReg }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div
                      *ngIf="parseId(field.property.id)[1] == 'multi' && parseId(field.property.id)[0] == 'select' && parseId(field.property.id)[2] == 'partial'  && parseId(field.property.id)[3] == 'settlement'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select  #formControl
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [formControlName]="field.property.id" [id]="field.property.id"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let category of category" [value]="category.id.categoryName">
                            {{ category.id.categoryName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="parseId(field.property.id)[1] == 'multi' && parseId(field.property.id)[0] == 'select' &&  parseId(field.property.id)[2] == 'hospital'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field appearance="fill" class="example-full-width"
                        (change)="onChangeInsuranceCompanyId($event.target)">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select multiple [id]="field.property.id" [formControlName]="field.property.id" #formControl
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let company of insuranceCompanyMasterData" [value]="company.id">
                            {{ company.nameReg }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div
                      *ngIf="parseId(field.property.id)[2] == 'modal'  && parseId(field.property.id)[0] == 'select' ">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select [formControlName]="field.property.id" [id]="field.property.id" #formControl
                          [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let tpa of tpaList" [value]="tpa.id"> {{ tpa.tpaName }} </mat-option>
                        </mat-select>
                        <!-- <select class="form-select my-2" [formControlName]="field.property.id" [id]="field.property.id" [disabled]="parseExpression2(field.property.expression, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <option *ngFor="let tpa of tpaList" [value]="tpa.id">
                            {{ tpa.tpaName }}
                          </option>
                        </select> -->
                      </mat-form-field>

                    </div>

                    <div *ngIf="parseId(field.property.id)[0] != 'select' && parseId(field.property.id)[0] != 'upload' 
                        && parseIdForDropdown(field.property.id)[0] != 'dropdown' && parseId(field.property.id)[2] != 'modal' 
                        && parseId(field.property.id)[4] != 'modal'  && parseId(field.property.id)[2] != 'partial' &&
                        parseIdForDropdown(field.property.id)[0] != 'lookup'  && parseIdForDropdown(field.property.id)[1] != 'search'
                         && parseId(field.property.id)[3] != 'settlement'">
                      <label [for]="field.property.id">{{
                        field.property.name
                        }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <input type="text" matInput [formControlName]="field.property.id" [id]="field.property.id"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)" #formControl
                          [readonly]="!field.property.writable">
                      </mat-form-field>

                    </div>

                  </div>


                  <div *ngSwitchCase="'long'">
                    <div *ngIf="parseIdForDropdown(field.property.id)[0] == 'lookup'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width"
                        *ngIf="parseType(field.property.id,field.property.type.name) == 'tel'">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <input type="tel" matInput placeholder="555-555-1234" [formControlName]="field.property.id"
                          (change)="lookupForPatient($event)" #formControl [id]="field.property.id"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)">
                        <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                        <div class="col-1 pt-1 d-flex justify-content-end" style="color:#009ed3;">
                          <div *ngIf="showLoading" class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </mat-form-field>
                    </div>

                   
                    <div  *ngIf="parseIdForDropdown(field.property.id)[0] != 'lookup'">
                      <label [for]="field.property.id">{{ field.property.name }}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width"
                        *ngIf="parseType(field.property.id,field.property.type.name) == 'tel'">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <input type="tel" matInput placeholder="555-555-1234" [formControlName]="field.property.id"
                          (change)="dataChange()" #formControl [id]="field.property.id"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)">
                        <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                      </mat-form-field>

                      <mat-form-field class="example-full-width"
                      *ngIf="parseType(field.property.id,field.property.type.name) == 'number'">
                      <mat-label>{{ field.property.name }}</mat-label>
                      <input type="number" matInput placeholder="0" [formControlName]="field.property.id"
                        (change)="dataChange()" #formControl [id]="field.property.id"
                        [disabled]="parseExpression2(field.property.expression, field.property.id)"
                        [readonly]="!field.property.writable">
                      <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                    </mat-form-field>
                    </div>
                 

                  </div>
                  <div *ngSwitchCase="'double'">
                    <label [for]="field.property.id">{{ field.property.name }}
                      <span class="required" *ngIf="field.property.required"></span>
                    </label>
                    <mat-form-field class="example-full-width"
                      *ngIf="parseType(field.property.id,field.property.type.name) == 'number'">
                      <mat-label>{{ field.property.name }}</mat-label>
                      <input type="number" matInput placeholder="0" [formControlName]="field.property.id"
                        (change)="dataChange()" (wheel)="($event).preventDefault()" #formControl [id]="field.property.id"
                        [disabled]="parseExpression2(field.property.expression, field.property.id)"
                        [readonly]="!field.property.writable" step="any">
                      <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'enum'">
                    <div *ngIf="parseId(field.property.id)[1] == 'multi' && parseId(field.property.id)[0] == 'select' && parseId(field.property.id)[2] == 'documents'">
                      <label [for]="field.property.id">{{field.property.name}}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select multiple [formControlName]="field.property.id" [id]="field.property.id"
                          [disabled]="parseExpression2( field.property.value, field.property.id)" #formControl
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let option of uploadedData" [value]="option.id">
                            {{ option.filename }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>

                    <div *ngIf="parseId(field.property.id)[1] == 'multi' && parseId(field.property.id)[0] == 'select' &&  parseId(field.property.id)[2] != 'documents'">
                      <label [for]="field.property.id">{{field.property.name}}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select multiple [formControlName]="field.property.id" [id]="field.property.id"
                          [disabled]="parseExpression2( field.property.value, field.property.id)" #formControl
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let option of getEnumOptions(field.property.id)" [value]="option.id">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                    <div *ngIf="parseId(field.property.id)[1] != 'multi' && parseId(field.property.id)[0] != 'select'  &&  parseId(field.property.id)[2] != 'documents'">
                      <label [for]="field.property.id">{{field.property.name}}
                        <span class="required" *ngIf="field.property.required"></span>
                      </label>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        <mat-select [formControlName]="field.property.id" [id]="field.property.id" #formControl
                          [disabled]="parseExpression2( field.property.value, field.property.id)"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}">
                          <mat-option *ngFor="let option of getEnumOptions(field.property.id)" [value]="option.id">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>



                  </div>

                    <div *ngSwitchCase="'date'">
                      <label [for]="field.property.id">{{
                        field.property.name
                        }}</label>
                      <span class="required" *ngIf="field.property.required"></span>
                      <mat-form-field class="example-full-width">
                        <mat-label>{{ field.property.name }}</mat-label>
                        
                        <input matInput [matDatepicker]="picker" display (ngModelChange)="checkDate($event, field.property.id)" 
                          [formControlName]="field.property.id" [id]="field.property.id"
                          [ngClass]="{'invalid': form.get(field.property.id)?.invalid && form.get(field.property.id)?.touched}"
                          [disabled]="parseExpression2(field.property.expression, field.property.id)" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="showDateError && field.property.id =='dob'" style="color: red;">
                        Date in the future can not be selected
                      </div>
                    </div>
                </ng-container>
              </div>
            </div>
            <div *ngIf="this.data.task.name == 'eKYP Query'" class="p-3">
              <button type="button" [disabled]="addkyp == true" class="btn btn-outline-primary" (click)="
                        openAddForm(selectedTaskDetails)">Add
              </button>
            </div>

            <div class="d-flex justify-content-end btn-group" role="group" aria-label="Basic example" style="margin:5px">
              <button *ngIf="(data.task.name == 'Pending with Insurer'  || data.task.name == 'Pre Auth Approval' ) "
                class="btn" type="button" style="color:white;background:#8e0101" (click)="viewClaimStatus()">Check Claim
                Status
              </button>
              <button *ngIf="(data.task.name == 'Pending with Insurer') "
              class="btn" type="button" style="color:white;background:#8e0101;margin-left:1px" (click)="viewInsuranceWebsite()">View Website
            </button>
              <!--<button class="btn" type="button" style="color:white;background:#8e0101;margin-left:1px"
                (click)="viewUploadedFileComponent()">View File</button>-->
              <button
                *ngIf="(data.task.name == 'Pre Auth' || data.task.name == 'Medical Scrutiny') && data.task.caseVariable?.patientPolicies != undefined"
                class="btn" type="button" style="color:white;background:#8e0101" (click)="openEkypForm()">View
                EKYP</button>

                <button class="btn" type="button" style="color:white;background:#8e0101;margin-left:1px"
                (click)="saveDraft()"
                >Save Draft</button>

              <button class="btn" type="submit" style="color:white;background:#8e0101;margin-left:1px"
                [disabled]="(!form.valid && submitted == true )|| showDateError">Submit</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>





<div class="p-3">
  <mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>