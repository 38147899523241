import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { AddEmailLookupDetailComponent } from '../add-email-lookup-detail/add-email-lookup-detail.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-add-email-lookup',
  templateUrl: './add-email-lookup.component.html',
  styleUrl: './add-email-lookup.component.scss'
})
export class AddEmailLookupComponent implements OnInit {
  addEmailLookUp !:FormGroup;
  user:any;
  selectedSenderType:any;
  deployedprocessByProcessDefTasks:any;
  lookupDetails :any[] =[];
  today = new Date();
  detailsTable = new MatTableDataSource();
  displayedColumns = ['fieldName', 'fieldType', 'fieldPattern', 'dataLookupStrategy', 'fieldValue' ,'fieldMatchCondition', 'edit'];
  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private _dialogRef: MatDialogRef<AddEmailLookupComponent>,
    private dialog: MatDialog,
    private authService: AuthService,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    
    
  ){
    this.addEmailLookUp = this.formBuilder.group({
      senderType :["", Validators.required],
      taskDefKey : ["", Validators.required],
      lookupPhrase: ["", Validators.required],
      emailLookupStrategy :["", Validators.required],

    });
    this.authService.user.subscribe((x: any) => {
      this.user = x;
      
    });
    
  }
  ngOnInit(): void {
    this.getDeployedprocessByProcessDefTasks('cashlessProcess');
    if(this.data.type='edit'){
      console.log(this.data.mail);
      this.addEmailLookUp.patchValue({
        senderType : this.data.mail.id.senderType,
        taskDefKey : this.data.mail.id.taskDefKey,
        lookupPhrase: this.data.mail.lookupPhrase,
        emailLookupStrategy : this.data.mail.emailLookupStrategy,
      });
      this.lookupDetails = this.data.mail.mailLookupDetails;
      this.detailsTable.data = this.data.mail.mailLookupDetails;
      
    }
  }

  getDeployedprocessByProcessDefTasks(processKey : any) {
   
    this.adminService
      .getDeployedprocessByProcessDefKey(processKey )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          //this.deployedprocessByProcessDefTasks = data;
          this.deployedprocessByProcessDefTasks = data.sort((a:any, b:any) => {
            return a.taskName.localeCompare(b.taskName);
          });
          console.log("Tasks", this.deployedprocessByProcessDefTasks);
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
        },
      });
  }
  addLookupDetails(){
    if(this.addEmailLookUp.invalid){
      this.toastrService.info("Please in the required field to add details");
    }else{
      const detailsPage = this.dialog.open(AddEmailLookupDetailComponent,{
              
        disableClose:true,
        width: '100vw',  // Sets the width to 100% of the viewport width
        maxWidth: '100vw',  // Ensures the max width doesn't override the width
        panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
        height: '100%',
        data:
        {
          type : 'add',
          emailLookup:this.addEmailLookUp.value,
        }
      });
      detailsPage.afterClosed().subscribe({
        next :(val)=>{
          console.log(val);
          if(val){
            console.log("val returned:"+val);
            this.lookupDetails.push(val);
            this.detailsTable.data = this.lookupDetails;
          }
         
         }
      })
        
      }
  

}

editLookupDetails(element:any){
  const detailsPage = this.dialog.open(AddEmailLookupDetailComponent,{
              
    disableClose:true,
    width: '100vw',  // Sets the width to 100% of the viewport width
    maxWidth: '100vw',  // Ensures the max width doesn't override the width
    panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
    height: '100%',
    data:
        {
          type : 'edit',
          emailLookup:element,
        }
  });
  detailsPage.afterClosed().subscribe({
    next :(val)=>{
      console.log(val);
      if(val){
        console.log(this.lookupDetails);
        this.lookupDetails = this.lookupDetails.map(item => {
          if (item.id === val.id) {
            return val; // Merge the existing item with the new values
          }
          return item; // Return the original item if no match
        });
        this.detailsTable.data = this.lookupDetails  ;
      }
     
     }
  })
}


  saveEmailLookup(){
    const json = {
      "id": {
        "senderId": this.user.id,
        "senderType": this.addEmailLookUp.value.senderType,
        "taskDefKey": this.addEmailLookUp.value.taskDefKey
      },
      "lookupPhrase": this.addEmailLookUp.value.lookupPhrase,
      "processingStatus": "",
      "inactive": true,
      "createdBy": this.user.id,
      "dateCreated": this.today.toISOString(),
      "updatedBy": this.user.id,
      "dateUpdated":  this.today.toISOString(),
      "emailLookupStrategy": this.addEmailLookUp.value.emailLookupStrategy,
      "lookupIntervalMinutes": 0,
      "subjectFilter": "",
      "processingConnector": "",
      "mailLookupSubjects": [
        {}
      ],
      "mailLookupDetails": [{}]
    }
    if(this.lookupDetails){
      json.mailLookupDetails = this.lookupDetails;
    }

    if(this.data.type == 'edit'){
      json.processingStatus = this.data.mail.processingStatus;
      json.inactive = this.data.mail.inactive;
      json.dateCreated = this.data.mail.dateCreated;
      json.lookupIntervalMinutes = this.data.mail.lookupIntervalMinutes;
      json.subjectFilter = this.data.mail.subjectFilter;
      json.processingConnector ='',
      json.mailLookupSubjects = this.data.mail.mailLookupSubjects;
      json.mailLookupDetails = this.data.mail.mailLookupDetails;

      console.log(json);
      this.adminService.patchEmailLookup(json).
      subscribe({
        next: (data) => {
          console.log('Email Lookup updated successfully');
          console.log(data);
        },
        error: (error) => {
          console.error('Error updating Email Lookup', error);
        }
      })
    }else{
      console.log(json);
      this.adminService.postEmailLookup(json).
      subscribe({
        next: (data) => {
          console.log('Email Lookup added successfully');
          console.log(data);
        },
        error: (error) => {
          console.error('Error adding Email Lookup', error);
        }
      })
    }
    
   
  }

  

}
