<div class="row p-3 py-4 bg-light">
  <div class="col-9 p-3 d-flex justify-content-start">
    <h1>{{ data.task.name }} for {{ data.task.caseVariables.org.name }}</h1>
  </div>

  <div class="col p-2 d-flex justify-content-end">
    <h4>
      <i class="p-2" style="color: rgb(183, 0, 0)"
        >* <span style="color: lightgray">marked fields are mandatory</span>
      </i>
    </h4>

    <div class="p-2 example-button-container" mat-dialog-close>
      <button style="color: white; background: #8e0101" mat-mini-fab>
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <mat-accordion>
    <mat-expansion-panel
      (opened)="panelOpenState.set(true)"
      (closed)="panelOpenState.set(false)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Onboarding flow daigram </mat-panel-title>
      </mat-expansion-panel-header>
      <img class="img-fluid" [src]="diagram" />
    </mat-expansion-panel>
  </mat-accordion>
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="container row mt-4">
      <div class="col-12" *ngFor="let field of data.task.formDataFieldOptions">
        <ng-container [ngSwitch]="field.property.type.name">
          <div *ngSwitchCase="'string'">
            <ng-container
              *ngIf="
                field.property.id == 'hospitalName' ||
                field.property.id == 'phoneNumber' ||
                field.property.id == 'hospitalEmail'
              "
            >
              <label [for]="field.property.id"
                >{{ field.property.name }}
                <span class="required" *ngIf="field.property.required"></span>
              </label>

              <input
                class="form-control my-2"
                [type]="getFieldType(field)"
                [formControlName]="field.property.id"
                [id]="field.property.id"
              />
            </ng-container>
            <ng-container
              *ngIf="
                field.property.id != 'hospitalName' &&
                field.property.id != 'phoneNumber' &&
                field.property.id != 'hospitalEmail' &&
                parseId(field.property.id)[0] != 'upload' &&
                parseId(field.property.id)[4] != 'modal' &&
                parseId(field.property.id)[3] != 'modal'
              "
            >
              <label [for]="field.property.id"
                >{{ field.property.name }}
                <span class="required" *ngIf="field.property.required"></span>
              </label>

              <input
                class="form-control my-2"
                [type]="getFieldType(field)"
                [formControlName]="field.property.id"
                [id]="field.property.id"
              />
            </ng-container>
            <div *ngIf="parseId(field.property.id)[0] == 'upload'">
              <label [for]="field.property.id"
                >{{ field.property.name }}
                <span class="required" *ngIf="field.property.required"></span>
              </label>
              <div class="col">
                <label class="btn btn-default p-0">
                  <input
                    class="form-control my-2"
                    accept="application/pdf"
                    type="file"
                    [formControlName]="field.property.id"
                    (change)="selectFile($event, parseId(field.property.id)[1])"
                    [id]="field.property.id"
                  />
                </label>
                <ul class="list-group list-group-flush">
                  <li
                    *ngFor="let file of fileInfos | async"
                    class="list-group-item"
                  >
                    <a href="{{ file.url }}">{{ file.name }}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              *ngIf="
                hospitalType == 'NON-CASHLESS' &&
                parseId(field.property.id)[1] == 'noncashless'
              "
            >
              <label for="field.property.value ='NON-CASHLESS'">
                Upload SOC for Non Cashless
              </label>
              <div class="col">
                <button
                  type="button"
                  mat-flat-button
                  style="color: white; background: #8e0101"
                  (click)="fileUploadComponent()"
                >
                  Upload File
                </button>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'enum'">
            <ng-container
              *ngIf="
                field.property.id != 'hospitalType' &&
                  field.property.type.name == 'enum';
                else enuminput
              "
            >
              <div>
                <label [for]="field.property.id"
                  >{{ field.property.name }}
                  <span class="required" *ngIf="field.property.required"></span>
                </label>
                <select
                  class="form-select my-2"
                  [formControlName]="field.property.id"
                  [id]="field.property.id"
                >
                  <option
                    *ngFor="let option of getEnumOptions(field.property.id)"
                    [value]="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </ng-container>
            <ng-template #enuminput>
              <div
                *ngIf="
                  field.property.id == 'hospitalType' ||
                  field.property.type.name == 'enum'
                "
              >
                <label [for]="field.property.id"
                  >{{ field.property.name }}
                  <span class="required" *ngIf="field.property.required"></span>
                </label>
                <select
                  (change)="onChangeHospitalType($event.target)"
                  class="form-select my-2"
                  [formControlName]="field.property.id"
                  [id]="field.property.id"
                >
                  <option
                    *ngFor="let option of getEnumOptions(field.property.id)"
                    [value]="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </ng-template>
          </div>
          <div *ngSwitchCase="'date'">
            <label [for]="field.property.id"
              >{{ field.property.name }}
              <span class="required" *ngIf="field.property.required"></span>
            </label>
            <input
              class="form-control my-2"
              type="date"
              [formControlName]="field.property.id"
              [id]="field.property.id"
            />
          </div>
          <div
            *ngIf="parseId(field.property.id)[4] == 'modal'"
            [hidden]="insuranceCompanydisabled"
          >
            <label [for]="field.property.id">{{ field.property.name }} </label>
            <br />
            <button
              type="button"
              mat-flat-button
              style="color: white; background: #8e0101"
              (click)="viewInsuranceCompanyForm(data.task.caseVariables.org)"
            >
              Add/Update
            </button>
            <!-- table -->
            <mat-accordion
              *ngIf="
                uploadedInsuranceData !== undefined &&
                uploadedInsuranceData.length > 0
              "
              class="p-4 m-2"
            >
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <strong>Insurance Soc Files</strong>
                </mat-expansion-panel-header>

                <div class="row">
                  <div class="p-4">
                    <table mat-table [dataSource]="dataSource1" matSort>
                      <!-- Position Column -->
                      <ng-container matColumnDef="insuranceName">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.insuranceName }}
                        </td>
                      </ng-container>
                      <!-- Name Column -->
                      <ng-container matColumnDef="socId">
                        <th mat-header-cell *matHeaderCellDef>Soc File</th>
                        <td mat-cell *matCellDef="let element">
                          <a (click)="openViewFile(element)"> View </a>
                        </td>
                      </ng-container>
                      <!-- Name Column -->
                      <ng-container matColumnDef="dateCreated">
                        <th mat-header-cell *matHeaderCellDef>Created Date</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.dateCreated | date : "short" }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns1"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns1"
                      ></tr>
                    </table>
                  </div>
                  <!-- <div class="demo-options p-4">
                        <div class="demo-toggles">
                          <mat-paginator
                            #paginator
                            class="demo-paginator"
                            [length]="length"
                            [pageSize]="pageSize"
                            [pageIndex]="pageIndex"
                            aria-label="Select page"
                          >
                          </mat-paginator>
                        </div>
                      </div> -->
                  <div
                    class="alert alert-danger mt-2 pt-1"
                    role="alert"
                    *ngIf="message != undefined"
                  >
                    {{ message }}
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div
            *ngIf="
              parseId(field.property.id)[3] == 'modal' &&
              parseId(field.property.id)[1] != 'noncashless'
            "
          >
            <label [for]="field.property.id">{{ field.property.name }} </label>
            <br />
            <button
              type="button"
              mat-flat-button
              style="color: white; background: #8e0101"
              (click)="viewProductForm(data.task.caseVariables.org)"
            >
              Add Product
            </button>
            <!-- vs -->

            <mat-accordion
              *ngIf="uploadedData !== undefined && uploadedData.length > 0"
              class="p-4 m-2"
            >
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <strong>Associated Product </strong>
                </mat-expansion-panel-header>

                <div class="row">
                  <div class="p-4">
                    <table mat-table [dataSource]="dataSource" matSort>
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Product Name</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.bgProductModel.name }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.bgProductModel.description }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="qty">
                        <th mat-header-cell *matHeaderCellDef>Qty</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.qty }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>Price</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.negotiatedRate }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- vs -->
        </ng-container>
      </div>
    </div>
    <button
      [disabled]="uploadedInsuranceData.length <= 0 || form.invalid"
      type="submit"
      mat-flat-button
      style="color: white; background: #8e0101"
    >
      Submit
    </button>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
