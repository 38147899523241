import { AfterViewInit, Component, Inject, signal } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { first, Observable, take } from "rxjs";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { ToastrService } from "ngx-toastr";
import { BgProductComponent } from "../../bg-product/bg-product.component";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { InsuranceComponent } from "../../insurance/insurance.component";
import { TechComponent } from "../../insurance/tech/tech.component";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { FileUploadComponent } from "src/app/file-upload/file-upload.component";
import { WorkflowService } from "src/app/services/workflow.service";
import { FileService } from "src/app/services/file.service";

@Component({
  selector: "app-initiate-onboarding",
  templateUrl: "./initiate-onboarding.component.html",
  styleUrls: ["./initiate-onboarding.component.scss"],
})
export class InitiateOnboardingComponent {
  insuranceData: any = [];
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  displayedColumns: string[] = ["name", "description", "qty", "price"];
  readonly panelOpenState = signal(false);
  id: any;
  route: any;
  customerId: any;
  createCase!: FormGroup;
  selectedoption = null;
  DoYouhaveFamilyPhysician = null;
  assignedTasks: any[] = [];
  uploadedData: any[] = [];
  uploadedInsuranceData: any[] = [];
  loading: any = false;
  error: any;
  selectedTaskDetails: any;
  message: any;
  form: FormGroup = this.formBuilder.group({});
  tasks: any[] = [];
  currentState: any;
  selectedFiles?: any;
  currentFile?: File;
  progress = 0;
  fileInfos?: Observable<any>;
  insuranceCompanyId: any;
  hospitalType: any = "CASHLESS";
  insuranceCompanydisabled: boolean = false;
  formData: FormData = new FormData();
  diagram: any;
  docType: any;
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  dataSource1 = new MatTableDataSource<any>(this.insuranceData);
  displayedColumns1: string[] = ["insuranceName", "socId", "dateCreated"];
  constructor(
    public dialogRef: MatDialogRef<InitiateOnboardingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private formBuilder: FormBuilder,
    private cashlessService: CashflowService,
    private fileService: FileService,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService: ToastrService,
    private _dialog: MatDialog,
    private workflowService:WorkflowService
  ) {
    // console.log("task : " + JSON.stringify(data));
  }

  ngOnInit() {
    this.initializeForm();
    this.getInsuranceCompanies();
    this.getBPMNDiagram(this.data.task.processInstanceId);
  }

  submitForm() {
    // console.log("Submit Form", this.form.value);
    this.cashlessService
      .postFormData(this.data.task.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          //window.alert('Registration Successfull');
          this.loading = false;
          this.toastrService.success(
            this.data.task.name + " has been updated successfully"
          );
          // console.log("done");
          this.dialogRef.close();

          //this.registrationsuccess = true;
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error.error.error;
          //window.alert(JSON.stringify(error.error.message));
          //this.registrationsuccess = true;
          this.loading = false;
          //this.router.navigate(["acountactivationComponent"])
        },
      });
  }

  initializeForm() {
    // console.log("main form", this.data.task.formDataFieldOptions);
    if (this.data.task) {
      const task = this.data.task; // assuming you only deal with the first task for now
      const formControls: Record<string, FormControl> = {};
      task.formDataFieldOptions
        // .slice(0, 4)
        .forEach(
          (field: {
            property: { required: any; id: string | number; value: any };
          }) => {
            const validators = field.property.required
              ? [Validators.required]
              : [];
            formControls[field.property.id] = new FormControl(
              field.property.value || "",
              validators
            );
          }
        );

      this.form = this.formBuilder.group(formControls);
    }
    this.getSelectedTaskDetails();
  }

  getEnumOptions(fieldId: string) {
    const field = this.data.task.formDataFieldOptions.find(
      (option: any) => option.property.id === fieldId
    );
    return field ? field.values : [];
  }

  getFieldType(field: any) {
    // console.log(field.property.mimeType);
    if (field.property.mimeType === "text/plain") {
      return "text";
    }
    return "text";
    // return "number";
  }

  getSelectedTaskDetails() {
    this.cashlessService
      .getSelectedHospitalOnBoardingProcessTask(this.data.task.id)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //window.alert('Registration Successfull');
          this.loading = false;
          this.selectedTaskDetails = data;
          // console.log(this.selectedTaskDetails);
          if (this.selectedTaskDetails) {
            this.updateForm(this.selectedTaskDetails);
            this.getUploadedHospitalMedia();
            this.getHospitalInsuranceView();
            this.getHospitalInsurance();
          }
          //this.registrationsuccess = true;
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error.error.error;

          this.loading = false;
        },
      });
  }

  updateForm(json: any) {
    const formControls = this.form.controls;
    if (this.form.controls["hospitalName"]) {
      this.form.controls["hospitalName"].setValue(
        this.selectedTaskDetails.org.name
      );
    }
    if (this.form.controls["phoneNumber"]) {
      this.form.controls["phoneNumber"].setValue(
        this.selectedTaskDetails.org.mobilePhone
      );
    }
    if (this.form.controls["hospitalEmail"]) {
      this.form.controls["hospitalEmail"].setValue(
        this.selectedTaskDetails.org.email
      );
    }

    // console.log("formControls", formControls);
  }

  selectFile(event: any, formId: any): void {
    const file: File | null = event.target.files.item(0);
    const proertyId = "NON-CASHLESS";
    // const proertyId = formId;
    this.selectedFiles = file;
    // this.docType = proertyId;
    this.docType = "NON-CASHLESS";
    this.formData.append("docType", proertyId);
  }

  uploadFiles(): void {
    //    event.stopPropagation();
    this.formData.append("file", this.selectedFiles);
    this.formData.append("hospitalId", this.selectedTaskDetails.org.id);
    this.formData.append("entityId", this.selectedTaskDetails.org.id);
    this.formData.append("caseId", "null");
  }

  insuranceCompanies: any;
  getInsuranceCompanies() {
    this.hospitalRegistrationService.getInsuranceCompany().subscribe({
      next: (data) => {
        this.loading = false;
        // console.log(data);
        this.insuranceCompanies = data;
        // console.log("Get Users by Manager Array:" + JSON.stringify(data));
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;

        this.loading = false;
      },
    });
  }

  getUploadedHospitalMedia() {
    this.fileService
      .getExternalMediaList(
        this.selectedTaskDetails.org.id,
        "HOSPITAL",
        ""
      )
      .subscribe({
        next: (data) => {
          this.loading = false;
          // console.log("....", data);
          this.uploadedData = data;
          // console.log("Get Users by Manager Array:" + JSON.stringify(data));
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;

          this.loading = false;
        },
      });
  }

  parseId(id: string) {
    return id.split("-");
  }

  onChangeHospitalType(arg0: any) {
    if (arg0) {
      this.hospitalType = arg0.value;
      if (this.hospitalType == "CASHLESS") {
        this.insuranceCompanydisabled = false;
        // this.cashSocdisabled = true;
      } else {
        this.insuranceCompanydisabled = true;
        // this.cashSocdisabled = !this.cashSocdisabled;
      }
      // console.log("hospitalType", this.hospitalType);
    }
  }

  viewInsuranceCompanyForm(data: any): void {
    const dialogRef = this._dialog.open(FileUploadComponent, {
      disableClose:true,
      data: {
        data,
        hospitalId: this.selectedTaskDetails.org.id,
        type: "FILE",
        entityType: "ONBOARDING",
        entity_id: this.selectedTaskDetails.org.id,
        caseId: this.data.task.processInstanceId, 
        taskName: this.data.task.taskDefKey
      },
      // Set width to 600px
      width:'90%',
      //height:'350px',  // Set height to 530px
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getHospitalInsuranceView();
          // this.getUserRoleslById();
          //this._dialogRef.close(true);
        }
      },
    });
  }

  viewInsuranceCompanyTechForm(data: any): void {
    const dialogRef = this._dialog.open(TechComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          if (this.form.controls["select-insurance-company-tech-modal"]) {
            this.form.controls["select-insurance-company-tech-modal"].setValue(
              true
            );
          }
        }
      },
    });
  }

  getHospitalInsurance() {
    this.hospitalRegistrationService
      .getBgProducttWithHospitalId(this.selectedTaskDetails.org.id)
      .subscribe({
        next: (data) => {
          // console.log("....", data);
          this.uploadedData = data;
          this.dataSource.data = this.uploadedData;
          if (this.form.controls["select-hospital-products-modal"] &&  this.uploadedData.length > 0) {
            this.form.controls["select-hospital-products-modal"].setValue(true);
          }
          // console.log("Get :" + this.dataSource.data);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  viewProductForm(data: any): void {
    const dialogRef = this._dialog.open(BgProductComponent, {
      disableClose:true,
      data,
      // Set width to 600px
      //height:'350px',  // Set height to 530px
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          // console.log("tyu", val);
          // this.getUserRoleslById();
          //this._dialogRef.close(true);
          // console.log("abc", val);
          if (this.form.controls["select-hospital-products-modal"]) {
            this.form.controls["select-hospital-products-modal"].setValue(true);
            this.getHospitalInsurance();
          }
        }
      },
    });
  }

  getBPMNDiagram(taskId: any) {
    this.workflowService
      .getBPMNDiagram(taskId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.diagram = "data:image/png;base64," + data; // use this in <img src="..."> binding

          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          // console.log(error);
          this.loading = false;
        },
      });
  }

  getHospitalInsuranceView() {
    this.hospitalRegistrationService
      .gethospitalInsurance(
        this.selectedTaskDetails.org.id,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          this.uploadedInsuranceData = data;
          this.dataSource1.data = this.uploadedInsuranceData;
          // console.log("this.uploadedInsuranceData" + this.dataSource1.data);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  openViewFile(metaData: any) {
    // console.log(metaData);
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose:true,
      data: { fileMetaData: metaData },
      width: "90%",
      height: "90%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }

  fileUploadComponent() {
    const dialogRef = this._dialog.open(FileUploadComponent, {
      disableClose:true,
      data: {
        hospitalId: this.selectedTaskDetails.org.id,
        type: "FILE",
        entityType: "ONBOARDING",
        entity_id: this.selectedTaskDetails.org.id,
        caseId: this.data.task.processInstanceId, 
        taskName: this.data.task.taskDefKey
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        // console.log("after closed:" + val);
        if (val === "File(s) uploaded") {
          this.form.patchValue({
            "upload-file": val,
          });
        }
      },
    });
  }
}
