<div class="pt-3 text-center ">

    <img width="10%" class="d-block mx-auto mb-4" src="../../assets/img/enterprise.png" alt="">

    <h1 class="display-5 fw-bold">Bima<span style="color:#C00000;">Garage</span> Parner portal</h1>

    <div class="col-lg-6 mx-auto">

        <p class="lead mb-4">The Parner portal allows you to Manage your cashless as well reimbursement claims process!</p>
        <p class="lead mb-4">You be be able to access all the features once the onboarding process is over.</p>

    </div>

</div>