import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ViewFileComponent } from 'src/app/portal/bg/inbox/view-file/view-file.component';
import { FileService } from 'src/app/services/file.service';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
import { UpdateManageHospitalTechnicalInsuranceComponent } from '../../update/update-manage-hospital-technical-insurance/update-manage-hospital-technical-insurance.component';
import { AddPortalDetailsComponent } from '../../add-portal-details/add-portal-details.component';
import { ViewPortalDetailsComponent } from '../../view-portal-details/view-portal-details.component';
import { ViewMailParserConfsComponent } from 'src/app/admin/manage-insurance-company/view-mail-parser-confs/view-mail-parser-confs.component';
import { SendEmailConfigComponent } from '../../send-email-config/send-email-config.component';
import { AddManageHospitalTechnicalInsuranceComponent } from '../../add/add-manage-hospital-technical-insurance/add-manage-hospital-technical-insurance.component';
import { AddManageHospitalAggrementComponent } from '../../add/add-manage-hospital-aggrement/add-manage-hospital-aggrement.component';
import { BgProductComponent } from 'src/app/onboarding/bg-product/bg-product.component';
import { AddHospitalSealComponent } from '../../add-hospital-seal/add-hospital-seal.component';
import { environment } from 'src/environments/environment.prod';
import { UpdateHospitalDetailsComponent } from '../../update/update-hospital-details/update-hospital-details.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-view-manage-hospital-technical-insurance',
  templateUrl: './view-manage-hospital-technical-insurance.component.html',
  styleUrls: ['./view-manage-hospital-technical-insurance.component.scss']
})
export class ViewManageHospitalTechnicalInsuranceComponent implements OnInit , AfterViewInit {
  displayedColumnsProduct: string[] = ["name", "description", "qty", "price" ,"funds",
    "fundsThreshold"];
  displayedColumnsLegal: string[] = ["docType", "path", "dateCreated"];
  displayedColumns: string[] = [
    "insuranceName",
    "claimSubmissionMethod",
    "socId",
    "dateCreated",
    "senderConfig",
    "update",
    "addPortalDetails"
    
  ];
  productsData: any[] = [];
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  @Input() hospitalDetails:any;
  message:any;
  currentData: any;
  addressForm!: FormGroup;
  legalData:any[] = [];
  insuranceData:any[] = [];
  dataSourceProduct = new MatTableDataSource<any>(this.productsData);
  dataSourceLegal = new MatTableDataSource<any>(this.legalData);
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  apiUrl: string = environment.API_URL;
  constructor(
    private hospitalRegistrationService: HospitalRegistrationService,
    @Inject(MAT_DIALOG_DATA) public data : any = {},
    private formBuilder: FormBuilder,
    private fileService:FileService,
    private _dialog: MatDialog,
    private cdr:ChangeDetectorRef,
  ){
    console.log(this.hospitalDetails)
   if(this.hospitalDetails){
    this.currentData = this.hospitalDetails
   }else{
    this.currentData = this.data;
   }
    
    console.log(this.currentData);
    this.getBgProducttWithHospital();
    this.getUploadedHospitalMedia();
    // this.getHospitalInsurance();
  }
  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   console.log("Paginator assigned after timeout:", this.paginator);
    //   this.dataSource.paginator = this.paginator;

    //   console.log("Paginator length:", this.paginator.length);
      
    //   this.paginator.page.subscribe((event) => {
    //     console.log("Paginator changed:", event);
    //   });

    //   this.cdr.detectChanges(); // 🔥 Force Angular to update
    // }, 500);
  }
  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      address: ["", Validators.required],
      district: ["", Validators.required],
      state: ["", Validators.required],
      name: ["", Validators.required],
      hospitalUniqueId:["", Validators.required],
      noOfBeds: ["", Validators.required],
      latitude: [""],
      longitude:[""],
      preAuthFilled:["", Validators.required],
      medicalScrutinyApproval:["", Validators.required],
      claimThroughEmail:["",Validators.required],
      fileDispatchVerification:["", Validators.required],
      contactDetail: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      emailId: ["", [Validators.required, Validators.email]],
      zone:[""]
    });

    if (this.currentData && this.currentData.data) {
      this.addressForm.patchValue(this.currentData.data);
      this.addressForm.disable();
    }
    // this.getHospitalInsurance();
   
  }
  
  onFormSubmit(): void { }

  getBgProducttWithHospital() {
    this.hospitalRegistrationService
      .getBgProducttWithHospitalId(this.currentData.data.hospitalOrgId)
      .subscribe({
        next: (data) => {
          this.productsData = data;
          this.dataSourceProduct.data = this.productsData;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  viewProductForm():void
    {
      //console.log(this.data.task.caseVariables.org.id)
        const dialogRef = this._dialog.open(BgProductComponent, {
          disableClose:true,
          data: this.currentData.data.hospitalOrgId,
          // Set width to 600px
          //height:'350px',  // Set height to 530px
        });
     
        dialogRef.afterClosed().subscribe({
          next: (val) => {
            if (val) {
              // console.log("tyu", val);
              // this.getUserRoleslById();
              //this._dialogRef.close(true);
              // console.log("abc", val);
              console.log(val);
              this.getBgProducttWithHospital();
              // if (this.form.controls["select-hospital-products-modal"]) {
              //   this.form.controls["select-hospital-products-modal"].setValue(true);
              //   this.getHospitalInsurance();
              // }
            }
          },
        });
      }

  getUploadedHospitalMedia() {
    this.fileService
      .getExternalMediaList(
        this.currentData.data.hospitalOrgId,
        "HOSPITAL",
        "LEGAL_AGREEMENT")
      .subscribe({
        next: (data) => {
          console.log(data);
          this.legalData = data;
          this.dataSourceLegal.data = this.legalData;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  
  addTechincalInsurance(data: any): void {
    const dialogRef = this._dialog.open(
      AddManageHospitalTechnicalInsuranceComponent,
      {
        disableClose: true,
        data: data,
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    });
  }

  
  addLegalAgreement(data: any): void {
    const dialogRef = this._dialog.open(
      AddManageHospitalAggrementComponent,
      {
        disableClose: true,
        data: data,
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getUploadedHospitalMedia();
        if (val) {
        }
      },
    });
  }

  getHospitalInsurance() {
    this.hospitalRegistrationService
      .gethospitalInsurance(
        this.currentData.data.hospitalOrgId,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          console.log( data);
          this.insuranceData = data;
          this.dataSource.data = this.insuranceData;
          this.paginator.length = data.length; 
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  applyFilter(filter:any){
    const value = filter.target.value;
    filter = value.trim();
    this.dataSource.filter = filter;
  }

  openViewFile(metaData: any) {
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose: true,
      data: { fileMetaData: metaData },
      width: "90%",
      // height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }
  addHospitalSeal(){
    console.log(this.currentData.data.hospitalOrgId)
    
    const dialogRef = this._dialog.open(AddHospitalSealComponent ,{
      disableClose: true,
      data:this.currentData.data.hospitalOrgId,
      width: "90%"
    });
    dialogRef.afterClosed().subscribe({
      next:(val)=>{
        console.log(val);
      }
    })
  }

  editTechincalInsurance(metaData: any) {
    // console.log(metaData);
    const dialogRef = this._dialog.open(
      UpdateManageHospitalTechnicalInsuranceComponent,
      {
        disableClose: true,
        data: metaData,

        // height: "70%",
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    });
  }

  addPortalDetails(insurance:any){
    const dialogRef = this._dialog.open(
      AddPortalDetailsComponent,
      {
        disableClose: true,
        data: insurance,
        width:'80%'

        // height: "70%",
      },
      
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    }); 
  }

  viewPortalDetails(insurance:any){
    const dialogRef = this._dialog.open(
      ViewPortalDetailsComponent,
      {
        disableClose: true,
        data: insurance,
        width:'80%'

        // height: "70%",
      },
      
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    }); 
  }
  ViewMailParser(insurance:any){
    const dialogRef = this._dialog.open(
      ViewMailParserConfsComponent,
      {
        disableClose: true,
        data: insurance,
        width:'80%'

        // height: "70%",
      },
      
    );
   
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    });
  }

  viewSendingMailParser(insurance:any){
    const dialogRef = this._dialog.open(
      SendEmailConfigComponent,
      {
        disableClose: true,
        data: insurance,
        width:'80%'

        // height: "70%",
      },
      
    );
   
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    });
  }

  updateHospitalDetails(){
    const updateDetails = this._dialog.open(UpdateHospitalDetailsComponent,{
      disableClose: true,
      data:this.currentData.data,
      width:'80%'
    });

    updateDetails.afterClosed().subscribe({
      next: (val) => {
        console.log(val);  
        // this.currentData.data = val;
        this.addressForm.patchValue(val);
      }
    });
  }

}
