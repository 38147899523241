<!-- <div mat-dialog-title>
  <h1>Add Coupon</h1>
</div> -->
<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Add Coupon</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="couponForm" (ngSubmit)="onFormSubmit()">
  <div mat-dialog-content class="content">
    <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id"  >
            </mat-form-field>
        </div> -->
    
    <div class="row">
      <!-- <mat-form-field appearance="outline">
                <mat-label>Product id</mat-label>
                <input matInput type="text" placeholder="Ex. Technical" formControlName="productId">
            </mat-form-field> -->
      <label id="example-radio-group-label">Choose discount for:</label>
      <mat-radio-group  class="radio-group" (change)="selectedRadioButton($event)">
        <mat-radio-button class="example-radio-button" value="agent">Agent</mat-radio-button>
        <mat-radio-button class="example-radio-button" value="hospital">Hospital</mat-radio-button>
        <mat-radio-button class="example-radio-button"   value="doctor">Doctor</mat-radio-button>
      </mat-radio-group>


      <mat-form-field appearance="outline">
        <mat-label>Product Name</mat-label>
        <mat-select
          formControlName="productName"
          [(ngModel)]="selectedProductTypeId"
          name="name"
        >
          <mat-option *ngFor="let product of products" [value]="product.id">
            {{ product.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Discount code</mat-label>
        <input
          matInput
          placeholder="Discount code"
          formControlName="discountCode"
        />
      </mat-form-field>
    
    
      <mat-form-field appearance="outline">
        <mat-label>Discount percent</mat-label>
        <input
          matInput
          type="text"
          placeholder="Discount percent"
          formControlName="discountPercent"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Discount note</mat-label>
        <input
          matInput
          type="email"
          placeholder="Discount note"
          formControlName="discountNote"
        />
      </mat-form-field>
  

      <!-- <mat-form-field appearance="outline">
                <mat-label>Start date</mat-label>
                <input matInput type="text" placeholder="Start date" formControlName="startDate">
            </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label>Start date</mat-label>
        <input
          matInput
          [min]="todayDate"
          [matDatepicker]="picker"
          placeholder="Start date"
          formControlName="startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>


      <!-- <mat-form-field appearance="outline">
                <mat-label>End date</mat-label>
                <input matInput type="text" placeholder="End date" formControlName="endDate">
            </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input
          matInput
          [min]="todayDate"
          [matDatepicker]="picker1"
          placeholder="End date"
          formControlName="endDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    
    <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Org id</mat-label>
                <input matInput type="text" placeholder="zip" formControlName="orgId">
            </mat-form-field>
        </div> -->
    <mat-form-field appearance="outline">
      <mat-label>Org Name</mat-label>
      <mat-select
        formControlName="orgId"
        [(ngModel)]="selectedOrgsTypeId"
        name="id"
      >
        <mat-option *ngFor="let org of orgs" [value]="org.id">
          {{ org.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Max count</mat-label>
        <input
          matInput
          type="number"
          placeholder="Max count"
          formControlName="maxCount"
        />
      </mat-form-field>
    
  
    <mat-form-field appearance="outline">
      <mat-label>Partner Type Name</mat-label>
      <mat-select
        [(ngModel)]="selectedPartnerTypeId"
        name="id"
        formControlName="partnerTypeId"
      >
        <mat-option *ngFor="let partner of partnerTypes" [value]="partner.id">
          {{ partner.type }} {{ partner.subType }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Agent Id</mat-label>
      <mat-select
        formControlName="agentId"
        name="agentId"
      >
        <mat-option *ngFor="let agent of agentList" [value]="agent.id">
          {{ agent.firstName }} {{ agent.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!couponForm.valid"
      type="submit"
    >
      Save
    </button>
  </div>
</form>
