import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit{
  form: FormGroup | any;
  // public newForm: FormGroup | any;

  firstFormGroup = this.formBuilder.group({
    firstCtrl: ["", Validators.required],
  });
  isLinear = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private toastrService:ToastrService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        passwd: ["", Validators.required],
        confirmPassword: ["", Validators.required],
        lastName: ["", Validators.required],
        userName: ["", Validators.required],
        addLine1: ["", Validators.required],
        city: ["", Validators.required],
        state: ["", Validators.required],
        country: ["", Validators.required],
        zipcode: ["", [Validators.required, Validators.minLength(6)]],
        mobileNumber: [
          "",
          [
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ],
        ],
        email: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
      },
      { validators: this.validateAreEqual }
    );
    this.getPartnerTypes();
  }

  public validateAreEqual(c: AbstractControl): { notSame: boolean } | null {
    return c.value.passwd === c.value.confirmPassword
      ? null
      : { notSame: true };
  }
  get f() {
    return this.form.controls;
  }

  message: any = undefined;
  submitted: boolean = false;
  selectedType: any;
  loading: boolean = false;

  
  register() {
    this.submitted = true;
    if (this.form.invalid || this.firstFormGroup.invalid) {
      return;
    }
    var pType = this.partnerTypes.find(
      (x: { id: any }) => x.id == this.selectedType
    );
    console.log("ptype", JSON.stringify(pType));

    if (pType.defaultRole != "MOBILE_USER") {
      pType.defaultRole = pType.defaultRole + ";MOBILE_USER";
    }
    
    var reqdata = {
      emailId: this.form.value.email,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      userName: this.form.value.userName,
      passwd: this.form.value.passwd,
      mobilePhone: this.form.value.mobileNumber,
      userType: pType.defaultRole,
      color: "purple",
      landline: "",
      inactive: false,
      address: this.form.value.addLine1,
      city: this.form.value.city,
      state: this.form.value.state,
      country: this.form.value.country,
      zip: this.form.value.zipcode,
      orgId: "",
      landingPage:"home"
    };
    //console.log("json", reqdata)
    this.loading = true;

    this.adminService
      .userregister(reqdata)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastrService.success("User is created successfully");
          this.loading = false;
          this.router.navigate(["login"]);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error.error.error;

          this.loading = false;
        },
      });
  }
  clear() {
    this.form.value.controls = "";
    this.form.reset();
    this.form.clearValidators();
  }

  partnerTypes: any;
  getPartnerTypes() {
    this.adminService.getPartnerTypes().subscribe((data: any) => {
      this.partnerTypes = data.filter(
        (x: { type: string }) =>
          x.type == "STOREFRONT"
      );
    });
  }
}
