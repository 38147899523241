import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-details-email-lookup',
  templateUrl: './details-email-lookup.component.html',
  styleUrl: './details-email-lookup.component.scss'
})
export class DetailsEmailLookupComponent implements OnInit {
  details:any[] =[];
  dataSource = new MatTableDataSource(this.details);
  displayedColumns: string[] = ['fieldName', 'fieldType', 'fieldPattern', 'dataLookupStrategy', 'fieldValue' ,'fieldMatchCondition'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
  ){

  }
  ngOnInit(): void {
   this.details = this.data;
   this.dataSource.data = this.details;
  }

  openAddForm(){
    
  }

}
