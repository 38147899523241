<div class="container">
  <div class="row p-3 py-4 bg-light">
    <div class="col-9 p-3 d-flex justify-content-start">
      <h1>{{ data.task.name }} for {{ data.task.caseVariables.org.name }}</h1>
    </div>

    <div class="col p-2 d-flex justify-content-end">
      <h4>
        <i class="p-2" style="color: rgb(183, 0, 0)"
          >* <span style="color: lightgray">marked fields are mandatory</span>
        </i>
      </h4>

      <div class="p-2 example-button-container" mat-dialog-close>
        <button style="color: white; background: #8e0101" mat-mini-fab>
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState.set(true)"
        (closed)="panelOpenState.set(false)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Onboarding flow daigram </mat-panel-title>
        </mat-expansion-panel-header>
        <img class="img-fluid" [src]="diagram" />
      </mat-expansion-panel>
    </mat-accordion>
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="container row mt-4">
        <div
          class="col-12"
          *ngFor="let field of data.task.formDataFieldOptions"
        >
          <ng-container [ngSwitch]="field.property.type.name">
            <div *ngSwitchCase="'string'">
              <ng-container
                *ngIf="
                  field.property.id != 'hospitalName' &&
                  field.property.id != 'phoneNumber' &&
                  field.property.id != 'hospitalEmail' &&
                  parseId(field.property.id)[0] != 'upload' &&
                  parseId(field.property.id)[4] != 'modal' &&
                  parseId(field.property.id)[3] != 'modal'
                "
              >
                <label [for]="field.property.id"
                  >{{ field.property.name }}
                  <span class="required" *ngIf="field.property.required"></span>
                </label>

                <input
                  class="form-control my-2"
                  [type]="getFieldType(field)"
                  [formControlName]="field.property.id"
                  [id]="field.property.id"
                />
              </ng-container>
              <div *ngIf="parseId(field.property.id)[0] == 'upload'">
                <label [for]="field.property.id"
                  >{{ field.property.name }}
                  <span class="required" *ngIf="field.property.required"></span>
                </label>
                <div class="col">
                  <label class="btn btn-default p-0">
                    <input
                      class="form-control my-2"
                      accept="application/pdf"
                      type="file"
                      [formControlName]="field.property.id"
                      (change)="
                        selectFile($event, parseId(field.property.id)[1])
                      "
                      [id]="field.property.id"
                    />
                  </label>
                  <ul class="list-group list-group-flush">
                    <li
                      *ngFor="let file of fileInfos | async"
                      class="list-group-item"
                    >
                      <a href="{{ file.url }}">{{ file.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                *ngIf="
                  field.property.id != 'hospitalName' &&
                  field.property.id != 'phoneNumber' &&
                  field.property.id != 'hospitalEmail' &&
                  field.property.id != 'techOnboardingComment' &&
                  parseId(field.property.id)[0] != 'upload' &&
                  parseId(field.property.id)[4] != 'modal'
                "
              >
                <label for="field.property.value ='NON-CASHLESS'"
                  >File Upload for Non Cashless
                </label>
                <div class="col">
                  <label class="btn btn-default p-0">
                    <input
                      class="form-control my-2"
                      accept="application/pdf"
                      type="file"
                      formControlName="fileUpload"
                      (change)="selectFile($event, 'CashSOC')"
                      id="fileUpload"
                    />
                  </label>
                  <ul class="list-group list-group-flush">
                    <li
                      *ngFor="let file of fileInfos | async"
                      class="list-group-item"
                    >
                      <a href="{{ file.url }}">{{ file.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'enum'">
              <div *ngIf="field.property.type.name == 'enum'">
                <label [for]="field.property.id"
                  >{{ field.property.name }}
                  <span class="required" *ngIf="field.property.required"></span>
                </label>
                <select
                  (change)="onChangeHospitalType($event.target)"
                  class="form-select my-2"
                  [formControlName]="field.property.id"
                  [id]="field.property.id"
                >
                  <option
                    *ngFor="let option of getEnumOptions(field.property.id)"
                    [value]="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div *ngSwitchCase="'date'">
              <label [for]="field.property.id"
                >{{ field.property.name }}
                <span class="required" *ngIf="field.property.required"></span>
              </label>
              <input
                class="form-control my-2"
                type="date"
                [formControlName]="field.property.id"
                [id]="field.property.id"
              />
            </div>

            <div
              class="mb-2"
              *ngIf="
                parseId(field.property.id)[0] == 'select' &&
                parseId(field.property.id)[1] == 'insurance' &&
                parseId(field.property.id)[2] == 'company' &&
                parseId(field.property.id)[3] == 'tech' &&
                parseId(field.property.id)[4] == 'modal'
              "
            >
              <label [for]="field.property.id"
                >{{ field.property.name }}
              </label>
              <br />
              <button
                type="button"
                mat-flat-button
                style="color: white; background: #8e0101"
                (click)="
                  viewInsuranceCompanyTechForm(data.task.caseVariables.org)
                "
              >
                Add/Update
              </button>
            </div>

            <!-- vs -->
          </ng-container>
        </div>
      </div>
      <mat-accordion class="p-2">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <strong>Insurance Details</strong>
          </mat-expansion-panel-header>
          <div class="container" *ngIf="insuranceData.length != 0">
            <mat-table class="p-4" [dataSource]="dataSource" matSort>
              <!-- Position Column -->
              <ng-container matColumnDef="insuranceName">
                <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.insuranceName }}
                </mat-cell>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="claimSubmissionMethod">
                <mat-header-cell *matHeaderCellDef>Tech Method</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.claimSubmissionMethod }}
                </mat-cell>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="config">
                <mat-header-cell *matHeaderCellDef>Tech Data</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{
                    element.emailConf ||
                      element.portalConf ||
                      element.integrationConf ||
                      "Null"
                  }}
                </mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="socId">
                <mat-header-cell *matHeaderCellDef>Soc</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a (click)="openViewFile(element)"> View </a>
                </mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="dateCreated">
                <mat-header-cell *matHeaderCellDef
                  >Created Date</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  {{ element.dateCreated | date : "short" }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
            <div class="demo-options p-4">
              <div class="demo-toggles">
                <mat-paginator
                  #paginator
                  class="demo-paginator"
                  (page)="handlePageEvent($event)"
                  [length]="length"
                  [pageSize]="pageSize"
                  [disabled]="disabled"
                  [showFirstLastButtons]="showFirstLastButtons"
                  [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                  [hidePageSize]="hidePageSize"
                  [pageIndex]="pageIndex"
                  aria-label="Select page"
                >
                </mat-paginator>
              </div>
            </div>
            <div
              class="alert alert-danger mt-2 pt-1"
              role="alert"
              *ngIf="message != undefined"
            >
              {{ message }}
            </div>
          </div>
          <div *ngIf="insuranceData.length === 0">
            <div class="alert alert-info mt-2 pt-1" role="alert">
              No Insurance Companies found.
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <button
        class="mt-4"
        [disabled]="!form.valid"
        type="submit"
        mat-flat-button
        style="color: white; background: #8e0101"
      >
        Submit
      </button>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
