
<div class="container pt-3">
  
  <div class="row m-2  justify-content-md-center">
    <div class="col-5 p-3 ">
        <div class="card">
            <div class="card-header text-center bg-light">
              <mat-icon>location_on</mat-icon>
              <label>Locating within {{radius}} KMS</label>
            </div>
            <div class="card-body">
                <div class="row pt-2">
                    <div class="col">
                        <div class="row justify-content-md-center">
                            <div class="col-8 text-center">
                            
                                <label>
                                  Radius (Kms):
                                </label>
                                <input type="number" (input)="radiusChanged($event)" [(ngModel)]="radius" class="form-control"
                                  placeholder="Radius(Kms) " aria-label="Radius">
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- <div class="row">
    <div class="p-4 col ">
      <mat-icon>location_on</mat-icon>
      <label>Locating within {{radius}} KMS</label>
    </div>
  </div>
  -->

  <div class="row py-4">
    <!-- <input label="Number input" type="number" (input)="radiusChanged($event)" [value]="radius"
      placeholder="Radius"> -->
      <div class="col-lg-8 mx-auto">

      
    <div class="row m-2 p-2">
      <input type="search" (input)="handleInput($event)" class="form-control" placeholder="Search..."
        aria-label="Search">
      <div class="col">
        <!-- <input type="search"  (Input)="handleInput($event)" class="form-control" placeholder="Search..." aria-label="Search"> -->
        <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
        </form>
        <ul class="list-group list-group-flush">
          <li *ngFor="let hospital of results" class="list-group-item" (click)="openMap(hospital.lat,hospital.lng)">
            <!-- <mat-icon>business_center</mat-icon> -->
            <h4>
              <mat-icon>business_center</mat-icon>

              {{ hospital.name }} , {{hospital.city}} , {{hospital.pin}}
              <mat-icon>open_in_browser</mat-icon>

            </h4>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- <div class="card mt-3">
        <div class="card-header">List of Hospital</div>
        <ul class="list-group list-group-flush">
          <li *ngFor="let hospital of results" class="list-group-item">
            <a>{{ hospital.name }} {{hospital.city}}  {{hospital.pin}}</a>
          </li>
        </ul>
      </div> -->
<!-- <div class="spinner-border text-primary" *ngIf="loading" role="status">
  <span class="visually-hidden">Please wait...</span>
</div> -->
<div class="d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Please wait...</span>
  </div>
</div>