import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { UpdateManageHospitalTechnicalInsuranceComponent } from "../update/update-manage-hospital-technical-insurance/update-manage-hospital-technical-insurance.component";
import { AddManageHospitalTechnicalInsuranceComponent } from "../add/add-manage-hospital-technical-insurance/add-manage-hospital-technical-insurance.component";
import { AddManageHospitalAggrementComponent } from "../add/add-manage-hospital-aggrement/add-manage-hospital-aggrement.component";
import { FileService } from "src/app/services/file.service";

@Component({
  selector: "app-manage-hospital-detail",
  templateUrl: "./manage-hospital-detail.component.html",
  styleUrls: ["./manage-hospital-detail.component.scss"],
})
export class ManageHospitalDetailComponent implements OnInit {
  addressForm!: FormGroup;
  currentData: any;
  insuranceData: any = [];
  message: any;
  legalData: any[] = [];
  productsData: any[] = [];

  dataSource = new MatTableDataSource<any>(this.insuranceData);
  dataSourceLegal = new MatTableDataSource<any>(this.legalData);
  dataSourceProduct = new MatTableDataSource<any>(this.productsData);
  displayedColumns: string[] = [
    "insuranceName",
    "claimSubmissionMethod",
    "config",
    "socId",
    "dateCreated",
    "update",
  ];
  displayedColumnsLegal: string[] = ["docType", "path", "dateCreated"];
  displayedColumnsProduct: string[] = ["name", "description", "qty", "price"];

  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent: PageEvent = new PageEvent();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _dialog: MatDialog,
    private hospitalRegistrationService: HospitalRegistrationService,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentData = this.data;
  }

  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      address: ["", Validators.required],
      district: ["", Validators.required],
      state: ["", Validators.required],
      name: ["", Validators.required],
      contactDetail: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      emailId: ["", [Validators.required, Validators.email]],
    });

    if (this.currentData && this.currentData.data) {
      this.addressForm.patchValue(this.currentData.data);
    }
    this.getBgProducttWithHospital();
    this.getHospitalInsurance();
    this.getUploadedHospitalMedia();
  }

  onFormSubmit(): void { }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  // get data from hospital organization id
  getHospitalInsurance() {
    this.hospitalRegistrationService
      .gethospitalInsurance(
        this.currentData.data.hospitalOrgId,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          console.log("insuranceData" + data);
          this.insuranceData = data;
          this.dataSource.data = this.insuranceData;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  getUploadedHospitalMedia() {
    this.fileService
      .getExternalMediaList(
        this.currentData.data.hospitalOrgId,
        "HOSPITAL",
        "")
      .subscribe({
        next: (data) => {
          this.legalData = data;
          this.dataSourceLegal.data = this.legalData;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  openViewFile(metaData: any) {
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose: true,
      data: { fileMetaData: metaData },
      width: "90%",
      // height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }

  editTechincalInsurance(metaData: any) {
    // console.log(metaData);
    const dialogRef = this._dialog.open(
      UpdateManageHospitalTechnicalInsuranceComponent,
      {
        disableClose: true,
        data: metaData,

        // height: "70%",
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    // this.getHospitalInsurance();
  }

  getBgProducttWithHospital() {
    this.hospitalRegistrationService
      .getBgProducttWithHospitalId(this.currentData.data.hospitalOrgId)
      .subscribe({
        next: (data) => {
          this.productsData = data;
          this.dataSourceProduct.data = this.productsData;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  addTechincalInsurance(data: any): void {
    const dialogRef = this._dialog.open(
      AddManageHospitalTechnicalInsuranceComponent,
      {
        disableClose: true,
        data: data,
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getHospitalInsurance();
        if (val) {
        }
      },
    });
  }

  addLegalAgreement(data: any): void {
    const dialogRef = this._dialog.open(
      AddManageHospitalAggrementComponent,
      {
        disableClose: true,
        data: data,
      }
    );
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getUploadedHospitalMedia();
        if (val) {
        }
      },
    });
  }
}
