import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { CashflowService } from 'src/app/services/cashflow.service';

@Component({
  selector: 'app-update-hospital-products',
  templateUrl: './update-hospital-products.component.html',
  styleUrls: ['./update-hospital-products.component.scss']
})
export class UpdateHospitalProductsComponent implements OnInit{
  productForm: FormGroup;
  orgId: any;
  user: any
  status: any;

  constructor(
    private cashflow:CashflowService,
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<UpdateHospitalProductsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    this.productForm = this._fb.group({
      bgProductId: '',
      name: ['', Validators.required],
      description: ['', Validators.required],
      uom: ['', Validators.required],
      qty: ['', Validators.required],
      price: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      shortName: ['', Validators.required],
      tcRequired: ['', Validators.required],
      gst: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      processWorkflow: ['', Validators.required],
      // orgId:'',
      inactive: ['', Validators.required],
    });
  }


  
  ngOnInit() {
    this.getDeployedProcesses();

    this.status = this.data.inactive ? "Inactive" : "Activate";
    this.productForm.controls['inactive'].setValue(this.status);
    this.productForm.patchValue(this.data);
  }

  onFormSubmit() {
    if (this.productForm.valid) {
      if (this.data) {
        this.adminService
          .updateBgProduct(this.productForm.value, this.data.bgProductId)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("Product has been updated successfully");


              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              this.toastrService.error(JSON.stringify(err.error));


            },
          });
      }
    }
  }
 deployedProcesses:any=[];
  getDeployedProcesses(){
    this.cashflow
          .getDeployedProcesses()
          .subscribe({
            next: (val: any) => {
              this.deployedProcesses = val;
            },
            error: (err: any) => {
              console.error(err);
              this.toastrService.error(JSON.stringify(err.error));
            },
          });
  }

}