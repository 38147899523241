import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { ShopService } from 'src/app/services/shop.service';
import {MatTableModule} from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-vendor-order',
  templateUrl: './vendor-order.component.html',
  styleUrls: ['./vendor-order.component.scss'],
  animations:[
    trigger('detailExpand',[
      state('collapsed', style({ height: '0px', opacity: '0' })),
      state('expanded',style({height: '*'})),
      transition('expanded <=> collapsed' , animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ]
})
export class VendorOrderComponent implements OnInit{

  displayedColumns: string[] = ['Order number', 'Order date'];
  selectedColumns: string[] =['Product Name','Quantity','Amount','Order Date','Status']
  dataSource:any;
  constructor(private shopService:ShopService){

  }
  ngOnInit(): void {
    this.getRetailOrders();
  }

  loading:any = false;
  orders:any[]=[];
  selectedOrder :any;
  message:any;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  details:boolean = false;
  expandedElement!:any|null;

  getRetailOrders() {
    this.shopService.getRetailOrders(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
      .subscribe({
        next: async (data?: any) => {
          this.loading = false;
          this.orders = await data;
          this.dataSource  = this.orders;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          this.loading = false;
        }
      });
  }

  
  handleInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.dataSource = query === ""? this.dataSource : this.dataSource.filter((item:any) =>
      item.orderId.toLowerCase().includes(query.toLowerCase()));
    } 
    else {    
      this.getRetailOrders();
      }    
     
  }


  selectedItemId: number | null = null;

  // toggleDetails(itemId: number): void {
  //   // Toggle the selected item ID
  //   this.selectedItemId = this.selectedItemId === itemId ? null : itemId;
  //   this.details =  true;
  // }

  expandedOrderId: string = ''; // Track expanded row

  // toggleDetails(orderId: string) {
  //   if (this.expandedOrderId === orderId) {
  //     this.expandedOrderId = ''; // Close details
  //   } else {
  //     this.expandedOrderId = orderId; // Open details
  //   }
  // }


  toggleDetails(row : any) {
    this.loadDetails(row.orderId);
    this.expandedElement = (this.expandedElement === row)?null :row;
  }

  loadDetails(orderId:any){
    //console.log("Order Id"+JSON.stringify(orderId));

    //filter the order from this.orders and add it in the selectedOrder
    this.selectedOrder = (this.orders.find(order =>order.orderId  === orderId));
     this.selectedOrder = this.selectedOrder.items;
    console.log("SelectedOrder:"+JSON.stringify(this.selectedOrder));
  }
}
