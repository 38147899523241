<div class="container p-3">
  <div class="addbtn pt-3">
    <button
    type="button"
    mat-flat-button style="color:white;background:#8e0101"
      (click)="openAddForm()"
    >
      Add Role
    </button>
  </div>

  <div class="row pt-3 pb-3  justify-content-md-center">
    <div class="col">
      <input
      type="search"
      (input)="handleInput($event)"
      class="form-control"
      placeholder="Filter"
      aria-label="Search"
    />
    </div>
    
  </div>
  
    <mat-table  [dataSource]="dataSource" matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="Id">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="OrgId">
        <mat-header-cell *matHeaderCellDef> Org Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.orgId }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="RoleDescription">
        <mat-header-cell *matHeaderCellDef> Role Description </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.roleDescription }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="RoleName">
        <mat-header-cell *matHeaderCellDef>Role Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.roleName }} </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.tags }} </mat-cell>
      </ng-container>

      <ng-container  matColumnDef="update">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
          Update
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button style="color:#8e0101;" (click)="updateRole(row)">
            <mat-icon>edit</mat-icon>
          </button>

        </mat-cell>

      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell  style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button style="color:#8e0101;" (click)="deleteRoles(row)">
            <mat-icon>delete</mat-icon>
          </button>

        </mat-cell>

      </ng-container>
      <ng-container matColumnDef="checkPrivileges">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
          Check Privileges
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            style="color:#8e0101;"
            (click)="openCheckPrivileges(row)"
          >
            <mat-icon>check_circle_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <br>
    <mat-paginator [length]="dataSource.data.length"  [pageSizeOptions]="[10,20,50]" showFirstLastButtons ></mat-paginator>
  <!-- <div class="demo-options">
    <div class="demo-toggles">
      <mat-paginator
        #paginator
        class="demo-paginator"
        (page)="handlePageEvent($event)"
        [length]="length"
        [pageSize]="pageSize"
        [disabled]="disabled"
        [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
        [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div> -->
  <div
    class="alert alert-danger mt-2 pt-1"
    role="alert"
    *ngIf="message != undefined"
  >
    {{ message }}
  </div>
</div>
