import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/authService';



@Injectable({ providedIn: 'root' })
export class AuthLoginGuard implements CanActivate {
    user: any;
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
        this.authService.user.subscribe((x) => {
            this.user = x;
            //console.log("Auth Login " + this.user.jwtToken);
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //this.user = this.authService.userValue;
//console.log(this.user);
        if(this.user != undefined){
//console.log("Token "+this.user.jwtToken)
            var token = this.authService.parseJwt(this.user?.jwtToken);
    
            var expTime: number = token.exp * 1000;
            var currentTime: number = new Date().getTime();
            var seconds = (expTime / 1000 - (currentTime / 1000));
            var minutes: any = seconds / 60;
    
            if (minutes >= 0) {
                this.router.navigate(['/home'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        } 
        if(this.user == undefined){
                return true;
        }
        

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}