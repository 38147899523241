import { AfterViewInit, Component, Inject, signal } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { first, Observable, take } from "rxjs";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { InsuranceComponent } from "../../insurance/insurance.component";
import { ToastrService } from "ngx-toastr";
import { BgProductComponent } from "../../bg-product/bg-product.component";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { TechComponent } from "../../insurance/tech/tech.component";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { WorkflowService } from "src/app/services/workflow.service";

@Component({
  selector: "app-tech-onboarding",
  templateUrl: "./tech-onboarding.component.html",
  styleUrls: ["./tech-onboarding.component.scss"],
})
export class TechOnboardingComponent {
  insuranceData: any = [];
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  displayedColumns: string[] = [
    "insuranceName",
    "claimSubmissionMethod",
    "config",
    "socId",
    "dateCreated",
  ];
  readonly panelOpenState = signal(false);
  id: any;
  route: any;
  customerId: any;
  createCase!: FormGroup;
  selectedoption = null;
  DoYouhaveFamilyPhysician = null;
  assignedTasks: any[] = [];
  uploadedData: any[] = [];
  loading: any = false;
  error: any;
  selectedTaskDetails: any;
  message: any;
  form: FormGroup = this.formBuilder.group({});
  tasks: any[] = [];
  currentState: any;
  selectedFiles?: any;
  currentFile?: File;
  progress = 0;
  fileInfos?: Observable<any>;
  insuranceCompanyId: any;
  hospitalType: any;
  insuranceCompanydisabled: boolean = true;
  cashSocdisabled: boolean = true;
  formData: FormData = new FormData();
  diagram: any;
  docType: any;
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  bGProduct: any;
  selectedProduct: number[] = [];
  rowNum = 0;
  size = 500;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent: PageEvent = new PageEvent();

  constructor(
    public dialogRef: MatDialogRef<TechOnboardingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private formBuilder: FormBuilder,
    private cashlessService: CashflowService,
    private customerService: CustomerService,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService: ToastrService,
    private _dialog: MatDialog,
    private workflowService:WorkflowService
  ) {
    this.currentState = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit() {
    // console.log("HospitalId", this.data.task?.caseVariables?.org?.id);
    this.initializeForm();
    this.getSelectedTaskDetails();
    this.getHospitalInsurance();
    this.getBPMNDiagram(this.data.task.processInstanceId);
  }
  parseId(id: string) {
    return id.split("-");
  }
  getBPMNDiagram(taskId: any) {
    this.workflowService
      .getBPMNDiagram(taskId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.diagram = "data:image/png;base64," + data; // use this in <img src="..."> binding

          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          // console.log(error);
          this.loading = false;
        },
      });
  }
  getSelectedTaskDetails() {
    this.cashlessService
      .getSelectedHospitalOnBoardingProcessTask(this.data.task.id)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //window.alert('Registration Successfull');
          this.loading = false;
          this.selectedTaskDetails = data;
          // console.log(this.selectedTaskDetails);
          if (this.selectedTaskDetails) {
          }
          //this.registrationsuccess = true;
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error.error.error;

          this.loading = false;
        },
      });
  }
  submitForm() {
    // console.log("Submit Form", this.form.value);
    this.cashlessService
      .postFormData(this.data.task.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          //window.alert('Registration Successfull');
          this.loading = false;
          this.toastrService.success(
            this.data.task.name,
            "has been updated successfully"
          );

          // console.log("done");
          this.dialogRef.close();
       
          //this.registrationsuccess = true;
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error.error.error;
          //window.alert(JSON.stringify(error.error.message));
          //this.registrationsuccess = true;
          this.loading = false;
          //this.router.navigate(["acountactivationComponent"])
        },
      });
  }
  initializeForm() {
    // console.log("part2", this.data.task.formDataFieldOptions);
    if (this.data.task) {
      const task = this.data.task; // assuming you only deal with the first task for now
      const formControls: Record<string, FormControl> = {};
      task.formDataFieldOptions
        // .slice(0, 4)
        .forEach(
          (field: {
            property: { required: any; id: string | number; value: any };
          }) => {
            const validators = field.property.required
              ? [Validators.required]
              : [];
            formControls[field.property.id] = new FormControl(
              field.property.value || "",
              validators
            );
          }
        );

      this.form = this.formBuilder.group(formControls);
      // console.log("here");
    }
    this.getSelectedTaskDetails();
    // const json = JSON.parse(this.selectedTaskDetails);

    // console.log(json);
  }
  getEnumOptions(fieldId: string) {
    const field = this.data.task.formDataFieldOptions.find(
      (option: any) => option.property.id === fieldId
    );
    return field ? field.values : [];
  }
  getFieldType(field: any) {
    // console.log(field.property.mimeType);
    if (field.property.mimeType === "text/plain") {
      return "text";
    }
    return "text";
    // return "number";
  }
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getHospitalInsurance();
  }

  getHospitalInsurance() {
    this.hospitalRegistrationService
      .gethospitalInsurance(
        this.data.task?.caseVariables?.org?.id,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          this.insuranceData = data;
          this.dataSource.data = this.insuranceData;
          // console.log("insuranceData ", +this.insuranceData);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }
  viewInsuranceCompanyTechForm(data: any): void {
    const dialogRef = this._dialog.open(TechComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getHospitalInsurance();
          if (this.form.controls["select-insurance-company-tech-modal"]) {
            this.form.controls["select-insurance-company-tech-modal"].setValue(
              true
            );
          }
        }
      },
    });
  }
  selectFile(event: any, formId: any): void {
    const file: File | null = event.target.files.item(0);
    const proertyId = formId;
    this.selectedFiles = file;
    this.docType = proertyId;
    this.formData.append("docType", proertyId);
  }
  onChangeHospitalType(arg0: any) {
    if (arg0) {
      this.hospitalType = arg0.value;
      if (this.hospitalType == "CASHLESS") {
        this.insuranceCompanydisabled = !this.insuranceCompanydisabled;
        this.cashSocdisabled = true;
      } else if (this.hospitalType == "NON-CASHLESS") {
        this.insuranceCompanydisabled = true;
        this.cashSocdisabled = !this.cashSocdisabled;
      }
      // console.log("hospitalType", this.hospitalType);
    }
  }

  openViewFile(metaData: any) {
    // console.log(metaData);
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose:true,
      data: { fileMetaData: metaData },
      width: "90%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }
}
