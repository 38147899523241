import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';



@Component({
  selector: 'app-add-email-conf',
  //standalone: true,
  //imports: [],
  templateUrl: './add-email-conf.component.html',
  styleUrl: './add-email-conf.component.scss'
})
export class AddEmailConfComponent {
  emailconfigForm: FormGroup;
  //orgId:any;
  userorg: any
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  orgs: any;
  partnerTypes: any;
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;
  todayDate:Date = new Date();
  insuranceCompanyMasterData:any= [];

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private toastrService:ToastrService,
    private HospitalRegistrationService: HospitalRegistrationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AddEmailConfComponent>,

  ) {
    this.emailconfigForm = this._fb.group({
     // id: '',
     category: ['', Validators.required],
     emailId: ['', [Validators.required]],
     seqNo: ['', [Validators.required]],
     subjectFilter: ['', [Validators.required]],
     matchRegex: ['', Validators.required],
     matcherField: ['', Validators.required],
     matchSet: ['', Validators.required],
     inactive: ['', Validators.required],
     insurance:['']

    });
  }

  ngOnInit(): void {
    // this.getProducts();
    // this.getPartnerTypes();
    this.getInsuranceCompanyMaster();
    console.log(this.data.id);
    console.log(this.data.name);
    this.emailconfigForm.patchValue({ insurance: this.data.name });
  }

  products: any;

  getProducts() {
    this.customerService.getProducts().pipe(first()).subscribe({
      next: (data: any) => {
        this.products = data;
      }, error: (err: any) => {
        console.log(err);

        if (err.error && err.error.error) {
          this.error = err.error.error;
        } else {
          this.error = 'No products available for!';
        }

      }
    })
  }


  getInsuranceCompanyMaster() {
    this.adminService.getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.insuranceCompanyMasterData = data;
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //this.loading = false;
        }
      });
  }
  getPartnerTypes() {
    this.adminService.getPartnerTypes().subscribe((data: any) => {
      this.partnerTypes = data;
    })
  }
  onFormSubmit() {
    // {
    //   "id": "string",
    //   "productId": "string",
    //   "discountCode": "string",
    //   "discountPercent": 0,
    //   "discountNote": "string",
    //   "startDate": "2023-11-01T10:04:54.794Z",
    //   "endDate": "2023-11-01T10:04:54.795Z",
    //   "maxCount": 0,
    //   "partnerTypeId": 0,
    //   "orgId": "string",
    //   "dateCreated": "2023-11-01T10:04:54.795Z",
    //   "createdBy": "string",
    //   "inactive": true,
    //   "currentCount": 0
    // }
    // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
    //console.log(this.datePipe.transform(this.couponForm.value.startDate,"yyyy-MM-dd")); //output : 2018-02-13
    var data =


    
  // "id": "string",
  // "category": "string",
  // "emailId": "string",
  // "seqNo": 0,
  // "subjectFilter": "string",
  // "matchRegex": "string",
  // "matcherField": "string",
  // "matchSet": "string",
  // "inactive": true,
  // "createdBy": "string",
  // "dateCreated": "2024-08-26T11:53:03.147Z",
  // "updatedBy": "string",
  // "dateUpdated": "2024-08-26T11:53:03.147Z"

    {
      //"id": this.couponForm.value.id,
      "category": this.emailconfigForm.value.category,
      "emailId": this.emailconfigForm.value.emailId,
      "seqNo": this.emailconfigForm.value.seqNo,
      "subjectFilter": this.emailconfigForm.value.subjectFilter,
      "matchRegex":this.emailconfigForm.value.matchRegex,
      "matcherField": this.emailconfigForm.value.matcherField,
      "matchSet": this.emailconfigForm.value.matchSet,
      "inactive": this.emailconfigForm.value.inactive,
      "insurance":this.emailconfigForm.value.insurance,
      "senderId": this.data.id,
      "createdBy": "",
      "dateCreated": "",
      "updatedBy": "",
      "dateUpdated": "",
    


    }
    this.HospitalRegistrationService.addEmailConfig(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("Email parser config has been added successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));


      },
    });


  }
}
