import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { CustomerService } from "src/app/services/customer.service";
import { FileService } from "src/app/services/file.service";

@Component({
  selector: "app-view-file",
  templateUrl: "./view-file.component.html",
  styleUrls: ["./view-file.component.scss"],
})
export class ViewFileComponent {
  id: any;
  mediaType: any;
  modalTitle: any;
  imgData: any;
  pdfUrl: any;
  fileURL: any;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
  ) {
    // console.log('selectedViewData - ',this.data);
  }
  ngOnInit() {
    console.log("inside view component");
    if (this.data.fileType == "POLICY") {
      this.getPolicyFile();
    } else if (this.data.fileType == "Processing Failed") {
      this.getPolicyFile();
      //console.log("hello");
      //this.showUnProcessedFile();
    } else {

      this.getFile();
    }
  }

  async getFile() {

    console.log(this.data.fileMetaData)
    if (this.data?.fileMetaData?.socId != null) {
      var mediaId = this.data.fileMetaData.socId;
       console.log(this.data.fileMetaData.socId)
    } else {
        mediaId = this.data.fileMetaData.id;
      console.log(this.data.fileMetaData.id)
    }
    // this.loader.showLoading("Please wait... Getting the file.");
    this.loading = true;
    this.fileService
      .getExternalMedia(mediaId)
      .subscribe({
        next: (data: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.pdfUrl = e.target.result;
          }
          reader.readAsDataURL(data);
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
        },
      });
  }
  async getPolicyFile() {
    console.log("data");
    // this.loader.showLoading("Please wait... Getting the file.");
    this.loading = true;
    await this.customerService
      .getPolicyFile(this.data.fileMetaData.id)
      .subscribe({
        next: (data: Uint8Array) => {
          //console.log("Got the file from S3");
          this.loading = false;

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));

          // this.loader.hideLoader();
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
          // this.loader.hideLoader();
        },
      });
  }

  async showUnProcessedFile() {
    console.log("data");
    await this.customerService
      .getPolicyFile(this.data.fileMetaData.policyId)
      .subscribe({
        next: (data: Uint8Array) => {
          //console.log("Got the file from S3");
          this.loading = false;

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));

          // this.loader.hideLoader();
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
          // this.loader.hideLoader();
        },
      });
  }
}
