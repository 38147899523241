import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-onboarding-insurance-view-modal",
  templateUrl: "./onboarding-insurance-view-modal.component.html",
  styleUrls: ["./onboarding-insurance-view-modal.component.scss"],
})
export class OnboardingInsuranceViewModalComponent {
  constructor(
    private _dialogRef: MatDialogRef<OnboardingInsuranceViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);
  }
}
