import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AddAssignTaskComponent } from 'src/app/modal/add-assign-task/add-assign-task.component';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { CashflowService } from 'src/app/services/cashflow.service';
import { CustomerService } from 'src/app/services/customer.service';
import {faClock} from "@fortawesome/free-solid-svg-icons";
import { ViewTaskComponent } from '../view-task/view-task.component';
import { DataServiceService } from 'src/app/services/data-service.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { DataShareModel } from 'src/app/model/dataShareModel';


@Component({
  selector: 'app-assignment-policy-consulting',
  templateUrl: './assignment-policy-consulting.component.html',
  styleUrls: ['./assignment-policy-consulting.component.scss']
})
export class AssignmentPolicyConsultingComponent implements OnInit, AfterViewInit {

  faClock = faClock;
  minDate:any;
  loading: any = false;
  error:any;
  message:any;
  user:any;
  selectedTask: any;
  tasks: any = [];


  constructor(
    private dataService:DataServiceService,
    private authService: AuthService,
    private _dialog: MatDialog,
    private workflowService:WorkflowService

  ) {
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;
    this.authService.user.subscribe(x => this.user = x);
    this.dataService.newTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "policyConsulting") {
        this.tasks = data.tasks;
      }
    });
    this.dataService.assignedTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "policyConsulting") {
        this.assignedTasks = data.tasks;
      }
    });


  }


  ngAfterViewInit(): void {
    this.getAssignedTask();
    //this.getTasks();

  }
  ngOnInit(): void {
    this.getAssignedTask();
  }
  assignedTasks:any=[];
  getAssignedTask() {
    console.log("in get all assigned ")
    
      this.workflowService
      .getAssignedTask("policyConsulting")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.assignedTasks = data;
          const dataShare = new DataShareModel("policyConsulting",data);

          this.dataService.updateAssignedTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });  
    
  }
  

  
  assignTask(data:any)
  {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data:{data:data, roleName:['ADMIN','HNB']},
      width:'430px', 
      height:'200'
    });
    dialogRef.afterClosed().subscribe({
      next: (val:any) => {
        if (val) {
          this.getAssignedTask();
          this.getTasks();
        }
      },
    });
  }

  
  viewTask(element:any){
    this.selectedTask = element;
    console.log("here",this.selectedTask);
    if(this.selectedTask.assignee == undefined){
     // this.toastrService.info("Please accept or assign the task to view");
  
    }else{
      const dialogRef = this._dialog.open(ViewTaskComponent, {
        disableClose:true,
        data: this.selectedTask,
        width:'90%', 
        height:'90%'
      });
      dialogRef.afterClosed().subscribe({
        next: (val:any) => {
          this.selectedTask = undefined;
          this.getAssignedTask();
          this.getTasks();
        },
      });
    }
  
  }

  
  getTasks() {
    this.workflowService.getNewTask("policyConsulting") .pipe(first())
    .subscribe({
      next: (data: any) => {
        this.tasks = data;
        const dataShare = new DataShareModel("policyConsulting",data);

        this.dataService.updateNewTasks(dataShare);
        this.loading = false;
      },
      error: (error) => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      },
    });  
  }

  isPrivileged(privilege:string[]){
    const privileges:any[] = this.user.privileges;
    var dd =  privileges.some((x: string) => privilege.includes(x));
//console.log("is Privileged "+ this.user.privileges);
    return dd;
  }
  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    console.log(this.user.roles);
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ dd);
    return dd;
  }
}

