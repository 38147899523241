import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-casedetails",
  templateUrl: "./casedetails.component.html",
  styleUrls: ["./casedetails.component.scss"],
})
export class CasedetailsComponent {
  caseId: any;
  currentState:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentState = this.router.getCurrentNavigation()?.extras.state;
    console.log("currentState", this.currentState);
    console.log("part2", this.currentState?.data);
  }
  activeTab = "general";

  general(activeTab: any) {
    this.activeTab = activeTab;
  }

  details(activeTab: any) {
    this.activeTab = activeTab;
  }

  documents(activeTab: any) {
    this.activeTab = activeTab;
  }

  comments(activeTab: any) {
    this.activeTab = activeTab;
  }

  mail(activeTab: any) {
    this.activeTab = activeTab;
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.caseId = params["id"]; // Access the 'id' parameter from the URL
      console.log("Test ID:", this.caseId);
    });
  }
}
