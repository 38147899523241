import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";
interface CashlessProcessInstancesQuery {
  hospitalOrgId?: any; // Make hospitalOrgId optional
  startDate?: any;       // Make startDate and endDate optional
  endDate?: any;
}
@Injectable({
  providedIn: "root",
})
export class CashflowService {
  constructor(private router: Router, private http: HttpClient) {}
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  requestOptions = { headers: this.headers };

  errorHandle(error: any) {
    let errorMessage = "";
    if (error.error.error instanceof ErrorEvent) {
      errorMessage = error.error.error;
    } else {
      errorMessage = error.error;
    }

    return throwError(() => {
      return errorMessage;
    });
  }

  /*
  * orgType -> HOSPITAL or BG
  */
  

  getHospitalByOrgId(orgId:any){
    return this.http
     .get<any>(
        `${environment.API_URL}/onboarding/org/${orgId}/hospital` )
     .pipe(catchError(this.errorHandle));
  }

  getActiveHospitals(){
    return this.http
     .get<any>(
        `${environment.API_URL}/onboarding/activeHospitals` )
     .pipe(catchError(this.errorHandle));
  }

  getDeployedProcesses(){
    return this.http
     .get<any>(
        `${environment.API_URL}/bpmn/processes/deployed` )
     .pipe(catchError(this.errorHandle));
  }


  getInsuranceByHospitalId(hospitalId:any,rowNum:any, size:any){
    return this.http
      .get<any>(
        `${environment.API_URL}/onboarding/hospital/${hospitalId}/insurance?rowNum=${rowNum}&size=${size}`
      )
      .pipe(catchError(this.errorHandle));
  }

  getSocForInsurance(insuranceId:any){
    return this.http
    .get<any>(
      `${environment.API_URL}/insurance/${insuranceId}`
    )
    .pipe(catchError(this.errorHandle)); 
  }

  getTpa(tpaId:any) {
    return this.http
      .get<any>(`${environment.API_URL}/tpamaster/${tpaId}`)
      .pipe(catchError(this.errorHandle));
  }

  getOnboardedHospitals(){
    return this.http
    .get<any>(
      `${environment.API_URL}/onboarding/user/onboardedHospitals`
    )
    .pipe(catchError(this.errorHandle));
  }

  getOnboardedHospitalByUser(){
    return this.http
    .get<any>(
      `${environment.API_URL}/onboarding/user/onboardedHospitals`
    )
    .pipe(catchError(this.errorHandle));
  }

  getOnboardedHospitalsByOrgIds(orgIds:any){
    return this.http
    .get<any>(
      `${environment.API_URL}/onboarding/onboardedHospitals`,orgIds
    )
    .pipe(catchError(this.errorHandle));
  }

  getCaseList(id?:any,startDate?:any,endDate?:any){
      return this.http
        .get<any>(
          `${environment.API_URL}/bpmn/processinstances/cashless?hospitalOrgId=${id}&startDate=${startDate}&endDate=${endDate}`
          
        )
        .pipe(catchError(this.errorHandle));

  }

  getCategoryByTypeAndEntityType(taskName:any){
    return this.http
    .get<any>(
      `${environment.API_URL}/auth/categoryMaster/${taskName}`
      
    )
    .pipe(catchError(this.errorHandle));
  }
  getTPAList(rowNum: number, size: number){
    return this.http
    .get<any>(
      `${environment.API_URL}/tpaMaster?rowNum=${rowNum}&size=${size}`
      
    )
    .pipe(catchError(this.errorHandle));
  }

  // view
  getSelectedTask(id: any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/bpmn/process/cashlessProcess/instance/` + id
      )
      .pipe(catchError(this.errorHandle));
  }

  getCaseDetails(id:any){
    return this.http
    .get<any>(
      `${environment.API_URL}/bpmn/processinstancetasks/cashless/` + id
    )
    .pipe(catchError(this.errorHandle));
  }

 
  
  // view
  getSelectedHospitalOnBoardingProcessTask(id: any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/bpmn/process/hospitalOnBoardingProcess/instance/` + id
      )
      .pipe(catchError(this.errorHandle));
  }

  postFormData(id: any, data: any) {
    return this.http
      .post<any>(`${environment.API_URL}/bpmn/process/tasks/` + id,data)
      .pipe(catchError(this.errorHandle));
  }
  
  saveDraftForFormData(id:any,data:any){
    return this.http
      .post<any>(
        `${environment.API_URL}/bpmn/save/tasks/` + id,data)
      .pipe(catchError(this.errorHandle));

  }

  postStartProcess(id: any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/bpmn/start/process/cashlessProcess/hospital/` +id
      )
      .pipe(catchError(this.errorHandle));
  }
  startOnboardingProcess(id: any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/bpmn/start/process/hospitalOnBoardingProcess/hospital/` +
          id
      )
      .pipe(catchError(this.errorHandle));
  }
  
  getTaskCalculations(processName:any,taskName:any,inactive:any){
    return this.http.get<any>(`${environment.API_URL}/extended/process/calculatedAttributes/`+processName+"/"+taskName+"/"+inactive)
    .pipe(
      catchError(this.errorHandle)
    );
  }
 
  getPreauthDetails(id: any) {
    return this.http
      .get<any>(
        `${environment.API_URL}/bpmn/preauth/` +
          id
      )
      .pipe(catchError(this.errorHandle));
  }

  getHospitalSeal(id:any){
    return this.http.get<any>(`${environment.API_URL}/externalMedia/${id}/seal`)
    .pipe(
      catchError(this.errorHandle)
    );
  }

  downloadPreAuthForm(caseId:any) {
    return this.http.get<any>(`${environment.API_URL}/bpmn/preAuthForm/${caseId}`,{   responseType: 'blob' as 'json',})
    .pipe(
      catchError(this.errorHandle)
    );
  }
}
function buildQuery(yourOptions: any) {
  throw new Error("Function not implemented.");
}

