<div class="container-fluid p-3 ">

  <div class="p-3">
    <!-- <div mat-dialog-title>
            <div class="row">
              <div class="col-8 d-flex justify-content-start">
                <h1>Case Details</h1>
              </div>
          
            </div>
          </div> -->
    <div mat-dialog-title>
      <div class="row">
        <div class="col-8 d-flex justify-content-start">
          <h1>Case Details</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
          <mat-icon color="accent">cancel</mat-icon>
        </div>
      </div>
    </div>
    <div class="card-body">
      <!-- <div class="card" style="margin: 10px;">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Case Diagram
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="card-body" *ngIf="diagram != undefined">
                                <img [src]="diagram" class="img-fluid"/>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div> -->
      <!-- <div class="card" *ngIf="diagram != undefined">
                        <img [src]="diagram" />
                      </div> -->
      <!-- <div class="card" style="margin: 10px;"> -->

      <mat-table #table [dataSource]="dataSource">

        <ng-conatiner matColumnDef="process_inst_id">
          <mat-header-cell *matHeaderCellDef>Case ID</mat-header-cell>
          <mat-cell *matCellDef="let element" (click)="viewTaskDetails(element)"> {{element.process_inst_id}}
          </mat-cell>
        </ng-conatiner>
        
        
        <ng-conatiner matColumnDef="taskName">
          <mat-header-cell *matHeaderCellDef>Task Name</mat-header-cell>
          <mat-cell *matCellDef="let element" (click)="viewTaskDetails(element)"> {{element.task_name }} </mat-cell>
        </ng-conatiner>

        <ng-conatiner matColumnDef="assignee">
          <mat-header-cell *matHeaderCellDef>Assignee</mat-header-cell>
          <mat-cell *matCellDef="let element" (click)="viewTaskDetails(element)"> {{element.assignee }} </mat-cell>
        </ng-conatiner>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{getExecutionStatus(element)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <mat-header-cell *matHeaderCellDef>Start Date</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.tx_date |date}} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

    </div>
  </div>
  <div class="p-3">
    <mat-dialog-actions align="end">
      <button color="accent" mat-button mat-dialog-close>Cancel</button>

    </mat-dialog-actions>
  </div>
</div>


<!-- </div> -->