<div mat-dialog-title>
    <h1>Add User Roles</h1>
</div>
<form [formGroup]="userroleform" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>roles</mat-label>
                <input matInput type="text" placeholder="roles" readonly formControlName="roles" >
            </mat-form-field>
        </div> -->
        <div class="p-2">
            <mat-form-field  appearance="outline">
              <mat-label>User Role</mat-label>
              <mat-select formControlName="roles" [(ngModel)]="selectedType" name="role" multiple>
                <mat-option *ngFor="let role of userroles" [value]="role.id">
                    {{role.roleName}} 
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>userId</mat-label>
                <input matInput type="text" placeholder="id" formControlName="userId">
            </mat-form-field>
        </div> -->
        
        
        
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <!-- <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button> -->
        <button mat-raised-button color="primary" type="submit" [disabled]="!userroleform.valid">Save</button>

    </div>
</form>