<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1><strong>Policy Snapshot</strong> </h1>
        </div>
        <div class="col-4 d-flex justify-content-end " mat-dialog-close>
            <!-- <h4><i class="px-3" style="color:lightgray">* marked fields are mandatory </i></h4> -->
            <mat-icon color="accent">cancel</mat-icon>
          </div>

    </div>
</div>
<div class="row p-2">
    <div class="col">      
          <div class="card">
            <div class="card-header">
                <strong>Basic Information</strong>
            </div>
            <div class="card-body">

            <div class="table-responsive"> <table class="table table-striped">
                <tbody>
                    <tr>
                        <td>Age</td>
                        <td>{{ data.analyzedData.ageInYears }}</td>
                      </tr>
                      <tr>
                        <td>With PED</td>
                        <td>{{ data.analyzedData.withPED }}</td>
                      </tr>
                      <tr>
                        <td>Pre hospitalization days</td>
                        <td>{{data.analyzedData.preHospitalizationDays}}</td>
                      </tr>
                      <tr>
                        <td>Post hospitalization days</td>
                        <td>{{data.analyzedData.postHospitalizationDays}}</td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td>{{data.analyzedData.start_date | date}}</td>
                      </tr>
                      <tr>
                        <td>Policy Year</td>
                        <td>{{data.analyzedData.currentPolicyYear}}</td>
                      </tr>
                      <tr>
                        <td>Your Co-Pay amount</td>
                        <td>{{data.analyzedData.coPayPercent}}</td>
                      </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
</div><div class="col">
          <div class="card ">
            <div class="card-header">
                <strong>Benefits</strong>
            </div>
            <div class="card-body">

            <div class="table-responsive"> <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let benefit of data.analyzedData.benefits">
                    <td>{{ benefit.name }}</td>
                    <td>{{ benefit.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        <!-- <div class=" p-4 " *ngIf="data.analyzedData != undefined">

            <div class="row justify-content-md-center">
                <div class="col">Age</div>
                <div class="col">{{data.analyzedData.ageInYears}}</div>
            </div>

            <div class="row justify-content-md-center">
                <div class="col">With PED</div>
                <div class="col">{{data.analyzedData.withPED}}</div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col">Pre hospitalization days</div>
                <div class="col">{{data.analyzedData.preHospitalizationDays}}</div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col">Post hospitalization days</div>
                <div class="col">{{data.analyzedData.postHospitalizationDays}}</div>
            </div>

            <div class="row justify-content-md-center">
                <div class="col">Start Date</div>
                <div class="col">{{data.analyzedData.start_date | date}}</div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col">Policy Year</div>
                <div class="col">{{data.analyzedData.currentPolicyYear}}</div>
            </div>

            <div class="row justify-content-md-center">
                <div class="col">Your Co-Pay amount</div>
                <div class="col">{{data.analyzedData.coPayPercent}}</div>
            </div>
                 
              

            <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
            <div *ngIf="message" class="alert alert-success" role="alert">{{ message }}</div>
        </div> -->
    </div>
</div>
<div class="p-3">
    <mat-dialog-actions align="end">
        <button color="accent" mat-button mat-dialog-close>Cancel</button>

    </mat-dialog-actions>
</div>