import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { HospitalRegistrationService } from "../../services/hospitalregistration.service";
import { AdminService } from "../../services/admin.service";
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: "app-org-registration",
  templateUrl: "./org-registration.component.html",
  styleUrls: ["./org-registration.component.scss"],
})
export class OrgRegistrationComponent {
  faCoffee = faCoffee;
  form!: FormGroup | any;
  loading = false;
  submitted = false;
  //country ="India";
  customerdata: any;
  customerId: any;
  name: any;
  firstName: any;
  lastName: any;
  addLine1: any;
  city: any;
  state: any;
  country: any;
  zipcode: any;
  mobileNumber: any;
  email: any;
  partnerTypes: any = [];
  selectedType: any;
  selectedSrcType: any;
  selectedZoneType: any;
  selectedhospitalType: any;
  selectedhospitalCategory: any;
  clicked: boolean = false;

  selected: any;

  type: any;
  defaultRole: any;
  subType: any;
  sourceType: any;
  hospitalZone: any;
  hospitalType: any;
  hospitalCategory: any;

  firstFormGroup = this.formBuilder.group({
    firstCtrl: ["", Validators.required],
  });
  isLinear = true;

  searchControl = new FormControl();
  hospitals$: Observable<any[]> = of([]);
  isLoading = false;
  selectedhospital: any;
  userInput: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private HospitalRegistrationService: HospitalRegistrationService,
    private adminService: AdminService,
    private toastrService: ToastrService
  ) {}
  ngOnInit() {
    this.hospitals$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((value) => {
        this.isLoading = true;
        // console.log("Searching for:", value);
        // Clear form fields if the user re-enters the hospital name
        if (typeof value === "string" && this.userInput) {
          this.clearFormFields();
        }
        this.userInput = true;
      }),
      switchMap((value) =>
        typeof value === "string" && value.length > 0
          ? this.HospitalRegistrationService.hospital(value)
          : of([])
      ),
      tap((hospitals) => {
        this.isLoading = false;
        // console.log("Search complete", hospitals);
      }),
      catchError((error) => {
        this.isLoading = false;
        console.error("Error occurred:", error);
        return of([]);
      })
    );

    this.form = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        hospitalUniqueId: ["", Validators.required],
        lastName: ["", Validators.required],
        passwd: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.pattern(/^(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).*$/),
          ],
        ],
        confirmPassword: ["", Validators.required],
        addLine1: ["", Validators.required],
        city: ["", Validators.required],
        state: ["", Validators.required],
        website: [],
        country: ["", Validators.required],
        zipcode: ["", [Validators.required, Validators.minLength(6)]],
        mobileNumber: [
          "",
          [
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ],
        ],
        email: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
      },
      { validators: this.passwordMatchValidator }
    );

    this.getPartnerTypes();
  }

  passwordMatchValidator: ValidatorFn = (
    formGroup: AbstractControl
  ): { [key: string]: any } | null => {
    const password = formGroup.get("passwd");
    const confirmPassword = formGroup.get("confirmPassword");
    return password &&
      confirmPassword &&
      password.value !== confirmPassword.value
      ? { mismatch: true }
      : null;
  };

  // displayHospitalName(hospital: any): string {
  //   return hospital && hospital.name ? hospital.name : '';
  // }

  get f() {
    return this.form.controls;
  }

  displayHospitalName(hospital: any): string {
    return hospital && hospital.name ? hospital.name : "";
  }

  onOptionSelected(event: any) {
    // console.log(event);
    this.userInput = false;
    const selectedHospital = event.option.value;
    this.selectedhospital = selectedHospital;
    // this.selectedHospital = selectedHospital;
    this.form.patchValue({
      // searchControl: this.selectedhospital.name,
      hospitalUniqueId: this.selectedhospital.hospitalUniqueId,
      email: this.selectedhospital.emailId,
      mobileNumber: this.selectedhospital.contactDetail,
      website: "",
      addLine1: this.selectedhospital.address,
      city: this.selectedhospital.cityVillageTown,
      state: this.selectedhospital.state,
      country: "India", // Assuming country is India, modify as needed
      zipcode: this.selectedhospital.pinCode,
    });
    // console.log("Selected value:", this.selectedhospital);
    // Do something with the selected value, e.g., save it, display it, etc.
  }

  // updates when the selected value changes
  clearFormFields() {
    this.form.patchValue({
      hospitalUniqueId: "",
      email: "",
      mobileNumber: "",
      website: "",
      addLine1: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
    });
  }

  message: any = undefined;

  register() {
    Swal.fire({
      title: "Are you sure want to add hospital?",
      //text: 'You will not be able to recover this imaginary file!',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitted = true;
        if (this.form.invalid || this.firstFormGroup.invalid) {
          return;
        }
        const pType = this.partnerTypes.find(
          (x: { id: any }) => x.id == this.selectedType
        );
        if (!pType.defaultRole.includes("MOBILE_USER") && pType.defaultRole != null) {
          pType.defaultRole = pType.defaultRole + ";MOBILE_USER";
        }

        var reqdata = {
          name: this.selectedhospital.name,
          uniqueId: JSON.stringify(this.form.value.hospitalUniqueId),
          // uniqueId: this.form.value.hospitalUniqueId,
          firstName: this.form.value.firstName,
          lastName: this.form.value.lastName,
          passwd: this.form.value.passwd,
          addLine1: this.form.value.addLine1,
          addLine2: "",
          city: this.form.value.city,
          state: this.form.value.state,
          country: this.form.value.country,
          zipcode: this.form.value.zipcode,
          website: this.form.value.website,
          email: this.form.value.email,
          mobileNumber: this.form.value.mobileNumber,
          contactNos: this.form.value.mobileNumber,
          defaultRole: pType.defaultRole,
          type: pType.type,
          subType: pType.subType,
          landingPage: "home",
        };
        // console.log("json", reqdata);
        this.loading = true;

        // Display loading indicator
        Swal.fire({
          title: "Please wait...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        this.HospitalRegistrationService
          .register(reqdata)
          .pipe(first())
          .subscribe({
            next: () => {
              this.toastrService.success("Org is created successfully");
              this.loading = false;
              this.clear();
              this.router.navigate(["login"]);
              Swal.close(); // Close the Swal after the success response
            },
            error: (error) => {
              console.log("error " + JSON.stringify(error));
              this.message = error.error.error;
              this.toastrService.error(this.message);
              this.form.reset();
              //this.toastrService.error("g",error.error);
              this.clicked = false;
              this.loading = false;
              Swal.close(); // Close the Swal even in case of error
              //this.clear();
            },
          });

        // console.log('Clicked Yes, File deleted!');
      } else if (result.isDismissed) {
        //console.log('Clicked No, File is safe!');
        Swal.fire("Changes are not saved", "", "info");
        //this.clear();
        this.clicked = false;
      }
    });
  }

  clear() {
    this.form.value.controls = "";
    this.form.reset();
    this.form.clearValidators();
  }

  getPartnerTypes() {
    this.adminService.getPartnerTypes().subscribe((data: any) => {
      this.partnerTypes = data.filter(
        (x: { type: string; subType: string }) => x.type == "HOSPITAL"
      );
    });
  }
}
