
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add  Details</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" >
        <h4>
          <i class="px-3" style="color: lightgray">* marked fields are mandatory
          </i>
        </h4>
        <mat-icon mat-dialog-close color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="detailForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
      <div class="row">
       
       
  
        <mat-form-field appearance="outline">
          <mat-label>Info</mat-label>
          <input matInput type="text" placeholder="Info" formControlName="info" />
        </mat-form-field>
  
        <!-- <mat-form-field appearance="outline">
          <mat-label>Field Type</mat-label>
          <input matInput type="text" placeholder="Field Type" formControlName="fieldType" />
        </mat-form-field> -->
  
        <mat-form-field appearance="outline">
          <mat-label>Email Id</mat-label>
          <input matInput type="text" placeholder="Enter Email Id" formControlName="fieldValue" />
        </mat-form-field>
  
        <!-- <mat-form-field appearance="outline">
          <mat-label>Field Name</mat-label>
          <input matInput type="text" placeholder="Field Name" formControlName="fieldName" />
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>Field Category</mat-label>
          <input matInput type="text" placeholder="Field Category" formControlName="fieldCategory" />
        </mat-form-field> -->
      </div>
      <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">
          Cancel
        </button>
        <button mat-raised-button color="primary" type="submit" [disabled]="detailForm.invalid">Save</button>
      </div>
    </div>
  </form>