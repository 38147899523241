import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
import { first } from 'rxjs';



@Component({
  selector: 'app-update-email-conf',
  //standalone: true,
  //imports: [],
  templateUrl: './update-email-conf.component.html',
  styleUrl: './update-email-conf.component.scss'
})
export class UpdateEmailConfComponent {

  emailconfigForm: FormGroup;
  orgId:any;
  user:any;
  status: any;
  insuranceCompanyMasterData:any= []
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  selectedType:any;
  
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private HospitalRegistrationService: HospitalRegistrationService,
    private _dialogRef: MatDialogRef<UpdateEmailConfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    
    this.emailconfigForm = this._fb.group({
      id:[''],
      category: ['', Validators.required],
      emailId: ['', [Validators.required]],
      seqNo: ['', [Validators.required]],
      subjectFilter: ['', [Validators.required]],
      matchRegex: ['', Validators.required],
      matcherField: ['', Validators.required],
      matchSet: ['', Validators.required],
      inactive: ['', Validators.required],
      //insurance:['']



    });
  }


  // "id": "string",
  // "productId": "string",
  // "discountCode": "string",
  // "discountPercent": 0,
  // "discountNote": "string",
  // "startDate": "2023-11-01T05:05:36.709Z",
  // "endDate": "2023-11-01T05:05:36.709Z",
  // "maxCount": 0,
  // "partnerTypeId": 0,
  // "orgId": "string",
  // "dateCreated": "2023-11-01T05:05:36.709Z",
  // "createdBy": "string",
  // "inactive": true,
  // "currentCount": 0
  ngOnInit(): void {
    this.getInsuranceCompanyMaster();

  //  var user = localStorage.getItem("user")
  //   this.user=  user;
  //   this.user = this.orgId;
  //   console.log("orgid",this.user);
  this.status = this.data.inactive ? "Inactive" : "Activate";
  this.emailconfigForm.controls['inactive'].setValue(this.status);
    this.emailconfigForm.patchValue(this.data.data);
    // var data = this.user.orgId;
    console.log("ggd",this.data)
   }

   
  getInsuranceCompanyMaster() {
    this.adminService.getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.insuranceCompanyMasterData = data;
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          //this.message = error;

          //this.loading = false;
        }
      });
  }

  onFormSubmit() {
    if (this.emailconfigForm.valid) {
      if (this.data) {

        var data ={
          "id": this.data.data.id,
          "category": this.emailconfigForm.value.category,
          "emailId": this.emailconfigForm.value.emailId,
          "seqNo": this.emailconfigForm.value.seqNo,
          "subjectFilter": this.emailconfigForm.value.subjectFilter,
          "matchRegex":this.emailconfigForm.value.matchRegex,
          "matcherField": this.emailconfigForm.value.matcherField,
          "matchSet": this.emailconfigForm.value.matchSet,
          "inactive": this.emailconfigForm.value.inactive,
          "insurance":this.emailconfigForm.value.insurance,
          "senderId": this.data.senderId,
          "createdBy": "",
          "dateCreated": "",
          "updatedBy": "",
          "dateUpdated": "",
        }
        this.HospitalRegistrationService
          // .updateEmailConfig( this.emailconfigForm.value)
          .updateEmailConfig(data)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("Email parser conf has been updated successfully");


              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));


            },
          });
      } 
      }
    }
  
}