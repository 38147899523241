
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add hospital</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="userhospitalform" (ngSubmit)="onFormSubmit()" >
    <div mat-dialog-content>
        <div class="hospital-selection">
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Hospital Name</mat-label>
                <mat-select
                formControlName="hospitals" 
                  placeholder="Select a hospital"
                  [(ngModel)]="selectedHospital"
                >
                  <mat-option
                    *ngFor="let hospital of hospitalList"
                    [value]="hospital"
                  >
                    {{ hospital.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </div>
        
        
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="!userhospitalform.valid">Save</button>

    </div>
</form>