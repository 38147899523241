import { Component, OnInit } from "@angular/core";
import { AdminService } from "../services/admin.service";
import { Observable, first } from "rxjs";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AuthService } from "../services/authService";
import { MatDialog } from "@angular/material/dialog";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = "";
  user: any;
  imgData:any;
  fileInfos?: Observable<any>;
  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private _dialog: MatDialog
  ) {
    this.user = this.authService.userValue;
    this.authService.imgData.subscribe((x) => {
      
      this.imgData = x;
      //console.log(this.imgData);
    });
  }
  ngOnInit(): void {
    this.getUser();
    //console.log("profile", this.user);
    this.adminService.getProfilePic().subscribe({
      next: (data: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => (this.authService.imgData.next(e.target.result));
        reader.readAsDataURL(new Blob([data]));
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }


  getUser() {
    this.authService.getUserById(this.user.id).pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.user = data;
          //console.log("users data", data);
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //sthis.loading = false;
        }
      });
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  error: any;
  loading: any = false;

  uploadProfilePic(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.adminService.uploadProfilePic(this.currentFile).subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.fileInfos = this.adminService.getProfilePic();
              window.location.reload();
            }
          },
          error: (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.error) {
              this.error = err.error.error;
            } else {
              this.error = "Could not upload the file!";
            }

            this.currentFile = undefined;
          },
        });
      }

      this.selectedFiles = undefined;
    }
  }

  openEditForm() {
    const dialogRef = this._dialog.open(EditProfileComponent, {
      disableClose:true,
      data: this.user,
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getUser();
        }
      },
    });
  }
}
