import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";

@Component({
  selector: "app-update-tpa",
  templateUrl: "./update-tpa.component.html",
  styleUrls: ["./update-tpa.component.scss"],
})
export class UpdateTpaComponent {
  tpaAddForm: any = [];
  selectedFiles?: any;
  message: any;
  tpaData: any;
  category: any;
  entityType: any;
  tpatables: any;
  formData: FormData = new FormData();
  status: any;
  constructor(
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<UpdateTpaComponent>,
    private tpaService: ManagetpaService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tpaAddForm = this._fb.group({
      tpaName: ["", Validators.required],
      emailId: ["", [Validators.required]],
      address: ["", [Validators.required]],
      city: ["", [Validators.required, Validators.maxLength(20)]],
      state: ["", [Validators.required]],
      pincode: ["", [Validators.required, Validators.maxLength(7)]],
      contactNos: [
        "",
        [
          Validators.required,
          Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$"),
        ],
      ],
      // logo: [""],
      inactive: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.status = this.data.inactive ? "Inactive" : "Activate";
    this.tpaAddForm.controls["inactive"].setValue(this.status);
    this.tpaAddForm.patchValue(this.data);
    console.log(this.data);
  }

  onFormSubmit() {
    console.log("onFormSubmit");
    var addData = {
      //"id": this.couponForm.value.id,
      id: this.data.id,
      tpaName: this.tpaAddForm.value.tpaName,
      emailId: this.tpaAddForm.value.emailId,
      address: this.tpaAddForm.value.address,
      city: this.tpaAddForm.value.city,
      state: this.tpaAddForm.value.state,
      pincode: this.tpaAddForm.value.pincode,
      contactNos: this.tpaAddForm.value.contactNos,
      inactive: this.tpaAddForm.value.inactive,
      logo: this.data.logo,
    };
    this.tpaAdd(addData);
  }

  tpaAdd(addData: any) {
    this.tpaService.updateTpa(this.data.id, addData).subscribe({
      next: (data: any) => {
        this.tpaData = data.id;
        console.log(this.tpaData);
        // this.tpaAddMedia(this.tpaData, this.category, this.entityType);
        this.toastrService.success("Tpa is updated successfully");
        this._dialogRef.close(true);
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
        this.toastrService.error(JSON.stringify(error));
        this._dialogRef.close(true);
      },
    });
  }
}
