import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { AddAssignTaskComponent } from 'src/app/modal/add-assign-task/add-assign-task.component';
import { AuthService } from 'src/app/services/authService';
import { DataServiceService } from 'src/app/services/data-service.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { ProcessEkypComponent } from '../process-ekyp.component';
import { DataShareModel } from 'src/app/model/dataShareModel';
import { ViewProcessEkypComponent } from 'src/app/modal/view-process-ekyp/view-process-ekyp.component';
import { MatPaginator } from '@angular/material/paginator';
import { AddEkypDetailsComponent } from '../add-ekyp-details/add-ekyp-details.component';

@Component({
  selector: 'app-process-ekyp-inbox',
  templateUrl: './process-ekyp-inbox.component.html',
  styleUrl: './process-ekyp-inbox.component.scss'
})
export class ProcessEkypInboxComponent implements OnInit {
  tasks: any[] = [];
  error: any;
  message: any;
  user: any;
  allTasks:any;
  selectedTask: any;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  assignedTasks: any;
  displayedColumns: string[] = ['pendingTask', 'orderDate', 'orderId', 'customerName', 'customerMobileNumber', 'assignedTo', 'action'];
  dataSource = new MatTableDataSource<any>(this.tasks);
  constructor(
    private workflowService: WorkflowService,
    private dataService: DataServiceService,
    private _dialog: MatDialog,
    private authService: AuthService,
    private processekypcomponentservice: ProcessEkypComponent

  ) {

    this.authService.user.subscribe(x => {
      this.user = x;

    });
    this.dataService.allTasksData$.subscribe((data: any) => {
      if (data != null && data.getProcessName() == "ekypProcess") {
        this.allTasks = data.tasks;
        this.dataSource.data =this.allTasks;
      }
    });
  }
  ngOnInit(): void {
    this.getAllTasks();
    this.dataSource.paginator = this.paginator;
   
  }
  
  getAllTasks() {
    this.workflowService
      .getAllTask("ekypProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allTasks = data;
         const dataShare = new DataShareModel("ekypProcess",data);
          this.dataService.updateAllTasks(dataShare);
          
         // this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          //this.loading = false;
        },
      });
  }


  acceptTask(element: any) {
    this.selectedTask = element;
    //console.log(JSON.stringify(this.dynamicFormGroup.value));
    console.log('in accept task')
    this.workflowService
      .assignTask(this.selectedTask.id, this.user.id, new Date().toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.viewTask(element);
          this.getAllTasks();
          this.selectedTask = undefined;
          this.processekypcomponentservice.updateSelectedTab(2);

        },
        error: (error: { error: any; }) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);

        },
      });
  }

  assignTask(data: any) {
    //console.log(JSON.stringify(this.dynamicFormGroup.value));
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data: { data: data, roleName: ["ADMIN", 'CASHLESS', 'HNB'] },
      width: '430px',
      height: '200'
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        this.getAllTasks();
      },
    });

  }


  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  viewTask(task:any){
      const dialogRef = this._dialog.open(ViewProcessEkypComponent,{
        disableClose:true,
        data: task ,
        width: '100vw',  // Sets the width to 100% of the viewport width
        maxWidth: '100vw',  // Ensures the max width doesn't override the width
        panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
        height: '100%'
      });

      // const dialogRef = this._dialog.open(AddEkypDetailsComponent,{
      //   disableClose:true,
      //   data: task ,
      //   width: '100vw',  // Sets the width to 100% of the viewport width
      //   maxWidth: '100vw',  // Ensures the max width doesn't override the width
      //   panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      //   height: '100%'
      // });

      dialogRef.afterClosed().subscribe({
        next:(val:any)=>{
          console.log(val);
          this.getAllTasks();
        }
      })
  }



}

