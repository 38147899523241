<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1 *ngIf="data.type == 'edit'">Edit Email LookUp</h1>
            <h1 *ngIf="data.type == 'add'">Add Email LookUp</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
            <h4>
                <i class="px-3" style="color: lightgray">* marked fields are mandatory
                </i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div>

<div class="container py-3 px-2">
   <form [formGroup]="addEmailLookUp" (ngSubmit)="saveEmailLookup()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>SenderType</mat-label>
                <!-- <input matInput type="text" placeholder="SenderType" formControlName="senderType"> -->
                <mat-select formControlName="senderType" placeholder = "SenderType">
                    <mat-option value="INSURANCE">INSURANCE</mat-option>
                    <mat-option value="TPA">TPA</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Task Def Key</mat-label>
                <!-- <input matInput type="text" placeholder="taskDefKey" formControlName="taskDefKey"> -->
                <mat-select placeholder="Task Def Key" formControlName="taskDefKey">
                    <mat-option *ngFor="let task of deployedprocessByProcessDefTasks" [value]="task.taskId">{{task.taskName}}</mat-option> 
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>LookupPhrase</mat-label>
                <input matInput type="text" placeholder="LookupPhrase" formControlName="lookupPhrase">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>EmailLookupStrategy</mat-label>
                <!-- <input matInput type="text" placeholder="emailLookupStrategy" formControlName="emailLookupStrategy"> -->
                <mat-select placeholder="EmailLookupStrategy"  formControlName="emailLookupStrategy">
                    <mat-option value="SUBJECT">SUBJECT</mat-option>
                    <mat-option value="BODY">BODY</mat-option>
                    <mat-option value="ATTACHMENT">ATTACHMENT</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="data.type == 'add'">
        <button mat-raised-button type="button" color="warm" (click)="addLookupDetails()">
            Add Lookup Details
        </button>
        
    </div>
    <div>
        <mat-table [dataSource]="detailsTable">
            <ng-container matColumnDef="fieldName">
                <mat-header-cell *matHeaderCellDef>Field Name</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fieldType">
                <mat-header-cell *matHeaderCellDef>Field Type</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldType}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fieldPattern">
                <mat-header-cell *matHeaderCellDef>Field Pattern</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldPattern}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dataLookupStrategy">
                <mat-header-cell *matHeaderCellDef>LookUp Strategy</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wrap-text">{{element.dataLookupStrategy}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fieldValue">
                <mat-header-cell *matHeaderCellDef>Field Value</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldValue}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fieldMatchCondition">
                <mat-header-cell *matHeaderCellDef>Field Matching Condition</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldMatchCondition}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                <!-- <mat-cell *matCellDef="let element" class="wrap-text">{{element.fieldMatchCondition}}</mat-cell> -->
                <mat-cell *matCellDef="let element" class="wrap-text">
                    <button mat-icon-button type="button" (click)="editLookupDetails(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row ;columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">
            Cancel
        </button>
        <button mat-raised-button color="primary" type="submit" [disabled]="this.addEmailLookUp.invalid">
            Save
        </button>
    </div>
   </form>
</div>
