<div mat-dialog-title>
  <h1>Update User Profile</h1>
</div>
<form [formGroup]="profileForm" (ngSubmit)="onFormSubmit()">
  <div mat-dialog-content class="content">
    <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id" readonly >
            </mat-form-field>
        </div> -->
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>firstName</mat-label>
        <input
          matInput
          type="text"
          placeholder="firstName"
          formControlName="firstName"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>lastName</mat-label>
        <input matInput placeholder="lastName" formControlName="lastName" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Landing Page</mat-label>
        <input matInput placeholder="Landing Page" formControlName="landingPage" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>address</mat-label>
        <input
          matInput
          type="email"
          placeholder="address"
          formControlName="address"
        />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>city</mat-label>
        <input matInput type="text" placeholder="city" formControlName="city" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>state</mat-label>
        <input
          matInput
          type="text"
          placeholder="state"
          formControlName="state"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>country</mat-label>
        <input
          matInput
          type="text"
          placeholder="country"
          formControlName="country"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>emailId</mat-label>
        <input
          matInput
          type="text"
          placeholder="emailId"
          formControlName="emailId"
          readonly=""
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>mobilePhone</mat-label>
        <input
          matInput
          type="number"
          pattern="[0-9]*"
          maxlength="10"
          placeholder="mobilePhone"
          formControlName="mobilePhone"
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="action">
    <button mat-raised-button type="button" [mat-dialog-close]="false">
      Cancel
    </button>
    <button mat-raised-button color="primary" type="submit">
      {{ data ? "Update" : "Save" }}
    </button>
  </div>
</form>
