import   { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-claim-orders-detail-agent',
  templateUrl: './claim-orders-detail-agent.component.html',
  styleUrls: ['./claim-orders-detail-agent.component.scss']
})
export class ClaimOrdersDetailAgentComponent {
  claimDetailForm: FormGroup;
  orgId:any;
  user:any
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<ClaimOrdersDetailAgentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    this.claimDetailForm = this._fb.group({
      id:'',
      amount:['',Validators.required],
      transactionDate:['',Validators.required],
      transactionStatus:['',Validators.required],
      gst: ['',Validators.required],
      agreementAccepted:['',Validators.required],
      couponId:['',Validators.required],
      discountPercent:['',Validators.required],
     // orgId:'',
     memberName:['',Validators.required],
     orderSource:['',Validators.required],
     policyNumber:['',Validators.required],
     productId:['',Validators.required],
     refOrderId:['',Validators.required],
    // orgId:'',
    refSignature:['',Validators.required],
    totalAmount:['',Validators.required],
    transactionId:['',Validators.required],
    userId:['',Validators.required],
    });
  }

  ngOnInit(): void {
    this.claimDetailForm.patchValue(this.data);
   }

 
}
