import { Component } from '@angular/core';

@Component({
  selector: 'app-vendor-support',
  templateUrl: './vendor-support.component.html',
  styleUrls: ['./vendor-support.component.scss']
})
export class VendorSupportComponent {

}
