import { HttpErrorResponse, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { first, map, Observable } from "rxjs";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { CashflowService } from "src/app/services/cashflow.service";
import { FileService } from "src/app/services/file.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-insurance",
  templateUrl: "./insurance.component.html",
  styleUrls: ["./insurance.component.scss"],
})
export class InsuranceComponent {
  insuranceData: any = [];
  dataSource = new MatTableDataSource<any>(this.insuranceData);
  displayedColumns: string[] = ["insuranceName", "socId", "dateCreated"];
  clicked: boolean = false;
  form: FormGroup = this.formBuilder.group({});
  fileInfos?: Observable<any>;
  formData: FormData = new FormData();
  insuranceCompanyId: any;
  insuranceCompanies: any;
  message: any;
  selectedFiles?: any;
  selectedTaskDetails: any;
  uploadedData: any[] = [];
  length = 100;
  pageSize = 10;
  submitted: boolean = false;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  uploadedMediaData: any = [];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent: PageEvent = new PageEvent();
  blacklisted: any;
  claims: any;
  selectedCategory: any;
  categoryList: any;
  startDate: any;
  endDate: any;
  payorType: any = "INSURANCE";
  progress: any;
  loading: boolean = false;

  constructor(
    private hospitalRegistrationService: HospitalRegistrationService,
    private cashlessService: CashflowService,
    private fielService: FileService,
    private formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<InsuranceComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileService: FileService,

  ) {
    this.form = this.formBuilder.group({
      selectInsuranceCompanies: ["", Validators.required],
      blacklisted: ["", Validators.required],
      selectedCategory: ["", Validators.required],
      claimSubmissionMethod: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      upload:["", Validators.required]
    });
  }

  ngOnInit(): void {
    console.log(this.data);
    // console.log("this.data" + this.data.taskName);
    if (this.payorType == "INSURANCE") {
      this.getInsuranceCompanies();
    } else {
      //this.getTpaCompanies();
    }


    // this.getUploadedHospitalMedia();
    this.getHospitalInsurance();
    this.getCategory(this.data.sourceType);
  }

  getCategory(taskName: any) {
    console.log(taskName);
    this.cashlessService
      .getCategoryByTypeAndEntityType(taskName)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.categoryList = data;
          this.selectedCategory = this.categoryList[0].id.categoryName;
        },
      });
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  close() {
    if (this.message == undefined) {
      this._dialogRef.close("FAILED");
    } else {
      this._dialogRef.close("UPLOADED");
    }
  }

  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
  }
  compareWithFn(o1: any, o2: any) {
    console.log("compare with");

    return o1 === o2;
  }

  selectCategory(event: any): void {
    //console.log(event.target.value);
    this.selectedCategory = event.target.value;
  }

  // UPLOADING FILE Separate the attributes for uploading a file startDate,endDate,file, category

  uploadFiles() {
    this.formData.append("startDate", new Date(this.startDate).toISOString());
    this.formData.append("endDate", new Date(this.endDate).toISOString());
    this.formData.append("file", this.selectedFiles);
    this.formData.append("category", "WORKFLOW");
    this.formData.append("sourceType", this.data.sourceType);/// put the the task defination name
    this.formData.append("entityType", "HOSPITAL");
    this.formData.append("entityId", this.data.entityId); /// put the hospital ID
    this.formData.append("docType", this.selectedCategory);


    this.fileService.addExternalMedia(this.formData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          console.log(event);
          this.selectedFiles = undefined
          this.saveInsuranceTPA(event.body.id);

          // window.location.reload();
        } else if (event instanceof HttpErrorResponse) {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          this.toastrService.error(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
        }else{

        }
      })
    )
      .subscribe({
        next: (data: any) => {
        },
        error: (error) => {
          this.toastrService.success(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });
  }

  saveInsuranceTPA(socId: any) {
    var submitData = {
      "hospitalOrgId": this.data.entityId,
      "insuranceId": this.insuranceCompanyId,
      "blacklisted": this.blacklisted,
      "claimSubmissionMethod": this.claims,
      "inactive": "false",
      "payorType": this.data.payorType,
      "socId": socId
    };
    this.hospitalRegistrationService.addHospitalInsurance(submitData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          this.toastrService.success(this.selectedCategory, " File has been uploaded successfully");
          this.form.reset();
          this.clicked = false;
          this.progress = 0;
          this.selectedFiles = undefined;
          this.getHospitalInsurance();

        } else {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          // this.toastrService.error(
          //   this.selectedCategory,
          //   " file couldn't be uploaded. Try again!"
          // );
        }
      })
    )

      .subscribe({
        next: (data: any) => {
        },
        error: (error) => {
          this.toastrService.success(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });

  }



  onChangeInsuranceCompanyId(arg0: any) {
    if (arg0) {
      this.insuranceCompanyId = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }
  onChangeInsuranceCompanyblacklisted(arg0: any) {
    if (arg0) {
      this.blacklisted = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }
  onChangeInsuranceCompanyclaims(arg0: any) {
    if (arg0) {
      this.claims = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }

  getInsuranceCompanies() {
    this.hospitalRegistrationService.getInsuranceCompany().subscribe({
      next: (data: any) => {
        // console.log(data);
        this.insuranceCompanies = data;
        // console.log("Get Users by Manager Array:" + JSON.stringify(data));
      },
      error: (error) => {
        // console.log("error " + JSON.stringify(error));
        this.message = error;
      },
    });
  }

  getUploadedHospitalMedia() {
    this.fileService
      .getExternalMediaList(
        this.data.id,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          // console.log("....", data);
          this.uploadedData = data;
          // console.log("Get Users by Manager Array:" + JSON.stringify(data));
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  getHospitalInsurance() {
    this.loading = true;
    this.hospitalRegistrationService
      .gethospitalInsurance(
        this.data.entityId,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          console.log("....", data);
          this.uploadedData = data;
          this.loading = false;
          this.dataSource.data = this.uploadedData;
          // console.log("Get Users by Manager Array:" + this.dataSource.data);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
          this.loading = false;
        },
      });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getHospitalInsurance();
  }



}
