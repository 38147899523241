<!--<section class="vh-100 d-flex justify-content-center align-items-center">-->
<div class="container-fluid ">
    <div class="p-5  bg-light ">
        <div class="row justify-content-md-center">

            <div class="col ">
                <div class="p-5 container text-center">
                    <a href="https://www.bimagarage.com"><img src="../../assets/img/logo.png"></a>


                    <h1 class="display-8 ">Welcome to Bima<span style="color:#C00000;">Garage</span>!</h1>
                    <p>
                        If you don't have a login, request for one!
                        And while we get you onboarded, take a sip from your favourite <fa-icon
                            [icon]="faCoffee"></fa-icon>
                    </p>
                    <p>Or you can call us anytime on our toll free number +91 844 844 4158 </p>


                </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col col-lg-4 justify-content-md-center">
                <div class="container text-center shadow-lg p-5 bg-white rounded-5">
                    <div class="p-3 text-sm">
                        <h2>User Login</h2>
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                        </div>

                        <!-- Email input -->

                        <mat-form-field>
                            <input matInput type="email" matInput id="username" formControlName="username"
                                placeholder="Enter your email address" />
                        </mat-form-field>
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Username is required</div>                        
                        </div>


                        <!-- Password input -->
                        <div class="form-outline mb-2">
                            <mat-form-field>
                                <!-- <input type="password" matInput id="form3Example4" formControlName="password"
                                 placeholder="Enter password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" /> -->
                                <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'"
                                    id="form3Example4" formControlName="password" placeholder="Enter password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                                    'visibility'}}</mat-icon>
                            </mat-form-field>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>

                        
                        <div class="text-center text-lg  pt-2 d-grid gap-2">
                            <button type="submit" [disabled]="loading" class="btn btn-danger btn-lg d-grid gap-2">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Login
                            </button>

                        </div>
                        <div class="text-center mt-2 pt-1 text-sm">
                            Not a member? <a href="#" style="--bs-link-opacity: .5" class="link-secondary text-center" routerLink="/regSelection"
                                routerLinkActive="active" ariaCurrentWhenActive="page">Sign up</a>
                        </div>
                        <div class="text-center">
                            <a href="#" class="link-secondary text-center text-sm-end " style="--bs-link-opacity: .5"
                                routerLink="/forgetPassword" routerLinkActive="active"
                                ariaCurrentWhenActive="page">Forgot password?</a>
                        </div>
                        <div class="text-center mt-2 pt-1 text-sm">

                            By logging in, you agree to BimaGarage's <a class="link-secondary"
                                style="--bs-link-opacity: .5" href="https://bimagarage.com/privacy-policy/">Privacy
                                Policy</a> and <a class="link-secondary" style="--bs-link-opacity: .5"
                                href="https://bimagarage.com/terms-and-conditions/">Terms of
                                Use.</a>

                        </div>
                        <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
                            {{message}}
                        </div>

                    </form>

                </div>
            </div>
        </div>



    </div>
</div>
<!--</section>-->