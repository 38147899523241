import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { UploadPolicyFileComponent } from './upload-policy-file/upload-policy-file.component';

@Component({
  selector: 'app-add-kyp-user',
  templateUrl: './add-kyp-user.component.html',
  styleUrl: './add-kyp-user.component.scss'
})
export class AddKypUserComponent implements OnInit  {
  form!:FormGroup;
  userId:any;
  constructor(
    private dialogRef: MatDialogRef<AddKypUserComponent>,
    private formBuilder: FormBuilder,
    private dialog:MatDialog,
    private customerService: CustomerService,
    private toastr: ToastrService,
   
   ) 
   {}
  ngOnInit(): void {
     this.form = this.formBuilder.group({
      contactNumber:["", Validators.required],
      firstname:["", Validators.required],
      lastname:["", Validators.required],
      emailId:["", Validators.required],
      // gender:["", Validators.required],
      // dob:["", Validators.required],
      address:["", Validators.required],
      city:["", Validators.required],
      state:["", Validators.required],
      zipcode:["", Validators.required],
      country:["", Validators.required],
      userType:[""],
     })
  }

  submitForm(){

   
    var user = {
      "contactNumber":this.form.value.contactNumber,
      "firstname":this.form.value.firstname,
      "lastname":this.form.value.lastname,
      "emailid-email":this.form.value.emailId,
      "userType": this.form.value.userType,
      "address": this.form.value.address,
      "city": this.form.value.city,
      "state": this.form.value.state,
      "country": this.form.value.country,
      "zipcode": this.form.value.zipcode,
     
      
    }
    console.log(user);

    this.customerService.postCreateHobNobUserForEkyp(user)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        console.log(data);
        this.userId = data.userId
        this.addPolicyFile();
        // this.form.reset();
    }
  }),
    (error:any)=>{
      console.log(error);
      
    };

  }

  addPolicyFile(){
    const dialog = this.dialog.open(UploadPolicyFileComponent,{
      disableClose:true,
      data:this.userId,
      height: '60%',
      width: '40%',
    });
    dialog.afterClosed().subscribe(() => {
      this.toastr.success("User created successfully");
      this.dialogRef.close();

    });
  }

}
