import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

const USER_KEY = "user";

@Injectable({ providedIn: 'root' })
export class CustomerService {


  constructor(
    private router: Router,
    private http: HttpClient
  ) {

  }
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  requestOptions = { headers: this.headers };

  getHealthFiles(){
    return this.http.get<any>(`${environment.API_URL}/auth/healthCloud/healthData`)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getHealthFilesByUserIdForAgent(userId:any){
    return this.http.get<any>(`${environment.API_URL}/auth/healthCloud/healthData/agent/`+userId)
      .pipe(
        catchError(this.errorHandle)
      );
  }


  getHealthFilesByUserId(userId:any){
    return this.http.get<any>(`${environment.API_URL}/auth/healthCloud/healthData/`+userId)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getHealthCloudFile(id:any) {
    const httpOptions = {
      // responseType: 'arraybuffer' as 'json'
      // 'responseType'  : 'arraybuffer' as 'json'
      responseType: 'blob' as 'json'        //This also worked
    };
    return this.http.get<any>(`${environment.API_URL}/auth/healthCloud/healthFile/`+id,httpOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }
/*
  getHospitalMedia(hospitalMediaId:any) {
    const httpOptions = {
      // responseType: 'arraybuffer' as 'json'
      // 'responseType'  : 'arraybuffer' as 'json'
      responseType: 'blob' as 'json'        //This also worked
    };
    return this.http.get<any>(`${environment.API_URL}/hospitalmedia/`+hospitalMediaId,httpOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }
 
  getExternalMedia(externalMediaId:any) {
    const httpOptions = {
      // responseType: 'arraybuffer' as 'json'
      // 'responseType'  : 'arraybuffer' as 'json'
      responseType: 'blob' as 'json'        //This also worked
    };
    return this.http.get<any>(`${environment.API_URL}/externalMedia/`+externalMediaId,httpOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }
*/
  getPolicies() {
    return this.http.get<any>(`${environment.API_URL}/ekyp/user/policies`)
      .pipe(
        catchError(this.errorHandle)
      );
  }
  getPolicieByPolicyId(policyId:any) {
    return this.http.get<any>(`${environment.API_URL}/ekyp/user/policies/`+policyId)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getPolicy(userId:any) {
    return this.http.get<any>(`${environment.API_URL}/ekyp/user/policy/`+userId)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  postCreateHobNobUserForEkyp(user:any){
    return this.http.post<any>(`${environment.API_URL}/ekyp/createHobnobUser`, user, this.requestOptions)
     .pipe(
        catchError(this.errorHandle)
      );
  }

  getPolicyFile(policyId:any) {
    const httpOptions = {
      // responseType: 'arraybuffer' as 'json'
      // 'responseType'  : 'arraybuffer' as 'json'
      responseType: 'blob' as 'json'        //This also worked
    };
    
    return this.http.get<any>(`${environment.API_URL}/auth/policy/`+policyId,httpOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getCaseStatus(caseId:any, rowNum:any,size:any) {
    return this.http.get<any>(`${environment.API_URL}/claims/case/status?caseId=`+caseId+"&rowNum="+rowNum+"&size="+size)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  // getClaimOrders() {
  //   return this.http.get<any>(`${environment.API_URL}/hobnob/orders?rowNum=0&size=20`)
  //     .pipe(
  //       catchError(this.errorHandle)
  //     );
  // }

  getClaimOrders(rowNum: number, size: number) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http.get<any>(`${environment.API_URL}/hobnob/orders?rowNum=` + rowNum + `&size=` + size, this.requestOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }
  getProcessEkypDetailsByPolicyId(rowNum:any, size:any,policyNumber: string,memberName:any){
    return this.http.get<any>(`${environment.API_URL}/ekyp/search/policies?rowNum=${rowNum}&size=${size}&policyNumber=${policyNumber}&memberName=${memberName}`,this.requestOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  

  getAgentOrders() {
    return this.http.get<any>(`${environment.API_URL}/hobnob/agent/orders?rowNum=0&size=20`)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getProducts() {
    return this.http.get<any>(`${environment.API_URL}/hobnob/products`)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  isDiscountsAvailable(productId:any) {
    return this.http.get<any>(`${environment.API_URL}/hobnob/discountCouponAvailable/product/`+productId,this.requestOptions)
      .pipe(map(data => {
        return data;
      }));
  }

  applyCoupon(productId:any, couponCode:any) {
    return this.http.get<any>(`${environment.API_URL}/hobnob/applyCoupon/product?productId=`+productId+"&couponCode="+couponCode,this.requestOptions)
      .pipe(map(data => {
        return data;
      }));
  }



  uploadPolicy(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('files', file);

    const req = new HttpRequest('POST', `${environment.API_URL}/ekyp/analyze`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  uploadFile(formData: FormData, type:any, category:any): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${environment.API_URL}/auth/uploadFile/`+type+"/"+category, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  createOrder(order: any) {
    return this.http.post<any>(`${environment.API_URL}/hobnob/order`, order,this.requestOptions)
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return data;
      }));
  }

  getPhonepePaymentUrl(orderId: string, amount: any) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http.get<any>(`${environment.API_URL}/phonepe/paymentUrl?orderId=` + orderId + `&amount=` + amount, this.requestOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  startProcess(processId: any) {
    return this.http.post<any>(`${environment.API_URL}/bpmn/start/process/`+processId,this.requestOptions)
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return data;
      }));
  }


  addkyp(kypdata:any) {
    return this.http.post<any>(`${environment.API_URL}/ekyp/manual`,kypdata)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  
  updateOrder(orderId: any,status:any): Observable<any> {
    return this.http.patch<any>(`${environment.API_URL}/hobnob/order?orderId=`+orderId+'&status='+status,{}).pipe(
      catchError(this.errorHandle)
    );  }



  analyzePolicy(policyId: any, memberName: any): Observable<HttpEvent<any>> {
    return this.http.post<any>(`${environment.API_URL}/ekyp/analyze?policyId=` + policyId + '&memberName=' + memberName, {}).pipe(
      catchError(this.errorHandle)
    );
  }

  hospitalLocator(locatorReg:any) {
    return this.http.post<any>(`${environment.API_URL}/locator/locate/hospitals`,locatorReg)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  uploadHealthFilesForUser(file: File,userId:any,selectedCategory:any): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('files', file);

    const req = new HttpRequest('POST', `${environment.API_URL}/auth/uploadFile/`+'health_cloud/'+selectedCategory+'/'+userId, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
  getUserByRoleName(roleName: string) {
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http.post<any>(`${environment.API_URL}/bpmn/user/roles`, roleName, this.requestOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  claimCheck(categoryId:any,fileId:any){
    const req = new HttpRequest('GET', `${environment.API_URL}/claimcheck/evaluate?policyId=`+fileId+'&summaryId='+categoryId, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  errorHandle(error: any) {
    let errorMessage = '';
    if (error.error.error instanceof ErrorEvent) {
      errorMessage = error.error.error;
    } else {
      errorMessage = error.error;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
  
}