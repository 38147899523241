<div class="container p-3">
  <div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>View mail parser config</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
    <div
    class="addbtn"
  >
  <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
    Add 
  </button>
  </div>

    <div class="row p-3 pt-3 justify-content-md-center">
      <input
        type="search"
        (input)="handleUInput($event)"
        
        class="form-control"
        placeholder="Filter"
        aria-label="Search"
      />
    </div>
   
      <mat-table  class="pt-3" [dataSource]="emailConfig" matSort>
        <!-- Position Column -->
        <ng-container matColumnDef="Id">
          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
        </ng-container>
  
        <!-- Position Column -->
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef> category </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.category }} </mat-cell>
        </ng-container>
      
  
        <!-- Name Column -->
        <ng-container matColumnDef="emailId">
          <mat-header-cell *matHeaderCellDef> emailId </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.emailId }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="matchRegex">
          <mat-header-cell *matHeaderCellDef>matchRegex </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.matchRegex }} </mat-cell>
        </ng-container>
  
        <!-- Weight Column -->
     
        <!-- Weight Column -->
        <ng-container matColumnDef="matchSet">
          <mat-header-cell *matHeaderCellDef>matchSet </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.matchSet }} </mat-cell>
        </ng-container>
  
        <!-- Weight Column -->
        <ng-container matColumnDef="subjectFilter">
          <mat-header-cell *matHeaderCellDef> subjectFilter </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.subjectFilter }} </mat-cell>
        </ng-container>
  
        <!-- Symbol Column -->
     
        <!-- Symbol Column -->
        <ng-container matColumnDef="City">
          <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.city }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="State">
          <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.state }} </mat-cell>
        </ng-container>
  
     
        <ng-container matColumnDef="Website">
          <mat-header-cell *matHeaderCellDef> Website </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.website }} </mat-cell>
        </ng-container>
  
        <!-- Symbol Column -->
        <ng-container matColumnDef="Contact">
          <mat-header-cell *matHeaderCellDef> Contact </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.contactNos }}
          </mat-cell>
        </ng-container>
     
        <ng-container matColumnDef="Action">
          <mat-header-cell  style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
            Action
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <!-- <button mat-icon-button color="primary" (click)="openEditForm(row)">
              <mat-icon>edit</mat-icon>
            </button> -->
  
            <button
            mat-icon-button
            style="color:#8e0101;"
            (click)="openEditForm(row)"
            matTooltip="Edit Form"
            matTooltipPosition="below"
          >
            <mat-icon>edit</mat-icon>
          </button>
            <!-- <button mat-icon-button color="warn">
                      <mat-icon>delete</mat-icon>
                    </button> -->
          </mat-cell>
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    
      <div class="demo-toggles">
        <br>
        <mat-paginator
          #paginator
          class="demo-paginator"
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageIndex"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    <div
      class="alert alert-danger mt-2 pt-1"
      role="alert"
      *ngIf="message != undefined"
    >
      {{ message }}
    </div>
  </div>
  