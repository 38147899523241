import { Component } from '@angular/core';

@Component({
  selector: 'app-aggregator',
  templateUrl: './aggregator.component.html',
  styleUrls: ['./aggregator.component.scss']
})
export class AggregatorComponent {

}
