<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Role with Privilages</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <ul>
    <li *ngFor="let data of filteredArr">
      <span>&#10003; {{ data.privilege }}</span>
    </li>
  </ul>
</div>
