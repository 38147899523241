import { Component } from '@angular/core';

@Component({
  selector: 'app-hobnob-home',
  templateUrl: './hobnob-home.component.html',
  styleUrls: ['./hobnob-home.component.scss']
})
export class HobnobHomeComponent {

}
