<div class="container-fluid py-1">
  <div class="container row justify-content-md-center">
    <div class="col-10">
      <div class="p-1 container text-center">
        <h4>Manage your profile.</h4>
      </div>
      <div class="spinner-border text-secondary" *ngIf="loading" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="col-2">
      <div class="addbtn">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="openEditForm()"
        >
          Edit
        </button>
      </div>
    </div>
  </div>

  <!-- profile -->
  <div class="container">
    <div class="main-body">
      <div class="row gutters-sm">
        <div class="col-md-4 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center text-center">
                <img
                  [src]="imgData"
                  *ngIf="imgData != false"
                  alt="Admin"
                  class="rounded-circle"
                  width="150"
                />
                <div class="mt-3">
                  <h4>Edit Profile Image</h4>
                  <input
                    type="file"
                    class="form-control"
                    (change)="selectFile($event)"
                  />
                  <div class="mt-2 d-block">
                    <button
                      class="btn btn-outline-success btn-sm"
                      [disabled]="!selectedFiles"
                      (click)="uploadProfilePic()"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Full Name</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ user.firstName }} {{ user.lastName }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Email</h6>
                </div>
                <div class="col-sm-9 text-secondary">{{ user.emailId }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Contact Number</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ user.mobilePhone }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Landing Page</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ user.landingPage }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Address</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ user.address }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">City</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ user.city }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">State</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ user.state }}
                </div>
              </div>
              <!-- <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Zip</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ user.zip }}
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- profile -->

  <!-- <div class="row justify-content-md-center">
    <div class="col-8 text-center">
      <label class="btn btn-default p-0">
        <input type="file" (change)="selectFile($event)" />
      </label>
    </div>

    <div class="col-4">
      <button
        class="btn btn-success btn-sm"
        [disabled]="!selectedFiles"
        (click)="uploadProfilePic()"
      >
        Upload
      </button>
    </div>
  </div> -->

  <div *ngIf="currentFile" class="progress my-3">
    <div
      class="progress-bar progress-bar-info progress-bar-striped"
      role="progressbar"
      attr.aria-valuenow="{{ progress }}"
      aria-valuemin="0"
      aria-valuemax="100"
      [ngStyle]="{ width: progress + '%' }"
    >
      {{ progress }}%
    </div>
  </div>

  <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
  <div *ngIf="message" class="alert alert-success" role="alert">
    {{ message }}
  </div>

  <div class="card mt-3">
    <div class="card-header">List of Files</div>
    <ul class="list-group list-group-flush">
      <li *ngFor="let file of fileInfos | async" class="list-group-item">
        <a href="{{ file.url }}">{{ file.name }}</a>
      </li>
    </ul>
  </div>
</div>
