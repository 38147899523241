<!-- <div class="container py-1 p-5   p-4" > -->
<!-- <div class="container mh-800 card overflow-y-scroll" > -->
<section>

  <div class="container" style="overflow: none">
    <mat-tab-group  #tabGroup class="p-3" [selectedIndex]="selectedTab">
      <mat-tab label="Dashboard">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">dashboard</mat-icon>
          Dashboard
        </ng-template>
        <app-workflow-dashboard [dashboardParam]="getDashboardParam()"></app-workflow-dashboard>
      </mat-tab>
      <mat-tab label="Inbox">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">inbox</mat-icon>
          Inbox 
        </ng-template>
        <app-process-ekyp-inbox></app-process-ekyp-inbox>
        
      </mat-tab>
      <!-- <mat-tab label="Assignment (Pending Tasks)">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">assignment</mat-icon>
          Assignments (Pending Tasks)
        </ng-template>
        <app-assignment-ekyp></app-assignment-ekyp>
      </mat-tab> -->

    </mat-tab-group>
  </div>

</section>
<!-- <div *ngIf="IsAssign">
              effwef
          </div> -->