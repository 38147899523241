import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-message',
  templateUrl: './confirmation-message.component.html',
  styleUrl: './confirmation-message.component.scss'
})
export class ConfirmationMessageComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationMessageComponent>,
  ){

  }

  onCancel(){
    this.dialogRef.close(false);
  }

  onConfirm(){
    this.dialogRef.close(true);
  }
}
