import { HttpEventType, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { map, Observable } from "rxjs";
import { AttachSOCComponent } from "src/app/onboarding/insurance/attach-soc/attach-soc.component";
import { TechComponent } from "src/app/onboarding/insurance/tech/tech.component";
import { FileService } from "src/app/services/file.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-update-manage-hospital-technical-insurance",
  templateUrl: "./update-manage-hospital-technical-insurance.component.html",
  styleUrls: ["./update-manage-hospital-technical-insurance.component.scss"],
})
export class UpdateManageHospitalTechnicalInsuranceComponent implements OnInit {
  // error message
  message: any;
  // upload file
  selectedFiles?: any;
  fileInfos?: Observable<any>;
  insuranceDataSource = new MatTableDataSource<any>();
  // form
  claims: any;
  progress:any;
  insuranceCompanyId: any;
  form: FormGroup = this.formBuilder.group({});
  formData: FormData = new FormData();
  blacklisted: any;
  claimSubmissionMethod: any;
  insuranceName: any;
  // submission
  clicked: boolean = false;
  validFrom: any;
  validTo: any;
  displayColumns:string[]=['payor' , 'branch','dateRange','blacklisted','submissionType','uploadSOC'];
  insuranceTPAJson:any ={
    list:[]
  };
  constructor(
    private HospitalRegistrationService: HospitalRegistrationService,
    private formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<UpdateManageHospitalTechnicalInsuranceComponent>,
    private toastrService: ToastrService,
    private _dialog: MatDialog,
    private fileService:FileService,
    private matDialogRef: MatDialogRef<UpdateManageHospitalTechnicalInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      insuranceName: [""],
      branch:[""],
      inHouse:[""],
      tpa:[""],
      activationStatus:[""],
      blacklisted: ["", Validators.required],
      claimSubmissionMethod: ["", Validators.required],
      configdata: ["", Validators.required],
      validFrom: ["", Validators.required],
      validTo: ["", Validators.required],
      upload: [null, Validators.required],
    });
  }
  ngOnInit(): void {
    console.log('Original data:', this.data);
 
  this.insuranceDataSource.data = [this.data];
  console.log('Original data:',  this.insuranceDataSource.data);
    this.form.controls["insuranceName"].setValue(this.data.insuranceName);
    this.form.controls["branch"].setValue(this.data.branch);
    this.form.controls["inHouse"].setValue(this.data.inhouse);
    this.form.controls["activationStatus"].setValue(this.data.inactive);
    this.form.controls["tpa"].setValue(this.data.tpa);
    this.form.controls["blacklisted"].setValue(this.data.blacklisted);
    this.form.controls["configdata"].setValue('{}');
    this.form.controls["validFrom"].setValue(this.data.validFrom);
    this.form.controls["validTo"].setValue(this.data.validTo);
    this.form.controls["claimSubmissionMethod"].setValue(
      this.data.claimSubmissionMethod
    );
  }
  uploadFiles() {
    if (
      this.claims === null ||
      this.claims === undefined ||
      this.claims === ""
    ) {
      this.claims = this.data.claimSubmissionMethod;
    }
    this.formData.append("hospitalId", this.data.hospitalOrgId);
    this.formData.append("claimSubmissionMethod", this.claims);
    this.formData.append("insuranceId", this.data.insuranceId);
    this.formData.append("insuranceName", this.data.insuranceName);
    this.formData.append("validFrom", new Date(this.validFrom).toISOString());
    this.formData.append("validTo", new Date(this.validTo).toISOString());
    
    if (this.data.claimSubmissionMethod === "EMAIL") {
      this.formData.append("emailConf", '{}');
    } else if (this.data.claimSubmissionMethod === "PORTAL") {
      this.formData.append("portalConf", '{}');
    } else if (this.data.claimSubmissionMethod === "INTEGRATION") {
      this.formData.append("integrationConf", '{}');
    } else {
      this.formData.append("emailConf", '{}y');
    }
    this.formData.append("file", this.selectedFiles);
    this.formData.append("docType", "CashlessSOC");
    this.formData.append("inactive", "false");
    // console.log("formData " + JSON.stringify(this.formData.getAll));

    this.hospitalInsuranceMediaSubmit();

  }

  hospitalInsuranceMediaSubmit() {
    var insuranceMediaData ={
     "startDate": new Date(this.validFrom).toISOString(),
      "endDate":new Date(this.validTo).toISOString(),
      "file": this.selectedFiles,
      "category": "ONBOARDING",
      // "sourceType": this.data.sourceType,/// put the the task defination name
      "entityType": "HOSPITAL",
      "entityId": this.data.hospitalOrgId, /// put the hospital ID
      "docType": "CashlessSOC",
      "inHouse":  this.form.value.inHouse,
    
    }
    this.fileService.addExternalMedia(insuranceMediaData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          console.log(event);
          this.selectedFiles = undefined
          //this.saveInsuranceTPA(event.body.id);
          this.form.reset();
          // window.location.reload();
        } else if (event instanceof HttpErrorResponse) {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          this.toastrService.error(
            
            " SOC file couldn't be uploaded. Try again!"
          );
        }else{

        }
      })
    )
      .subscribe({
        next: (data: any) => {
        },
        error: (error:any) => {
          this.toastrService.success(
            
            "SOC  file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });
  }  

  isRowEmpty(element: any) {
    return !element.insuranceName ||  !element.blacklisted || !element.submissionType;
  }

  attachSOC(element:any){
    var reqData = {
      "startDate":element.startDate ? new Date(element.startDate).toISOString() :'',
      "endDate": element.endDate? new Date(element.endDate).toISOString() : '',
      "category":"WOKFLOW",
      "sourceType":this.data.sourceType,
      "entityType":"HOSPITAL",
      "entityId":this.data.entityId,
      "docType":"SOC_DOCUMENT",
      "socId": '',
    }
    
    const attachSOC = this._dialog.open(AttachSOCComponent,{
      data: reqData,
      disableClose:true,
      width:'50%', 
      height:'60%'
    });
    attachSOC.afterClosed()
    .subscribe({
      next:(data:any)=>{
          if(data){
            console.log(data);
           reqData.socId = data;
           console.log(data);
           //this.insuranceTPAJson = {...this.insuranceTPAJson , ...reqData};
           //this.insuranceTPAJson.list.push(reqData);
              //this.saveInsuranceTPA(data);
              //element.isDisabled = true;
              this.addInsuranceTPAJson(element , data)
             // this.getUploadedHospitalMedia();
          }
      }
    })
  }
addInsuranceTPAJson(reqData:any , socId:any){
  var submitData = {
    "hospitalOrgId": reqData.hospitalOrgId,
    "insuranceId": reqData.insuranceId,
    "blacklisted": reqData.blacklisted,
    "claimSubmissionMethod": reqData.claimSubmissionMethod,
    "inactive": "false",
    "payorType":"INSURANCE",
    "socId": socId,
    "branch":reqData.branch,
     };
  // this.insuranceTPAJson.list.push(submitData);
  // console.log(this.insuranceTPAJson);
  this.saveInsuranceTPA(submitData);
}


  saveInsuranceTPA(submitData:any) {
    //console.log(this.form.value);
    // var submitData = {
    //   "hospitalOrgId": this.data.data.hospitalOrgId,
    //   "insuranceId":this.insuranceCompanyId.id,
    //   "blacklisted": this.form.value.blacklisted,
    //   "claimSubmissionMethod": this.form.value.claimSubmissionMethod,
    //   "inactive": "false",
    //   "payorType": this.insuranceCompanyId.payorType,
    //   "socId": socId,
    //   "branch":this.form.value.branch,
    // };
    // console.log(submitData);
    // this.formData.append("socId", socId);
    // this.HospitalRegistrationService
    // .patchHospitalInsuranceTech(submitData, this.data.hospitalOrgId).pipe()
    // .subscribe({
    //   next:(data:any)=>{
    //     this.toastrService.success("File has been uploaded successfully!")
    //   }
    // })
    this.HospitalRegistrationService
    .patchHospitalInsuranceTech(submitData, this.data.hospitalOrgId).pipe(
      // map((event: any) => {
      //   // console.log(event);
      //   if (event.type === HttpEventType.UploadProgress) {
      //     this.progress = Math.round((100 * event.loaded) / event.total);

      //   } else if (event instanceof HttpResponse) {
      //     this.message = event.status;
      //     this.toastrService.success( "SOC  File has been uploaded successfully");
      //     this.form.reset();
      //     this.clicked = false;
      //     this.progress = 0;
      //     this.selectedFiles = undefined;
      //     //this.getHospitalInsurance();

      //   } else {
      //     // console.log(event.type);
      //     this.message = event.error;
      //     // console.log(this.message);
      //     // this.toastrService.error(
      //     //   this.selectedCategory,
      //     //   " file couldn't be uploaded. Try again!"
      //     // );
      //   }
      // })
    )

      .subscribe({
        next: (data: any) => {
          this.toastrService.success("File has been uploaded successfully!")
          this.matDialogRef.close();
        },
        error: (error) => {
          this.toastrService.success(
            
            "SOC file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });

  }
  
  hospitalInsurance() {
    this.HospitalRegistrationService
      .patchHospitalInsuranceTech(this.formData, this.data.hospitalOrgId)
      .subscribe({
        next: (data: any) => {
          this.toastrService.success(
            "Data uploaded successfully for technical insurance"
          );
          this._dialogRef.close(true);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
          this.toastrService.error(JSON.stringify(error));
          this._dialogRef.close(true);
        },
      });
  }

  onChangeInsuranceCompanyblacklisted(arg0: any) {
    if (arg0) {
      this.blacklisted = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    }
  }
  onChangeInsuranceCompanyclaims(arg0: any) {
    if (arg0) {
      this.claims = arg0.value;
      // console.log("insuranceCompanyId", this.insuranceCompanyId);
    } else {
      this.claims = this.data.claimSubmissionMethod;
    }
  }
  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
  }
}
