import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-org',
  templateUrl: './edit-org.component.html',
  styleUrls: ['./edit-org.component.scss']
})
export class EditOrgComponent {

  empForm: FormGroup;
  selected = 'pending';
  user:any;
  privileges:any;
  isReadonly: boolean = true;
  editing: boolean =false;
  status: any;


  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private authService:AuthService,
    private _dialogRef: MatDialogRef<EditOrgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService:ToastrService,

    //private _coreService: CoreService
   // displayedColumns: string[] = ['Id', 'Name','firstName','lastName', 'Type','SubType', 'Specialty','EMail', 'Address','Address2', 'City', 'State','Country','Zipcode','Website', 'Mobile','ContactNos','DefaultRole','RegistrationStatus','action'];

  ) {
    this.empForm = this._fb.group({
      id:'',
      type: ['',Validators.required,],
      name: ['',[Validators.required,Validators.maxLength(255)]],
      firstName: ['',Validators.required],
      lastName: [''],
      // specialty: [''],
      addLine2: [''],
      state: [''],
      country: [''],
      website: [''],
      contactNos: ['',[Validators.maxLength(10) ,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      defaultRole: [''],
      registrationStatus: [''],
      subType:['',Validators.required],
      // userType:[''],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      addLine1:[Validators.required],
      city:['',[Validators.required,Validators.maxLength(100)]],
      zipcode:['', [Validators.required, Validators.maxLength(10)]],
      // mobilePhone:['', [Validators.maxLength(10) ,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }

  ngOnInit(): void {
    this.empForm.patchValue(this.data);
    this.status = this.data.registrationStatus =='PENDING' ? "PENDING" : "REGISTERED";
    this.empForm.controls['registrationStatus'].setValue(this.status);
    // this.authService.user.subscribe(x => this.user = x);
    // // console.log("is ggsdg "+ this.user);
    // this.isPrivileged(this.user);
  //  this.privileges =  localStorage.getItem("privileges");
  //  console.log("is ggsdg "+ JSON.stringify(this.privileges));
  //  if(this.privileges == "SUPERADMIN","ADMIN")
  //  {
  //   console.log("true");
  //   this.isReadonly = false;
  //   this.editing = true;
  //  }
  }

  onFormSubmit() {
    if (this.empForm.valid) {
      if (this.data) {
        this.adminService
          .updateOrg(this.data.id, this.empForm.value)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("Org has been updated successfully");
              this._dialogRef.close(true);
            },
            error: (err: any) => {
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));
              console.error(err);
            },
          });
      } 
      // else {
      //   this.adminService.userregister(this.empForm.value).subscribe({
      //     next: (val: any) => {
      //       this._dialogRef.close(true);
      //     },
      //     error: (err: any) => {
      //       console.error(err);
      //     },
      //   });
      // }
    } else {
      this.toastrService.error("Please enter the remaining data ");
    }
  }
}