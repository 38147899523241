import { Component, Inject } from '@angular/core';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddUserRoleComponent } from '../add-user-role/add-user-role.component';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { CashflowService } from 'src/app/services/cashflow.service';
import { AddUserOrgComponent } from './add-user-org/add-user-org.component';
import { UpdateUserOrgComponent } from './update-user-org/update-user-org.component';

@Component({
  selector: 'app-manage-user-org',
  templateUrl: './manage-user-org.component.html',
  styleUrl: './manage-user-org.component.scss'
})
export class ManageUserOrgComponent {

  displayedColumns: string[] = ['orgd', 'userId', 'update','delete'];
  //  displayedColumns: string[] = ['Id', 'FirstName', 'LastName', 'User Type', 'EMail', 'Address', 'City', 'Zip', 'Mobile', 'action'];
  user: any;
  userRolesData: any;
  message: any;
  pageSize = 10;
  pageIndex = 0;
  hospitalList:any;
  userHospital:any;
  filterdata:any;
  userHospitalData:any;
  filteredArr: any;

  constructor(private adminService: AdminService, private authService: AuthService,
    private _dialog: MatDialog,private toastrService:ToastrService,
    private cashlessService:CashflowService,
    private _dialogRef: MatDialogRef<ManageUserOrgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
    this.authService.user.subscribe(x => this.user = x);
    // const dataSource = new MatTableDataSource<any>(this.userHospitalData);
    const dataSource = new MatTableDataSource<any>(this.filteredArr);
    
console.log("selected user id",this.data);
  }

  ngOnInit(): void {

    this.getUserhospitalByUserId();
    this.getOnboardedHospitals();
  }

  getUserhospitalByUserId() {
    // this.adminService.getUserRoleslById(this.user.id).pipe(first())
    this.adminService.getUserhospitalByUserId(this.data.id).pipe(first())

      .subscribe({
        next: (data: any) => {
          //this.loading = false;
console.log("getUserRoleslById", data);
          this.userHospitalData = data
          
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //sthis.loading = false;
        }
      });

  }

  
  getOnboardedHospitals(){
    // this.cashlessService.getOnboardedHospitals()
    // .pipe(first())
    // .subscribe({
    //  next:(hospitals:any)=>{
    //    this.hospitalList = hospitals.filter((data:any)=>{
    //     console.log("fgh",this.data);
    //  this.filterdata  = data.hospitalOrgId[0] = this.userHospitalData.id.hospitalOrgId
    //    });
    //    console.log(this.filterdata)

    //  },
    //  error:(error)=>{
    //    console.log("error " + JSON.stringify(error));
    //  }
    // })
    // this.cashlessService.getOnboardedHospitals()
    // .pipe(first())
    // .subscribe({
    //   next: (hospitalList: any) => {
    //     console.log("User hospital:", hospitalList);
    //     this.hospitalList = hospitalList;
 
    //     this.adminService.getUserhospitalByUserId(this.data)
    //       .pipe(first())
    //       .subscribe({
    //         next: (data: any) => {
    //           console.log("Manage user privileges:", data);
    //           this.userHospital = data;
 
    //           // Initialize an empty array to hold filtered privileges
    //           this.filteredArr = [];
 
    //           // Iterate through roles and privileges to find matches
    //         var dt = this.hospitalList?.forEach((hospitalList: any) => {
    //             const matchingPrivileges = this.userHospital?.filter((privilege: any) =>
    //                 // rolePrivilege.id.privilegeId === privilege.id
    //             hospitalList.hospitalOrgId === privilege.id.hospitalOrgId

    //               )
                
    //             this.filteredArr = this.filteredArr.concat(matchingPrivileges);
    //           });
 
    //           console.log("Filtered privileges:", this.filteredArr);
    //           console.log("dt",dt)
    //         },
    //         error: (error) => {
    //           console.log(error);
    //         },
    //       });
    //   },
    //   error: (error) => {
    //     console.log("Error: " + JSON.stringify(error));
    //     this.message = error;
    //   },
    // });

     this.adminService.getUserhospitalByUserId(this.data.id)
    .pipe(first())
    .subscribe({
      next: (hospitalList: any) => {
        console.log("User hospital:", hospitalList);
        this.hospitalList = hospitalList;
 
    this.cashlessService.getOnboardedHospitals()
    .pipe(first())
          .subscribe({
            next: (data: any) => {
              console.log("Manage user data:", data);
              this.userHospital = data;
 
              this.filteredArr = [];
 
            var dt = this.hospitalList?.forEach((hospitalList: any) => {
              //if(hospitalList.inactive === false){
                const matching = this.userHospital?.filter((data: any) =>       
                  hospitalList.id.hospitalOrgId === data.hospitalOrgId)
                  //hospitalList.inactive === true;
                  //this.filteredArr.push(hospitalList.inactive);                
                  this.filteredArr = this.filteredArr.concat(matching);
             // }
                // const matching = this.userHospital?.filter((data: any) =>       
                // hospitalList.id.hospitalOrgId === data.hospitalOrgId)
                // //hospitalList.inactive === true;
                // //this.filteredArr.push(hospitalList.inactive);
                
                // this.filteredArr = this.filteredArr.concat(matching);
              });
 
              console.log("Filtered :", this.filteredArr);
              console.log("dt",dt)
            },
            error: (error) => {
              console.log(error);
            },
          });
      },
      error: (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.message = error;
      },
    });
}
 


 openAddForm() {
    const dialogRef = this._dialog.open(AddUserOrgComponent, {
      disableClose:true,
      "data":this.data.id,
      width:'603px',   // Set width to 600px
      height:'350px',  // Set height to 530px
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getUserhospitalByUserId();
          this.getOnboardedHospitals();   
                 //this._dialogRef.close(true);

        }
      },
    });
  }

  
  openEditForm(data: any) {
    const dialogRef = this._dialog.open(UpdateUserOrgComponent, {
      disableClose:true,
      data: { userId: this.data.id, hospitaldata: data },

      width: "90%",
      height: "70%",
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getUserhospitalByUserId();
          this.getOnboardedHospitals();           }
        
      },
    });

  }
    deleteHospital(data: any) {
      console.log("fg", data);
  
      Swal.fire({
        title: 'Are you sure want to inactivate this hospital?',
        //text: 'You will not be able to recover this imaginary file!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, inactivate it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
  
        if (result.isConfirmed) {
  
          this.adminService.deleteUserhospital(this.data.id,data.hospitalOrgId).pipe(first())
            .subscribe({
              next: (data: any) => {
                this.toastrService.success("hospital has been inactivated successfully");
                this.getUserhospitalByUserId();
                this.getOnboardedHospitals();   
  
              },
              error: error => {
                console.log("error " + JSON.stringify(error))
                this.toastrService.error(JSON.stringify(error.error.error));
                this.message = error;
              }
            });
        } else if (result.isDismissed) {
  
        }
      })
  
  
  
    }
  // openAddRolesForm() {
  //   // console.log("dta",data)

  //   const dialogRef = this._dialog.open(AddUserRoleComponent);
  //   dialogRef.afterClosed().subscribe({
  //     next: (val) => {
  //       if (val) {
  //         this.getUserRoleslById();
  //       }
  //     },
  //   });
  // }
  // deletUsereRoles(role: any) {
  //   //debugger;

  //   Swal.fire({
  //     title: 'Are you sure want to delete this role?',
  //     //text: 'You will not be able to recover this imaginary file!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'No, keep it',
  //   }).then((result) => {

  //     if (result.isConfirmed) {

  //      // console.log('Clicked Yes, File deleted!');
  //       var userroledata = {
  //         "roleIds": [
  //           role.id
  //         ],
  //         // "userId": this.user.id
  //         "userId": this.data
    
  //       }
  //      // console.log("payload",userroledata);
  //       this.adminService.deleteUserRoles(this.data,role.id).pipe(first())
  //         .subscribe({
  //           next: (data: any) => {
  //             //this.loading = false;
  //             //console.log("after delete",data);
  //             this.toastrService.success("User role has been deleted successfully");
  
  //             this.getUserRoleslById();
  //             // this.roles = data;
  //           },
  //           error: error => {
  //             console.log("error " + JSON.stringify(error))
  //             this.message = error;
    
  //             //sthis.loading = false;
  //           }
  //         });

  //     } else if (result.isDismissed) {

  //       //console.log('Clicked No, File is safe!');

  //     }
  //   })

  //     //console.log("Implement delete functionality here");
     
  
  //   }
  }
    
  
   


