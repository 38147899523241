import { HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
@Component({
  selector: 'app-attach-soc',
  templateUrl: './attach-soc.component.html',
  styleUrl: './attach-soc.component.scss'
})
export class AttachSOCComponent implements OnInit {
  selectedFiles: any;
  progress: any;
  message: any;
  formData: FormData = new FormData();
  constructor(
    private matDialogRef: MatDialogRef<AttachSOCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileService: FileService,
    private toastrService: ToastrService,
  ) {

  }

  ngOnInit(): void {

  }

  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = file;
    this.uploadSOC();
  }

  close() {
    this.matDialogRef.close();
  }

  uploadSOC() {

    let files = { "file": this.selectedFiles };
    this.formData.append("startDate", this.data.startDate);
    this.formData.append("endDate", this.data.endDate);
    this.formData.append("file", this.selectedFiles);
    this.formData.append("category", this.data.category);
    this.formData.append("sourceType", this.data.sourceType);/// put the the task defination name
    this.formData.append("entityType", this.data.entityType);
    this.formData.append("entityId", this.data.entityId); /// put the hospital ID
    this.formData.append("docType", this.data.docType);

    this.fileService.addExternalMedia(this.formData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          //const id= event.body;
          console.log(event.body.id);
          this.selectedFiles = undefined
          this.matDialogRef.close(event.body.id);
        } else if (event instanceof HttpErrorResponse) {
          // console.log(event.type);
          this.message = event.error;
          // console.log(this.message);
          this.toastrService.error(

            " file couldn't be uploaded. Try again!"
          );
        } else {

        }
      })
    )
      .subscribe({
        next: (data: any) => {
        },
        error: (error) => {
          this.toastrService.success(

            " file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          // this.loading = false;
        },
      });
  }
}
