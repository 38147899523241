import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA , MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { ViewFileComponent } from 'src/app/portal/bg/inbox/view-file/view-file.component';
import { CashflowService } from 'src/app/services/cashflow.service';
import { FileService } from 'src/app/services/file.service';


import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
@Component({
  selector: 'app-view-upload-file',
  templateUrl: './view-upload-file.component.html',
  styleUrls: ['./view-upload-file.component.scss']
})
export class ViewUploadFileComponent implements OnInit{
  uploadedFiles: any = [];
  displayedColumns: string[] = ["docType", "fileName", "createdDate", "view"];
  uploadedData: any = [];
  dataSource = new MatTableDataSource<any>(this.uploadedFiles);
  length = 100;
  message:any;
  pageSize = 10;
  insurance:any;
  insuranceMedia:any;
  pageIndex = 0;
  public media:any;
  insuranceCompanyList:any;
  pageSizeOptions = [5, 10, 25];
  constructor(
    public MatDialogRef:MatDialogRef<ViewUploadFileComponent>,
     @Inject (MAT_DIALOG_DATA) public data:any,
      
    private dialog:MatDialog,
    private cashlessService:CashflowService,
    private hospitalRegistrationService: HospitalRegistrationService,
    private fileService:FileService,
    
  ){
   
  }
  ngOnInit(): void {
    this.media = this.data?.media ?? {},
    //console.log(this.data.media);
   // this.getInsuranceMedia();
    this.getUploadedHospitalMedia();
    console.log(this.data);
  }

  toSentenceCase(str: string) {
    return str.replace(/\b\w+\b/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }


  getUploadedHospitalMedia() {
    this.fileService.getExternalMediaList(this.data.hospitalId, "HOSPITAL",
      "").subscribe({
      next: (data) => {
        console.log("....", data);
        this.uploadedData = data;
        this.getInsuranceByHospitalId(this.data.task.caseVariables.hospitalId);
        this.dataSource.data = this.uploadedData;
        console.log("....", this.uploadedData);
        console.log("Get Users by Manager Array:" + JSON.stringify(data));
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        this.message = error;
      },
    });
  }

  getInsuranceMedia(){
    this.getInsuranceByHospitalId(this.data.task.caseVariables.hospitalId);
    // const insuranceId = this.data['select-primary-insurance-company-name-modal'];
    // this.insurance = this.insuranceCompanyList.filter((insurance:any)=>insurance.insuranceId === insuranceId);
    // console.log(this.insurance);
    // this.insuranceMedia = this.insurance[0].media;
    // console.log(this.insuranceMedia);
  }

  getInsuranceByHospitalId(hospitalId: any) {
    this.cashlessService.getInsuranceByHospitalId(hospitalId, this.pageIndex, this.pageSize)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
           console.log(data);
          this.insuranceCompanyList = data;
          const insuranceId = this.data.task.caseVariables['select-primary-insurance-company-name-modal'];
          this.insurance = this.insuranceCompanyList.filter((insurance:any)=>insurance.insuranceId === insuranceId);
          console.log(this.insurance);
          this.insuranceMedia = this.insurance[0].media;
          console.log(this.insuranceMedia);
          this.uploadedData.push( this.insuranceMedia);
          

        }
      })
  }

  viewFile(element:any){
    const dialogRef = this.dialog.open(ViewFileComponent,{
      disableClose:true,
      data:{fileMetaData:element, type: element.docType},
      height:'90%',
      width:'90%'

    });

    this.MatDialogRef.afterClosed().subscribe({
      next: (val)=> {
        if (val){}
      },
    })
  }
}
