<div class="pt-4">
  <div class="bg-light rounded-3  p-4" *ngIf="caseDetails.length> 0">
   
    <div class="row">
      <div class="col">
        <span class="my-4">
          <h2>Timeline</h2>
        </span>
      </div>
      <div class="col">
        <button class="btn" type="button" style="color:white;background:#8e0101;margin-left:1px" (click)="openViewFileComponent()">View File</button>
      </div>
    </div>
    <ul class="timeline-3">
      <li *ngFor="let task of caseDetails">

        <div ><b>{{ task.task_name }}</b></div>
        <div >Date: {{ task.tx_date | date }}</div>
        <div> Assigned to: {{task.assignee}}</div>
      </li>
      <!-- <li>
        <a href="#!">New Web Design</a>
        <a href="#!" class="float-end">21 March, 2014</a>
        <p class="mt-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas
          placerat facilisis mollis. Duis sagittis ligula in sodales
          vehicula....
        </p>
      </li>
      <li>
        <a href="#!">21 000 Job Seekers</a>
        <a href="#!" class="float-end">4 March, 2014</a>
        <p class="mt-2">
          Curabitur purus sem, malesuada eu luctus eget, suscipit sed turpis.
          Nam pellentesque felis vitae justo accumsan, sed semper nisi
          sollicitudin...
        </p>
      </li>
      <li>
        <a href="#!">Awesome Employers</a>
        <a href="#!" class="float-end">1 April, 2014</a>
        <p class="mt-2">
          Fusce ullamcorper ligula sit amet quam accumsan aliquet. Sed nulla
          odio, tincidunt vitae nunc vitae, mollis pharetra velit. Sed nec
          tempor nibh...
        </p>
      </li> -->
    </ul>
  </div>
</div>