import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-preauth',
  templateUrl: './preauth.component.html',
  styleUrl: './preauth.component.scss'
})
export class PreauthComponent implements OnInit {
  fname: any = "BIMA";
  lname: any = "GARAGE";
  fnameA: any = [];
  lnameA: any = [];
  nameLength: any = 40;
  dob:any ;
  remaining: number = 0;
  idCardRemaining: number = 0;
  treatingDoctorContactNumber:any = new Array(10);
  dateOfAdmission:any;
  timeOfAdmission:any;
  firstConsultationDate:any;
  insurance:any;
  insuranceMediaId:any;
  error: any;

  today = new Date();
  apiUrl: string = environment.API_URL;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private cashlessService: CashflowService) {

    //console.log("###### DATA #####   " + JSON.stringify(this.data));


  }
  ngOnInit() {
    this.getPreAuthData(this.data.caseId);

  }

  dataConversion() {
    this.fnameA = Object.assign([], this.removeQuotes(this.preAuthData?.firstname));
    this.lnameA = Object.assign([], this.removeQuotes(this.preAuthData?.lastname));
    this.age = this.removeQuotes(this.preAuthData?.age);
    console.log(this.age);
    this.remaining = (40 - (this.fnameA.length + this.lnameA.length));
 
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(this.removeQuotes(this.preAuthData.dob), 'ddMMYYYY' , 'UTC');
    // console.log(formattedDate);
    this.dob = Object.assign([], (formattedDate));
   // this.mediclaimExists = this.removeQuotes(this.preAuthData.mediclaimExists);
    
    this.dateOfAdmission =Object.assign([], datePipe.transform(this.removeQuotes(this.preAuthData.dateOfAdmission), 'ddMMYYYY' , 'UTC'));
    this.timeOfAdmission = Object.assign([],this.removeQuotes(this.preAuthData.timeOfAdmission));
   
    this.firstConsultationDate = Object.assign([], datePipe.transform(this.removeQuotes(this.preAuthData.firstConsultationDate), 'ddMMYYYY' , 'UTC'));
  }

  removeQuotes(data: any) {
    // console.log(data);
    if(data){
      const trasnformedData = data.replaceAll("\"", "");
    return trasnformedData.toUpperCase();
    }else{
      return '';
    }
    
  }
  age:any = '';
  months:any= '';
  calculateAge(dob: string) {
    const today = new Date();
    console.log(today);
    const dobDate = new Date(this.removeQuotes(dob));
    console.log(dobDate)
     let age = today.getFullYear() - dobDate.getFullYear();
     let months = today.getMonth() - dobDate.getMonth();
    if (months < 0) {
      age--;
      months += 12;
    }
    this.age = age + '';
    this.months = months+ '';
    //console.log(`${this.age} years ${this.months} months`);
    // return `${this.age} years ${this.months} months`;
  }
  convertToArray(data:any){
    return Object.assign([],this.removeQuotes(data));
  }

  dateFormat(data:any){
    const datePipe = new DatePipe('en-US');
    return Object.assign([], datePipe.transform(data, 'ddMMYYYY' , 'UTC'));
  }
  timeFormat(data:any){
    const datePipe = new DatePipe('en-US');
    return Object.assign([], datePipe.transform(data, 'HH:mm' , 'IST'));
  }

  ageFormat(data: string) {
    return Object.assign([],data);
  }

  convertToParagraph(data:any){
    return this.removeQuotes(data);
  }
  getBlankSpaces(price: number) {
    const priceLength = price.toString().length;
    // console.log(priceLength);
    const blankSpacesLength = 8 - priceLength;
    return blankSpacesLength;
  }

  counter(i: number) {
    return new Array(i);
  }
  async downloadPDF() {
    const data = document.getElementById("preauth-content");
    if (data) {
      const imgWidth = 208; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const contentHeight = data.scrollHeight; // Total content height in px
 
      html2canvas(data).then((canvas) => {
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
 
        const pdf = new jsPDF("p", "mm", "a4");
        let position = 0;
 
        // Add first page
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
       
        // Calculate the number of pages needed
        const totalPages = Math.ceil(imgHeight / pageHeight);
 
        for (let i = 1; i < totalPages; i++) {
          pdf.addPage();
          position = -(i * pageHeight);
          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        }
 
        pdf.save("preAuthForm.pdf");
      });
    }
  }

  preAuthData: any;
  getPreAuthData(id: any) {
    this
      .cashlessService.getPreauthDetails(id)
      .subscribe({
        next: (data: any) => {

          this.preAuthData = data;
          // console.log(JSON.stringify(this.preAuthData));
          this.dataConversion();
          this.calculateAge(this.preAuthData.dob)
          this.setInsuranceMediaId(this.preAuthData);
        },
        error: (err: any) => {
          console.error(err);
          //this.toastrService.error("Error");
        },
      });
  }

  setInsuranceMediaId(data: any) {
    //var x = JSON.parse(data);
    for (var key in data) {
      // // console.log(key);
      //var k = key.replace(/^"|"$/g, "");
      if (key == "select-tpa-modal" && data["select-tpa-modal"] !="" && data.inhouseTPA == "inHouseTPANo") {
        this.insuranceMediaId = data[key].replace(/^"|"$/g, "");
        this.getTpa();
        // console.log("tpaMediaId: " + JSON.stringify(this.insuranceMediaId));
      }
     else if (key == "select-primary-insurance-company-name-modal") {
        this.insuranceMediaId = data[key].replace(/^"|"$/g, "");
        this.getInsuranceCompany();
        // console.log("insuranceMediaId: " + JSON.stringify(this.insuranceMediaId));
      }
    }
  }

  getInsuranceCompany() {
    this.cashlessService
      .getSocForInsurance(this.insuranceMediaId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.insurance = data;
          // console.log("prod", data);
        },
        error: (err: any) => {
          // console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No Insurance Companies available!";
          }
        },
      });
  }

  getTpa() {
    this.cashlessService
      .getTpa(this.insuranceMediaId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.insurance = data;
          // console.log("prod", data);
        },
        error: (err: any) => {
          // console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No Tpa available!";
          }
        },
      });
  }

}


