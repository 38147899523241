<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Policy Members</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
</div>
<div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center">
        <div class="p-4">
            <div class="example-container ">
                <mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="memberName">
                        <mat-header-cell *matHeaderCellDef>Member Name</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.memberName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ped">
                        <mat-header-cell *matHeaderCellDef>Ped</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.ped}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="dob">
                        <mat-header-cell *matHeaderCellDef>Date of Birth</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.dob }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="relationship">
                        <mat-header-cell *matHeaderCellDef>Relationship</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.relationship}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobileNumber">
                        <mat-header-cell *matHeaderCellDef>Mobile Number</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.mobileNumber}} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                </mat-table>
                <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5,10,15]" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>