import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs";
import { AddAssignTaskComponent } from "src/app/modal/add-assign-task/add-assign-task.component";
import { DataShareModel } from "src/app/model/dataShareModel";
import { AuthService } from "src/app/services/authService";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { DataServiceService } from "src/app/services/data-service.service";
import { WorkflowService } from "src/app/services/workflow.service";
import { OnboardingFormComponent } from "../onboarding-form/onboarding-form.component";
@Component({
  selector: "app-onboarding-inbox",
  templateUrl: "./onboarding-inbox.component.html",
  styleUrl: "./onboarding-inbox.component.scss",
})
export class OnboardingInboxComponent implements OnInit, AfterViewInit {
  assignedTasks: any[] = [];
  loading: any = false;
  error: any;
  message: any;
  form: FormGroup = this.formBuilder.group({});
  tasks: any[] = [];
  displayedColumns: string[] = [
    "currenttask",
    "hospitalName",
    "orderDate",
    "onboardingId",
    "assignedTo",
    "action",
  ];
  selectedTask: any;
  ekypdata: any;
  user: any;
  table!: MatTable<any>;
  dataSource = new MatTableDataSource<any>(this.tasks);
  paginator: any;
  fields: any;

  @ViewChild(MatTable)
  policyMembers: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private cashlessservice: CashflowService,
    private customerService: CustomerService,
    private _dialog: MatDialog,
    private authService: AuthService,
    private toastrService: ToastrService,
    private dataService: DataServiceService,
    private workflowService: WorkflowService
  ) {
    this.dataService.allTasksData$.subscribe((data: any) => {
      if (data !=null && data.getProcessName() == "hospitalOnBoardingProcess") {
        this.tasks = data.tasks;
        this.dataSource.data = data.getTasks();
      }
      console.log(this.dataSource.data);
    });
  }

  ngOnInit(): void {
    this.user = this.authService.userValue;
  }
  ngAfterViewInit(): void {
     
    this.getAllTasks();
    this.dataSource.paginator = this.paginator;
     
  }

  getAllTasks() {
    this.workflowService
      .getAllTask("hospitalOnBoardingProcess")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          // console.log("Accept");
          this.assignedTasks = data;
          const dataShare = new DataShareModel("hospitalOnBoardingProcess",data);
          this.dataService.updateAllTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          // console.log(error);
          this.loading = false;
        },
      });
  }

  selectTask(task: any) {
    this.selectedTask = task;
  }
  buildForm(selectedTask: any) {
    throw new Error("Method not implemented.");
  }

  acceptTask(element: any) {
    this.selectedTask = element;
    //  console.log("Accept");
    this.workflowService
      .assignTask(this.selectedTask.id, this.user.id, new Date().toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.loading = false;
          this.getAllTasks();
          this.viewTask(element);
          this.toastrService.success("Task has been accepted successfully");
        },
        error: (error: { error: any }) => {
          this.error = error.error;
          this.message = undefined;
          // console.log(error);
          this.loading = false;
        },
      });
  }


  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  assignTask(data: any) {
    //console.log(JSON.stringify(this.dynamicFormGroup.value));
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data: { data: data, roleName: ["ADMIN", "ONBOARDING"] },

      width: "430px",
      height: "200",
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
        }
        this.getAllTasks();
      },
    });
  }

  viewTask(task: any) {
    console.log("here", task.name);
    const dialogRef = this._dialog.open(OnboardingFormComponent, {
      disableClose:true,
      data: { task: task },
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
     
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        this.getAllTasks();
      },
    });
    // if (task.name == "Create Agreement") {
    //   const dialogRef = this._dialog.open(CreateAgreementComponent, {
    //     disableClose:true,
    //     data: { task: task },
    //     width: "90%",
       
    //   });
    //   dialogRef.afterClosed().subscribe({
    //     next: (val: any) => {
    //       this.getAllTasks();
          
    //     },
    //   });
    // } else if (task.name == "Initiate Onboarding") {
    //   const dialogRef = this._dialog.open(InitiateOnboardingComponent, {
    //     disableClose:true,
    //     data: { task: task },
    //     width: "90%",
       
    //   });
    //   dialogRef.afterClosed().subscribe({
    //     next: (val: any) => {
    //       this.getAllTasks();
    //     },
    //   });
    // } else if (task.name == "Tech Onboarding") {
    //   const dialogRef = this._dialog.open(TechOnboardingComponent, {
    //     disableClose:true,
    //     data: { task: task },
    //     width: "90%",
       
    //   });
    //   dialogRef.afterClosed().subscribe({
    //     next: (val: any) => {
    //       this.getAllTasks();
    //     },
    //   });
    // }
  }

  escalation(dueDate:number){
    const currentDate = new Date().getTime();
    if(dueDate > currentDate){
      return false;
    }else{
      return true;
    }

  }

  
}
