import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-role-with-privilages',
  templateUrl: './role-with-privilages.component.html',
  styleUrls: ['./role-with-privilages.component.scss']
})
export class RoleWithPrivilagesComponent {

  pageSize = 10;
  pageIndex = 0;
  roles: any;
  message: any;
  priveleges: any;
  filteredArr: any;
  privilege: any;
  constructor(private adminService: AdminService,
    private _dialogRef: MatDialogRef<RoleWithPrivilagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
  ngOnInit(): void {
    console.log("ty", this.data);
    this.cellClicked();
  }
  cellClicked() {
    this.roles = this.data;
    this.adminService.getPrivileges()
      .pipe(first())
      .subscribe({
        next: (privileges: any) => {
         // console.log("Manage role privileges:", privileges);
          this.priveleges = privileges;
          this.filteredArr = [];
          this.roles.rolePrivileges?.forEach((role: any) => {
            const matchingPrivileges = this.priveleges?.filter((privilege: any) =>
              role.id.privilegeId === privilege.id
              )
            this.filteredArr = this.filteredArr.concat(matchingPrivileges);
          });

          //console.log("Filtered privileges:", this.filteredArr);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

}

