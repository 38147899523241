import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, map, retry, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class ManagetpaService {
  constructor(private router: Router, private http: HttpClient) {}
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  requestOptions = { headers: this.headers };
  errorHandle(error: any) {
    let errorMessage = "";
    if (error.error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.error;
    } else {
      // Get server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  getTpa() {
    return this.http
      .get<any>(`${environment.API_URL}/tpamaster/list?rowNum=0&size=10`)
      .pipe(catchError(this.errorHandle));
  }

  addExternalMedia(data:any) {
    return this.http
      .post<any>(`${environment.API_URL}/externalMedia/add`, data)
      .pipe(catchError(this.errorHandle));
  }

  addTpa(data: any) {
    return this.http.post<any>(`${environment.API_URL}/tpamaster/add`, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateTpa(id: number, data: any) {
    return this.http
      .patch<any>(`${environment.API_URL}/tpamaster/` + id, data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteTpa(Id: string) {
    return this.http
      .delete<any>(`${environment.API_URL}/tpamaster/` + Id)
      .pipe(catchError(this.errorHandle));
  }
}
