<div class="container py-1 ">

  
  <div class="border rounded-1 p-2">
    <div class="row p-2">
      <div class="col text-start">
        <span style="color:grey">Task Filters</span>
      </div>
      <div class="col text-end">
        <button type="button" style="color:white;background:#8e0101;" class="btn  btn-sm" (click)="getAllTasks()">
          Refresh
        </button>
        <button type="button" style="color:white;background:#8e0101;" class="btn  btn-sm" (click)="filterPriority('ALL')">
          Reset Filter
        </button>
      </div>
    </div>
    <hr>
    <div class="row p-2">
      <div class="col-2 p-1" *ngFor="let filterButton of filterData">
        <button 
          (click)="filterPriority(filterButton.taskName)" 
          type="button"
          [class]="filterButton.count > 0 ? 'btn btn-warning btn-sm position-relative' : 'btn btn-secondary btn-sm position-relative'"
          [ngClass]="{'selected-button': taskId === filterButton.taskName}">
          {{filterButton.taskName}}
          <span 
            [class]="filterButton.count > 0 
              ? 'position-absolute top-0 start-100 translate-middle badge text-bg-white rounded-pill bg-danger' 
              : 'position-absolute top-0 start-100 translate-middle badge text-bg-white rounded-pill bg-secondary'">
            {{filterButton.count}}
            <span class="visually-hidden">Pending</span>
          </span>
        </button>
      </div>
    </div>
    
  </div>

  <div class="totalCount" *ngIf="dataSource">
    <span > Total Cases: </span> <h1 style="padding-top: 10px;font-weight: bold;">{{allTasks.length}}</h1>
   </div>

  <div class="row  px-3 py-4 justify-content-md-center">
    <input matInput (input)="applyFilter($event)" class="form-control" placeholder="Filter" aria-label="Search">
  </div>

  <!-- <div class="row  px-3 py-4 justify-content-md-center">
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
  </div> -->

  <table mat-table [dataSource]="dataSource">
    <!-- Position Column -->
    <!-- {{selectedTask}} -->
    <ng-container matColumnDef="currenttask">
      <th mat-header-cell *matHeaderCellDef>Tasks</th>
      <td mat-cell *matCellDef="let element" [class]="element == selectedTask ? 'selected' : ''">
        <b>{{ element.taskName}}</b>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="orderDate">
      <th mat-header-cell *matHeaderCellDef>Case Date</th>
      <td mat-cell *matCellDef="let element">
        <!-- {{ element.createdDate? element.createdDate : element.dueDate | date : "M/dd/yyyy hh:mm" }} -->
        {{ element.processStartTime  | date : "M/dd/yyyy hh:mm" }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="orderId">
      <th mat-header-cell *matHeaderCellDef>Case Id</th>
      <td mat-cell *matCellDef="let element">{{ element.processInstId }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef>Customer</th>
      <td mat-cell *matCellDef="let element" (click)="viewClaimStatus(element)">
        {{element.memberName}}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="hospital">
      <th mat-header-cell *matHeaderCellDef>Hospital Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.hospitalName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="assignTo">
      <th mat-header-cell *matHeaderCellDef>Assign To</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.assigneeName != undefined">
          {{element.assigneeName}}
        </span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="center-header">Action</th>
      <td mat-cell *matCellDef="let element">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn" style="color:white;background:#8e0101" (click)="acceptTask(element)"
            [disabled]="element.assignee != undefined ">
            Accept
          </button>
          <button type="button" class="btn" style="color:white;background:#8e0101" (click)="assignTask(element)"
            [disabled]="!isPrivileged(['ADMIN','SUPERADMIN','CAN_ASSIGN_TASK'])">
            Assign
          </button>
          <button type="button" style="color:white;background:#8e0101;margin-left:1px" class="btn"
            (click)="viewTask(element)">View </button>
          <button type="button" class="btn" style="color:white;background:#8e0101" (click)="status(element)">
            Status
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100,]" showFirstLastButtons aria-label="Select page of Tasks!">
  </mat-paginator>
  <hr />
  <div *ngIf="dataSource != undefined && dataSource.data.length <= 0" class="alert alert-primary" role="alert">
    You don't have any pending tasks at this moment. Check back later!
  </div>