import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddTpaComponent } from "./add-tpa/add-tpa.component";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first, Observable } from "rxjs";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { UpdateTpaComponent } from "./update-tpa/update-tpa.component";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UploadTpaLogoComponent } from "./upload-tpa-logo/upload-tpa-logo.component";
import { environment } from "src/environments/environment.prod";
import { ViewMailParserConfsComponent } from "../manage-insurance-company/view-mail-parser-confs/view-mail-parser-confs.component";
import { EmailConfComponent } from "../email-conf/email-conf.component";

@Component({
  selector: "app-manage-tpa",
  templateUrl: "./manage-tpa.component.html",
  styleUrls: ["./manage-tpa.component.scss"],
})
export class ManageTPAComponent {
  apiUrl: string = environment.API_URL;
  tpatables: any = [];
  selectedFiles?: any;
  category: any;
  entityType: any;
  formData: FormData = new FormData();
  displayedColumns: string[] = [
    "image",
    "tpaName",
    "address",
    "city",
    "state",
    "pincode",
    "emailId",
    "contactNos",
    "status",
    "action",
    "delete",
    "upload",
    "emailParserConfs"
  ];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();
  dataSource = new MatTableDataSource<any>(this.tpatables);
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  message: any;

  constructor(
    private _dialog: MatDialog,
    private tpaService: ManagetpaService,

    private toastrService: ToastrService
  ) {
    this.getTpa();
  }
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  getTpa() {
    this.tpaService
      .getTpa()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.tpatables = data;
          this.dataSource.data = this.tpatables;
          this.dataSource.paginator = this.paginator;
          // console.log("prod", data);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  openEditForm(data: any) {
    //console.log(data);
    const dialogRef = this._dialog.open(UpdateTpaComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });

    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
          this.getTpa();
        }
      },
    });
  }

  deleteTpa(data: any) {
    console.log("fg", data);
    Swal.fire({
      title: "Are you sure want to delete this tpa?",
      //text: 'You will not be able to recover this imaginary file!',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.tpaService
          .deleteTpa(data.id)
          .pipe(first())
          .subscribe({
            next: (data: any) => {
              this.toastrService.success("Tpa has been deleted successfully");

              //this.loading = false;
              this.getTpa();
              //console.log("roles",data);
              // this.roles = data;
            },
            error: (error) => {
              console.log("error " + JSON.stringify(error));
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(error.error.error));

              this.message = error;

              //sthis.loading = false;
            },
          });

        // console.log('Clicked Yes, File deleted!');
      } else if (result.isDismissed) {
        //console.log('Clicked No, File is safe!');
      }
    });
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddTpaComponent, {
      disableClose:true,
      width: "90%",
      height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getTpa();
        }
      },
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getTpa();
  }


    
  ViewMailParser(element:any) {
    const dialogRef = this._dialog.open(EmailConfComponent, {
      data: { element},
      height:'80%',
      width:'80%',
      disableClose:true,

    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getTpa();
        }
      },
    });
  }


  updateLogo(data: any) {
    console.log("selectFile", data);
    const dialogRef = this._dialog.open(UploadTpaLogoComponent, {
      disableClose:true,
      width: "90%",
      height: "70%",
      data,
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getTpa();
        }
      },
    });
  }
}
