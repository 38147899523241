<div class="container p-3">
    <div class="row">
        <div class="col">
            <h1> Deployed processes</h1>
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-1">

        </div>
        <div class="col">
            <h2>Process name</h2>
        </div>
        <div class="col">
            <h2>Process Key</h2>
        </div>
        <div class="col">
            <h2> Process version (Latest)</h2>
        </div>
    </div>
    <hr>
    <div  *ngFor="let wf of deployedprocessByProcessDef" [class]="selectedKey != undefined && selectedKey == wf.key?'row bg-white text-danger':'row'">
        <div class="col-1">
            <mat-icon *ngIf="selectedKey == wf.key">play_arrow</mat-icon>
        </div>
        <div  class="col p-1" (click)="getDeployedprocessByProcessDefTasks(wf.key)">
             {{wf.name}}
        </div>
        <div class="col p-1">
            {{wf.key}}
        </div>
        <div class="col p-1">
            <span class="badge text-bg-warning"> {{wf.version}}</span>
        </div>
    </div>
    <hr>
    <div class="row pt-3" *ngFor="let tasks of deployedprocessByProcessDefTasks">
        <div class="col-3 align-middle">
            {{tasks.taskName}}
        </div>
        <div class="col" >
            <div class="row" *ngFor="let model of tasks.formPropertyModel">
                <div class="col-8">
                    {{model.formAttributeName}}
                </div>
                <div class="col-4">
                    {{model.formAttributeType}}
                </div>
            </div>
        </div>
        
    </div>

</div>