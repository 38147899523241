<div class="px-4 py-5 my-5 text-center " [class]="success != undefined?'celebration':''">

    <img width="10%" class="d-block mx-auto mb-4" src="../../assets/img/campaign.png" alt="">

    <h1 class="display-5 fw-bold">Hospital Aquisition Campaign</h1>

    <div class="col-lg-6 mx-auto">

        <p class="lead mb-4">Targeting all Rohini affiliated hospitals based on selected city and bed size (25-100)</p>
        <div class="p-3">
            <select #cities class="form-select" aria-label="Cities" (change)="onSelected(cities.value)">
                <option selected>Select a city to target.</option>
                <option value="{{city.value}}" *ngFor="let city of targetCities">{{city.name}}</option>
              </select>
        </div>
        

        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" #btn (click)="actionMethod(); btn.disabled = true;" [disabled]="isDisabled" style="background-color:C00000;"
                class="btn btn-danger btn-lg px-4 gap-3">Start campaign</button>
        </div>
        <div class=" py-5 d-grid gap-2 d-sm-flex justify-content-sm-center">
            <h1 class="display-7 fw-bold">{{success}}</h1>
        </div>

    </div>

</div>
