import { Component, Inject, OnInit, Pipe } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { CustomerService } from 'src/app/services/customer.service';
import { TaskDetailsComponent } from '../task-details/task-details.component';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})
export class CaseDetailsComponent implements OnInit  {
  caseId: any;
  caseDetails:any =[];
  dataSource = new MatTableDataSource<any>(this.caseDetails);
  diagram:any;
  loading:any=false;
  error:any;
  message:any;
  displayedColumns = ['process_inst_id','taskName','assignee','status','startDate']
  constructor(
    public dialRef:MatDialogRef<CaseDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private route: ActivatedRoute,
     private cashlessService: CashflowService,
    private workflowService:WorkflowService,
     private _dialog:MatDialog
  ){
    
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.caseId = this.data.process_inst_id; // Access the 'id' parameter from the URL
      console.log("Test ID:", this.data);
    });
    this.getCaseDetails();
   
  }


  getCaseDetails(){
    this.cashlessService.getCaseDetails(this.caseId)
    .pipe(first())
    .subscribe({
      next:(data:any)=>{
        console.log("data:"+JSON.stringify(data));
        this.caseDetails = data;
        this.dataSource.data = this.caseDetails;
        this.getBPMNDiagram(this.caseId);
      }
    });
     
  }

  getExecutionStatus(element: any): string {
    var task_name = element.task_def_key;
   // console.log( element.task_data[task_name + '-ExecutionStatus']);
    return element.task_data[task_name + '-ExecutionStatus'];
  }

  getBPMNDiagram(taskId:any){
    this.workflowService
    .getBPMNDiagram(taskId)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        
        this.diagram = 'data:image/png;base64,' + data; 

        this.loading = false;
      },
      error: (error) => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      },
    });
  }

  viewTaskDetails(selectedTask:any){
    let dialogRef = this._dialog.open(TaskDetailsComponent,{
      disableClose:true,
      data:selectedTask,
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });

    // console.log(selectedTask);
    // const navigationExtras: NavigationExtras = {
    //   state: {
    //     data: selectedTask
    //   }
    // };
    // console.log(navigationExtras?.state?.data);
    // this.router.navigate(["/cashless/manage-case/task/"+selectedTask.task_id], navigationExtras);
  }


}
