import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDateRangeInput } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { NavigationExtras, Router } from "@angular/router";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { CashflowService } from "src/app/services/cashflow.service";
import { AddTaskCalculationComponent } from "./add-task-calculation/add-task-calculation.component";
import { UpdateTaskCalculationComponent } from "./update-task-calculation/update-task-calculation.component";
export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}
@Component({
  selector: "app-manage-task-calculation",
  //standalone: false,
  // imports: [],
  templateUrl: "./manage-task-calculation.component.html",
  styleUrl: "./manage-task-calculation.component.scss",
})
export class ManageTaskCalculationComponent {
  hospitalList: any = [];
  selectedHospital: any;
  selectedTask: any;
  deployedAllProcesses: any;
  deployedprocessByProcessDefKey: any;
  selectedDeployedSubEntityProcessByProcessDefKey: any;
  selectedDeployeedProcessEntity: any;
  taskCalculations: any = [];
  selectedStatus:any;
  processName :any;
  taskName:any;
  dataSource = new MatTableDataSource<any>(this.taskCalculations);
  displayedColumns = [
    "name",
    "calculationExpression",
    "processVersion",
    "attributeName",
    "processName",
    "taskName",
    "action",
  ];
  constructor(
    private cashlessService: CashflowService,
    private router: Router,
    private _dialog: MatDialog,
    private adminService: AdminService
  ) {}
  ngOnInit(): void {
    //this.getOnboardedHospitals();
    //this.getCaseList('','','');
    this.getDeployedProcesses();
    //this.categoryForm.controls['inactive'].setValue(this.status);
  }

  getDeployedProcesses() {
    this.cashlessService
      .getDeployedProcesses()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.deployedAllProcesses = data;
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            //this.error = err.error.error;
          } else {
            //this.error = 'No products available for!';
          }
        },
      });
  }

  setProcess(event: any) {
    this.processName  = event.value;
    console.log("GDfg",this.processName);
    this.getDeployedprocessByProcessDefKey(this.processName );
  }
  
  setSubProcess(event: any) {
    this.taskName = event.value;
  }

  setStatus(event: any) {
    this.selectedStatus = event.value;
    //console.log("GDfg",this.selectedStatus);
    this.adminService
    .getTaskCalculations(
      this.processName,
      this.taskName,event.value)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        this.taskCalculations = data;
        this.dataSource.data = this.taskCalculations;

        console.log("fsf", this.taskCalculations);
      },
    });

  }

  getDeployedprocessByProcessDefKey(processName : any) {
    this.adminService
      .getDeployedprocessByProcessDefKey(processName )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.deployedprocessByProcessDefKey = data;
          console.log("fsff", this.deployedprocessByProcessDefKey);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
        },
      });
  }



 
  search() {
    this.adminService
      .getTaskCalculations(
        this.processName,
        this.taskName,this.selectedStatus)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.taskCalculations = data;
          this.dataSource.data = this.taskCalculations;

          console.log("fsf", this.taskCalculations);
        },
      });
  }

  getTaskCalculations(){
    this.adminService
    .getTaskCalculations(
      this.processName,
      this.taskName,this.selectedStatus)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        this.taskCalculations = data;
        this.dataSource.data = this.taskCalculations;

        console.log("fsf", this.taskCalculations);
      },
    });
  }
  clear() {
    this.selectedHospital = "";
    //this.getCaseList("", "", "");
  }

 
  openAddForm() {
    const dialogRef = this._dialog.open(AddTaskCalculationComponent,{disableClose:true,});
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          //this.getCatergoryMaster();
          this.getTaskCalculations();
        }
      },
    });
  }

  openEditForm(data: any) {
    const dialogRef = this._dialog.open(UpdateTaskCalculationComponent, {
      disableClose:true,
      data,
      //width: '750px',
      // height: '200'
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          //this.getCatergoryMaster();
          this.getTaskCalculations();

        }
      },
    });
  }
}
