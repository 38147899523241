import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Country, State, City } from 'country-state-city';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {


  empForm: FormGroup;
  //orgId:any;
  userorg:any
  roles:any=[];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message:any;
  selectedType:any;
  selectedCountry:any;
  selectedState:any;
  countries:any=[];
  states:any = [];
  cities:any = [];
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private _dialogRef: MatDialogRef<AddUserComponent>,
    private toastrService:ToastrService,
    private http: HttpClient

    // @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public data?: any,

    //private _coreService: CoreService
  ) {

    //console.log("dgdsg",data.orgId)
    this.empForm = this._fb.group({
     // id:'',
      firstName: ['',[Validators.required,Validators.maxLength(50)]],
      lastName: ['',[Validators.required,Validators.maxLength(50)]],
      userName:['',Validators.required],
      userType:['',Validators.required],
      emailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      address:['',Validators.required],
      city:['',Validators.required],
      state:['',Validators.required],
      country:['',Validators.required],
     // orgId:'',
      zip:['', [Validators.required, Validators.maxLength(6)]],
      mobilePhone:['', [Validators.required,Validators.maxLength(10) ,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      landingPage:"home"
    });
  }

  ngOnInit(): void {
 this.getroles();
 this.getCountries();
  //  var user = localStorage.getItem("user")
  //   this.user=  user;
  //   this.user = this.orgId;
  //   console.log("orgid",this.user);
   // this.empForm.patchValue(this.data);
    // var data = this.user.orgId;
    // console.log(data)
   }

   getroles(){
    this.adminService.getRoles(this.pageIndex*this.pageSize,this.pageSize).pipe(first())
    .subscribe({
      next: (data:any) => {
        //this.loading = false;
  //console.log("userroles",data);
        this.roles = data;
      },
      error: error => {
        console.log("error " + JSON.stringify(error))
        this.message = error;
  
        //sthis.loading = false;
      }
    });
  }

  onFormSubmit() {
    //debugger;
     
    // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
        // var data =     {
        //   "id": "",
        //   "emailId": "ty@gmail.com",
        //   "firstName": "fgh",
        //   "lastName": "jj",
        //   "userName": "nmbvvm",
        //   "passwd": "bvc",
        //   "mobilePhone": "8745342345",
        //   "landline": "",
        //   "userType": "ADMIN",
        //   "color": "",
        //   "address": "",
        //   "city": "",
        //   "state": "",
        //   "country": "",
        //   "zip": "",
        //   "inactive": true,
        //   "orgId": "741cdabe-4c9b-4892-9326-e12b424bafb5"
          
        // }

         // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
        var data =     {
          "id": "",
          "emailId": this.empForm.value.emailId,
          "firstName": this.empForm.value.firstName,
          "lastName": this.empForm.value.lastName,
          "userName": this.empForm.value.userName,
          "passwd": "",
          "mobilePhone": this.empForm.value.mobilePhone,
          "landline": "",
          "userType": this.selectedType,
          "color": "",
          "address": this.empForm.value.address,
          "city": this.empForm.value.city.name,
          "state": this.empForm.value.state.name,
          "country": this.empForm.value.country.name,
          "zip": this.empForm.value.zip,
          "inactive": true,
          "orgId": "",
          landingPage:"home"
          
        }
        this.adminService.userregister(data).subscribe({
          next: (val: any) => {
            this.toastrService.success("User has been updated successfully");
            this._dialogRef.close(true);
          },
          error: (err: any) => {
            //this.toastrService.error("Error");
            this.toastrService.error(JSON.stringify(err.error.error));

            console.error(err);
          },
        });
   
      
    }

    getCountries(){

      this.countries = Country.getAllCountries(); 
      console.log("List of countries:"+JSON.stringify(this.countries));
      // this.adminService.getCountries().subscribe({
      //   next:(data:any)=>{
      //     //console.log("List of countries:"+JSON.stringify(data));
      //     this.countries = data.countries;
      //     //console.log("List of countries:"+JSON.stringify(this.countries));

      //   }
      // })
    }
    countryId:any;
    setCountry(event:any){
      // console.log("Country selected:"+JSON.stringify(event.value));
       this.countryId = event.value;

      this.getStates(this.countryId);
    }

    getStates(countryId:any){

      this.states = State.getStatesOfCountry(countryId);
      // console.log(this.states);
      // this.adminService.getStates(countryId).subscribe({
      //   next:(data:any)=>{
      //     this.states =  data.states;
      //   }
      // })
    }

    setState(event:any){
      // console.log("state selected:"+JSON.stringify(event.value.id));
      var stateId = event.value;
      this.getCities(this.countryId ,stateId);
      //this.getStates(countryId)
    
    }

    getCities(countryId:any, stateId:any){
      this.cities = City.getCitiesOfState(countryId ,stateId);
      // this.adminService.getCities(stateId).subscribe({
      //   next:(data:any)=>{
      //     this.cities = data.cities;
      //   }
      // })
    }
}