<div class="container p-3">
    <div class="container py-3 px-2 d-flex justify-content-end">
      <div class="addbtn p-3">
        <button type="button" mat-flat-button style="color:white;background:#8e0101;" (click)="openAddForm()">
          Add 
        </button>
      </div>
      </div>
</div>
<div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center">
        
            <div class="p-4">
                <mat-table [dataSource]="dataSource" >
                    <ng-container matColumnDef="senderType">
                        <mat-header-cell *matHeaderCellDef>Sender Type</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.id.senderType}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="taskDefKey">
                        <mat-header-cell *matHeaderCellDef>Task Definiation Key</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.id.taskDefKey}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lookupPhrase">
                        <mat-header-cell *matHeaderCellDef>LookUp Phrase</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.lookupPhrase}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="emailLookupStrategy">
                        <mat-header-cell *matHeaderCellDef>LookUp Strategy</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.emailLookupStrategy}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <mat-header-cell *matHeaderCellDef>Details </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button style="color:white;background:#8e0101" mat-flat-button (click)="showDetails(element)">Details</button>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <mat-header-cell *matHeaderCellDef>Edit </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button style="color:white;background:#8e0101" mat-flat-button (click)="editDetails(element)">Edit</button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row ;columns: displayedColumns;"></mat-row>
                </mat-table>
            </div>
        
    </div>
</div>