import { ChangeDetectorRef, Component, Directive, ElementRef, HostListener, Inject, QueryList, ViewChild, ViewChildren } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { first, lastValueFrom, Observable, of } from "rxjs";
import { map, filter } from "rxjs/operators";
import { ViewFileComponent } from "src/app/portal/bg/inbox/view-file/view-file.component";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { ToastrService } from 'ngx-toastr';
import { FileUploadComponent } from "src/app/file-upload/file-upload.component";
import { ViewUploadFileComponent } from "src/app/file-upload/view-upload-file/view-upload-file.component";
import { InsuranceComponent } from "src/app/onboarding/insurance/insurance.component";
import { ManagetpaService } from "src/app/services/admin/managetpa.service";
import { DatePipe } from "@angular/common";
import { AddKypComponent } from "src/app/portal/bg/inbox/add-kyp/add-kyp.component";
import { EditKypComponent } from "src/app/portal/bg/inbox/edit-kyp/edit-kyp.component";
import { AuthService } from "src/app/services/authService";
import { WorkflowService } from "src/app/services/workflow.service";
import { ClaimsComponent } from "src/app/portal/bg/claims/claims.component";
import { FileSelectDirective } from "ng2-file-upload";
import { FileService } from "src/app/services/file.service";
import { ExternalMediaMetadata } from "src/app/model/mediaMetadata";
import { AdminService } from "src/app/services/admin.service";
import { MemberListComponent } from "../member-list/member-list.component";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent {

  id: any;
  route: any;
  addkyp: boolean = false;
  taskDetailsNew!: FormGroup;
  tpaList: any = [];
  imgData: any;
  insuranceCompanyList: any = [];
  displayedColumns: string[] = ["docType", "fileName", "createdDate", "view"];
  selectedFiles?: any;
  selectedOption: any;
  uploadedFiles: any = [];
  dataSource = new MatTableDataSource<any>(this.uploadedFiles);
  length = 100;
  selectedInusranceCompany: any;
  pageSize = 100;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  formData: FormData = new FormData();
  customerId: any;
  createCase!: FormGroup;
  selectedoption = null;
  DoYouhaveFamilyPhysician = null;
  assignedTasks: any[] = [];
  loading: any = false;
  error: any;
  rowNum = 0;
  pdfUrl: any;
  inputValue: number = 0;
  showError = false;
  showDateError = false;
  size = 100;
  insuranceCompanyMasterData: any[] = [];
  selectedTaskDetails: any;
  message: any;
  form: FormGroup = this.formBuilder.group({});
  lookUpForm: FormGroup = this.formBuilder.group({});
  previousDetails: FormGroup = this.formBuilder.group({});
  tasks: any[] = [];
  formSubmissionProgress: boolean = false
  diagram: any;
  submitted: boolean = false;
  currentState: any;
  insuranceMedia: any = '';
  uploadedData: any = [];
  uploadedHospitalMedia: any = [];
  maxDate = new Date();
  formattedDate: any;
  comparedWith: any;
  user: any;
  showLoading: boolean = false;
  taskForms: FormGroup[] = [];
  taskFormData: any[] = [];
  docTypeList: any = [];
  categoryList: any = [];
  fileExists = false;
  @ViewChildren('formControl') formControls!: QueryList<ElementRef>;
  @ViewChild('myForm', { static: false }) formElement!: ElementRef;
  constructor(
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private cashlessService: CashflowService,
    private customerService: CustomerService,
    private cdr: ChangeDetectorRef,
    //private hospitalRegistrationService: HospitalRegistrationService,
    private fileService: FileService,
    private _dialog: MatDialog,
    private toastrService: ToastrService,
    private tpaService: ManagetpaService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private workflowService: WorkflowService,
    private sanitizer: DomSanitizer
  ) {
    //this.currentState = this.router.getCurrentNavigation()?.extras.state;
    //console.log("currentState", this.currentState);
    // console.log("part2", this.data.task.task);
    // console.log("here", this.id);
  }

  ngOnInit() {
    this.authService.user.subscribe(x => this.user = x);
    // console.log("here", this.customerId);
    console.log("part2", this.data.task);
    this.getTaskDetailsByTask();
    this.maxDate = new Date();
    // console.log(this.maxDate);
    this.initializeForm();
    // this.getCategory();
    this.inputValue = 0;
    this.getTpa();
    //this.getSelectedTaskDetails();
    this.getTaskCalculations();
    this.checkFileExists();
   
   
    //this.subscribeToControlValueChanges();
  }

  pdfSrc = 'path/to/pdf/file.pdf';

  togglePdf() {
    this.showPdf = !this.showPdf;
  }

  // lookUpPatientDetails() {
  //   if (this.lookUpForm.value.policyNumber) {
  //     this.lookupForPatientDetails('policyNumber', this.lookUpForm.value.policyNumber)
  //   } else if (this.lookUpForm.value.phoneNumber) {
  //     this.lookupForPatientDetails('phoneNumber', this.lookUpForm.value.phoneNumber)
  //   } else {
  //     this.lookupForPatientDetails('memberName', this.lookUpForm.value.memberName)
  //   }
  // }

  checkDate(event: any, id: any) {
    console.log(event);
    const selectedDate = this.datePipe.transform(event, 'yyyy-MM-dd');
    console.log(selectedDate);
    this.formattedDate = this.datePipe.transform(new Date(this.maxDate), 'yyyy-MM-dd');
    console.log(this.formattedDate);
    // this.showDateError = event> this.formattedDate;
    if (selectedDate && (selectedDate > this.formattedDate) && (id === 'dob')) {
      // console.log("date more than today ");
      this.showDateError = true;

    } else {
      this.showDateError = false;
    }
  }
  onChangeInsuranceCompanyId(arg0: any) {
    console.log(arg0.value);
    this.filterInsuranceMediaByInsuranceId(arg0.value);

  }
  // progress:any;
  // uploadSOCFileForInsurance(){
  //   this.progress = 0;
  //   this.formData = new FormData();
  //   this.formData.append("file", this.insuranceMedia.media);
  //   this.formData.append("docType", 'SOC_DOCUMENT');
  //   this.formData.append("hospitalId", this.data.hospitalId);
  //   this.formData.append("entityId", this.data.entity_id);
  //   this.formData.append("caseId", this.data.caseId); //it should be process instance i
  // }
  onInputChange(value: number) {
    this.inputValue = value;
    this.showError = value.toString().length !== 10; // Check for exact 10 digits
  }

  hasPriviledge: boolean = true;
  focusFirstInvalidField() {
    const firstInvalidControlName = Object.keys(this.form.controls).find(controlName => {
      const control = this.form.get(controlName);
      console.log(control);
      return control?.invalid;
    });
    console.log(firstInvalidControlName);
    //  const firstInvalidControl = this.form.get('+firstInvalidControlName');
    this.form.get(`${firstInvalidControlName}`)
    console.log(this.form.get(`${firstInvalidControlName}`))
    if (firstInvalidControlName) {
      //this.form.get(firstInvalidControlName)?.nativeElement.focus();
      console.log(this.formControls);
      this.formControls.filter((input: any) => { input.nativeElement.id == firstInvalidControlName });
      // const controlElement = this.formElement.nativeElement.querySelector(`${firstInvalidControlName}`);
      // console.log(controlElement);
      // if (controlElement) {
      //   controlElement.nativeElement.focus();
      // }
    }

  }
  saveDraft() {

    this.cashlessService.saveDraftForFormData(this.data.task.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastrService.success('Draft saved! ')
        }
      })


  }
  submitForm() {
    Object.keys(this.form.controls).forEach((controlName) => {
      console.log(controlName + " " + this.form.get(controlName)?.value);
      // 1. empty 2. special character 3. type (long, double, string)
    });

    if (!this.form.valid) {
      const firstInvalidControlName = Object.keys(this.form.controls).find(controlName => {
        const control = this.form.get(controlName);
        console.log(control);
        return control?.invalid;
      });
      console.log(firstInvalidControlName);
      let element = document.querySelector(`[id="${firstInvalidControlName}"]`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center"
        })
      }
    } else {
      if (this.data.assignee == undefined) {
        this.acceptTask(this.data);
      }

      if (this.data.task.taskDefKey == 'preauth') {
        console.log(this.isPrivileged(['IS_HOSPITAL_USER']));
        console.log(this.data.task.caseVariables.hospital.preAuthFilled);
        if (this.isPrivileged(['IS_HOSPITAL_USER']) && this.data.task.caseVariables.hospital.preAuthFilled == true) {
          this.hasPriviledge = true;
        } else if (this.isPrivileged(['IS_HOSPITAL_USER']) && this.data.task.caseVariables.hospital.preAuthFilled == false) {
          this.hasPriviledge = false;
          this.toastrService.info("This tasks needs to be completed by the hospital authorities!");
        }
        else {
          this.hasPriviledge = true;

        }
      }

      if (this.hasPriviledge) {
        console.log(this.form.value); // Log form values for debugging
        // this.form.get('select-multi-drugAdministrationRoute')?.value
        this.submitted = true;
        this.formSubmissionProgress = true;

        this.cashlessService
          .postFormData(this.data.task.id, this.form.value)
          .pipe(first())
          .subscribe({
            next: () => {
              this.loading = false;
              // console.log("done");
              this.toastrService.success(this.data.task.name, "has been updated successfully");
              this.submitted = false;
              this.formSubmissionProgress = false;
              this.dialogRef.close();
              this.router.navigate(["/cashless/create-case"])
            },
            error: (error) => {
              this.loading = false;
              console.log("error " + JSON.stringify(error));
              this.submitted = false;
              this.toastrService.error("Please fill in the required fields ");
              this.message = error.error.error;
              this.dialogRef.close();

            },
            // () =>{
            //   this.formSubmissionProgress = false;

            // }
          });
      }



      //console.log("Submit Form");
    }
  }

  acceptTask(task: any) {

    this.workflowService.assignTask(task.id, this.user.id, new Date().toISOString())
      .pipe(first())
      .subscribe({
        next: (_data: any) => {
          //console.log("Task accepted");
        },
        error: error => {
          console.error('Error', error);
        }

      });
  }
   otherFields: any[] = [];
   searchFields: any[] = [];
  initializeForm() {
    //console.log("part2", this.data.task.formDataFieldOptions);
    this.getInsuranceByHospitalId(this.data.task.caseVariables.hospitalId);
    this.getCategoryMasterListByTask();
    this.getInsuranceCompanyMaster();

    // if(this.data.task.caseVariables['select-primary-insurance-company-name-modal']){
    //   console.log(this.data.task.caseVariables['select-primary-insurance-company-name-modal']);
    //   this.getInsuranceCompanyNameById(this.data.task.caseVariables['select-primary-insurance-company-name-modal']);
    // }

    this.getViewFileList();
    this.getBPMNDiagram(this.data.task.processInstanceId);
    if (this.data.task) {
      const task = this.data.task; 
      const formControls: Record<string, FormControl> = {};
  
      task.formDataFieldOptions.forEach((field: { property: { required: any; id: string | number; value: any }; }) => {
        const fieldId = field.property.id.toString();
        const validators = [];

       
        if (field.property.required) {
          validators.push(Validators.required);
        }

        if (fieldId.toLowerCase().includes('contact')) {
          validators.push(
            Validators.pattern(/^\d{10}$/), 
            Validators.minLength(10),
            Validators.maxLength(10)
          );
        }

      
        formControls[fieldId] = new FormControl(field.property.value, validators);

       
        const fieldType = this.parseIdForDropdown(fieldId)[1]; 
        if (fieldType === 'search') {
          this.searchFields.push(field);
        } else {
          this.otherFields.push(field);
        }
      });

   
      this.form = this.formBuilder.group(formControls);

      console.log('Form Controls:', formControls);
      console.log('Search Fields:', this.searchFields);
      console.log('Other Fields:', this.otherFields);

    }

    this.getSelectedTaskDetails()

  }




  memberName: any[] = [];

  // lookupForPatientDetails(propertyId: any, value: any) {
  //   if (value) {
  //     this.adminService.lookUpDetails(propertyId, value)
  //       .subscribe({
  //         next: (data: any) => {
  //           console.log(data.length);
  //           if(data.length>0){
  //             this.openMemberListDialog(data);
  //           }else{
  //             this.toastrService.info("No Patient Information available for the given details!. Please fill in the details");
  //             this.showLoading = false;
  //             this.form.reset();
  //           }
           
  //         },
  //         error: (error: any) => {
  //           console.log(error);
  //         }
  //       });
  //   }

  // }

  openMemberListDialog(memberList: any) {
    const memberListDialog = this._dialog.open(MemberListComponent, {
      data: memberList,
      disableClose: true,
      width: '50vw',  // Sets the width to 100% of the viewport width
      maxWidth: '50vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '50%'
    })
    memberListDialog.afterClosed().
      subscribe(
        (result: any) => {
          console.log(result);
          this.form.patchValue(result);
          const patientWithFormattedDob = {
            ...result,
            dob: new Date(result.memberDOB),
            patientName: result.memberName,
            policyMemberMobileNumber: result.memberPhoneNumber
          };
          this.form.patchValue(patientWithFormattedDob);
        });
  }

  lookupForPatient(propertyId: any) {
    this.showLoading = true;
    this.cdr.detectChanges();
    //this.showLoading = true;
    const fieldValue = this.form.get(propertyId)?.value;
    console.log(fieldValue);
    console.log(propertyId);
    // const fieldValue = event.target.value
    if (fieldValue) {
      this.adminService.lookUpDetails(this.parseIdForDropdown(propertyId)[0], fieldValue)
        .subscribe({
          next: (data: any) => {
            console.log(data);
 
            if(data.length>0){
              this.showLoading = false;
              this.openMemberListDialog(data);
            }else{
              this.toastrService.info("No Patient Information available for the given details!. Please fill in the details");
              this.showLoading = false;
              // this.form.reset();
            }
          },
         
          error: (error: any) => {
            console.log(error);
            this.showLoading = false;
          }
        })
    }
   
  }

  

  // patchForm(patientDetails: any) {
  //   this.form.patchValue({
  //     'address': patientDetails.address ? patientDetails.address : '',
  //     'city': patientDetails.city ? patientDetails.city : '',
  //     'firstname': patientDetails.firstName ? patientDetails.firstName : '',
  //     'lastname': patientDetails.lastName ? patientDetails.lastName : '',
  //     'emailid-email': patientDetails.emailId ? patientDetails.emailId : '',
  //     'state': patientDetails.state ? patientDetails.state : '',
  //     'country': patientDetails.country ? patientDetails.country : '',
  //     'zipcode': patientDetails.zip ? patientDetails.zip : ''


  //   })
  // }

  parseId(id: string) {
    return id.split("-");
  }
  parseIdForDropdown(id: string) {
    return id.split("_");
  }

  parseAnotherId(id: string) {
    //console.log(id.split(" "));
    return id.split(" ");

  }
  dropdownValues: any;
  getVariable(_propertyId: any) {
    //this.dropdownValues = data.task.caseVariablesid+'Options
  }

  parseType(fieldId: any, typeName: any) {
    //console.log("Field name :"+fieldId.toLowerCase().includes("contact")+" type :"+typeName);
    if (fieldId.toLowerCase().includes("contact") && typeName == "long") {

      return "tel";
    } else {
      return "number";
    }
  }

  parseExpression2(fieldValue: any, fieldId: any) {
    //console.log("fieldValue "+fieldValue+" fieldId "+fieldId);
    if (fieldValue && fieldValue.includes('==')) {
      const key = fieldValue.split("==");
      const varName = key[0].trim();
      const varCheckValue = key[1].trim().replaceAll("\"", "");

      //get existing value of varName
      const varValue = this.form.get(varName)?.value;
      //this.form.get(fieldId)?.setValue("");
      //console.log(" Field Name ="+varName+", Field Value ="+varValue+", expression Value ="+varCheckValue);
      if (varCheckValue == varValue) {
        this.form.get(fieldId)?.enable();
        return false;
      } else {
        this.form.get(fieldId)?.disable();
        return true;
      }
    } else {
      this.form.get(fieldId)?.enable();
      return false;
    }
  }

  writableCheck(fieldValue: any, fieldId: any, writable: any) {
    if (!(this.parseExpression2(fieldValue, fieldId) || writable)) {
      this.form.get(fieldId)?.disable();
      return true;
    } else {
      this.form.get(fieldId)?.enable();
      return false;
    }
  }

  parseExpression2ForEnum(fieldValue: any, fieldId: any) {
    //console.log("fieldValue "+fieldValue+" fieldId "+fieldId);
    if (fieldValue && fieldValue.includes('==')) {
      const key = fieldValue.split("==");
      const varName = key[0].trim();
      const varCheckValue = key[1].trim().replaceAll("\"", "");
      //get existing value of varName
      const varValue = this.form.get(varName)?.value;
      console.log("var Value " + varValue + " varCheckValue " + varCheckValue + " varName " + varName);
      if (varCheckValue == varValue) {
        this.form.get(fieldId)?.enable();
        return false;
      } else {
        this.form.get(fieldId)?.disable();
        return true;
      }
    } else {
      this.form.get(fieldId)?.enable();
      return false;
    }
  }


  viewClaimStatus() {
    console.log(this.data.task);
    const dialog = this._dialog.open(ClaimsComponent, {
      data: this.data.task,
      disableClose: true,
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
    });

    dialog.afterClosed().subscribe(() => {

    });
  }

  viewInsuranceWebsite() {
    const url = new URL('https://shpv.starhealth.in'); // Replace with your URL
    const username = 'vhi.kaushalya.medi2183@vitrayatech.com'; // Replace with your username
    const password = 'VO##_=Z#H7v8';

    const newWindow = window.open(url, '_blank');



  }

  getBPMNDiagram(taskId: any) {
    this.workflowService
      .getBPMNDiagram(taskId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {

          this.diagram = 'data:image/png;base64,' + data;

          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  async fileUploadComponent() {
    var mediaMetadata = new ExternalMediaMetadata();
    mediaMetadata.category = "WORKFLOW";
    mediaMetadata.entityId = this.data.task.processInstanceId;
    mediaMetadata.entityType = "CASE";
    mediaMetadata.sourceType = this.data.task.taskDefKey;
    const dialogRef = this._dialog.open(FileUploadComponent, {
      disableClose: true,
      data: mediaMetadata/*{
        hospitalId: this.data.task.caseVariables.hospitalId,
        entity_id: this.data.task.caseVariables.userId,
        caseId: this.data.task.processInstanceId,
        taskName: this.data.task.taskDefKey
        mediaMetadata=mediaMetadata;
      }*/,
      width: '60%'
    });

    dialogRef.afterClosed().subscribe(async (val: any) => {
      console.log("after closed:" + val);
      if (val === 'UPLOADED') {
        this.form.patchValue({ 'upload-file': val });
        this.getViewFileList();
      } else if (await this.checkFileExists()) {
        this.form.patchValue({ 'upload-file': 'UPLOADED' });
      } else {
        this.toastrService.error("Upload failed. Please try again!");
      }
    });
  }



  async getViewFileList() {
    try {
      const data: any = await lastValueFrom(
        this.fileService.getExternalMediaList(this.data.task.processInstanceId, 'CASE', '')
      );
      this.uploadedData = data;
      this.uploadedData = this.uploadedData.sort((a: any, b: any) => a.dateCreated - b.dateCreated).reverse();
      this.dataSource.data = this.uploadedData;
      this.uploadedHospitalMedia = data;
      this.docTypeList = this.uploadedHospitalMedia.map((file: any) => file.docType);

      console.log(this.docTypeList);
      return this.docTypeList;
    } catch (error) {
      console.log("error " + JSON.stringify(error));
      this.message = error;
      return [];
    }
  }

  category: any;
  async getCategoryMasterListByTask() {
    // await this.cashlessService.getCategoryByTypeAndEntityType(this.data.task.taskDefKey)
    //   .pipe(first())
    //   .subscribe({
    //     next: (data: any) => {
    //       this.category = data

    //       this.categoryList = this.category.map((category: any) => category.id.categoryName);

    //       console.log(this.categoryList);

    //     }
    //   })
    try {
      const data: any = await lastValueFrom(
        this.cashlessService.getCategoryByTypeAndEntityType(this.data.task.taskDefKey)
      );
      this.category = data
      this.categoryList = this.category.map((category: any) => category.id.categoryName);
      console.log(this.categoryList);
    } catch (error) {
      console.error('Error fetching category:', error);
      this.categoryList = []; // Optionally set an empty array on error
    }
    // this.cashlessService.getCategoryByTypeAndEntityType(this.data.task.taskDefKey)
    //   .pipe(
    //     map((category: any) => category?.id?.categoryName)
    //   )
    //   .subscribe({
    //     next: (data: any) => {
    //       // If data is valid, assign it to categoryList
    //       this.categoryList = data;
    //       console.log(this.categoryList);
    //     },
    //     error: (err) => {
    //       console.error('Error fetching category:', err);
    //       this.categoryList = []; // Optionally set an empty array on error
    //     }
    //   });

  }

  getInsuranceCompanyMaster() {
    this.adminService.getInsuranceCompanyMaster(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
      .subscribe({
        next: (data: any[]) => {
          //this.loading = false;
          console.log(data);
          this.insuranceCompanyMasterData = data;

          this.getInsuranceCompanyNameById(this.data.task.caseVariables['select-primary-insurance-company-name-modal'])
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //this.loading = false;
        }
      });
  }



  getInsuranceCompanyNameById(id: any) {
    console.log(id)
    if (this.insuranceCompanyMasterData) {
      this.selectedInusranceCompany = this.insuranceCompanyMasterData.filter((category: any) => category.id == id);
      console.log(this.selectedInusranceCompany);

    }
  }

  async checkFileExists() {
    // this.getViewFileList().then(()=>{

    // })

    await this.getViewFileList();
    await this.getCategoryMasterListByTask();

    if (this.categoryList && this.docTypeList) {
      console.log(this.categoryList.length)
      for (var i = 0; i < this.categoryList.length; i++) {
        console.log(this.categoryList[i])
        if (this.docTypeList.includes(this.categoryList[i])) {
          console.log("File exists");
          this.fileExists = true;
          return true;
        }
      }

    }
    console.log("File does not exists");

    this.fileExists = false;
    return false;
  }


  openEkypForm() {
    // const ekypData = JSON.parse(this.data.task.caseVariables.patientPolicies);
    const dialogRef = this._dialog.open(EditKypComponent, {
      disableClose: true,
      data: {
        policy: this.data.task.caseVariables.patientPolicies,
        // policyId:policy.policyNumber,
        // order_id: order_id,
        // taskId:taskId

      },
      height: '80%',
      width: '80%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          // this.selectedTask.caseVariables.policy = val;
          // this.selectTask(this.selectedTask);
        }
      },
    });

  }


  compareWithFn(o1: any, o2: any) {
    //console.log("compare with");

    return o1 === o2;
  };

  getEnumOptions(fieldId: string) {
    const field = this.data.task.formDataFieldOptions.find(
      (option: any) => option.property.id === fieldId
    );

    //console.log(JSON.stringify(field.values));
    return field ? field.values : [];
  }

  getSelectedTaskDetails() {
    this.cashlessService
      .getSelectedTask(this.data.task.id)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //window.alert('Registration Successfull');
          console.log("data:" + JSON.stringify(data));
          this.loading = false;
          this.selectedTaskDetails = data;
          console.log("selectedtaskdetails:" + this.selectedTaskDetails);
          if (this.selectedTaskDetails) {
            this.updateForm(this.selectedTaskDetails);
            this.taskDetailsHistory(this.selectedTaskDetails);

            this.getViewFileList();
            //this.getUploadedHospitalMedia();
          }
          //this.registrationsuccess = true;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error.error.error;

          this.loading = false;

        },
      });
  }

  updateForm(json: any) {
    const formControls = this.form.controls;

    // 2.1. Update Existing Controls Based on Field Name Match
    Object.keys(json).forEach(updateKey => {
      const control = formControls[updateKey];
      if (control) {
        let value = json[updateKey];

        // Check if the control is a multi-select field and process the value accordingly
        if (Array.isArray(value) || typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
          // Convert string value to an array
          if (typeof value === 'string') {
            // Remove surrounding brackets and split by comma
            value = value.replace(/^\[|\]$/g, '').split(',').map(item => item.trim());
            console.log(value);
          }

          // Update the control's value
          control.setValue(value);
          console.log(control.value);
        } else {
          // Handle other types of controls or values
          control.setValue(value);
          console.log(control.value);
        }
      } else {
        // Consider handling missing updateJson fields (optional)
        // console.warn(`Field '${updateKey}' not found in the form. Ignoring update.`);
      }
    });

    console.log(formControls);
  }

  /*getUploadedHospitalMedia() {
    this.hospitalRegistrationService.gethospitalMediaByCaseId(this.data.task.caseVariables.hospitalId, this.pageIndex * this.pageSize,
      this.pageSize, this.data.task.processInstanceId).subscribe({
        next: (data) => {
          // console.log("....", data);
          this.uploadedData = data;
          this.dataSource.data = this.uploadedData;
          // console.log("Get Users by Manager Array:" + JSON.stringify(data));
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }*/
  childComponentOutput: any;
  Selected(file: any) {
    console.log(file);
    this.childComponentOutput = file.id;
    this.getExternalMediaById(this.childComponentOutput);
  }
  showPdf: any = false;
  getExternalMediaById(mediaId: any) {
    console.log(JSON.stringify(mediaId));
    this.loading = true;
    this.fileService
      .getExternalMedia(mediaId.id)
      .subscribe({
        next: (data: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.pdfUrl = e.target.result;
            this.showPdf = true;
            this.loading = false;
          }
          reader.readAsDataURL(data);
        },
        error: async (err: any) => {
          this.showPdf = false;
          this.loading = false;
          console.log("error");
          console.log(err);
        },
      });
  }

  onMediaListUpdated(media:any){
    this.uploadedData = media;
  }



  toSentenceCase(str: string) {
    return str.replace(/\b\w+\b/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  viewInsuranceCompanyForm(data: any): void {
    const dialogRef = this._dialog.open(InsuranceComponent, {
      disableClose: true,
      data,
      // Set width to 600px
      //height:'350px',  // Set height to 530px
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          // this.getUserRoleslById();
          //this._dialogRef.close(true);
        }
      },
    });
  }

  taskHistory: any;
  filteredData: any;
  taskDetailsHistory(json: any) {
    this.filteredData = Object.fromEntries(
      Object.entries(json)
        .filter(([key, value]) => !key.includes("TAT") && value !== null)

    );
    console.log(this.filteredData);
    const files = Object.fromEntries(
      Object.entries(json).filter(([key]) => key.includes("upload"))

    );
    console.log(files);
    this.uploadedFiles = Object.entries(files).map(([key, value]) => {
      const fileNameParts = key.split('-'); // Split the file name by '-'
      const fieldName = fileNameParts.slice(1).join(' '); // Extract field name after '-'
      return {
        fieldName: fieldName.charAt(0).toUpperCase() + fieldName.slice(1), // Capitalize first letter
        filePath: value
      };
    });


    this.previousDetails = this.formBuilder.group(this.filteredData);

    console.log(this.previousDetails.controls);
    console.log("Uploaded Files:" + JSON.stringify(this.uploadedFiles));
  }

  policy: any;
  openAddForm(data: any, _policyFile?: any) {
    console.log(data);
    const dialogData = {
      data: data,
      policyFile: 'CASHLESS'
    }
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.width = '1000px';
    config.height = '500px';
    config.data = dialogData;
    const dialogRef = this._dialog.open(AddKypComponent, config);
    dialogRef.afterClosed().subscribe({
      next: (val) => {

        if (val) {
          // console.log("new added",this.selectedTask);
          this.addkyp = true;
          console.log("new added1", val);
          this.policy = val;
          // this.selectedTask.caseVariables.policy = val;
          // this.selectTask(this.selectedTask);

          //this.getUsers();
          //this.getroles();
        }
      },
    });
  }

  openEditForm(_policy: any, orderId: any, id: any) {
    // selectedTask.caseVariables.policy,
    //                   selectedTask.caseVariables.order.id,
    //                   selectedTask.id
    const dialogRef = this._dialog.open(EditKypComponent, {
      disableClose: true,
      data: {
        data: this.policy,
        order_id: orderId,
        member_name: '',
        taskId: id
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          // this.selectedTask.caseVariables.policy = val;
          // this.selectTask(this.selectedTask);
        }
      },
    });
  }

  getInsuranceByHospitalId(hospitalId: any) {
    this.cashlessService.getInsuranceByHospitalId(hospitalId, this.rowNum, this.size)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.insuranceCompanyList = data;

        }
      })
  }
  insurance: any;
  filterInsuranceMediaByInsuranceId(insuranceId: any) {
    this.insurance = this.insuranceCompanyList.filter((insurance: any) => insurance.insuranceId === insuranceId);
    console.log(this.insurance);
    // this.insuranceMedia = this.insurance[0].media;
    // console.log(this.insuranceMedia);
  }

  openViewFile(file: any) {
    console.log(file);
    const dialogRef = this._dialog.open(ViewFileComponent, {
      disableClose: true,
      data: { fileMetaData: file },

    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) { }
      },
    });
  }

  viewUploadedFileComponent() {
    console.log(this.insurance)
    //this.filterInsuranceMediaByInsuranceId(this.insurance)
    var input = {
      task: this.data.task,
      // media: this.insurance.media
    }
    console.log(input);
    const dialogRef = this._dialog.open(ViewUploadFileComponent, {
      disableClose: true,
      data: input
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        console.log("after closed:" + val);

      },
    });
  }

  getTpa() {
    this.tpaService
      .getTpa()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.tpaList = data;
          // console.log("prod", data);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  isDateDisabled(date: Date) {
    return date > this.maxDate;
  }

  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    //console.log(this.user.roles);
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ dd);
    return dd;
  }
  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }
  taskCalculations: any = [];
  getTaskCalculations() {
    this.cashlessService.getTaskCalculations('cashlessProcess', this.data.task.taskDefKey, 'false')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.taskCalculations = data;
          console.log(this.taskCalculations);
          //this.subscribeToControlValueChanges();
        }
      });
  }

  dataChange() {
    console.log("Trying to change data ");
    for (var x = 0; x < this.taskCalculations.length; x++) {
      const attribute = this.taskCalculations[x].id.attributeName;
      console.log(attribute);
      var calculation = this.taskCalculations[x].calculationExpression;
      console.log(calculation);
      Object.keys(this.form.controls).forEach((key) => {
        const v = this.form.get(key)?.value;
        calculation = calculation.replaceAll(key, v);
      });
      var calculatedValue = eval(calculation);
      console.log("calculatedValue " + calculatedValue);
      this.form.get(attribute)?.setValue(calculatedValue);
    }
  }


  taskDetails: any;
  formGroups: FormGroup[] = [];
  getTaskDetailsByTask() {
    this.cashlessService.getCaseDetails(this.data.task.processInstanceId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.taskDetails = data;
          console.log(this.taskDetails);
          this.taskDetails.forEach((task: any) => {
            console.log(task);
            const taskData = Object.fromEntries(
              Object.entries(task.task_data)
                .filter(([key, value]) => !key.includes("TAT") && value !== null)
            );
            this.taskDetailsNew = this.formBuilder.group(taskData);
            this.formGroups.push(this.taskDetailsNew);
            // console.log(this.formGroups);
          });
        }
      });

  }
  toggleView() {
    if (this.showPdf) {
      this.showPdf = false;
    }
    else {
      this.showPdf = false;
    }
  }


}
