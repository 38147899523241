import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { AuthService } from "../services/authService";
import { environment } from "src/environments/environment.prod";
import { Injectable } from "@angular/core";
import { User } from "../model/user";

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
    user: any;
    constructor(private authService: AuthService) {
        this.authService.user.subscribe((x) => {
            this.user = x;
            //console.log("Interceptor " + this.user.jwtToken);
        });
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //const user:any = this.authService.userValue;
        //console.log(user);
        const isLoggedIn = this.user?.jwtToken;
        const isApiUrl = request.url.startsWith(environment.API_URL);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${this.user?.jwtToken}` }
            });
        }
        return next.handle(request);
    }
}