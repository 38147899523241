import { Component } from '@angular/core';
import { HospitalRegistrationService } from '../services/hospitalregistration.service';
import { first } from 'rxjs';
import { City } from '../model/city';


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent {
  clicked = false;
  minBedSize:any = 25;
  maxBedSize:any = 200;
  constructor(public HospitalRegistrationService: HospitalRegistrationService) {
    this.getCities();
  }
  success:any;// ="Congratulations! the Campaign has started.";
  actionMethod() {
    this.HospitalRegistrationService.startCampaing(this.targetCity, this.minBedSize, this.maxBedSize)
    .pipe(first())
    .subscribe({
      next: () => {
        this.success =  "Congratulations! the  "+this.targetCity+" Campaign has started.";
      },
      error: error => {
        console.log("error "+JSON.stringify(error))
        this.success = error.error.error;
      }
    });
    this.success =  "Congratulations! the "+this.targetCity+" Campaign has started.";
  }

  onSelected(value:string): void {
		this.targetCity = value;
    if(this.targetCity == 'Select a city to target.'){
      this.isDisabled = true;
    }else{
      this.isDisabled = false;
    }
	}

  isDisabled:boolean =  true;
  targetCity:any;
  cities:any = [];
  targetCities:any[]=[];
  citiesData:City[] = [];

  getCities(){
    this.HospitalRegistrationService.getCities().subscribe((data:any[]) =>{
//console.log("Cities "+JSON.stringify(data));
      this.cities = data;
      for(var x=0;x< this.cities.length;x++){
          var city = this.cities[x];
          var d = new City();
          d.name = city;
          d.value = city;
          this.targetCities.push(d);
      }
      //console.log(this.targetCities);
    })
  }

}
