<div class="container p-3">
  <div class="section" *ngIf="searchCompleted">
    <div class="loader"></div>
  </div>
  <div class=" p-3" style="text-align:right">
    <button mat-flat-button type="button" style="color: white; background: #8e0101;" (click)="addHospital()">Add </button>
  </div>
  <div class="row pt-3 pb-3 justify-content-md-center">
    <div class="search-container">
      <input  [formControl]="searchField" class="form-control" placeholder="Filter" aria-label="Search" />
      <button mat-flat-button type="button" style="color: white; background: #8e0101;" (click)="handleUInput()">Search </button>
      <button mat-flat-button type="button" style="color: white; background: #8e0101;" (click)="clearSearch()">Clear </button>
    </div>
    
  </div>
  <mat-table class="pt-3" #table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Hospital Name </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.dateCreated | date : "short" }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="contactDetail">
      <mat-header-cell *matHeaderCellDef>Contact Number</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.contactDetail }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pincode">
      <mat-header-cell *matHeaderCellDef>Pincode</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.pinCode }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.cityVillageTown }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="emailId">
      <mat-header-cell *matHeaderCellDef>Contact Email</mat-header-cell>
      <mat-cell *matCellDef="let element"> 
        <div class="email-container"> {{ element.emailId }}</div>
       
       </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statusOfRegistration">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.statusOfRegistration }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openView(element)">
          View
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[10,20,25]" showFirstLastButtons></mat-paginator>
  

  <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
    {{ message }}
  </div>

</div>