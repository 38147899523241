<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1 *ngIf=" data.type == 'addEKYP'">Add EKYP</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div [class]="
        data.type == 'addEKYP' && data.type != undefined ? 'col-md-6' : ''
      " style="padding: 0">
      <div class="container text-center">
        <p *ngIf="data.type == 'addEKYP' && data.type != undefined" style="font-weight: bold">
          Policy File
        </p>
        <div style="width: 100%; max-height: 50%">
          <ngx-extended-pdf-viewer *ngIf="pdfUrl != undefined" [src]="pdfUrl"
            [textLayer]="true" [showHandToolButton]="true">
          </ngx-extended-pdf-viewer>
          <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="data.type == 'addEKYP' && data.type != undefined">
      <!-- <div style="width: 100%; height: 20%;"> -->
      <div class="container text-center">
        <p style="font-weight: bold">Add EKYP</p>
        <app-add-kyp [src]="data"></app-add-kyp>
      </div>
      <!-- </div> -->
    </div>
  </div>
</mat-dialog-content>