<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1>Add Payor Region and Branch</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
            <h4>
                <i class="px-3" style="color: lightgray">* marked fields are mandatory
                </i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div>
<form [formGroup]="zoneForm">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <!-- <input matInput type="text" placeholder="category" > -->
                <mat-select placeholder="category" formControlName="category">
                    <mat-option value="EMAIL">EMAIL</mat-option>
                    <mat-option value="PORTAL">PORTAL</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Insurance Company</mat-label>
                <!-- <input matInput type="text" placeholder="insuranceCompanyId" formControlName="insuranceCompanyId"> -->
                <mat-select placeholder="insuranceCompany" formControlName="insuranceCompanyId">
                    <mat-option *ngFor="let company of payorList"
                        [value]="company.id">{{company?.nameReg}} - {{company.payorType}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Zone Region</mat-label>
                <!-- <input matInput type="text" placeholder="zone" formControlName="zone"> -->
                <mat-select placeholder="zone" formControlName="zone">
                    <mat-option value="NORTH">NORTH</mat-option>
                    <mat-option value="SOUTH">SOUTH</mat-option>
                    <mat-option value="EAST">EAST</mat-option>
                    <mat-option value="WEST">WEST</mat-option>
                    <mat-option value="CENTRAL">CENTRAL</mat-option>

                </mat-select>

            </mat-form-field>
            <form [formGroup]="branchForm" class="branchForm">
                <mat-form-field appearance="outline" class="field">
                    <mat-label>Branch</mat-label>
                    <input matInput type="text" placeholder="Branch" formControlName="branch">

                </mat-form-field>

                <mat-form-field appearance="outline" class="field">
                    <mat-label>Address</mat-label>
                    <input matInput type="text" placeholder="Address" formControlName="address">


                </mat-form-field>

                <mat-form-field appearance="outline" class="field">
                    <mat-label>City</mat-label>
                    <input matInput type="text" placeholder="City" formControlName="city">

                </mat-form-field>

                <mat-form-field appearance="outline" class="field">
                    <mat-label>State</mat-label>
                    <input matInput type="text" placeholder="State" formControlName="state">
                </mat-form-field>

                <mat-form-field appearance="outline" class="field">
                    <mat-label>Pincode</mat-label>
                    <input matInput type="text" placeholder="Pincode" formControlName="pincode">
                </mat-form-field>
                <mat-form-field appearance="outline" class="field">
                    <mat-label>Insurance Email Id</mat-label>
                    <input matInput placeholder="Insurance Email ID" formControlName="insuranceEmailId">

                    <!-- <mat-chip-list #tagList>
                        <mat-chip selected color="primary" *ngFor="let Tag of emailTags" [removable]="removable" 
                            (removed)="removeEmailId(Tag)">
                            {{Tag}}
                            <mat-icon matChipRemove *ngIf="removable">cancel
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <input matInput type="text" placeholder="insuranceEmailId" formControlName="insuranceEmailId"
                        [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addEmailId($event)"> -->

                </mat-form-field>
            </form>



        </div>

        <div mat-dialog-actions class="action">
            <button mat-raised-button type="button" [mat-dialog-close]="false">
                Cancel
            </button>
            <button mat-raised-button color="primary" type="button" [disabled]="zoneForm.invalid || branchForm.invalid" (click)="onFormSubmit()">
                Save
            </button>
        </div>
    </div>
</form>