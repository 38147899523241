import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-upload-policy-file',
  templateUrl: './upload-policy-file.component.html',
  styleUrl: './upload-policy-file.component.scss'
})
export class UploadPolicyFileComponent implements OnInit {

  selectedCategory:any;
  selectedFiles:any;
  currentFile: any;
  progress =0;
  message:any;

  clicked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UploadPolicyFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private customerService:CustomerService,
    private toastrService: ToastrService,
  ) { }
  ngOnInit(): void {
    
  }

  selectFile(event: any): void {
    
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = event.target.files.item(0);
    console.log(this.selectedFiles);
    //this.selectedFiles = file;
    this.currentFile = file;
  }

  uploadFile(){
    this.customerService.uploadHealthFilesForUser(this.selectedFiles,this.data, this.selectedCategory)
    .subscribe( {next: (event: any) => {
      //console.log("event",event);
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
        

      } else if (event instanceof HttpResponse) {
        this.message = event.body.message;
        this.toastrService.success(this.message);

       
      } 
      this.dialogRef.close();
      
    },
    })
  }
  

}
