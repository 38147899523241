import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { first } from "rxjs";
import { ShopService } from "src/app/services/shop.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/services/admin.service";
import { CustomerService } from "src/app/services/customer.service";
import { MatDialog } from "@angular/material/dialog";
import { UpdateProductComponent } from "./update-product/update-product.component";
import { AddProductComponent } from "./add-product/add-product.component";
import Swal from "sweetalert2";

@Component({
  selector: "app-manage-product",
  templateUrl: "./manage-product.component.html",
  styleUrls: ["./manage-product.component.scss"],
})
export class ManageProductComponent {
  [x: string]: any;
  displayedColumns: string[] = [
    "name",
    "price",
    "description",
    "status",
    "action",
    "delete",
  ];
  couponForm: FormGroup;
  change: any;
  categories: any;
  tags: any;

  ngAfterViewInit() {}
  constructor(
    private shopService: ShopService,
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private customerService: CustomerService
  ) {
    this.couponForm = this._fb.group({
      // id: '',
      productName: ["", Validators.required],
      discountCode: ["", [Validators.required, Validators.maxLength(10)]],
      discountPercent: ["", Validators.required],
      discountNote: ["", [Validators.required, Validators.maxLength(300)]],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      maxCount: ["", Validators.required],
      partnerTypeId: ["", Validators.required],
      // orgId:'',
      orgId: ["", Validators.required],
    });
    this.getProducts();
  }
  ngOnInit(): void {}

  length = 100;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 50, 100];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  loading = false;
  message = undefined;
  error: any;
  submitted = false;
  products: any = [];

  pageEvent: PageEvent = new PageEvent();
  dataSource = new MatTableDataSource<any>(this.products);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

   handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getProducts();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  getProducts() {
    this.customerService
      .getProducts()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.products = data;
          this.dataSource.data = this.products;
          this.dataSource.paginator = this.paginator;
          //console.log("prod", data);
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No products available for!";
          }
        },
      });
  }

  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.dataSource.data = this.products.filter((item: any) => {
        return Object.values(item).some((val) =>
          String(val).toLowerCase().includes(query)
        );
      });
    } else {
      this.getProducts();
    }
  }

  openEditForm(data: any) {
    //console.log(data);
    const dialogRef = this._dialog.open(UpdateProductComponent, {
      disableClose:true,
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
          this.getProducts();
        }
      },
    });
  }

  test(a: { index: any }) {
    //console.log(a.index)
    this.change = a.index;
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddProductComponent,{disableClose:true,});
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }

  deleteProduct(data: any) {
    console.log("fg", data);

    Swal.fire({
      title: "Are you sure want to delete this product?",
      //text: 'You will not be able to recover this imaginary file!',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminService
          .deleteProduct(data.id)
          .pipe(first())
          .subscribe({
            next: (data: any) => {
              this.toastrService.success(
                "Product has been deleted successfully"
              );

              //this.loading = false;
              this.getProducts();
              //console.log("roles",data);
              // this.roles = data;
            },
            error: (error) => {
              console.log("error " + JSON.stringify(error));
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(error.error.error));

              this.message = error;

              //sthis.loading = false;
            },
          });

        // console.log('Clicked Yes, File deleted!');
      } else if (result.isDismissed) {
        //console.log('Clicked No, File is safe!');
      }
    });
  }
}
