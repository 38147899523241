import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/authService';
@Component({
  selector: 'app-accountactivationfromemail',
  templateUrl: './accountactivationfromemail.component.html',
  styleUrls: ['./accountactivationfromemail.component.scss']
})
export class AccountactivationfromemailComponent implements OnInit{
  name: any;
  customerId: any;
  customerData: any;
  message:any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authservice: AuthService) {

    
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.customerId = params.userId;
        this.authservice.accountactivation(this.customerId).subscribe((data: AnalyserNode) => {
          this.customerData = data;
        })
      }
    );
  }

}
