<div mat-dialog-title>
    <h1>Claim Orders Details</h1>
</div>
<form [formGroup]="claimDetailForm">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id" readonly >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Amount</mat-label>
                <input matInput type="text" placeholder="Amount" formControlName="amount" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Transaction Date</mat-label>
                <input matInput placeholder="Transaction Date" formControlName="transactionDate" readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Transaction Status</mat-label>
                <input matInput type="text" placeholder="Transaction Status" formControlName="transactionStatus" readonly >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Gst</mat-label>
                <input matInput type="email" placeholder="Gst" formControlName="gst" readonly >
            </mat-form-field>
        </div>
        
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Agreement Accepted</mat-label>
                <input matInput type="text" placeholder="Agreement Accepted" formControlName="agreementAccepted" readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Coupon Id</mat-label>
                <input matInput type="text" placeholder="Coupon Id" formControlName="couponId" readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Discount Percent</mat-label>
                <input matInput type="text" placeholder="Discount Percent" formControlName="discountPercent" readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Member Name</mat-label>
                <input matInput type="text" placeholder="Member Name" formControlName="memberName" readonly >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Order Source</mat-label>
                <input matInput type="text" placeholder="Order Source" formControlName="orderSource"  readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Policy Number</mat-label>
                <input matInput type="text" placeholder="Policy Number" formControlName="policyNumber"  readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Product Id</mat-label>
                <input matInput type="text" placeholder="Product Id" formControlName="productId"  readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>RefOrder Id</mat-label>
                <input matInput type="text" placeholder="RefOrder Id" formControlName="refOrderId"  readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Ref Signature</mat-label>
                <input matInput type="text" placeholder="Ref Signature" formControlName="refSignature"  readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Total Amount</mat-label>
                <input matInput type="text" placeholder="Total Amount" formControlName="totalAmount"  readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Transaction Id</mat-label>
                <input matInput type="text" placeholder="Transaction Id" formControlName="transactionId"  readonly>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>User Id</mat-label>
                <input matInput type="text" placeholder="User Id" formControlName="userId"  readonly>
            </mat-form-field>
        </div>
    </div>
</form>

 