import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomerService } from 'src/app/services/customer.service';
import { BehaviorSubject, first, Observable, take } from 'rxjs';
import { ViewAddKypComponent } from '../view-add-kyp/view-add-kyp.component';
import { EditKypComponent } from 'src/app/portal/bg/inbox/edit-kyp/edit-kyp.component';
import { AdminService } from 'src/app/services/admin.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/authService';
import { ToastrService } from 'ngx-toastr';
import { WorkflowService } from 'src/app/services/workflow.service';
import { MatTableDataSource } from '@angular/material/table';
import { AddEkypDetailsComponent } from 'src/app/process-ekyp/add-ekyp-details/add-ekyp-details.component';
import { EditEkypDetailsComponent } from 'src/app/process-ekyp/edit-ekyp-details/edit-ekyp-details.component';

@Component({
  selector: 'app-view-process-ekyp',
  templateUrl: './view-process-ekyp.component.html',
  styleUrls: ['./view-process-ekyp.component.scss']
})
export class ViewProcessEkypComponent implements OnInit, AfterViewInit {
  selectedTask: any;
  getpolicyFiles: any = [];
  public fields: any = [];
  public dynamicFormGroup!: FormGroup;
  diagram:any;
  ekypdata: any;

  policyMembers: any;
  policyMemberList: any[] = [];
  healthFiles: any;
  results: any;
  loading: any = false;
  error:any;
  message:any;
  policyFiles: any[] = [];
  memberName:any;
  fileInfos?: Observable<any>;
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  today: Date = new Date();
  minDate: string;
  user:any;
  tasks: any[] = [];
  enableEditButton:any;
  dataSource = new MatTableDataSource(this.policyMemberList);
  displayedColumns =['memberName','dob','isHolder']


  constructor(
    public dialogRef: MatDialogRef<ViewProcessEkypComponent>,
    private customerService: CustomerService,
    private adminService: AdminService,
    private authService: AuthService,
    private _dialog: MatDialog,
    private toastrService: ToastrService,
    private workflowService:WorkflowService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;
    this.authService.user.subscribe(x => this.user = x);
  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {
    this.getTasks();
    this.selectTask(this.data);
   this.getPolicyMemberList();
  }


  
  async selectTask(data: any) {
      console.log(data);
      this.selectedTask = data;
      if (this.selectedTask.name == "Process eKYP") {
        this.customerService.getPolicieByPolicyId(this.selectedTask.caseVariables.policyId).pipe(first())
          .subscribe({
            next: (data: any) => {
              this.getpolicyFiles = data;
              this.fields = [];
              this.buildForm(this.selectedTask);
              console.log(this.getpolicyFiles);
            },
            error: (error) => {
              //this.error = error.error;
              //this.message = undefined;
              console.log(error);
              //this.loading = false;
            },
          });
      } else {
        this.getBPMNDiagram(data.id);
        this.fields = [];
        this.buildForm(this.selectedTask);
        if (this.selectedTask.policy) {
          this.ekypdata = JSON.parse(this.selectedTask.caseVariables.policy.ekypData);
          console.log(this.ekypdata);
          // const data = JSON.parse( this.selectedTask.ekyp_data);
          this.policyMembers = this.ekypdata.coverage_information;
          console.log("selectedTask", this.selectedTask);
        }
  
  
        this.getHealthCloudFilesByUser(this.selectedTask.caseVariables.customer.id);
        this.getPolicyForUser(this.selectedTask.caseVariables.customer.id);
      }
  
    }

    private buildForm(task: any) {
      const formGroupFields = this.getFormControlsFields(task);
      this.dynamicFormGroup = new FormGroup(formGroupFields);
    }

    private getFormControlsFields(task: any) {
      let formGroupFields: any = {};
  
      for (let form of task.formDataFieldOptions) {
        if (form.property.type.name == "date") {
          formGroupFields[form.property.id] = new FormControl(
            new Date().toISOString().substring(0, 10)
          );
        } else {
          formGroupFields[form.property.id] = new FormControl("");
        }
  
        this.fields.push({ ...form, fieldName: form.property.id });
        //console.log(form.id);
      }
  
      return formGroupFields;
    }

    getBPMNDiagram(taskId: any) {
      this.workflowService
        .getBPMNDiagram(taskId)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
  
            this.diagram = 'data:image/png;base64,' + data; // use this in <img src="..."> binding
  
            this.loading = false;
          },
          error: (error) => {
            this.error = error.error;
            this.message = undefined;
            console.log(error);
            this.loading = false;
          },
        });
    }
  
    async getHealthCloudFilesByUser(userId: any) {
      console.log(userId);
      // await this.loader.showLoading("Please wait. Looking for your files.");
      this.customerService.getHealthFilesByUserIdForAgent(userId).pipe(take(4))
        .subscribe({
          next: async (data: any) => {
            this.healthFiles = data;
            this.results = data;
            this.error = undefined;
            console.log("data", this.results.healthCloudModelFiles.length);
            // await this.loader.hideLoader();
          },
          error: async error => {
            this.error = error.error;
            console.log(error);
            // await this.loader.hideLoader();
          }
        });
    }


  async getPolicyForUser(userId: any) {
    // await this.loader.showLoading("Please wait. Looking for your files.");
    this.customerService.getPolicy(userId).pipe(take(4))
      .subscribe({
        next: async (data: any) => {
          this.policyFiles = data;
          console.log("data.here", this.policyFiles)
          this.error = undefined;
          // console.log("data", this.results.healthCloudModelFiles.length);
          // await this.loader.hideLoader();
        },
        error: async error => {
          this.error = error.error;
          console.log(error);
          // await this.loader.hideLoader();
        }
      });
  }


  openViewFile(userId: any, metaData: any, fileType: any, type: any) {
    console.log(metaData);
    //policyNumber == 'Processing Failed"
    if (metaData.policyNumber == "Processing Failed") {
      fileType = "Processing Failed";
    }
    // const dialogRef = this._dialog.open(ViewFileComponent, {
    //   data: { fileMetaData: metaData, fileType: fileType, type: type, userId: userId },
    //   width: '90%  ',
    //   // height: '70%'


    // })
    // AddEkypDetailsComponent
    // ViewAddKypComponent
    const dialogRef = this._dialog.open(AddEkypDetailsComponent, {
      disableClose:true,
      data: metaData,
      // { 
        // policyId: metaData.policyNumber,
        // userId: userId,
        // policyPath: metaData.policyPath,
        // caseId: metaData.id
     // },
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
    });
    // dialogRef.afterClosed().subscribe({
    //   next: (val) => {

    //     if (val) {


    //     }
    //   },
    // });;
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        console.log("the value returend is " + JSON.stringify(val))
        if (val) {
          this.enableEditButton = true;
          console.log("new added", this.selectedTask);
          console.log("new added1", val);
          //  this.selectedTask.caseVariables.policy.push(val);
          this.selectedTask.caseVariables.policy = val;
          this.selectTask(this.selectedTask);


        }
      },
    });
  }
  onMemberChange(event: any) {
    this.memberName = event.value;
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  
  openEditForm(policy: any, order_id: any, taskId: any) {
    const dialogRef = this._dialog.open(EditEkypDetailsComponent, {
      disableClose:true,
      data: {
        data: policy,
        order_id: order_id,
       // member_name: this.memberName,
        taskId: taskId
      },
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.selectedTask.caseVariables.policy = val;
          this.selectTask(this.selectedTask);
        }
      },
    });
  }


  
  selectedCategory: any;
  selectCategory(event: any, name: any): void {
    console.log(name)
    if (name === "File Categories") {
      console.log(event.target.value);
      this.selectedCategory = event.target.value;
    }

  }

  uploadHealthCloudFiles(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.adminService
          .uploadHealthFilesForUser(
            this.currentFile,
            this.selectedTask.caseVariables.customer.id,
            this.selectedCategory
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (event instanceof HttpResponse) {
                this.message = event.body.message;
                this.fileInfos = this.adminService.getProfilePic();
              }
            },
            error: (err: any) => {
              console.log(err);
              this.progress = 0;

              if (err.error && err.error.error) {
                this.error = err.error.error;
              } else {
                this.error = "Could not upload the file!";
              }

              this.currentFile = undefined;
            },
          });
      }

      this.selectedFiles = undefined;
    }
  }

  executeTask() {
    let d = this.dynamicFormGroup.value;
    this.dynamicFormGroup.addControl("user", new FormControl(this.user));
    this.dynamicFormGroup.addControl("sourceType", new FormControl("HOBNOB"));
    //console.log(JSON.stringify(this.dynamicFormGroup.value));
    if (this.selectedTask.assignee == null) {
      this.toastrService.error("Please accept the task to excecute it");
    } else {

      this.workflowService
        .executeTask(this.selectedTask.id, this.dynamicFormGroup.value)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            //console.log("tasks " + JSON.stringify(data));
            this.loading = false;
            this.selectedTask = undefined;
            //this.getTasks();
            this.dialogRef.close();
            
          },
          error: (error) => {
            this.error = error.error;
            this.message = undefined;
            console.log(error);
            this.loading = false;
          },
        });

    }
  }
   
  getTasks() {
    this.workflowService.getNewTask("policyConsulting").subscribe(res => {
      this.tasks = res;
     // this.dataSource = this.tasks;
      console.log(this.tasks);
    })
  }


  getPolicyMemberList(){
      this.adminService.lookUpDetails('memberName',(this.selectedTask.caseVariables.customerUserId.firstName+this.selectedTask.caseVariables.customerUserId.lastName))
      .subscribe({
        next:(data:any)=>{
          console.log(data);
          this.policyMemberList = data;
          this.dataSource.data = data;
        },
        error:(error:any)=>{
          console.log(error);
        }
      })
  }
}
