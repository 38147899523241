import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent {
  productForm: FormGroup;
  //orgId:any;
  userorg: any
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  orgs: any;
  partnerTypes: any;
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;


  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<AddProductComponent>,

  ) {
    this.productForm = this._fb.group({
     // id: '',
     name: ['', Validators.required],
     description: ['', [Validators.required]],
     price: ['', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
     shortName: ['', [Validators.required]],
     inactive: ['', Validators.required],
     tcRequired: ['', Validators.required],
     gst: ['', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      // orgId:'',
      processWorkflow: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  products: any;


  onFormSubmit() {
    // {
    //   "id": "string",
    //   "productId": "string",
    //   "discountCode": "string",
    //   "discountPercent": 0,
    //   "discountNote": "string",
    //   "startDate": "2023-11-01T10:04:54.794Z",
    //   "endDate": "2023-11-01T10:04:54.795Z",
    //   "maxCount": 0,
    //   "partnerTypeId": 0,
    //   "orgId": "string",
    //   "dateCreated": "2023-11-01T10:04:54.795Z",
    //   "createdBy": "string",
    //   "inactive": true,
    //   "currentCount": 0
    // }
    // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
    //console.log(this.datePipe.transform(this.couponForm.value.startDate,"yyyy-MM-dd")); //output : 2018-02-13
    // var data =
    // {
    //   "id": "string",
    //   "name": "string",
    //   "description": "string",
    //   "price": 0,
    //   "shortName": "string",
    //   "inactive": true,
    //   "tcRequired": true,
    //   "gst": 0,
    //   "processWorkflow": "string"
    // }
  var data =  {
      //"id": this.couponForm.value.id,
      "name": this.productForm.value.name,
      "description": this.productForm.value.description,
      "price": this.productForm.value.price,
       "shortName": this.productForm.value.shortName,
       "tcRequired": this.productForm.value.tcRequired,
       "gst": this.productForm.value.gst,
       "processWorkflow": this.productForm.value.processWorkflow,

      "inactive": this.productForm.value.inactive,
    }
    this.adminService.addProduct(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("product has been added successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        this.toastrService.error(JSON.stringify(err.error));

      },
    });


  }
}
