<div class="horizontal-scroller ">
  <div class="scroll-content">
    <div class="btn-group" role="group" aria-label="Basic example">
      <div *ngFor="let item of data">
        <ng-container *ngIf="item.bgProductModel; else otherFields">
          <div style="border: 1px solid #ccc; padding: 5px; border-radius: 5px;margin:1px;">
            {{ item.bgProductModel.name }} -
            Qty :{{ item.qty }}, Rate: ({{ item.negotiatedRate }})
          </div>

        </ng-container>

        <ng-template #otherFields>
          <button
            mat-flat-button
          
            style="color:white; background:#8e0101; margin:5px; display: flex; align-items: center; justify-content: space-between; padding: 8px;"
          >
            <span style="display: flex; align-items: center; flex-grow: 1;">
             
              <ng-container *ngIf="item.socId; else cashlessMedia" (click)="onFileSelected(item)">
                {{ item.insuranceName }}
              </ng-container>
              <ng-template #cashlessMedia >
                <ng-conatiner (click)="onFileSelected(item)">
                  {{ item.filename }} ({{ item.docType }})
                </ng-conatiner>
             
              </ng-template>
              <mat-icon
                (click)="deleteMedia(item.id)"
                style="font-size: 16px; cursor: pointer; padding:5px"
              >
                delete
              </mat-icon>
            </span>
          </button>
        </ng-template>
        

        
    
          <!-- <button class="combined-button" (click)="onFileSelected(item)">
            <ng-container *ngIf="item.socId; else cashlessMedia">
              {{ item.insuranceName }}
            </ng-container>
            <ng-template #cashlessMedia>
              {{ item.filename }} ({{ item.docType }})
            </ng-template>
            <mat-icon class="delete-icon" (click)="deleteMedia(item.id)">delete</mat-icon>
          </button> -->
          
          

      </div>

    </div>
  </div>
</div>