import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ShopService } from 'src/app/services/shop.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-edit-store-product',
  templateUrl: './edit-store-product.component.html',
  styleUrls: ['./edit-store-product.component.scss']
})
export class EditStoreProductComponent {

  prductForm: FormGroup;
  ProductId: any;
  user: any
  length = 100;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 50, 100];
  tags: any;
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private shopService: ShopService,
    private _dialogRef: MatDialogRef<EditStoreProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {

    //console.log(data);
    this.prductForm = this._fb.group({
      id: '',
      name: ['', Validators.required],
      type: ['', Validators.required],
      description: ['', Validators.required],
      short_description: ['', Validators.required],
      regular_price: ["", [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      sale_price: ["", [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      stock_quantity: ["", [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      images: ['', Validators.required],
      categories: ['', Validators.required],
      tags: ['', Validators.required],
      date_on_sale_from: [],
      date_on_sale_to: [],
      manage_stock: [],






      // "images": [
      //   {
      //     "src": "string"
      //   }

    });
  }


  ngOnInit(): void {

    // this.gettages();
    this.prductForm.patchValue(this.data);
    //console.log(this.data);
    this.ProductId = this.data.id;
  }

  onFormSubmit() {
    //debugger;


    var data = {

      "name": this.prductForm.value.name,
      "type": "simple",
      "description": this.prductForm.value.description,
      "short_description": this.prductForm.value.short_description,
      "categories": [
        {
          "id":   this.data.categories[0].id,
          "name":  this.data.categories[0].name,
          "slug":  this.data.categories[0].slug

        }
      ],
      "tags": [
        {
          "id": 0,
          "name": "",
          "slug": ""
        }
      ],
      // "images": [
      //   {
      //     "src": "https://staging.bimagarage.com/wp-content/uploads/2023/08/MicrosoftTeams-image-1-1-1.jpg"
      //   }
      // ],
      "images": [
        {
          "src": this.data.images[0].src
        }
      ],
      "id": this.prductForm.value.id,
      "regular_price": this.prductForm.value.regular_price,
      "sale_price": this.prductForm.value.sale_price,
      "stock_quantity": this.prductForm.value.stock_quantity,
      "sku": "",
      "date_on_sale_from": "",
      "date_on_sale_to": "",
      "manage_stock": true

    }
    if (this.data) {
      this.shopService
        .updateProduct(this.ProductId, data)
        .subscribe({
          next: (val: any) => {
            this.toastrService.success("Product has been updated successfully");
            this._dialogRef.close(true);


          },
          error: (err: any) => {
            console.error(err);
            this.toastrService.error("Please update the details");
            // this.toastrService.error(JSON.stringify(err.error.error));

          },
        });
    }
  }


  gettages() {
    this.shopService.getTags(this.pageIndex, this.length).pipe(first())
      .subscribe({
        next: (data: any) => {

          //console.log("tags", data);
          this.tags = data;

        },
        error: error => {
          console.log(error);
        }
      });
  }

}