import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-update-category-master',
 // standalone: true,
 // imports: [],
  templateUrl: './update-category-master.component.html',
  styleUrl: './update-category-master.component.scss'
})
export class UpdateCategoryMasterComponent {

  categoryForm: FormGroup;
  orgId:any;
  user:any;
  status: any;
  type:any;
  
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<UpdateCategoryMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  )
  {
    //console.log(this.data);

  this.categoryForm = this._fb.group({
    // id: '',
    category: ['', Validators.required],
    categoryName: ['', [Validators.required]],
    entityType: ['', [Validators.required]],
    entitySubType:"",
    type: ['', [Validators.required]],
    categoryDescription:"",
    tags:"",
    inactive:""
    //deployeedallprocess:"",
    //deployedprocessByProcessDefKey:""
  
   });
  }

  // "id": "string",
  // "productId": "string",
  // "discountCode": "string",
  // "discountPercent": 0,
  // "discountNote": "string",
  // "startDate": "2023-11-01T05:05:36.709Z",
  // "endDate": "2023-11-01T05:05:36.709Z",
  // "maxCount": 0,
  // "partnerTypeId": 0,
  // "orgId": "string",
  // "dateCreated": "2023-11-01T05:05:36.709Z",
  // "createdBy": "string",
  // "inactive": true,
  // "currentCount": 0
  ngOnInit(): void {

  //  var user = localStorage.getItem("user")
  //   this.user=  user;
  //   this.user = this.orgId;
  //   console.log("orgid",this.user);
  this.status = this.data.inactive ? "Inactive" : "Activate";
  this.type = this.data.DROPDOWN ? "DROPDOWN" : "FILE";

  
  this.categoryForm.controls['inactive'].setValue(this.status);
    this.categoryForm.patchValue(this.data);
    // var data = this.user.orgId;
      this.categoryForm.controls['category'].setValue(this.data.id.category);
      this.categoryForm.controls['categoryName'].setValue(this.data.id.categoryName);
      this.categoryForm.controls['entityType'].setValue(this.data.id.entityType);
      this.categoryForm.controls['entitySubType'].setValue(this.data.id.entitySubType);

      this.categoryForm.controls['type'].setValue(this.data.id.type);


    console.log(this.data)
   }

  onFormSubmit() {
    if (this.categoryForm.valid) {
      var data = {
        
          "category": this.categoryForm.value.category,
          "categoryName": this.categoryForm.value.categoryName,
          "entitySubType":this.categoryForm.value.entitySubType ,
          "entityType": this.categoryForm.value.entityType,
          "type": this.categoryForm.value.type,
          "categoryDescription": this.categoryForm.value.categoryDescription,
          //"createdBy": "",
          //"dateCreated": "",
          //"updatedBy": "",
          //"dateUpdated": "",
          "tags": this.categoryForm.value.tags,
          "inactive": this.categoryForm.value.inactive
        }
      
      if (this.data) {
        this.adminService.updateCategoryMaster(data)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("Category has been updated successfully");


              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));


            },
          });
      } 
      }
    }
  
}