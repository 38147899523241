import { Component, OnInit ,Inject} from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manage-reportee',
  templateUrl: './manage-reportee.component.html',
  styleUrls: ['./manage-reportee.component.scss']
})
export class ManageReporteeComponent implements OnInit {
  manageReporteeForm:FormGroup;
  pageSize = 50;
  pageIndex = 0;
  users:any;
  message:any;
  user:any;
  selectedUser:any;
  today = new Date('en-US');
  
  constructor(
    public adminService:AdminService,
    public toastService:ToastrService,
    private authService:AuthService,
    private _fb:FormBuilder,
    public datePipe: DatePipe,
    private _dialogRef: MatDialogRef<ManageReporteeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
      this.authService.user.subscribe(x=>this.user = x);
      this.manageReporteeForm = this._fb.group({
        manager:['',[Validators.required]]
      });


  }

  ngOnInit(): void {
    var user = localStorage.getItem("user");
    //console.log("User from local storage:"+JSON.stringify(user));
    //throw new Error('Method not implemented.');
    this.getUsers();
    
    
   // this.today = this.datePipe.transform(this.today,'yyyy-MM-dd')
  }

  getUsers(){
    this.adminService.getOrgUsers(this.pageIndex * this.pageSize, this.pageSize)
    .pipe(first())
    .subscribe({
      next:async (data:any)=>{
        this.users = data;
        //console.log("List of users:"+JSON.stringify(this.users));
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
        this.message = error;
      },
    })
    }

    onFormSubmit(){
     
      var data = {
          "id":{
            "userId": this.data,
            "managerId": this.selectedUser,
            "orgId": this.user.orgId,
          },
            "dateCreated":"2024-03-25T16:55:02.743Z",
            "dateUpdated":"2024-03-25T16:55:02.743Z",
            "createdBy": this.user.id,
            "orgGroup": "default",
            "updatedBy": this.user.id
        }
       // console.log("Manager data :"+JSON.stringify(data));

       
       this.adminService.addUserManager(data).subscribe({
        next:(val)=>{
          this.toastService.success("User manager has been updated successfully");
          this._dialogRef.close(true);
        },
        error: (err: any) => {
          // this.toastrService.success("Error");
          this.toastService.error(JSON.stringify(err.error.error));
  
          console.error(err);
        },
       })
    }

     
      
    
  
}
