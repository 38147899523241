<div class="container py-1">
    <div class="row justify-content-md-center">
        <div class="col">
            <div class="p-1 container text-center">
                <a href="https://www.bimagarage.com"><img src="../../assets/img/logo.png" /></a>
                <h1 class="display-8">
                    Welcome to Bima<span style="color: #c00000">Garage</span>!
                </h1>
                <hr />
                <div class="row justify-content-md-center">
                    <div class="col-6 text-center">
                      <div class="h-100 p-5 rounded-3" style="background: #f0f0f0">
                        <h2>Aggregator Registration</h2>
                        <p>
                          Coming soon!
                        </p>
          
                        
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>