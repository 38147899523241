<div class="container p-3">
  <div class="row p-3 pt-3 justify-content-md-center">
    <input
      type="search"
      (input)="handleUInput($event)"
      
      class="form-control"
      placeholder="Filter"
      aria-label="Search"
    />
  </div>
 
    <mat-table  class="pt-3" [dataSource]="orgs" matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="Id">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="FirstName">
                <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="LastName">
                <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
            </ng-container> -->

      <!-- Name Column -->
      <ng-container matColumnDef="Type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.type }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SubType">
        <mat-header-cell *matHeaderCellDef> Sub Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.subType }} </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <!-- <ng-container matColumnDef="Specialty">
                <mat-header-cell *matHeaderCellDef> Specialty </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.subType}} </mat-cell>
            </ng-container> -->

      <!-- Weight Column -->
      <ng-container matColumnDef="User Type">
        <mat-header-cell *matHeaderCellDef> User Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.userType }} </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="EMail">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <!-- <ng-container matColumnDef="Address">
                <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.addLine1}} </mat-cell>
            </ng-container> -->
      <!-- <ng-container matColumnDef="Address2">
                <mat-header-cell *matHeaderCellDef> Address2 </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.addLine2}} </mat-cell>
            </ng-container> -->

      <!-- Symbol Column -->
      <ng-container matColumnDef="City">
        <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.city }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="State">
        <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.state }} </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="Country">
                <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.country}} </mat-cell>
            </ng-container> -->

      <!-- Symbol Column -->
      <!-- <ng-container matColumnDef="Zipcode">
                <mat-header-cell *matHeaderCellDef> Zipcode </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.zipcode}} </mat-cell>
            </ng-container> -->
      <ng-container matColumnDef="Website">
        <mat-header-cell *matHeaderCellDef> Website </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.website }} </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="Contact">
        <mat-header-cell *matHeaderCellDef> Contact </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.contactNos }}
        </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="ContactNos">
                <mat-header-cell *matHeaderCellDef> Contact Nos </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.contactNos}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="DefaultRole">
                <mat-header-cell *matHeaderCellDef> Default Role </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.defaultRole}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="RegistrationStatus">
                <mat-header-cell *matHeaderCellDef> Registration Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.registrationStatus}} </mat-cell>
            </ng-container> -->
      <ng-container matColumnDef="Action">
        <mat-header-cell  style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <!-- <button mat-icon-button color="primary" (click)="openEditForm(row)">
            <mat-icon>edit</mat-icon>
          </button> -->

          <button
          mat-icon-button
          style="color:#8e0101;"
          (click)="openEditForm(row)"
          matTooltip="Edit Form"
          matTooltipPosition="below"
        >
          <mat-icon>edit</mat-icon>
        </button>
          <!-- <button mat-icon-button color="warn">
                    <mat-icon>delete</mat-icon>
                  </button> -->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  
    <div class="demo-toggles">
      <br>
      <mat-paginator
        #paginator
        class="demo-paginator"
        (page)="handlePageEvent($event)"
        [length]="length"
        [pageSize]="pageSize"
        [disabled]="disabled"
        [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
        [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  <div
    class="alert alert-danger mt-2 pt-1"
    role="alert"
    *ngIf="message != undefined"
  >
    {{ message }}
  </div>
</div>
