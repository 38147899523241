import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public MatDialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ){

  }

  ngOnInit(): void {
    
  }

  close(){
    this.MatDialogRef.close(false)
  }

  confirm(){
    this.MatDialogRef.close(true);
  }


}
