<div mat-dialog-title>
    <div class="header">
        <h1>Attach SOC</h1>
        <mat-icon color="accent" (click)="close()">cancel</mat-icon>
    </div>


</div>
<div mat-dialog-content>
    <div class="card">

        <input class="form-control my-2" accept="application/pdf" type="file" (change)="selectFile($event)" />
        <div *ngIf="selectedFiles" class="progress my-3">
            <div
              class="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              attr.aria-valuenow="{{ progress }}"
              aria-valuemin="0"
              aria-valuemax="100"
              [ngStyle]="{ width: progress + '%' }"
            >
              {{ progress }}%
            </div>
    </div>

</div>

<div >
    <mat-dialog-actions align="end">
        <button color="accent" mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</div>