import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent {
  empForm: FormGroup;
  orgId: any;
  user: any
  status: any;

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private _dialogRef: MatDialogRef<EditUserComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    this.empForm = this._fb.group({
      id: '',
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.maxLength(50)]],
      userType: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      // orgId:'',
      zip: ['', [Validators.required, Validators.maxLength(6)]],
      inactive: ['', Validators.required],
      mobilePhone: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      landingPage: "home"
    });
  }

  ngOnInit(): void {

    //  var user = localStorage.getItem("user")
    //   this.user=  user;
    //   this.user = this.orgId;
    //   console.log("orgid",this.user);
    this.status = this.data.inactive ? "Inactive" : "Activate";
    this.empForm.controls['inactive'].setValue(this.status);
    this.empForm.patchValue(this.data);
    // var data = this.user.orgId;
    // console.log(data)
  }

  onFormSubmit() {
    if (this.empForm.valid) {
      if (this.data) {
        this.adminService
          .updateUser(this.data.id, this.empForm.value)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("User has been updated successfully");

              this._dialogRef.close(true);
            },
            error: (err: any) => {
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error.error));


              console.error(err);
            },
          });
      }

    }
  }

}