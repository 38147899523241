<div class="container pt-1 ">
  <div class="row">
    <div class="col-8 pt-3 " *ngIf="hospitalList != undefined && isPrivileged(['CAN_VIEW_HOSPITAL'])">
      <mat-form-field class="example-full-width">
        <mat-select placeholder="Select a hospital" [(ngModel)]="selectedHospital">
          <mat-option *ngFor="let hospital of hospitalList" [value]="hospital">
            {{hospital.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-8 pt-3" *ngIf="!isPrivileged(['CAN_VIEW_HOSPITAL']) && selectedHospital != undefined">
      <h2> {{this.selectedHospital.name}}</h2>
    </div>
    <div class="col-4 pt-4 text-end">
      <div class="example-button-row">
      <button mat-flat-button  (click)="startProcess();"
        [disabled]="disabled == true" style="color:white;background:#8e0101;"><mat-icon>add_circle_outline</mat-icon> Initiate Admission</button></div>
    </div>
  </div>

  <mat-tab-group #tabGroup class="p-3" [selectedIndex]="selectedTab">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">dashboard</mat-icon>
        Dashboard
      </ng-template>
      <app-workflow-dashboard [dashboardParam]="getDashboardParam()"></app-workflow-dashboard>
    </mat-tab>
   <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">folder</mat-icon>
       Cases 
     
    </ng-template>
    <app-manage-cases></app-manage-cases>
   </mat-tab>
    
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">inbox</mat-icon>
        Inbox (New Tasks)
      </ng-template>
        <!-- <app-cashless-inbox></app-cashless-inbox> -->
         <app-new-inbox></app-new-inbox>
    </mat-tab>

    <!--<mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">assignment</mat-icon>
        Assignments (Pending Tasks)
      </ng-template>
        <app-assignment-cashless></app-assignment-cashless>
    </mat-tab>-->

  </mat-tab-group>
</div>