import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit{
  selectedTask: any;
  caseId:any;
  form!:FormGroup;
  taskData:any;
  constructor(
    public dialogRef:MatDialogRef<TaskDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,

  ){
    this.selectedTask = this.data;
    // console.log("currentState", this.selectedTask);
    
  }
  ngOnInit(): void {
    this.caseId = this.data.taskId; 
  
  } 
  


  
}

