<mat-dialog-content class="mat-typography">
    <div class="container py-1">
      <div mat-dialog-title>
        <div class="row m-2 justify-content-md-center">
          <div class="col p-3">
            <div class="card">
              <div class="card-header text-center bg-light">Medical Scrutiny Diagnosis</div>
              <div class="card-body">
               
                <div class="row pt-2">
                 <input matInput type="text"   class="form-control" placeholder="Enter the search term" [formControl]="searchTerm">
                </div>
  
                <div class="row pt-2">
                  <button type="submit"  class="btn btn-success btn-sm" (click)="getDiagnosis()" [disabled]="disabled">Get Diagnosis</button>
                </div>
                <div class="overlay" *ngIf="diagnosisInProgress">
                    <div class="spinner"></div>
                  </div>
                <div class="row pt-2" *ngIf="providedDiagnosis"> 
                    <h4>Provisional Diagnosis</h4>
                    <p>{{ providedDiagnosis }}</p> 
                  </div>
                
                
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button (click)="close()" [disabled]="closed">Close</button>
  </mat-dialog-actions>
  