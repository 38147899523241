import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AddAssignTaskComponent } from 'src/app/modal/add-assign-task/add-assign-task.component';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { CashflowService } from 'src/app/services/cashflow.service';
import { CustomerService } from 'src/app/services/customer.service';
import {faClock} from "@fortawesome/free-solid-svg-icons";
import { ViewReimbursementComponent } from 'src/app/modal/view-reimbursement/view-reimbursement.component';
import { DataServiceService } from '../../../services/data-service.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { DataShareModel } from 'src/app/model/dataShareModel';

@Component({
  selector: 'app-assignment-reimbursement',
  templateUrl: './assignment-reimbursement.component.html',
  styleUrls: ['./assignment-reimbursement.component.scss']
})
export class AssignmentReimbursementComponent implements OnInit, AfterViewInit {

  faClock = faClock;
  minDate:any;
  loading: any = false;
  error:any;
  message:any;
  user:any;
  selectedTask: any;
  tasks:any[]=[];
  assignedTasks:any[]=[];
  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private cashlessService: CashflowService,
    private dataService: DataServiceService,
    private workflowService:WorkflowService

  ) {
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;
    this.authService.user.subscribe(x => this.user = x);
    this.dataService.newTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "reimbursementProcessHobnob") {
        this.tasks = data.tasks;
      }
    });
    this.dataService.assignedTasksData$.subscribe((data: any) => {
      if (data.getProcessName() == "reimbursementProcessHobnob") {
        this.assignedTasks = data.tasks;
      }
    });


  }
  ngAfterViewInit(): void {
    //this.getReimbursementAssignedTask();
  }
  ngOnInit(): void {
    //this.getReimbursementAssignedTask();
  }
 
  getReimbursementAssignedTask() {
    console.log("in get all assigned ")
    
      this.workflowService
      .getAssignedTask("reimbursementProcessHobnob")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.assignedTasks = data;
          console.log("assignedTasks " + JSON.stringify(this.assignedTasks));
          const dataShare = new DataShareModel("reimbursementProcessHobnob",data);

          this.dataService.updateAssignedTasks(dataShare);

          
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });  
    
  }

  getTasks() {
    var variables: any[][] = [[], []];
    this.workflowService
      .getNewTask("reimbursementProcessHobnob")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.tasks = data;
          //this.dataSource.data = data;
          //console.log("tasks " + JSON.stringify(this.tasks));
          const dataShare = new DataShareModel("reimbursementProcessHobnob",data);

          this.dataService.updateNewTasks(dataShare);
          this.loading = false;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }
  assignTask(data:any)
  {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data:{data:data, roleName:['ADMIN','HNB']},
      width:'430px', 
      height:'200'
    });
    dialogRef.afterClosed().subscribe({
      next: (val:any) => {
        if (val) {
          this.getReimbursementAssignedTask();
          this.getTasks();
        }
      },
    });
  }

  viewTask(element:any){
    this.selectedTask = element;
    console.log("here",this.selectedTask);
    if(this.selectedTask.assignee == undefined){
     // this.toastrService.info("Please accept or assign the task to view");
  
    }else{
      const dialogRef = this._dialog.open(ViewReimbursementComponent, {
        disableClose:true,
        data: this.selectedTask,
        width:'90%', 
        height:'90%'
      });
      dialogRef.afterClosed().subscribe({
        next: (val:any) => {
          this.selectedTask = undefined;
          this.getReimbursementAssignedTask();
          this.getTasks();
        },
      });
    }
  
}

  isPrivileged(privilege:string[]){
    const privileges:any[] = this.user.privileges;
    var dd =  privileges.some((x: string) => privilege.includes(x));
//console.log("is Privileged "+ this.user.privileges);
    return dd;
  }
  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    console.log(this.user.roles);
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ dd);
    return dd;
  }
}

