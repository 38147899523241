<div class="container p-3">

    <div class="d-flex justify-content-end">
        <button type="button" mat-flat-button style="color: white; background: #8e0101"
            (click)="addTechincalInsurance(hospitalDetails)">
            Add
        </button>
    </div>
    <div class="row pt-3 pb-3 justify-content-md-center">
        <div class="col">
            <input matInput (keyup)="applyFilter($event)" placeholder="Filter" class="form-control">

        </div>

    </div>
    <div class="table-container">
        <table mat-table class="table" [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="insuranceName">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.insuranceName }}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="claimSubmissionMethod">
                <th mat-header-cell *matHeaderCellDef>Tech Method</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.claimSubmissionMethod }}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="socId">
                <th mat-header-cell *matHeaderCellDef>Soc</th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="openViewFile(element)"> View </a>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef>Created Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.dateCreated | date : "short" }}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="senderConfig">
                <th mat-header-cell *matHeaderCellDef>Sender's Config</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button style="color:#8e0101;" (click)="viewSendingMailParser(element)">
                        <mat-icon>mail_outline</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="update">
                <th mat-header-cell *matHeaderCellDef>Update</th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-flat-button style="color: white; background: #8e0101"
                        (click)="editTechincalInsurance(element)">
                        Update
                    </button>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="addPortalDetails">
                <th mat-header-cell *matHeaderCellDef>Portal Details</th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" mat-flat-button style="color: white; background: #8e0101; margin:2px"
                        (click)="addPortalDetails(element)">
                        Add
                    </button>
                    <button type="button" mat-flat-button style="color: white; background: #8e0101"
                        (click)="viewPortalDetails(element)">
                        View
                    </button>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    
    </div>

  
    <mat-paginator #paginator [length]="dataSource.data.length || 0" [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons>
    </mat-paginator>
    <!-- 
        <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
            {{ message }}
        </div> -->
</div>

<div *ngIf="insuranceData.length === 0">
    <div class="alert alert-info mt-2 pt-1" role="alert">
        No Insurance Companies found.
    </div>
</div>