<div class="container pt-3 py-1">

  <div class="row m-2  justify-content-md-center">
    <div class="col-5 p-3 ">
      <div class="card">
        <div class="card-header text-center bg-light">
          Upload medical records.
        </div>
        <div class="card-body">
          <div class="row pt-2">
            <div class="col">
              <select id="category" class="form-select" aria-label="Default select example" required="true"
                (change)="selectCategory($event)">
                <option value="" selected disabled>Select a category</option>
                <option value="PRESCRIPTION">Prescription</option>
                <option value="REPORT">Report</option>
                <option value="SUMMARY">Summary</option>
                <option value="POLICY">Policy</option>
                <option value="OTHERS">Others</option>
              </select>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col text-end">
              <label class="btn btn-default p-0">
                <input class="form-control" type="file" accept="application/pdf,image/png,image/jpeg,image/jpg"
                  (change)="selectFile($event)" />
              </label>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col text-end">
              <button class="btn btn-success btn-sm"
                [disabled]="selectedFiles == undefined || selectedCategory == undefined"
                (click)="uploadHealthCloudFiles()">
                Upload
              </button>
            </div>
          </div>


          <div *ngIf="currentFile" class="progress my-3">
            <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
              attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
              [ngStyle]="{ width: progress + '%' }">
              {{ progress }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="container py-1">
  <div class="card p-4 m-1 overflow-auto w-10 custom-scrollbar">
    <div class="col-md-12 text-center ">
      <h2>Health Cloud Files</h2>
    </div>

    <div class="row  px-3 py-4 justify-content-md-center">
      <input type="search" (input)="handleUInput($event)" class="form-control" placeholder="Filter" aria-label="Search">
    </div>
    <div class="row">
      <div class=" p-4">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Position Column -->
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.category }}
            </td>
          </ng-container>
          <ng-container matColumnDef="mediaType">
            <th mat-header-cell *matHeaderCellDef>Document Type</th>
            <td mat-cell *matCellDef="let element">
              {{ element.mediaType }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef>Date Uploaded</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dateCreated | date : "short" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef>File</th>
            <td mat-cell *matCellDef="let element">
              <a (click)="openViewFile(element)"> View File </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="demo-options p-4">
        <div class="demo-toggles">
          <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>

        </div>
      </div>
      <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
        {{ message }}
      </div>
    </div>
  </div>
</div>