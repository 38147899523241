<div class="row p-3 py-4 bg-light">
  <div class="row bg-light">
    <div class="col-9 p-3 d-flex justify-content-start">
      <h1>Update Files for {{ data.data?.name }}</h1>
    </div>
  
    <div class="col p-2 d-flex justify-content-end">
      <h4>
        <i class="p-2" style="color: rgb(183, 0, 0)"
          >* <span style="color: lightgray">marked fields are mandatory</span>
        </i>
      </h4>
  
      <!-- <div class="p-2 example-button-container" mat-dialog-close>
        <button style="color: white; background: #8e0101" mat-mini-fab>
          <mat-icon>Close</mat-icon>
        </button>
      </div> -->
    </div>
  </div>
  <div class="row p-3 ">
    <div class="col-1 p-2 text-secondary">
      <strong>UPLOADED SOC(s) </strong>
    </div>
    <div class="col-10">
      <app-attachment-scroller [data]="uploadedData"
        ></app-attachment-scroller>
    </div>
    <div class="col-1 pt-1 d-flex justify-content-end" style="color:#009ed3;">
      <div *ngIf="loading" class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  
</div>
<mat-dialog-content class="mat-typography">
  <div class="card">
    <div class="card-body">
      <form class="overflow-card" [formGroup]="form">
        <div class="container row">
          <div class="">
            <label for="selectInsuranceCompanies"
              >Select Insurance Company
            </label>
            <select
              (change)="onChangeInsuranceCompanyId($event.target)"
              class="form-select my-2"
              formControlName="selectInsuranceCompanies"
              id="selectInsuranceCompanies"
            >
              <option
                *ngFor="let insuranceCompany of insuranceCompanies"
                [value]="insuranceCompany.id"
              >
                {{ insuranceCompany.nameReg }}
              </option>
            </select>
          </div>
          <div class="">
            <label for="blacklisted">Select Blacklisted Type </label>
            <select
              (change)="onChangeInsuranceCompanyblacklisted($event.target)"
              class="form-select my-2"
              formControlName="blacklisted"
              id="blacklisted"
            >
              <option value="true">Yes</option>
              <option selected value="false">No</option>
            </select>
          </div>
          <div class="">
            <label for="selectedCategory">Select Category </label>
            <select
              class="form-select"
              required="true"
              formControlName="selectedCategory"
              id="selectedCategory"
              [(ngModel)]="selectedCategory"
              [compareWith]="compareWithFn"
            >
              <option
                *ngFor="let category of categoryList"
                [value]="category.id.categoryName"
              >
                {{ category.id.categoryName }}
              </option>
            </select>
          </div>
          <div class="">
            <label class="normal-font">Valid From </label>
            <input
              class="form-control"
              type="date"
              formControlName="startDate"
              [(ngModel)]="startDate"
            />
          </div>
          <div class="">
            <label class="normal-font">Valid To</label>
            <input
              class="form-control"
              type="date"
              formControlName="endDate"
              [(ngModel)]="endDate"
            />
          </div>
          <div class="">
            <label for="blacklisted"
              >Select Claim Submission Method Type
            </label>
            <select
              (change)="onChangeInsuranceCompanyclaims($event.target)"
              class="form-select my-2"
              formControlName="claimSubmissionMethod"
              id="claimSubmissionMethod"
            >
              <option value="EMAIL">EMAIL</option>
              <option value="PORTAL">PORTAL</option>
              <option value="INTEGRATION">INTEGRATION</option>
            </select>
          </div>
          <div>
            <label for="upload">Upload SOC</label>
            <div class="col">
              <label class="btn btn-default p-0">
                <input
                  class="form-control my-2"
                  accept="application/pdf"
                  type="file"
                  (change)="selectFile($event)"
                  formControlName="upload"
                />
              </label>
              <!-- <ul class="list-group list-group-flush">
                <li
                  *ngFor="let file of fileInfos | async"
                  class="list-group-item"
                >
                  <a href="{{ file.url }}">{{ file.name }}</a>
                </li>
              </ul> -->
              <div mat-dialog-actions class="action">
                <button
                  mat-raised-button
                  type="button"
                  [mat-dialog-close]="false"
                >
                  Cancel
                </button>
                <button
                  mat-flat-button
                  style="color: white; background: #8e0101"
                  [disabled]="selectedFiles == undefined || clicked"
                  (click)="uploadFiles(); clicked = true"
                >
                  Submit
                </button>
                
              </div>
              <div *ngIf="selectedFiles" class="progress my-3">
                <div
                  class="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  attr.aria-valuenow="{{ progress }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }"
                >
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close (click)="close()">Close</button>
</mat-dialog-actions>
