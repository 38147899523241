import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-view-policy-members',
  templateUrl: './view-policy-members.component.html',
  styleUrl: './view-policy-members.component.scss'
})
export class ViewPolicyMembersComponent implements OnInit{

  dataSource=  new MatTableDataSource;
  displayedColumns = ['memberName','ped','dob' , 'relationship','mobileNumber'];
  @ViewChild(MatPaginator) paginator! : MatPaginator;
  constructor(
    @Inject(MAT_DIALOG_DATA) public policyMembers:any,

  ){
    this.dataSource.data = this.policyMembers;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
      console.log(this.policyMembers);
  }
}
