import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { CashflowService } from 'src/app/services/cashflow.service';



@Component({
  selector: 'app-add-category-master',
 // standalone: true,
 // imports: [],
  templateUrl: './add-category-master.component.html',
  styleUrl: './add-category-master.component.scss'
})
export class AddCategoryMasterComponent {

  categoryForm: FormGroup;
  //orgId:any;
  userorg: any
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  orgs: any;
  partnerTypes: any;
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;
  todayDate:Date = new Date();
  deployedprocessByProcessDefKey:any;
  selectedDeployeedProcessEntity:any;
  selectedDeployedSubEntityProcessByProcessDefKey:any;
  selectedtags:any = [];

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private cashlessservice:CashflowService,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<AddCategoryMasterComponent>,

  ) {
    this.categoryForm = this._fb.group({
     // id: '',
     category: ['', Validators.required],
     categoryName: ['', [Validators.required]],
     entityType: ['', [Validators.required]],
     type: ['', [Validators.required]],
     categoryDescription:"",
     tags:"",
     deployeedallprocess:"",
     deployedprocessByProcessDefKey:"",
     inactive:""
   
    });
  }

  ngOnInit(): void {
    this.getDeployedProcesses();
  }

  deployedAllProcesses: any;

  getDeployedProcesses() {
    this.cashlessservice.getDeployedProcesses().pipe(first()).subscribe({
      next: (data: any) => {
        this.deployedAllProcesses = data;
      }, error: (err: any) => {
        console.log(err);

        if (err.error && err.error.error) {
          this.error = err.error.error;
        } else {
          this.error = 'No products available for!';
        }

      }
    })
  }


  key:any;
  setProcess(event:any){
    // console.log("Country selected:"+JSON.stringify(event.value));
     this.key = event.value;

    this.getDeployedprocessByProcessDefKey(this.key);
  }

  getDeployedprocessByProcessDefKey(key:any){


    this.adminService.getDeployedprocessByProcessDefKey(key).pipe(first())
    .subscribe({
      next: (data: any) => {
        //this.loading = false;
        this.deployedprocessByProcessDefKey = data;
        console.log("fsff",this.deployedprocessByProcessDefKey)
      },
      error: error => {
        console.log("error " + JSON.stringify(error))
        this.message = error;

        //this.loading = false;
      }
    });
    // console.log(this.states);
    // this.adminService.getStates(countryId).subscribe({
    //   next:(data:any)=>{
    //     this.states =  data.states;
    //   }
    // })
  }

 

  onFormSubmit() {
    // {
    //   "id": "string",
    //   "productId": "string",
    //   "discountCode": "string",
    //   "discountPercent": 0,
    //   "discountNote": "string",
    //   "startDate": "2023-11-01T10:04:54.794Z",
    //   "endDate": "2023-11-01T10:04:54.795Z",
    //   "maxCount": 0,
    //   "partnerTypeId": 0,
    //   "orgId": "string",
    //   "dateCreated": "2023-11-01T10:04:54.795Z",
    //   "createdBy": "string",
    //   "inactive": true,
    //   "currentCount": 0
    // }
    // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
    //console.log(this.datePipe.transform(this.couponForm.value.startDate,"yyyy-MM-dd")); //output : 2018-02-13
    // var data =

    // {
    //   //"id": this.couponForm.value.id,
    //   "productId": this.selectedProductTypeId,
    //   "discountCode": this.couponForm.value.discountCode,
    //   "discountPercent": this.couponForm.value.discountPercent,
    //   "discountNote": this.couponForm.value.discountNote,
    //    "startDate": this.couponForm.value.startDate,
    //    "endDate": this.couponForm.value.endDate,
    //   //"startDate": "2023-11-01T10:04:54.794Z",
    //   //"endDate": "2023-11-01T10:04:54.795Z",
    //   // "startDate": this.datePipe.transform(this.couponForm.value.startDate, "yyyy-MM-dd"),
    //   // "endDate": this.datePipe.transform(this.couponForm.value.endDate, "yyyy-MM-dd"),
    //   //"startDate": this.datePipe.transform(this.couponForm.value.startDate, "yyyy-MM-dd"),
    //   //"endDate": this.datePipe.transform(this.couponForm.value.endDate, "yyyy-MM-dd"),
    //   "maxCount": this.couponForm.value.maxCount,
    //   "partnerTypeId": this.couponForm.value.partnerTypeId,
    //   "orgId": this.couponForm.value.orgId,
    //   "dateCreated": "2023-11-01T10:04:54.795Z",
    //   "createdBy": "",
    //   "inactive": true,
    //   "currentCount": 0


    // }

    var data ={
      
        "category": this.categoryForm.value.category,
        "categoryName": this.categoryForm.value.categoryName,
        "entitySubType": this.selectedDeployedSubEntityProcessByProcessDefKey,
        "entityType":this.selectedDeployeedProcessEntity,
        "type": this.categoryForm.value.type,
        "categoryDescription": this.categoryForm.value.categoryDescription,
        "createdBy": "",
        "dateCreated": "",
        "updatedBy": "",
        "dateUpdated": "",
        "tags":this.selectedtags.join(", "),
        //"inactive": true
        "inactive": this.categoryForm.value.inactive

      }
      
    
    this.adminService.addCategoryMaster(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("category has been added successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));


      },
    });


  }
}
