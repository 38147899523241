import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';

@Component({
  selector: 'app-add-email-lookup-detail',
  templateUrl: './add-email-lookup-detail.component.html',
  styleUrl: './add-email-lookup-detail.component.scss'
})
export class AddEmailLookupDetailComponent implements OnInit{
  detailForm!: FormGroup;
  user:any;
  today = new Date();
  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private dialog : MatDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AddEmailLookupDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){

    this.detailForm = this.formBuilder.group({
      fieldName : ["",Validators.required],
      fieldType : ["",Validators.required],
      fieldPattern : ["",Validators.required],
      infoType : ["",Validators.required],
      dataLookupStrategy : ["",Validators.required],
      fieldValue : ["",Validators.required],
      fieldMatchCondition : ["",Validators.required],

    })
    this.authService.user.subscribe((x: any) => {
      this.user = x;
      
    });
    if(data.type == 'edit'){
      console.log(data.emailLookup);
      this.detailForm.patchValue(
        { 
          fieldName: data.emailLookup.fieldName,
          fieldType: data.emailLookup.fieldType,
          fieldPattern: data.emailLookup.fieldPattern,
          infoType: data.emailLookup.infoType,
          dataLookupStrategy: data.emailLookup.dataLookupStrategy,
          fieldValue: data.emailLookup.fieldValue,
          fieldMatchCondition: data.emailLookup.fieldMatchCondition
        });
    }
  }
  ngOnInit(): void {
    
  }

 

  onSubmit(){
    const details = {
      "id":'',
      "fieldName" : this.detailForm.value.fieldName,
      "fieldType": this.detailForm.value.fieldType,
      "fieldPattern": this.detailForm.value.fieldPattern,
      "inactive": true,
      "createdBy":this.user.id,
      "dateCreated":this.today.toISOString(),
      "updatedBy":this.user.id,
      "dateUpdated":this.today.toISOString(),
      "senderId":this.user.id,
      "senderType":this.data.emailLookup.senderType,
      "taskDefKey":this.data.emailLookup.taskDefKey,
      "infoType": this.detailForm.value.infoType,
      "dataLookupStrategy": this.detailForm.value.dataLookupStrategy,
      "fieldValue": this.detailForm.value.fieldValue,
      "fieldMatchCondition": this.detailForm.value.fieldMatchCondition

    }
    if(this.data.type == 'edit'){
      details.inactive = this.data.emailLookup.inactive;
      details.createdBy = this.data.emailLookup.createdBy;
      details.dateCreated = this.data.emailLookup.dateCreated;
      details.id = this.data.emailLookup.id;
     
    }
    this.dialogRef.close(details);
    
  }

  
}
