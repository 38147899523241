import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgOtpInputComponent, NgOtpInputModule } from "ng-otp-input";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { CashflowService } from "src/app/services/cashflow.service";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-pre-auth-details",
  templateUrl: "./pre-auth-details.component.html",
  styleUrl: "./pre-auth-details.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class PreAuthDetailsComponent implements OnInit {
  apiUrl: string = environment.API_URL;
  [x: string]: any;
  caseId: any;
  details: any;
  insuranceMediaId: any;
  insurance: any;
  addressLength: any;
  date = new Date();
  jsonData: any = {};
  constructor(
    private cashFlowService: CashflowService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  @ViewChildren("ngOtpInput") ngOtpInputs!: QueryList<NgOtpInputComponent>;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.caseId = params["id"]; // Access the 'id' parameter from the URL\
      this.getDetails(); // Call the function to fetch the pre-authorization details for the given case ID\
      
    });
  }

  setInsuranceMediaId(data: any) {
    //var x = JSON.parse(data);
    for (var key in data) {
      console.log(key);
      //var k = key.replace(/^"|"$/g, "");
      if (key == "select-primary-insurance-company-name-modal") {
        this.insuranceMediaId = data[key];
        this.getInsuranceCompany();
        console.log("Id: " + JSON.stringify(this.insuranceMediaId));
      }
    }
  }

  getDetails() {
    this.cashFlowService
      .getPreauthDetails(this.caseId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(JSON.stringify(data));
          
          this.details = this.convertDataToJSON(data);
          this.setInsuranceMediaId(this.details);
          console.log(JSON.stringify(this.details));


          
          const otpInputsArray = this.ngOtpInputs.toArray();
          otpInputsArray[0].setValue(
            this.details.hospital.name.toUpperCase() || " "
          );
          otpInputsArray[1].setValue(
            this.details?.hospital?.cityVillageTown.toUpperCase() || " "
          );
          otpInputsArray[2].setValue(
            this.details?.hospital?.hospitalOrgId.toUpperCase() || " "
          );
          otpInputsArray[3].setValue(
            this.details?.hospital?.emailId.toUpperCase() || " "
          );
          otpInputsArray[4].setValue(
            this.details?.hospital?.hospitalUniqueId || " "
          );

          /*otpInputsArray[5].setValue(
            this.details["Patient First Name"].toUpperCase() || ""
          );
          otpInputsArray[6].setValue(
            this.details["Patient Contact Number"] || ""
          );
          otpInputsArray[7].setValue(
            this.details["Contact Number of Attending Relative"] || ""
          );
          otpInputsArray[8].setValue(this.details["Date of birth"] || "");
          otpInputsArray[9].setValue(this.details["Policy Number"] || "");
          otpInputsArray[9].setValue(this.details["Employee ID"] || "");
          otpInputsArray[10].setValue(
            this.details["Primary Insurance Company Name"] || ""
          );
          otpInputsArray[11].setValue(
            this.details["Name of The Family Physician"] || ""
          );
          otpInputsArray[12].setValue(
            this.details["Contact Number of Physician"] || ""
          );
          otpInputsArray[13].setValue(
            this.details["Occupation of the Insured Person"] || ""
          );
          otpInputsArray[14].setValue(
            this.details["Name Of the Treating Doctor"] || ""
          );
          otpInputsArray[15].setValue(
            this.details["Contact Number Of the Treating Doctor"] || ""
          );
          otpInputsArray[16].setValue(this.details["ICD 10 Code"] || "");
          otpInputsArray[17].setValue(
            this.details["ICD 10 Code PCS Code"] || ""
          );*/
        },
        error: (error) => {
          console.error("Error", error);
        },
      });
  }

  convertDataToJSON(data: any): any {
    Object.keys(data).forEach((key) => {
      let value = data[key];

      if (typeof value === "string") {
        // Remove extra quotes and escape characters
        if (value.startsWith('"') && value.endsWith('"')) {
          value = value.replace(/^"|"$/g, "");
        } // Convert to number if possible
        if (!isNaN(Number(value))) {
          value = Number(value);
        }
      }

      this.jsonData[key] = value;
    });

    return this.jsonData;
  }

  getInsuranceCompany() {
    this.cashFlowService
      .getSocForInsurance(this.insuranceMediaId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.insurance = data;
          console.log("prod", data);
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No Insurance Companies available!";
          }
        },
      });
  }
}
