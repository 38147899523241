import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { CustomerService } from "src/app/services/customer.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { CashflowService } from "src/app/services/cashflow.service";

@Component({
  selector: "app-add-task-calculation",
  //standalone: true,
  //imports: [],
  templateUrl: "./add-task-calculation.component.html",
  styleUrl: "./add-task-calculation.component.scss",
})
export class AddTaskCalculationComponent {
  taskCalculationForm: FormGroup;
  form: FormGroup ;

  //orgId:any;
  userorg: any;
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  orgs: any;
  formPropertyModel:any;
  partnerTypes: any;
  calculatedExpression:any = '';
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;
  todayDate: Date = new Date();
  deployedprocessByProcessDefKey: any;
  selectedDeployeedProcessEntity: any;
  selectedDeployedSubEntityProcessByProcessDefKey: any;
  selectedattributes: any = [];
  selectedSubEntity: any =[];
  inputGroups: { value: string }[][] = [[]]; // Initialize with one empty group
  //inputs: { value: number }[] = [{ value: 0 }];
  totalSum: number = 0;
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private cashlessservice: CashflowService,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<AddTaskCalculationComponent>
  ) {
    this.form = this._fb.group({
      inputs: this._fb.array([])
    });
    this.taskCalculationForm = this._fb.group({
      // id: '',
      name: ["", Validators.required],
      calculationExpression: ["", [Validators.required]],
      processVersion: ["", [Validators.required]],
      processName: ["", [Validators.required]],
      taskName: "",
      inactive: "",
      attributeName: "",
    });
   
  }

  ngOnInit(): void {
    this.getDeployedProcesses();
  }
  // addInput() {
  //   this.inputs.push({ value: 0 });
  //   this.calculateResult(); // Recalculate when a new input is added
  // }

  // calculateResult() {
  //   this.totalSum = this.inputs.reduce((sum, input) => sum + (input.value || 0), 0);
  // }


  get inputs() {
    return this.form.get('inputs') as FormArray;
  }

  createInput(fieldName:any): FormGroup {
   // const newInput = new FormControl(0);
    return this._fb.group({
      value: new FormControl('')
     //value:newInput
    });
  }

  addInput(fieldName:any) {
    console.log(fieldName);
    
    this.inputs.push(this.createInput(fieldName));
    console.log(this.form.controls.inputs)
    //this.calculateResult(); // Recalculate when a new input is added
  }

  calculateResult() {
    let replacedExpression = this.calculatedExpression;
    const formValues = this.form.value.inputs.map((input: any) => input.value);
    console.log(formValues); // Output: [10, 20, 30]
    const variableNames = replacedExpression.match(/[a-zA-Z]+/g);
    variableNames.forEach((variable:any, index:any) => {
      replacedExpression = replacedExpression.replace(variable, formValues[index]);
    });
    console.log(replacedExpression)
    this.totalSum = eval(replacedExpression);
    console.log("fe",this.totalSum);

  }


  onInputChange() {
    console.log("dg")
   // this.calculateResult();
  }

  
  deployedAllProcesses: any;

  getDeployedProcesses() {
    this.cashlessservice
      .getDeployedProcesses()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.deployedAllProcesses = data;
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No products available for!";
          }
        },
      });
  }

  key: any;
  setProcess(event: any) {
    // console.log("Country selected:"+JSON.stringify(event.value));
    this.key = event.value;

    this.getDeployedprocessByProcessDefKey(this.key);
  }
  setSubProcess(event: any) {
    //this.taskName = event.value;
    console.log(event);
  }

  
  selectedtask(data:any)
  {
console.log("vv",data);
this.selectedSubEntity = data;
  }
  appendAttributes(event:any){
   
    const newValue = event;
    this.addInput(newValue[newValue.length -1]);
    console.log(newValue[newValue.length -1]);
    this.calculatedExpression =   this.calculatedExpression + newValue[newValue.length -1];
   
  }
  getDeployedprocessByProcessDefKey(key: any) {
    this.adminService
      .getDeployedprocessByProcessDefKey(key)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.deployedprocessByProcessDefKey = data;
          console.log("fsff", this.deployedprocessByProcessDefKey);
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //this.loading = false;
        },
      });
    // console.log(this.states);
    // this.adminService.getStates(countryId).subscribe({
    //   next:(data:any)=>{
    //     this.states =  data.states;
    //   }
    // })
  }

  onFormSubmit() {
    var data = {
      name: this.taskCalculationForm.value.name,
      calculationExpression:this.taskCalculationForm.value.calculationExpression,
      processVersion: this.taskCalculationForm.value.processVersion,
      attributeName: this.taskCalculationForm.value.attributeName.join(", "),
      processName: this.taskCalculationForm.value.processName,
      taskName: this.taskCalculationForm.value.taskName,
      // "inactive": this.taskCalculationForm.value.inactive
      inactive: true,
    };

    this.adminService.addTaskCalculation(data).subscribe({
      next: (val: any) => {
        this.toastrService.success(
          "Task calculation has been added successfully"
        );
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));
      },
    });
  }
}
