<div mat-dialog-title>
  <div class="row bg-light">
    <div class="col-9 px-5 p-3 d-flex justify-content-start">
      <h1>View File</h1>
    </div>
    <div class="col p-2 d-flex justify-content-end">
      <div class="p-2 example-button-container" mat-dialog-close>
        <button style="color: white; background: #8e0101" mat-mini-fab>
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container text-center">
      <div style="width: 100%; max-height: 50%">
        <ngx-extended-pdf-viewer
          *ngIf="pdfUrl != undefined"
          [src]="pdfUrl"
          [textLayer]="true"
          [showHandToolButton]="true"
        >
        </ngx-extended-pdf-viewer>
        <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
