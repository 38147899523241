import { Component } from '@angular/core';

@Component({
  selector: 'app-create-abha',
  templateUrl: './create-abha.component.html',
  styleUrls: ['./create-abha.component.scss']
})
export class CreateAbhaComponent {

}
