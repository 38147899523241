<div class="container">
  <mat-tab-group #tabGroup>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">dashboard</mat-icon>
        Dashboard
      </ng-template>
      <app-workflow-dashboard [dashboardParam]="getDashboardParam()"></app-workflow-dashboard>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">inbox</mat-icon>
        Inbox - All tasks
      </ng-template>
      <app-onboarding-inbox></app-onboarding-inbox>
    </mat-tab>
    <!--<mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">assignment</mat-icon>
        Assignments (Pending Tasks)
      </ng-template>
      <app-assignment></app-assignment>
    </mat-tab>-->
  </mat-tab-group>
</div>