import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.scss']
})
export class EditCouponComponent {

  couponForm: FormGroup;
  orgId:any;
  user:any;
  status: any;

  
  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<EditCouponComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    this.couponForm = this._fb.group({
      id:'',
      productId:['',Validators.required],
      discountCode:['',Validators.required],
      discountPercent:['', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      discountNote: ['',Validators.required],
      startDate:['',Validators.required],
      endDate:['',Validators.required],
      orgId:['',Validators.required],
     // orgId:'',
     maxCount:['', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
     partnerTypeId:['',Validators.required],
     inactive:['',Validators.required],


    });
  }


  // "id": "string",
  // "productId": "string",
  // "discountCode": "string",
  // "discountPercent": 0,
  // "discountNote": "string",
  // "startDate": "2023-11-01T05:05:36.709Z",
  // "endDate": "2023-11-01T05:05:36.709Z",
  // "maxCount": 0,
  // "partnerTypeId": 0,
  // "orgId": "string",
  // "dateCreated": "2023-11-01T05:05:36.709Z",
  // "createdBy": "string",
  // "inactive": true,
  // "currentCount": 0
  ngOnInit(): void {

  //  var user = localStorage.getItem("user")
  //   this.user=  user;
  //   this.user = this.orgId;
  //   console.log("orgid",this.user);
  this.status = this.data.inactive ? "Inactive" : "Activate";
  this.couponForm.controls['inactive'].setValue(this.status);
    this.couponForm.patchValue(this.data);
    // var data = this.user.orgId;
    //console.log(this.data)
   }

  onFormSubmit() {
    if (this.couponForm.valid) {
      if (this.data) {
        this.adminService
          .updateDiscountCoupon( this.couponForm.value)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("Coupon has been updated successfully");


              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error));


            },
          });
      } 
      }
    }
  
}