<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Edit Technical Configuration for {{ data.name }}</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="card">
    <div class="card-body">
      <form class="overflow-card" [formGroup]="form">
        <div class="container row">
          <div class="">
            <label for="insuranceName">Selected Insurance Company </label>
            <input
              readonly
              type="text"
              class="form-control"
              id="insuranceName"
              formControlName="insuranceName"
            />
          </div>
          <div class="">
            <label for="blacklisted">Select Blacklisted Type </label>
            <select
              (change)="onChangeInsuranceCompanyblacklisted($event.target)"
              class="form-select my-2"
              formControlName="blacklisted"
              id="blacklisted"
            >
              <option value="true">Yes</option>
              <option selected value="false">No</option>
            </select>
          </div>
          <div class="">
            <label for="claimSubmissionMethod"
              >Select Claim Submission Method Type
            </label>
            <select
              (change)="onChangeInsuranceCompanyclaims($event.target)"
              class="form-select my-2"
              formControlName="claimSubmissionMethod"
              id="claimSubmissionMethod"
            >
              <option value="EMAIL">EMAIL</option>
              <option value="PORTAL">PORTAL</option>
              <option value="INTEGRATION">INTEGRATION</option>
            </select>
          </div>
          <div class="row pt-2">
            <label class="normal-font">Valid From </label>
            <input class="form-control" formControlName="validFrom" type="date"  [(ngModel)]="validFrom"/>
          </div>

          <div class="row pt-2">
            <label class="normal-font">Valid To</label>
            <input class="form-control"formControlName="validTo" type="date" [(ngModel)]="validTo"/>
          </div>
          <div>
            <label for="upload">Upload SOC</label>
            <div class="col">
              <label class="btn btn-default p-0">
                <input
                  class="form-control my-2"
                  accept="application/pdf"
                  type="file"
                  formControlName="upload"
                  (change)="selectFile($event)"
                  id="upload"
                />
              </label>
              <ul class="list-group list-group-flush">
                <li
                  *ngFor="let file of fileInfos | async"
                  class="list-group-item"
                >
                  <a href="{{ file.url }}">{{ file.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="">
            <label for="configdata">Config Data </label>
            <textarea
              formControlName="configdata"
              class="form-control"
              id="configdata"
              rows="3"
            ></textarea>
          </div>
          <div mat-dialog-actions class="action">
            <button mat-raised-button type="button" [mat-dialog-close]="false">
              Cancel
            </button>
            <button
              mat-flat-button
              style="color: white; background: #8e0101"
              type="button"
              [disabled]="selectedFiles == undefined || clicked"
              (click)="uploadFiles(); clicked = true"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
