<div class="container p-3">
    <div class="container py-3 px-2">
      <div class="addbtn p-3">
        <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
          Add 
        </button>
      </div>
    
      <div class="row justify-content-md-center">
       
        <div class="col-lg-9 ms-3">
         
          <mat-form-field appearance="outline">
            <mat-label>Process Name</mat-label>
            <mat-select
              (selectionChange)="setProcess($event)"
              [(ngModel)]="selectedDeployeedProcessEntity"
              name="Entity"
            >
              <mat-option *ngFor="let process of deployedAllProcesses" [value]="process.key">
                {{ process.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
              <mat-label>Task Name</mat-label>
              <!-- <input matInput type="text" placeholder="state" formControlName="state"> -->
              <mat-select
                [(ngModel)]="selectedDeployedSubEntityProcessByProcessDefKey"
                (selectionChange)="setSubProcess($event)"

  
                name="Sub-Entity" >
                <mat-option *ngFor="let process of deployedprocessByProcessDefKey" [value]="process.taskId">
                  {{ process.taskName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select  name="inactiveData"
              (selectionChange)="setStatus($event)"

              >
                <mat-option value="true"> Inactive </mat-option>
                <mat-option value="false"> Activate </mat-option>
              </mat-select>
            </mat-form-field> 
              </div>
              <!-- <div class="col-lg-2 mt-2">
                <div class="example-flex-container">
                  <button mat-flat-button style="color:white;background:#8e0101" (click)="search()">
                    Search
                  </button>
                   <button
                  mat-flat-button style="color:white;background:#8e0101"
                    (click)="clear()">
                    Clear
                  </button> 
                </div>
                
              </div> -->
        </div>
      </div>
    </div>
    <div class="container py-3 px-2">
      <div class="card px-3 py-4 justify-content-md-center">
        <div class="p-4">
          <mat-table class="" #table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell
                *matCellDef="let element"
              >

                {{ element.name}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="calculationExpression">
              <mat-header-cell *matHeaderCellDef> Calculation Expression </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.calculationExpression}}
               </mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="processVersion">
              <mat-header-cell *matHeaderCellDef> Process Version </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id.processVersion}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="attributeName">
              <mat-header-cell *matHeaderCellDef> Attribute Name </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id.attributeName}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="processName">
              <mat-header-cell *matHeaderCellDef> Process Name </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id.processName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="taskName">
              <mat-header-cell *matHeaderCellDef> Task Name </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id.taskName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button style="color:white;background:#8e0101" mat-flat-button (click)="openEditForm(element)">Edit</button>
              </mat-cell>
              
              
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>
      </div>
    </div> 
    
  