<div mat-dialog-title>
    <h1>Add Product</h1>
</div>
<form [formGroup]="prductForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id" readonly >
            </mat-form-field>
        </div> -->
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Product Name</mat-label>
                <input matInput type="text" placeholder="Product Name" formControlName="name" >
            </mat-form-field>
            <mat-form-field  appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select formControlName="category" [(ngModel)]="selectedCategory" name="category">
                  <mat-option *ngFor="let category of categories" [value]="category.id">
                      {{category.name}} 
                  </mat-option>
                </mat-select>
              </mat-form-field>
            <!-- <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <input matInput placeholder="Type" formControlName="type">
            </mat-form-field> -->
            <mat-form-field  appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type" [(ngModel)]="selectedType" name="type">
                    <mat-option value="simple">Simple</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput type="text" placeholder="Description" formControlName="description" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Short Description</mat-label>
                <input matInput type="text" placeholder="Short Description" formControlName="short_description" >
            </mat-form-field>
        </div>
        
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Regular Price</mat-label>
                <input matInput type="text" placeholder="Regular Price" formControlName="regular_price" >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Sale Price</mat-label>
                <input matInput type="text" placeholder="Sale Price" formControlName="sale_price" >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Stock Quantity</mat-label>
                <input matInput type="text" placeholder="Stock Quantity" formControlName="stock_quantity" >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>SKU Id</mat-label>
                <input matInput type="text" placeholder="SKU Id" formControlName="skuId">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Product Image</mat-label>
                <input matInput type="text" placeholder="Product Image" formControlName="images" multiple>
            </mat-form-field>
        </div>
       
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{'Save'}}</button>
    </div>
</form>