<div class="container">
    <div mat-dialog-title>
        <div class="row">
            <div class="col-8 d-flex justify-content-start">
                <h1>Policy Consulting </h1>
            </div>
            <div class="col-4 d-flex justify-content-end " mat-dialog-close>
                <h4><i class="px-3" style="color:lightgray">* marked fields are mandatory </i></h4>
                <mat-icon color="accent">cancel</mat-icon>
            </div>
        </div>
    </div>

    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">widgets</mat-icon>
                Workflow
            </ng-template>
            <div class="" *ngIf="diagram != undefined">
                <img [src]="diagram" />
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">list</mat-icon>
                Task Details
            </ng-template>

            <div class="card-body">
                <ul>
                    <li>
                        <b>SOP:</b>
                        <div [innerHTML]="data.description"></div>
                    </li>
                    <li *ngIf="data.dueDate != null">
                        <b>TAT:</b>
                        <span style="color: red">
                            {{ data.dueDate | date : "long" }}</span>
                    </li>
                    <li style="color: #c00000" *ngIf="data.caseVariables.policy == null">
                        <b>Note:</b> The policy hasn't been uploaded!
                    </li>
                    <li style="color: #c00000" *ngIf="
                    data.caseVariables.order.memberName == ''
                ">
                        <b>Note:</b> The member name has not been provided!
                    </li>
                </ul>
                <hr />
                <div>
                    <ul>
                        <li>
                            <b>CUSTOMER:</b>
                            {{ data.caseVariables.customer.firstName }}
                            {{ data.caseVariables.customer.lasttName }}
                        </li>
                        <li>
                            <b>Email:</b>
                            {{ data.caseVariables.customer.emailId }}
                        </li>
                        <li>
                            <b>Mobile:</b>
                            {{
                            data.caseVariables.customer.mobilePhone
                            }}
                        </li>
                    </ul>

                    <ul class="collapse" id="collapse-Example">
                        <li>
                            <b>ORDER:</b>
                            {{ data.caseVariables.order.id }}
                        </li>
                        <li>
                            <b>ORDER Date:</b>
                            {{
                            data.caseVariables.order.transactionDate
                            | date
                            }}
                        </li>
                        <li>
                            <b>Product:</b>
                            {{ data.caseVariables.product.name }}
                        </li>
                        <li>
                            <b>Payment Status:</b>
                            {{
                            data.caseVariables.order.transactionStatus
                            | json
                            }}
                        </li>
                        <li *ngIf="
                        data.caseVariables.order.memberName != ''
                  ">
                            <b>Member Name:</b>
                            {{ data.caseVariables.order.memberName }}
                        </li>
                    </ul>

                    <hr />
                    <ul *ngIf="data.caseVariables.policy != null">
                        <li>
                            <b>POLICY:</b>
                            {{ data.caseVariables.policy.policyNumber }}
                        </li>
                        <li>
                            <b>Policy Link:</b>
                            {{ data.caseVariables.policy.policyPath }}
                        </li>
                        <li>
                            <b>eKYP:</b>

                            <div class="row">
                                <div class="col">
                                    Insurance Company :
                                    {{
                                    data.caseVariables.policy.ekypData
                                    .insurance_company
                                    }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Insurance Product :
                                    {{
                                    data.caseVariables.policy.ekypData
                                    .insurance_name
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    Policy Start :
                                    {{
                                    data.caseVariables.policy.ekypData
                                    .start_date
                                    }}
                                </div>
                                <div class="col">
                                    Policy End :
                                    {{
                                    data.caseVariables.policy.ekypData
                                    .end_date
                                    }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Policy Type :
                                    {{
                                    data.caseVariables.policy.ekypData
                                    .policy_type
                                    }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Ported Policy :
                                    {{
                                    data.caseVariables.policy.ekypData
                                    .portedPolicy
                                    }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Sum Insured :
                                    {{
                                    data.caseVariables.policy.sumInsured
                                    }}
                                </div>
                            </div>
                            <hr />
                        </li>
                    </ul>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <!-- vs -->
    <div class="row">
        <div class="col">
            <div *ngIf="data != undefined">
                <!-- <mat-accordion class="p-6 m-4" *ngIf="diagram != undefined">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <strong>Case Diagram</strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="card-body">
                            <img [src]="diagram" style="max-width: 70%; height: auto;" />
                        </div>
                    </mat-expansion-panel>
                </mat-accordion> -->
                <!-- <div class="card" *ngIf="diagram != undefined">
                    <div class="card-header">
                        <span>Case Diagram</span>
                    </div>
                    <div class="card-body">
                        
                    </div>
                    
                </div> -->
                <!-- <mat-accordion class="p-6 m-4">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <strong>Task details</strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                         <div class="card-body">
                            <ul>
                                <li>
                                    <b>SOP:</b>
                                    <div [innerHTML]="data.description"></div>
                                </li>
                                <li *ngIf="data.dueDate != null">
                                    <b>TAT:</b>
                                    <span style="color: red">
                                        {{ data.dueDate | date : "long" }}</span>
                                </li>
                                <li style="color: #c00000"
                                    *ngIf="data.caseVariables.policy == null">
                                    <b>Note:</b> The policy hasn't been uploaded!
                                </li>
                                <li style="color: #c00000" *ngIf="
                                data.caseVariables.order.memberName == ''
                            ">
                                    <b>Note:</b> The member name has not been provided!
                                </li>
                            </ul>
                            <hr />
                            <div>
                                <ul>
                                    <li>
                                        <b>CUSTOMER:</b>
                                        {{ data.caseVariables.customer.firstName }}
                                        {{ data.caseVariables.customer.lasttName }}
                                    </li>
                                    <li>
                                        <b>Email:</b>
                                        {{ data.caseVariables.customer.emailId }}
                                    </li>
                                    <li>
                                        <b>Mobile:</b>
                                        {{
                                            data.caseVariables.customer.mobilePhone
                                        }}
                                    </li>
                                </ul>

                                <ul class="collapse" id="collapse-Example">
                                    <li>
                                        <b>ORDER:</b>
                                        {{ data.caseVariables.order.id }}
                                    </li>
                                    <li>
                                        <b>ORDER Date:</b>
                                        {{
                                            data.caseVariables.order.transactionDate
                                        | date
                                        }}
                                    </li>
                                    <li>
                                        <b>Product:</b>
                                        {{ data.caseVariables.product.name }}
                                    </li>
                                    <li>
                                        <b>Payment Status:</b>
                                        {{
                                            data.caseVariables.order.transactionStatus
                                        | json
                                        }}
                                    </li>
                                    <li *ngIf="
                                    data.caseVariables.order.memberName != ''
                              ">
                                        <b>Member Name:</b>
                                        {{ data.caseVariables.order.memberName }}
                                    </li>
                                </ul>

                                <hr />
                                <ul *ngIf="data.caseVariables.policy != null">
                                    <li>
                                        <b>POLICY:</b>
                                        {{ data.caseVariables.policy.policyNumber }}
                                    </li>
                                    <li>
                                        <b>Policy Link:</b>
                                        {{ data.caseVariables.policy.policyPath }}
                                    </li>
                                    <li>
                                        <b>eKYP:</b>
                                       
                                        <div class="row">
                                            <div class="col">
                                                Insurance Company :
                                                {{
                                                    data.caseVariables.policy.ekypData
                                                .insurance_company
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                Insurance Product :
                                                {{
                                                    data.caseVariables.policy.ekypData
                                                .insurance_name
                                                }}
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                Policy Start :
                                                {{
                                                    data.caseVariables.policy.ekypData
                                                .start_date
                                                }}
                                            </div>
                                            <div class="col">
                                                Policy End :
                                                {{
                                                    data.caseVariables.policy.ekypData
                                                .end_date
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                Policy Type :
                                                {{
                                                    data.caseVariables.policy.ekypData
                                                .policy_type
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                Ported Policy :
                                                {{
                                                    data.caseVariables.policy.ekypData
                                                .portedPolicy
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                Sum Insured :
                                                {{
                                                    data.caseVariables.policy.sumInsured
                                                }}
                                            </div>
                                        </div>
                                        <hr />
                                    </li>
                                </ul>
                            </div>
                        </div> 
                    </mat-expansion-panel>
                </mat-accordion> -->

                <div class="card p-2 m-2">
                    <form [formGroup]="dynamicFormGroup" *ngIf="dynamicFormGroup != undefined">
                        <div *ngFor="let field of fields">
                            <div class="row m-2 p-2" *ngIf="field.property.type.name == 'boolean'">
                                <div class="col">
                                    <b>{{ field.property.name }}</b>
                                </div>
                                <div class="col">
                                    <select [formControlName]="field.property.id" class="form-select"
                                        aria-label="Default select example" required="true" [value]="false">
                                        <option value="true">Accept</option>
                                        <option value="false">Reject</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row m-2 p-2" *ngIf="
                            field.property.type.name == 'string' &&
                            field.property.id != 'fileUpload'
                          ">
                                <div class="col">
                                    <b>{{ field.property.name }}</b>
                                </div>
                                <div class="col">
                                    <textarea class="form-control" required="true"
                                        [formControlName]="field.property.id"></textarea>
                                </div>
                            </div>

                            <div class="row m-2 p-2" *ngIf="
                            field.property.type.name == 'string' &&
                            field.property.id == 'fileUpload'
                          ">
                                <div class="col">
                                    <b>{{ field.property.name }}</b>
                                </div>

                            </div>
                            <div class="row m-2 p-2" *ngIf="field.property.type.name == 'date'">
                                <div class="col">
                                    <b>{{ field.property.name }}</b>
                                </div>
                                <div class="col">
                                    <input type="date" class="form-control" required="true"
                                        [formControlName]="field.property.id" />
                                </div>
                            </div>

                            <div class="row m-2 p-2" *ngIf="field.property.type.name == 'enum'">
                                <div class="col">
                                    <b>{{ field.property.name }}</b>
                                </div>
                                <div class="col">
                                    <select [formControlName]="field.property.id" class="form-select"
                                        aria-label="Default select example" required="true">
                                        <option value="" selected disabled>
                                            Update status
                                        </option>
                                        <option *ngFor="let option of field.values" [value]="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="text-end">
                            <button type="button" class="btn btn-danger" [disabled]="
                            dynamicFormGroup != undefined &&
                            dynamicFormGroup.invalid
                          " (click)="executeTask()">
                                Execute
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="p-3">
        <mat-dialog-actions align="end">
            <button color="accent" mat-button mat-dialog-close>Cancel</button>

        </mat-dialog-actions>
    </div>
</div>