<!-- <div mat-dialog-title>
    <h1>Update Coupon</h1>
</div> -->
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Update hospital</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>

<form [formGroup]="userhospitalform" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="hospitalOrgId" readonly >
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput type="text" placeholder="Product id" formControlName="name" >
            </mat-form-field>
            <!-- <mat-form-field appearance="outline">
                <mat-label>Discount code</mat-label>
                <input matInput placeholder="Discount code" formControlName="discountCode">
            </mat-form-field>
    
        
            <mat-form-field appearance="outline">
                <mat-label>Discount percent</mat-label>
                <input matInput type="text" placeholder="Discount percent" formControlName="discountPercent" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Discount note</mat-label>
                <input matInput type="email" placeholder="Discount note" formControlName="discountNote" >
            </mat-form-field>
        
        
        
            <mat-form-field appearance="outline">
                <mat-label>Start date</mat-label>
                <input matInput type="text" placeholder="Start date" formControlName="startDate" readonly>
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>End date</mat-label>
                <input matInput type="text" placeholder="End date" formControlName="endDate" readonly>
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>Org id</mat-label>
                <input matInput type="text" placeholder="Org id" formControlName="orgId" readonly>
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>Max count</mat-label>
                <input matInput type="text" placeholder="Max count" formControlName="maxCount" >
            </mat-form-field>
        
        
            <mat-form-field appearance="outline">
                <mat-label>Partner type id</mat-label>
                <input matInput type="text" placeholder="Partner type id" formControlName="partnerTypeId"  readonly>
            </mat-form-field>
         -->
        
            <!-- <mat-form-field appearance="outline">
                <mat-label>Inactive</mat-label>
                <input matInput type="text" placeholder=" Active Status" formControlName="inactive">
            </mat-form-field> -->
            
      <!-- <mat-form-field appearance="outline">
        <mat-label>{{ status }}</mat-label>
        <mat-select formControlName="inactive" name="inactiveData">
          <mat-option value="true"> Inactive </mat-option>
          <mat-option value="false"> Activate </mat-option>
        </mat-select>
      </mat-form-field> -->
        </div>
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
    </div>
</form>