<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1>Add Zone</h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
            <h4>
                <i class="px-3" style="color: lightgray">* marked fields are mandatory
                </i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div>
<form [formGroup]="zoneForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <!-- <input matInput type="text" placeholder="category" > -->
                <mat-select placeholder="category" formControlName="category">
                    <mat-option value="EMAIL">EMAIL</mat-option>
                    <mat-option value="PORTAL">PORTAL</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>InsuranceCompanyId</mat-label>
                <!-- <input matInput type="text" placeholder="insuranceCompanyId" formControlName="insuranceCompanyId"> -->
                <mat-select placeholder="insuranceCompanyId" formControlName="insuranceCompanyId">
                    <mat-option *ngFor="let company of insuranceAndTpaList"
                        [value]="company.id">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Zone</mat-label>
                <!-- <input matInput type="text" placeholder="zone" formControlName="zone"> -->
                <mat-select placeholder="zone" formControlName="zone">
                    <mat-option value="NORTH">NORTH</mat-option>
                    <mat-option value="SOUTH">SOUTH</mat-option>
                    <mat-option value="EAST">EAST</mat-option>
                    <mat-option value="WEST">WEST</mat-option>

                </mat-select>

            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>InsuranceEmailId</mat-label>
                <input
                matInput
                placeholder="Insurance Email ID"
                [matChipInputFor]="tagList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addEmailId($event)"
              />
                <mat-chip-grid #tagList>
                    <mat-chip *ngFor="let tag of emailTags" [removable]="removable" (removed)="removeEmailId(tag)">
                      {{ tag }}
                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-grid>
                 
                  
                <!-- <mat-chip-list #tagList>
                    <mat-chip selected color="primary" *ngFor="let Tag of emailTags" [removable]="removable" 
                        (removed)="removeEmailId(Tag)">
                        {{Tag}}
                        <mat-icon matChipRemove *ngIf="removable">cancel
                        </mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <input matInput type="text" placeholder="insuranceEmailId" formControlName="insuranceEmailId"
                    [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addEmailId($event)"> -->

            </mat-form-field>

        </div>

        <div mat-dialog-actions class="action">
            <button mat-raised-button type="button" [mat-dialog-close]="false">
                Cancel
            </button>
            <button mat-raised-button color="primary" type="submit">
                Save
            </button>
        </div>
    </div>
</form>