<section class="container">
  <div class="p-3 text-center">
    <div class="addbtn">
      <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
        Add
      </button>
    </div>
  </div>
  <mat-table class="" [dataSource]="tpatables">
    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef>Image</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.logo != null; else other_content">
          <img style="width: 20vh; height: 20vh" [src]="apiUrl + '/externalMedia/' + element.logo" />
        </div>
        <ng-template #other_content>
          <p>No logo for this tpa</p>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tpaName">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.tpaName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.address }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.city }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.state }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="pincode">
      <mat-header-cell *matHeaderCellDef>Pin Code</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.pincode }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="emailId">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.emailId }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="contactNos">
      <mat-header-cell *matHeaderCellDef>Number</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.contactNos }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element" [style.color]="element.inactive == true ? 'gray' : null">
        {{ element.inactive ? "In Active" : "Active" }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Update</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button style="color:#8e0101;" (click)="openEditForm(element)" matTooltip="Edit Form"
          matTooltipPosition="below">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="delete">
      <mat-header-cell  style="background:rgb(241, 241, 241);" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button style="color:#8e0101;"(click)="deleteProduct(element)"
        matTooltip="Edit Form"
        matTooltipPosition="below">
        <mat-icon>check_circle_outline</mat-icon>
      </button>
      </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="delete">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element"> <button [disabled]="element.inactive == true" mat-icon-button
          [style.color]="element.inactive == true ? 'gray' : '#8e0101'" (click)="deleteTpa(element)" matTooltip="Delete"
          matTooltipPosition="below"> <mat-icon>delete</mat-icon> </button> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="upload">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Logo upload</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button style="color:#8e0101;" (click)="updateLogo(element)" matTooltip="Edit Form"
          matTooltipPosition="below">
          <mat-icon>upload</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="emailParserConfs">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Mail parser</mat-header-cell>
      <mat-cell *matCellDef="let element">

        <button mat-icon-button style="color:#8e0101;" (click)="ViewMailParser(element)" matTooltip="Inactive"
          matTooltipPosition="below">
          <mat-icon>mail_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div class="demo-options p-4">
    <div class="demo-toggles">
      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
  <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
    {{ message }}
  </div>
</section>