import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ToastrService } from "ngx-toastr";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";

@Component({
  selector: "app-tech",
  templateUrl: "./tech.component.html",
  styleUrls: ["./tech.component.scss"],
})
export class TechComponent {
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: PageEvent = new PageEvent();

  message: any;
  insuranceCompanies: any;
  form: FormGroup = this.formBuilder.group({});
  formData: FormData = new FormData();
  claims: any;
  insuranceCompanyId: any;
  claimSubmissionMethod: any;

  constructor(
    private HospitalRegistrationService: HospitalRegistrationService,
    private formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<TechComponent>,
    private toastrService: ToastrService,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      selectInsuranceCompanies: ["", Validators.required],
      claimSubmissionMethod: ["", Validators.required],
      configdata: ["", Validators.required],
      // upload: [null, Validators.required],
    });
  }
  ngOnInit(): void {
    this.getHospitalInsurance();
  }
  getHospitalInsurance() {
    this.HospitalRegistrationService
      .gethospitalInsurance(
        this.data.id,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .subscribe({
        next: (data) => {
          this.insuranceCompanies = data;
          console.log("Hi", this.insuranceCompanies);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;
        },
      });
  }

  onChangeInsuranceCompanyId(arg0: any) {
    if (arg0) {
      this.insuranceCompanyId = JSON.parse(arg0.value);
      this.claimSubmissionMethod =
        this.insuranceCompanyId.claimSubmissionMethod;
      this.form.controls["claimSubmissionMethod"].setValue(
        this.claimSubmissionMethod
      );
      console.log("insuranceCompanyId", JSON.parse(arg0.value));
    }
  }

  uploadFiles() {
 
    this.formData.append("claimSubmissionMethod",  this.insuranceCompanyId.claimSubmissionMethod);
    this.formData.append("hospitalId", this.insuranceCompanyId.hospitalOrgId);
    this.formData.append("insuranceCompanyId", this.insuranceCompanyId.insuranceId);
    this.formData.append(
      "claimSubmissionMethod",
      this.insuranceCompanyId.claimSubmissionMethod
    );
    if (this.insuranceCompanyId.claimSubmissionMethod === "EMAIL") {
      console.log("Claim Submission Method:", this.form.value.configdata);
      this.formData.append("emailConf", this.form.value.configdata);
    } else if (this.insuranceCompanyId.claimSubmissionMethod === "PORTAL") {
      console.log("Claim Submission Method:", this.form.value.configdata);
      this.formData.append("portalConf", this.form.value.configdata);
    } else if (this.insuranceCompanyId.claimSubmissionMethod === "INTEGRATION") {
      console.log("Claim Submission Method:", this.form.value.configdata);
      this.formData.append("integrationConf", this.form.value.configdata);
    }else {
      this.formData.append("emailConf", this.form.value.configdata);
    }
    // console.log("formData " + JSON.stringify(this.formData.getAll));
    this.hospitalInsurance();
  }

  hospitalInsurance() {
    this.HospitalRegistrationService
      .patchHospitalInsuranceTech(
        this.formData,
        this.insuranceCompanyId.hospitalOrgId
      )
      .subscribe({
        next: (data: any) => {
          this.toastrService.success("File has been uploaded successfully");
          this._dialogRef.close(true);
        },
        error: (error) => {
          // console.log("error " + JSON.stringify(error));
          this.message = error;
          this.toastrService.error(JSON.stringify(error));
          this._dialogRef.close(true);
        },
      });
  }
}
