import { Component, HostListener, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { first } from "rxjs";
import { Order } from "src/app/model/order";
import { LocalPaymentResponse } from "src/app/model/paymentResponse";
import { Policy } from "src/app/model/policy";
import { PolicyMember } from "src/app/model/policyMember";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";
import { environment } from "src/environments/environment.prod";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ServiceLevelAggrementComponent } from "../service-level-aggrement/service-level-aggrement.component";


declare var Razorpay: any;

@Component({
  selector: "app-claims-now",
  templateUrl: "./claims-now.component.html",
  styleUrls: ["./claims-now.component.scss"],
})
export class ClaimsNowComponent {
  pdf =   '../../../../../assets/pdfs/Updated Customer-BG SLA (1).pdf';
  tcValue:boolean = false;
  @ViewChild("stepper")
  stepper!: MatStepper | undefined;;

  firstFormGroup = this._formBuilder.group({
    policyNumber: ["", Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    memberName: ["", Validators.required],
  });
  isEditable = true;
  updateOrderdData:any;

  get p() {
    return this.firstFormGroup.controls;
  }
  showStepper = false;
  Step1 = false;
  Step2 = false;
  Step3 = false;
  Step4 = false;
  payurlbtn = false;
  initiateClaimBtn = true;
  clickCount: number = 0;

  selectedProduct: any;
  selectedPolicy?: Policy;
  form: any = {};
  paymentId: any;
  error: any;
  productsForm = new FormControl("");
  user: any;
  couponCode: any;
  appliedCoupon: any;
  availableDiscount: any;
  Istc:boolean = false;
  main: Boolean = true;
  //discountPercent:any;
  //couponId:any;
  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog,
    private toastrService:ToastrService,

  ) {
    //this.getProducts();
  }
  ngOnInit() {
    this.user = this.authService.userValue;
    //console.log("User " + this.user);
    this.getProducts();
    this.getPolicies();
  }

  options = {
    entity: "order",
    key: "",
    amount: "",
    name: "BimaGarage",
    description: "Claims Assistance",
    image: "../../../../../assets/img/logo.png",
    receipt: "",
    handler: function (response: any) {
      var event = new CustomEvent("payment.success", {
        detail: response,
        bubbles: true,
        cancelable: true,
      });
      window.dispatchEvent(event);
    },
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      productId: "",
      productName: "",
    },
    theme: {
      color: "#C00000",
    },
  };

  products: any;

  getProducts() {
    this.customerService
      .getProducts()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.products = data.filter((x: any) => x.inactive == false);
        },
        error: (err: any) => {
          console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No products available for!";
          }
        },
      });
  }

  payurl: any;
  order: Order = new Order();
  orderId: any;
  initiateClaim(): void {
    this.paymentId = "";
    this.error = "";
    this.order.memberName = this.secondFormGroup.get("memberName")?.value || '';
    this.order.policyNumber = this.firstFormGroup.get("policyNumber")?.value ||'';
    //this.order.amount = this.appliedCoupon.discount;
    if(this.order.amount && !this.couponCode){
      this.order.amount = this.order.amount + (0.18 * this.order.amount); 
      console.log("gg",this.order.amount);
    }
    console.log('amount with gst =' + this.order.amount);
    this.order.processStatus = 'IN-PROGRESS';
  
    this.customerService
      .createOrder(this.order)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.initiateClaimBtn = false;
          this.orderId = data.razorpayOrderId;
          this.options.amount = data.applicationFee; //paise
        
          if (data.applicationFee == 0) {
            this.updateOrder(data.razorpayOrderId,"NA");
            this.showStepper = false;
           // this.stepper.reset();


          }
          else {
            this.customerService
              .getPhonepePaymentUrl(this.orderId, Math.round(this.order.amount ?? 0)*100)
              .pipe(first())
              .subscribe({
                next: (data: any) => {
                  console.log(JSON.stringify(data));

                  this.payurl = data.url;
                  
                  this.payurlbtn = true;


                },
              });
          }
        },

        error: (err: any) => {
          console.log(err);
          this.initiateClaimBtn = true;
          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "Couldn't create the order - try again!";
          }
        },
      });
  }



  updateOrder(orderId:any,status:any) {
    this.customerService.updateOrder(orderId, status).subscribe({
      next: (data: any) => {
        this.updateOrderdData=data;
        this.toastrService.success("order has been created ");
      }, error: (err: any) => {
        console.log(err);
        if (err.error && err.error.error) {
          this.error = err.error.error;
        } else {
          this.error = 'Could not update the order!';
        }

      }
    })
  }
  resetstepper() {
    if(this.stepper)
    {
      //this.stepper.reset();
      this.payurlbtn = false;
      this.initiateClaimBtn = true;
    }
  
  }
  selectProduct(product: any) {
   // debugger
    if (this.clickCount == 0) {
      this.clickCount++;
    } else {
      if(this.stepper)
        {
          //this.stepper.reset();
        }
    }
     if(product.id == 3  && product.tcRequired == true) {
        const dialogRef = this._dialog.open(ServiceLevelAggrementComponent,{disableClose:true,});
        dialogRef.afterClosed().subscribe({
          next: (val) => {
            if (val) {
              this.tcValue = val;
              console.log("Agreement true here");
              this.showStepper = true;
              this.Step1 = true;
              this.Step2 = true;
              this.Step3 = true;
              this.Step4 = false;
              this.payurlbtn = false;
            }
          },
        });
      
      //this.warningICAR();
    }
    else if (product.id == 1 || product.id == 2 || product.id == 3) {
      this.showStepper = true;
      this.Step1 = true;
      this.Step2 = true;
      this.Step3 = true;
      this.Step4 = false;
      this.payurlbtn = false;
    }
     else if (product.id == 4) {
      this.showStepper = true;
      this.Step1 = false;
      this.Step2 = false;
      this.Step3 = false;
      this.Step4 = true;
      this.payurlbtn = false;
    } else {
      this.showStepper = false;
      this.Step1 = false;
      this.Step2 = false;
      this.Step3 = false;
      this.Step4 = false;
    }
    //product.price = "1999"
    this.getAvailableDiscounts(product.id);
    this.selectedProduct = product;
    this.order.amount = product.price;
   
    this.order.productId = product.id;
    if(product.price == 0)
    {
      this.order.transactionStatus = "NA";

    }
    else{
      this.order.transactionStatus = "INITIATED";

    }
    //this.order.transactionStatus = "INITIATED";
    this.order.userId = this.user.id;
    this.order.orderSource = "WEB";
    this.order.agreementAccepted = this.tcValue;
    //this.order.couponId=  this.couponId
    //this.order.discountPercent=this.discountPercent;

    // if (product.id == 4 && product.price != 0) {
    //this.initiateClaim();
    //}
    // else if (product.price == 0) {
    //   window.alert("Invalid Amount, Minimum value is Rs 1")
    // }
    //this.initiateClaim();
    // console.log("Selected Product "+this.order);
  }

  onChange(value: any) {
    var dd = this.policies.find((x) => x.policyNumber == value.value);

    this.selectedPolicy = dd;
    this.order.policyNumber = dd?.id;
    this.members = this.selectedPolicy?.policyMembers;
    //console.log("Members "+JSON.stringify( this.members));
  }

  onMemberChange(value: any) {
    this.order.memberName = value.value;

    //console.log("Member name "+JSON.stringify( this.order.memberName));
  }

  policies: Policy[] = [];
  members: any;
  message: any;
  loading: any = false;
  getPolicies() {
    this.customerService
      .getPolicies()
      .pipe(first())
      .subscribe({
        next: (data: Policy[]) => {
          this.loading = false;
          //console.log(data);
          this.policies = data;
          this.error = undefined;
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  applyCoupon() {
    this.customerService
      .applyCoupon(this.selectedProduct.id, this.couponCode)
      .pipe(first())
      .subscribe({
        next: async (data: any) => {
          this.order.amount = data.discount;
          this.order.discountPercent = data.productDiscount.discountPercent;
          this.order.couponId = data.productDiscount.id;
          //alert("discountedPrice " + JSON.stringify(data));
          console.log("discountedPrice " + JSON.stringify(data));
          this.toastrService.success(
            `Coupon Applied ! Discount Percent: ${data.productDiscount.discountPercent}% | Discount Price: ${data.discount.toFixed(2)}`
          );
        },
        error: async (err: { error: { error: any } }) => {
          console.log(err);
          if (err.error && err.error.error) {
           this.toastrService.info("No Coupon found");
          } else {
            this.toastrService.error("Please try again!");
          }
          
console.log(JSON.stringify(this.selectedProduct))
          this.order.amount = this.selectedProduct.price ;
          this.order.discountPercent = 0;
          this.order.couponId = '';
          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No discounts available!";
          }
        },
      });
  }

  async getAvailableDiscounts(productId: any) {
    this.customerService
      .isDiscountsAvailable(productId)
      .pipe(first())
      .subscribe({
        next: async (data: any) => {
          this.availableDiscount = data;
          //console.log("Discounts " + JSON.stringify(data));
        },
        error: async (err) => {
          console.log(err);
          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No discounts available!";
          }
        },
      });
  }
  
  
  // warningICAR() {
  //   console.log("zfsdg")
  //   Swal.fire({
  //     title: 'Please agree to the terms and conditions',
  //     //text: 'You will not be able to recover this imaginary file!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Agree!',
  //     cancelButtonText: 'Disagree',
  //   }).then((result) => {

  //     if (result.isConfirmed) {
  //      this.main =  false;
  //      this.Istc=true;
    
  //     }
  //     if(result.isDismissed){

  //     }
  //   })



  // }
}
