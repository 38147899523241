import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/authService';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    user:any;
    constructor(
        private router: Router,
        private authService: AuthService
    ) { 
        this.authService.user.subscribe((x) => {
            this.user = x;
            //console.log("Auth " + this.user.jwtToken);
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //const user = this.authService.userValue;
        if (this.user) {
            var privileges = this.user.privileges;
            var dd = privileges.filter((x: string) => x == 'ADMIN' || x == 'SUPERADMIN');
            if (dd.length > 0) {
                return true;
            }

        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}