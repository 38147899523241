import { Component, Inject, OnInit } from "@angular/core";
import { CashflowService } from "../services/cashflow.service";
import { empty, first, map } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AdminService } from "../services/admin.service";
import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ExternalMediaMetadata } from "../model/mediaMetadata";
import { FileService } from "../services/file.service";
import { HospitalRegistrationService } from "../services/hospitalregistration.service";
import { ManagetpaService } from "../services/admin/managetpa.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {

  mediaMetaData: ExternalMediaMetadata = new ExternalMediaMetadata();
  formData: FormData = new FormData();
  loading: any;
  message: any;
  closed: boolean = false;
  selectedFiles: any = null;
  currentFile: any;
  categoryList: any;
  selectedCategory?: any;
  validFrom: any;
  validTo: any;
  dateValidation: boolean = false;
  progress = 0;
  clicked: boolean = false;
  dateEnabled: boolean = false;
  constructor(
    public MatDialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cashlessService: CashflowService,
    private toastrService: ToastrService,
    private fileService: FileService,
    private tpaService: ManagetpaService,
  ) {
    this.mediaMetaData = this.data;
    console.log(JSON.stringify(this.mediaMetaData));
  }
  ngOnInit(): void {
    this.getCategory(this.mediaMetaData.sourceType);
  }

  getCategory(taskName: any) {
    this.cashlessService
      .getCategoryByTypeAndEntityType(taskName)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.categoryList = data;
          this.selectedCategory = this.categoryList[0].id.categoryName;
          this.dateEnabled = this.categoryList[0].dateEnabled;
          // console.log(this.dateEnabled);
        },
      });
  }

  compareWithFn(o1: any, o2: any) {
    console.log("compare with");

    return o1 === o2;
  }

  enableDate() {
    //console.log("hello");
    if (
      this.selectedCategory &&
      !this.categoryList.find(
        (category: any) => category.id.categoryName === this.selectedCategory
      ).dateEnabled
    ) {
      this.dateEnabled = this.categoryList.find(
        (category: any) => category.id.categoryName === this.selectedCategory
      ).dateEnabled;
      // console.log(this.dateEnabled);
    } else {
      this.dateEnabled = this.categoryList.find(
        (category: any) => category.id.categoryName === this.selectedCategory
      ).dateEnabled;
      // console.log(this.dateEnabled);
    }
  }

  dateValidationCheck() {
    if (this.validFrom > this.validTo && this.dateEnabled) {
      this.dateValidation = true;
    } else {
      this.dateValidation = false;
    }
  }

  selectFile(event: any): void {
    const file: File | null = event.target.files.item(0);
    this.selectedFiles = event.target.files.item(0);
    console.log(this.selectedFiles);
    //this.selectedFiles = file;
    this.currentFile = file;
  }

  uploadFile() {
    // console.log(this.validFrom);
    // console.log(this.validTo);
    this.progress = 0;
    this.clicked = true;
    this.closed = true;
    console.log(this.clicked);
    this.formData = new FormData();


      this.formData.append("file", this.selectedFiles);
      this.formData.append("category", this.data.category);
      this.formData.append("sourceType", this.data.sourceType);
      this.formData.append("entityId", this.data.entityId);
      this.formData.append("entityType", this.data.entityType); //it should be process instance id in case of cashless
      this.formData.append("docType", this.selectedCategory);
      if (this.dateEnabled) {
        this.formData.append("startDate", new Date(this.validFrom).toISOString());
        this.formData.append("endDate", new Date(this.validTo).toISOString());
      }
    
    //console.log("form data setup complete");

    this.fileService.addExternalMedia(this.formData).pipe(
      map((event: any) => {
        // console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);

        } else if (event instanceof HttpResponse) {
          this.message = event.status;
          
          // console.log(this.message);
          this.toastrService.success(
            this.selectedCategory,
            " file has been uploaded successfully"
          );
          this.closed = false;
          // window.location.reload();
        } else if (event instanceof HttpErrorResponse) {
          this.message = event.error;
          // console.log(this.message);
          this.toastrService.success(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
        } else {
          // console.log(event.type);
        }
      })
    )
      .subscribe({
        next: (data: any) => {
          this.loading = false;
          //this.message = data;
          //this.toastrService.success(this.selectedCategory," file has been uploaded successfully");
        },
        error: (error) => {
          this.toastrService.success(
            this.selectedCategory,
            " file couldn't be uploaded. Try again!"
          );
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  close() {
    if (this.message == undefined) {
      this.MatDialogRef.close("FAILED");
    } else {
      this.MatDialogRef.close("UPLOADED");
    }
  }
}
