<div class="p-3">
  <div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Manage Hospital Details - {{ currentData.data.name }}</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <hr />
  <mat-dialog-content class="mat-typography">
    <!-- Edit -->
    <mat-accordion class="m-2">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <strong>Address / Contact Details</strong>
        </mat-expansion-panel-header>
        <form [formGroup]="addressForm" (ngSubmit)="onFormSubmit()">
          <div mat-dialog-content class="content">
            <div class="text-center">
              <p>Contact Details</p>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Xyz"
                  formControlName="name"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Mobile</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="1234567890"
                  formControlName="contactDetail"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  placeholder="abc@gmail.com"
                  formControlName="emailId"
                />
              </mat-form-field>
            </div>
            <hr />
            <div class="text-center">
              <p>Address Details</p>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label>Address</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Abc, Street"
                  formControlName="address"
                />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label>District</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Mumbai"
                  formControlName="district"
                />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Maharashtra"
                  formControlName="state"
                />
              </mat-form-field>
            </div>
          </div>
          <div mat-dialog-actions class="action" hidden>
            <button mat-raised-button type="button" [mat-dialog-close]="false">
              Cancel
            </button>
            <button mat-raised-button color="primary" type="submit">
              Update
            </button>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Insurance Compaines -->
    <mat-accordion class="m-2">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <strong>Technical Details</strong>
        </mat-expansion-panel-header>
        <div class="d-flex justify-content-end">
          <button
            type="button"
            mat-flat-button
            style="color: white; background: #8e0101"
            (click)="addTechincalInsurance(currentData.data)"
          >
            Add
          </button>
        </div>
        <div *ngIf="insuranceData.length != 0">
          <mat-table class="p-4" [dataSource]="dataSource" matSort>
            <!-- Position Column -->
            <ng-container matColumnDef="insuranceName">
              <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.insuranceName }}
              </mat-cell>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="claimSubmissionMethod">
              <mat-header-cell *matHeaderCellDef>Tech Method</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.claimSubmissionMethod }}
              </mat-cell>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="config">
              <mat-header-cell *matHeaderCellDef>Tech Data</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{
                  element.emailConf ||
                    element.portalConf ||
                    element.integrationConf ||
                    "Null"
                }}
              </mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="socId">
              <mat-header-cell *matHeaderCellDef>Soc</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a (click)="openViewFile(element)"> View </a>
              </mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="dateCreated">
              <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.dateCreated | date : "short" }}
              </mat-cell>
            </ng-container>
            <!-- Edit Column -->
            <ng-container matColumnDef="update">
              <mat-header-cell *matHeaderCellDef>Update</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button
                  type="button"
                  mat-flat-button
                  style="color: white; background: #8e0101"
                  (click)="editTechincalInsurance(element)"
                >
                  Update
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <div class="demo-options p-4">
            <div class="demo-toggles">
              <mat-paginator
                #paginator
                class="demo-paginator"
                (page)="handlePageEvent($event)"
                [length]="length"
                [pageSize]="pageSize"
                [disabled]="disabled"
                [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                [hidePageSize]="hidePageSize"
                [pageIndex]="pageIndex"
                aria-label="Select page"
              >
              </mat-paginator>
            </div>
          </div>
          <div
            class="alert alert-danger mt-2 pt-1"
            role="alert"
            *ngIf="message != undefined"
          >
            {{ message }}
          </div>
        </div>
        <div *ngIf="insuranceData.length === 0">
          <div class="alert alert-info mt-2 pt-1" role="alert">
            No Insurance Companies found.
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-2">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <strong>Legal Agreement & Non Caashless Soc</strong>
        </mat-expansion-panel-header>
        <div class="d-flex justify-content-end">
          <button
            type="button"
            mat-flat-button
            style="color: white; background: #8e0101"
            (click)="addLegalAgreement(currentData.data)"
          >
            Add
          </button>
        </div>
        <div *ngIf="legalData.length != 0">
          <div class="p-4">
            <table mat-table [dataSource]="dataSourceLegal" matSort>
              <!-- Position Column -->
              <ng-container matColumnDef="docType">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.docType }}
                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="path">
                <th mat-header-cell *matHeaderCellDef>Soc | Agreement</th>
                <td mat-cell *matCellDef="let element">
                  <a (click)="openViewFile(element)"> View </a>
                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef>Created Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dateCreated | date : "short" }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsLegal"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsLegal"
              ></tr>
            </table>
          </div>
          <div class="demo-options p-4">
            <div class="demo-toggles">
              <mat-paginator
                #paginator
                class="demo-paginator"
                (page)="handlePageEvent($event)"
                [length]="length"
                [pageSize]="pageSize"
                [disabled]="disabled"
                [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                [hidePageSize]="hidePageSize"
                [pageIndex]="pageIndex"
                aria-label="Select page"
              >
              </mat-paginator>
            </div>
          </div>
          <div
            class="alert alert-danger mt-2 pt-1"
            role="alert"
            *ngIf="message != undefined"
          >
            {{ message }}
          </div>
        </div>
        <div *ngIf="legalData.length === 0">
          <div class="alert alert-info mt-2 pt-1" role="alert">
            No Legal Agreement & Non Caashless Soc found.
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-2">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <strong>Associated Product </strong>
        </mat-expansion-panel-header>

        <div class="row">
          <div *ngIf="productsData.length === 0">
            <div class="alert alert-info mt-2 pt-1" role="alert">
              No Products found.
            </div>
          </div>
          <div *ngIf="productsData.length != 0">
            <div class="p-4">
              <table mat-table [dataSource]="dataSourceProduct" matSort>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Product Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.bgProductModel.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.bgProductModel.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="qty">
                  <th mat-header-cell *matHeaderCellDef>Qty</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.qty }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef>Price</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.negotiatedRate }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsProduct"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsProduct"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="p-3">
      <button color="accent" mat-button mat-dialog-close>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
