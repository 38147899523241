<div class="container p-3">
  <div class="container py-3 px-2">
    <!-- <div class="row justify-content-md-center">
      <div class="col-lg-4 ms-3">
        <mat-form-field *ngIf="hospitalList != undefined" appearance="fill" class="w-100">
          <mat-select placeholder="Select a hospital" [(ngModel)]="selectedHospital" (selectionChange)="searchCases()">
            <mat-option *ngFor="let hospital of hospitalList" [value]="hospital">
              {{ hospital.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4">
        <mat-form-field>
          <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="searchCases()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-lg-2 mt-2">
        <div class="d-flex flex-row">
         
          <button mat-flat-button style="color:white;background:#8e0101" (click)="clear()">
            Clear
          </button>
        
        </div>

      </div>
    </div> -->
    <div class="border rounded-1 p-2">
      <div class="col text-start">
        <span style="color:grey; font-size: 20px; font-weight: 500;">Task Filters</span>
      </div>
      <div class="custom-row">
        <div style="width:500px">
          <mat-form-field *ngIf="hospitalList != undefined" appearance="fill" class="w-100">
            <mat-select placeholder="Select a hospital" [(ngModel)]="selectedHospital" (selectionChange)="searchCases()">
              <mat-option *ngFor="let hospital of hospitalList" [value]="hospital">
                {{ hospital.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div >
          <mat-form-field>
            <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="searchCases()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div >
            <!-- <button mat-flat-button style="color:white;background:#8e0101" (click)="searchCases()">
              Search
            </button> -->
            <button type="button" style="color:white;background:#8e0101;" class="btn  btn-sm"
            (click)="filterPriority('ALL')">
            Reset Filter
          </button> 
            <!-- <button mat-flat-button style="color:white;background:#8e0101" (click)="exportexcel()">
              Export to excel
            </button> -->
         
  
        </div>
      </div>
      <hr>
      <div class="row p-2">
        
        <!-- <div class="col text-end">
         
          <button type="button" style="color:white;background:#8e0101;" class="btn  btn-sm"
            (click)="filterPriority('ALL')">
            Reset Filter
          </button> 
        </div> -->
      </div>
    
      <div class="row p-2">
        <div class="col-2 p-1" *ngFor="let filterButton of filterData">
          <button (click)="filterPriority(filterButton.taskId)" type="button"
            [class]="filterButton.count > 0?'btn btn-warning btn-sm position-relative':'btn btn-secondary btn-sm position-relative'"
            [ngClass]="{'selected-button': taskId === filterButton.taskId}">{{filterButton.taskName}}
            <span
              [class]="filterButton.count > 0?'position-absolute top-0 start-100 translate-middle badge text-bg-white rounded-pill bg-danger':'position-absolute top-0 start-100 translate-middle badge text-bg-white rounded-pill bg-secondary'">
              {{filterButton.count}}
              <span class="visually-hidden">Pending</span>
            </span>
          </button>
        </div>
        
    </div>
  </div>
  <!-- <button (click)="exportexcel()">Export to Excel</button> -->
  <div *ngIf="selectedHospital">
  <div class="totalCount" *ngIf="dataSource">
   <span > Total Cases: </span> <h1 style="padding-top: 10px;font-weight: bold;">{{caseList.length}}</h1>
  </div>
  <div class="row  px-3 py-4 justify-content-md-center">
    <input matInput (keyup)="applyFilter($event)" class="form-control" placeholder="Filter" aria-label="Search">
  </div>

      <table mat-table [dataSource]="dataSource" id="excel-table">

        <ng-container matColumnDef="caseID">
          <th mat-header-cell *matHeaderCellDef>Case ID</th>
          <td mat-cell *matCellDef="let element" [class]="element == selectedTask? 'selected' : ''">
            {{ element.process_inst_id }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="patientName">
          <th mat-header-cell *matHeaderCellDef>Patient Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.process_data.patientName }}
            
          </td>
        </ng-container>
        
        <ng-container matColumnDef="hospitalName">
          <th mat-header-cell *matHeaderCellDef>HospitalName</th>
          <td mat-cell *matCellDef="let element">
            {{ element.process_data.hospitalName }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element._id.date | date }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let element" >
            {{ element.tx_date | date }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="currentProcessState">
          <th mat-header-cell *matHeaderCellDef> Current Process Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.process_data.currentProcessStatus | capitalizeAndSpace}}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <th mat-header-cell *matHeaderCellDef class="center-header">Action</th>
      <td mat-cell *matCellDef="let element">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-custom" style="color:white;background:#8e0101" (click)="viewCaseDetails(element)">
            View
          </button>
          <button type="button" class="btn btn-custom" style="color:white;background:#8e0101; margin-left: 2px;" (click)="downloadPreAuthForm(element)"
          [disabled]="(element?.process_data['preauth-ExecutionStatus']) != 'EXECUTED'">
            Download PreAuth Form
          </button>
          
          <button type="button" class="btn btn-custom" style="color:white;background:#8e0101 ; ;margin-left: 2px;" (click)="viewPreAuth(element)"
          [disabled]="(element?.process_data['preauth-ExecutionStatus']) != 'EXECUTED'">
           Pre Auth Form
          </button>
        </div>
      </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        
      </table>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[10,25,50,100,200]" showFirstLastButtons></mat-paginator>
  </div>
</div>

