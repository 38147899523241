<div mat-dialog-title class="">
    <div class="row ">
        <div class="col-8 text-left">
            <h2><strong>User Performance - {{data.getProcessName | camelCaseToSpaces | uppercase}}</strong> </h2>
        </div>
        <div class="col-4 d-flex justify-content-end " mat-dialog-close>
            <mat-icon color="accent">cancel</mat-icon>
          </div>
    </div>
    
    <div class="row bg-light p-2">
        <div class="col-4 text-center ">
            <h4>User</h4>
        </div>
        <div class="col text-center">
            <h4>Task</h4>
        </div>
        <div class="col text-center">
            <h4>Execution Count</h4>
        </div>
        <div class="col text-center">
            <h4>Avg. Duration (Mins)</h4>
        </div>
        <div class="col text-center">
            <h4>TAT</h4>
        </div>
    </div>
    <div class="row h5" *ngFor="let element of data.userPerformance">
        <div class="col-4 text-center">
            {{element.username}}
        </div>
        <div class="col text-center">
            <div class="row" *ngFor="let task of element.tasks">
                <div class="col text-center">
                    {{task.taskName}} 
                </div>
                <div class="col text-center">
                    {{task.count }}
                </div>
                <div class="col text-center">
                    {{task.averageDuration/(1000*60*60) | number}}
                </div>
                <div class="col text-center">
                    {{task.tat}}
                </div>
            </div>
            
        </div>
    </div>
    
    <div class="p-2 bg-light">
        <mat-dialog-actions align="end">
            <button color="accent" mat-button mat-dialog-close>Cancel</button>
    
        </mat-dialog-actions>
    </div>
</div>
