import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileService } from '../services/file.service';

@Component({
  selector: 'app-attachment-scroller',
  templateUrl: './attachment-scroller.component.html',
  styleUrl: './attachment-scroller.component.scss'
})
export class AttachmentScrollerComponent {
  @Input() data:any;
  @Output() fileSelected = new EventEmitter<any>();
  constructor(
    private fileService:FileService
  ){

  }

  onFileSelected(item:any){
    console.log(item);
    this.fileSelected.emit(item);

  }

  deleteMedia(id:any){
    this.fileService.deleteExternalMedia(id)
    .subscribe({
      next: (data: any) => {
        console.log('Media deleted successfully', data);
      },
      error: (error) => {
        console.error('Error deleting media', error);
      }
    })
    console.log(id);
  }
}
