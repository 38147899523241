import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { BehaviorSubject, Observable, concat, every, first, of, take } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ViewFileComponent } from "../portal/bg/inbox/view-file/view-file.component";
import { AddKypComponent } from "../portal/bg/inbox/add-kyp/add-kyp.component";
import { AddAssignTaskComponent } from "../modal/add-assign-task/add-assign-task.component";
import { EditKypComponent } from "../portal/bg/inbox/edit-kyp/edit-kyp.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToastrService } from "ngx-toastr";
import { DashboardParam } from "../model/dashboardParam";
import { ViewAddKypComponent } from "../modal/view-add-kyp/view-add-kyp.component";
import { DataServiceService } from "../services/data-service.service";
import { WorkflowService } from "../services/workflow.service";
import { MatTabGroup } from "@angular/material/tabs";
import { DataShareModel } from "../model/dataShareModel";

@Component({
  selector: 'app-process-ekyp',
  templateUrl: './process-ekyp.component.html',
  styleUrls: ['./process-ekyp.component.scss']
})
export class ProcessEkypComponent implements OnInit, AfterViewInit {

  @Input() model!: {};
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  selectedIndex = 0;
  //ekypProcess
  public selectedTabSubject = new BehaviorSubject<any>(1);
  selectedTabState$ = this.selectedTabSubject.asObservable();
  public selectedTab:any = 1;
  dashboardParams: DashboardParam = { "processType": "ekypProcess", "orgType": "BG" };

  public dynamicFormGroup!: FormGroup;
  public fields: any = [];
  minDate: string;
  healthFiles: any;
  databody: any;
  results: any;
  sharedView: boolean = false;
  rolename: any;
  tasks: any[] = [];
  showDetails: boolean = true;
  policyMembers: any;
  assignedTasks: any[] = [];

  displayedColumns: string[] = ['pendingTask', 'orderDate', 'orderId', 'customerName', 'customerMobileNumber', 'assignedTo', 'action'];
  //displayedColumns: string[] = ['pendingTask','productName', 'orderDate', 'orderId', 'customerName','customerMobileNumber','action'];
  dataSource = new MatTableDataSource<any>(this.tasks);
  secondFormGroup = this._formBuilder.group({
    memberName: ["", Validators.required],
  });

  isLoading$ = new BehaviorSubject<boolean>(false);
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  faInbox = faInbox;
  today: Date = new Date();
  loading: any = false;
  error: any;
  message: any;
  selectedTask: any;
  allTasks:any;
  user: any;
  getpolicyFiles: any = [];
  disabled: boolean = false;

  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private dataService:DataServiceService,
    private workflowService:WorkflowService
  ) {
    const today = new Date().toISOString().split("T")[0];
    this.minDate = today;

  }

  public updateSelectedTab(newData: any) {
    this.selectedTabSubject.next(newData);
    this.disabled = false;
  }
  ngAfterViewInit(): void {
    
  }
  
  ngOnInit() {
    this.getAllTasks();
    this.authService.user.subscribe(x => {
      this.user = x;
      
    });
  }

  getAllTasks(){
    this.workflowService.getAllTask("ekypProcess")
    .subscribe({
      next:(data:any)=>{
        this.allTasks = data;
        const dataShare = new DataShareModel("ekypProcess",data);
        this.dataService.updateAllTasks(dataShare);
      },
      error:(error:any)=>{
        console.log(error);
      }

    })
  }


  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  getDashboardParam() {
    return JSON.stringify(this.dashboardParams);
  }


}




