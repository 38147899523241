import { Component, ViewChild } from "@angular/core";
import { MatPaginator, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { first } from "rxjs";
import { AuthService } from "src/app/services/authService";
import { CustomerService } from "src/app/services/customer.service";
import { ClaimOrdersDetailAgentComponent } from "../claim-orders-detail-agent/claim-orders-detail-agent.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-claim-orders-agent",
  templateUrl: "./claim-orders-agent.component.html",
  styleUrls: ["./claim-orders-agent.component.scss"],
})
export class ClaimOrdersAgentComponent {
  claimOrders: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];
  message: any;
  coupons: any;
  hpcMessage: any;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  user: any;
  downloadJsonHref: any;
  pageEvent: PageEvent = new PageEvent();

  dataSource = new MatTableDataSource<any>(this.claimOrders);
  displayedColumns: string[] = [
    "Id",
    "Name",
    "Amount",
    "TransactionDate",
    "TransactionStatus",
    "Gst",
    "DiscountPercent",
    "Action",
    "Download",
  ];
  loading: boolean = false;
  constructor(
    private _dialog: MatDialog,
    private authService: AuthService,
    private customerService: CustomerService,
  ) {
    this.authService.user.subscribe((x) => (this.user = x));
  }

  ngOnInit(): void {
    this.getClaimOrders();
  }

  getClaimOrders() {
    this.customerService
      .getAgentOrders()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.claimOrders = data;
          //console.log("orders data", this.claimOrders);
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //sthis.loading = false;
        },
      });
  }
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getClaimOrders();
  }


  handleInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.claimOrders = query === "" ? this.claimOrders : this.claimOrders.filter((item: any) =>
        item?.memberName?.toLowerCase().includes(query.toLowerCase()));
    } else {
      this.getClaimOrders();
    }

  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  openDetails(data: any) {
    const dialogRef = this._dialog.open(ClaimOrdersDetailAgentComponent, {
      disableClose:true,
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
          this.getClaimOrders();
        }
      },
    });
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }
  generateDownloadJsonUri(data: any) {
    //console.log("ty", data);
    let info: any[][] = [];
    let dt = JSON.stringify(data);
    let tt = JSON.parse(dt);
    //  tt.forEach((element: any,index: any,array: any)=>{
    //  info.push([element.name,element.amount,element.gst])
    //  })
    info.push([
      tt.memberName,
      tt.amount,
      tt.couponId,
      tt.discountPercent,
      tt.orderSource,
      tt.productId,
      tt.transactionDate,
      tt.transactionStatus,
      tt.gst,
    ]);
    const doc = new jsPDF();
    // const doc = new jsPDF({
    //   orientation: "landscape",
    //   unit: "in",
    //   format: [4, 2]
    // });
    autoTable(doc, {
      head: [
        [
          "Member Name",
          "Amount",
          "Coupon Id",
          "Discount Percent",
          "Order Source",
          "productId",
          "Transaction Date",
          "Transaction Status",
          "Gst",
        ],
      ],
      // body: [info,[{colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }]],

      body: info,
      // body: [
      //   [{ content: 'Text', colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }],
      // ],
    });

    doc.save("orders.pdf");
  }
}
