import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

import { Router } from "@angular/router";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../services/authService";
import { DashboardParam } from "../model/dashboardParam";
import { ToastrService } from "ngx-toastr";
import { DataServiceService } from "../services/data-service.service";
import { WorkflowService } from "../services/workflow.service";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
})
export class OnboardingComponent implements OnInit {
  dateVal = new Date();
  createCase!: FormGroup;
  selectedoption = null;
  DoYouhaveFamilyPhysician = null;
  assignedTasks: any[] = [];
  loading: any = false;
  dashboardParams: DashboardParam = {
    processType: "hospitalOnBoardingProcess",
    orgType: "BG",
  };
  error: any;
  message: any;

  policyMembers: any;
  user: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private cashlessservice: CashflowService,
    private customerService: CustomerService,
    private _dialog: MatDialog,
    private authService: AuthService,
    private toastrService: ToastrService,
    private dataService: DataServiceService,
    private workflowService: WorkflowService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.userValue;
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  getDashboardParam() {
    return JSON.stringify(this.dashboardParams);
  }
}
